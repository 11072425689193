import React ,  { useState , useEffect } from 'react';
import { useParams } from "react-router-dom";
import PurchaseFormServices from './Services/PurchaseFormServices'


const ResalePurchaseOrderTable = (props) => {
const [state, setState] = useState([]);
const params = useParams();
const fetchPurchaseOrderItem = async (id) => {
    const resultinfo = await PurchaseFormServices.getPurchaseOrderItem(id);
    setState(resultinfo);
}

useEffect(()=>{
    fetchPurchaseOrderItem(params.id);
},[params.id])

    return (
            <table class="table table-bordered record-table">
                <thead>
                    <tr>
                        <th>Item</th>
                        <th>Description</th>
                        <th>Qty</th>
                        <th>Rate</th>
                        <th>Amount</th>
                        <th>Customer</th>
                        <th>Container #</th>	
                    </tr>
                </thead>

                <tbody>
                    {
                        state.map((value)=>
                            <tr>
                                <td>{value.item}</td>
                                <td>{value.des}</td>
                                <td>{value.quantity}</td>
                                <td>{value.rate}</td>
                                <td>{value.amount}</td>
                                <td>{value.customer}</td>
                                <td>{value.containerNumber}</td>
                            </tr>
                        )
                    }
                </tbody>
            </table>
    );
};

export default ResalePurchaseOrderTable;