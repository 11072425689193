import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import moment from 'moment-timezone';
import jstz from 'jstz';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";
import { DatePicker } from '@material-ui/pickers'
import React, { useState, useEffect } from "react";
import { useCookies } from 'react-cookie';

const CustomDatePicker = (props) => {
  const [date, setDate] = useState({});
  const [cookies, setCookie] = useCookies(props.pageName);

  useEffect(() => {
    setDate(cookies);
  }, [cookies]);

  const getESTDate = (date) => {
    const timezone = jstz.determine();
    return moment(date).tz(timezone.name());
  };

  // const getOneDayBeforeDate = (date) => {
  //   return getESTDate(date).add(5, 'minutes');
  // };

  const handleDateChange = (selectedDate) => {
    const estDate = getESTDate(selectedDate).format('YYYY-MM-DD');
    setCookie(props.columnDef.field, estDate, { path: '/' + props.pageName });
    setDate({ [props.columnDef.field]: selectedDate });
    props.onFilterChanged(props.columnDef.tableData.id, selectedDate);
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <DatePicker
        id="date-picker-dialog"
        format="MM/dd/yy"
        value={(date[props.columnDef.field]) ? getESTDate(date[props.columnDef.field]) : null}
        clearable
        onChange={handleDateChange}
      />
    </MuiPickersUtilsProvider>
  );
};

export default CustomDatePicker;
