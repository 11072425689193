import React, { Component } from 'react';
import Sidebar from "./../Layout/Sidebar";
import { withRouter, Link } from 'react-router-dom';
import logo from '../../images/logo.png';
import profilepic from '../../images/avatar-4.jpg';
import { ACCESS_TOKEN_NAME, ACCESS_TOKEN_NAME_USER, API_BASE_URL } from '../../constants/apiConstants';
import LocationDriver from './LocationDriver';
import PlanningServices from './PlanningServices';
import PlanningBox from './PlanningBox';
import LeftNavbar from "./../Layout/LeftNavbar";
import { LocationContext } from "../../context/LocationContext";
import Avatar from 'react-avatar';
import Cookies from 'universal-cookie';
import swal from "sweetalert";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import ProgressBar from '../ProgressBar';
import SyncComponent from '../Shared/SyncComponent';


class DriverPlanning extends Component {
    constructor(props) {
        super(props);
        const cookies = new Cookies();
        this.state = {
            location: [],
            driver: [],
            slectedLocation: {},
            selectDriver: [],
            ordersDetails: [],
            dateinfo: (cookies.getAll().planningDate) ? new Date(cookies.getAll().planningDate) : new Date(),
            ordersDetailsLoading: false,
            selectedLocationid: null,
            limit: 6,
            display: 'none',
            activeUser: [],
            showProgressBar: false,
            pageNameList: 'Planning'

        }
        this.handleDriverChange = this.handleDriverChange.bind(this);
        this.planning = this.planning.bind(this);
        this.handleDispatcher = this.handleDispatcher.bind(this);
        this.storeLocation = this.storeLocation.bind(this);
    }

    handelPorfilebox = () => {
        this.setState((old) => {
            return {
                ...old,
                display: old.display == 'none' ? 'block' : 'none'
            }
        })
    }

    //    memoizedAdd = () => {
    //         let cache = {};
    //         return async (n) => {
    //             console.log(cache, 'cacheinfo');

    //             if (n in cache) {
    //                 return cache[n];
    //             } else {
    //                 let result = await PlanningServices.getPlanningByInvoice(n);


    //                 cache[n] = result;

    //                 console.log(result, 'resultinfo');
    //                 return result;
    //             }
    //         }
    //     }

    // randomizeIndex = (count) => {

    //     console.log(count, 'randomizeElemnts');

    //     return Math.floor(count * Math.random());
    // }

    // randomizeElemnts = (array, count) => {



    //     if (count > array.length) {
    //         throw new Error('Array size cannot be smaller than expected random numbers count.');
    //     }
    //     const result = [];
    //     const guardian = new Set();
    //     while (result.length < count) {
    //         const index = this.randomizeIndex(count);
    //         if (guardian.has(index)) {
    //             continue;
    //         }
    //         const element = array[index];
    //         guardian.add(index);
    //         result.push(element);
    //     }
    //     return result;
    // }

  

    search = async (value) => {
        console.log('hmmmmmmmmmm')
        if(!value)
        {
            await this.planning();
           await this.storeLocation();
           return;
        }
        this.setState({
            showProgressBar: true
        })
       
        const driverAll = await PlanningServices.fetchDriverWithoutLocation();
        const resultinfo = await PlanningServices.getPlanningByInvoice(value);
        console.log('checkLength', resultinfo.length)
        if (resultinfo.length > 0) {
            const selectDriver = new Array();
            const selectDate = new Array();
            resultinfo.forEach(element => {
                selectDriver.push(element.driverId);
                selectDate.push(element.planningDate);
            });
            const selectDriverinfo = new Array();
            driverAll.forEach(element => {
                if (selectDriver.includes(element.contactId)) {
                    selectDriverinfo.push(element);
                }
            });
            this.setState({
                dateinfo: new Date(selectDate[Math.floor(selectDate.length * Math.random())]),
                selectDriver: selectDriverinfo,
            });
            setTimeout(() => {
                this.setState({
                    showProgressBar: false
                })
              }, 1000);
        } else {
           
            setTimeout(() => {
                toast.error(`No Invoice Found`, {
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });
            });
            this.setState({
                showProgressBar: false
            });
        }
    }

    handleLogout = () => {
        localStorage.removeItem(ACCESS_TOKEN_NAME);
        localStorage.removeItem('role');
        localStorage.removeItem('user');
        localStorage.removeItem(ACCESS_TOKEN_NAME_USER);
        this.props.history.push("/");
    }

    handelDate = (e) => {
        this.setState({
            showProgressBar: true
        })
        this.setState((old) => {
            return {
                ...old,
                dateinfo: new Date(e),
            }
        });
         setTimeout(() => {
                this.setState({
                    showProgressBar: false
                })
              }, 1000);
    }

    async fetchNeedToDispatch() {
        try {

           
            this.setState({
                ordersDetailsLoading: true
            });
            const result = PlanningServices.findOrderIdDetails();
            console.log('hhhhhhhhhhsdsdsdsdsdsdsdsdsd', this.state.slectedLocation?.supplierloctionname,this.state.slectedLocation?.supplierlocationid)
            result.then((data) => {
                this.setState({
                    ordersDetails: data.filter((item) => item.City == this.state.slectedLocation?.supplierloctionname || this.state.slectedLocation?.supplierlocationid == 0),
                    ordersDetailsLoading: false
                });
            });
        } catch (error) {
            console.log(error, 'storeLocation');
        }
    }


    async storeLocation(user = false) {
        //console.log('okkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkk')
        
        const cookies = new Cookies();
        //console.log('afsadfsdfsdfsdf', cookies.getAll())
        try {
            this.setState({
                showProgressBar: true
            })
            let result;
            let resultDriver;
            if (user && user != 'false') {
                result = await PlanningServices.fetchLocation(user);
                result.unshift({ supplierlocationid: 0, supplierloctionname: "All Location" });
                // const driverCollection = new Array();
                // for (const item of result) {
                //     const allDriver =  await PlanningServices.fetchDriver(item.supplierlocationid);
                //     for (const data of allDriver) {
                //         driverCollection.push(data);
                //     }
                // }
                // resultDriver = driverCollection;
                if (cookies.getAll().driverLocation) {
                    resultDriver = await PlanningServices.fetchDriver(cookies.getAll().driverLocation);
                    // if(resultDriver.length == 0)
                    // {
                    //     setTimeout(() => {
                    //         toast.error(`Driver not found for this location`, {
                    //             autoClose: 2000,
                    //             hideProgressBar: false,
                    //             closeOnClick: true,
                    //             pauseOnHover: true,
                    //             draggable: true
                    //         });
                    //     });
                    // }
                } else {
                    resultDriver = await PlanningServices.fetchDriverByUser(user);
                }
            } else {
                result = await PlanningServices.findAllSupplierLocationWithoutUser();
                result.unshift({ supplierlocationid: 0, supplierloctionname: "All Location" });
                if (cookies.getAll().driverLocation) {
                    resultDriver = await PlanningServices.fetchDriver(cookies.getAll().driverLocation);
                    // if(resultDriver.length == 0)
                    // {
                    //     setTimeout(() => {
                    //         toast.error(`Driver not found for this location`, {
                    //             autoClose: 2000,
                    //             hideProgressBar: false,
                    //             closeOnClick: true,
                    //             pauseOnHover: true,
                    //             draggable: true
                    //         });
                    //     });
                    // }
                } else {
                    resultDriver = await PlanningServices.fetchDriverWithoutLocation();
                }
            }

            this.setState({
                location: result,
                slectedLocation: (cookies.getAll().driverLocation) ? result.find((item) => item.supplierlocationid == cookies.getAll().driverLocation) : result[0],
                driver: resultDriver.filter((item) => item.contactId != 99434),
                selectDriver: (cookies.getAll().driverName) ?
                    resultDriver.filter((item) => item.contactId == cookies.getAll().driverName) :
                    resultDriver.filter((item) => item.contactId != 99434)
            });
            if(resultDriver.length == 0)
            {
                swal({
                    text: "Driver not found for this location",
                    icon: "error",
                    dangerMode: true,
                
                })
            }
            this.fetchNeedToDispatch();
            setTimeout(() => {
                this.setState({
                    showProgressBar: false
                })
              }, 1000);
        } catch (error) {
            console.log(error, 'storeLocation');
            // this.setState({
            //     showProgressBar: false
            // })
            setTimeout(() => {
                this.setState({
                    showProgressBar: false
                })
              }, 1000);
        }
    }

    async storeLocation2(user = false) {
        //console.log('okkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkk')
        
        const cookies = new Cookies();
        //console.log('afsadfsdfsdfsdf', cookies.getAll())
        try {
            // this.setState({
            //     showProgressBar: true
            // })
            let result;
            let resultDriver;
            if (user && user != 'false') {
                result = await PlanningServices.fetchLocation(user);
                result.unshift({ supplierlocationid: 0, supplierloctionname: "All Location" });
                // const driverCollection = new Array();
                // for (const item of result) {
                //     const allDriver =  await PlanningServices.fetchDriver(item.supplierlocationid);
                //     for (const data of allDriver) {
                //         driverCollection.push(data);
                //     }
                // }
                // resultDriver = driverCollection;
                if (cookies.getAll().driverLocation) {
                    resultDriver = await PlanningServices.fetchDriver(cookies.getAll().driverLocation);
                    // if(resultDriver.length == 0)
                    // {
                    //     setTimeout(() => {
                    //         toast.error(`Driver not found for this location`, {
                    //             autoClose: 2000,
                    //             hideProgressBar: false,
                    //             closeOnClick: true,
                    //             pauseOnHover: true,
                    //             draggable: true
                    //         });
                    //     });
                    // }
                } else {
                    resultDriver = await PlanningServices.fetchDriverByUser(user);
                }
            } else {
                result = await PlanningServices.findAllSupplierLocationWithoutUser();
                result.unshift({ supplierlocationid: 0, supplierloctionname: "All Location" });
                if (cookies.getAll().driverLocation) {
                    resultDriver = await PlanningServices.fetchDriver(cookies.getAll().driverLocation);
                    // if(resultDriver.length == 0)
                    // {
                    //     setTimeout(() => {
                    //         toast.error(`Driver not found for this location`, {
                    //             autoClose: 2000,
                    //             hideProgressBar: false,
                    //             closeOnClick: true,
                    //             pauseOnHover: true,
                    //             draggable: true
                    //         });
                    //     });
                    // }
                } else {
                    resultDriver = await PlanningServices.fetchDriverWithoutLocation();
                }
            }

            this.setState({
                location: result,
                slectedLocation: (cookies.getAll().driverLocation) ? result.find((item) => item.supplierlocationid == cookies.getAll().driverLocation) : result[0],
                driver: resultDriver.filter((item) => item.contactId != 99434),
                selectDriver: (cookies.getAll().driverName) ?
                    resultDriver.filter((item) => item.contactId == cookies.getAll().driverName) :
                    resultDriver.filter((item) => item.contactId != 99434)
            });
            if(resultDriver.length == 0)
            {
                swal({
                    text: "Driver not found for this location",
                    icon: "error",
                    dangerMode: true,
                
                })
            }
            this.fetchNeedToDispatch();
            // this.setState({
            //     showProgressBar: false
            // })
        } catch (error) {
            console.log(error, 'storeLocation');
           
            // setTimeout(() => {
            //     this.setState({
            //         showProgressBar: false
            //     })
            //   }, 1000);
        }
    }

    async handleLocationChange(locationid) {
        try {
            console.log('achaaaaaaaaaaaaaaaa')
            console.log(locationid, 'locationidinfo');
            this.setState({
                showProgressBar: true
            })

            if (locationid == 0) {
                var result = await PlanningServices.fetchDriverWithoutLocation();
            } else {
                var result = await PlanningServices.fetchDriver(locationid);
                
            }
            
            const orderinfo = await PlanningServices.findOrderIdDetails();
            // console.log('reeeeeeeeeeeeeeeeeeeeeee', this.state.location)
            // console.log('reeeeeeeeeeeeeeeeeeeeeee', orderinfo.filter((item) => item.City == this.state.location.find((item) => item.supplierlocationid == locationid)?.supplierloctionname))
            if(result.length > 0)
            {
                this.setState({
                    selectDriver: result,
                    limit: 6,
                    driver: result,
                    slectedLocation: this.state.location.find((item) => item.supplierlocationid == locationid),
                    ordersDetails: (locationid == 0) ? orderinfo : orderinfo.filter((item) => item.City == this.state.location.find((item) => item.supplierlocationid == locationid)?.supplierloctionname),
                });

                console.log('reeeeeeeeeeeeeeeeeeeeeee', this.state.ordersDetails)
            }
            else
            {
              
                this.setState({
                    selectDriver: result,
                    limit: 6,
                    driver: result,
                    slectedLocation: this.state.location.find((item) => item.supplierlocationid == locationid),
                    ordersDetails: (locationid == 0) ? orderinfo : orderinfo.filter((item) => item.City == this.state.location.find((item) => item.supplierlocationid == locationid)?.supplierloctionname),
                });
                swal({
                    text: "Driver not found for this location",
                    icon: "error",
                    dangerMode: true,
                
                })
            }
            setTimeout(() => {
                this.setState({
                    showProgressBar: false
                })
              }, 1000);
           

      

        } catch (error) {
            console.log(error, 'handleLocationChange');
            setTimeout(() => {
                this.setState({
                    showProgressBar: false
                })
              }, 1000);
        }
    }

    async handleDriverChange(e) {
        this.setState({
            showProgressBar: true
        })
        let driverId = e.target.value;
        if (!isNaN(driverId)) {
            let driverObject = this.state.driver.filter((item) => item.contactId == driverId);
            this.setState({
                selectDriver: driverObject
            });
        } else {
            this.setState({
                selectDriver: this.state.driver
            });
        }
        setTimeout(() => {
            this.setState({
                showProgressBar: false
            })
          }, 1000);
    }


    componentDidUpdate() {
        if (this.context.slectedLocation?.locationid != this.state.selectedLocationid) {
            this.setState((old) => {
                return {
                    ...old,
                    selectedLocationid: this.context.slectedLocation?.locationid
                }
            })

            this.handleLocationChange(this.context.slectedLocation?.locationid);
        }
    }

    async fetchActiveUser() {
        try {
            const result = await PlanningServices.fetchActiveUser();
            result.unshift({ id: false, name: "All" });
            this.setState({
                activeUser: result
            })
        } catch (error) {
            console.log(error, 'errorinfo');
        }
    }
    async handleDispatcher(e) {
        this.storeLocation(e.target.value);
    }


    componentDidMount() {
        this.storeLocation();
        this.fetchActiveUser();
    }

    planning() {
        this.fetchNeedToDispatch();
    }

    handelScroll = () => {
        this.setState((old) => {
            return {
                ...old,
                limit: old.limit + 6
            }
        })
    }

    render() {

        return (
            <div id="pcoded" className="pcoded">
                <div className="pcoded-overlay-box"></div>
             
                
                
                <div className="pcoded-container navbar-wrapper">
                    <nav className="navbar header-navbar pcoded-header" style={{ height: '55px', minHeight: '26px' }}>
                        <div className="navbar-wrapper">
                            <div className="navbar-logo" style={{ height: '52px' }}>
                                <a style={{ top: '7px' }} className="mobile-menu navabar_menu" id="mobile-collapse" href="#!">
                                    <i class="ti-menu" style={{ "color": " #000", "font-size": "22px;" }}></i>
                                </a>
                                <a className="mobile-search morphsearch-search" href="#">
                                    <i className="ti-search"></i>
                                </a>
                                <Link to="/dashboard">
                                    <img style={{ width: '30%' }} className="img-fluid logo-img" src={logo} alt="Theme-Logo" />
                                </Link>
                                <ToastContainer />
                                <a className="mobile-options">
                                    <i className="ti-more"></i>
                                </a>
                            </div>

                            <SyncComponent pageNameList={this.state.pageNameList} />

                            {/* <div className="navbar-container container-fluid">



                                <ul className="nav-left" style={{ margin: "1% 0% 0% 8%" }}>
                                    <h4>{ this.state.pageNameList }</h4>
                                </ul>

                                <ul className="nav-right">
                                    {

                                        <li class="user-profile header-notification">
                                            <a className="mobile-menu navabar_menu1" id="mobile-collapse1"><i className="ti-menu" style={{ "font-size": "24px" }}></i></a>
                                        </li>

                                    }
                                    <li className="user-profile header-notification" style={{ lineHeight: 3.5 }}>
                                        <a href="javascript:void(0)" onClick={this.handelPorfilebox}>
                                            <Avatar name={localStorage.getItem('user')} colors={['#ed3028', '#711999', '#383838']} size="40" round="20px" />
                                        </a>
                                        <ul className="show-notification profile-notification" style={{ display: this.state.display }}>
                                            <li>
                                                <a href="#">
                                                    <i className="ti-user"></i> Profile
                                                </a>
                                            </li>
                                            <li>
                                                <Link to="/resetpassword">
                                                    <i className="ti-layout-sidebar-left"></i> Change Password
                                                </Link>
                                            </li>
                                            <li>
                                                <a onClick={this.handleLogout}>
                                                    <i className="ti-layout-sidebar-left"></i> Logout
                                                </a>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </div> */}
                        </div>
                    </nav>

                    <div className="pcoded-container navbar-wrapper">
                        <div className="pcoded-main-container">
                            <div className="pcoded-wrapper">
                                <Sidebar />
                                <LeftNavbar ordersDetailsLoading={this.state.ordersDetailsLoading} invoice={this.state.ordersDetails} />
                                <div className="pcoded-content page_content">
                                {
                                    this.state.showProgressBar ?
                                        <ProgressBar/>
                                    :
                                    ''
                                    }
                                    <div className="pcoded-inner-content">
                                        <div className="main-body">
                                            <div className="page-wrapper">
                                                <div className="page-body">
                                                    <LocationDriver  storeLocation={this.storeLocation} search={this.search} handelDate={this.handelDate} dateinfo={this.state.dateinfo} handleDispatcher={this.handleDispatcher} activeUser={this.state.activeUser} handleDriverChange={this.handleDriverChange}  driver={this.state.driver} location={this.state.location} />
                                                    <div class="container-fluid" style={{ padding: "23px 0px" }}>
                                                        <div>
                                                            {
                                                                this.state.selectDriver.map((item, index) => (index < this.state.limit) ? <PlanningBox dateinfo={this.state.dateinfo} handelScroll={this.handelScroll} planning={this.planning} ordersDetailsLoading={this.state.ordersDetailsLoading} ordersDetails={this.state.ordersDetails} driverInfo={item} slectedLocation={this.state.slectedLocation} driver={this.state.driver} storeLocation={this.storeLocation} storeLocation2={this.storeLocation2} showProgressBar={this.state.showProgressBar} /> : '')
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

DriverPlanning.contextType = LocationContext;

export default withRouter(DriverPlanning);
