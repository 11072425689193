import React, { createContext, useState } from 'react';

export const LocationContext = createContext({
  slectedLocation:null,
  setslectedLocation(){},
  updateslectedLocation(){}
});

export const LocationContextProvider = ({ children }) => {

  const [slectedLocation, setslectedLocation] = useState(null);

  const updateslectedLocation = (locationid) => [
    setslectedLocation((prevState) => {
      return { ...prevState, locationid };
    }),
  ];

  const value = {
    slectedLocation,
    setslectedLocation,
    updateslectedLocation,
  };

  return <LocationContext.Provider value={value}>{children}</LocationContext.Provider>;
};

