import React, { useState, useForm } from "react";
import axios from "axios";
import "./RegistrationForm.css";
import { API_BASE_URL, ACCESS_TOKEN_NAME } from "../../constants/apiConstants";
import { useParams } from "react-router-dom";
import logo from '../../images/logo.png';
import { ToastContainer, toast } from 'react-toastify';

function SetPassword(props) {
  const [state, setState] = useState({
    password: "",
    confirmPassword: "",
    successMessage: null,
    errorMessage: {},
  });
  const {token} = useParams();
//   console.log('token',token);
  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(name, value);
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    validate(name, value);
  };

  const validate = (key, value) => {
    setState((prevState) => ({
      ...prevState,
      errorMessage: {
        ...prevState.errorMessage,
        [key]: "",
      },
    }));
    switch (key) {
      case "password":
        
        if (value.length < 5) {
          setState((prevState) => ({
            ...prevState,
            errorMessage: {
              ...prevState.errorMessage,
              password: "Invalid Password",
            },
          }));
        }
        break;
      case "confirmPassword":
        if (value !== state.password) {
          setState((prevState) => ({
            ...prevState,
            errorMessage: {
              ...prevState.errorMessage,
              confirmPassword: "Password did not match",
            },
          }));
        }
        break;

      default:
    }
  };
  
  const handleSubmitClick = (e) => {
    e.preventDefault();
    if (!state.password.length) {
      setState((prevState) => ({
        ...prevState,
        errorMessage: {
          ...prevState.errorMessage,
          password: "Please Enter Password",
        },
      }));
    }
    if (state.password === state.confirmPassword) {
    } else {
      props.showError('Passwords do not match');
    }
    console.log(
      state.password.length
    );
    if (state.password.length
    ) {
      setState((prevState) => ({
        ...prevState,
        errorMessage: {},
      }));
      const payload = {
        password: state.password,
        resetToken: token
      };
      console.log("payload..", payload);
      axios
        .post(API_BASE_URL + "/setPassword", payload)
        .then(function (response) {
          console.log("set password--->>", response);
          if (response.status === 200) {
            setState((prevState) => ({
              ...prevState,
              successMessage: "Password Set Successfully",
            }));

            // localStorage.setItem(ACCESS_TOKEN_NAME, response.data.AccessToken);
            
            setState((prevState) => ({
              ...prevState,
              errorMessage: {},
            }));

            setTimeout(() => {
              toast.success('Password Set Successfully', {
                  autoClose: 2000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true
              });
            });
            redirectToLogin();
          } else {
            setState((prevState) => ({
              ...prevState,
              errorMessage: "some error occurd",
            }));
          }
        })
        .catch(function (error) {
          console.log(error);
          
          setTimeout(() => {
            toast.success('Token Expired', {
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true
            });
          });
        });
    } else {
      setState((prevState) => ({
        ...prevState,
        errorMessage: {
          ...prevState.errorMessage,
          commonError: "Please enter valid username and password",
        },
      }));
    }
  };
  const redirectToLogin = () => {
    props.history.push("/login");
  };
  return (
    // <div className="register_main">
    //   <form>
    //     {state.errorMessage.commonError && (
    //       <span className="red">{state.errorMessage.commonError}</span>
    //     )}
    //     <div className="form-group text-left">
    //       <label htmlFor="exampleInputPassword1">Password</label>
    //       <input
    //         type="password"
    //         name="password"
    //         className="form-control"
    //         id="password"
    //         placeholder="Password"
    //         value={state.password}
    //         onChange={handleChange}
    //       />
    //       <small id="emailHelp" className="form-text text-muted">
    //         Minimum five characters required.
    //       </small>
    //       {state.errorMessage.password && (
    //         <span className="red">{state.errorMessage.password}</span>
    //       )}
    //     </div>
    //     <div className="form-group text-left">
    //       <label htmlFor="exampleInputPassword1">Confirm Password</label>
    //       <input
    //         type="password"
    //         name="confirmPassword"
    //         className="form-control"
    //         id="confirmPassword"
    //         placeholder="Password"
    //         value={state.confirmPassword}
    //         onChange={handleChange}
    //       />
    //       {state.errorMessage.confirmPassword && (
    //         <span className="red">{state.errorMessage.confirmPassword}</span>
    //       )}
    //     </div>
    //     <button
    //       type="submit"
    //       className="btn btn-primary"
    //       onClick={handleSubmitClick}
    //     >
    //       Submit
    //     </button>
    //   </form>
    //   <div
    //     className="alert alert-success mt-2"
    //     style={{ display: state.successMessage ? "block" : "none" }}
    //     role="alert"
    //   >
    //     {state.successMessage}
    //   </div>
    // </div>

    <div id="login" className="login-background">
    <div className="container-fluid">
        <div className="row">
            <div className="col-md-6">
                <img src={logo} className="page-logo"/>
            </div>
        </div>

        <div className="login-section">
            <div className="row">
                <div className="col-md-6  content-l">
                    <h3 className="time">IT'S TIME TO CHANGE PASSWORD</h3>
                    <h6 className="get">Get Login To Explore</h6>
                </div>

                <div className="col-md-6">
                    <div className="confirm-card">
                        <h2>Please Enter Password</h2>
                        <p className="login-inner">Enter your details below</p>
                        <div className="row confirm-space">
                            <div className="col-md-12 mt-3 send-pass">
                                <div className="form-group inp-btm">
                                    {/* <input type="email" className="form-control custom-input" id="exampleInputEmail1"
                                        aria-describedby="emailHelp" placeholder="Enter Password" /> */}
                                    <input
                                      type="password"
                                      name="password"
                                      className="form-control custom-input"
                                      id="password"
                                      placeholder="Enter Password"
                                      value={state.password}
                                      onChange={handleChange}
                                    />
                                    {/* <small id="emailHelp" className="form-text text-muted">
                                      Minimum five characters required.
                                    </small> */}
                                    {state.errorMessage.password && (
                                      <span className="red">{state.errorMessage.password}</span>
                                    )}
                                </div>
                            </div>

                            <div className="col-md-12 pass">
                                <div className="form-group inp-btm">
                                    {/* <input type="email" className="form-control custom-input" id="exampleInputEmail1"
                                        aria-describedby="emailHelp" placeholder="Confirm Password" /> */}
                                    <input
                                      type="password"
                                      name="confirmPassword"
                                      className="form-control custom-input"
                                      id="confirmPassword"
                                      placeholder="Enter Confirm Password"
                                      value={state.confirmPassword}
                                      onChange={handleChange}
                                    />
                                    {state.errorMessage.confirmPassword && (
                                      <span className="red">{state.errorMessage.confirmPassword}</span>
                                    )}    

                                </div>
                            </div>
                            {/* <div className="col-md-12">
                                <p className="requirement">Password Requirement</p>
                                <p className="validate"><i className="fa fa-times" aria-hidden="true"></i> Contain atleast one uppercase letter</p>
                                <p className="validate"><i className="fa fa-times" aria-hidden="true"></i> Contain atleast one lowercase letter</p>
                                <p className="validate"><i className="fa fa-times" aria-hidden="true"></i> Contain atleast two number</p>
                                <p className="validate"><i className="fa fa-times" aria-hidden="true"></i> Contain atleast one special character</p>
                            </div> */}

                            <div className="col-md-12">
                                <button className="btn btn-danger btn-process" onClick={handleSubmitClick}>Save <i
                                        className="fa fa-long-arrow-right btn-icn-right"
                                        aria-hidden="true"></i></button>
                            </div>
                            {/* <div className="col-md-12">
                                <p className="forgot">Back</p>
                            </div> */}

                        </div>
                    </div>

                </div>
            </div>
        </div>
        <ToastContainer />
        <footer>
            <p className="footer-content">©2021, Container One</p>
        </footer>
    </div>
    
</div>

  );
}

export default SetPassword;
