import React from 'react';
import PropTypes from 'prop-types';

const CustomTime = ({
  field,
  form: { touched, errors },
  label,
  disabled,
  inputRef,
  ...props
}) => {
  const handleTimeChange = (e) => {
    const newValue = e.target.value;
    console.log('Time value has changed:', newValue);
    props.fun(true)

    // You can perform additional actions here when the value changes

    // Make sure to call the onChange handler to update the form's value
    field.onChange(e);
  };

  return (
    <div className="form-group">
      <label htmlFor={field.name}>{label}</label>
      {disabled ? (
        <input
          type="time"
          id={field.name}
          className="form-control time"
          ref={inputRef}
          {...field}
          {...props}
          onChange={handleTimeChange} // Listen for changes
          disabled
        />
      ) : (
        <input
          type="time"
          id={field.name}
          className="form-control time"
          ref={inputRef}
          {...field}
          {...props}
          onChange={handleTimeChange} // Listen for changes
        />
      )}

      {touched[field.name] && errors[field.name] ? (
        <small style={{ color: 'red' }}>{errors[field.name]}</small>
      ) : null}
    </div>
  );
};

CustomTime.defaultProps = {
  inputRef: undefined,
};

CustomTime.propTypes = {
  label: PropTypes.string.isRequired,
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  inputRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
};

export default CustomTime;
