import React, { useState, useEffect } from "react";
import axios from "axios";
import "./RegistrationForm.css";
import { API_BASE_URL, ACCESS_TOKEN_NAME, ACCESS_TOKEN_NAME_USER } from "../../constants/apiConstants";
import { withRouter, Link, useHistory } from "react-router-dom";
import InputMask from 'react-input-mask';
import { Multiselect } from 'multiselect-react-dropdown';
import logo from '../../images/logo.png';
import profilepic from '../../images/avatar-4.jpg';
import Navbar from "./../Layout/Navbar";
import Sidebar from "./../Layout/Sidebar";
import Footer from "./../Layout/Footer";
import ResetServices from "../ResetPassword/ResetServices";
import Avatar from 'react-avatar';
import { ToastContainer, toast } from 'react-toastify';
import Select from 'react-select/creatable';


function RegistrationForm(props) {
let history = useHistory();
  const [state, setState] = useState({
    name: "",
    email: "",
    role: "Select role",
    mobile: "",
    recordID:null,
    secondaryMobile: "",
    officeLocation: "Select Office Location",
    supplierLocations: [],
    successMessage: null,
    errorMessage: {}
  });
  const[location, setLocation] = useState();
  const[userEmail, setuserEmail] = useState([]);
  const[loading, setLoading] = useState(false);
  const [supplierLocation, SetSupplierLocation] = useState({
    options: [{}]
  });
  const[roles, setRoles] = useState();

  const fetchEmailFromMethod = async (skip , top) => {
    
    try {
      setLoading(true);
      const result = await axios.get(API_BASE_URL + `/fetchUserEmail?skip=${skip}&top=${top}`, {
        headers : {
          Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN_NAME),
        }
      });
      setLoading(false);
      result.data.data.forEach((element)=>{
        setuserEmail((old)=>[...old,{ value:element.UserEmail, label:element.UserEmail , name: element.UserName , ...element}])
      });
      return result.data.data;
    } catch(error) {
      setLoading(false);
    }
  }
  
  useEffect(() => {
    async function getOfficeLocation(){
      const result = await axios.get(API_BASE_URL + '/findAllOfficeLocation', {
        headers : {
          Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN_NAME),
        },
      });
      if (result.status === 200) {
        setLocation(result.data);
      }
    }

    const fetchAllRoles = async () => {
      const result = await axios.get(API_BASE_URL + '/userRoles', {
        headers : {
          Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN_NAME),
        },
      });
      console.log("allRoles", result)
      if (result.status === 200) {
        setRoles(result.data);
      }
    }
    fetchAllRoles()
    getOfficeLocation();
    
    async function fetchLocation() { 
      const result = await axios.get(
          API_BASE_URL + `/findAllSupplierLocation`,
          {
              headers : {
                  Authorization : "Bearer " + localStorage.getItem(ACCESS_TOKEN_NAME),
              }
          },
      );
      const data = [];
      if(result.status === 200){
          result.data.forEach(element => {
            data.push({id: element.id, name: element.SupplierLocation});
            console.log('pushed data',data);
          });
          SetSupplierLocation({options: data});
          console.log({options: data},'supplierLocation');
      }
    }   
    fetchLocation();
    async function fetchEmail () {
      let skip = 0;
      do {
        const result = await fetchEmailFromMethod(skip, 100);
        if(result.length == 0){
          break;
        }
        skip = skip + 100;
      }
      while (true);
    }
    fetchEmail();
  }, []);

  const onSelect = (selectedList, selectedItem) => {
    setState((prevState) => ({
      ...prevState,
      supplierLocations:selectedList,
    }));
  }

  const [putLocation, setputLocation] = useState({});

  const allLocation = async (e) => {
    if(e.target.checked){
      setputLocation(supplierLocation.options);
      setState((prevState) => ({
        ...prevState,
        supplierLocations: supplierLocation.options,
      }));

    } else {
      setputLocation({});
      setState((prevState) => ({
        ...prevState,
        supplierLocations: [],
      }));
    }
  }

  const handleChange = (e) => {
    const { name, value } = e.target;

    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    validate(name, value);
  };

  const validate = async (key, value) => {
    const result = await ResetServices.checkEmailForReset(value);
    setState((prevState) => ({
      ...prevState,
      errorMessage: {
        ...prevState.errorMessage,
        [key]: "",
      },
    }));
    switch (key) {
      case "email":
        let pattern = new RegExp(
          /^(("[\w-\s]+")|([+\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
        );
        if (!pattern.test(value)) {
          setState((prevState) => ({
            ...prevState,
            errorMessage: {
              ...prevState.errorMessage,
              email: "Please Enter valid Email",
            },
          }));
        } else if(result.emailexist) {
          setState((prevState) => ({
            ...prevState,
            errorMessage: {
              ...prevState.errorMessage,
              email: "Your email address is already registered with us",
            },
          }));
        }
        

        break;

      default:
    }
  };
  const handleCancel = (e) => {
    history.push("/userlist");
  }
  const handleSubmitClick = async (e) => {
    e.preventDefault();
    if (!state.name.length) {
      console.log('name====', state.name.length)
      setState((prevState) => ({
        ...prevState,
        errorMessage: {
          ...prevState.errorMessage,
          name: "Please Enter Name",
        },
      }));
    }

    if (state.mobile == "") {
      setState((prevState) => ({
        ...prevState,
        errorMessage: {
          ...prevState.errorMessage,
          mobile: "Please Enter Office Phone No",
        },
      }));
    } else if (state.mobile && state.mobile.length < 10) {
      setState((prevState) => ({
        ...prevState,
        errorMessage: {
          ...prevState.errorMessage,
          mobile: "Please Enter Validate Office Phone No",
        },
      }));
    }


    if (state.supplierLocations.length == 0) {
      setState((prevState) => ({
        ...prevState,
        errorMessage: {
          ...prevState.errorMessage,
          supplierLocations: "Please Select Supplier Location",
        },
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        errorMessage: {
          ...prevState.errorMessage,
          supplierLocations: "",
        },
      }));
    }

    if (state.officeLocation == "Select Office Location" ) {
      setState((prevState) => ({
        ...prevState,
        errorMessage: {
          ...prevState.errorMessage,
          officeLocation: "Please Select Office Location",
        },
      }));
    }

    validate("email", state.email);

    if (!state.role.length) {
      setState((prevState) => ({
        ...prevState,
        errorMessage: {
          ...prevState.errorMessage,
          role: "Please Enter Role",
        },
      }));
    }

    const result = await ResetServices.checkEmailForReset(state.email);

    if (
      state.name &&
      state.email &&
      state.officeLocation != "Select Office Location" && 
      state.officeLocation &&
      !result.emailexist &&
      state.officeLocation.length != 0 &&
      state.supplierLocations.length != 0 && 
      state.mobile && state.mobile.length >= 10 &&
      state.role
    ) {
      setState((prevState) => ({
        ...prevState,
        errorMessage: {},
      }));
      const payload = {
        name: state.name,
        email: state.email,
        mobile : state.mobile,
        recordID : state.recordID,
        secondaryMobile : state.secondaryMobile,
        officeLocation : state.officeLocation,
        supplierLocations: state.supplierLocations,
        role: state.role,
      };
      console.log("payload..", payload);
   
      let result = "test";
      axios
        .post(API_BASE_URL + "/sign_up", payload)
        .then(function (response) {
          console.log("sign_up response--->>", response);
          result = response;
          if (response.status === 200) {
            setState((prevState) => ({
              ...prevState,
            }));

            localStorage.setItem(ACCESS_TOKEN_NAME_USER, response.data.AccessToken);
            setState((prevState) => ({
              ...prevState,
              errorMessage: {},
            }));

            
            setTimeout(() => {
              toast.success('Users Added Successfully', {
                  autoClose: 2000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true
              });
          });
            props.history.push("/userlist");
          } else {
            setState((prevState) => ({
              ...prevState,
              errorMessage: "some error occurd",
            }));
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    } else {
      setState((prevState) => ({
        ...prevState,
        errorMessage: {
          ...prevState.errorMessage,
          commonError: "Please Fill All The Fields",
        },
      }));
    }
  }

  function handleLogout() {
    localStorage.removeItem(ACCESS_TOKEN_NAME);
    localStorage.removeItem('role');
    localStorage.removeItem('user');
    localStorage.removeItem(ACCESS_TOKEN_NAME_USER);
    props.history.push("/");
  }

  const [display, setdisplay] = useState('none');
  
  return (
    <>

    <div id="pcoded" className="pcoded">
        <div className="pcoded-overlay-box"></div>
        <div className="pcoded-container navbar-wrapper">
        <nav className="navbar header-navbar pcoded-header" style={{height:'55px', minHeight:'26px'}}> 
        <div className="navbar-wrapper">
            <div className="navbar-logo" style={{height: '52px'}}>
                <a style={{top:'7px'}} className="mobile-menu navabar_menu" id="mobile-collapse" href="#!">
                    <i class="ti-menu" style={{"color":" #000", "font-size" : "22px;"}}></i>
                </a>
                <a className="mobile-search morphsearch-search" href="#">
                    <i className="ti-search"></i>
                </a>
                <Link to="/dashboard">
                    <img style={{width:'30%'}} className="img-fluid logo-img" src={logo} alt="Theme-Logo" />
                </Link>
                <a className="mobile-options">
                    <i className="ti-more"></i>
                </a>
            </div>
            
            <div className="navbar-container container-fluid">
                <ul className="nav-right">
                    <li className="user-profile header-notification" style={{lineHeight: 3.5}}>
                          <a href="javascript:void(0)" onClick={()=>{
                              setdisplay((old)=>(old == 'none') ? 'block' : 'none')
                          }}>
                            <Avatar name={localStorage.getItem('user')} colors={['#ed3028', '#711999', '#383838']} size="40" round="20px" />
                          </a>
                        <ul className="show-notification profile-notification" style={{display:display}}>
                            <li>
                                <a href="#">
                                    <i className="ti-user"></i> Profile
                                </a>
                            </li>
                            <li>
                              <Link to="/resetpassword">
                                <i className="ti-layout-sidebar-left"></i> Change Password
                              </Link>
                            </li>
                            <li>
                                <a href="" onClick={() => handleLogout()}>
                                    <i className="ti-layout-sidebar-left"></i> Logout
                                </a>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
            
            <div className="pcoded-main-container">
                <div className="pcoded-wrapper">

                  <Sidebar />
                   
                    <div className="pcoded-content page_content">
                        <div className="pcoded-inner-content">
                            <div className="main-body">
                                <div className="page-wrapper">

                                    <div className="page-body">
                                        <div className="row">
                                            <div className="col-md-2">
                                                <h4 className="text-left" style={{ marginTop: "20px", fontSize: "large"}}>Add User</h4>
                                            </div>
                                            <div className="col-md-10">
                                                {/* <h4>Add User</h4> */}
                                            </div>
                                        </div>


                                        <div className="col-md-12 tabs-ui p-0">
                                        <div className="tab-content" id="nav-tabContent">
                                            <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">

                                           <form autoComplete="off">
                                           {/* {state.errorMessage.commonError && (
                                                    <span className="red">{state.errorMessage.commonError}</span>
                                                  )} */}
                                             <div className="row">

                                             <div className="col-md-6">
                                                  <div className="form-group text-left">
                                                    <label>Email</label>
                                                    <Select
                                                      placeholder="Type something or press enter..."
                                                      options={userEmail}
                                                      isLoading={loading}
                                                      onChange={(e)=>{
                                                        setState((old)=>{
                                                          return {
                                                              ...old,
                                                              name: e.UserName,
                                                              email: e.UserEmail,
                                                              mobile: (e.UserPhoneNumber) ? e.UserPhoneNumber?.replace("-","")?.replace("-","") : "",
                                                              recordID: e.RecordID
                                                          }
                                                        });
                                                      }}
                                                      getOptionLabel={option =><p>{option.name}<br/><br/>{option.label}<br/><br/></p>}
                                                    />
                                                    {state.errorMessage.email && (
                                                      <span className="red">{state.errorMessage.email}</span>
                                                    )}
                                                  </div>
                                                </div>

                                                <div className="col-md-6">
                                                  <div className="form-group text-left">
                                                    <label>Name</label>
                                                    <input
                                                      type="text"
                                                      name="name"
                                                      className="form-control"
                                                      id="name"
                                                      placeholder="Name"
                                                      value={state.name}
                                                      onChange={handleChange}
                                                    />
                                                    {state.errorMessage.name && (
                                                      <span className="red">{state.errorMessage.name}</span>
                                                    )}
                                                  </div>
                                                </div>
                                                <div className="col-md-6">
                                                  <div className="form-group text-left">
                                                    <label>Office Phone</label>
                                                    <InputMask
                                                      name="mobile"
                                                      className="form-control"
                                                      id="mobile"
                                                      placeholder="Office Phone"
                                                      value={state.mobile}
                                                      onChange={handleChange}
                                                      maxLength="10"
                                                    />
                                                    {state.errorMessage.mobile && (
                                                      <span className="red">{state.errorMessage.mobile}</span>
                                                    )}
                                                 </div>
                                                </div>
                                                <div className="col-md-6">
                                                  <div className="form-group text-left">
                                                    <label>Cell Phone</label>
                                                    <InputMask
                                                      name="secondaryMobile"
                                                      className="form-control"
                                                      id="mobile"
                                                      placeholder="Cell Phone"
                                                      value={state.secondaryMobile}
                                                      onChange={handleChange}
                                                      maxLength="10"
                                                    />
                                                    {state.errorMessage.secondaryMobile && (
                                                      <span className="red">{state.errorMessage.secondaryMobile}</span>
                                                    )}
                                                  </div>
                                                </div>
                                                <div className="col-md-6">
                                                  <div className="form-group text-left">
                                                  <label>Office Location</label>
                                                  <select
                                                    className="form-control"
                                                    name="officeLocation"
                                                    value={state.officeLocation}
                                                    onChange={handleChange}
                                                  >
                                                    <option disabled>Select Office Location</option>
                                                  {location &&
                                                          location.map((data, index) => ( 
                                                            <option key={data.id} value={data.id}>{data.officeName}</option>
                                                          ))}
                                                  </select>
                                                  {state.errorMessage.officeLocation && (
                                                      <span className="red">{state.errorMessage.officeLocation}</span>
                                                    )}
                                                  </div>
                                                </div>

                                                <div className="col-md-6">
                                                  <div className="form-group text-left">
                                                  <label>Role</label>
                                                  <select
                                                    className="form-control"
                                                    name="role"
                                                    value={state.role}
                                                    onChange={handleChange}
                                                  >
                                                    <option disabled>Select role</option>
                                                  {roles &&
                                                          roles.map((data, index) => ( 
                                                            <option key={data.id} value={data.id}>{data.roleName}</option>
                                                          ))}
                                                  </select>
                                                  {state.errorMessage.roleName && (
                                                      <span className="red">{state.errorMessage.roleName}</span>
                                                    )}
                                                  </div>
                                                </div>

                                                <div className="col-md-12">
                                                  <div className="form-group text-left">
                                                    <label>Supplier Location</label>
                                                      <Multiselect
                                                        options={supplierLocation.options}
                                                        onSelect={onSelect}
                                                        onChange={handleChange}
                                                        displayValue="name"
                                                        placeholder="Select Supplier"
                                                        selectedValues={putLocation}
                                                      />
                                                      {state.errorMessage.supplierLocations && (
                                                      <span className="red">{state.errorMessage.supplierLocations}</span>
                                                    )}
                                                  </div>


                                                  {
                                                      ( localStorage.getItem('role') == 1 && (
                                                        <>
                                                          <div className="form-group text-right">
                                                            <label>Apply All Location</label> &nbsp;
                                                            <input type="checkbox"
                                                             onClick={allLocation} 
                                                             />&nbsp;&nbsp;
                                                          </div>
                                                        </>
                                                      ))
                                                    }

                                                </div>

                                                

                                                <div className="col-md-12 cstm-add-btn" >
                                                  <button
                                                    type="button"
                                                    className="btn btn-primary mr-2 cstm-me-10"
                                                    onClick={handleSubmitClick}
                                                  >
                                                    Add
                                                  </button>
                                                  <button
                                                    type="button"
                                                    className="btn "
                                                    onClick={handleCancel}
                                                  >
                                                  Cancel
                                                  </button>
                                                  </div>

                                             </div>
                                             </form> 
                                          

                                                <div
                                                  className="alert alert-success mt-2"
                                                  style={{ display: state.successMessage ? "block" : "none" }}
                                                  role="alert"
                                                >
                                                  {state.successMessage}
                                                </div>

                                            </div>
                                          <div className="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">2</div>
                                          <div className="tab-pane fade" id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab">3</div>
                                      </div>
                                            
                                        </div>

                                       
                                    </div>
                                </div>
                                <ToastContainer />
                                <Footer />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> 
    </>
  );
}

export default withRouter(RegistrationForm);
