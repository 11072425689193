import { API_BASE_URL , ACCESS_TOKEN_NAME } from '../../constants/apiConstants';
import axios from 'axios';

export default class DeliveredServices {
    static async fetchDelivered() {
        const token = localStorage.getItem(ACCESS_TOKEN_NAME);
        const response = await axios.get(API_BASE_URL + "/findSingleOrder?status=11", {
          headers: {
            Authorization: "Bearer " + token,
          },
        });
        return response.data;
    }


    static async fetchDeliveredbyLocation(supplierLocation) {
      const token = localStorage.getItem(ACCESS_TOKEN_NAME);
      const response = await axios.get(API_BASE_URL + "/fetchDeliveredbyLocation?status=11&supplierLocation="+supplierLocation, {
        headers: {
          Authorization: "Bearer " + token,
        },
      });
      return response.data;
  }
}