import React, { useState, useEffect } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { ACCESS_TOKEN_NAME, ACCESS_TOKEN_NAME_USER, API_BASE_URL } from '../../constants/apiConstants';
import axios from 'axios'
import "../../images/icon/themify-icons/themify-icons.css";
import "../../images/js/script.js"
import "../../images/js/pcoded.min.js"
import "../../images/js/demo-12.js"
import "../../images/icon/icofont/css/icofont.css";
import logo from '../../images/logo.png';
import profilepic from '../../images/avatar-4.jpg';
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery';
import Navbar from "./../Layout/Navbar";
import Sidebar from "./../Layout/Sidebar";
import Footer from "./../Layout/Footer";
import swal from "sweetalert";
import './custom.css';
import Avatar from 'react-avatar';
import moment from 'moment';
import NumberFormate from '../../utils/NumberFormate';
import SyncComponent from '../Shared/SyncComponent';

function Vendor(props) {

    const [data, setData] = useState();
    const [loading, setLoading] = useState(false);
    const [display, setdisplay] = useState('none');
    const [pageNameList, setPageNameList] = useState('Carrier')

    const handleCheck = async (e) => {
        switch(e.target.value) {
            case "allActive":
                $('#myTable').DataTable().destroy();
                fetchCarrier("allActive");
              break;
            case "inactive":
                $('#myTable').DataTable().destroy();
                fetchCarrier("inactive");
              break;
            case "disqualified":
                $('#myTable').DataTable().destroy();
                fetchCarrier("disqualified");
            break;
            case "disActive":
                $('#myTable').DataTable().destroy();
                fetchCarrier("disActive");
                break;
            case "all":
                $('#myTable').DataTable().destroy();
                fetchCarrier("all");
            break;
          }
    }

    async function fetchCarrier(status = "allActive") {
        setLoading(true);
        const result = await axios.get(
            API_BASE_URL + "/findAllVendor?status="+ status,
            {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN_NAME),
                }
            },
        );
        if (result.status === 200) {
            setLoading(false);
            setData(result.data);
            $('#myTable').DataTable();
        }
    }

    useEffect(() => {
        fetchCarrier();
    }, []);



    function handleLogout() {
        localStorage.removeItem(ACCESS_TOKEN_NAME);
        localStorage.removeItem('role');
        localStorage.removeItem('user');
        localStorage.removeItem(ACCESS_TOKEN_NAME_USER);
        props.history.push("/");
    }

    /*
    const vendorInActive = async (id) => {
        const response = await axios.post(API_BASE_URL + `/vendorActiveDeactive?id=${id}`, {
            status : 0
        } , {
            headers: {
                Authorization : "Bearer " + localStorage.getItem(ACCESS_TOKEN_NAME),
            },
        });

        if (response.status == 200) {
            swal("Success!", "Vendor inactive successfully!");
            var table = $('#myTable').DataTable();
            table.destroy();
            fetchCarrier();
        }
    }

    const vendorActive = async (id) => {
        const response = await axios.post(API_BASE_URL + `/vendorActiveDeactive?id=${id}`, {
            status : 1
        } , {
            headers: {
                Authorization : "Bearer " + localStorage.getItem(ACCESS_TOKEN_NAME),
            },
        });

        if (response.status == 200) {
            swal("Success!", "Vendor active successfully!");
            var table = $('#myTable').DataTable();
            table.destroy();
            fetchCarrier();
        }
    }
    */

    return (
        <>
            <div id="pcoded" className="pcoded">
                <div className="pcoded-overlay-box"></div>
                <div className="pcoded-container navbar-wrapper">

                    <nav className="navbar header-navbar pcoded-header" style={{ height: '55px', minHeight: '26px' }}>
                        <div className="navbar-wrapper">
                            <div className="navbar-logo" style={{ height: '52px' }}>
                                <a style={{ top: '7px' }} className="mobile-menu navabar_menu" id="mobile-collapse" href="#!">
                                    <i class="ti-menu" style={{ "color": " #000", "font-size": "22px;" }}></i>
                                </a>
                                <a className="mobile-search morphsearch-search" href="#">
                                    <i className="ti-search"></i>
                                </a>
                                <Link to="/dashboard">
                                    <img style={{ width: '30%' }} className="img-fluid logo-img" src={logo} alt="Theme-Logo" />
                                </Link>
                                <a className="mobile-options">
                                    <i className="ti-more"></i>
                                </a>
                            </div>

                            <SyncComponent pageNameList={pageNameList} />
                        </div>
                    </nav>

                    <div className="pcoded-main-container">
                        <div className="pcoded-wrapper">

                            <Sidebar />

                            <div className="pcoded-content page_content">
                                <div className="pcoded-inner-content">
                                    <div className="main-body">
                                        <div className="page-wrapper">

                                            <div className="page-body">
                                                <div className="row">
                                                    <div className="col-md-8 col-xl-8">

                                                    </div>

                                                    <div className="col-md-2 col-xl-2">
                                                        <select onChange={e => handleCheck(e)} class="form-control">
                                                            <option value={"allActive"}>Active</option>
                                                            <option value={"inactive"}>Inactive</option>
                                                            <option value={"disqualified"}>Disqualified</option>
                                                            <option value={"disActive"}>Disqualified active</option>
                                                            <option value={"all"}>All</option>
                                                        </select>                                                    
                                                    </div>

                                                    <div className="col-md-2 col-xl-2">
                                                        <Link className="btn btn-primary btn-sm" to="/addvendor" style={{ color: "#ffffff" }}>Add Carrier</Link>
                                                    </div>
                                                </div>


                                                <div className="col-md-12 tabs-ui p-0">
                                                    <div className="tab-content" id="nav-tabContent">
                                                        <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">

                                                            {
                                                                (loading) ?
                                                                    <i class="fa fa-spinner fa-spin center-position" />
                                                                    :
                                                                    <div className='table-responsive'>
                                                                        <table id="myTable" className="table table-hover table-bordered" width="100%" data-page-length='50'>
                                                                            <thead>
                                                                                <tr>
                                                                                    <th>Carrier Name</th>
                                                                                    <th>Phone</th>
                                                                                    <th>Email</th>
                                                                                    {/* <th>Fax</th> */}
                                                                                    <th>City</th>
                                                                                    <th>State</th>
                                                                                    <th>Supplier Location</th>
                                                                                    <th>Notes</th>
                                                                                    <th>Trailer Size</th>
                                                                                    <th>Total of Drivers</th>
                                                                                    <th>Insurance Exp</th>
                                                                                    <th>DOT Number</th>
                                                                                    <th>MC Number</th>
                                                                                    <th>Safety Rating</th>
                                                                                    <th>Action</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {data &&
                                                                                    data.map((data, index) => (
                                                                                        <tr key={data.id}>
                                                                                            <td><Link to={`/vendordetails/${data.id}`}>{data.fullName}</Link></td>
                                                                                            <td><Link to={`/vendordetails/${data.id}`}>{NumberFormate(data.phone)}</Link></td>
                                                                                            <td><Link to={`/vendordetails/${data.id}`}>{data.email}</Link></td>
                                                                                            <td><Link to={`/vendordetails/${data.id}`}>{data.vendorAddressCity}</Link></td>
                                                                                            <td><Link to={`/vendordetails/${data.id}`}>{data.vendorAddressState}</Link></td>
                                                                                            <td><Link to={`/vendordetails/${data.id}`}>{data.allSupplierLocations}</Link></td>
                                                                                            <td><Link to={`/vendordetails/${data.id}`}>{data.notes}</Link></td>
                                                                                            <td><Link to={`/vendordetails/${data.id}`}>{data.trailerSize}</Link></td>
                                                                                            <td><Link to={`/vendordetails/${data.id}`}>{data.count}</Link></td>
                                                                                            <td><Link to={`/vendordetails/${data.id}`}>{(data.insuranceExpirationDate) ? moment(data.insuranceExpirationDate).format('MM/DD/YYYY') : ''}</Link></td>
                                                                                            <td><Link to={`/vendordetails/${data.id}`}>{data.usDOTNumber}</Link></td>
                                                                                            <td><Link to={`/vendordetails/${data.id}`}>{data.mcNumber}</Link></td>
                                                                                            <td><Link to={`/vendordetails/${data.id}`}>{data.safetyRating}</Link></td>
                                                                                            <td colSpan="2" className="d-flex justify-content-center">

                                                                                                <Link to={`/vendordetails/${data.id}`} >
                                                                                                    <i className="fa fa-eye text-primary iconbaraction" data-toggle="tooltip" data-placement="top" title="View"></i>
                                                                                                </Link>
                                                                                                {/*
                                                                                (data.isActive == 1) ?
                                                                                    <i onClick={()=>vendorInActive(data.id)} className="fa fa-trash text-danger iconbaraction" data-toggle="tooltip" data-placement="top" title="Deactivate" aria-hidden="true"></i>
                                                                                :
                                                                                <i onClick={()=>vendorActive(data.id)} className="fa fa-check-circle text-primary iconbaraction" data-toggle="tooltip" data-placement="top" title="Activate" aria-hidden="true"></i>
                                                                            */}
                                                                                            </td>
                                                                                        </tr>
                                                                                    ))}
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                            }

                                                        </div>
                                                        <div className="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">2</div>
                                                        <div className="tab-pane fade" id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab">3</div>

                                                    </div>
                                                </div>

                                                <Footer />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <style jsx="true">{`
                                                table.dataTable thead th, table.dataTable thead, th, td{
                                                    font-family: 'Source Sans Pro', sans-serif;
                                                  }
                                                  table.dataTable thead th, table.dataTable thead, td a {
                                                      font-size:12px !important;
                                                  }
                                                  a.btn{
                                                    border:none;
                                                    border-radius:5px;
                                                  }
  
                                                  table.dataTable thead th, table.dataTable thead th {
                                                      font-size: 12px !important;
                                                      font-weight: 600;
                                                      background: #fbf5f5;
                                                  }
                                            `}</style>
            </div>

        </>
    )
}

export default withRouter(Vendor);