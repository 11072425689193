import React, { Component } from 'react';
import moment from 'moment-timezone';
import { Formik } from 'formik';
import Input from '../Shared/form/Input';
import Time from '../Shared/form/Time';
import Textarea from '../Shared/form/Textarea';
import Checkbox from '../Shared/form/Checkbox';
import Radio from '../Shared/form/Radio';
import Date from '../Shared/form/Date';
import DateTime from '../Shared/form/DateTime';
import Select from '../Shared/form/Select';
import Vendor from '../Shared/form/Vendor';
import Driver from '../Shared/form/Driver';
import PromiseTimeLine from '../Shared/form/PromiseTimeLine';
import model from './ResalePurchaseOrderModel';
import FormSchema from '../Shared/form/formSchema';
import PurchaseFormServices from './Services/PurchaseFormServices'
import PurchaseOrderServices from './Services/PurchaseOrderServices'
import ResalePurchaseOrderTable from './ResalePurchaseOrderTable';
import swal from "sweetalert";
import DispachedServices from '../Dispached/DispachedServices';
import Dropzone from '../Shared/form/Dropzone';
import InvoiceServices from '../Invoice/Services/InvoiceServices';
import jstz from 'jstz';
import { ToastContainer, toast } from 'react-toastify';
import './Style/table.css';
import { productPurchaseOption, shipTo, invoiceLinkedTo, releasePOLinked, supplierLocation, shipedMehtod, promiseTimeLines, containerOrientation } from './Option/AllOption';
const { fields } = model;


class PurchaseForm extends Component {
    schema = new FormSchema(fields.id, [
        fields.purchaseOrder,
        fields.supplierVendor,
        fields.pickupLocation,
        fields.productService,
        fields.vendor,
        fields.driver,
        fields.vendorid,
        fields.releaseNumber,
        fields.date,
        fields.shipTo,
        fields.invoiceLinkedTo,
        fields.resalePOLinkedTo,
        fields.releaseDate,
        fields.customerPhone,
        fields.releasePOLinked,
        fields.supplierLocation,
        fields.actualPickupDate,
        fields.customerALtPhone,
        fields.shipedMehtod,
        fields.promiseTimeLine,
        fields.promiseDate,
        fields.containerOrientation,
        fields.expectedDate,
        fields.scheduledDeleiveryDate,
        fields.expected,
        fields.refounded,
        fields.depositcallCompleted,
        fields.from,
        fields.to,
        fields.deleiveryStatus,
        fields.actualDeleiveryDate,
        fields.billing,
        fields.shipping,
        fields.totalAmount,
        fields.rate,
        fields.container,
        fields.memo,
        fields.messages,
        fields.shipline1,
        fields.shipline2,
        fields.shipline3,
        fields.shipline4,
        fields.shipline5,
        fields.shipcity,
        fields.shipstate,
        fields.shipzip,
        fields.shipcountry,
        //fields.attachMent,
    ]);

    constructor(props) {
        super(props);
        this.state = {
            customerNote: null,
            vendorNote: null,
            dispatchNote: null,
            Note: null,
            customerNoteCollection: "",
            vendorNoteCollection: "",
            dispatchNoteCollection: "",
            purchasingNoteCollection: "",
            pickupLocation: {},
            vendorOption: [],
            driverOption: [],
            selectedVendor: {},
            fetchloading: false,
            vendorloading: false,
            driverloading: false,
            loading: false,
            data: [],
            fuelSurcharge: [],
            orderstatus: null,
            vendorData: [],
            //billing: null,
            //shipping: null,
            depotChecked: false,
            billingTextbox: false,
            shippingTextbox: false,
            rateChange: false,
            vendorChage: false,
            purchaseOrderStatus: false,
            rate: 0
        }
        this.deliveryNotesRef = React.createRef();
    }

    

    cancelDispatched = async () => {
        if (this.state.data.transportPORecordID == null || this.state.data.transportPORecordID == 0) {
            return false;
        }
        const result = await PurchaseOrderServices.getPurchasedOrderByrecordID(this.state.data.transportPORecordID);
        if (result[0].purchaseOrderStatus == 'Closed') {
            swal("Success!", "This Transport PO is closed, so you can't delete it. If you need to make changes, then let the accounting team know", "success");
        } else {
            swal({
                title: "Warning!",
                text: "Are you sure want to delete purchase order?",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then(async (willDelete) => {
                    if (willDelete) {
                        const response = await PurchaseOrderServices.updateTransportPOStatus(this.state.data.transportPORecordID);
                        if (response) {
                            PurchaseOrderServices.updatePlanningOrderStatus(this.state.data?.invoicePorecordID, 7);
                            swal("Success!", "Cancel dispatched on " + this.state.data.pdate, "success");
                            setTimeout(function () {
                                window.location.href = "/driverPlanning";
                            }, 2000);
                        }

                    }
                });
        }
    }



    fetchFuelSurcharge = async () => {
        try {
            const resultinfo = await PurchaseFormServices.fetchFuelSurcharge();

            console.log(resultinfo, 'resultinfo');

            this.setState({
                fuelSurcharge: resultinfo
            });
        } catch (errors) {
            console.log(errors, "sdadsasds")
        }
    }


    handleSubmit = async (values) => {

        values.deposit_call_completed = (this.state?.depotChecked) ? true : false;
        values.expedited = (this.state.data?.isExpedited) ? true : false;
        values.refounded = (this.state.data?.isRefunded) ? true : false;


        values.customernote = this.state.customerNoteCollection;
        values.vendornote = this.state.vendorNoteCollection;
        values.dispatchnote = this.state.dispatchedNoteCollection;
        values.purchasingnote = this.state.purchasingNoteCollection;
      


        values.record_id = this.state.data?.invoiceLinkedTo
        values.transportPORecordID = this.state.data?.transportPORecordID
        values.shipAddressAddr1 = this.state.data?.shipAddressAddr1
        values.shipAddressAddr2 = this.state.data?.shipAddressAddr2
        values.shipAddressAddr3 = this.state.data?.shipAddressAddr3
        values.shipAddressCity = this.state.data?.shipAddressCity
        values.shipAddressState = this.state.data?.shipAddressState
        values.shipAddressPostalCode = this.state.data?.shipAddressPostalCode
        values.shipAddressCountry = this.state.data?.shipAddressCountry
        values.billAddressAddr1 = this.state.vendorData?.vendorAddressAddr1
        values.billAddressAddr2 = this.state.vendorData?.vendorAddressAddr2
        values.billAddressAddr3 = this.state.vendorData?.vendorAddressAddr3
        values.billAddressCity = this.state.vendorData?.vendorAddressCity
        values.billAddressState = this.state.vendorData?.vendorAddressState
        values.billAddressPostalCode = this.state.vendorData?.vendorAddressPostalCode
        values.billAddressCountry = this.state.vendorData?.vendorAddressCountry
        values.amount = (this.state.rateChange) ? this.state.rate : this.state.data.totalAmount
        values.quantity = 1
        values.rate = (this.state.rateChange) ? this.state.rate : this.state.data.totalAmount
        values.pickupLocationName = this.state.data?.pickupLocationName
        values.pickupCity = this.state.data?.pickupCity
        values.pickupState = this.state.data?.pickupState
        values.distance = this.state.data?.distance
        values.pickupPerMileCost = this.state.data?.pickupPerMileCost
        values.pickupAddress1 = this.state.data?.pickupAddress1
        values.pickupMinimumRate = this.state.data?.pickupMinimumRate
        values.pickupZipcode = this.state.data?.pickupZipcode
        values.pickupBudget = this.state.data?.pickupBudget
        values.pickupPhone = this.state.data?.pickupPhone
        values.pickupEmail = this.state.data?.pickupEmail
        values.customer = this.state.data?.customer
        values.resalePOLinkedToRecordId = this.state.data?.resalePOLinkedToRecordId
        values.pickupLocationRecordId = this.state.pickupLocation?.recordID
        values.resalePORecordId = this.state.data?.invoicePorecordID;
        var status = (this.state.data.transportPORecordID) ? "update" : "create";
        await swal({
            title: "Are you sure ?",
            text: "Are you sure want to " + status + " purchase order?",
            icon: "warning",
            dangerMode: true,
        })
            .then(async willCreate => {
                if (willCreate) {
                    try {
                        this.setState({
                            loading: true
                        });
                        const result = await PurchaseOrderServices.createOrUpdatePurchaseOrder(values);
                        const { match } = this.props;
                        if (result) {
                            await PurchaseOrderServices.updatePurchaseOrderStatus(this.state.data?.invoicePorecordID, 10);
                            await PurchaseFormServices.upldatePlanningDriver(values.driver, match.params.id);
                            //PurchaseOrderServices.notifyToDriver(this.state.data?.invoicePorecordID, this.state.selectedVendor.driverid, this.props.dateObject, values.deliveryNotes, values.messages);
                            this.setState({
                                loading: false
                            });
                            this.fetchDate();
                            swal("Success!", result.messages, "success");
                        }
                    } catch (err) {
                        this.setState({
                            loading: false
                        });
                        console.log(err, 'createOrUpdatePurchaseOrder');
                        swal("Oops!", "Seems like have some error", "error");
                    }
                }
            });

    };

    fetchAllNotes= async () => {
        const { match } = this.props;
        const data = await InvoiceServices.getAllNotes(match.params.id,match.params.invoiceRecordID)
        const InvoiceDispatchNotes = data.dispatchNotes?.replace('null', '')?.replace('undefined', '');
        const InvoiceVendorNotes = data.vendorNotes?.replace('null', '')?.replace('undefined', '');
        const resalePOCustomerNotes = data.invoiceCustomerNotes?.replace('null', '')?.replace('undefined', '');
        const purchasingNoteCollection = data.purchasingNotes?.replace('null', '')?.replace('undefined', '');
        if(resalePOCustomerNotes && this.state.customerNoteCollection != resalePOCustomerNotes)
        {
            this.setState({
                customerNoteCollection: resalePOCustomerNotes
            })
            console.log('Value Changed of Customer Notes')
        }
        if(InvoiceVendorNotes && this.state.vendorNoteCollection != InvoiceVendorNotes)
        {
            this.setState({
                vendorNoteCollection: InvoiceVendorNotes
            })
            console.log('Value Changed of Vendor Notes')
        }
        if(InvoiceDispatchNotes && this.state.dispatchedNoteCollection != InvoiceDispatchNotes)
        {
            this.setState({
                dispatchedNoteCollection: InvoiceDispatchNotes
            })
            console.log('Value Changed of Dispatch Notes')
        }
        if(purchasingNoteCollection && this.state.purchasingNoteCollection != purchasingNoteCollection)
        {
            this.setState({
                purchasingNoteCollection: purchasingNoteCollection
            })
            console.log('Value Changed of Dispatch Notes')
        }
        console.log('hhhhhhhhhhhhhhhhhhhsssssssshhhhhhhhhsss', data);
     
       

    }   

    fetPlanningDriverinfo = async () => {
        const { match } = this.props;

        this.setState(state => {
            return {
                ...state,
                vendorloading: true,
            }
        });

        const response = await PurchaseFormServices.getPlanningDriverinfo(match.params.id);
        if (response.length != 0) {
            const vendorObject = await PurchaseFormServices.getVendorById(response[0]?.id);
            this.fetchAllDriverByVendor(response[0]?.id);
            this.setState(state => {
                return {
                    ...state,
                    selectedVendor: { value: response[0]?.name, label: response[0]?.companyName, name: response[0]?.name, vendorid: response[0]?.id, driverid: response[0]?.driverId },
                    vendorloading: false,
                    vendorData: vendorObject[0]
                }
            });
        } else {
            this.setState(state => {
                return {
                    ...state,
                    vendorloading: false,
                }
            });
        }
    }

    showTextBox = () => {
        this.setState((state) => {
            if (state.shippingTextbox) {
                return {
                    ...state,
                    shippingTextbox: false
                }
            } else {
                return {
                    ...state,
                    shippingTextbox: true
                }
            }
        });
    }

    emailTrigger = async () => {
        const deliveryNotes = document.getElementsByName("deliveryNotes")[0].value;
        const messages = document.getElementsByName("messages")[0].value;
        const response = await PurchaseOrderServices.notifyToDriver(this.state.data?.invoicePorecordID, this.state.selectedVendor.driverid, this.props.dateObject, deliveryNotes, messages);
        if (response) {
            swal("Success!", "Mail has triggered to the vendor and driver", "success");
        }
    }

    updateShipping = async () => {
        try {
            await InvoiceServices.updateShipping({
                shipAddressAddr1: document.getElementsByName("shipline1")[0].value,
                shipAddressAddr2: document.getElementsByName("shipline2")[0].value,
                shipAddressAddr3: document.getElementsByName("shipline3")[0].value,
                shipAddressAddr4: document.getElementsByName("shipline4")[0].value,
                shipAddressAddr5: document.getElementsByName("shipline5")[0].value,
                shipAddressCity: document.getElementsByName("shipcity")[0].value,
                shipAddressCountry: document.getElementsByName("shipcountry")[0].value,
                shipAddressPostalCode: document.getElementsByName("shipzip")[0].value,
                shipAddressState: document.getElementsByName("shipstate")[0].value
            }, this.state.data.invoicePorecordID);
            this.fetchDate();
            this.setState((state) => {
                return {
                    ...state,
                    shippingTextbox: false
                }
            });
        } catch (error) {
            console.log(error.message, 'message');
        }
    }

    fetchDate = async () => {
        this.setState(state => {
            return {
                ...state,
                fetchloading: true,
            }
        });
        const { match } = this.props;
        await this.fetchFuelSurcharge();
        const result = await InvoiceServices.getAllNotes(match.params.id,match.params.invoiceRecordID)
        const response = await PurchaseFormServices.getData(match.params.id);
        var shippingObject = (response[0]?.shipAddressAddr1) ? response[0]?.shipAddressAddr1 : ' ' + ' ' + (response[0]?.shipAddressAddr2) ? response[0]?.shipAddressAddr2 : ' ' + ' ' + (response[0]?.shipAddressAddr3) ? response[0]?.shipAddressAddr3 : ' ' + ' ' + (response[0]?.shipAddressCity) ? response[0]?.shipAddressCity : ' ' + ' ' + (response[0]?.shipAddressState) ? response[0]?.shipAddressState : ' ' + ' ' + (response[0]?.shipAddressPostalCode) ? response[0]?.shipAddressPostalCode : ' ' + ' ' + (response[0]?.shipAddressCountry) ? response[0]?.shipAddressCountry : ' ';
        var customerNote = response[0]?.customerDetailNotes;
        var vendorNote = response[0]?.vendorDetailNotes;
        var dispatchNote = response[0]?.dispatchDetailNotes;

        if (response.length) {
            //this.checkOrderStatus();
            this.setState(state => {
                const { customerNoteCollection, dispatchNoteCollection, vendorNoteCollection, purchasingNoteCollection } = state;

                return {
                    ...state,
                    shipping: shippingObject,
                    data: response[0],
                    fetchloading: false,
                    rate: response[0]?.totalAmount,
                    depotChecked: response[0]?.isLastDepotCallCompleted,
                    customerNoteCollection: result?.invoiceCustomerNotes,
                    vendorNoteCollection: result?.vendorNotes,
                    purchasingNoteCollection: result?.purchasingNotes,
                    dispatchedNoteCollection: result?.dispatchNotes,
                    resalePOIsDepotCallCompleted: result?.resalePOIsDepotCallCompleted
                }
            });

            this.fetchPickupLocation();
            this.fechFinalizeStatus();
        }
    }

    handleDepot = async (e) => {
        let isChecked = e.target.checked;
        if (isChecked) {
            this.setState({
                depotChecked: true
            })
        } else {
            this.setState({
                depotChecked: false
            })
        }

    }

    checkOrderStatus = async () => {
        const result = await PurchaseFormServices.getPurchaseOrderStatus(this.state.data?.recordID);
        this.setState(state => {
            return {
                ...state,
                orderstatus: result.status,
            }
        });

        if (result.status) {
            swal("Purchase order has been created.........");
        }
    }


    fetallOption = async () => {
        const response = await PurchaseFormServices.getVendor();
        const option = new Array();
        for (var i = 0; i < response.length; i++) {
            if (response[i].name) {
                option.push({ value: response[i].name, label: response[i].companyName, name: response[i].name, vendorid: response[i].id });
            }
        }
        this.setState(state => {
            return {
                ...state,
                vendorOption: option
            }
        });
    }

    fetchAllDriverByVendor = async (id) => {

        this.setState(state => {
            return {
                ...state,
                driverloading: true
            }
        });

        const response = await PurchaseFormServices.fetchAllDriverByVendor(id);
        const option = new Array();
        for (var i = 0; i < response.length; i++) {

            if (response[i].name) {
                option.push({ value: response[i].id, label: response[i].name });
            }
        }

        this.setState(state => {
            return {
                ...state,
                driverOption: option,
                driverloading: false
            }
        });
    }

    fetchPickupLocation = async () => {
        try {
            
            const response = await PurchaseFormServices.getPicklocationByName(this.state.data?.pickupLocationName, this.state.data?.pickupAddress1);
            console.log("harihss",response[0])
            this.setState({
                pickupLocation: response[0]
            })
        } catch (error) {

        }
    }

    handleFinalizeDelivery = async () => {
        if (this.state.data.transportPORecordID == null || this.state.data.transportPORecordID == 0) {
            return false;
        }
        swal({
            text: "Are you sure you want to finalize the order?",
            icon: "warning",
            dangerMode: true,
            buttons: true,
        }).then(async (status) => {
            try {
                let result = await PurchaseOrderServices.getContainerNumber(this.state.data.transportPORecordID);
                if (result.filter((item) => item.containerNumber == "" || item.containerNumber == null).length == 1) {
                    swal("Please make sure all the containers numbers are added before finalizing order");
                } else {

                    let result = await PurchaseOrderServices.getPurchasedOrderByrecordID(this.state.data.transportPORecordID);

                    if (result[0].pickupDate == null || result[0].pickupDate == "" || result[0].deliveryDate == null || result[0].deliveryDate == "") {
                        swal("Please make sure pickup and delivery dates are added before finalizing order");
                    } else {

                        let billingRecordId = await PurchaseOrderServices.doAddBillingToMethod({ vendor: this.state.selectedVendor.name, pordeRefNumber: this.state.data.pordeRefNumber, vendorid: this.state.selectedVendor.vendorid });

                        if (billingRecordId) {
                            PurchaseOrderServices.doAddPurchaseOrderLineToMethod({ billingRecordID: billingRecordId, recordID: this.state.data.transportPORecordID }).then((item) => {
                                this.fechFinalizeStatus();
                                swal("Your bill has been successfully created");
                            });
                        }

                    }
                }
            } catch (error) {
                console.log("doAddBillingToMethod error" + error);
            }
        });
    }


    fechFinalizeStatus = async () => {
        if (this.state.data.transportPORecordID == null || this.state.data.transportPORecordID == 0) {
            return false;
        }
        try {
            const response = await PurchaseOrderServices.getPurchasedOrderByrecordID(this.state.data.transportPORecordID);
            this.setState((old) => {
                return {
                    ...old,
                    purchaseOrderStatus: response[0]?.purchaseOrderStatus
                }
            })
        } catch (error) {
            console.log(error, 'fechFinalizeStatus');
        }
    }

    componentDidMount() {
        this.fetallOption();
        //this.fetPlanningDriverinfo();
        this.fetchDate();
    }

    customerNote = async () => {
        const { match } = this.props;
        const timezone = jstz.determine();
        if (this.state.customerNote == null) {
            return false;
        }
       await this.fetchAllNotes();

        await this.setState(state => {
            const { customerNote, customerNoteCollection } = state;
            return {
                ...state,
                customerNote: null,
                customerNoteCollection: customerNote + ' - ' + localStorage.getItem('user') + ' ' + '(' + moment(this.props.dateObject).tz(timezone.name()).format('MM/DD/YYYY  HH:mm A') + ')\n' + customerNoteCollection
            }
        });

        await InvoiceServices.updateCustomerNote({ resaleporecordID: match.params.id, category: 'rpo', type: 'customernote', customer_note: this.state.customerNoteCollection }, match.params.invoiceRecordID);
        document.getElementById('customerinput').value = null
        setTimeout(() => {
            toast.success('Customer note updated successfully', {
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true
            });
        });

        //document.getElementById('customerinput').value = null
    }


    vendorNote = async () => {
        const { match } = this.props;
        const timezone = jstz.determine();
        if (this.state.vendorNote == null) {
            return false;
        }
        await this.fetchAllNotes();

        await this.setState(state => {
            const { vendorNote, vendorNoteCollection } = state;
            return {
                ...state,
                vendorNote: null,
                vendorNoteCollection: vendorNote + ' - ' + localStorage.getItem('user') + ' ' + '(' + moment(this.props.dateObject).tz(timezone.name()).format('MM/DD/YYYY  HH:mm A') + ')\n' + vendorNoteCollection
            }
        });

        console.log("ttttt");

        await InvoiceServices.updateVendorNote({ resaleporecordID: match.params.id, category: 'rpo', type: 'vendornote', vendor_note: this.state.vendorNoteCollection },match.params.invoiceRecordID);
        document.getElementById('vendorinput').value = null
        setTimeout(() => {
            toast.success('Vendor note updated successfully', {
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true
            });
        });
    }

    purchasingNote = async () => {
        console.log('this.state.purchasingNote', this.state.purchasingNote)
        const { match } = this.props;
        const timezone = jstz.determine();
        if (this.state.purchasingNote == null) {
            return false;
        }
        await this.fetchAllNotes();

        await this.setState(state => {
            const { purchasingNote, purchasingNoteCollection } = state;
            return {
                ...state,
                purchasingNote: null,
                purchasingNoteCollection: purchasingNote + ' - ' + localStorage.getItem('user') + ' ' + '(' + moment(this.props.dateObject).tz(timezone.name()).format('MM/DD/YYYY  HH:mm A') + ')' + (purchasingNoteCollection ? '\n' +  purchasingNoteCollection : '')
            }
        });

        console.log('sssssssss', this.state.purchasingNoteCollection)

        await InvoiceServices.updatePurchasingNote({ resaleporecordID: match.params.id, category: 'rpo', type: 'purchasingnote', purchasing_note: this.state.purchasingNoteCollection }, match.params.invoiceRecordID);
        document.getElementById('purchasingNote').value = null
        setTimeout(() => {
            toast.success('Purchasing note updated successfully', {
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true
            });
        });
    }

    dispatchNote = async () => {
        const { match } = this.props;
        const timezone = jstz.determine();
        if (this.state.dispatchNote == null) {
            return false;
        }
        await this.fetchAllNotes();

        await this.setState(state => {
            const { dispatchNote, dispatchedNoteCollection } = state;
            return {
                ...state,
                dispatchNote: null,
                dispatchedNoteCollection: dispatchNote + ' - ' + localStorage.getItem('user') + ' ' + '(' + moment(this.props.dateObject).tz(timezone.name()).format('MM/DD/YYYY  HH:mm A') + ')\n' + dispatchedNoteCollection
            }
        });

        await InvoiceServices.updateDispatchNote({ resaleporecordID: match.params.id, category: 'rpo', type: 'dispatchednote', dsipatch_note: this.state.dispatchedNoteCollection }, match.params.invoiceRecordID);
        document.getElementById('dispatchinput').value = null
        setTimeout(() => {
            toast.success('Dispatch note updated successfully', {
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true
            });
        });
    }



    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    handleRate = (e) => {
        this.setState({
            rate: e.target.value,
            rateChange: true
        });
    }

    recevedVendorinfo = async (response, vendorid) => {
        this.fetchAllDriverByVendor(vendorid);
        this.setState({
            vendorData: response[0],
            vendorChage: true,
            selectedVendor: { value: response[0]?.fullName, label: response[0]?.companyName, name: response[0]?.fullName, vendorid: response[0]?.id, driverid: response[0]?.driverId },
        });
    }


    initialValues = () => {
        return this.schema.initialValues({
            [fields.productService.name]: this.state.data?.co1ProductService,
            [fields.supplierVendor.name]: this.state.data?.supplierVendorInvoiceNumber,
            [fields.customerPhone.name]: this.state.data?.shipToEntityMainPhone,
            [fields.customerALtPhone.name]: this.state.data?.shipToEntityAltPhone,
            [fields.pickupLocation.name]: this.state.data?.pickupLocation,
            [fields.releaseNumber.name]: this.state.data?.releaseNumber,
            [fields.date.name]: (this.state.data?.txnDate) ? this.state.data?.txnDate.split('T')[0] : '',
            [fields.supplierLocation.name]: this.state.data?.supplierLocation,
            [fields.releaseNumber.name]: this.state.data?.releaseNumber,
            [fields.shipedMehtod.name]: this.state.data?.shipMethodMethodField,
            [fields.shipTo.name]: this.state.data?.shipToEntity,
            [fields.invoiceLinkedTo.name]: this.state.data?.invoiceLinkedTo,
            [fields.containerOrientation.name]: this.state.data?.containerOrientation,
            [fields.vendor.name]: this.state.data?.vendor,
            [fields.promiseTimeLine.name]: this.state.data?.promiseDateTimeline,
            [fields.releaseDate.name]: (this.state.data?.releaseDate) ? moment(this.state.data?.releaseDate.split('T')[0]).format('MM/DD/YYYY') : '',
            [fields.actualPickupDate.name]: (this.state.data?.pickupDate) ? this.state.data?.pickupDate.split('T')[0] : '',
            [fields.purchaseOrder.name]: this.state.data?.refNumber,
            [fields.expectedDate.name]: (this.state.data?.expectedDate) ? moment(this.state.data?.expectedDate.split('T')[0]).format('MM/DD/YYYY') : '',
            [fields.scheduledDeleiveryDate.name]: (this.state.data?.scheduledDate) ? moment(this.state.data?.scheduledDate.split('T')[0]).format('MM/DD/YYYY') : '',
            [fields.deleiveryStatus.name]: this.state.data?.deliveryStatus,
            [fields.actualDeleiveryDate.name]: (this.state.data?.deliveryDate) ? moment(this.state.data?.deliveryDate.split('T')[0]).format('MM/DD/YYYY') : '',
            [fields.promiseDate.name]: (this.state.data?.promiseDate) ? this.state.data?.promiseDate.split('T')[0] : '',
            [fields.rate.name]: this.state.data?.totalAmount,
            [fields.totalAmount.name]: this.state.data?.totalAmount,
            [fields.memo.name]: this.state.data?.memo,
            [fields.messages.name]: this.state.data?.vendorMsg,
            [fields.from.name]: (this.state.data?.scheduledDeliveryTimeFrom) ? this.state.data?.scheduledDeliveryTimeFrom.split('T')[1].split('.')[0] : '',
            [fields.to.name]: (this.state.data?.scheduledDeliveryTimeTo) ? this.state.data?.scheduledDeliveryTimeTo.split('T')[1].split('.')[0] : ''
        });
    };


    render() {
        console.log("ASDA",this.state.pickupLocation?.minimumRate+'miles'+this.state.pickupLocation?.perMilePrice+'dist'+this.state.data.distanceBetweenPickupAndDeliveryAddress)
        //this.state.data.distanceBetweenPickupAndDeliveryAddress * this.state.pickupLocation?.perMilePrice
        if (this.state.fetchloading) {
            return (<i class="fa fa-spinner fa-spin center-position" />);
        }
        const timezone = jstz.determine();

        var minRate = this.state.pickupLocation?.minimumRate;
        var distance = this.state.data?.distance;
        var perMile = this.state.pickupLocation?.perMilePrice;
        var totalBuget = distance * perMile;
        var totalBugetPrice = Math.max(minRate, totalBuget);

        return (
            <>
                <div className="row">
                    <div className="col-sm-6">
                        <h2><i className="fa fa-file-text icon-file-text" /> Purchase Order : {this.state.data?.refNumber}</h2>
                        <ul className="list-item-purchase">
                            <li>{this.state.vendorData.fullName}</li>
                        </ul>
                    </div>
                    <div className="col-sm-6 total-amount-text">
                        <p>Total Amount: {this.state.data.totalAmount?.toFixed(2)}</p>
                    </div>
                </div>
                <ToastContainer />
                <Formik
                    initialValues={this.initialValues()}
                    onSubmit={this.handleSubmit}
                    validationSchema={this.schema.schema}
                >
                    {props => (
                        <form onSubmit={props.handleSubmit} className="purchase-order-form">
                            <div className="purchase-order-form-list">
                                <ul>
                                    <li>
                                        <Input
                                            placeholder={'193329T-1'}
                                            name={fields.productService.name}
                                            label={fields.productService.label}
                                            disabled={true}
                                        />

                                        <Input
                                            placeholder={'193329T-1'}
                                            name={fields.vendor.name}
                                            label={fields.vendor.label}
                                            disabled={true}
                                        />
                                        {
                                            /*  
                                                <Driver
                                                    name={fields.driver.name}
                                                    label={fields.driver.label}
                                                    placeholder={'Slect Driver'}
                                                    option={this.state.driverOption}
                                                    defaultValue={this.state.selectedVendor}
                                                    value={this.state.selectedVendor.value}
                                                    vendorChage={this.state.vendorChage}
                                                />
                                            */
                                        }

                                        <Input
                                            name={fields.shipTo.name}
                                            label={fields.shipTo.label}
                                            placeholder={'Slect Ship To'}
                                            disabled={true}
                                        />

                                        <Input
                                            placeholder={'Enter Customer Phone Number'}
                                            name={fields.customerPhone.name}
                                            label={fields.customerPhone.label}
                                            disabled={true}
                                        />

                                        <Input
                                            placeholder={'Enter Customer Phone Number'}
                                            name={fields.customerALtPhone.name}
                                            label={fields.customerALtPhone.label}
                                            disabled={true}
                                        />

                                        <div className="form-group">

                                            <label for="ServicePurchase"></label>
                                            <div className="billing-input-icon">Billing

                                            </div>

                                            <p class="address-text">
                                                {
                                                    this.state.data?.billAddressAddr1
                                                }
                                                <br />
                                                {
                                                    (this.state.data?.billAddressAddr2) ?
                                                        this.state.data?.billAddressAddr2 :
                                                        ''
                                                }

                                                {
                                                    (this.state.data?.billAddressAddr3) ?
                                                        this.state.data?.billAddressAddr3 :
                                                        ''
                                                }
                                                {this.state.data?.billAddressCity} , &nbsp;
                                                {this.state.data?.billAddressState}<br />
                                                {this.state.data?.billAddressPostalCode}<br />
                                                {this.state.data?.billAddressCountry}<br />
                                            </p>
                                        </div>

                                        <div className="form-group">
                                            <label for="ServicePurchase"></label>
                                            <div className="billing-input-icon">Shiping
                                            </div>

                                            {this.state.shippingTextbox
                                                ?
                                                <>
                                                    <Input
                                                        name={fields.shipline1.name}
                                                    />

                                                    <Input
                                                        name={fields.shipline2.name}
                                                    />

                                                    <Input
                                                        name={fields.shipline3.name}
                                                    />

                                                    <Input
                                                        name={fields.shipline4.name}
                                                    />

                                                    <Textarea
                                                        name={fields.shipline5.name}
                                                    />


                                                    <div class="row">
                                                        <div class="col-sm-6">

                                                            <Input
                                                                name={fields.shipcity.name}
                                                            />

                                                        </div>
                                                        <div class="col-sm-6">

                                                            <Input
                                                                name={fields.shipstate.name}
                                                            />

                                                        </div>
                                                    </div>

                                                    <div class="row">
                                                        <div class="col-sm-6">

                                                            <Input
                                                                name={fields.shipzip.name}
                                                            />

                                                        </div>
                                                        <div class="col-sm-6">

                                                            <Input
                                                                name={fields.shipcountry.name}
                                                            />

                                                        </div>
                                                    </div>
                                                </>
                                                :
                                                <p class="address-text">
                                                    {
                                                        this.state.data?.shipAddressAddr1
                                                    }
                                                    <br />
                                                    {
                                                        (this.state.data?.shipAddressAddr2) ?
                                                            this.state.data?.shipAddressAddr2 :
                                                            ''
                                                    }

                                                    {
                                                        (this.state.data?.shipAddressAddr3) ?
                                                            this.state.data?.shipAddressAddr3 :
                                                            ''
                                                    }
                                                    {this.state.data?.shipAddressCity} , &nbsp;
                                                    {this.state.data?.shipAddressState}<br />
                                                    {this.state.data?.shipAddressPostalCode}<br />
                                                    {this.state.data?.shipAddressCountry}<br />
                                                </p>
                                            }
                                        </div>

                                    </li>
                                    <li>
                                        <div className="row">
                                            <div className="col">
                                                <Input
                                                    placeholder={'193329T-1'}
                                                    name={fields.purchaseOrder.name}
                                                    label={fields.purchaseOrder.label}
                                                    disabled={true}
                                                />
                                            </div>
                                        </div>

                                        <Date
                                            name={fields.date.name}
                                            label={fields.date.label}
                                        />

                                        <Input
                                            name={fields.invoiceLinkedTo.name}
                                            label={fields.invoiceLinkedTo.label}
                                            disabled={true}
                                        />

                                        <Select
                                            name={fields.shipedMehtod.name}
                                            label={fields.shipedMehtod.label}
                                            option={shipedMehtod}
                                            disabled={true}
                                            valueinfo={this.state.data?.shipMethodMethodField}
                                        />

                                        <Select
                                            name={fields.containerOrientation.name}
                                            label={fields.containerOrientation.label}
                                            option={containerOrientation}
                                            valueinfo={this.state.data?.containerOrientation}
                                            disabled={true}
                                        />

                                        <div>
                                            <Checkbox
                                                name={fields.expected.name}
                                                label={fields.expected.label}
                                                disabled={true}
                                                value={(this.state.data?.isExpedited) ? true : false}
                                            />
                                        </div>
                                        <div>
                                            <Checkbox
                                                name={fields.refounded.name}
                                                label={fields.refounded.label}
                                                disabled={true}
                                                value={(this.state.data?.isRefunded) ? true : false}
                                            />
                                        </div>
                                    </li>
                                    <li>

                                        <Input
                                            name={fields.supplierVendor.name}
                                            label={fields.supplierVendor.label}
                                            disabled={true}
                                        />

                                        <Input
                                            placeholder={'Enter Release Number'}
                                            name={fields.releaseNumber.name}
                                            label={fields.releaseNumber.label}
                                        />

                                        <DateTime
                                            name={fields.releaseDate.name}
                                            label={fields.releaseDate.label}
                                        />

                                        <Input
                                            name={fields.supplierLocation.name}
                                            label={fields.supplierLocation.label}
                                            placeholder={'Slect Supplier Location'}
                                            option={supplierLocation}
                                            disabled={true}
                                        />

                                        <div className="row">
                                            <div className="col-6">
                                                <PromiseTimeLine
                                                    name={fields.promiseTimeLine.name}
                                                    label={fields.promiseTimeLine.label}
                                                    option={promiseTimeLines}
                                                    defaultValue={this.state.data?.promiseDateTimeline}
                                                />
                                            </div>
                                            <div className="col-6">
                                                <Date
                                                    name={fields.promiseDate.name}
                                                    label={fields.promiseDate.label}
                                                />
                                            </div>
                                        </div>
                                        <Date
                                            name={fields.expectedDate.name}
                                            label={fields.expectedDate.label}
                                        />
                                        <div >
                                            {
                                                (this.state.data?.isLastDepotCallCompleted) ?
                                                    <input disabled checked onClick={this.handleDepot} type="checkbox" />
                                                    :
                                                    <input disabled onClick={this.handleDepot} type="checkbox" />
                                            }
                                            <label className="labelinupt">Depot Call Completed</label>
                                        </div>
                                        {
                                            (this.state.data?.isLastDepotCallCompleted) ?
                                                <div className="row">
                                                    <div className="col-6">
                                                        <p>{(this.state.data.lastDepotCall.split('T')[0]) ? moment(this.state.data.lastDepotCall.split('T')[0]).format('MM/DD/YYYY') : ''} {(this.state.data?.lastDepotCall) ? moment(this.state.data?.lastDepotCall).tz(timezone.name()).format('HH:mm A') : ''}</p>
                                                    </div>
                                                    <div className="col-6">
                                                        <p>{(this.state.data?.lastDepotCallCompletedBy) ? this.state.data?.lastDepotCallCompletedBy : ''}</p>
                                                    </div>
                                                </div>
                                                :
                                                ''
                                        }
                                    </li>
                                    <li className='widthModifed22'>
                                        <div className="row">
                                            <div className="col">
                                                <div className="form-group">
                                                    <label>{fields.pickupLocation.label}</label>
                                                    <select disabled class="form-control">
                                                        <option>{this.state.data?.pickupLocationName}</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row location-detail-list">

                                            <div class="col-6">
                                                <div class="row">
                                                    <div class="col-12">
                                                        <p><b>Location Details</b></p>
                                                    </div>
                                                </div>

                                                <div class="row">
                                                    <div class="col-12">
                                                        <p>{this.state.data?.pickupLocationName}</p>
                                                    </div>
                                                </div>

                                                <div class="row">
                                                    <div class="col-12">
                                                        <p>
                                                            {this.state.data?.pickupAddress1}
                                                        </p>
                                                    </div>
                                                </div>

                                                <div class="row">
                                                    <div class="col-12">
                                                        <p>{this.state.data?.pickupCity}, {this.state.data?.pickupState}</p>
                                                    </div>
                                                </div>

                                                <div class="row">
                                                    <div class="col-12">
                                                        <p>{this.state.data?.pickupZipcode}</p>
                                                    </div>
                                                </div>

                                                <div class="row">
                                                    <div class="col-12">
                                                        <p> {this.state.data?.pickupPhone}</p>
                                                    </div>
                                                </div>

                                                <div class="row">
                                                    <div class="col-12">
                                                        <p> {this.state.data?.pickupEmail}</p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-6">
                                                <div class="row">
                                                    <div class="col-12">
                                                        <p><b>Budget</b></p>
                                                    </div>
                                                </div>

                                                <div class="row">
                                                    <div class="col-12">
                                                        <p>Distance - {this.state.data?.distanceBetweenPickupAndDeliveryAddress}</p>
                                                    </div>
                                                </div>

                                                <div class="row">
                                                    <div class="col-12">
                                                        <p>Price/mile - {parseFloat(this.state.data?.pickupPerMileCost).toFixed(2)}</p>
                                                    </div>
                                                </div>

                                                <div class="row">
                                                    <div class="col-12">
                                                       <p>Line Haul - {parseFloat(Math.max(this.state.pickupLocation?.minimumRate, (this.state.data.distanceBetweenPickupAndDeliveryAddress * this.state.pickupLocation?.perMilePrice))).toFixed(2)}</p>
                                                    </div>
                                                </div>

                                                <div class="row">
                                                    <div class="col-12">
                                                        <p>FSC Rate - {(this.state.fuelSurcharge[0]) ? parseFloat(this.state.fuelSurcharge[0].fuelSurchargeRate).toFixed(2) : ""}</p>
                                                    </div>
                                                </div>

                                                <div class="row">
                                                    <div class="col-12">
                                                        <p>FSC Total - {(this.state.data.distanceBetweenPickupAndDeliveryAddress && this.state.fuelSurcharge[0]) ? parseFloat(Math.round(this.state.data.distanceBetweenPickupAndDeliveryAddress) * this.state.fuelSurcharge[0].fuelSurchargeRate).toFixed(2) : ''}</p>
                                                    </div>
                                                </div>

                                                <div class="row">
                                                    <div class="col-12">
                                                        <p>Total Cost - {(this.state.data.distanceBetweenPickupAndDeliveryAddress && this.state.fuelSurcharge[0]) ? parseFloat((Math.round(this.state.data.distanceBetweenPickupAndDeliveryAddress) * this.state.fuelSurcharge[0].fuelSurchargeRate) + parseFloat(Math.max(this.state.pickupLocation?.minimumRate, (this.state.data.distanceBetweenPickupAndDeliveryAddress * this.state.pickupLocation?.perMilePrice)))).toFixed(2) : ''}</p>
                                                    </div>
                                                </div>

                                                <div class="row">
                                                    <div class="col-12">
                                                        <p>Min Rate- {parseFloat(this.state.data?.pickupMinimumRate).toFixed(2)}</p>
                                                    </div>
                                                </div>

                                               

                                                
                                            </div>
                                        </div>

                                        <Date
                                            name={fields.actualPickupDate.name}
                                            label={fields.actualPickupDate.label}
                                        />

                                        <Date
                                            name={fields.scheduledDeleiveryDate.name}
                                            label={fields.scheduledDeleiveryDate.label}
                                        />

                                        <div className="row">
                                            <div className="col-6">
                                                <Time
                                                    placeholder={'From Time'}
                                                    name={fields.from.name}
                                                    label={fields.from.label}
                                                />
                                            </div>
                                            <div className="col-6">
                                                <Time
                                                    placeholder={'To Time'}
                                                    name={fields.to.name}
                                                    label={fields.to.label}
                                                />
                                            </div>
                                        </div>
                                        <Input
                                            name={fields.deleiveryStatus.name}
                                            label={fields.deleiveryStatus.label}
                                        />
                                        <Date
                                            name={fields.actualDeleiveryDate.name}
                                            label={fields.actualDeleiveryDate.label}
                                        />
                                    </li>
                                    <li className='widthModified18'>
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="form-group">
                                                    <label htmlFor="ServicePurchase">Customer Note</label>
                                                    <div className="customer-note-box">
                                                    {
                                                            this.state.customerNoteCollection?.split("\n").map((i, key) => {
                                                                return <p key={key}>{i}</p>
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                                {
                                                  
                                                        <div className="form-group">
                                                            <input type="text" name="customerNote" onChange={this.handleChange} class="form-control customer-note" placeholder="Add Customer Note" id="customerinput" />
                                                            <button type="button" onClick={this.customerNote} class="btn btn-primary mt-2">Add</button>
                                                        </div>
                                                    
                                                }

                                            </div>

                                            <div className="col-12">
                                                <div className="form-group">
                                                    <label htmlFor="ServicePurchase">Vendor Note</label>
                                                    <div className="customer-note-box">
                                                    {
                                                            this.state.vendorNoteCollection?.split("\n").map((i, key) => {
                                                                return <p key={key}>{i}</p>
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                                {
                                                   
                                                        <div className="form-group">
                                                            <input type="text" name="vendorNote" onChange={this.handleChange} class="form-control vendor-note" placeholder="Add Vendor Note" id="vendorinput" />
                                                            <button type="button" onClick={this.vendorNote} class="btn btn-primary mt-2">Add</button>
                                                        </div>
                                                  
                                                }

                                            </div>

                                            <div className="col-12">
                                                <div className="form-group">
                                                    <label htmlFor="ServicePurchase">Dispatch Note</label>
                                                    <div className="customer-note-box">
                                                    {
                                                            this.state.dispatchedNoteCollection?.split("\n").map((i, key) => {
                                                                return <p key={key}>{i}</p>
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                                {
                                                    
                                                        <div className="form-group">
                                                            <input type="text" name="dispatchNote" onChange={this.handleChange} class="form-control dispatch-note" placeholder="Add Dispatch Note" id="dispatchinput" />
                                                            <button type="button" onClick={this.dispatchNote} class="btn btn-primary mt-2">Add</button>
                                                        </div>
                                                    
                                                }
                                            </div>

                                            <div className="col-12">
                                                <div className="form-group">
                                                    <label htmlFor="ServicePurchase">Purchasing Note</label>
                                                    <div className="customer-note-box">
                                                        {
                                                            this.state.purchasingNoteCollection?.split("\n").map((i, key) => {
                                                                return <p key={key}>{i}</p>
                                                            })
                                                        }
                                                    </div>
                                                </div>

                                                <div className="form-group">
                                                    <input type="text" name="purchasingNote" onChange={this.handleChange} class="form-control customer-note" placeholder="Add Purchasing Note" id="purchasingNote" />
                                                    <button type="button" onClick={this.purchasingNote} class="btn btn-primary mt-2">Add</button>
                                                </div>
                                            </div>

                                            

                                        </div>
                                    </li>
                                </ul>
                            </div>

                            <ResalePurchaseOrderTable />
                            <div className="row mt-5 mb-5">
                                <div className="col-sm-4">
                                    <div className="col-sm-12">
                                        {
                                            /*
                                                <Textarea
                                                    name={fields.deliveryNotes.name}
                                                    label={fields.deliveryNotes.label}
                                                />
                                            */
                                        }
                                    </div>
                                </div>

                                <div className="col-sm-4">
                                    <Textarea
                                        name={fields.memo.name}
                                        label={fields.memo.label}
                                    />
                                    <Textarea
                                        name={fields.messages.name}
                                        label={fields.messages.label}
                                    />
                                </div>
                                <div className="col-sm-4">
                                    <div className="taxs-amount">
                                        <p>Taxes</p>
                                        <div className="group-amount">
                                            <h3>Total</h3>
                                            <p>{this.state.data.totalAmount?.toFixed(2)}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {
                                /*  
                                    <div className="row">
                                        <div className="col-sm-12 text-right mb-5">
                                            <button onClick={() => this.cancelDispatched()} type="button" className="btn btn-primary">Delete</button>
                                            &nbsp;&nbsp;
                                            <button onClick={() => this.emailTrigger()} type="button" className="btn btn-primary">Email</button>
                                            &nbsp;&nbsp;
                                            <button onClick={() => this.handleFinalizeDelivery()} type="button" className="btn btn-primary">Finalize Delivery</button>
                                            &nbsp;&nbsp;
                                            {
                                                (this.state.loading) ?
                                                    <button disabled type="button" type="submit" className="btn btn-success button-save">loding....</button>
                                                    :
                                                    <>
                                                        {
                                                            (this.state.orderstatus) ?
                                                                <button type="button" disabled type="submit" className="btn btn-primary  button-save">Save</button> :
                                                                <button type="button" type="submit" className="btn btn-primary  button-save">Save</button>
                                                        }
                                                    </>
                                            }
                                        </div>
                                    </div>
                                */
                            }
                        </form>

                    )}
                </Formik>
                <style jsx="true">{`
                    
                    .form-control {
                        font-size: 14px;
                        border: 1px solid #c8c2c2;
                        border-radius: 0px;
                    } 
                    .css-yk16xz-control{
                        border-color:1px solid #c8c2c2;
                        border-radius:0px;
                    }  
                    .purchase-order-form label {
                        font-weight: 500;
                        font-size: 11px;
                    }   
                    .form-control:disabled {
                        background-color: #fff0f0 !important;
                        opacity:1 !important;
                    }
                    .customer-note-box {
                        background-color: #fff6f6 !important;
                    }
                `}</style>
            </>
        );
    }
}

export default PurchaseForm;