import React, { useState, useEffect } from 'react';
import moment from 'moment';
import axios from 'axios';
import avoidNull from '../../../utils/AvoidNull';
import avoidNull2 from '../../../utils/AvoidNull2';
import removeAddress from '../../../utils/RemoveAddress';
import PlanningServices from '../../DriverPlanning/PlanningServices';
import MaterialTable from "material-table";
import { ACCESS_TOKEN_NAME, ACCESS_TOKEN_NAME_USER, API_BASE_URL } from '../../../constants/apiConstants';
import CustomDatePicker from "../../../utils/customDatePicker";
import FilterComponentBox from "../../../utils/FilterComponentBox";
import ResetFillterButton from "../../../utils/ResetFillterButton";
import { useCookies } from 'react-cookie';
import Cookies from 'universal-cookie';


function DataTablePlanned(props) {
    const [pageRefresh, setPageRefresh] = useState(false);
    const [cookies, setCookie, removeCookie] = useCookies(['orderstatus']);

    const [orderData, setOrderData] = useState();
    const [loading, setloading] = useState(false);

    const fetchDispatchedOrder = async (driverInfo) => {
        setloading(true);
        const response = await axios.post(API_BASE_URL + `/fetchDispatchedOrder`, { driverInfo }, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN_NAME),
            },
        })
        setloading(false);
        setOrderData(response.data);
    }
    console.log("filterrrrr", props.sortBy);
    
    

    
    const cookiesi = new Cookies();

    const resetFilter = async () => {
        const test = document.querySelectorAll(".material-icons");
        for(let tests of test)
        {
        if(tests.getAttribute("aria-label") == 'clear')
        {
            tests.click();
        }
        }
        
        const cookies = new Cookies();
        if (Object.keys(cookies.getAll()).length > 0) {
            setPageRefresh(true);
        }
        else {
            setPageRefresh(true);
        }
        await removeCookie("orderBy", { path: "/orderstatus" });
        await removeCookie("direction", { path: "/orderstatus" });
        await removeCookie("invoiceRefNumber", { path: "/orderstatus" });
        await removeCookie("invoicePaidDate", { path: "/orderstatus" });
        await removeCookie("invoiceTerms", { path: "/orderstatus" });
        await removeCookie("invoiceLineItemItemNameWithoutParent", { path: "/orderstatus" });
        await removeCookie("invoiceAssignedToName", { path: "/orderstatus" });
        await removeCookie("resalePOPickupCity", { path: "/orderstatus" });
        await removeCookie("resalePORefNumer", { path: "/orderstatus" });
        await removeCookie("resalePOReleaseNumber", { path: "/orderstatus" });
        await removeCookie("resalePOReleaseDate", { path: "/orderstatus" });
        await removeCookie("resalePOTotalPrice", { path: "/orderstatus" });
        await removeCookie("resalePOExpectedDate", { path: "/orderstatus" });
        await removeCookie("resalePOLastDepotCallDate", { path: "/orderstatus" });
        await removeCookie("resalePOLineItemDistance", { path: "/orderstatus" });
        await removeCookie("invoiceShipCity", { path: "/orderstatus" });
        await removeCookie("transportPOLineItemContainerNumber", { path: "/orderstatus" });
        await removeCookie("transportPORefNumer", { path: "/orderstatus" });
        await removeCookie("transportPOVendor", { path: "/orderstatus" });
        await removeCookie("transportPOTotalPrice", { path: "/orderstatus" });
        await removeCookie("transportPOScheduledDeliveryDate", { path: "/orderstatus" });
        await removeCookie("transportPODeliveryStatus", { path: "/orderstatus" });
        await removeCookie("invoicePromisedTimeline", { path: "/orderstatus" });
        await removeCookie("invoicePromisedDate", { path: "/orderstatus" });
        await removeCookie("invoiceTxnDate", { path: "/orderstatus" });
        await removeCookie("invoiceCustomerNotes", { path: "/orderstatus" });
        await removeCookie("invoiceBalanceRemaining", { path: "/orderstatus" });
        await removeCookie("invoiceSupplierLocation", { path: "/orderstatus" });
        await removeCookie("invoiceVendorNotes", { path: "/orderstatus" });
        await removeCookie("resalePOSupplierLocation", { path: "/orderstatus" });
        await removeCookie("invoiceDispatchNotes", { path: "/orderstatus" });
        await removeCookie("transportPODeliveredDate", { path: "/orderstatus" });
        await removeCookie("isCompleted", { path: "/orderstatus" });
        await removeCookie("resalePOVendor", { path: "/orderstatus" });
        await removeCookie("plannedStatus", { path: "/orderstatus" });
        await removeCookie("plannedDriver", { path: "/orderstatus" });
        await removeCookie("planningDate", { path: "/orderstatus" });
        await removeCookie("newDistance", { path: "/orderstatus" });
    }


    const createPlan = async (product, invoiceRefNumber, resalePORefNumer, resaleVendor, transportPORecordID) => {
        const response = await PlanningServices.getPlanningInfo(invoiceRefNumber);
            if (response[0] && response[0].id) {
                window.open("/exitingPurchaseorder/" + result[0].id + "/" + transportPORecordID);
          
            }
            
        const payload = {
            contactId: 0,
            locationId: 123,
            customerConfirmation: 0,
            driveConfirmation: 0,
            size: 0,
            invoiceId: invoiceRefNumber,
            City: '',
            drivername: '',
            product: product,
            State: '',
            transportPORecordID,
            DropState: '',
            DropCity: '',
            Dist: '',
            resalePORefNumer,
            isExpedited: 0,
            vendorFullName: resaleVendor,
            date: ''
        };
        const result = await PlanningServices.savePlanning(payload);
        if (result) {
            //fetchDispatchedOrder({});
            window.open("/exitingPurchaseorder/" + result[0].lastinsterid + "/" + transportPORecordID);
        }
    }

    return (
        <div className="col-md-12">
        <MaterialTable
            key={pageRefresh}
            columns={[
                { title: "RecordID", field: "RecordID", hidden: true },
                {
                    title: "Customer", field: "invoiceRefNumber",
                    defaultSort: cookiesi.getAll()['orderBy'] == 'invoiceCustomerFullName' ? cookiesi.getAll()['direction'] : false,
                    filterComponent: ({ columnDef, onFilterChanged }) => (
                        <FilterComponentBox pageName={'orderstatus'} onFilterChanged={onFilterChanged} columnDef={columnDef} columnName={columnDef.field} />
                    ),
                    render: rowData => <a className="breakwordwichpadding" target="_blank" href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID}><strong className="title-strong">{rowData.Customer?.split('*')[0]}</strong><br />{rowData.Customer?.split('*')[1]}</a>
                },

                {
                    title: "Paid Date", field: "invoicePaidDate",
                    defaultSort: cookiesi.getAll()['orderBy'] == 'invoicePaidDate' ? cookiesi.getAll()['direction'] : false,
                    customFilterAndSearch: (term, rowData) => (rowData.invoicePaidDate == moment(term).format('YYYY-MM-DD')) ? true : false,
                    filterComponent: (props) => <CustomDatePicker pageName={'orderstatus'} {...props} />,

                    type: 'date', render: rowData => <a target="_blank" href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID}>{(rowData.invoicePaidDate) ? moment(rowData.invoicePaidDate).format('MM/DD/YY') : ''}</a>
                },

                {
                    title: "Product", field: "invoiceLineItemItemNameWithoutParent",
                    defaultSort: cookiesi.getAll()['orderBy'] == 'invoiceLineItemItemNameWithoutParent' ? cookiesi.getAll()['direction'] : false,
                    filterComponent: ({ columnDef, onFilterChanged }) => (
                        <FilterComponentBox pageName={'orderstatus'} onFilterChanged={onFilterChanged} columnDef={columnDef} columnName={columnDef.field} />
                    ),
                    render: rowData => <a target="_blank" href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID}>{rowData.invoiceLineItemItemNameWithoutParent}</a>
                },

                {
                    title: "Assign To", field: "invoiceAssignedToName",
                    defaultSort: cookiesi.getAll()['orderBy'] == 'invoiceAssignedToName' ? cookiesi.getAll()['direction'] : false,
                    filterComponent: ({ columnDef, onFilterChanged }) => (
                        <FilterComponentBox pageName={'orderstatus'} onFilterChanged={onFilterChanged} columnDef={columnDef} columnName={columnDef.field} />
                    ),
                    render: rowData => <a className="breakword" target="_blank" href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID}>{rowData.invoiceAssignedToName}</a>
                },

                {
                    title: "Supp Loc", field: "invoiceSupplierLocation",
                    defaultSort: cookiesi.getAll()['orderBy'] == 'invoiceSupplierLocation' ? cookiesi.getAll()['direction'] : false,
                    filterComponent: ({ columnDef, onFilterChanged }) => (
                        <FilterComponentBox pageName={'orderstatus'} onFilterChanged={onFilterChanged} columnDef={columnDef} columnName={columnDef.field} />
                    ),
                    render: rowData => <a className="breakword" target="_blank" href={'https://www.google.com/maps/place/' + rowData.invoiceSupplierLocation}>{rowData.invoiceSupplierLocation}</a>
                },

                {
                    title: "Pickup Loc", field: "resalePOPickupCity",
                    defaultSort: cookiesi.getAll()['orderBy'] == 'resalePOPickupCity' ? cookiesi.getAll()['direction'] : false,
                    filterComponent: ({ columnDef, onFilterChanged }) => (
                        <FilterComponentBox pageName={'orderstatus'} onFilterChanged={onFilterChanged} columnDef={columnDef} columnName={columnDef.field} />
                    ),
                    render: rowData => <a className="breakword" target="_blank" href={'https://www.google.com/maps/place/' + rowData.resalePOPickupCity}>{rowData.resalePOPickupCity}<br />{rowData.resalePOPickupState}</a>
                },


                {
                    title: "Res PO", field: "resalePORefNumer",
                    defaultSort: cookiesi.getAll()['orderBy'] == 'resalePORefNumer' ? cookiesi.getAll()['direction'] : false,
                    filterComponent: ({ columnDef, onFilterChanged }) => (
                        <FilterComponentBox pageName={'orderstatus'} onFilterChanged={onFilterChanged} columnDef={columnDef} columnName={columnDef.field} />
                    ),
                    render: rowData => <a target="_blank" href={'/resalePurchaseOrder/' + rowData.ResalePORecordID}>{rowData.resalePORefNumer}</a>
                },

                {
                    title: "Res Ven", field: "resalePOVendor",
                    defaultSort: cookiesi.getAll()['orderBy'] == 'resalePOVendor' ? cookiesi.getAll()['direction'] : false,
                    filterComponent: ({ columnDef, onFilterChanged }) => (
                        <FilterComponentBox pageName={'orderstatus'} onFilterChanged={onFilterChanged} columnDef={columnDef} columnName={columnDef.field} />
                    ),
                    render: rowData => <a className="breakword" target="_blank" href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID}>{rowData.resalePOVendor}</a>
                },

                {
                    title: "Rele #", field: "resalePOReleaseNumber",
                    defaultSort: cookiesi.getAll()['orderBy'] == 'resalePOReleaseNumber' ? cookiesi.getAll()['direction'] : false,
                    filterComponent: ({ columnDef, onFilterChanged }) => (
                        <FilterComponentBox pageName={'orderstatus'} onFilterChanged={onFilterChanged} columnDef={columnDef} columnName={columnDef.field} />
                    ),
                    render: rowData => <a target="_blank" href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID}>{rowData.resalePOReleaseNumber}</a>
                },

                {
                    title: "Release Date", field: "resalePOReleaseDate",
                    defaultSort: cookiesi.getAll()['orderBy'] == 'resalePOReleaseDate' ? cookiesi.getAll()['direction'] : false,
                    customFilterAndSearch: (term, rowData) => (rowData.resalePOReleaseDate == moment(term).format('YYYY-MM-DD')) ? true : false,
                    filterComponent: (props) => <CustomDatePicker pageName={'orderstatus'} {...props} />,

                    type: 'date', render: rowData => <a target="_blank" href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID}>{(rowData.ReleaseDate) ? moment(rowData.ReleaseDate).format('MM/DD/YY') : ''}</a>
                },


                {
                    title: "Res Total", field: "resalePOTotalPrice",
                    defaultSort: cookiesi.getAll()['orderBy'] == 'resalePOTotalPrice' ? cookiesi.getAll()['direction'] : false,
                    filterComponent: ({ columnDef, onFilterChanged }) => (
                        <FilterComponentBox pageName={'orderstatus'} onFilterChanged={onFilterChanged} columnDef={columnDef} columnName={columnDef.field} />
                    ),
                    render: rowData => <a target="_blank" href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID}>{rowData.resalePOTotalPrice}</a>
                },

                {
                    title: "Exp Date", field: "resalePOExpectedDate", type: 'date',
                    defaultSort: cookiesi.getAll()['orderBy'] == 'resalePOExpectedDate' ? cookiesi.getAll()['direction'] : false,
                    filterComponent: (props) => <CustomDatePicker pageName={'orderstatus'} {...props} />,
                    customFilterAndSearch: (term, rowData) => (rowData.resalePOExpectedDate == moment(term).format('YYYY-MM-DD')) ? true : false,
                    render: rowData => <a target="_blank" href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID}>{(rowData.resalePOExpectedDate) ? moment(rowData.resalePOExpectedDate).format('MM/DD/YY') : ''}</a>
                },

                {
                    title: "Last Depot Call", field: "resalePOLastDepotCallDate",
                    defaultSort: cookiesi.getAll()['orderBy'] == 'resalePOLastDepotCallDate' ? cookiesi.getAll()['direction'] : false,
                    customFilterAndSearch: (term, rowData) => (rowData.resalePOLastDepotCallDate == moment(term).format('YYYY-MM-DD')) ? true : false,
                    filterComponent: (props) => <CustomDatePicker pageName={'orderstatus'} {...props} />,

                    type: 'date', render: rowData => <a target="_blank" href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID}>{(rowData.resalePOLastDepotCallDate) ? moment(rowData.LastDepotCall).format('MM/DD/YY') : ''}</a>
                },

                {
                    title: "Planned", field: "plannedStatus",
                    defaultSort: cookiesi.getAll()['orderBy'] == 'plannedStatus' ? cookiesi.getAll()['direction'] : false,
                    filterComponent: ({ columnDef, onFilterChanged }) => (
                        <FilterComponentBox pageName={'orderstatus'} onFilterChanged={onFilterChanged} columnDef={columnDef} columnName={columnDef.field} />
                    ),
                    render: rowData => <a target="_blank" href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID}><center>{rowData.plannedStatus}</center></a>
                },
                {
                    title: "Planned Driver", field: "plannedDriver",
                    defaultSort: cookiesi.getAll()['orderBy'] == 'plannedDriver' ? cookiesi.getAll()['direction'] : false,
                    filterComponent: ({ columnDef, onFilterChanged }) => (
                        <FilterComponentBox pageName={'orderstatus'} onFilterChanged={onFilterChanged} columnDef={columnDef} columnName={columnDef.field} />
                    ),
                    render: rowData => <a target="_blank" href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID}><center>{rowData.plannedDriver}</center></a>
                },



                {
                    title: "Planned Date", field: "planningDate",
                    defaultSort: cookiesi.getAll()['orderBy'] == 'planningDate' ? cookiesi.getAll()['direction'] : false,
                    filterComponent: (props) => <CustomDatePicker pageName={'orderstatus'} {...props} />,
                    //customFilterAndSearch: (term, rowData) =>  (rowData.planningDate == moment(term).format('YYYY-MM-DD')) ? true : false,

                    type: 'date', render: rowData => <a target="_blank" href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID}>{(rowData.planningYear && rowData.planningDate) ? moment(rowData.planningYear + ' ' + moment(rowData.planningDate).format('YYYY')).format('MM/DD/YYYY') : ''}</a>
                },


                {
                    title: "Miles",
                    defaultSort: cookiesi.getAll()['orderBy'] == 'newDistance' ? cookiesi.getAll()['direction'] : false,
                    filterComponent: ({ columnDef, onFilterChanged }) => (
                        <FilterComponentBox pageName={'orderstatus'} onFilterChanged={onFilterChanged} columnDef={columnDef} columnName={columnDef.field} />
                    ),
                    field: "newDistance", render: rowData => <a target="_blank" href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID}>{ rowData.newDistance ? Math.round(rowData.newDistance) : ''}</a>
                },

                {
                    title: "Del City/State", field: "invoiceShipCity",
                    defaultSort: cookiesi.getAll()['orderBy'] == 'invoiceShipCity' ? cookiesi.getAll()['direction'] : false,
                    filterComponent: ({ columnDef, onFilterChanged }) => (
                        <FilterComponentBox pageName={'orderstatus'} onFilterChanged={onFilterChanged} columnDef={columnDef} columnName={columnDef.field} />
                    ),
                    render: (rowData) => {
                        return <a className="breakword" target="_blank" href={`https://www.google.com/maps/dir/?api=1&origin=${avoidNull(rowData.resalePOPickupAddress1)}${avoidNull(rowData.resalePOPickupCity)}${avoidNull2(rowData.resalePOPickupZipCode)}&destination=${removeAddress(rowData.invoiceShipAddress1,rowData.shipState)}${removeAddress(rowData.invoiceShipAddress2, rowData.shipState)}${removeAddress(rowData.invoiceShipAddress3, rowData.shipState)}${avoidNull(rowData.invoiceShipCity)}${avoidNull(rowData.shipState)}${avoidNull2(rowData.invoiceShipZip)}`}>{rowData.invoiceShipCity}<br />{rowData.shipState}</a>
                    }
                },

                {
                    title: "Del Status",
                    defaultSort: cookiesi.getAll()['orderBy'] == 'transportPODeliveryStatus' ? cookiesi.getAll()['direction'] : false,
                    filterComponent: ({ columnDef, onFilterChanged }) => (
                        <FilterComponentBox pageName={'orderstatus'} onFilterChanged={onFilterChanged} columnDef={columnDef} columnName={columnDef.field} />
                    ),
                    field: "transportPODeliveryStatus", render: rowData => <a target="_blank" href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID}>{rowData.transportPODeliveryStatus}</a>
                },

                {
                    title: "Prom Timeline",
                    defaultSort: cookiesi.getAll()['orderBy'] == 'invoicePromisedTimeline' ? cookiesi.getAll()['direction'] : false,
                    filterComponent: ({ columnDef, onFilterChanged }) => (
                        <FilterComponentBox pageName={'orderstatus'} onFilterChanged={onFilterChanged} columnDef={columnDef} columnName={columnDef.field} />
                    ),
                    field: "invoicePromisedTimeline", render: rowData => <a target="_blank" href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID}>{rowData.invoicePromisedTimeline}</a>
                },

                {
                    title: "Prom Date", field: "invoicePromisedDate",
                    defaultSort: cookiesi.getAll()['orderBy'] == 'invoicePromisedDate' ? cookiesi.getAll()['direction'] : false,
                    filterComponent: (props) => <CustomDatePicker pageName={'orderstatus'} {...props} />,
                    type: 'date', render: rowData => <a target="_blank" href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID}>{(rowData.invoicePromisedDate) ? moment(rowData.invoicePromisedDate).format('MM/DD/YY') : ''}</a>
                },

                {
                    title: "Inv Num",
                    cellStyle: {
                        display: 'none'
                    },

                    headerStyle: {
                        display: 'none'
                    },

                    field: "InvoiceRefNumber", render: rowData => <a href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID}>{rowData.InvoiceRefNumber}</a>
                },



                { render: rowData => <button onClick={() => (despatchLoading) ? <button disabled class="btn btn-primary custome-btn">Dispatch</button> : doDispatch(rowData.InvoiceRefNumber, rowData.Product, rowData.orderStatusRecordID)} class="btn btn-primary custome-btn">Dispatch</button> }


            ]}


            data={query =>
                new Promise((resolve, reject) => {
                    const cookies = new Cookies();
                    cookies.remove('adminer_version');

                    console.log(Object.keys(cookies.getAll()).length, 'queryFilterslength');

                    if (query.filters.length > 0 || Object.keys(cookies.getAll()).length > 0) {
                        let url = API_BASE_URL + `/fetchOrderBasedOnColumn?`;
                        url += 'size=' + query.pageSize;
                        url += '&page=' + (query.page + 1);
                        url += '&orderNumber=' + 15;
                        if (query.orderBy) {
                            removeCookie("orderBy", { path: '/orderstatus' });
                            removeCookie("direction", { path: '/orderstatus' });
                            if (query.orderBy.field == 'invoiceRefNumber') {
                                // url += '&sortField=invoiceRefNumber'
                                setCookie("orderBy", "invoiceCustomerFullName", { path: '/orderstatus' });
                            }
                            else {
                                setCookie("orderBy", query.orderBy.field, { path: '/orderstatus' });
                                // url += '&sortField=' + query.orderBy.field

                            }
                            setCookie("direction", query.orderDirection, { path: '/orderstatus' });
                            // url += '&sortType=' + query.orderDirection    
                        }
                        else {
                            url += '&sortField=invoiceTxnDate';
                            url += '&sortType=asc';
                            url += '&sortField=invoiceCustomerFullName';
                            url += '&sortType=asc';
                            url += '&sortField=resalePORecordID';
                            url += '&sortType=asc';
                        }

                        if (Object.keys(cookies.getAll()).length > 0) {
                            for (const key in cookies.cookies) {
                                console.log(moment.isDate(cookies.cookies[key].replace('"', '').replace('"', '')), 'momentinfo');

                                if (cookies.cookies[key] && moment.isDate(cookies.cookies[key])) {
                                    cookies.cookies[key] = moment(cookies.cookies[key]).format('YYYY-MM-DD')
                                }

                                if (cookies.cookies[key]) {
                                    if (key == 'invoiceRefNumber' && isNaN(cookies.cookies[key])) {
                                        url += '&columnName=' + 'invoiceCustomerFullName';
                                        if (cookies.cookies[key].toLowerCase() == 'yes') {
                                            url += '&search=' + 1;
                                        } else if (cookies.cookies[key].toLowerCase() == 'no') {
                                            url += '&search=' + 0;
                                        } else {
                                            url += '&search=' + cookies.cookies[key];
                                        }
                                    }
                                    else if (key == 'orderBy') {

                                        url += '&sortField=' + cookies.cookies[key]

                                        // url += '&sortType=' + cookies.cookies['direction']
                                    }
                                    else if (key == 'direction') {
                                        url += '&sortType=' + cookies.cookies[key]
                                    }
                                    else {
                                        url += '&columnName=' + key;
                                        if (cookies.cookies[key].toLowerCase() == 'yes') {
                                            url += '&search=' + 1;
                                        } else if (cookies.cookies[key].toLowerCase() == 'no') {
                                            url += '&search=' + 0;
                                        } else {
                                            url += '&search=' + cookies.cookies[key];
                                        }
                                    }
                                }
                            }
                        } else {
                            query.filters.forEach((item) => {
                                if (item.value && moment.isDate(item.value)) {
                                    item.value = moment(item.value).format('YYYY-MM-DD')
                                }

                                if (item.column.field == 'invoiceRefNumber' && isNaN(parseInt(item.value))) {
                                    url += '&columnName=' + 'invoiceCustomerFullName';
                                    url += '&search=' + item.value;
                                } else {
                                    url += '&columnName=' + item.column.field;
                                    url += '&search=' + item.value;
                                }
                            });
                        }


                        axios.get(url, {
                            headers: {
                                Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN_NAME),
                            },
                        }).then(result => {
                            console.log("API result", result.data);
                            resolve({
                                data: result.data.rows.map((data) => ({
                                    RecordID: data.invoiceRecordID,
                                    orderStatusRecordID: data.recordID,
                                    Customer: `${data.invoiceCustomerFullName.replace(',', ' ')}*${data.invoiceRefNumber}`,
                                    invoiceRefNumber: data.invoiceRefNumber,
                                    InvoiceDate: (data.invoiceTxnDate) ? data.invoiceTxnDate?.split('T')[0] : '',
                                    invoicePaidDate: (data.invoicePaidDate) ? data.invoicePaidDate?.split('T')[0] : '',
                                    invoiceLineItemItemNameWithoutParent: data.invoiceLineItemItemNameWithoutParent,
                                    invoiceAssignedToName: data.invoiceAssignedToName,
                                    invoiceCustomerNotes: data?.invoiceCustomerNotes,
                                    invoiceTerms: data.invoiceTerms,
                                    Balance: (data.invoiceBalanceRemaining) ? data.invoiceBalanceRemaining : '',
                                    invoiceSupplierLocation: data.invoiceSupplierLocation,
                                    resalePORefNumer: data.resalePORefNumer,
                                    resalePOReleaseNumber: data.resalePOReleaseNumber,
                                    invoiceShipMethodMethodFieldName: data?.invoiceShipMethodMethodFieldName,
                                    resalePOExpectedDate: data.resalePOExpectedDate,
                                    resalePOVendor: data.resalePOVendor,
                                    resalePOTotalPrice: data.resalePOTotalPrice,
                                    invoiceShipAddress1: data.invoiceShipAddress1,
                                    invoiceShipAddress2: data.invoiceShipAddress2,
                                    invoiceShipAddress3: data.invoiceShipAddress3,
                                    resalePOLineItemContainerNumber: data.resalePOLineItemContainerNumber,
                                    invoiceShipCity: data.invoiceShipCity,
                                    invoiceShipZip: data.invoiceShipZip,
                                    newDistance: data.newDistance ? data.newDistance : null,
                                    resalePOLineItemDistance: (data.resalePOLineItemDistance && data.resalePOLineItemDistance != 0) ? data.resalePOLineItemDistance : (data.purchaseOrder) ? data.purchaseOrder.distanceBetweenPickupAndDeliveryAddress : null,
                                    invoiceIsExpedited: (data.invoiceIsExpedited) ? 'Yes' : 'No',
                                    invoicePromisedTimeline: (data.invoicePromisedTimeline) ? data.invoicePromisedTimeline : '',
                                    invoicePromisedDate: (data.invoicePromisedDate) ? data.invoicePromisedDate?.split('T')[0] : '',
                                    resalePOPickupAddress1: data.resalePOPickupAddress1,
                                    resalePOPickupCity: data.resalePOPickupCity,
                                    resalePOPickupZipCode: data.resalePOPickupZipCode,
                                    ResalePORecordID: data.resalePORecordID,
                                    resalePOLastDepotCallDate: (data.resalePOLastDepotCallDate) ? data.resalePOLastDepotCallDate?.split('T')[0] : '',
                                    resalePOReleaseDate: (data.resalePOReleaseDate) ? data.resalePOReleaseDate?.split('T')[0] : '',

                                    shipCity: (data.invoiceShipCity) ? data.invoiceShipCity : '',
                                    shipState: (data.invoiceShipState) ? data.invoiceShipState : '',
                                    plannedStatus: (data.planning && data.planning.plannedStatus == 1) ? 'Yes' : 'No',
                                    planningDate: (data.planning.createdAt) ? data.planning.createdAt : null,
                                    planningYear: (data.planning.date) ? data.planning.date : null,
                                    plannedDriver: (data.planning.driverName) ? data.planning.driverName : '',


                                    invoiceShipAddress1: data.invoiceShipAddress1,
                                    invoiceShipAddress2: data.invoiceShipAddress2,
                                    invoiceShipAddress3: data.invoiceShipAddress3,
                                    invoiceShipCity: data.invoiceShipCity,
                                    invoiceShipZip: data.invoiceShipZip,
                                    resalePOPickupAddress1: data.resalePOPickupAddress1,
                                    resalePOPickupCity: data.resalePOPickupCity,
                                    resalePOPickupZipCode: data.resalePOPickupZipCode,

                                    invoiceLineItem_RecordID: data.invoiceLineItem_RecordID,

                                    DeliveryStatus: data.transportPODeliveryStatus,
                                    PromisedTimeline: (data.invoicePromisedTimeline) ? data.invoicePromisedTimeline : '',
                                    PromisedDate: (data.invoicePromisedDate) ? data.invoicePromisedDate?.split('T')[0] : '',
                                })),
                                page: result.data.currentPage - 1,
                                totalCount: result.data.totalItems,
                            });
                            setPageRefresh(false);
                        });
                    } else {
                        let url = API_BASE_URL + `/fetchCompletedOrder?`;
                        url += 'size=' + query.pageSize;
                        url += '&page=' + (query.page + 1);
                        url += '&orderNumber=' + 15;
                        if (query.orderBy) {
                            if (query.orderBy.field == 'invoiceRefNumber') {
                                url += '&sortField=invoiceRefNumber'
                                setCookie("orderBy", "invoiceCustomerFullName", { path: '/orderstatus' });
                            }
                            else {
                                setCookie("orderBy", query.orderBy.field, { path: '/orderstatus' });
                                url += '&sortField=' + query.orderBy.field

                            }
                            setCookie("direction", query.orderDirection, { path: '/orderstatus' });
                            url += '&sortType=' + query.orderDirection
                        } else {
                            switch (2) {
                                case '2':
                                    url += '&sortField=invoicePaidDate';
                                    url += '&sortType=desc';
                                    url += '&sortField=invoiceCustomerFullName';
                                    url += '&sortType=asc';
                                    url += '&sortField=resalePORecordID';
                                    url += '&sortType=asc';
                                    break;
                                case '7':
                                    url += '&sortField=invoicePaidDate';
                                    url += '&sortType=asc';
                                    url += '&sortField=invoiceCustomerFullName';
                                    url += '&sortType=asc';
                                    url += '&sortField=resalePORecordID';
                                    url += '&sortType=asc';
                                    break;
                                case '15':
                                    url += '&sortField=invoicePaidDate';
                                    url += '&sortType=asc';
                                    url += '&sortField=invoiceCustomerFullName';
                                    url += '&sortType=asc';
                                    url += '&sortField=resalePORecordID';
                                    url += '&sortType=asc';
                                    break;
                                case '10':
                                    url += '&sortField=transportPOScheduledDeliveryDate';
                                    url += '&sortType=asc';
                                    url += '&sortField=invoiceCustomerFullName';
                                    url += '&sortType=asc';
                                    url += '&sortField=resalePORecordID';
                                    url += '&sortType=asc';
                                    break;
                                default:
                                    url += '&sortField=invoiceTxnDate';
                                    url += '&sortType=asc';
                                    url += '&sortField=invoiceCustomerFullName';
                                    url += '&sortType=asc';
                                    url += '&sortField=resalePORecordID';
                                    url += '&sortType=asc';
                            }
                        }

                        if (query.search) {
                            url += '&search=' + query.search;
                        }

                        if (query.filters && !query.search) {
                            query.filters.forEach((item) => {
                                url += '&search=' + item.value;
                            });
                        }

                        if (props.endDate && props.startDate) {
                            url += '&from=' + props.startDate;
                            url += '&to=' + props.endDate;
                        }

                        axios.get(url, {
                            headers: {
                                Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN_NAME),
                            },
                        }).then(result => {
                            resolve({
                                data: result.data.rows.map((data) => ({
                                    RecordID: data.invoiceRecordID,
                                    orderStatusRecordID: data.recordID,
                                    Customer: `${data.invoiceCustomerFullName.replace(',', ' ')}*${data.invoiceRefNumber}`,
                                    invoiceRefNumber: data.invoiceRefNumber,
                                    InvoiceDate: (data.invoiceTxnDate) ? data.invoiceTxnDate?.split('T')[0] : '',
                                    invoicePaidDate: (data.invoicePaidDate) ? data.invoicePaidDate?.split('T')[0] : '',
                                    invoiceLineItemItemNameWithoutParent: data.invoiceLineItemItemNameWithoutParent,
                                    invoiceAssignedToName: data.invoiceAssignedToName,
                                    invoiceCustomerNotes: data?.invoiceCustomerNotes,
                                    invoiceTerms: data.invoiceTerms,
                                    Balance: (data.invoiceBalanceRemaining) ? data.invoiceBalanceRemaining : '',
                                    invoiceSupplierLocation: data.invoiceSupplierLocation,
                                    resalePORefNumer: data.resalePORefNumer,
                                    resalePOReleaseNumber: data.resalePOReleaseNumber,
                                    invoiceShipMethodMethodFieldName: data?.invoiceShipMethodMethodFieldName,
                                    resalePOExpectedDate: data.resalePOExpectedDate,

                                    plannedStatus: (data.planning && data.planning.plannedStatus == 1) ? 'Yes' : 'No',
                                    planningDate: (data.planning.createdAt) ? data.planning.createdAt : null,
                                    planningYear: (data.planning.date) ? data.planning.date : null,
                                    plannedDriver: (data.planning.driverName) ? data.planning.driverName : '',
                                    newDistance: data.newDistance ? data.newDistance : null,


                                    resalePOVendor: data.resalePOVendor,
                                    resalePOTotalPrice: data.resalePOTotalPrice,
                                    invoiceShipAddress1: data.invoiceShipAddress1,
                                    invoiceShipAddress2: data.invoiceShipAddress2,
                                    invoiceShipAddress3: data.invoiceShipAddress3,
                                    invoiceShipCity: data.invoiceShipCity,
                                    invoiceShipZip: data.invoiceShipZip,
                                    resalePOLineItemDistance: (data.resalePOLineItemDistance && data.resalePOLineItemDistance != 0) ? data.resalePOLineItemDistance : (data.purchaseOrder) ? data.purchaseOrder.distanceBetweenPickupAndDeliveryAddress : null,
                                    invoiceIsExpedited: (data.invoiceIsExpedited) ? 'Yes' : 'No',
                                    invoicePromisedTimeline: (data.invoicePromisedTimeline) ? data.invoicePromisedTimeline : '',
                                    invoicePromisedDate: (data.invoicePromisedDate) ? data.invoicePromisedDate?.split('T')[0] : '',
                                    resalePOPickupAddress1: data.resalePOPickupAddress1,
                                    resalePOPickupCity: data.resalePOPickupCity,
                                    resalePOPickupZipCode: data.resalePOPickupZipCode,
                                    resalePOLineItemContainerNumber: data.resalePOLineItemContainerNumber,
                                    ResalePORecordID: data.resalePORecordID,
                                    resalePOLastDepotCallDate: (data.resalePOLastDepotCallDate) ? data.resalePOLastDepotCallDate?.split('T')[0] : '',
                                    resalePOReleaseDate: (data.resalePOReleaseDate) ? data.resalePOReleaseDate?.split('T')[0] : '',

                                    shipCity: (data.invoiceShipCity) ? data.invoiceShipCity : '',
                                    shipState: (data.invoiceShipState) ? data.invoiceShipState : '',
                                    plannedStatus: (data.planning && data.planning.plannedStatus == 1) ? 'Yes' : 'No',


                                    invoiceShipAddress1: data.invoiceShipAddress1,
                                    invoiceShipAddress2: data.invoiceShipAddress2,
                                    invoiceShipAddress3: data.invoiceShipAddress3,
                                    invoiceShipCity: data.invoiceShipCity,
                                    invoiceShipZip: data.invoiceShipZip,
                                    resalePOPickupAddress1: data.resalePOPickupAddress1,
                                    resalePOPickupCity: data.resalePOPickupCity,
                                    resalePOPickupZipCode: data.resalePOPickupZipCode,

                                    invoiceLineItem_RecordID: data.invoiceLineItem_RecordID,

                                    DeliveryStatus: data.transportPODeliveryStatus,
                                    PromisedTimeline: (data.invoicePromisedTimeline) ? data.invoicePromisedTimeline : '',
                                    PromisedDate: (data.invoicePromisedDate) ? data.invoicePromisedDate?.split('T')[0] : '',

                                })),
                                page: result.data.currentPage - 1,
                                totalCount: result.data.totalItems,
                            });
                            setPageRefresh(false);
                        })
                    }
                })
            }

            title="Order"
            options={{
                columnsButton: false,
                actionsColumnIndex: -1,
                selection: false,
                toolbar: true,
                showTitle: false,

                rowStyle: rowData => ({
                    backgroundColor: (rowData.invoiceTerms == "Rent To Own") ? '#ffda73' : '#fff'
                }),

                search: true,
                thirdSortClick: false,
                sorting: true,
                paging: true,
                filtering: true,
                exportButton: false,
                pageSize: 50,
                pageSizeOptions: [50, 100, 200],
                // pageSizeOptions: [25, 50, 100, 200, { value: rowsPerPage.data, label: 'All' }],
                // headerStyle: {
                //     backgroundColor: '#ef3028',
                //     color: '#FFF'
                //   }, 
            }}

            components={{
                Toolbar: props => (
                    <ResetFillterButton elsement={props} resetFilter={resetFilter} />
                ),
            }}

    
        />
  <style jsx="true">{`

.btn-set{
    position: absolute;
    top: 18px;
    font-size: 17px;
    right: 255px;
    color: #000;
    padding: 4px 6px;
    border: 1px solid #79a3ff;
}

.input-wid-set {
    border: none;
    border-bottom: 1px solid #949494;
    position: relative;
    top: 3px;
    width: 100%;
}

                table td {
                    white-space: break-spaces!important;
                    word-wrap: break-all!important;
                }
                .MuiTableCell-root a {
                    font-size:12px !important;
                    font-family: 'Source Sans Pro', sans-serif;
                    overflow: visible;
                    display: block;
                    text-overflow: clip;
                    word-break: break-word;
                    white-space: break-spaces!important;            
                }
                // .MuiTableHead-root th:nth-child(8) {
                //     width: 129px!important;
                // }
                .MuiTableCell-root {
                    width: fit-content;
                }
                .MuiTableCell-root a {
                    width: 45px;
                }
                th.MuiTableCell-root.MuiTableCell-head.jss13.MuiTableCell-alignLeft:nth-child(2) {
                    width: 70px!important;
                }
                
                th.MuiTableCell-root.MuiTableCell-head.jss13.MuiTableCell-alignLeft:nth-child(3) {
                    width: 70px!important;
                }
                
                .MuiTableCell-root.MuiTableCell-head.MTableHeader-header-13.MuiTableCell-alignLeft:nth-child(6) {
                    width: 300px !important;
                }
                th.MuiTableCell-root.MuiTableCell-head.jss13.MuiTableCell-alignLeft:nth-child(7) {
                    width: 65px!important;
                }
                th.MuiTableCell-root.MuiTableCell-head.jss13.MuiTableCell-alignLeft:nth-child(15) {
                    width: 80px!important;
                }
                th {
                    text-align: inherit;
                    white-space: nowrap;
                }
                td.MuiTableCell-root.MuiTableCell-body.MuiTableCell-alignLeft a {
                    width:100%;
                }
                @media (max-width: 1920px){
            span.MuiButtonBase-root.MuiTableSortLabel-root {
                width: 100%;
            }
            .MuiTableCell-root.MuiTableCell-head.MTableHeader-header-13.MuiTableCell-alignLeft:nth-child(6) {
                width: 160px !important;
            }
            th.MuiTableCell-root.MuiTableCell-head.MTableHeader-header-13.MuiTableCell-alignLeft:nth-child(12) {
                width: 190px!important;
            }
        }

            `}</style>

        </div>

    );
}

export default DataTablePlanned;