import { API_BASE_URL , ACCESS_TOKEN_NAME } from '../../constants/apiConstants';
import axios from 'axios'

export default class ReportService {
  static async getReportsWithStatus(status) {
    const token = localStorage.getItem(ACCESS_TOKEN_NAME);
    var response = await axios.get(API_BASE_URL + `/getReports?status=`+status, {
        headers: {
          Authorization: "Bearer " + token,
        },
    });
    return response.data;
  }

  // Retreive planning delete log which deleted in sync due to TPO was not created and 7 days passed
  static async getdeletePlanningTPONull() {
    const token = localStorage.getItem(ACCESS_TOKEN_NAME);
    const response = await axios.get(API_BASE_URL + `/getdeletePlanningTPONull`, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    return response.data;
  }
}

