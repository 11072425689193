
import PropType from 'prop-types';
import React from 'react';

const SolidSurfaceCheckboxInvoice = ({
  field, value , bindFunction, labelClassName , form: { touched, errors , values }, label, disabled, inputRef, ...props
}) => (
  <>
  {
    (values[field.name]) ?
      <input
          checked
          type="checkbox"
          id={field.name}
          ref={inputRef}
          {...field}
          {...props}
          onClick={bindFunction}
      /> 
    :
    <input
        type="checkbox"
        id={field.name}
        ref={inputRef}
        {...field}
        {...props}
        onClick={bindFunction}
    />
  }

  <label className={labelClassName} htmlFor={field.name} style={{ fontWeight: '500' }}>&nbsp;&nbsp;{label}&nbsp;&nbsp;</label>
  {touched[field.name] && errors[field.name] ? (
    <small style={{ color: 'red' }}>{errors[field.name]}</small>
  ) : null}
</>
);

SolidSurfaceCheckboxInvoice.defaultProps = {
  inputRef: undefined
};

SolidSurfaceCheckboxInvoice.propTypes = {
  label: PropType.string.isRequired,
  field: PropType.object.isRequired,
  form: PropType.object.isRequired,
  inputRef: PropType.oneOfType([
    PropType.func,
    PropType.shape({ current: PropType.instanceOf(Element) })
  ])
};

export default SolidSurfaceCheckboxInvoice;
