import React, { useState, useEffect } from 'react';

import './CustomStyle.css';

import $ from 'jquery';

import PlanningServices from './PlanningServices';

import { withRouter, Link } from 'react-router-dom';

import swal from "sweetalert";

import CreatableSelect from 'react-select/creatable';

//import OrderRefrence from "../Shared/form/OrderRefrence";

import PurchaseOrderServices from '../PurchaseOrder/Services/PurchaseOrderServices';

import { API_BASE_URL, ACCESS_TOKEN_NAME } from '../../constants/apiConstants';

import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

import debounce from 'lodash.debounce';

import { Box } from '@mui/material';

import Modal from '@mui/material/Modal';

import Button from '@mui/material/Button';


// import InputLabel from "@mui/material/InputLabel";
// import MenuItem from "@mui/material/MenuItem";
// import FormControl from "@mui/material/FormControl";
// import Select from "@mui/material/Select";


import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect';
import TextField from "@material-ui/core/TextField";
import moment from 'moment';
import ProgressBar from '../ProgressBar';
import { useCookies } from 'react-cookie';
import Cookies from 'universal-cookie';



const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
};

function PlanningBox(props) {
    const [planData, setPlanData] = useState({ id: '', refNumber: '', driverId: '', date: '', oldDriverID: '', oldDate: '', driverName: '', recordID: '' })
    const [open, setOpen] = React.useState(false);
    const [position, setPosition] = useState(1);
    const [selectPostion, setSelectPostion] = useState(1)
    const [positionLength, setPositionLength] = useState(0)
    const [oldPosition, setOldPosition] = useState(0)
    const [cookies, setCookie, removeCookie] = useCookies(['driverPlanning']);
    const [newDriverId, setNewDriverId] = useState(null)
    const handleOpen = async (e, date, id, refNumber, driverId, driverName, recordID) => {
        if (id) {
            const date_ob = new Date()
            const dateNew = ('0' + date_ob.getDate()).slice(-2)
            const month = ('0' + (date_ob.getMonth() + 1)).slice(-2)
            console.log('sfsdfsddfsdf', month)
            let year = date_ob.getFullYear()
            if (month == '12' & date.includes('Jan')) {
                year = year + 1
            }
            console.log('checkYearrrr', year, date)

            await setSelectPostion(1)
            setPlanData((prevPlanData) => ({
                ...prevPlanData,
                id: id,
                refNumber: refNumber,
                driverId: driverId,
                oldDriverID: driverId,
                oldDate: moment(date + year).format('YYYY-MM-DD'),
                date: moment(date + year).format('YYYY-MM-DD'),
                driverName: driverName,
                recordID: recordID
            }));
        }
    };

    useEffect(() => {
        // This effect will run whenever planData is updated
        console.log('planDatawwww', planData);

        // Assuming updatePosition is an asynchronous function, use async/await
        if (planData.date && planData.driverId) {
            updatePosition(planData.date, planData.driverId).then(() => {
                // Now, setOpen(true) after updatePosition is done
                setOpen(true);
            });
        }
    }, [planData]);
    const handleClose = () => {
        setOpen(false);
    };
    const [loading, setloading] = useState(false);

    const [addclass, setaddclass] = useState(false);

    const [planningDetails, setPlanningDetails] = useState([]);

    const datesArray = [];




    const customStyles = {
        option: (styles) => ({ ...styles, padding: '0px 70px 0px 15px', position: "sticky" }),
        singleValue: (provided, state) => {
            const padding = '100px 50px 0px 0px';
            return { ...provided, padding };
        }
    }




    window.onscroll = debounce(() => {
        // console.log('scrool----', window.innerHeight, document.documentElement.scrollTop, window.innerHeight + document.documentElement.scrollTop, document.documentElement.offsetHeight)
        // console.log('scrool--2', Math.abs(document.documentElement.scrollHeight - document.documentElement.clientHeight - document.documentElement.scrollTop) <= 1, document.documentElement.scrollHeight, - document.documentElement.clientHeight - document.documentElement.scrollTop)
        // // if (window.innerHeight + document.documentElement.scrollTop === document.documentElement.offsetHeight) {
        
        if (Math.abs(document.documentElement.scrollHeight - document.documentElement.clientHeight - document.documentElement.scrollTop) <= 1) {
            props.handelScroll();
        }
    }, 100);


    useEffect(() => {
        if (props.driverInfo.contactId) {

            setloading(true);

            const result = PlanningServices.fetchPlanning(props.driverInfo.contactId);

            result.then((data) => {

                setloading(false);

                setPlanningDetails(data);
            });

        }

    }, []);

    const updatePlanningPosition = async () => {
        planData.position = selectPostion
        let reDriverID = 99688
        let newDriverId = null
        console.log('planDAtaaa', planData)
        console.log('gggDatata', moment(planData.date).format('ddd D MMM'), planData.driverId, positionLength, 1, null, null, planData.position)
        console.log('checkDriverIddddddddddd', props.driverInfo.contactId)
        const cookies = new Cookies();
        console.log('verifyDriverName', cookies.getAll().driverName)

        // if(planData.driverId != planData.oldDriverID)
        // {

        //     //setCookie('driverName', planData.driverId, { path: '/driverPlanning' });
        //     if(cookies.getAll().driverName)
        //     {
        //         newDriverId=cookies.getAll().driverName
        //         setCookie('driverName', reDriverID, { path: '/driverPlanning' });

        //     }
        //     else
        //     {
        //         setCookie('driverName', planData.driverId, { path: '/driverPlanning' });
        //     }

        //     // props.driverInfo.contactId = planData.driverId
        // }
        if (cookies.getAll().driverName) {
            newDriverId = cookies.getAll().driverName
        }
        else {
            await setCookie('driverName', planData.driverId, { path: '/driverPlanning' });
        }


        // if(cookies.getAll().driverName)
        // {
        // if(planData.driverId == planData.oldDriverID && planData.date != planData.oldDate && positionLength > 0)
        // {
        // if(firstcolumn.includes(moment(planData.date).format('ddd D MMM')))
        // {
        //     //console.log('gggDatata', moment(planData.date).format('ddd D MMM'), planData.driverId, positionLength, 1, null, null, planData.position)
        //     await firstincrementRow(moment(planData.date).format('ddd D MMM'), planData.driverId, positionLength, 1, null, null, planData.position)

        // }
        // else
        // {
        //     console.log('gggDatata', moment(planData.date).format('ddd D MMM'), planData.driverId, positionLength, 1, null, null, planData.position)
        //     await secondincrementRow(moment(planData.date).format('ddd D MMM'), planData.driverId, positionLength, 1, null, null, planData.position)

        // }

        // await updatePosition(planData.oldDate,planData.oldDriverID)
        // console.log('getPosition', positionLength)

        // if(firstcolumn.includes(moment(planData.oldDate).format('ddd D MMM')))
        // {
        //     await firstincrementRow(moment(planData.oldDate).format('ddd D MMM'), planData.oldDriverID, positionLength == 1 ? 0 : positionLength, 0)
        // }
        // else
        // {
        //     await secondincrementRow(moment(planData.oldDate).format('ddd D MMM'), planData.oldDriverID, positionLength == 1 ? 0 : positionLength, 0)
        // }
        // }
        // newDriverId=cookies.getAll().driverName
        // removeCookie('driverName', { path: '/driverPlanning' });
        // }
        // else
        // {
        //     await setCookie('driverName', planData.driverId, { path: '/driverPlanning' });
        // }



        //   if(moment(planData.date).format('ddd D MMM'))  



        // console.log('checkasdasdasdasdasd', planData,firstcolumn)

        //await firstincrementRow(planData.date, planData.driverId, 1, 1, planning, id, priority)
        const updatePlanData = await PlanningServices.swapPositionPlanning(planData)
        //console.log('checkUpdate', updatePlanData)

        if (updatePlanData.status == 200) {
            await handleClose()
            toast.success(updatePlanData.message, {
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true
            });
            console.log('chrckDeiiii', props.driverInfo.contactId, props.driverInfo.contactId)

            await props.planning();
            await fetchOldPlanning();

            await props.storeLocation()
            console.log('checkNewwwwwww', newDriverId)
            //    if(!(planData.driverId == planData.oldDriverID && planData.date == planData.oldDate))
            //    {
            if (newDriverId) {
                //await setCookie('driverName', newDriverId, { path: '/driverPlanning' });
                window.location.reload()
                // await props.storeLocation()
            }
            else {
                removeCookie("driverName", { path: "/driverPlanning" });
                await props.storeLocation()
            }
            //    }

            //    if(planData.driverId != planData.oldDriverID)
            //     {
            //    removeCookie("driverName", { path: "/driverPlanning" });
            //    await props.storeLocation()
            //     }
            //await refreshPlanning(planData.driverId);
            //    props.driverInfo.contactId = planData.driverId
            //    props.driverInfo.drivername = 'Albert Aguirre'
            //    await fetchOldPlanning();
            //    await props.planning();
        }
        else {
            await handleClose()
            toast.error(updatePlanData.message, {
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true
            });
        }

    }

    const setNewPosition = async (e) => {
        await setSelectPostion(e.target.value)
    }

    const getSwapDate = async (e) => {
        const date = e.target.value
        await setPlanData((prevPlanData) => ({
            ...prevPlanData,
            date: date
        }));


        // console.log(planData,'sssssssssssss')



        await updatePosition(date, planData.driverId)
    }

    const updatePosition = async (date, driverId) => {
        console.log('planDatawwww', planData)
        const getPosition = await PlanningServices.fetchPosition(date, driverId)
        console.log('getPosition', getPosition)
        if (date == planData.oldDate && driverId == planData.oldDriverID) {
            setPosition(getPosition.data)
            setPositionLength(getPosition.data)
        }
        else {
            setPosition(getPosition.data + 1)
            setPositionLength(getPosition.data)
        }


    }

    const getSwapDriver = async (e) => {
        var selectedOption = e.target.options[e.target.selectedIndex];
        var selectedName = selectedOption.text;

        const driverId = e.target.value
        await setPlanData((prevPlanData) => ({
            ...prevPlanData,
            driverId: driverId,
            driverName: selectedName
        }));


        await updatePosition(planData.date, driverId)

    }

    const sendEmail = async (driverId, id, driverName) => {
        setloading(true);

        let date = '';
        if (id == 1) {
            console.log("firstcolumn", driverId, firstcolumn)
            date = firstcolumn
        }
        else if (id == 2) {
            console.log("secondcolumn", driverId, secondcolumn)
            date = secondcolumn
        }
        const payload = {
            "driver": driverId,
            driverName,
            date
        }
        const result = await PlanningServices.sendPlanningEmail(payload);
        setloading(false);
        if (result.status == 200) {
            toast.success(result.message, {
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true
            });
        }
        else if (result.status == 404) {
            toast.warning(result.message, {
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true
            });
        }
        else if (result.status == 500) {
            toast.error(result.message, {
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true
            });
        }
        else {
            toast.error('Oops something went wrong please try again!!', {
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true
            });
        }

    }

    useEffect(() => {
        console.log('okakakkakak')
        if (props.driverInfo.contactId) {

            setloading(true);

            const result = PlanningServices.fetchPlanning(props.driverInfo.contactId);

            result.then((data) => {

                setloading(false);

                setPlanningDetails(data);
            });

        }

    }, [props.driverInfo.contactId]);


    function fetchOldPlanning() {
        setloading(true);
        const result = PlanningServices.fetchPlanning(props.driverInfo.contactId);
        result.then((data) => {
            setloading(false);
            setPlanningDetails(data);
        });
    }

    // const refreshPlanning = (driverId) => {
    //     setloading(true);
    //     const result = PlanningServices.fetchPlanning(driverId);
    //     result.then((data) => {
    //         setloading(false);
    //         setPlanningDetails(data);
    //     }); 
    // }

    console.log('checkDatatatta', props.dateinfo)
    console.log('planDataaaaaaaa', planData)

    useEffect(() => {
        fetchOldPlanning();
    }, [props.slectedLocation]);

    var currentDate = props.dateinfo;

    var day = currentDate.getDay();

    var firstDay = currentDate.getDate() + (day == 0 ? - 6 : 1) - day;

    var firstMonday = firstDay;

    var nextMonday = firstDay + 7;

    var days = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

    var colour = ['#ffbfca', '#bfc3ff', '#d2f9ec', '#ffd9be', '#ddbcfd', '#cdce9c'];

    var months = new Array("Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sep", "Oct", "Nov", "Dec");

    var firstcolumn = [];

    var secondcolumn = [];


    function daysInMonth(month, year) {

        var date = new Date(year, month, 1);

        var days = [];

        while (date.getMonth() === month) {

            days.push(new Date(date));

            date.setDate(date.getDate() + 1);

        }

        return days.length;

    }



    var lastcustomDate = ((nextMonday - daysInMonth(currentDate.getMonth(), currentDate.getFullYear())) <= 0) ? 1 : (nextMonday - daysInMonth(currentDate.getMonth(), currentDate.getFullYear()));
    for (var i = 0; i < 6; i++) {
        if (daysInMonth(currentDate.getMonth(), currentDate.getFullYear()) < nextMonday) {
            if (currentDate.getMonth() == 11) {
                secondcolumn.push(days[i] + ' ' + lastcustomDate + ' ' + months[currentDate.getMonth() - 11]);
            } else {
                secondcolumn.push(days[i] + ' ' + lastcustomDate + ' ' + months[currentDate.getMonth() + 1]);
            }
            lastcustomDate++
        } else {
            secondcolumn.push(days[i] + ' ' + nextMonday + ' ' + months[currentDate.getMonth()]);
            nextMonday++
        }

    }



    var customDate = 1;

    for (var i = 0; i < 6; i++) {

        if (daysInMonth(currentDate.getMonth(), currentDate.getFullYear()) < firstMonday) {

            if (currentDate.getMonth() == 11) {
                firstcolumn.push(days[i] + ' ' + customDate + ' ' + months[currentDate.getMonth() - 11]);
            } else {
                firstcolumn.push(days[i] + ' ' + customDate + ' ' + months[currentDate.getMonth() + 1]);
            }

            customDate++

        } else {
            if (firstMonday <= 0) {
                firstcolumn.push(days[i] + ' ' + (daysInMonth(currentDate.getMonth() - 1, currentDate.getFullYear()) - (firstMonday * -1)) + ' ' + months[currentDate.getMonth() - 1]);
                firstMonday++
            } else {
                firstcolumn.push(days[i] + ' ' + firstMonday + ' ' + months[currentDate.getMonth()]);
                firstMonday++
            }
        }

    }


    const [firstincrement, setfirstincrement] = useState([]);
    const [secondincrement, setsecondincrement] = useState([]);

    async function firstincrementRow(date, driverId, oldcount, sub, planning, id, priority) {
        console.log('checkDaddddddddddsdsdsdsd', secondincrement)
        console.log('checkDataaaaaaaaaaass', date, driverId, oldcount, sub, planning, id, priority)
        if (id) {
            const payload = {
                id,
                date,
                driverId,
                priority
            }
            console.log('payloaddddddddddddddddd111', payload)
            setfirstincrement(

                (firstincrement.find((item) => item.date == date && item.driverId == driverId)) ?

                    firstincrement.map((x) => {

                        if (x.date === date && x.driverId === driverId)

                            return {

                                ...x,

                                count: x.count + 1,

                            };

                        return x;

                    })

                    :

                    (old) => [...old, { date, count: (oldcount > 0) ? oldcount + 1 : 2, driverId }]

            );
            await PlanningServices.createBlankPlanningByPriority(payload);
            await fetchOldPlanning();
            await fetchOldPlanning();
            await fetchOldPlanning();
            await fetchOldPlanning();
            await props.storeLocation2()
        }
        //console.log('rrrrwewewewewewewewewewe', idsd)
        else if (sub == 0) {
            setfirstincrement(
                (firstincrement.find((item) => item.date == date && item.driverId == driverId)) ?

                    firstincrement.map((x) => {

                        if (x.date === date && x.driverId === driverId)

                            return {

                                ...x,

                                count: (x.count > 1) ? x.count - 1 : 1,

                            };

                        return x;

                    })

                    :

                    (old) => [...old, { date, count: (oldcount > 0) ? oldcount - 1 : 1, driverId }]

            );

        } else {
            setfirstincrement(

                (firstincrement.find((item) => item.date == date && item.driverId == driverId)) ?

                    firstincrement.map((x) => {

                        if (x.date === date && x.driverId === driverId)

                            return {

                                ...x,

                                count: x.count + 1,

                            };

                        return x;

                    })

                    :

                    (old) => [...old, { date, count: (oldcount > 0) ? oldcount + 1 : 2, driverId }]

            );
        }
    }




    async function secondincrementRow(date, driverId, oldcount, sub, planning, id, priority) {
        console.log('checkkkkkSub', date, driverId, oldcount, sub, planning, id, priority)
        if (id) {
            const payload = {
                id,
                date,
                driverId,
                priority
            }
            setsecondincrement(

                (secondincrement.find((item) => item.date == date && item.driverId == driverId)) ?

                    secondincrement.map((x) => {

                        if (x.date === date && x.driverId === driverId)

                            return {

                                ...x,

                                count: x.count + 1,

                            };

                        return x;

                    })

                    :

                    (old) => [...old, { date, count: (oldcount > 0) ? oldcount + 1 : 2, driverId }]

            );

            console.log('payloaddddddddddddddddd', payload)
            await PlanningServices.createBlankPlanningByPriority(payload);
            await fetchOldPlanning();
            await fetchOldPlanning();
            await fetchOldPlanning();
            await fetchOldPlanning();
            await props.storeLocation2()
        }


        else if (sub == 0) {


            setsecondincrement(
                (secondincrement.find((item) => item.date == date && item.driverId == driverId)) ?

                    secondincrement.map((x) => {

                        if (x.date === date && x.driverId === driverId)

                            return {

                                ...x,

                                count: (x.count > 1) ? x.count - 1 : 1,

                            };

                        return x;

                    })

                    :

                    (old) => [...old, { date, count: (oldcount > 0) ? oldcount - 1 : 1, driverId }]

            );
        } else {
            setsecondincrement(

                (secondincrement.find((item) => item.date == date && item.driverId == driverId)) ?

                    secondincrement.map((x) => {

                        if (x.date === date && x.driverId === driverId)

                            return {

                                ...x,

                                count: x.count + 1,

                            };

                        return x;

                    })

                    :

                    (old) => [...old, { date, count: (oldcount > 0) ? oldcount + 1 : 2, driverId }]

            );
        }

    }



    const [unavaliableObject, setUnavaliable] = useState([]);




    useEffect(() => {


        const result = PlanningServices.fetchUnavaliable();

        result.then((data) => {
            console.log("fetchUnavaliable", data)
            setUnavaliable(data);

        });

    }, []);



    function unavaliable(date, driverName, status) {

        const payload = {

            drivername: driverName,

            date,

            status

        };



        if (status == 0) {

            swal({

                text: "Are you sure want to unavailable on " + date,

                icon: "warning",

                dangerMode: true,

                buttons: true,

            })

                .then(innsert => {

                    if (innsert) {

                        setUnavaliable((state) => [...state, { driverName, date, status }]);



                        axios

                            .post(API_BASE_URL + `/unplanning`, payload, {

                                headers: {

                                    Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN_NAME),

                                },

                            })

                            .then(function (response) {

                                swal("Success!", "unavailable on " + date, "success");

                            });

                    }

                });

        } else {

            swal({

                text: "Are you sure want to available on " + date,

                icon: "warning",

                dangerMode: true,

                buttons: true,

            })

                .then(innsert => {

                    if (innsert) {

                        setUnavaliable((state) => state.map((x) => {

                            if (x.driverName === driverName && x.date === date)

                                return {

                                    ...x,

                                    status: 1

                                };

                            return x;

                        }));



                        axios

                            .post(API_BASE_URL + `/unplanning`, payload, {

                                headers: {

                                    Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN_NAME),

                                },

                            })

                            .then(function (response) {

                                swal("Success!", "unavailable on " + date, "success");

                            });

                    }

                });

        }

    }



    async function deletePlanning(e, date, driverId, driverName, id, length, resalePORefNumer, product, column) {

        var row = e.target.closest('tr').children;
        console.log('ssssssss', column)

        var refrence = row[2]?.children[0]?.text;
        console.log('dddddddd', refrence)
        // //console.log('refrence', refrence)
        console.log('rowwww', length)
        // return

        if (refrence == '' || typeof (row[2]?.children[0]?.text) == 'undefined') {

            console.log("hhhhhh", date, driverId, length, 0, id, resalePORefNumer)
            // if (column == 'firstColumn') {
            //     firstincrementRow(date, driverId, length == 1 ? 0 : length, 0)
            // } else {
            //     secondincrementRow(date, driverId, length == 1 ? 0 : length, 0)
            // }

            if (id) {

                await PlanningServices.deletePlanningByID(id);
                await props.planning();
                await fetchOldPlanning();
                await props.storeLocation()
                if (column == 'firstColumn') {
                    firstincrementRow(date, driverId, length == 1 ? 0 : length, 0)
                } else {
                    secondincrementRow(date, driverId, length == 1 ? 0 : length, 0)
                }
            }
            else {
                console.log('ssssss', length)
                if (column == 'firstColumn') {
                    firstincrementRow(date, driverId, length == 1 ? 0 : length, 0)
                } else {
                    secondincrementRow(date, driverId, length == 1 ? 0 : length, 0)
                }
                fetchOldPlanning();
                await props.storeLocation()
            }

            return true;
        }

        const data = await PlanningServices.getInvoiceCurrentStatus(resalePORefNumer);

        const payload = {
            invoiceId: refrence,
            drivername: driverName,
            resalePORefNumer,
            product,
            date
        };

        console.log(data, 'datainfo');

        swal({
            text: "Are you sure want to unplan on " + date,
            icon: "warning",
            dangerMode: true,
            buttons: true,
        })

            .then(async (innsert) => {
                if (innsert) {
                    if ((data[0][0] && data[0][0].transportPOBill !== null && data[0][0].transportPOStatusName == "Closed") || (data[0][0] && data[0][0].transportPOBill !== null)) {
                        swal({
                            title: "Warning!",
                            text: "This order has been finalized, it can not be unplanned",
                            icon: "warning",
                        });
                    } else {

                        if (data[0][0] && data[0][0].transportPO) {
                            const TPOResponse = await PurchaseOrderServices.updateTransportPOStatus(data[0][0].transportPO, 'Planning screen');
                            if (TPOResponse[0].status == 400) {
                                swal({
                                    title: "Warning!",
                                    text: "This order has been finalized, it can not be unplanned",
                                    icon: "warning",
                                });
                                return
                            }
                        } else {
                            await PlanningServices.blankplanning(id, { driverId, date });
                        }
                        props.planning();
                        fetchOldPlanning();
                        setPlanningDetails(planningDetails.filter((item) => item.invoiceId != resalePORefNumer));
                        setTimeout(() => {
                            toast.success(`Success!, unplan on   ${date}, success`, {
                                autoClose: 2000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true
                            });
                        });
                    }
                }

            });

    }

    function updatePlanning(e, date, driverId, driverName, id, recordID) {

        var row = e.target.closest('tr').children;

        var customerConfirmation = row[7]?.children[0]?.value;
        var driveConfirmation = row[8]?.children[0]?.value;

        if (customerConfirmation == 'NULL' || driveConfirmation == 'NULL') {
            swal({
                title: "Warning!",
                text: "Please select confirmation",
                icon: "warning",
            });
            return false;
        }

        const payload = {
            id,
            customerConfirmation,
            driveConfirmation
        };

        console.log("hhhhhhhhhhhhhhh", payload)

        swal({
            title: "Warning!",
            text: "Are you sure want to update plan?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then(async (willDelete) => {
            let verifyDepot = await PlanningServices.verifyDepot(recordID);

            if (verifyDepot.resalePOIsDepotCallCompleted == 1) {
                await PlanningServices.updatePlanning(payload, id);
                fetchOldPlanning();
                if (willDelete) {
                    try {
                        window.open("/purchaseorder/" + id);
                    } catch (error) {
                        console.log(error, 'errorinfo')
                    }
                }

            }

            else {
                setTimeout(() => {
                    toast.error(`Depot Call is not Completed`, {
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true
                    });
                });
            }
        });




    }

    async function invoiceRecordID(recordID) {
        const result = await PlanningServices.fetchInvoiceRecordID(recordID);
        window.open("/invoice/" + result[0].invoiceRecordID + "/" + result[0].invoiceLineItem_RecordID);
    }

    async function custmConfir(e, planningID) {
        if (planningID) {
            await PlanningServices.planningUpate({ customerConfirmation: e.target.value }, planningID);
            await props.storeLocation2()
            fetchOldPlanning();
        }
    }

    async function driveConfir(e, planningID) {
        if (planningID) {
            await PlanningServices.planningUpate({ driveConfirmation: e.target.value }, planningID);
            await props.storeLocation2()
            fetchOldPlanning();
        }
    }

    function doPlanning(e, date, driverId, driverName) {

        /*
        var row = e.target.closest('tr').children;

        var refrence = row[1]?.children[0]?.value;

        var conf = row[5]?.children[0]?.value;

        if(conf == 'NULL' || refrence == '') {
            swal({
                title: "Warning!",
                text: "Please select reference number or confirmation",
                icon: "warning",
            });
            return false;
        }

        var orders = props.ordersDetails.filter((item) => item.invoiceRefNumber == refrence);

        const payload = {

            contactId: driverId,

            locationId: 123,

            dispatch: conf,

            invoiceId: refrence,

            City: (orders[0]) ? orders[0].City : '',

            drivername: driverName,

            product: (orders[0]) ? orders[0].invoiceLineItemItemNameWithoutParent : '',

            State: (orders[0]) ? orders[0].State : '',

            DropState: (orders[0]) ? orders[0].DropState : '',

            DropCity: (orders[0]) ? orders[0].DropCity : '',

            Dist: (orders[0]) ? orders[0].Dist : '',

            resalePORefNumer: (orders[0]) ? orders[0].resalePORefNumer : '',

            date

        };

        swal({

            title: "Warning!",

            text: "Are you sure want to plan?",

            icon: "warning",

            buttons: true,

            dangerMode: true,

        }).then(async (willDelete) => {

            if (willDelete) {

                try {

                    const result = await PlanningServices.savePlanning(payload);

                    if (result[0].status == 1) {

                        await PurchaseOrderServices.updatePlanningOrderStatus(result[0].orderId, 15);

                        window.open("/purchaseorder/" + result[0].lastinsterid);

                    }

                } catch (error) {

                    console.log(error, 'errorinfo')

                }

            }

        });
        
        */
    }

    const [planningLoading, setplanningLoading] = useState(false);

    async function handleOrder(e, value, date, driverId, driverName, index, planningID) {
        console.log('dddddddddd', driverId)
        if (planningID) {
            const orders = props.ordersDetails.filter((item) => item.invoiceRefNumber == value.invoiceRefNumber && item.invoicespo_record_id == value.invoicespo_record_id);
            await PlanningServices.updateblankplanning({
                contactId: driverId,
                locationId: 123,
                customerConfirmation: 0,
                driveConfirmation: 0,
                size: (orders[0]) ? orders[0].invoiceLineItemItemName : '',
                invoiceId: value.invoicespo_record_id,
                City: (orders[0]) ? orders[0].resalePOPickupCity : '',
                State: (orders[0]) ? orders[0].resalePOPickupState : '',
                drivername: driverName,
                product: (orders[0]) ? orders[0].invoiceLineItemItemNameWithoutParent : '',
                DropState: (orders[0]) ? orders[0].DropState : '',
                DropCity: (orders[0]) ? orders[0].DropCity : '',
                Dist: (orders[0]) ? orders[0].Dist : '',
                resalePORefNumer: (orders[0]) ? orders[0].resalePORefNumer : '',
                isExpedited: (orders[0]) ? orders[0].invoiceIsExpedited : '',
                resalePO: (orders[0]) ? orders[0].resalePO : '',
                invoiceLineItem_RecordID: (orders[0]) ? orders[0].invoiceLineItem_RecordID : '',
                date
            }, planningID);
            await props.planning();
            await fetchOldPlanning();
            await props.storeLocation2();
        } else {
            const result = await PlanningServices.fetchPlanningByDriverAndate(driverId, date);
            if (result.length < index) {
                for (var i = 0; i < (index - result.length); i++) {
                    await PlanningServices.saveblankplanning({
                        contactId: driverId,
                        plannedStatus: '',
                        locationId: '',
                        customerConfirmation: '',
                        driveConfirmation: '',
                        vendorFullName: '',
                        orderId: '',
                        size: '',
                        invoiceId: '',
                        City: '',
                        drivername: '',
                        product: '',
                        State: '',
                        DropState: '',
                        DropCity: '',
                        Dist: '',
                        resalePORefNumer: '',
                        isExpedited: '',
                        date
                    });
                }
            }


            var orders = props.ordersDetails.filter((item) => item.invoiceRefNumber == value.invoiceRefNumber && item.invoicespo_record_id == value.invoicespo_record_id);

            if (orders.length >= 1) {

                var row = $(e.explicitOriginalTarget).closest("tr");
                //console.log('ddddddddhdhdhdhdhdhhd', $(row[1]).find("#pickUp"))
                //return

                $(row[1]).find("#pickUp").html((orders[0]) ? orders[0].resalePOPickupCity : '');

                $(row[1]).find("#dropOff").html((orders[0]) ? orders[0].DropCity : '');

                $(row[1]).find("#dist").html((orders[0]) ? orders[0].Dist : '');

                $(row[1]).find("#size").html((orders[0]) ? orders[0].invoiceLineItemItemName : '');

                const payload = {
                    contactId: driverId,
                    locationId: 123,
                    customerConfirmation: 0,
                    driveConfirmation: 0,
                    size: (orders[0]) ? orders[0].invoiceLineItemItemName : '',
                    invoiceId: value.invoicespo_record_id,
                    City: (orders[0]) ? orders[0].resalePOPickupCity : '',
                    drivername: driverName,
                    product: (orders[0]) ? orders[0].invoiceLineItemItemNameWithoutParent : '',
                    State: (orders[0]) ? orders[0].resalePOPickupState : '',
                    DropState: (orders[0]) ? orders[0].DropState : '',
                    DropCity: (orders[0]) ? orders[0].DropCity : '',
                    Dist: (orders[0]) ? orders[0].Dist : '',
                    resalePORefNumer: (orders[0]) ? orders[0].resalePORefNumer : '',
                    isExpedited: (orders[0]) ? orders[0].invoiceIsExpedited : '',
                    resalePO: (orders[0]) ? orders[0].resalePO : '',
                    invoiceLineItem_RecordID: (orders[0]) ? orders[0].invoiceLineItem_RecordID : '',
                    date
                };

                console.log('payloaddddddddd', payload)

                const resultinfo = await PlanningServices.fetchPlanningByRecordID(value.invoicespo_record_id);
                if (resultinfo.length == 1) {
                    await PlanningServices.deletePlanning({ resalePORefNumer: value.invoicespo_record_id });
                    // await PlanningServices.deletePlanningLogs({ resalePO : (order[0]) ? order[0].resalePO : '', transportPo: (order[0]) ? order[0].transportPO : '', date })
                }

                setplanningLoading(true);
                const result = await PlanningServices.savePlanning(payload);

                if (result[0].status == 1) {
                    setplanningLoading(false);
                    //await PurchaseOrderServices.updatePlanningOrderStatus(result[0].orderId, 15);
                    await props.planning();
                    await fetchOldPlanning();
                    await props.storeLocation2();
                }

            }
        }
    }

    // if (loading) {

    //     return (<i class="fa fa-spinner fa-spin center-position" />);

    // }
    console.log(props, 'gggggggggg');
    console.log(planningDetails, 'planningDetailsplanningDetails');
    return (

        <div style={{ marginBottom: '20px' }} className="box-effect">

            <h5 className="text-center titleFont">{props.driverInfo.drivername}</h5>

            <div className="row">

                <div style={{ padding: '0px' }} className="col-md-6">

                    <div className="space">

                        <div className="table-responsive customTable">

                            <form>

                                <table className="table table-bordered">

                                    <thead>

                                        <tr>
                                            <th>Swap</th>
                                            <th>Day</th>

                                            <th style={{ padding: '10px 25px' }}>Order</th>

                                            <th>Pick City</th>

                                            <th>Drop City</th>

                                            <th>Miles</th>

                                            <th>Size</th>

                                            <th>&nbsp;&nbsp;Cust&nbsp;&nbsp;</th>

                                            <th>Driver</th>

                                            {/* <th>Dispach?</th> */}

                                            <th>Action</th>

                                        </tr>

                                    </thead>

                                    <tbody>

                                        {

                                            firstcolumn.map((date, index) =>

                                                Array.from(Array((firstincrement.find((item) => item.date == date && item.driverId == props.driverInfo.contactId)) ? firstincrement.find((item) => item.date == date && item.driverId == props.driverInfo.contactId).count : (planningDetails.filter((item) => item.Dt == date)?.length) ? planningDetails.filter((item) => item.Dt == date)?.length : 1), (e, i) =>

                                                    <tr style={{ "background-color": (planningDetails.filter((item) => item.Dt == date)[i]?.planningTerms?.replace(/\s/g, '')?.toLowerCase() == "renttoown" && planningDetails.filter((item) => item.Dt == date)[i]?.towTruck == 1) ? "#F7AE77" : (planningDetails.filter((item) => item.Dt == date)[i]?.planningTerms?.replace(/\s/g, '')?.toLowerCase() == "renttoown" && planningDetails.filter((item) => item.Dt == date)[i]?.solidSurface == 1) ? "#90EE90" : (planningDetails.filter((item) => item.Dt == date)[i]?.towTruck == 1 && planningDetails.filter((item) => item.Dt == date)[i]?.solidSurface == 1) ? '#F0BBF0' : (planningDetails.filter((item) => item.Dt == date)[i]?.planningTerms?.replace(/\s/g, '')?.toLowerCase() == "renttoown") ? '#ffda73' : (planningDetails.filter((item) => item.Dt == date)[i]?.solidSurface == 1) ? '#7bede9' : (planningDetails.filter((item) => item.Dt == date)[i]?.towTruck == 1) ? 'pink' : colour[index] }}

                                                        className={(planningDetails.filter((item) => item.Dt == date)[i]?.isExpedited == 1 && planningDetails.filter((item) => item.Dt == date)[i]?.sameTrailerMarker == 1) ? 'plMarkerExpClass' : (planningDetails.filter((item) => item.Dt == date)[i]?.isExpedited == 1) ? 'plExpeditedClass' : (planningDetails.filter((item) => item.Dt == date)[i]?.sameTrailerMarker == 1) ? 'plSameMarkerClass' : 'plDefaultColorClass'} >
                                                        {/* // <tr style={{ "background-color": planningDetails.filter((item) => item.Dt == date)[i]?.planningTerms?.replace(/\s/g, '')?.toLowerCase() == "renttoown" ? "#ffda73" : colour[index] }} > */}
                                                        {
                                                            (planningDetails.filter((item) => item.Dt == date)[i]?.invoiceId)
                                                                ?
                                                                <td>{planningDetails.filter((item) => item.Dt == date)[i]?.id ? <><button style={{ "background-color": "white", "color": "red" }} type="button" className="btn-sm action-icon-decrase  btn-primary" onClick={(e) => handleOpen(e, date, planningDetails.filter((item) => item.Dt == date)[i]?.id, planningDetails.filter((item) => item.Dt == date)[i]?.resalePORefNumer, props.driverInfo.contactId, planningDetails.filter((item) => item.Dt == date)[i]?.driverName, planningDetails.filter((item) => item.Dt == date)[i]?.invoiceId)}><i className="fa fa-exchange" ></i></button>&nbsp;</> : ''}</td>
                                                                :
                                                                <td></td>
                                                        }

                                                        <td style={{ "font-weight": "500", "font-size": "12px" }}>

                                                            <>
                                                                <span>{date}</span><br />

                                                                {

                                                                    (unavaliableObject.filter((element) => element.date == date && element.driverName == props.driverInfo.drivername && element.status == 0).length > 0) ?

                                                                        <a onClick={() => unavaliable(date, props.driverInfo.drivername, 1)} style={{ "cursor": "pointer" }} >

                                                                            <span href="#" style={{ 'background-color': 'red' }} className="badge badge-success">Unavailable</span>

                                                                        </a>

                                                                        :

                                                                        <a onClick={() => unavaliable(date, props.driverInfo.drivername, 0)} style={{ "cursor": "pointer" }} >

                                                                            <span href="#" className="badge badge-success">Available</span>

                                                                        </a>

                                                                }

                                                            </>

                                                        </td>
                                                        {/* :
                                                                <td></td>
                                                        } */}

                                                        <td>
                                                            {
                                                                (planningDetails.filter((item) => item.Dt == date)[i]?.invoiceId) ?
                                                                    <a href="javascript:void(0)" onClick={() => invoiceRecordID(planningDetails.filter((item) => item.Dt == date)[i]?.invoiceId)}>{planningDetails.filter((item) => item.Dt == date && item.driverId == props.driverInfo.contactId)[i]?.resalePORefNumer}</a>
                                                                    :
                                                                    (unavaliableObject.filter((element) => element.date == date && element.driverName == props.driverInfo.drivername && element.status == 0).length > 0) ?
                                                                        <input disabled onChange={(e) => handleOrder(e, date, props.driverInfo.contactId, props.driverInfo.drivername)} list="firstbrowsers" name="refrenceNumber" id="browser" className="form-control form-group d-flex input-order-decrase" />
                                                                        :

                                                                        <CreatableSelect
                                                                            onChange={(value) => {
                                                                                handleOrder(new Event('Click'), value, date, props.driverInfo.contactId, props.driverInfo.drivername, i, planningDetails.filter((item) => item.Dt == date)[i]?.id)
                                                                            }}

                                                                            onMenuOpen={() => {
                                                                                setaddclass(true);
                                                                            }}

                                                                            onMenuClose={() => {
                                                                                setaddclass(false);
                                                                            }}

                                                                            getOptionLabel={option =>
                                                                                <p>{option.label} <br />
                                                                                    {option.resalePOPickupCity},&nbsp;{option.resalePOPickupState} <br />
                                                                                    {option.DropCity},&nbsp;{option.DropState} <br />
                                                                                    {option.invoiceLineItemItemNameWithoutParent} <br />
                                                                                    {option.resalePORefNumer} <br /></p>
                                                                            }
                                                                            options={props.ordersDetails.map((item) => {
                                                                                return ({
                                                                                    label: item.invoiceRefNumber,
                                                                                    value: item.invoiceRefNumber,
                                                                                    ...item
                                                                                })
                                                                            })}

                                                                            styles={customStyles}
                                                                        />

                                                            }
                                                            <br />
                                                            {/* <b style={{ "color": "red" }}> {(planningDetails.filter((item) => item.Dt == date && item.driverId == props.driverInfo.contactId)[i]?.isExpedited == 1) ? 'Expedited' : ''} </b> */}
                                                        </td>

                                                        <td id="pickUp">
                                                            {planningDetails.filter((item) => item.Dt == date)[i]?.Pc}<br />
                                                            {planningDetails.filter((item) => item.Dt == date)[i]?.Ps}
                                                        </td>

                                                        <td id="dropOff">
                                                            {planningDetails.filter((item) => item.Dt == date)[i]?.Dc}<br />
                                                            {planningDetails.filter((item) => item.Dt == date)[i]?.DS}
                                                        </td>

                                                        <td id="dist">{planningDetails.filter((item) => item.Dt == date)[i]?.Dist}</td>

                                                        <td id="size">
                                                            {/* {planningDetails.filter((item) => item.Dt == date)[i]?.size.split(':')[0]}<br /> */}
                                                            {planningDetails.filter((item) => item.Dt == date)[i]?.size.split(':')[1]}
                                                        </td>

                                                        <td>
                                                            {
                                                                (unavaliableObject.filter((element) => element.date == date && element.driverName == props.driverInfo.drivername && element.status == 0).length > 0) ?
                                                                    <select style={{ "padding": "0px" }} className="form-control form-group d-flex" disabled></select>
                                                                    :
                                                                    <select style={{ "padding": "0px" }} onChange={(e) => custmConfir(e, planningDetails.filter((item) => item.Dt == date)[i]?.id)} name="customerConfirmation" className="form-control form-group d-flex">

                                                                        {

                                                                            (planningDetails.filter((item) => item.Dt == date)[i]?.customerConfirmation == 1) ?

                                                                                <>

                                                                                    <option value="0"></option>

                                                                                    <option selected value="1">&nbsp;&nbsp;&nbsp;&nbsp;Y</option>

                                                                                </>
                                                                                :
                                                                                <>
                                                                                    <option value="0"></option>

                                                                                    <option value="1">&nbsp;&nbsp;&nbsp;&nbsp;Y</option>

                                                                                </>
                                                                        }

                                                                    </select>
                                                            }


                                                        </td>


                                                        <td>
                                                            {
                                                                (unavaliableObject.filter((element) => element.date == date && element.driverName == props.driverInfo.drivername && element.status == 0).length > 0) ?
                                                                    <select style={{ "padding": "0px" }} className="form-control form-group d-flex" disabled></select>
                                                                    :
                                                                    <select style={{ "padding": "0px" }} onChange={(e) => driveConfir(e, planningDetails.filter((item) => item.Dt == date)[i]?.id)} name="driveConfirmation" className="form-control form-group d-flex">

                                                                        {

                                                                            (planningDetails.filter((item) => item.Dt == date)[i]?.driveConfirmation == 1) ?

                                                                                <>

                                                                                    <option value="0"></option>

                                                                                    <option selected value="1">&nbsp;&nbsp;&nbsp;&nbsp;Y</option>

                                                                                </>

                                                                                :

                                                                                <>

                                                                                    <option value="0"></option>

                                                                                    <option value="1">&nbsp;&nbsp;&nbsp;&nbsp;Y</option>

                                                                                </>

                                                                        }

                                                                    </select>
                                                            }
                                                        </td>

                                                        <td>

                                                            <button type="button" style={{ "background-color": "white" }} onClick={() => firstincrementRow(date, props.driverInfo.contactId, planningDetails.filter((item) => item.Dt == date)?.length, 1, planningDetails, planningDetails.filter((item) => item.Dt == date)[i]?.id, planningDetails.filter((item) => item.Dt == date)[i]?.priority)} id="addBtn" className="btn-sm action-icon-decrase  plus-btn"><i className="fa fa-plus"></i></button>&nbsp;

                                                            {

                                                                (planningDetails.filter((item) => item.Dt == date)[i]?.invoiceId) ?

                                                                    <><button style={{ "background-color": "white", "color": "red" }} type="button" onClick={(e) => updatePlanning(e, date, props.driverInfo.contactId, props.driverInfo.drivername, planningDetails.filter((item) => item.Dt == date)[i]?.id, planningDetails.filter((item) => item.Dt == date)[i]?.invoiceId)} className="btn-sm action-icon-decrase  btn-primary"><i className="fa fa-truck"></i></button>&nbsp;</>

                                                                    :

                                                                    <><button style={{ "background-color": "white", "color": "red" }} type="button" onClick={(e) => doPlanning(e, date, props.driverInfo.contactId, props.driverInfo.drivername)} className="btn-sm action-icon-decrase  btn-primary"><i className="fa fa-truck"></i></button>&nbsp;</>

                                                            }

                                                            <><button style={{ "background-color": "white", "color": "red" }} type="button" onClick={(e) => deletePlanning(e, date, props.driverInfo.contactId, props.driverInfo.drivername, planningDetails.filter((item) => item.Dt == date)[i]?.id, planningDetails.filter((item) => item.Dt == date)?.length, planningDetails.filter((item) => item.Dt == date)[i]?.invoiceId, planningDetails.filter((item) => item.Dt == date)[i]?.product, 'firstColumn')} className="btn-sm action-icon-decrase  btn-primary"><i className="fa fa-times"></i></button>&nbsp;</>


                                                        </td>

                                                    </tr>

                                                )

                                            )

                                        }

                                    </tbody>

                                </table>
                                <a style={{ cursor: "pointer", padding: "12px" }} onClick={() => sendEmail(props.driverInfo.contactId, 1, props.driverInfo.drivername)} ><span href="#" className="badge badge-success" style={{ backgroundColor: "red", fontSize: "12px", padding: "12px", marginBottom: "12px" }}>Send Email</span></a>
                            </form>

                        </div>

                    </div>

                </div>
                <ToastContainer />
                <div style={{ padding: '0px' }} className="col-md-6">


                    <div className="space">

                        <div className="table-responsive customTable">

                            <form>

                                <table className="table table-bordered">

                                    <thead>

                                        <tr>
                                            <th style={{ padding: "10px 4px" }}>Swap</th>
                                            <th>Day</th>

                                            <th style={{ padding: '10px 25px' }}>Order</th>

                                            <th>Pick City</th>

                                            <th>Drop City</th>

                                            <th>Miles</th>

                                            <th>Size</th>

                                            <th>&nbsp;&nbsp;Cust&nbsp;&nbsp;</th>

                                            <th>Driver</th>

                                            {/* <th>Dispach?</th> */}

                                            <th>Action</th>

                                        </tr>

                                    </thead>

                                    <tbody>

                                        {


                                            secondcolumn.map((date, index) =>

                                                Array.from(Array((secondincrement.find((item) => item.date == date && item.driverId == props.driverInfo.contactId)) ? secondincrement.find((item) => item.date == date && item.driverId == props.driverInfo.contactId).count : (planningDetails.filter((item) => item.Dt == date)?.length) ? planningDetails.filter((item) => item.Dt == date)?.length : 1), (_e, i) =>

                                                    <tr style={{ "background-color": (planningDetails.filter((item) => item.Dt == date)[i]?.planningTerms?.replace(/\s/g, '')?.toLowerCase() == "renttoown" && planningDetails.filter((item) => item.Dt == date)[i]?.towTruck == 1) ? "#F7AE77" : (planningDetails.filter((item) => item.Dt == date)[i]?.planningTerms?.replace(/\s/g, '')?.toLowerCase() == "renttoown" && planningDetails.filter((item) => item.Dt == date)[i]?.solidSurface == 1) ? "#90EE90" : (planningDetails.filter((item) => item.Dt == date)[i]?.towTruck == 1 && planningDetails.filter((item) => item.Dt == date)[i]?.solidSurface == 1) ? '#F0BBF0' : (planningDetails.filter((item) => item.Dt == date)[i]?.planningTerms?.replace(/\s/g, '')?.toLowerCase() == "renttoown") ? '#ffda73' : (planningDetails.filter((item) => item.Dt == date)[i]?.solidSurface == 1) ? '#7bede9' : (planningDetails.filter((item) => item.Dt == date)[i]?.towTruck == 1) ? 'pink' : colour[index] }}

                                                        className={(planningDetails.filter((item) => item.Dt == date)[i]?.isExpedited == 1 && planningDetails.filter((item) => item.Dt == date)[i]?.sameTrailerMarker == 1) ? 'plMarkerExpClass' : (planningDetails.filter((item) => item.Dt == date)[i]?.isExpedited == 1) ? 'plExpeditedClass' : (planningDetails.filter((item) => item.Dt == date)[i]?.sameTrailerMarker == 1) ? 'plSameMarkerClass' : 'plDefaultColorClass'}
                                                    >
                                                        {/* <tr style={{ "background-color": planningDetails.filter((item) => item.Dt == date)[i]?.planningTerms?.replace(/\s/g, '')?.toLowerCase() == "renttoown" ? "#ffda73" : colour[index] }} >       */}
                                                        {/* {
                                                            (i == 0) ? */}
                                                        {
                                                            (planningDetails.filter((item) => item.Dt == date)[i]?.invoiceId)
                                                                ?
                                                                <td>{planningDetails.filter((item) => item.Dt == date)[i]?.id ? <><button style={{ "background-color": "white", "color": "red" }} type="button" className="btn-sm action-icon-decrase  btn-primary" onClick={(e) => handleOpen(e, date, planningDetails.filter((item) => item.Dt == date)[i]?.id, planningDetails.filter((item) => item.Dt == date)[i]?.resalePORefNumer, props.driverInfo.contactId, planningDetails.filter((item) => item.Dt == date)[i]?.driverName, planningDetails.filter((item) => item.Dt == date)[i]?.invoiceId)}><i className="fa fa-exchange" ></i></button>&nbsp;</> : ''}</td>
                                                                :
                                                                <td></td>
                                                        }

                                                        <td style={{ "font-weight": "500", "font-size": "12px" }}>

                                                            <>

                                                                <span>{date}</span><br />

                                                                {

                                                                    (unavaliableObject.filter((element) => element.date == date && element.driverName == props.driverInfo.drivername && element.status == 0).length > 0) ?

                                                                        <a onClick={() => unavaliable(date, props.driverInfo.drivername, 1)} style={{ "cursor": "pointer" }} >

                                                                            <span href="#" style={{ 'background-color': 'red' }} className="badge badge-success">Unavailable</span>

                                                                        </a>

                                                                        :

                                                                        <a onClick={() => unavaliable(date, props.driverInfo.drivername, 0)} style={{ "cursor": "pointer" }} >

                                                                            <span href="#" className="badge badge-success">Available</span>

                                                                        </a>

                                                                }

                                                            </>

                                                        </td>
                                                        {/* :
                                                                <td></td>
                                                        } */}

                                                        <td>

                                                            {

                                                                (planningDetails.filter((item) => item.Dt == date)[i]?.invoiceId) ?

                                                                    <a href="javascript:void(0)" onClick={() => invoiceRecordID(planningDetails.filter((item) => item.Dt == date)[i]?.invoiceId)}>{planningDetails.filter((item) => item.Dt == date && item.driverId == props.driverInfo.contactId)[i]?.resalePORefNumer}</a>

                                                                    :

                                                                    (unavaliableObject.filter((element) => element.date == date && element.driverName == props.driverInfo.drivername && element.status == 0).length > 0) ?

                                                                        <input disabled onChange={(e) => handleOrder(e, date, props.driverInfo.contactId, props.driverInfo.drivername)} list="browsers" name="refrenceNumber" id="browser" className="form-control form-group d-flex input-order-decrase" />
                                                                        :
                                                                        <CreatableSelect
                                                                            onChange={(value) => {
                                                                                handleOrder(new Event('Click'), value, date, props.driverInfo.contactId, props.driverInfo.drivername, i, planningDetails.filter((item) => item.Dt == date)[i]?.id);
                                                                            }}
                                                                            onMenuOpen={() => {
                                                                                setaddclass(true);
                                                                            }}

                                                                            onMenuClose={() => {
                                                                                setaddclass(false);
                                                                            }}
                                                                            getOptionLabel={option =>
                                                                                <p>{option.label} <br />
                                                                                    {option.resalePOPickupCity},&nbsp;{option.resalePOPickupState} <br />
                                                                                    {option.DropCity},&nbsp;{option.DropState} <br />
                                                                                    {option.invoiceLineItemItemNameWithoutParent} <br />
                                                                                    {option.resalePORefNumer} <br /></p>
                                                                            }
                                                                            options={props.ordersDetails.map((item) => {
                                                                                return ({
                                                                                    label: item.invoiceRefNumber,
                                                                                    value: item.invoiceRefNumber,
                                                                                    ...item
                                                                                })
                                                                            })}

                                                                            styles={customStyles}
                                                                        />

                                                            }
                                                            <br />
                                                            <b style={{ "color": "red" }}> {(planningDetails.filter((item) => item.Dt == date && item.driverId == props.driverInfo.contactId)[i]?.isExpedited == 1) ? 'Expedited' : ''} </b>
                                                        </td>

                                                        <td id="#pickUp">
                                                            {planningDetails.filter((item) => item.Dt == date)[i]?.Pc}<br />
                                                            {planningDetails.filter((item) => item.Dt == date)[i]?.Ps}
                                                        </td>

                                                        <td id="#dropOff">
                                                            {planningDetails.filter((item) => item.Dt == date)[i]?.Dc}<br />
                                                            {planningDetails.filter((item) => item.Dt == date)[i]?.DS}
                                                        </td>

                                                        <td id="#dist">{planningDetails.filter((item) => item.Dt == date)[i]?.Dist}</td>

                                                        <td id="#size">
                                                            {/* {planningDetails.filter((item) => item.Dt == date)[i]?.size.split(':')[0]}<br /> */}
                                                            {planningDetails.filter((item) => item.Dt == date)[i]?.size.split(':')[1]}
                                                        </td>


                                                        <td>
                                                            {
                                                                (unavaliableObject.filter((element) => element.date == date && element.driverName == props.driverInfo.drivername && element.status == 0).length > 0) ?
                                                                    <select style={{ "padding": "0px" }} className="form-control form-group d-flex" disabled></select>
                                                                    :
                                                                    <select style={{ "padding": "0px" }} name="customerConfirmation" onChange={(e) => custmConfir(e, planningDetails.filter((item) => item.Dt == date)[i]?.id)} className="form-control form-group d-flex">

                                                                        {

                                                                            (planningDetails.filter((item) => item.Dt == date)[i]?.customerConfirmation == 1) ?

                                                                                <>
                                                                                    <option value="0"></option>

                                                                                    <option selected value="1">&nbsp;&nbsp;&nbsp;&nbsp;Y</option>

                                                                                </>

                                                                                :

                                                                                <>
                                                                                    <option value="0"></option>

                                                                                    <option value="1">&nbsp;&nbsp;&nbsp;&nbsp;Y</option>
                                                                                </>

                                                                        }

                                                                    </select>
                                                            }


                                                        </td>


                                                        <td>
                                                            {
                                                                (unavaliableObject.filter((element) => element.date == date && element.driverName == props.driverInfo.drivername && element.status == 0).length > 0) ?
                                                                    <select style={{ "padding": "0px" }} className="form-control form-group d-flex" disabled></select>
                                                                    :
                                                                    <select style={{ "padding": "0px" }} name="driveConfirmation" onChange={(e) => driveConfir(e, planningDetails.filter((item) => item.Dt == date)[i]?.id)} className="form-control form-group d-flex">

                                                                        {

                                                                            (planningDetails.filter((item) => item.Dt == date)[i]?.driveConfirmation == 1) ?

                                                                                <>
                                                                                    <option value="0"></option>
                                                                                    <option selected value="1">&nbsp;&nbsp;&nbsp;&nbsp;Y</option>
                                                                                </>

                                                                                :
                                                                                <>
                                                                                    <option value="0"></option>
                                                                                    <option value="1">&nbsp;&nbsp;&nbsp;&nbsp;Y</option>
                                                                                </>

                                                                        }

                                                                    </select>
                                                            }
                                                        </td>

                                                        <td>


                                                            <button type="button" style={{ "background-color": "white" }} onClick={() => secondincrementRow(date, props.driverInfo.contactId, planningDetails.filter((item) => item.Dt == date)?.length, 1, planningDetails, planningDetails.filter((item) => item.Dt == date)[i]?.id, planningDetails.filter((item) => item.Dt == date)[i]?.priority)} id="addBtn" className="btn-sm action-icon-decrase  action-icon-decrase  plus-btn"><i className="fa fa-plus"></i></button>&nbsp;

                                                            {

                                                                (planningDetails.filter((item) => item.Dt == date)[i]?.invoiceId) ?

                                                                    <><button style={{ "background-color": "white", "color": "red" }} type="button" onClick={(e) => updatePlanning(e, date, props.driverInfo.contactId, props.driverInfo.drivername, planningDetails.filter((item) => item.Dt == date)[i]?.id, planningDetails.filter((item) => item.Dt == date)[i]?.invoiceId)} className="btn-sm action-icon-decrase  btn-primary"><i className="fa fa-truck"></i></button>&nbsp;</>

                                                                    :

                                                                    <><button style={{ "background-color": "white", "color": "red" }} type="button" onClick={(e) => doPlanning(e, date, props.driverInfo.contactId, props.driverInfo.drivername)} className="btn-sm action-icon-decrase  btn-primary"><i className="fa fa-truck"></i></button>&nbsp;</>

                                                            }

                                                            <><button style={{ "background-color": "white", "color": "red" }} type="button" onClick={(e) => deletePlanning(e, date, props.driverInfo.contactId, props.driverInfo.drivername, planningDetails.filter((item) => item.Dt == date)[i]?.id, planningDetails.filter((item) => item.Dt == date)?.length, planningDetails.filter((item) => item.Dt == date)[i]?.invoiceId, planningDetails.filter((item) => item.Dt == date)[i]?.product, 'secondColumn')} className="btn-sm action-icon-decrase  btn-primary"><i className="fa fa-times"></i></button>&nbsp;</>


                                                        </td>

                                                    </tr>

                                                )

                                            )

                                        }

                                    </tbody>

                                </table>
                                <a style={{ cursor: "pointer", padding: "12px" }} onClick={() => sendEmail(props.driverInfo.contactId, 2, props.driverInfo.drivername)} ><span href="#" className="badge badge-success" style={{ backgroundColor: "red", fontSize: "12px", padding: "12px", marginBottom: "12px" }}>Send Email</span></a>
                            </form>
                            <Modal
                                open={open}
                                onClose={handleClose}
                                aria-labelledby="child-modal-title"
                                aria-describedby="child-modal-description"
                                className="cstmModal_Sec"
                            >
                                <Box className="modal_ContentBox" sx={{ ...style, width: 600 }}>
                                    <Box className="ModalHeader_Box">
                                        <h2 id="child-modal-title">Swap planning for {planData ? planData.refNumber : ''}</h2>
                                        {/* <p id="child-modal-description">
                                            Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                                        </p> */}
                                    </Box>

                                    <Box className="modalFormBox">
                                        <Box className="customFormControlBox">
                                            <FormControl fullWidth className='customFormControl planDate'>
                                                <InputLabel variant="standard">
                                                    Planning Date
                                                </InputLabel>

                                                <TextField
                                                    className='customDate'
                                                    type="date"
                                                    defaultValue={planData ? planData.date : 'yyyy-mm-dd'}
                                                    // inputProps={{ min: moment().format('yyyy-MM-DD') }}
                                                    onChange={getSwapDate}
                                                />

                                                {/* <NativeSelect
                                                    defaultValue={30}
                                                    inputProps={{
                                                        name: 'age',
                                                        id: 'uncontrolled-native',
                                                    }}
                                                >
                                                    <option value={10}>Ten</option>
                                                    <option value={20}>Twenty</option>
                                                    <option value={30}>Thirty</option>
                                                </NativeSelect> */}
                                            </FormControl>
                                        </Box>

                                        <Box className="customFormControlBox">
                                            <FormControl fullWidth className='customFormControl'>
                                                <InputLabel variant="standard">
                                                    Driver
                                                </InputLabel>
                                                <NativeSelect
                                                    defaultValue={planData ? planData.driverId : ''}
                                                    inputProps={{
                                                        name: 'driver',
                                                        id: 'uncontrolled-native',
                                                    }}
                                                    onChange={getSwapDriver}
                                                >
                                                    {
                                                        props.driver.map((item) => <option id={item.contactId} value={item.contactId} >{item.drivername}</option>)
                                                    }
                                                    {/* <option value={10}>Ten</option>
                                                    <option value={20}>Twenty</option>
                                                    <option value={30}>Thirty</option> */}
                                                </NativeSelect>
                                            </FormControl>
                                        </Box>

                                        <Box className="customFormControlBox">
                                            <FormControl fullWidth className='customFormControl'>
                                                <InputLabel variant="standard">
                                                    Position
                                                </InputLabel>
                                                <NativeSelect
                                                    defaultValue={1}
                                                    inputProps={{
                                                        name: 'position',
                                                        id: 'uncontrolled-native',
                                                    }}
                                                    onChange={setNewPosition}
                                                >

                                                    {Array.from({ length: position }, (_, i) => (
                                                        <option value={i + 1}>
                                                            {i + 1}
                                                        </option>
                                                    ))}


                                                </NativeSelect>
                                            </FormControl>
                                        </Box>
                                    </Box>

                                    <Box className="modalFooter_BTNBox">
                                        <Button className='saveBTN' onClick={updatePlanningPosition}>Save</Button>
                                        <Button className='cancelBTN' onClick={handleClose}>Close</Button>
                                    </Box>
                                </Box>


                                {/* <Box className="modalHeaderBox"></Box> */}



                                {/* <Box className="modalButtonBox"></Box> */}



                            </Modal>
                        </div>

                    </div>

                </div>

            </div>

            <style jsx="true">{`
                td {
                    text-align: center;
                }
                .customTable td {
                    font-size: 12px !important;
                    font-family: 'Source Sans Pro', sans-serif;
                }
                .titleFont {
                    font-size: 16px !important;
                    font-family: 'Source Sans Pro', sans-serif; 
                }

                .customTable th {
                    font-size: 12px !important;
                    font-family: 'Source Sans Pro', sans-serif;
                }
                .css-1wy0on6{
                    display:none;
                }
                `}

            </style>
            {
                (addclass) ?
                    <style jsx="true">{` 
                        .css-2b097c-container {
                            position: absolute;
                            width: 24%;
                        }
                    `}
                    </style>
                    :
                    ''
            }

        </div>

    );

}

export default withRouter(PlanningBox);

