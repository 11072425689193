import React, { useState, useEffect } from "react";
import $ from 'jquery';
import { Route, Link } from "react-router-dom";

function DeactiveUserTable(props) {
  return (
    <table id="deactiveUserTable" className="table table-hover table-bordered" width="100%">
      <thead>
        <tr>
          <th>Name</th>
          <th>Email</th>
          <th>Office Name</th>
          <th>Supplier</th>
        </tr>
      </thead>
      <tbody>
        {
          console.log(props.deActiveUser,'deActiveUser')
        }
        {props.deActiveUser &&
          props.deActiveUser.map((user) => (
            <tr key={user.id}>
              <td><Link to={`/users/edit/${user.id}`}>{user.name}</Link></td>
              <td><Link to={`/users/edit/${user.id}`}>{user.email}</Link></td>
              <td><Link to={`/users/edit/${user.id}`}>{user.officeLocation.officeName}</Link></td>
              <td><Link to={`/users/edit/${user.id}`}>
                {
                  user.userSupplierLocations && user.userSupplierLocations.map((data , i) =>(
                 
                      (i > 3 && i % 4 == 1) ? 
                        <br/>
                      :
                      <span>{data.supplierLocation.SupplierLocation} | </span>
                  ))
                }
                </Link>
              </td>
    
            </tr>
          ))}
      </tbody>
    </table>
  );
}

export default DeactiveUserTable;