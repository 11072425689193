import React, { useState, useEffect } from 'react';
import $ from 'jquery';
import moment from 'moment';
import ReportService from './ReportService';

const PlanningTable = (props) => {

    // const [count, setCount] =useState(0)
    let count = 1
  console.log('okkkkk', props.state.deletePlanningLog)
    if(props.state.loading) {
      return (<i class="fa fa-spinner fa-spin center-position" />);
    }

    return (
        <div className="table-responsive">
        <table id="deletePlanningTable" className="table table-bordered">
          <thead>
            <tr>
              <td className="text-left">S.No.</td>
              <td className="text-left">Record id</td>
              <td className="text-left">Order no.</td>
              <td className="text-left">Planning date</td>
              <td className="text-right">Deleted date</td>
            </tr>
          </thead>
          <tbody>
            { 
              props.state.deletePlanningLog?.response?.map((item , index) => 
                <tr>
                  <td className="text-left">{count++}</td>
                  <td className="text-right">{item.recordID}</td>
                  <td className="text-left">{item.invoiceRefNumber}</td>
                  <td className="text-left">{moment(item.planningDate).format('MM/DD/YY')}</td>
                  <td className="text-right">{moment(item.createdAt).format('MM/DD/YY')}</td>
                  {/* <td className="text-right">{ (item.insuranceExpirationDate) ? moment(item.insuranceExpirationDate).format('MM/DD/YY') : '' }</td>
                  <td className="text-right">{item.phone}</td> */}
                </tr>
              )
            }
          </tbody>
        </table>
      </div>
    );
}

export default PlanningTable;