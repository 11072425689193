import React, { useState, useEffect } from 'react';
import moment from 'moment';
import MaterialTable from "material-table";
import axios from 'axios';
import PlanningServices from '../DriverPlanning/PlanningServices';
import avoidNull from '../../utils/AvoidNull';
import avoidNull2 from '../../utils/AvoidNull2';
import removeAddress from '../../utils/RemoveAddress';
import { ACCESS_TOKEN_NAME, ACCESS_TOKEN_NAME_USER, API_BASE_URL } from '../../constants/apiConstants';

const DisqualifiedPlanneOrdersBox = (props) => {
    const [orderData, setOrderData] = useState();
    const [loading, setloading] = useState(false);

    const fetchDispatchedOrder = async (driverInfo) => {
        setloading(true);
        const response = await axios.get(API_BASE_URL + `/getPlanninginsuranceExpirationDate`, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN_NAME),
            },
        })
        setloading(false);
        setOrderData(response.data);
    }

    const createPlan = async (product, invoiceRefNumber, resalePORefNumer, resaleVendor, transportPORecordID) => {
        const payload = {
            contactId: 0,
            locationId: 123,
            customerConfirmation: 0,
            driveConfirmation: 0,
            size: 0,
            invoiceId: invoiceRefNumber,
            City: '',
            drivername: '',
            product: product,
            State: '',
            DropState: '',
            DropCity: '',
            Dist: '',
            resalePORefNumer,
            isExpedited: 0,
            vendorFullName: resaleVendor,
            date: ''
        };
        const result = await PlanningServices.savePlanning(payload);
        if (result) {
            fetchDispatchedOrder({});
            window.open("/exitingPurchaseorder/" + result[0].lastinsterid + "/" + transportPORecordID);
        }
    }
    /*
    useEffect(()=>{
        fetchDispatchedOrder(props.driverInfo);
    },[props.driverInfo]);

    */

    useEffect(() => {
        fetchDispatchedOrder({});
    }, []);

    return (
        <div className="col-md-12">
            {
                (loading) ?
                    <i class="fa fa-spinner fa-spin center-position" />
                    :

                    <MaterialTable
                        columns={[
                            { title: "RecordID", field: "RecordID", hidden: true },
                            { title: "Customer", field: "Customer", render: rowData => <a target="_blank" href={'/invoice/' + rowData.RecordID}><strong className="title-strong">{rowData.Customer?.split(',')[0]}</strong><br />{rowData.Customer?.split(',')[1]}</a> },
                            { title: "Paid Date", field: "PaidDate", type: 'date', render: rowData => <a target="_blank" href={'/invoice/' + rowData.RecordID}>{(rowData.PaidDate) ? moment(rowData.PaidDate).format('MM/DD/YYYY') : ''}</a> },
                            { title: "Terms", field: "Terms", render: rowData => <a target="_blank" href={'/invoice/' + rowData.RecordID}>{rowData.Terms}</a> },
                            { title: "Product", field: "Product", render: rowData => <a target="_blank" href={'/invoice/' + rowData.RecordID}>{rowData.Product}</a> },
                            { title: "Assign To", field: "AssignedTo", render: rowData => <a target="_blank" href={'/invoice/' + rowData.RecordID}>{rowData.AssignedTo}</a> },
                            { title: "Supp Loc", field: "SupplierLocation", render: rowData => <a target="_blank" href={'https://www.google.com/maps/place/' + rowData.resalePOSupplierLocation}>{rowData.resalePOSupplierLocation}</a> },
                            { title: "Pickup Loc", field: "PickupLoc", render: rowData => <a target="_blank" href={'https://www.google.com/maps/place/' + rowData.pickupLocation}>{rowData.pickupLocation}</a> },
                            { title: "Res PO #", field: "ResalePO", render: rowData => <a target="_blank" href={'/resalePurchaseOrder/' + rowData.ResalePORecordID}>{rowData.ResalePO}</a> },
                            { title: "Rele #", field: "Resale", render: rowData => <a target="_blank" href={'/invoice/' + rowData.RecordID}>{rowData.Resale}</a> },
                            { title: "Release Date", field: "ReleaseDate", type: 'date', render: rowData => <a target="_blank" href={'/invoice/' + rowData.RecordID}>{(rowData.ReleaseDate) ? moment(rowData.ReleaseDate).format('MM/DD/YYYY') : ''}</a> },
                            { title: "Res Total", field: "ResaleTotal", render: rowData => <a target="_blank" href={'/invoice/' + rowData.RecordID}>{rowData.ResaleTotal}</a> },
                            { title: "Expected", field: "Expected", type: 'date', render: rowData => <a target="_blank" href={'/invoice/' + rowData.RecordID}>{(rowData.Expected) ? moment(rowData.Expected).format('MM/DD/YYYY') : ''}</a> },
                            { title: "Last Depot Call", field: "LastDepotCall", type: 'date', render: rowData => <a target="_blank" href={'/invoice/' + rowData.RecordID}>{(rowData.LastDepotCall) ? moment(rowData.LastDepotCall).format('MM/DD/YYYY') : ''}</a> },
                            { title: "Del City/State", field: "shipCity", render: (rowData) => { 
                                return  <a className="breakword" target="_blank" href={`https://www.google.com/maps/dir/?api=1&origin=${avoidNull(rowData.resalePOPickupAddress1)}${avoidNull(rowData.resalePOPickupCity)}${avoidNull2(rowData.resalePOPickupZipCode)}&destination=${removeAddress(rowData.invoiceShipAddress1)}${removeAddress(rowData.invoiceShipAddress2)}${removeAddress(rowData.invoiceShipAddress3)}${avoidNull(rowData.invoiceShipCity)}${avoidNull2(rowData.invoiceShipZip)}`}>{rowData.shipCity}<br />{rowData.shipState}</a> }
                            },
                            { title: "Container #", field: "transportPOLineItemContainerNumber", render: rowData => <a target="_blank" href={'/invoice/' + rowData.RecordID}>{rowData.transportPOLineItemContainerNumber}</a> },
                            { title: "Tran PO #", field: "TransportPO", render: rowData => (rowData.planningId) ? <a target="_blank" href={'/exitingPurchaseorder/' + rowData.planningId + '/' + rowData.TransportPORecordID}>{rowData.TransportPO}</a> : <a href="javascript:void(0)" onClick={() => createPlan(rowData.Product, rowData.ResalePORecordID, rowData.ResalePO, rowData.ResaleVendor, rowData.TransportPORecordID)} >{rowData.TransportPO}</a> },
                            { title: "Tran Ven", field: "transportPOVendor", render: rowData => <a target="_blank" href={'/invoice/' + rowData.RecordID}>{rowData.transportPOVendor}</a> },
                            { title: "Tran Tot", field: "TransportTotal", render: rowData => <a target="_blank" href={'/invoice/' + rowData.RecordID}>{rowData.TransportTotal}</a> },
                            { title: "Sch For", field: "ScheduledFor", type: 'date', render: rowData => <a target="_blank" href={'/invoice/' + rowData.RecordID}>{(rowData.ScheduledFor) ? moment(rowData.ScheduledFor).format('MM/DD/YYYY') : ''}</a> },
                            { title: "Del Status", field: "DeliveryStatus", render: rowData => <a target="_blank" href={'/invoice/' + rowData.RecordID}>{rowData.DeliveryStatus}</a> },
                            { title: "Prom Timeline", field: "PromisedTimeline", render: rowData => <a target="_blank" href={'/invoice/' + rowData.RecordID}>{rowData.PromisedTimeline}</a> },
                            { title: "Prom Date", field: "PromisedDate", type: 'date', render: rowData => <a target="_blank" href={'/invoice/' + rowData.RecordID}>{(rowData.PromisedDate) ? moment(rowData.PromisedDate).format('MM/DD/YYYY') : ''}</a> },
                            { title: "Tier 1", field: "InvoiceLineHasTier1AddOn", render: rowData => <a target="_blank" href={'/invoice/' + rowData.RecordID}>{rowData.InvoiceLineHasTier1AddOn}</a> }
                        ]}
                        data={orderData &&
                            orderData.map((data) => ({
                                RecordID: data.invoiceRecordID,
                                orderStatusRecordID: data.recordID,
                                Customer: `${data.invoiceCustomerFullName.replace(',', ' ')},${data.invoiceRefNumber}`,
                                InvoiceRefNumber: data.invoiceRefNumber,
                                PaidDate: (data.invoicePaidDate) ? data.invoicePaidDate?.split('T')[0] : '',
                                Terms: data.invoiceTerms,
                                Expected: (data.resalePOExpectedDate) ? data.resalePOExpectedDate?.split('T')[0] : '',
                                TransportPO: (data.transportPORecordID) ? data.transportPORefNumer : data.invoiceRefNumber + 'T',
                                transportPOVendor: data.transportPOVendor,
                                TransportTotal: data.transportPOTotalPrice,
                                ScheduledFor: (data.transportPOScheduledDeliveryDate) ? data.transportPOScheduledDeliveryDate?.split('T')[0] : '',
                                PromisedDate: data.invoicePromisedDate,
                                Product: data.invoiceLineItemItemNameWithoutParent,
                                AssignedTo: data.invoiceAssignedToName,
                                SupplierLocation: data.invoiceSupplierLocation,
                                ResalePO: data.resalePO,
                                TransportPORecordID: data.transportPORecordID,
                                InvoiceLineHasTier1AddOn: (data.InvoiceLineHasTier1AddOn) ? 'True' : 'False',
                                transportPOLineItemContainerNumber: data.transportPOLineItemContainerNumber,
                                pickupCity: data.invoiceLineSupplierLocation,
                                planningId: data.planningId,

                                pickupLocation: (data.resalePOPickupCity && data.resalePOPickupState) ? data.resalePOPickupCity + ', ' + data.resalePOPickupState : '',

                                ShippingCity: (data.invoiceShipCity && data.invoiceShipState) ? data.invoiceShipCity + ' ' + data.invoiceShipState : '',
                                invoiceShipAddress1: data.invoiceShipAddress1,
                                invoiceShipAddress2:  data.invoiceShipAddress2,
                                invoiceShipAddress3:  data.invoiceShipAddress3,
                                invoiceShipCity: data.invoiceShipCity,
                                invoiceShipZip: data.invoiceShipZip,
                                resalePOPickupAddress1: data.resalePOPickupAddress1,
                                resalePOPickupCity: data.resalePOPickupCity,
                                resalePOPickupZipCode: data.resalePOPickupZipCode,

                                resalePOSupplierLocation: data.resalePOSupplierLocation,
                                ResalePORecordID: data.resalePORecordID,
                                Dropoff: (data.resalePOShipCity && data.resalePOShipState) ? data.resalePOShipCity + ' ' + data.resalePOShipState : '',
                                distance: data.resalePODistance,
                                entity: data.entity,
                                DriverName: data.driverName,
                                container: (data.resalePOLineItemContainerNumber) ? data.resalePOLineItemContainerNumber : '',
                                ResaleVendor: data.resalePOVendor,
                                Resale: data.resalePOReleaseNumber,
                                ReleaseDate: (data.resalePOReleaseDate) ? data.resalePOReleaseDate?.split('T')[0] : '',
                                ResaleTotal: data.resalePOTotalPrice,
                                ExpectedDeliveryDate: (data.resalePOExpectedDate) ? data.resalePOExpectedDate?.split('T')[0] : '',
                                LastDepotCall: (data.resalePOLastDepotCallDate) ? data.resalePOLastDepotCallDate?.split('T')[0] : '',

                                DeliveryStatus: data.transportPODeliveryStatus,
                                PromisedTimeline: (data.invoicePromisedTimeline) ? data.invoicePromisedTimeline : '',
                                PromisedDate: (data.invoicePromisedDate) ? data.invoicePromisedDate?.split('T')[0] : '',
                            }))}
                        title="Order"
                        options={{
                            columnsButton: false,
                            actionsColumnIndex: -1,
                            selection: false,
                            toolbar: true,
                            showTitle: false,
                            search: true,
                            thirdSortClick: false,
                            sorting: true,
                            paging: true,
                            filtering: true,
                            exportButton: false,
                            pageSize: 25,
                            pageSizeOptions: [25, 50, 100, 200]
                        }}
                        actions={[]}
                    />
            }
            <style jsx="true">{`
            .MuiTableCell-root{
                padding: 2px !important;
                font-family: 'Source Sans Pro', sans-serif;
            }
            .MuiTableCell-root a {
                font-size:12px !important;
                font-family: 'Source Sans Pro', sans-serif;
            }

            .MuiTableHead-root th {
                font-size: 12px !important;
                font-weight: 600;
                background: #fbf5f5;
            }
        `}</style>
        </div>
    );
};

export default DisqualifiedPlanneOrdersBox;