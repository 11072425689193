import React, { useState } from 'react';
import Avatar from 'react-avatar';
import { Link } from 'react-router-dom';
import SyncServices from '../SyncServices.js';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect';
import TextField from "@material-ui/core/TextField";
import { ToastContainer, toast } from 'react-toastify';
import { ACCESS_TOKEN_NAME, ACCESS_TOKEN_NAME_USER, API_BASE_URL } from '../../constants/apiConstants';

import { Box } from '@mui/material';

import Modal from '@mui/material/Modal';

import Button from '@mui/material/Button';
import ProgressBar from '../ProgressBar.jsx';
import { useLoaderContext } from '../../context/loaderContext.js';


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
};



const SyncComponent = (props) => {
    // const { state, toggleState } = useLoaderContext();
    const [display, setdisplay] = useState('none');
    const [open, setOpen] = React.useState(false);
    const [invoiceNumber, setInvoiceNumber] = useState(null)
    const [show, setShow] = useState(false);
    const [showProgressBar, setShowProgressBar] = useState(false)




    const toggleClass = () => {
      setShow(!show);
    };

    const handleOpen = () => {
        setShow(!show);
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    };

    const getInvoiceValue = (e) => {
        setInvoiceNumber(e.target.value)
    }

    const locationSync = async () => {
        setShow(!show);
        setShowProgressBar(true);
        await SyncServices.updatePickupLocationSync()
        await SyncServices.updateSupplierLocationSync()
        setTimeout(() => {
            toast.success('Location sync completed successfully', {
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true
            });
        });
        setShowProgressBar(false);
        //props.setPageRefresh(true)
      
        return true
    }

    const carrierSync = async () => {
        setShow(!show);
        setShowProgressBar(true);
        await SyncServices.updateVendorIntervalSync()
        await SyncServices.updateContactIntervalSync()
        setTimeout(() => {
            toast.success('Carrier sync completed successfully', {
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true
            });
        });
        setShowProgressBar(false);
        //props.setPageRefresh(true)
       
        return true
    }

    const DisptachSync = async () => {
        setShow(!show);
        setShowProgressBar(true);
        await SyncServices.DispatchAppSync()
       setTimeout(() => {
        toast.success('Dispatched sync completed successfully', {
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true
        });
        setShowProgressBar(false);  // Move this inside
    }, 0);
        if(props.pageNameList == 'Dispatched')
        {
            props.referencePage()
        }
       
      
        return true
    }

    const WelcomeCallSync = async () => {
        setShow(!show);
        setShowProgressBar(true);
        await SyncServices.WelcomeCallSync()
       // await SyncServices.WelcomeCallAppSync()
        setTimeout(() => {
            toast.success('Welcome call sync completed successfully', {
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true
            });
        });
        setShowProgressBar(false);
        if(props.pageNameList == 'Welcome Call Needed List')
        {
            props.setPageRefresh(true)
        }
       
      
        return true
    }

    const updateFuelSurchargeSync = async () => {
        setShow(!show);
        setShowProgressBar(true);
        await SyncServices.updateFuelSurchargeSync()
        setTimeout(() => {
            toast.success('Fuel surcharge sync completed successfully', {
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true
            });
        });
        setShowProgressBar(false);
        //props.setPageRefresh(true)
        
        return true
    }

    const fmcsaSync = async () => {
        setShow(!show);
        setShowProgressBar(true);
        await SyncServices.fmcsSyncConditinalSync()
        setTimeout(() => {
            toast.success('FMCSA sync completed successfully', {
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true
            });
        });
        setShowProgressBar(false);
        //props.setPageRefresh(true)
    
        return true
    }

    function handleLogout() {
        localStorage.removeItem(ACCESS_TOKEN_NAME);
        localStorage.removeItem('role');
        localStorage.removeItem('user');
        localStorage.removeItem(ACCESS_TOKEN_NAME_USER);
       // props.history.push("/");
    }

    const invoiceNumberSync = async () => {
        console.log('checkkkk', invoiceNumber)
        if(invoiceNumber)
        {
            setOpen(false);
            setShowProgressBar(true);
        await SyncServices.updateManuallyRecordId(invoiceNumber)
        setTimeout(() => {
            toast.success('Invoice number sync completed successfully', {
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true
            });
        });
        setShowProgressBar(false);
       // props.setPageRefresh(true)
       
      
        }
        else
        {
            setTimeout(() => {
                toast.error('Please enter Invoice Number', {
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });
            });
        }
        
        return true
    }

    return (
        <div className="navbar-container container-fluid">
                                   {
                    showProgressBar
                        ?
                        <ProgressBar/>
                        :
                        ''
                }
                            <ul className="nav-left" style={{ margin: "1% 0% 0% 8%" }}>
                                <h4>{ props.pageNameList }</h4>
                            </ul>
                            <ul className="nav-right">
                            {
                                props.pageNameList === 'Planning'
                                ?
                            <li class="user-profile header-notification">
                                <a className="mobile-menu navabar_menu1" id="mobile-collapse1"><i className="ti-menu" style={{ "font-size": "24px" }}></i></a>
                            </li>
                                :
                                ''
                            }
                                
                                <li className="user-profile header-notification" style={{ lineHeight: 3.5 }}>
                                <a href='#' className='cstm-btn-sync' onClick={toggleClass}>Sync</a>
                                <div className={`cstm-sync ${show ? 'show' : ''}`}>
                                <button onClick={locationSync} class="cstm-inner-btns">pickup/Supplier</button>
                                <button onClick={handleOpen} class="cstm-inner-btns">Invoice Number</button>
                                <button onClick={WelcomeCallSync} class="cstm-inner-btns">WelcomeCall</button>
                                <button onClick={DisptachSync} class="cstm-inner-btns">Dispatched</button>
                                <button onClick={carrierSync} class="cstm-inner-btns">Vendor/Driver</button>
                                <button onClick={updateFuelSurchargeSync} class="cstm-inner-btns">Fuelsurcharge</button>
                                <button onClick={fmcsaSync} class="cstm-inner-btns">FMCSA</button>
                                </div>
                                    <a href="javascript:void(0)" onClick={() => {
                                        setdisplay((old) => (old == 'none') ? 'block' : 'none')
                                    }}>
                                        <Avatar name={localStorage.getItem('user')} colors={['#ed3028', '#711999', '#383838']} size="40" round="20px" />
                                    </a>
                                    <ul className="show-notification profile-notification" style={{ display: display }}>
                                        <li>
                                            <a href="#">
                                                <i className="ti-user"></i> Profile
                                            </a>
                                        </li>
                                        <li>
                                            <Link to="/resetpassword">
                                                <i className="ti-layout-sidebar-left"></i> Change Password
                                            </Link>
                                        </li>
                                        <li>
                                            <a href="#" onClick={() => handleLogout()}>
                                                <i className="ti-layout-sidebar-left"></i> Logout
                                            </a>
                                        </li>
                                    </ul>
                                </li>
                            </ul>

                            <Modal
                                open={open}
                                onClose={handleClose}
                                aria-labelledby="child-modal-title"
                                aria-describedby="child-modal-description"
                                className="cstmModal_Sec"
                            >
                                <Box className="modal_ContentBox" sx={{ ...style, width: 300 }}>
                                    <Box className="ModalHeader_Box">
                                        <h2 id="child-modal-title">invoice number Sync</h2>
                                        {/* <p id="child-modal-description">
                                            Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                                        </p> */}
                                    </Box>

                                    <Box className="modalFormBox">
                                        <Box className="customFormControlBox">
                                            <FormControl fullWidth className='customFormControl planDate'>
                                            <Box className="modalFormBox">
                                        <Box className="customFormControlBox">
                                            <FormControl fullWidth className='customFormControl planDate'>
                                                <InputLabel variant="standard">
                                                Invoice Number
                                                </InputLabel>
                                               
                                                <TextField
                                                    className='customDate'
                                                    id="outlined-basic" 
                                                    type="text" 
                                                    onChange={getInvoiceValue}
                                                />
                                    
                                                {/* <NativeSelect
                                                    defaultValue={30}
                                                    inputProps={{
                                                        name: 'age',
                                                        id: 'uncontrolled-native',
                                                    }}
                                                >
                                                    <option value={10}>Ten</option>
                                                    <option value={20}>Twenty</option>
                                                    <option value={30}>Thirty</option>
                                                </NativeSelect> */}
                                            </FormControl>
                                        </Box>
                                        </Box>
                                                
                                                {/* <TextField
                                                    hiddenLabel
                                                    id="filled-hidden-label-small"
                                                    variant="filled"
                                                    size="small"
                                                    onChange={getInvoiceValue}
                                                    /> */}
                                    
                                                {/* <NativeSelect
                                                    defaultValue={30}
                                                    inputProps={{
                                                        name: 'age',
                                                        id: 'uncontrolled-native',
                                                    }}
                                                >
                                                    <option value={10}>Ten</option>
                                                    <option value={20}>Twenty</option>
                                                    <option value={30}>Thirty</option>
                                                </NativeSelect> */}
                                            </FormControl>
                                        </Box>

                                   

                                  
                                    </Box>

                                    <Box className="modalFooter_BTNBox">
                                        <Button className='saveBTN' onClick={invoiceNumberSync} >Save</Button>
                                        <Button className='cancelBTN' onClick={handleClose}>Close</Button>
                                    </Box>
                                </Box>


                                {/* <Box className="modalHeaderBox"></Box> */}



                                {/* <Box className="modalButtonBox"></Box> */}



                            </Modal>
                        </div>


    )
}


export default SyncComponent