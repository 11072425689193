import { API_BASE_URL , ACCESS_TOKEN_NAME } from '../../constants/apiConstants';
import axios from 'axios'

export default class PlanningServices {
  static async fetchLocation(user) {
    const userId = (user) ? user : localStorage.getItem('userId');
    const token = localStorage.getItem(ACCESS_TOKEN_NAME);
    const response = await axios.get(API_BASE_URL + `/findSupplierLocationByUserid?id=${userId}`, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    return response.data;
  }

  static async createBlankPlanningByPriority(payload) {
    const token  = localStorage.getItem(ACCESS_TOKEN_NAME);
    const response = await axios.post(API_BASE_URL + `/createBlankPlanningByPriority`, payload, {
      headers: {
        Authorization: "Bearer " + token,
      }
    });
    return response.data
  }

  static async swapPositionPlanning(payload) {
    const token = localStorage.getItem(ACCESS_TOKEN_NAME);
    const response = await axios.post(API_BASE_URL + `/swapPositionPlanning`, payload, {
      headers: {
        Authorization: "Bearer " + token,
      }
    });
    return response.data
  }

  static async findAllSupplierLocationWithoutUser() {
    const token = localStorage.getItem(ACCESS_TOKEN_NAME);
    const response = await axios.get(API_BASE_URL + `/findAllSupplierLocationWithoutUser`, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    return response.data;
  }

  static async validateGoogleAddress(shipAddressAddr1,shipAddressAddr2,shipAddressAddr3,shipAddressCity,shipAddressState,shipAddressPostalCode,shipAddressCountry) {
    const token = localStorage.getItem(ACCESS_TOKEN_NAME);
    var response = await axios.post(API_BASE_URL + `/validateAddress`, {
      shipAddressAddr1 : shipAddressAddr1,
      shipAddressAddr2 : shipAddressAddr2,
      shipAddressAddr3 : shipAddressAddr3,
      shipAddressCity:shipAddressCity,
      shipAddressState:shipAddressState,
      shipAddressPostalCode:shipAddressPostalCode,
      shipAddressCountry : shipAddressCountry
    },
     {
      headers: {
        Authorization: "Bearer " + token,
      },
  });
  return response.data;
  }

  static async fetchDriver(locationId) {
    const token = localStorage.getItem(ACCESS_TOKEN_NAME);
    const response = await axios.get(API_BASE_URL + `/findDriverBySupplierLocationid?id=${locationId}`, {
      headers: {
        Authorization: "Bearer " + token,
      }
    });
    return response.data;
  }

  static async verifyDepot(id)
  {
    const token = localStorage.getItem(ACCESS_TOKEN_NAME);
    const response = await axios.get(API_BASE_URL + `/verifyDepotCall?id=${id}`, {
      headers: {
        Authorization: "Bearer " + token,
      }
    });
    return response?.data;
  }

  static async fetchPosition(date, driverId)
  {
    const token = localStorage.getItem(ACCESS_TOKEN_NAME);
    const response = await axios.get(API_BASE_URL + `/fetchPosition?date=${date}&driverId=${driverId}`, {
      headers: {
        Authorization: "Bearer " + token,
      }
    });
    return response.data;
  }

  static async fetchDriverByUser(id) {
    const token = localStorage.getItem(ACCESS_TOKEN_NAME);
    const response = await axios.get(API_BASE_URL + `/findDriverByUserid?id=${id}`, {
      headers: {
        Authorization: "Bearer " + token,
      }
    });
    return response.data;
  }

  static async fetchDriverWithoutLocation() {
    const token = localStorage.getItem(ACCESS_TOKEN_NAME);
    const response = await axios.get(API_BASE_URL + `/findDriverWithoutSupplierLocation`, {
      headers: {
        Authorization: "Bearer " + token,
      }
    });
    return response.data;
  }

  static async fetchPlanningByRecordID(recordID) {
    const response = await axios.get(API_BASE_URL + `/getPlanningByRecordID?recordID=`+recordID, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN_NAME),
      }
    });
    return response.data;
  }

  static async fetchPlanningByDriverAndate(driver, date) {
    const response = await axios.get(API_BASE_URL + `/getPlanningByDriverAndate?driver=`+driver+`&date=`+date, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN_NAME),
      }
    });
    return response.data;
  }

  static async findOrderIdDetails() {
    const response = await axios.get(API_BASE_URL + `/findOrderIdDetails?id=7`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN_NAME),
      },
    });
    return response.data;
  }

  static async getPlanningByInvoice(resalePORefNumer) {
    const response = await axios.get(API_BASE_URL + `/getPlanningByInvoiceReference?resalePORefNumer=${resalePORefNumer}`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN_NAME),
      },
    });
    return response.data;
  }

  static async getPlanningInfo(recordID) {
    const response = await axios.get(API_BASE_URL + `/getPlanningByRecordID?recordID=` + recordID, {
        headers: {
            Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN_NAME),
        }
    });
    return response.data;
}

  static async savePlanning(payload) {
    const response = await axios.post(API_BASE_URL + `/saveplanning`, payload, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN_NAME),
      },
    })
    return response.data;
  }

  static async saveblankplanning(payload) {
    const response = await axios.post(API_BASE_URL + `/saveblankplanning`, payload, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN_NAME),
      },
    })
    return response.data;
  }

  static async updateblankplanning(payload , id) {
    const response = await axios.patch(API_BASE_URL + `/updateblankplanning?id=${id}`, payload, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN_NAME),
      },
    })
    return response.data;
  }

  static async blankplanning(id , payload) {
    const response = await axios.patch(API_BASE_URL + `/blankplanning?id=${id}`,payload, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN_NAME),
      },
    })
    return response.data;
  }

  static async fetchPlanning(driverId){
    const response = await axios.get(API_BASE_URL + `/getorderIDdetailsondrivers?contactId=${driverId}`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN_NAME),
        },
      });
      return response.data;
  }

  static async updatePlanning(payload , id) {
    const response = await axios.put(API_BASE_URL + `/updateOrderDetailslOnPlanning?id=${id}`, payload, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN_NAME),
      },
    })
    return response.data;
  }

  static async planningUpate (payload , id) {
    const response = await axios.patch(API_BASE_URL + `/updatePlanning?id=${id}`, payload, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN_NAME),
      },
    })
    return response.data;
  }

  static async deletePlanning(payload) {
    const response = await axios.delete(API_BASE_URL + `/deletePlanningDriverDetails`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN_NAME),
      },
      data: payload
    });
    return response.data;
  }

  static async deletePlanningByID(id) {
    const response = await axios.delete(API_BASE_URL + `/deletePlanningByID?id=${id}`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN_NAME),
      }});
    return response?.data;
  }

  static async fetchUnavaliable(){
    const response = await axios.get(API_BASE_URL + `/getUnplanning`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN_NAME),
        },
      })
      return response.data;
  }

  static async fetchInvoiceRecordID(recordID){
    const response = await axios.get(API_BASE_URL + `/findresalePO?id=${recordID}`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN_NAME),
        },
      })
      return response.data;
  }

  static async fetchActiveUser() {
    const token = localStorage.getItem(ACCESS_TOKEN_NAME);
    const result = await axios.get(API_BASE_URL + "/findAllUser", {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    return result.data;
  }

  static async getInvoiceCurrentStatus(invoiceRefNumber){
    const response = await axios.get(API_BASE_URL + `/getInvoiceCurrentStatus?invoiceRefNumber=`+invoiceRefNumber, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN_NAME),
        },
      })
      return response.data;
  }

  static async sendPlanningEmail(payload)
  {
    const response =  axios.post(API_BASE_URL + `/sendEmailPlanning`, payload, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN_NAME),
      },
    }).then((resp) => {
      
      return {status:resp.data.status, message:resp.data.message}
    }).catch((err) => {
      return {status:err.response.status, message:'Oops Something went wrong'}
    })

    return response
  }
}





