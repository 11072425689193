import React from "react";
import { ACCESS_TOKEN_NAME, ACCESS_TOKEN_NAME_USER } from "../../constants/apiConstants";
import { Link } from "react-router-dom";
import logo from '../../images/logo.png';
import profilepic from '../../images/avatar-4.jpg';
import Avatar from 'react-avatar';

const Navbar = (props) => {

  function handleLogout() {
    localStorage.removeItem(ACCESS_TOKEN_NAME);
    localStorage.removeItem('role');
    localStorage.removeItem('user');
    localStorage.removeItem(ACCESS_TOKEN_NAME_USER);
    props.history.push("/login");
  }

  return (
    <nav className="navbar header-navbar pcoded-header">
                <div className="navbar-wrapper">

                    <div className="navbar-logo">
                        <a className="mobile-menu" id="mobile-collapse" href="#!">
                            <i class="ti-menu" style={{"color":" #000", "font-size" : "22px;"}}></i>
                        </a>
                        <a className="mobile-search morphsearch-search" href="#">
                            <i className="ti-search"></i>
                        </a>
                        <Link to="/dashboard">
                            <img className="img-fluid logo-img" src={logo} alt="Theme-Logo" />
                        </Link>
                        <a className="mobile-options">
                            <i className="ti-more"></i>
                        </a>
                    </div>

                    <div className="navbar-container container-fluid">

                        <ul className="nav-right">
                            
                            <li className="user-profile header-notification">
                                <a href="#!">
                                <Avatar name={localStorage.getItem('user')} colors={['#ed3028', '#711999', '#383838']} size="40" round="20px" />
                                </a>
                                <ul className="show-notification profile-notification">
                                    <li>
                                        <a href="#">
                                            <i className="ti-user"></i> Profile
                                        </a>
                                    </li>
                                    <li>
                              <Link to="/resetpassword">
                                <i className="ti-layout-sidebar-left"></i> Change Password
                              </Link>
                            </li>
                                    <li>
                                        <a href="" onClick={() => handleLogout()}>
                                            <i className="ti-layout-sidebar-left"></i> Logout
                                        </a>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
  );
};

export default Navbar;
