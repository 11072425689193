import React, { Component } from 'react';
import { Field } from 'formik';
import  CustomCheckbox  from './CustomCheckbox';


class Checkbox extends Component {
    render() {
        return (
            <Field 
                placeholder={this.props.placeholder} 
                name={this.props.name}
                label={this.props.label}
                component={CustomCheckbox}
                disabled={this.props.disabled}
                value={this.props.value}
            />
        );
    }
}

export default Checkbox;