import React from "react";
import { withRouter } from "react-router-dom";
import { ACCESS_TOKEN_NAME, ACCESS_TOKEN_NAME_USER } from "../../constants/apiConstants";
import Navbar from "./../Layout/Navbar";
function Header(props) {
  console.log(props, "props in heade");
  const capitalize = (s) => {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1);
  };
  let title = capitalize(
    props.location.pathname.substring(1, props.location.pathname.length)
  );
  if (props.location.pathname === "/") {
    title = "Welcome";
  }
  function renderLogout() {
    if (localStorage.getItem(ACCESS_TOKEN_NAME) !== "null") {
      return (
        <div className="ml-auto">
          <button className="btn btn-danger btn-sm" onClick={() => handleLogout()}>
            Logout
          </button>
        </div>
      );
    }
  }
  function handleLogout() {
    localStorage.removeItem(ACCESS_TOKEN_NAME);
    localStorage.removeItem('role');
    localStorage.removeItem('user');
    localStorage.removeItem(ACCESS_TOKEN_NAME_USER);
    props.history.push("/");
  }
  return (
    <nav className="navbar navbar-dark bg-primary">
      <div className="row col-12 d-flex  text-white">
        <Navbar />
        <div className="ml-auto">
          <span className="h6">{localStorage.getItem('user')}</span>
        </div>
        {renderLogout()}
      </div>
    </nav>
  );
}
export default withRouter(Header);
