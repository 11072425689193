import React, { Component } from 'react';
import  CustomPromiseTimeLine  from './CustomPromiseTimeLine';
import { Field } from 'formik';

class PromiseTimeLine extends Component {
    render() {
        return (
            <CustomPromiseTimeLine
                name={this.props.name}
                label={this.props.label}
                iid={this.props.name}
                options={this.props.option}
                placeholder={this.props.placeholder}
                defaultValue={this.props.defaultValue}
                fun={this.props.fun}
                value={this.props.value}
          />
        );
    }
}

export default PromiseTimeLine;