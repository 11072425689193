import React, { useState, useEffect } from "react";
import axios from "axios";
import { API_BASE_URL, ACCESS_TOKEN_NAME, ACCESS_TOKEN_NAME_USER } from "../../constants/apiConstants";
import { withRouter, Link } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import {Multiselect}  from "multiselect-react-dropdown";
import InputMask from 'react-input-mask';
import logo from '../../images/logo.png';
import profilepic from '../../images/avatar-4.jpg';
import Navbar from "./../Layout/Navbar";
import Sidebar from "./../Layout/Sidebar";
import Footer from "./../Layout/Footer";
import Avatar from 'react-avatar';

function AddVendor(props) {
//   useEffect(()=>{
//     if(!window.location.hash) {
//         window.location = window.location + '#loaded';
//         window.location.reload();
//     }

// }, []);
  const [display, setdisplay] = useState('none');
  const [state, setState] = useState({
    CompanyName: "",
    Phone: "",
    Email: "",
    Fax: "",

    Address1: "",
    Address2: "",
    Address3: "",
    Address4: "",

    City: "",
    State: "",
    Country: "",
    Postalcode: "",

    InsuranceCompany: "",
    CoverageType: "",
    DOTNumber: "",
    MCNumber: "",
    SafetyRating: "",
    Notes: "",
    typeOfCarrier: "",
    InsuranceExpirationDate: "",
    TrailerSize: [],
    TruckTypes: [], 
    successMessage: null,
    errorMessage: {},
  });
  const [multiSelectOptions, setMultiSelectOptions] = useState({
    options: [{name: '20 ft'},{name: '22 ft'}, {name: '40 ft'}]
  })
  const [supplierLocation, SetSupplierLocation] = useState({
    options: [{}]
  });
  useEffect(() => {
    async function fetchLocation() { 
      const result = await axios.get(
          API_BASE_URL + `/findAllSupplierLocation`,
          {
              headers : {
                  Authorization : "Bearer " + localStorage.getItem(ACCESS_TOKEN_NAME),
              }
          },
      );
      const data = [];
      if(result.status === 200){
          result.data.forEach(element => {
            data.push({id: element.id, name: element.SupplierLocation});
            console.log('pushed data',data);
          });
          SetSupplierLocation({options: data});
          console.log({options: data},'supplierLocation');
      }
    }   
    fetchLocation();
  
  }, []);

  const onSelect1 = (selectedList, selectedItem) => {
    console.log('onselect', selectedList, selectedItem.id);
    setState((prevState) => ({
      ...prevState,
      supplierLocations: {
        selectedList
      },
    }));
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(name, value);
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    validate(name, value);
  };

  const validate = (key, value) => {
    setState((prevState) => ({
      ...prevState,
      errorMessage: {
        ...prevState.errorMessage,
        [key]: "",
      },
    }));
    switch (key) {
      case "email":
        let pattern = new RegExp(
          /^(("[\w-\s]+")|([+\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
        );
        if (!pattern.test(value)) {
          setState((prevState) => ({
            ...prevState,
            errorMessage: {
              ...prevState.errorMessage,
              email: "Please Enter valid Email",
            },
          }));
        }
        break;

      default:
    }
  };
  const handleCancel =() => {
      props.history.push('/vendor');
  }
  

  const onSelect2 = (selectedList, selectedItem) => {
    console.log('selectedlist',selectedList);
    setState((prevState) => ({
      ...prevState,
      TruckTypes: {
        selectedList
      },
    }));
  }

  const onRemove2 = (selectedList, removedItem) => {
    console.log('deselected',selectedList);
    setState((prevState) => ({
      ...prevState,
      TruckTypes: {
        selectedList
      },
    }));
  }




  const onSelect = (selectedList, selectedItem) => {
    console.log('selectedlist',selectedList);
    setState((prevState) => ({
      ...prevState,
      TrailerSize: {
        selectedList
      },
    }));
  }

  const onRemove = (selectedList, removedItem) => {
    console.log('deselected',selectedList);
    setState((prevState) => ({
      ...prevState,
      TrailerSize: {
        selectedList
      },
    }));
  }

  const handleSubmitClick = (e) => {
    e.preventDefault();


  

    if (!state.CompanyName.trim().length) {
      setState((prevState) => ({
        ...prevState,
        errorMessage: {
          ...prevState.errorMessage,
          CompanyName: "Please Enter Company Name",
        },
      }));
    }
    if (!state.Email.trim().length) {
      setState((prevState) => ({
        ...prevState,
        errorMessage: {
          ...prevState.errorMessage,
          Email: "Please Enter Email",
        },
      }));
    }
    if (!state.Phone.trim().length) {
      setState((prevState) => ({
        ...prevState,
        errorMessage: {
          ...prevState.errorMessage,
          Phone: "Please Enter Phone Number",
        },
      }));
    }

    /*
    if (!state.Fax.length) {
      setState((prevState) => ({
        ...prevState,
        errorMessage: {
          ...prevState.errorMessage,
          Fax: "Please Enter Fax",
        },
      }));
    }
    */

    if (!state.Address1.trim().length) {
      setState((prevState) => ({
        ...prevState,
        errorMessage: {
          ...prevState.errorMessage,
          Address1: "Please Enter Billing Address 1",
        },
      }));
    }

    /*

    if (!state.Address2.length) {
      setState((prevState) => ({
        ...prevState,
        errorMessage: {
          ...prevState.errorMessage,
          Address2: "Please Enter Billing Address 2",
        },
      }));
    }

    if (!state.City.length) {
      setState((prevState) => ({
        ...prevState,
        errorMessage: {
          ...prevState.errorMessage,
          City: "Please Enter City",
        },
      }));
    }

    if (!state.State.length) {
      setState((prevState) => ({
        ...prevState,
        errorMessage: {
          ...prevState.errorMessage,
          State: "Please Enter State",
        },
      }));
    }

    
    if (!state.Country.length) {
      setState((prevState) => ({
        ...prevState,
        errorMessage: {
          ...prevState.errorMessage,
          Country: "Please Enter Country",
        },
      }));
    }

    
    if (!state.Postalcode.length) {
      setState((prevState) => ({
        ...prevState,
        errorMessage: {
          ...prevState.errorMessage,
          Postalcode: "Please Enter Postal Code",
        },
      }));
    }

    /*

    if (!state.InsuranceCompany.length) {
      setState((prevState) => ({
        ...prevState,
        errorMessage: {
          ...prevState.errorMessage,
          InsuranceCompany: "Please Enter Insurance Company",
        },
      }));
    }

 
    if (!state.CoverageType.length) {
      setState((prevState) => ({
        ...prevState,
        errorMessage: {
          ...prevState.errorMessage,
          CoverageType: "Please Enter Coverage Type",
        },
      }));
    }


    if (!state.DOTNumber.length) {
      setState((prevState) => ({
        ...prevState,
        errorMessage: {
          ...prevState.errorMessage,
          DOTNumber: "Please Enter DOTNumber",
        },
      }));
    }



    if (!state.MCNumber.length) {
      setState((prevState) => ({
        ...prevState,
        errorMessage: {
          ...prevState.errorMessage,
          MCNumber: "Please Enter MCNumber",
        },
      }));
    }
    
    if (!state.SafetyRating.length) {
      setState((prevState) => ({
        ...prevState,
        errorMessage: {
          ...prevState.errorMessage,
          SafetyRating: "Please Enter Safety Rating",
        },
      }));
    }

    if (!state.Notes.length) {
      setState((prevState) => ({
        ...prevState,
        errorMessage: {
          ...prevState.errorMessage,
          Notes: "Please Enter Notes",
        },
      }));
    }


    if (!state.InsuranceExpirationDate.length) {
      setState((prevState) => ({
        ...prevState,
        errorMessage: {
          ...prevState.errorMessage,
          InsuranceExpirationDate: "Please Enter Date",
        },
      }));
    }
    


    if (state.TrailerSize.length==="") {
      setState((prevState) => ({
        ...prevState,
        errorMessage: {
          ...prevState.errorMessage,
          TrailerSize: "Please Select Trailer Size",
        },
      }));
    }
    */

    validate("email", state.email);

    if (
        state.CompanyName &&
        state.Email && state.Phone && 
        state.Address1
    ) {
      setState((prevState) => ({
        ...prevState,
        errorMessage: {},
      }));
      const payload = {
        CompanyName: state.CompanyName?.trim(),
        Email: state.Email?.trim(),
        Phone: state.Phone?.trim(),
        Fax: state.Fax?.trim(),

        Address1: state.Address1?.trim(),
        Address2: state.Address2,
        Address3: state.Address3,
        Address4: state.Address4,

        City: state.City,
        State: state.State,
        Country: state.Country,
        Postalcode: state.Postalcode,

        InsuranceCompany: state.InsuranceCompany,
        CoverageType: state.CoverageType,
        DOTNumber: state.DOTNumber,
        MCNumber: state.MCNumber,
        SafetyRating: state.SafetyRating,
        Notes: state.Notes,
        typeOfCarrier:state.typeOfCarrier,
        InsuranceExpirationDate: (state.InsuranceExpirationDate) ? state.InsuranceExpirationDate : null,
        supplierLocations: (state.supplierLocations) ? state.supplierLocations : [],
        TruckTypes: (state.TruckTypes) ? state.TruckTypes : [],
        TrailerSize: (state.TrailerSize) ? state.TrailerSize  : []
      };
      console.log("payload..", payload);
    
      let result = "test";
      axios
        .post(API_BASE_URL + "/addVendor", payload)
        .then(function (response) {
          console.log("sign_up response--->>", response);

          result = response;
          if(response.data.status === 404)
          {
            swal({  
              title: "Vendor not created!",
              text: response.data.message,
              icon: "error",
              button: "OK ",
            })
          }
          else if (response.status === 200) {
            setState((prevState) => ({
              ...prevState,
            }));

            localStorage.setItem(ACCESS_TOKEN_NAME_USER, response.data.AccessToken);
            setState((prevState) => ({
              ...prevState,
              errorMessage: {},
            }));

            setTimeout(() => {
              toast.success('Vendor Added Successfully', {
                  autoClose: 2000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true
              });
          });
          props.history.push(`/vendordetails/${response.data.id}`);
            //props.history.push("/vendor");
          } else {
            setState((prevState) => ({
              ...prevState,
              errorMessage: "some error occurd",
            }));
          }
        })
        .catch(function (error) {
          console.log("aSas",error);
        });
    } else {
      setState((prevState) => ({
        ...prevState,
        errorMessage: {
          ...prevState.errorMessage,
          commonError: "Please Fill All The Fields",
        },
      }));
    }
  };

  function handleLogout() {
    localStorage.removeItem(ACCESS_TOKEN_NAME);
    localStorage.removeItem('role');
    localStorage.removeItem('user');
    localStorage.removeItem(ACCESS_TOKEN_NAME_USER);
    props.history.push("/");
  }

  const options = [{name: 'Tilt deck', id: 1},{name: 'Flatbeds', id: 2},{name: 'Tanks', id: 3} , {name: 'Tow Truck', id: 4}]
  //const options = ['Tilt back', 'Flatbeds', 'Tanks']
  
  return (
    <>

    <div id="pcoded" className="pcoded">
        <div className="pcoded-overlay-box"></div>
        <div className="pcoded-container navbar-wrapper">

                    
        <nav className="navbar header-navbar pcoded-header" style={{height:'55px', minHeight:'26px'}}> 
        <div className="navbar-wrapper">
            <div className="navbar-logo" style={{height: '52px'}}>
                <a style={{top:'7px'}} className="mobile-menu navabar_menu" id="mobile-collapse" href="#!">
                    <i class="ti-menu" style={{"color":" #000", "font-size" : "22px;"}}></i>
                </a>
                <a className="mobile-search morphsearch-search" href="#">
                    <i className="ti-search"></i>
                </a>
                <Link to="/dashboard">
                    <img style={{width:'30%'}} className="img-fluid logo-img" src={logo} alt="Theme-Logo" />
                </Link>
                <a className="mobile-options">
                    <i className="ti-more"></i>
                </a>
            </div>
            
            <div className="navbar-container container-fluid">
                <ul className="nav-right">
                    <li className="user-profile header-notification" style={{lineHeight: 3.5}}>
                        <a href="javascript:void(0)" onClick={()=>{
                              setdisplay((old)=>(old == 'none') ? 'block' : 'none')
                          }}>
                          <Avatar name={localStorage.getItem('user')} colors={['#ed3028', '#711999', '#383838']} size="40" round="20px" />
                        </a>
                        <ul className="show-notification profile-notification" style={{display:display}}>
                            <li>
                                <a href="#">
                                    <i className="ti-user"></i> Profile
                                </a>
                            </li>
                            <li>
                              <Link to="/resetpassword">
                                <i className="ti-layout-sidebar-left"></i> Change Password
                              </Link>
                            </li>
                            <li>
                                <a href="" onClick={() => handleLogout()}>
                                    <i className="ti-layout-sidebar-left"></i> Logout
                                </a>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
            <div className="pcoded-main-container">
                <div className="pcoded-wrapper">
                    
                    <Sidebar />

                    <div className="pcoded-content page_content">
                        <div className="pcoded-inner-content">
                            <div className="main-body">
                                <div className="page-wrapper">

                                    <div className="page-body">
                                        <div className="row">
                                            <div className="col-md-2">
                                                <h4 style={{ marginTop: "20px", fontSize: "large"}}>Add Carrier</h4>
                                            </div>
                                            <div className="col-md-10">
                                                {/* <h4>Add User</h4> */}
                                            </div>
                                        </div>


                                        <div className="col-md-12 tabs-ui p-0">
                                        <div className="tab-content" id="nav-tabContent">
                                            <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">

                                            
                                            <form>
                                                  {/* {state.errorMessage.commonError && (
                                                    <span className="red">{state.errorMessage.commonError}</span>
                                                  )} */}
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group text-left">
                                                            <label>Company Name *</label>
                                                            <input
                                                            type="text"
                                                            name="CompanyName"
                                                            className="form-control"
                                                            placeholder="Company Name"
                                                            value={state.CompanyName}
                                                            onChange={handleChange}
                                                            />
                                                            {state.errorMessage.CompanyName && (
                                                            <span className="red">{state.errorMessage.CompanyName}</span>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group text-left">
                                                        <label>Email *</label>
                                                            <input
                                                            type="email"
                                                            name="Email"
                                                            className="form-control"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Email"
                                                            value={state.Email}
                                                            onChange={handleChange}
                                                            />

                                                            {state.errorMessage.Email && (
                                                            <span className="red">{state.errorMessage.Email}</span>
                                                            )}
                                                        </div>
                                                    </div>
                                                  
                                                    <div className="col-md-6">
                                                        <div className="form-group text-left">
                                                        <label>Phone *</label>
                                                            <InputMask
                                                            type="text"
                                                            name="Phone"
                                                            className="form-control"
                                                            placeholder="Phone"
                                                            value={state.Phone} 
                                           
                                                            onChange={handleChange}
                                                            // minLength="10"
                                                            // maxLength="10"
                                                            />
                                                            {state.errorMessage.Phone && (
                                                            <span className="red">{state.errorMessage.Phone}</span>
                                                            )}
                                                        </div>
                                                    </div>        
                                                  
                                                    <div className="col-md-6">
                                                        <div className="form-group text-left">
                                                    <label>Fax</label>
                                                        <input
                                                        type="text"
                                                        name="Fax"
                                                        className="form-control"
                                                        placeholder="Fax"
                                                        value={state.Fax}
                                                        onChange={handleChange}
                                                        />
                                                        {state.errorMessage.Fax && (
                                                        <span className="red">{state.errorMessage.Fax}</span>
                                                        )}
                                                    </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="form-group text-left">
                                                        <label>Billing Address *</label>
                                                          <div className="row">
                                                            <div className="col-md-3">
                                                              <input 
                                                                placeholder="Address 1"
                                                                value={state.Address1}
                                                                onChange={handleChange}
                                                                name="Address1" 
                                                                type="text" 
                                                                className="form-control" 
                                                              />

                                                              {state.errorMessage.Address1 && (
                                                                <span className="red">{state.errorMessage.Address1}</span>
                                                              )}

                                                            </div>
                                                            <div className="col-md-3">
                                                              <input
                                                                placeholder="Address 2"
                                                                value={state.Address2}
                                                                onChange={handleChange}
                                                                name="Address2" 
                                                                type="text" 
                                                                className="form-control" 
                                                              />

                                                              {state.errorMessage.Address2 && (
                                                                <span className="red">{state.errorMessage.Address2}</span>
                                                              )}

                                                            </div>
                                                            <div className="col-md-3">
                                                              <input
                                                                placeholder="Address 3"
                                                                value={state.Address3}
                                                                onChange={handleChange}
                                                                name="Address3"  
                                                                type="text" 
                                                                className="form-control" 
                                                              />

                                                            </div>
                                                            <div className="col-md-3">
                                                              <input
                                                                placeholder="Address 4"
                                                                value={state.Address4}
                                                                onChange={handleChange}
                                                                name="Address4"  
                                                                type="text" 
                                                                className="form-control" 
                                                              />

                                                            </div>
                                                          </div><br/>

                                                          <div className="row">
                                                            <div className="col-md-3">
                                                              <input 
                                                                placeholder="City"
                                                                value={state.City}
                                                                onChange={handleChange}
                                                                name="City"   
                                                                type="text" 
                                                                className="form-control" 
                                                              />

                                                              {state.errorMessage.City && (
                                                                <span className="red">{state.errorMessage.City}</span>
                                                              )}

                                                            </div>
                                                            <div className="col-md-3">
                                                              <input
                                                                placeholder="State"
                                                                value={state.State}
                                                                onChange={handleChange}
                                                                name="State"   
                                                                type="text" 
                                                                className="form-control" 
                                                              />

                                                              {state.errorMessage.State && (
                                                                <span className="red">{state.errorMessage.State}</span>
                                                              )}

                                                            </div>
                                                            <div className="col-md-3">
                                                              <input
                                                                placeholder="Country"
                                                                value={state.Country}
                                                                onChange={handleChange}
                                                                name="Country"   
                                                                type="text" 
                                                                className="form-control" 
                                                              />

                                                              {state.errorMessage.Country && (
                                                                <span className="red">{state.errorMessage.Country}</span>
                                                              )}

                                                            </div>
                                                            <div className="col-md-3">
                                                              <input
                                                                placeholder="Postal Code"
                                                                value={state.Postalcode}
                                                                onChange={handleChange}
                                                                name="Postalcode"   
                                                                type="text" 
                                                                className="form-control" 
                                                              />

                                                              {state.errorMessage.Postalcode && (
                                                                <span className="red">{state.errorMessage.Postalcode}</span>
                                                              )}

                                                            </div>
                                                          </div>
                                                        </div>
                                                    </div>
                                                  
                                                    <div className="col-md-6">
                                                        <div className="form-group text-left">
                                                        <label>Insurance Company</label>
                                                            <input
                                                            type="text"
                                                            name="InsuranceCompany"
                                                            className="form-control"
                                                            placeholder="Insurance Company"
                                                            value={state.InsuranceCompany}
                                                            onChange={handleChange}
                                                            />
                                                            {state.errorMessage.InsuranceCompany && (
                                                            <span className="red">{state.errorMessage.InsuranceCompany}</span>
                                                            )}
                                                        </div>   
                                                    </div>
                                                  
                                                    <div className="col-md-6">
                                                        <div className="form-group text-left">
                                                        <label>Coverage Type</label>
                                                            <input
                                                            type="text"
                                                            name="CoverageType"
                                                            className="form-control"
                                                            placeholder="Coverage Type"
                                                            value={state.CoverageType}
                                                            onChange={handleChange}
                                                            />
                                                            {state.errorMessage.CoverageType && (
                                                            <span className="red">{state.errorMessage.CoverageType}</span>
                                                            )}
                                                        </div>   
                                                    </div>
                                                  
                                                    <div className="col-md-6">
                                                        <div className="form-group text-left">
                                                        <label>DOT Number</label>
                                                            <input
                                                            type="text"
                                                            name="DOTNumber"
                                                            className="form-control"
                                                            placeholder="DOT Number"
                                                            value={state.DOTNumber}
                                                            onChange={handleChange}
                                                            />
                                                            {state.errorMessage.DOTNumber && (
                                                            <span className="red">{state.errorMessage.DOTNumber}</span>
                                                            )}
                                                        </div>  
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group text-left">
                                                            <label>MC Number</label>
                                                            <input
                                                            type="text"
                                                            name="MCNumber"
                                                            className="form-control"
                                                            placeholder="MC Number"
                                                            value={state.MCNumber}
                                                            onChange={handleChange}
                                                            />
                                                            {state.errorMessage.MCNumber && (
                                                            <span className="red">{state.errorMessage.MCNumber}</span>
                                                            )}
                                                        </div>
                                                    </div>
                                                  
                          
                                                  

                                                    <div className="col-md-6">
                                                      <div className="form-group text-left">
                                                        <label>Safety Rating</label>
                                                        <select name="SafetyRating" value={state.SafetyRating} onChange={handleChange} className="form-control">
                                                          <option></option>
                                                          <option value="Satisfactory">Satisfactory</option>
                                                          <option value="Conditional">Conditional</option>
                                                          <option value="None">None</option>
                                                        </select>
                                                        {state.errorMessage.SafetyRating && (<span className="red">{state.errorMessage.SafetyRating}</span>)}
                                                      </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="form-group text-left">
                                                            <label>Notes</label>
                                                            <input
                                                            type="text"
                                                            name="Notes"
                                                            className="form-control"
                                                            placeholder="Notes"
                                                            value={state.Notes}
                                                            onChange={handleChange}
                                                            />
                                                            {state.errorMessage.Notes && (
                                                            <span className="red">{state.errorMessage.Notes}</span>
                                                            )}
                                                        </div>   
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group text-left">
                                                            <label>Insurance Expiration Date</label>
                                                            <input
                                                            type="date"
                                                            name="InsuranceExpirationDate"
                                                            className="form-control"
                                                            placeholder="Insurance Expiration Date"
                                                            value={state.InsuranceExpirationDate}
                                                            onChange={handleChange}
                                                            />
                                                            {state.errorMessage.InsuranceExpirationDate && (
                                                            <span className="red">{state.errorMessage.InsuranceExpirationDate}</span>
                                                            )}
                                                        </div>   
                                                    </div>
                                                    <div className="col-md-6">
                                                            <div className="form-group text-left">
                                                            <label>Trailer Size</label>
                                                                <Multiselect
                                                                options={multiSelectOptions.options}
                                                                onSelect={onSelect}
                                                                onRemove={onRemove}
                                                                displayValue="name"
                                                                placeholder="Select Trailer Size"
                                                                />
                                                                {state.errorMessage.TrailerSize && (
                                                            <span className="red">{state.errorMessage.TrailerSize}</span>
                                                            )}
                                                            </div>
                                                        </div>


                                                        <div className="col-md-6">
                                                  
                                                  <div className="form-group text-left">
                                                  <label>Type of Carrier</label>
                                                  <select
                                                    className="form-control"
                                                    name="typeOfCarrier" 
                                                    //value={state.officeLocation}
                                                    onChange={handleChange}
                                                  >
                                                    <option disabled selected>Select Carrier</option>
                                                    <option value="Dedicated" >Dedicated </option>
                                                    <option value="Non-Dedicated" >Non-Dedicated </option>
                                                  </select>
                                                
                                                  </div>
                                                </div>


                                                <div className="col-md-6">
                                                  <div className="form-group text-left">

                                                    <label>Trailer Types</label>
                                                      <Multiselect
                                                        options={options}
                                                        onSelect={onSelect2}
                                                        onRemove={onRemove2}
                                                        displayValue="name"
                                                        placeholder="Select Trailer Types"
                                                      />
                                                  </div>

                                                </div>



                                                <div className="col-md-6">
                                                  <div className="form-group text-left">
                                                  <label>Supplier Location</label>
                                                      <Multiselect
                                                      options={supplierLocation.options}
                                                      onSelect={onSelect1}
                                                      onChange={handleChange}
                                                      displayValue="name"
                                                      placeholder="Select Supplier"
                                                              />
                                                      {state.errorMessage.supplierLocations && (
                                                      <span className="red">{state.errorMessage.supplierLocations}</span>
                                                    )}
                                                    
                                                  
                                                  </div>

                                                </div>


                                                        
                                                        <div className="col-md-6 ">

                                                          </div>
                                                  
                                                    <div className="col-md-12 cstm-add-btn">
                                                        <button
                                                        type="button"
                                                        className="btn btn-primary mr-2"
                                                        onClick={handleSubmitClick}
                                                    >
                                                        Add
                                                    </button>
                                                    <button
                                                        type="button"
                                                        className="btn btn-default"
                                                        onClick={handleCancel}
                                                    >
                                                        Cancel
                                                    </button>
                                                    </div>
                                                  
                                                  </div>
                                                </form>

                                                <div
                                                  className="alert alert-success mt-2"
                                                  style={{ display: state.successMessage ? "block" : "none" }}
                                                  role="alert"
                                                >
                                                  {state.successMessage}
                                                </div>

                                            </div>
                                          <div className="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">2</div>
                                          <div className="tab-pane fade" id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab">3</div>
                                      </div>
                                            
                                        </div>

                                        <Footer />
                                        <ToastContainer />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> 
    </>
  );
}

export default withRouter(AddVendor);
