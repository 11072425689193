import React, { useState, useEffect } from 'react';

const ProgressBar = () => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      if (progress < 100) {
        setProgress(prevProgress => prevProgress + 1);
      }
    }, 100);

    // Simulating API response delay
    setTimeout(() => {
      clearInterval(timer);
    }, 10000); // Change this value to match the expected API response time

    return () => clearInterval(timer);
  }, [progress]);

  return (
    <div className='overLay'>
      <div class="loading"></div>
      {/* <div className="progress-bar" style={{ width: `${progress}%` }}>
        {progress}%
      </div> */}
    </div>
    
  );
};

export default ProgressBar;
