import React from 'react';
import { Redirect, Route } from "react-router-dom";
import { ACCESS_TOKEN_NAME } from '../constants/apiConstants';
import { LocationContextProvider } from '../context/LocationContext';
import { FormContextProvider } from '../context/FormContext';


const PrivateRoute = ({component: Component, ...rest}) => {
  return (
      <Route {...rest} render={props => (
        localStorage.getItem(ACCESS_TOKEN_NAME) == null ?
            <Redirect to="/" />
          : 
          <FormContextProvider>
            <LocationContextProvider>
                <Component {...props} />
            </LocationContextProvider>
          </FormContextProvider>
      )} />
  );
};
const IsLogginRoute = ({component: Component, ...rest}) => {
  return (
      <Route {...rest} render={props => (
       ! localStorage.getItem(ACCESS_TOKEN_NAME)  ?
              <Component {...props} />
          : <Redirect to="/home" />
      )} />
  );
};

export  {PrivateRoute,IsLogginRoute};