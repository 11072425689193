import IdField from '../Shared/fields/idField';
import StringField from '../Shared/fields/stringField';
import DateField from '../Shared/fields/dateField';
import BooleanField from '../Shared/fields/booleanField';

const fields = {
  id: new IdField('id', 'id'),
  customer: new StringField('customer', 'Customer'),
  invoice: new StringField('invoice', 'Invoice#'),
  wooCommerce: new StringField('woocommerce', 'WooCommerce'),
  assignedto: new StringField('assignedto', 'Assigned To'),
  billemail: new StringField('billemail', 'Bill Email'),
  date: new DateField('date', 'Date', {
    "required": true
  }),
  due: new DateField('due', 'Due', {
    "required": true
  }),
  terms: new StringField('terms', 'Terms'),
  onhold: new BooleanField('onhold', 'On Hold'),
  expedited: new BooleanField('expedited', 'Expedited'),
  order_cancelled: new BooleanField('order_cancelled', 'Order Cancelled'),
  order_refunded: new BooleanField('order_refunded', 'Order Refunded'),
  customer_completed: new BooleanField('customer_completed', 'Customer Call Completed'),
  solid_surface: new BooleanField('solid_surface', 'Solid Surface'),
  towTruck: new BooleanField('towTruck', 'Tow truck'),
  accessories_approved: new BooleanField('accessories_approved', 'Accessories Approved by Purchase'),
  approved_accounts: new BooleanField('approved_accounts', 'Rent-To-Own Approved by Accounts'),
  IsSameTrailerDiscount: new BooleanField('IsSameTrailerDiscount', 'Same trailer discount'),
  emailed: new BooleanField('emailed', 'Has Been Emailed'),
  promiseTimeLine: new StringField('promise_time_line', 'Promise Date Time Line'),
  promiseDate: new DateField('promise_date', 'Promise Date'),
  supplier_location: new StringField('supplier_location', 'Supplier Location'),
  container_orientation: new StringField('container_orientation', 'Container Orientation'),
  ship_method: new StringField('ship_method', 'Ship Method'),
  customerALtPhone: new StringField('customer_alt_phone', 'Customer Alt Phone'),
  customerPhone: new StringField('customer_phone', 'Customer Phone'),
  memo: new StringField('memo', 'Memo'),
  billline1: new StringField('billline1', 'billline1'),
  billline2: new StringField('billline2', 'billline2'),
  billline3: new StringField('billline3', 'billline3'),
  billline4: new StringField('billline4', 'billline4'),
  billline5: new StringField('billline5', 'billline5'),
  billcity: new StringField('billcity', 'billcity'),
  billstate: new StringField('billstate', 'billstate'),
  billzip: new StringField('billzip', 'billzip'),
  billcountry: new StringField('billcountry', 'billcountry'),

  shipline1: new StringField('shipline1', 'shipline1'),
  shipline2: new StringField('shipline2', 'shipline2'),
  shipline3: new StringField('shipline3', 'shipline3'),
  shipline4: new StringField('shipline4', 'shipline4'),
  shipline5: new StringField('shipline5', 'shipline5'),
  shipcity: new StringField('shipcity', 'shipcity'),
  shipstate: new StringField('shipstate', 'shipstate'),
  shipzip: new StringField('shipzip', 'shipzip'),
  shipcountry: new StringField('shipcountry', 'shipcountry')

};

export default {
  fields,
};
