import React,{ useState, useEffect } from 'react';
import EnterEmail from './EnterEmail';
import EnterOldPassword from './EnterOldPassword';
import { useHistory, useParams, Link } from "react-router-dom";

import axios from "axios";
import "./ResetPassword.css";
import { API_BASE_URL, ACCESS_TOKEN_NAME } from "../../constants/apiConstants";
import logo from '../../images/logo.png';
import ResetServices from './ResetServices';
import { ToastContainer, toast } from 'react-toastify';


function ResetPasswordLayout(props) {
  const [object , setObject] = useState({});
  const [emailexit , setemailexit] = useState(false);

  const handlesubmit = async (e) => {
    e.preventDefault();
    const email = object?.email;
    const response = await ResetServices.checkEmailForReset(email);
    if (response.emailexist) {
      setemailexit(response.emailexist);
    } else {
     
      setTimeout(() => {
        toast.success("Your email id doesn't exist in our system", {
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true
        });
    });
    }
  }

  var history = useHistory();
  const changePassword = async (e) => {
    e.preventDefault();
    const email = object?.email;
    try{
      const response = await ResetServices.changePassword(e.target.new_password.value , email);
      if(response){
        setObject({});
        setTimeout(() => {
          toast.success("Your password has been chenged", {
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true
          });
      });
        setTimeout(function(){
          history.push("/dashboard");
         }, 3000);
      }
    }catch(error){
      console.log(error);
    }
  }

  const inputhandel = (e) => {
    setObject({
      [e.target.name] : e.target.value
    })
  }

  return (
    <div id="login" className="login-background">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-6">
            <img src={logo} className="page-logo" />
          </div>
        </div>

        <div className="login-section">
          <div className="row">
            <div className="col-md-6  content-l">
              <h3 className="time">IT'S TIME TO RESET PASSWORD</h3>
            </div>
          <ToastContainer />
            <div className="col-md-6">
              <div className="login-card">
                <h2>Reset your password?</h2>
                <p className="login-inner">We'll help you reset it and get back on track.</p>
                <div className="row form-space">
                  {
                    (emailexit) ? 
                       <EnterOldPassword changePassword={changePassword} object={object} handlesubmit={handlesubmit} />
                    :
                      <EnterEmail inputhandel={inputhandel} object={object} handlesubmit={handlesubmit} />
                  }
                  
                </div>
              </div>
            </div>
          </div>
        </div>

        <footer>
          <p className="footer-content">©2021, Container One</p>
        </footer>

      </div>
    </div>
  );
}

export default ResetPasswordLayout;
