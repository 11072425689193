import React from 'react';

function EnterEmail(props) {
  return (
    <div className="col-md-12">
        <form onSubmit={props.handlesubmit}>
          <div class="form-group">
            <input required onChange={props.inputhandel} value={props.object?.email} class="form-control custom-input" placeholder="Enter email" type="text" name="email" />
          </div>
          <input type="submit" class="btn btn-danger btn-process" value="Submit"/>
        </form>
    </div>
  );
}

export default EnterEmail;