import React, { createContext, useState } from 'react';

export const FormContext = createContext({
  formvalue:null,
  setFormvalue(){}
});

export const FormContextProvider = ({ children }) => {
  const [formvalue, setFormvalue] = useState(null);
  return <FormContext.Provider value={{
    formvalue,
    setFormvalue
  }}>{children}</FormContext.Provider>;
};

