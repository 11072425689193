import React, { useState, useEffect } from 'react';
import { withRouter, Link, useParams } from 'react-router-dom';
import { ACCESS_TOKEN_NAME, ACCESS_TOKEN_NAME_USER, API_BASE_URL } from '../../constants/apiConstants';
import axios from 'axios'
import logo from '../../images/logo.png';
import profilepic from '../../images/avatar-4.jpg';
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $, { data } from 'jquery';
import Navbar from "./../Layout/Navbar";
import Sidebar from "./../Layout/Sidebar";
import Footer from "./../Layout/Footer";
import Avatar from 'react-avatar';
import moment from 'moment';
import { ToastContainer, toast } from 'react-toastify';
import NumberFormate from '../../utils/NumberFormate';


function VendorDetails(props) {
    const params = useParams();
    const [vendorDetails, setVendorDetails] = useState({});
    const [contactDetails, setContactDetails] = useState([]);
    const [isActive, setIsActive] = useState(true);
    const [contactType, setcontactType] = useState("Driver");

    const [display, setdisplay] = useState('none');

    async function fetchVendor() {
        const { id } = params;
        const result = await axios.get(API_BASE_URL + `/findDriverByvendor?id=`+id+`&status=`+[1,0], {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN_NAME),
                },
            }
        );
        if (result.status === 200) {
            setVendorDetails(result.data[0]);
            setContactDetails(result.data);
        }
    }

    useEffect(() => {
        fetchVendor();
    }, []);

    const contactTypeChange = (e) => {
        setcontactType(e.target.value);
        fetchVendor();
    }

    const updateContactStatus = async (id, status) => {
        const result = await axios.patch(
            API_BASE_URL + "/updateContactStatus",
            {
                id,
                status
            },
            {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN_NAME),
                }
            },
        );
        if (result.status == 200) {

            const { id } = params;
            const result = await axios.get(API_BASE_URL + `/findDriverByvendor?id=`+id+`&status=`+[1,0], {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN_NAME),
                    },
                }
            );

            if (result.status === 200) {
                setContactDetails(result.data);
                setVendorDetails(result.data[0]);

                const message = (status) ? 'Driver status has been activated successfully' : 'Driver status has been deactivated successfully';
                
                setTimeout(() => {
                    toast.success(message, {
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true
                    });
                });
            }
        }
    }

    const vendorInActive = async (id) => {
        const response = await axios.post(API_BASE_URL + `/vendorActiveDeactive?id=${id}`, {
            status : 0
        } , {
            headers: {
                Authorization : "Bearer " + localStorage.getItem(ACCESS_TOKEN_NAME),
            },
        });

        if (response.status == 200) {
            setTimeout(() => {
                toast.success('Vendor deactivate successfully!', {
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });
            });
            fetchVendor();
        }
    }

    const vendorActive = async (id) => {
        const response = await axios.post(API_BASE_URL + `/vendorActiveDeactive?id=${id}`, {
            status : 1
        } , {
            headers: {
                Authorization : "Bearer " + localStorage.getItem(ACCESS_TOKEN_NAME),
            },
        });

        if (response.status == 200) {
           
            setTimeout(() => {
                toast.success('Vendor activate successfully!', {
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });
            });
            fetchVendor();
        }
    }



    const handleCheck = async (e) => {
        let isChecked = e.target.checked;
        if (isChecked) {
            setIsActive(false);
        } else {
            setIsActive(true);
        }
        fetchVendor();
    }

    function handleLogout() {
        localStorage.removeItem(ACCESS_TOKEN_NAME);
        localStorage.removeItem('role');
        localStorage.removeItem('user');
        localStorage.removeItem(ACCESS_TOKEN_NAME_USER);
        props.history.push("/");
    }

    return (
        <>
            <div id="pcoded" className="pcoded">
                <div className="pcoded-overlay-box"></div>
                <div className="pcoded-container navbar-wrapper">


                    <nav className="navbar header-navbar pcoded-header" style={{ height: '55px', minHeight: '26px' }}>
                        <div className="navbar-wrapper">
                            <div className="navbar-logo" style={{ height: '52px' }}>
                                <a style={{ top: '7px' }} className="mobile-menu navabar_menu" id="mobile-collapse" href="#!">
                                    <i class="ti-menu" style={{ "color": " #000", "font-size": "22px;" }}></i>
                                </a>
                                <a className="mobile-search morphsearch-search" href="#">
                                    <i className="ti-search"></i>
                                </a>
                                <Link to="/dashboard">
                                    <img style={{width:'30%'}} className="img-fluid logo-img" src={logo} alt="Theme-Logo" />
                                </Link>
                                <a className="mobile-options">
                                    <i className="ti-more"></i>
                                </a>
                            </div>

                            <div className="navbar-container container-fluid">
                                <ul className="nav-left" style={{margin: "1% 0% 0% 8%" }}>
                                    <h4>Carrier Details</h4>
                                </ul>
                                <ul className="nav-right">
                                    <li className="user-profile header-notification" style={{ lineHeight: 3.5 }}>
                                    <a href="javascript:void(0)" onClick={()=>{
                                        setdisplay((old)=>(old == 'none') ? 'block' : 'none')
                                    }}>
                                           <Avatar name={localStorage.getItem('user')} colors={['#ed3028', '#711999', '#383838']} size="40" round="20px" />
                                        </a>
                                        <ul className="show-notification profile-notification" style={{display:display}}>
                                            <li>
                                                <a href="#">
                                                    <i className="ti-user"></i> Profile
                                                </a>
                                            </li>
                                            <li>
                                                <Link to="/resetpassword">
                                                    <i className="ti-layout-sidebar-left"></i> Change Password
                                                </Link>
                                            </li>
                                            <li>
                                                <a href="" onClick={() => handleLogout()}>
                                                    <i className="ti-layout-sidebar-left"></i> Logout
                                                </a>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </nav>
                    <div className="pcoded-main-container">
                        <div className="pcoded-wrapper">
                            <Sidebar />
                            <div className="pcoded-content page_content">
                                <div className="pcoded-inner-content">
                                    <div className="main-body">
                                        <div className="page-wrapper">
                                        <ToastContainer />
                                            <div className="page-body">
                                                <div className="col-md-12 tabs-ui p-0">
                                                &nbsp;&nbsp;&nbsp;
                                                {
                                                    (vendorDetails?.visActive == 1) ?
                                                        <a href="javascript:void(0)" onClick={()=>vendorInActive(vendorDetails?.vendorRecordID)} className="btn btn-primary btn-sm" style={{float: "right", color: "#ffffff"}}>Deactivate</a>
                                                    :
                                                        <a href="javascript:void(0)" onClick={()=>vendorActive(vendorDetails?.vendorRecordID)} className="btn btn-primary btn-sm" style={{float: "right", color: "#ffffff"}}>Activate</a>
                                                }
                                                &nbsp;&nbsp;&nbsp;
                                                <Link className="btn btn-primary btn-sm" style={{float: "right", color: "#ffffff"}} to={`/vendoredit/${vendorDetails?.vid}`}>Edit Carrier</Link>


                                                    <div className="tab-content" id="nav-tabContent">
                                                        <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                                                            <div className="row registration-form">
                                                                <div className="col-md-2">
                                                                    <div className="form-group">
                                                                        <label style={{ fontWeight: "bold" }} >Company Name</label>
                                                                        <p>{vendorDetails?.vendorcompanyName}</p>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-2">
                                                                    <div className="form-group">
                                                                        <label style={{ fontWeight: "bold" }} >Phone </label>
                                                                        <p>{NumberFormate(vendorDetails?.vphone)}</p>
                                                                    </div>
                                                                </div>

                                                                <div className="col-md-2">
                                                                    <div className="form-group">
                                                                        <label style={{ fontWeight: "bold" }} >FMCSA Status</label>
                                                                            {
                                                                            ( vendorDetails?.overrideStatus == 1 ) ?
                                                                                <p>Override</p>
                                                                                :
                                                                                (vendorDetails?.FMCSAStatus == 'Qualified') ?
                                                                                    <p>Qualified</p>
                                                                                :
                                                                                (vendorDetails?.FMCSAStatus == 'Disqualifie' || vendorDetails?.FMCSAStatus == 'Disqualified') ?
                                                                                    <p>Disqualified</p>
                                                                                : 
                                                                                ''
                                                                            }
                                                                    </div>
                                                                </div>

                                                                <div className="col-md-2">
                                                                    <div className="form-group">
                                                                        <label style={{ fontWeight: "bold" }} >Email</label>
                                                                        <p>{vendorDetails?.vemail}</p>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-2">
                                                                    <div className="form-group">
                                                                        <label style={{ fontWeight: "bold" }} >Fax </label>
                                                                        <p>{vendorDetails?.vfax}</p>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-2">
                                                                    <div className="form-group">
                                                                        <label style={{ fontWeight: "bold" }} >Billing Address </label>
                                                                            <p class="address-text">
                                                                                {vendorDetails?.vendorAddressAddr1}<br />
                                                                                {
                                                                                    (vendorDetails?.vendorAddressAddr2) ?
                                                                                        vendorDetails?.vendorAddressAddr2 :
                                                                                        ''
                                                                                }
                                                                                {
                                                                                (vendorDetails?.vendorAddressAddr3) ?
                                                                                    vendorDetails?.vendorAddressAddr3 :
                                                                                    ''
                                                                                }
                                                                                {vendorDetails?.vendorAddressCity} , &nbsp;
                                                                                {vendorDetails?.vendorAddressState}<br />
                                                                                {vendorDetails?.vendorAddressPostalCode}<br />
                                                                                {vendorDetails?.vendorAddressCountry}<br />
                                                                            </p>
                                                                    </div>                                          
                                                                </div>

                                                                <div className="col-md-2">
                                                                    <div className="form-group">
                                                                        <label style={{ fontWeight: "bold" }} >Insurance Company </label>
                                                                        <p>{vendorDetails?.insuranceCompany}</p>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-2">
                                                                    <div className="form-group">
                                                                        <label style={{ fontWeight: "bold" }} >Coverage Type </label>
                                                                        <p>{vendorDetails?.coverageType}</p>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-2">
                                                                    <div className="form-group">
                                                                        <label style={{ fontWeight: "bold" }} >DOT Number </label>
                                                                        <p>{vendorDetails?.usDOTNumber}</p>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-2">
                                                                    <div className="form-group">
                                                                        <label style={{ fontWeight: "bold" }} >MC Number </label>
                                                                        <p>{vendorDetails?.mcNumber}</p>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-2">
                                                                    <div className="form-group">
                                                                        <label style={{ fontWeight: "bold" }} >Safety Rating </label>
                                                                        <p>{vendorDetails?.vsafetyRating}</p>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-2">
                                                                    <div className="form-group">
                                                                        <label style={{ fontWeight: "bold" }} >Insurance Expiration Date </label>
                                                                        <p>{(vendorDetails?.vinsuranceExpirationDate) ? moment(vendorDetails?.vinsuranceExpirationDate).format('MM/DD/YYYY') : ''}</p>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-2">
                                                                    <div className="form-group">
                                                                        <label style={{ fontWeight: "bold" }} >Trailer Size </label>
                                                                        <p>{vendorDetails?.vtrailerSize}</p>
                                                                    </div>
                                                                </div>

                                                                {
                                                                    vendorDetails?.overrideStatus === 1 ?
                                                                    <><div className="col-md-2">
                                                                            <div className="form-group">
                                                                                <label style={{ fontWeight: "bold" }}>Override </label>
                                                                                <p>Yes</p>
                                                                            </div>
                                                                        </div><div className="col-md-2">
                                                                                <div className="form-group">
                                                                                    <label style={{ fontWeight: "bold" }}>Override By </label>
                                                                                    <p>{vendorDetails?.overrideBy}</p>
                                                                                </div>
                                                                            </div><div className="col-md-2">
                                                                                <div className="form-group">
                                                                                    <label style={{ fontWeight: "bold" }}>Override Time </label>
                                                                                    <p>{moment(vendorDetails?.overrideTime).format('MMMM Do YYYY, h:mm:ss a')}</p>
                                                                                </div>
                                                                            </div></>

                                                                :
                                                                ''
                                                                }
                                                            </div>
                                                            <hr />
                                                        </div>

                                                        <div class="row">
                                                            <div className="col-md-4">
                                                                <h5 style={{ float: "left", fontSize: "large" }}>Driver</h5>
                                                                <label className="switch float-right" style={{ marginTop: "5px" }}>
                                                                    <input type="checkbox" id="togBtn" onChange={handleCheck} />
                                                                    <div className="slider round ">
                                                                        <span className="on">Deactivate</span>
                                                                        <span className="off">Activate</span>
                                                                    </div>
                                                                </label>
                                                            </div>

                                                            <div className="col-md-4">
                                                                <select onChange={contactTypeChange} className="form-control">
                                                                    <option value="Driver">Driver</option>
                                                                    <option value="Other">Other</option>
                                                                </select>
                                                            </div>

                                                            <div className="col-md-4">
                                                                <Link style={{ float: "right", marginBottom: "10px", color: "#ffffff" }} className="btn btn-primary btn-sm" to={vendorDetails?.vid ?  '/addcontact/'+vendorDetails?.vid : '#'}>Add Driver</Link>
                                                            </div>
                                                        </div>

                                                        <div class="row">
                                                            <div className="col-md-12">
                                                                <table id="myTable" className="table table-hover table-bordered" width="100%">
                                                                    <thead>
                                                                        <tr>
                                                                            <th>Name</th>
                                                                            <th>Supplier Location</th>
                                                                            <th>Phone</th>
                                                                            <th>Contact Type</th>
                                                                            <th>Driver Email</th>
                                                                            <th>Truck Type</th>
                                                                            <th>Trailer Type</th>
                                                                            <th>Trailer Size</th>
                                                                            <th>Action</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {
                                                                        contactDetails &&
                                                                            contactDetails.map((data, index) => data.id && data.isActive == isActive && (data.contactType == contactType || (contactType == 'Other' && data.contactType == ""))  && (
                                                                                <tr key={data.id}>
                                                                                    <td><Link to={`/contactedit/${data.id}`}>{data.name}</Link></td>
                                                                                    <td><Link to={`/contactedit/${data.id}`}>{data.SupplierLocation}</Link></td>
                                                                                    <td><Link to={`/contactedit/${data.id}`}>{NumberFormate(data.phone)}</Link></td>
                                                                                    <td><Link to={`/contactedit/${data.id}`}>{(data.specialType == "Contact") ? 'Main Contact' : data.contactType }</Link></td>
                                                                                    {/* <td><Link to={`/contactedit/${data.id}`}>{data.email}</Link></td> */}
                                                                                    <td><Link to={`/contactedit/${data.id}`}>{(data.specialType == "Contact") ? data.vemail : data.email }</Link></td>
                                                                                    <td><Link to={`/contactedit/${data.id}`}>{data.truckType}</Link></td>
                                                                                    <td><Link to={`/contactedit/${data.id}`}>{data.trailerType}</Link></td>
                                                                                    <td><Link to={`/contactedit/${data.id}`}>{data.trailerSize}</Link></td>

                                                                                    <td>
                                                                                        {
                                                                                            (data.isActive) ?
                                                                                                <i onClick={() => updateContactStatus(data.id, 0)} style={{"cursor":"pointer"}} className="fa fa-trash text-danger iconbaraction" data-toggle="tooltip" data-placement="top" title="Deactivate" aria-hidden="true"></i>
                                                                                                :
                                                                                                <i onClick={() => updateContactStatus(data.id, 1)} style={{"cursor":"pointer"}} className="fa fa-check-circle text-primary iconbaraction" data-toggle="tooltip" data-placement="top" title="Activate" aria-hidden="true"></i>
                                                                                        }
                                                                                    </td>
                                                                                </tr>
                                                                            ))}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                        <div className="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">2</div>
                                                        <div className="tab-pane fade" id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab">3</div>
                                                    </div>
                                                </div>
                                                <Footer />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <style jsx="true">{`
                    .label-billing label{
                        display:block;
                    }
                    .on {                         
                        padding-right:31px;
                    }
                `}
                </style>
            </div>
        </>
    )
}

export default withRouter(VendorDetails);