import React, { useEffect, useState } from "react";
import axios from "axios";
import "./LoginForm.css";
import { API_BASE_URL, ACCESS_TOKEN_NAME, ACCESS_TOKEN_NAME_USER } from "../../constants/apiConstants";
import { withRouter, useHistory, Link } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import logo from '../../images/logo.png';

function LoginForm() {
  let history = useHistory();
  if(localStorage.getItem(ACCESS_TOKEN_NAME) != null){
    history.push('/dashboard');
  }

  const [state, setState] = useState({
    email: "",
    password: "",
    successMessage: null,
    errorMessage: null
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmitClick = (e) => {
    e.preventDefault();
    const payload = {
      username: state.email,
      password: state.password,
    };
    axios
      .post(API_BASE_URL + "/login", payload)
      .then(function (response) {
        console.log("login reponse--->", response.data);
        if (response.data.status === 200) {
          setState((prevState) => ({
            ...prevState,
            successMessage: "Login successful. Redirecting to home page..",
          }));
          localStorage.setItem(ACCESS_TOKEN_NAME, response.data.AccessToken);
          localStorage.setItem('role', response.data.role);
          localStorage.setItem('orderstatus', 1);
          localStorage.setItem('userId', response.data.data.id);
          localStorage.setItem('user', response.data.data.name);
          if(localStorage.getItem('role') === "1"){
            redirectToHome();
          }
          else if(localStorage.getItem('role') === "2"){
            localStorage.setItem(ACCESS_TOKEN_NAME_USER, response.data.AccessToken);
            redirectToHome()
          }else if(localStorage.getItem('role') === "3"){
            localStorage.setItem(ACCESS_TOKEN_NAME_USER, response.data.AccessToken);
            history.push('/driver');
          }
          
        } else {
          setState((prevState) => ({
            ...prevState,
            errorMessage: "Email or password is incorrect.",
          }));

        }
      })

      .catch(function (error) {
        
        setTimeout(() => {
          toast.success('Username or password is incorrect', {
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true
          });
      });

      });
  };
  const redirectToHome = () => {
    history.push("/dashboard");
  };


  return (

      // <div className="container">
      //   <div className="card col-12 col-lg-4 login-card mt-2 hv-center">
      //     <form>
      //       <div className="form-group text-left">
      //         <input
      //           type="email"
      //           name="email"
      //           className="form-control"
      //           id="email"
      //           aria-describedby="emailHelp"
      //           placeholder="Enter email"
      //           value={state.email}
      //           onChange={handleChange}
      //         />
      //       </div>
      //       <div className="form-group text-left">
      //         <input
      //           type="password"
      //           name="password"
      //           className="form-control"
      //           id="password"
      //           placeholder="Password"
      //           value={state.password}
      //           onChange={handleChange}
      //         />
      //       </div>           
      //       <button
      //         type="submit"
      //         className="btn btn-primary"
      //         onClick={handleSubmitClick}
      //       >
      //         Login
      //       </button>

      //       <div className="form-check" style={{marginTop:"10px"}}>
      //         <Link to="/ForgotPassword">Forgot Password?</Link>
      //       </div>

      //     </form>
      //     <div
      //       className="alert alert-success mt-2"
      //       style={{ display: state.successMessage ? "block" : "none" }}
      //       role="alert"
      //     >
      //       {state.successMessage}
      //     </div>
      //     <div
      //       className="alert alert-success mt-2"
      //       style={{ display: state.errorMessage ? "block" : "none" }}
      //       role="alert"
      //     >
      //       {state.errorMessage}
      //     </div>
      //   </div>
      // </div>

      <div id="login" className="login-background">
        <div className="container-fluid">
        <div className="row">
            <div className="col-md-6">
               <img src={logo} className="page-logo" /> 
            </div>
            {/* <div className="col-md-6 text-right">
              <button className="btn btn-danger registration-btn">Registration</button>
            </div> */}
        </div>  

        <div className="login-section">
            <div className="row">
                <div className="col-md-6  content-l">
                       <h3 className="time">IT'S TIME TO LOGIN</h3>
                       <h6 className="get">Get Login To Explore</h6>
                </div>

                <div className="col-md-6">
                  <form>
                    <div className="login-card">
                        <h2>Welcome!</h2>
                        <p className="login-inner">Enter your email address and password to get access your account.</p>
                        <div className="row form-space">
                        <div className="col-md-12">
                        <div className="form-group">
                        <input
                          type="email"
                          name="email"
                          className="form-control custom-input"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          placeholder="Email"
                          value={state.email}
                          onChange={handleChange}
                        />
                            {/* <input type="email" className="form-control custom-input" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Email" /> */}
                            <i className="fa fa-envelope icon-ps" aria-hidden="true"></i>
                        </div>
                    </div>

                    <div className="col-md-12 pass">
                        <div className="form-group">
                        <div className="form-group text-left">
                          <input
                            type="password"
                            name="password"
                            className="form-control custom-input"
                            id="password"
                            placeholder="Password"
                            value={state.password}
                            onChange={handleChange}
                          />
                        </div> 
                            {/* <input type="email" class="form-control custom-input" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Password" /> */}
                            <i className="fa fa-key icon-ps" aria-hidden="true"></i>
                          </div>
                    </div>

                    <div className="col-md-12">
                        <button
                        type="submit"
                        className="btn btn-danger btn-process"
                        onClick={handleSubmitClick}
                      >
                        Login
                      </button>
                        {/* <button className="btn btn-danger btn-process">Next <i class="fa fa-long-arrow-right btn-icn-right" aria-hidden="true"></i></button> */}
                    </div>
                    <div className="col-md-12" style={{marginTop:"10px"}}>
                    <p className="forgot"><Link to="/ForgotPassword">Forgot Password</Link></p>
                    </div>

                </div>
                </div>
                </form>

                <div
                  className="alert alert-success mt-2"
                  style={{ display: state.successMessage ? "block" : "none", width:"425px" }}
                  role="alert"
                >
                  {state.successMessage}
                </div>
                <div
                  className="alert alert-success"
                  style={{ display: state.errorMessage ? "block" : "none", width:"425px" }}
                  role="alert"
                >
                  {state.errorMessage}
                </div>

                </div>
            </div>
        </div>


        <footer>
            <p className="footer-content">©2021, Container One</p>
        </footer>

        </div>
        <ToastContainer /> 
            
    </div>
  );
}

export default withRouter(LoginForm);
