import { API_BASE_URL , ACCESS_TOKEN_NAME } from '../../constants/apiConstants';
import axios from 'axios'

export default class ResetServices {
    static async checkEmailForReset(email) {
      const response = await axios.post(API_BASE_URL + `/checkEmailIdExist`, {email});
      return response.data;
    }

    static async changePassword(new_password , email) {
      const response = await axios.put(API_BASE_URL + `/resetPassword`, {
        newpassword:new_password,
        email:email
      });
      return response.data;
    }
  
}
  