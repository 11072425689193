import GenericField from './genericField';
import * as yup from 'yup';


export default class BooleanField extends GenericField {
  constructor(
    name,
    label,
    { required = false, yesLabel = undefined, noLabel = undefined } = {},
  ) {
    super(name, label);
    this.yesLabel = yesLabel || 'Yes';
    this.noLabel = noLabel || 'No';
    this.required = required;
  }


  forView(value) {
    return value ? this.yesLabel : this.noLabel;
  }

  forFormInitialValue(value) {
    return value;
  }

  forForm() {
    let yupChain = yup
      .bool()
      .default(false)
      .label(this.label);

      if (this.required) {
        yupChain = yupChain.oneOf([true] , 'Required');
      }

    return yupChain;
  }

}
