import React, { useEffect } from 'react';
import { Link, useLocation } from "react-router-dom";
import "../../assets/css/demo.css";
import "../../assets/css/sidebar-accordion.css";
import "../../images/icon/themify-icons/themify-icons.css";
import $ from 'jquery'


function Sidebar() {
    const location = useLocation();

    useEffect(() => {
        $(".navabar_menu").click(function () {
            $("body").toggleClass("toggleNav");
        });
    }, []);

    return (
        <div className="sidebar_navbar contenedor-menu" style={{ top: '59px' }}>
            <span></span>
            <ul className="menu">
                <li className={location.pathname == "/dashboard" ? "pcoded-hasmenu activado" : "pcoded-hasmenu"}>
                    <Link to="/dashboard">
                        <span className="pcoded-micon"><i className="fa fa-tachometer" aria-hidden="true"></i></span>
                        <span className="pcoded-mtext" data-i18n="nav.dash.main">Dashboard</span>
                        <span className="pcoded-mcaret"></span>
                    </Link>
                </li>

            
                    <li className={location.pathname == "/userlist" ? "pcoded-hasmenu activado" : "pcoded-hasmenu"}>
                        <Link to="/userlist">
                            <span className="pcoded-micon"><i className="fa fa-users" aria-hidden="true"></i></span>
                            <span className="pcoded-mtext" data-i18n="nav.dash.main">User</span>
                            <span className="pcoded-mcaret"></span>
                        </Link>
                    </li>

  


                <li className={location.pathname == "/vendor" ? "pcoded-hasmenu activado" : "pcoded-hasmenu"}>
                    <Link to="/vendor">
                        <span className="pcoded-micon"><i className="fa fa-industry" aria-hidden="true"></i></span>
                        <span className="pcoded-mtext" data-i18n="nav.dash.main">Carrier</span>
                        <span className="pcoded-mcaret"></span>
                    </Link>
                </li>


                <li className={location.pathname == "/driver" ? "pcoded-hasmenu activado" : "pcoded-hasmenu"}>
                    <Link to="/driver">
                        <span className="pcoded-micon"><i className="fa fa-motorcycle" aria-hidden="true"></i></span>
                        <span className="pcoded-mtext" data-i18n="nav.dash.main">Driver</span>
                        <span className="pcoded-mcaret"></span>
                    </Link>
                </li>


                <li className={location.pathname == "/welcomeCallNeeded" ? "pcoded-hasmenu activado" : "pcoded-hasmenu"}>
                    <a href="/welcomeCallNeeded">
                        <span className="pcoded-micon"><i className="fa fa-truck" aria-hidden="true"></i></span>
                        <span className="pcoded-mtext" data-i18n="nav.dash.main">Welcome Call Needed</span>
                        <span className="pcoded-mcaret"></span>
                    </a>
                </li>


                <li className={location.pathname == "/needsplanned" ? "pcoded-hasmenu activado" : "pcoded-hasmenu"}>
                    <a href="/needsplanned">
                        <span className="pcoded-micon"><i className="fa fa-truck" aria-hidden="true"></i></span>
                        <span className="pcoded-mtext" data-i18n="nav.dash.main">Needs Planned</span>
                        <span className="pcoded-mcaret"></span>
                    </a>
                </li>


                <li className={location.pathname == "/driverPlanning" ? "pcoded-hasmenu activado" : "pcoded-hasmenu"}>
                    <a href="/driverPlanning">
                        <span className="pcoded-micon"><i className="fa fa-tasks" aria-hidden="true"></i></span>
                        <span className="pcoded-mtext" data-i18n="nav.dash.main">Planning</span>
                        <span className="pcoded-mcaret"></span>
                    </a>
                </li>

                <li className={location.pathname == "/planned" ? "pcoded-hasmenu activado" : "pcoded-hasmenu"}>
                    <a href="/planned">
                        <span className="pcoded-micon"><i className="fa fa-tasks" aria-hidden="true"></i></span>
                        <span className="pcoded-mtext" data-i18n="nav.dash.main">Planned</span>
                        <span className="pcoded-mcaret"></span>
                    </a>
                </li>


                <li className={location.pathname == "/needsdispatch" ? "pcoded-hasmenu activado" : "pcoded-hasmenu"}>
                    <a href="/needsdispatch">
                        <span className="pcoded-micon"><i className="fa fa-truck" aria-hidden="true"></i></span>
                        <span className="pcoded-mtext" data-i18n="nav.dash.main">Needs Dispatch</span>
                        <span className="pcoded-mcaret"></span>
                    </a>
                </li>

                <li className={location.pathname == "/dispatcheddepot" ? "pcoded-hasmenu activado" : "pcoded-hasmenu"}>
                    <a href="/dispatcheddepot">
                        <span className="pcoded-micon"><i className="fa fa-space-shuttle" aria-hidden="true"></i></span>
                        <span className="pcoded-mtext" data-i18n="nav.dash.main">Dispatched Depot Calls</span>
                        <span className="pcoded-mcaret"></span>
                    </a>
                </li>
                
                <li className={location.pathname == "/dispatched" ? "pcoded-hasmenu activado" : "pcoded-hasmenu"}>
                    <a href="/dispatched">
                        <span className="pcoded-micon"><i className="fa fa-space-shuttle" aria-hidden="true"></i></span>
                        <span className="pcoded-mtext" data-i18n="nav.dash.main">Dispatched</span>
                        <span className="pcoded-mcaret"></span>
                    </a>
                </li>

                

                <li className={location.pathname == "/WaitingOnRelease" ? "pcoded-hasmenu activado" : "pcoded-hasmenu"}>
                    <a href="/WaitingOnRelease">
                        <span className="pcoded-micon"><i className="fa fa-space-shuttle" aria-hidden="true"></i></span>
                        <span className="pcoded-mtext" data-i18n="nav.dash.main">Waiting on release</span>
                        <span className="pcoded-mcaret"></span>
                    </a>
                </li>

                <li className={location.pathname == "/delivered" ? "pcoded-hasmenu activado" : "pcoded-hasmenu"}>
                    <a href="/delivered">
                        <span className="pcoded-micon"><i className="fa fa-space-shuttle" aria-hidden="true"></i></span>
                        <span className="pcoded-mtext" data-i18n="nav.dash.main">Delivered</span>
                        <span className="pcoded-mcaret"></span>
                    </a>
                </li>

                


                <li className={location.pathname == "/completed" ? "pcoded-hasmenu activado" : "pcoded-hasmenu"}>
                    <a href="/completed">
                        <span className="pcoded-micon"><i className="fa fa-handshake-o" aria-hidden="true"></i></span>

                        <span className="pcoded-mtext" data-i18n="nav.dash.main">Completed</span>
                        
                        <span className="pcoded-mcaret"></span>
                    </a>
                </li>
                
                <li className={location.pathname == "/orderstatus" ? "pcoded-hasmenu activado" : "pcoded-hasmenu"}>
                    <a href="/orderstatus">
                        <span className="pcoded-micon"><i className="fa fa-signal" aria-hidden="true"></i></span>
                        <span className="pcoded-mtext" data-i18n="nav.dash.main">Order Status</span>
                        <span className="pcoded-mcaret"></span>
                    </a>
                </li>

                <li className={location.pathname == "/reports" ? "pcoded-hasmenu activado" : "pcoded-hasmenu"}>
                    <Link to="/reports">
                        <span className="pcoded-micon"><i className="fa fa-file" aria-hidden="true"></i></span>
                        <span className="pcoded-mtext" data-i18n="nav.dash.main">Report</span>
                        <span className="pcoded-mcaret"></span>
                    </Link>
                </li>

            </ul>
            <style jsx="true">
                {`
                    @media only screen and (max-width: 1600px) {
                        .sidebar_navbar {
                            overflow-y: scroll;
                            bottom: 0;
                            height: auto !important;
                        }
                    }           
                `}
            </style>
        </div>

    );
}
export default Sidebar;
