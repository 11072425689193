import React, { Component } from 'react';
import Sidebar from "../Layout/Sidebar";
import { withRouter, Link } from 'react-router-dom';
import logo from '../../images/logo.png';
import profilepic from '../../images/avatar-4.jpg';
import { ACCESS_TOKEN_NAME, ACCESS_TOKEN_NAME_USER, API_BASE_URL } from '../../constants/apiConstants';
import LocationDriver from './LocationDriver';
import CompletedServices from './CompletedServices';
import CompletedBox from './CompletedBox';
import PlanningServices from '../DriverPlanning/PlanningServices';
import { LocationContext } from "../../context/LocationContext";
import Avatar from 'react-avatar';
import DatePicker from "react-datepicker";
import moment from 'moment-timezone';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import SyncComponent from '../Shared/SyncComponent';


class CompletedLayout extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            orderData: [],
            total: 0,
            currentPage: 0,
            totalPages: 0,
            startDate: null,
            endDate: null,
            display: 'none',
            pageNameList: 'Completed'
        }
    }

    handelPorfilebox = () => {
        this.setState((old) => {
            return {
                ...old,
                display: old.display == 'none' ? 'block' : 'none'
            }
        })
    }

    lastPage = () => {
     
    }

    firstPage = () => {
       
    }

    previousPage = async () => {
        if(this.state.currentPage <= 0){
            return true;
        }
        const result = await axios.get(API_BASE_URL + `/fetchCompletedOrder?page=${this.state.currentPage - 1}&size=${25}`, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN_NAME),
            },
        });
        this.setState({
            orderData: result.data.rows,
            total:result.data.totalItems,
            currentPage:result.data.currentPage,
            totalPages:result.data.totalPages
        });

    }

    nextPage = async () => {
        if ((this.state.currentPage + 1) == this.state.totalPages ) {
            return true;
        }
        const result = await axios.get(API_BASE_URL + `/fetchCompletedOrder?page=${this.state.currentPage + 1}&size=${25}`, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN_NAME),
            },
        });
        this.setState({
            orderData: result.data.rows,
            total:result.data.totalItems,
            currentPage:result.data.currentPage,
            totalPages:result.data.totalPages
        });
    }

    handleLogout = () => {
        localStorage.removeItem(ACCESS_TOKEN_NAME);
        localStorage.removeItem('role');
        localStorage.removeItem('user');
        localStorage.removeItem(ACCESS_TOKEN_NAME_USER);
        this.props.history.push("/");
    }

    fetchDispatchedOrder = async () => {
        this.setState({
            loading: true
        });
        const result = await axios.get(API_BASE_URL + `/fetchCompletedOrder?page=${0}&size=${25}`, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN_NAME),
            },
        });
        this.setState({
            loading: false,
            orderData: result.data.rows,
            total:result.data.totalItems,
            currentPage:result.data.currentPage,
            totalPages:result.data.totalPages
        });
    }


    onSearch () {

    }

    componentDidMount() {
        //this.fetchDispatchedOrder();
    }

    render() {
        return (
            <div id="pcoded" className="pcoded">
                <div className="pcoded-overlay-box"></div>
                <div className="pcoded-container navbar-wrapper">
                    <nav className="navbar header-navbar pcoded-header" style={{ height: '55px', minHeight: '26px' }}>
                        <div className="navbar-wrapper">
                            <div className="navbar-logo" style={{ height: '52px' }}>
                                <a style={{ top: '7px' }} className="mobile-menu navabar_menu" id="mobile-collapse" href="#!">
                                    <i class="ti-menu" style={{ "color": " #000", "font-size": "22px;" }}></i>
                                </a>
                                <a className="mobile-search morphsearch-search" href="#">
                                    <i className="ti-search"></i>
                                </a>
                                <Link to="/dashboard">
                                    <img style={{ width: '30%' }} className="img-fluid logo-img" src={logo} alt="Theme-Logo" />
                                </Link>
                                <a className="mobile-options">
                                    <i className="ti-more"></i>
                                </a>
                            </div>

                            <SyncComponent pageNameList={this.state.pageNameList} />
                        </div>
                    </nav>
                    <div className="pcoded-main-container">
                        <div className="pcoded-wrapper">
                            <Sidebar />
                            <ToastContainer />
                            <div className="pcoded-content page_content">
                                <div className="pcoded-inner-content">
                                    <div className="main-body">
                                        <div className="page-wrapper">
                                            <div className="page-body">
                                                <div class="container-fluid" style={{ padding: "23px 0px" }}>
                                                <div className='row justify-content-center align-items-center color-choose'>
                                               <h5 className='cstmstates'><span>RTO</span><span className='statecolor rto'></span></h5>
                                               <h5 className='cstmstates'><span>Solid Surface</span><span className='statecolor solid_surface'></span></h5>      
                                               <h5 className='cstmstates'><span>RTO on Solid Surface</span><span className='statecolor rto_ss'></span></h5>  
                                               <h5 className='cstmstates'><span>Tow Truck</span><span className='statecolor solid_tow'></span></h5>      
                                               <h5 className='cstmstates'><span>RTO + Tow Truck</span><span className='statecolor rto-two'></span></h5>    
                                               <h5 className='cstmstates'><span>Solid Surface + Tow Truck</span><span className='statecolor solid-two'></span></h5>      
                                            </div>
                                                        <CompletedBox data={this.state} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <style jsx="true">{`
                    .MuiTableCell-alignLeft {
                        z-index:unset !important;
                    }
                `}</style>
            </div>
        );
    }
}

CompletedLayout.contextType = LocationContext;

export default CompletedLayout;