import React, { Component } from 'react';
import PurchaseFormServices from '../Services/PurchaseFormServices'

class Templatepdf extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fetchloading: false,
            data: []
        }
    }

    fetchDate = async () => {
        this.setState(state => {
            return {
                ...state,
                fetchloading: true,
            }
        });
        const { match } = this.props;

        const response = await PurchaseFormServices.getData(match.params.id);
        if (response.length) {
            this.setState(state => {
                return {
                    ...state,
                    data: response[0],
                    fetchloading: false,
                }
            });
        }
    }

    componentDidMount() {
        this.fetchDate();
    }

    render() {
        return (


<div style={{width: '216mm', margin: '0 auto'}}>
<table style={{width: '100%', fontSize: '15px'}}>
<tbody>
   <tr style={{textAlign: 'left'}}>
   <th />
      <th style={{fontFamily: '"Roboto", sans-serif', fontSize: '16px', fontWeight: 500}}>LOAD CONFIRMATION
   </th>
   </tr>
</tbody>
</table>
<table style={{width: '100%', fontSize: '15px'}}>
<tbody>
   <tr>
      <td>
      </td>
      <td> </td>
      <td style={{fontFamily: '"Roboto", sans-serif', fontWeight: 'bold', fontSize: '18px'}}>Invoice # : &nbsp;&nbsp;
      </td>
   </tr>
   <tr>
      <td style={{fontFamily: '"Roboto", sans-serif'}}><strong>EFFCTIVE DATE :</strong></td>
      <td style={{fontFamily: '"Roboto", sans-serif'}}><strong>CARRIER :</strong> &nbsp;&nbsp; &nbsp;
      </td>
   </tr>
   <tr>
      <td style={{fontFamily: '"Roboto", sans-serif', paddingTop: '20px'}}><strong> COMMODITY:</strong> Container-No
      Interchange
      </td>
      <td style={{fontFamily: '"Roboto", sans-serif', paddingTop: '10px'}}><strong>CARRIER # :</strong>
      </td>
      <td />
   </tr>
   <tr>
      <td />
         <td style={{fontFamily: '"Roboto", sans-serif', paddingTop: '10px'}}><strong>DISPATCHER CELL:<br /> CONTACT
         </strong><br /> <strong>PHONE</strong> : 
      </td>
   </tr>
</tbody>
</table>
<table style={{width: '100%', marginTop: '15px', fontSize: '14px'}}>
<tbody>
   <tr>
      <td style={{fontFamily: '"Roboto", sans-serif', textAlign: 'center', fontSize: '16px', border: '1px solid #000', fontWeight: 'bold'}} colSpan={2}>Any question or concerns about this load please cell agency : (330) 286-0277<br /> Important:
      CARRIER
      call agent if your dispatch instructions below differ! </td>
   </tr>
</tbody>
</table>
<table style={{width: '100%', border: '1px solid #000', marginTop: '14px', fontSize: '14px'}}>
<tbody>
   <tr>
   </tr>
   <tr>
      <td style={{fontFamily: '"Roboto", sans-serif', fontWeight: 'bold', border: '1px solid'}}>PICK UP<br />NAME/ADDRESS</td>
      <td style={{fontFamily: '"Roboto", sans-serif'}}>10-20-21<br />PICK UP
      </td>
      <td style={{fontFamily: '"Roboto", sans-serif'}}><strong>CONTACT<br />PHONE</strong></td>
      <td style={{fontFamily: '"Roboto", sans-serif'}}><br />
      </td>
   </tr>
   <tr>
      <td style={{fontFamily: '"Roboto", sans-serif', paddingTop: '30px'}}><strong>DIRECTION SPECIFIC INSTRUCTIONS
      </strong></td>
      <td style={{fontFamily: '"Roboto", sans-serif', paddingTop: '30px'}} colSpan={3}> Example of special notes
      dispatching team
      can give up details</td>
   </tr>
</tbody>
</table>
<table style={{width: '100%', border: '1px solid #000', marginTop: '14px', fontSize: '14px'}}>
<tbody>
   <tr>
   </tr>
   <tr>
      <td style={{fontFamily: '"Roboto", sans-serif', fontWeight: 'bold', border: '1px solid'}}>DELIVERY<br />NAME/ADDRESS</td>
      <td style={{fontFamily: '"Roboto", sans-serif'}}>10-20-21<br />DELIVERY
      </td>
      <td style={{fontFamily: '"Roboto", sans-serif'}}><strong>CONTACT<br />PHONE</strong></td>
      <td style={{fontFamily: '"Roboto", sans-serif'}} />
   </tr>
   <tr>
      <td style={{fontFamily: '"Roboto", sans-serif', paddingTop: '30px'}}><strong>DIRECTION SPECIFIC
      INSTRUCTIONS</strong></td>
      <td style={{fontFamily: '"Roboto", sans-serif', paddingTop: '30px'}} colSpan={3} />
   </tr>
</tbody>
</table>
<table style={{width: '100%', border: '1px solid #000', fontSize: '15px', marginTop: '14px'}}>
<tbody>
   <tr>
      <td style={{fontFamily: '"Roboto", sans-serif'}} colSpan={4}><strong>ADDITIONAL INSTUCTIONS: </strong></td>
   </tr>
   <tr>
      <td style={{fontFamily: '"Roboto", sans-serif', textAlign: 'center', paddingTop: '30px', fontSize: '20px'}} colSpan={4}><strong>Total CARRIER Pay : </strong></td>
   </tr>
   <tr>
      <td style={{fontFamily: '"Roboto", sans-serif', textAlign: 'center'}} colSpan={4}> please remitt your
      invoice, this PO and Proof delivery to Accounting@container.net Ask us about our quick pay program!</td>
   </tr>
</tbody>
</table>
<table style={{width: '100%', marginTop: '10px', fontSize: '14px'}}>
<tbody>
   <tr>
      <td style={{fontFamily: '"Roboto", sans-serif', lineHeight: '1.6'}}>Dispatcher CONTACT:<br />Dispatcher PHONE: </td>
      <td style={{fontFamily: '"Roboto", sans-serif', textAlign: 'center', lineHeight: '1.6'}}>
      <strong /><br /></td>
      <td style={{fontFamily: '"Roboto", sans-serif', paddingBottom: '20px'}}>
      <strong>CARRIER:</strong>
      </td>
      <td style={{fontFamily: '"Roboto", sans-serif', paddingBottom: '20px'}} />
   </tr>
   <tr>
      <td style={{fontFamily: '"Roboto", sans-serif'}}> Signature : </td>
      <td style={{fontFamily: '"Roboto", sans-serif'}}>
      <div className="line-sign" style={{width: '100%', height: '2px', background: '#000', marginTop: '3pc'}} />
      </td>
      <td style={{fontFamily: '"Roboto", sans-serif', lineHeight: '1.5', border: '1px solid #000', padding: '5px 5px'}} colSpan={2}>
      <div className="group-sect" style={{display: 'flex', alignItems: 'end'}}>
      <div className="text-content"> CARRIER
         Signature: 
      </div>
      </div>
      </td>
   </tr>
   <tr>
      <td style={{fontFamily: '"Roboto", sans-serif', paddingTop: '10px', paddingBottom: '10px'}}>
      Confirm Date
      </td>
      <td style={{fontFamily: '"Roboto", sans-serif'}} />
   </tr>
</tbody>
</table>
<table style={{width: '100%', marginTop: '10px', fontSize: '14px'}}>
<tbody>
   <tr>
      <td style={{fontFamily: '"Roboto", sans-serif', padding: '5px', textAlign: 'center', fontSize: '13px', fontWeight: 'bold'}} colSpan={4}>CARRIER MUST SIGN LOAD CONFIRMATION<br /> THANK YOU FOR DOING BUSINESS WITH CONTAINERONE<br /><span style={{fontSize: '15px'}}>** TEXT
      OUR DRIVER LINE TO REPORT LOAD STATUS SEND INVOICE # AND PICTURES ** (724) 510-7538</span></td>
   </tr>
</tbody>
</table>


<table style={{width: '100%', marginTop: '10px', fontSize: '14px'}}>
      <tr>
        <td style={{fontFamily: '"Roboto", sans-serif',  border:'1px solid #000', padding: '5px', textAlign: 'center', fontSize: '13px', fontWeight: 'bold'}}
          colspan="4">IMPORTANT BILLING INSTRUCTIONS!
          <br/>DOCUMENTS REQUIRED FOR PAYMENT, CARRIER INVOICE, OUT-GATE TICKET, CONTAINERONE RATE CONFIRMATION, AND<br/>
          COMPLETED INSPECTION REPORT. Carrier Payable Questions? Call (330) 286-0558
        </td>

      </tr>
    </table>

<table style={{width: '100%', marginTop: '10px', fontSize: '14px'}}>
<tbody>
   <tr>
      <td style={{fontFamily: '"Roboto", sans-serif'}} colSpan={2}><strong>Please Send PaperWork To:<br />
      ContainerOne<br />8204 N Palmyra Road Canfield, OH 44406</strong></td>
      <td colSpan={2} />
   </tr>
</tbody>
</table>
<table style={{marginTop: '10px', fontSize: '14px'}}>
<tbody>
   <tr>
      <td style={{fontFamily: '"Roboto", sans-serif', textAlign: 'center', fontSize: '14px'}} colSpan={4}>
      ATTENTION<br />
      CARRIER Certifies it is aware to claiforina Air Resources Board's Truck and Bus Drayage and
      Greenhouse Gas Roules and that one all roads originating in, destined for or passsing through california,
      Carrier will utilize only vechiles that are complaint with those Rules. Please see CARB Regulations,including
      CARB Dray rules.</td>
   </tr>
</tbody>
</table>
</div>


        );
    }
}

export default Templatepdf;