import React, { Component } from 'react';
import Sidebar from "../Layout/Sidebar";
import { withRouter, Link } from 'react-router-dom';
import logo from '../../images/logo.png';
import profilepic from '../../images/avatar-4.jpg';
import { ACCESS_TOKEN_NAME, ACCESS_TOKEN_NAME_USER, API_BASE_URL } from '../../constants/apiConstants';
import LocationDriver from './LocationDriver';
import DisqualifiedPlanneOrdersServices from './DisqualifiedPlanneOrdersServices';
import DisqualifiedPlanneOrdersBox from './DisqualifiedPlanneOrdersBox';
import PlanningServices from '../DriverPlanning/PlanningServices';
import { LocationContext } from "../../context/LocationContext";
import Avatar from 'react-avatar';


class DisqualifiedPlanneOrdersLayout extends Component {
   constructor(props) {
      super(props);
      this.state = {
         location:[],
         driver:[],
         selectDriver:[],
         selectedLocationid:null,
         display:'none'
      }
      this.handleDriverChange = this.handleDriverChange.bind(this);
   }

    handelPorfilebox = () => {
        this.setState((old)=>{
            return {
                ...old,
                display: old.display == 'none' ?  'block' : 'none'
            }
        })
    }

   handleLogout = () => {
      localStorage.removeItem(ACCESS_TOKEN_NAME);
      localStorage.removeItem('role');
      localStorage.removeItem('user');
      localStorage.removeItem(ACCESS_TOKEN_NAME_USER);
      this.props.history.push("/");
  }

  async storeLocation(){
      try{
         const result = await DisqualifiedPlanneOrdersServices.fetchLocation();
         result.unshift({ supplierlocationid: 0, supplierloctionname: "All Location" });
         //const resultDriver = await DispachedServices.fetchDriver(result[1].supplierlocationid);
         const resultDriver = await PlanningServices.fetchDriverWithoutLocation();
         this.setState({
            location:result,
            driver:resultDriver,
            selectDriver:resultDriver
         });
      } catch(error) {
         console.log(error,'storeLocation');
      }
  }

  async handleLocationChange(locationid){
    try{
        if(locationid == 0) {
            var result = await PlanningServices.fetchDriverWithoutLocation();
        } else {
            var result = await DisqualifiedPlanneOrdersServices.fetchDriver(locationid);
        }

        this.setState({
            selectDriver:result,
            driver:result
        });
     } catch(error) {
        console.log(error,'handleLocationChange');
     }
  }

  async handleDriverChange(e){
    let driverId = e.target.value;
    if(!isNaN(driverId)){
        let driverObject = this.state.driver.filter((item)=>item.contactId == driverId);
        this.setState({
            selectDriver:driverObject
        });
    } else {
        this.setState({
            selectDriver:this.state.driver
        });
    }
  }

  componentDidUpdate() {
    if(this.context.slectedLocation?.locationid != this.state.selectedLocationid) {
        this.setState((old) => {
            return {
                ...old,
                selectedLocationid:this.context.slectedLocation?.locationid
            }
        })

        this.handleLocationChange(this.context.slectedLocation?.locationid);
    }
  }

  componentDidMount () {
   this.storeLocation();
  }

  render() {
      return (
          <div id="pcoded" className="pcoded">
              <div className="pcoded-overlay-box"></div>
              <div className="pcoded-container navbar-wrapper">
              <nav className="navbar header-navbar pcoded-header" style={{height:'55px', minHeight:'26px'}}> 
                  <div className="navbar-wrapper">
                      <div className="navbar-logo" style={{height: '52px'}}>
                          <a style={{top:'7px'}} className="mobile-menu navabar_menu" id="mobile-collapse" href="#!">
                              <i class="ti-menu" style={{"color":" #000", "font-size" : "22px;"}}></i>
                          </a>
                          <a className="mobile-search morphsearch-search" href="#">
                              <i className="ti-search"></i>
                          </a>
                          <Link to="/dashboard">
                              <img style={{width:'30%'}} className="img-fluid logo-img" src={logo} alt="Theme-Logo" />
                          </Link>
                          <a className="mobile-options">
                              <i className="ti-more"></i>
                          </a>
                      </div>
                      
                      <div className="navbar-container container-fluid">
                            <ul className="nav-left" style={{margin: "1% 0% 0% 8%" }}>
                                <h4>Orders that are planned, but Carrier disqualified</h4>
                            </ul>
                            
                          <ul className="nav-right">
                              <li className="user-profile header-notification" style={{lineHeight: 3.5}}>
                              <a href="javascript:void(0)" onClick={this.handelPorfilebox}>
                              <Avatar name={localStorage.getItem('user')} colors={['#ed3028', '#711999', '#383838']} size="40" round="20px" />
                                  </a>
                                  <ul className="show-notification profile-notification" style={{display: this.state.display}}>
                                      <li>
                                          <a href="#">
                                              <i className="ti-user"></i> Profile
                                          </a>
                                      </li>
                                      <li>
                              <Link to="/resetpassword">
                                <i className="ti-layout-sidebar-left"></i> Change Password
                              </Link>
                            </li>
                                      <li>
                                          <a onClick={this.handleLogout}>
                                              <i className="ti-layout-sidebar-left"></i> Logout
                                          </a>
                                      </li>
                                  </ul>
                              </li>
                          </ul>
                      </div>
                  </div>
              </nav>
              <div className="pcoded-main-container">
                      <div className="pcoded-wrapper">
                          <Sidebar />
                          <div className="pcoded-content page_content">
                              <div className="pcoded-inner-content">
                                  <div className="main-body">
                                      <div className="page-wrapper">
                                          <div className="page-body">
                                              {
                                              /*
                                                <LocationDriver handleDriverChange={this.handleDriverChange}  driver={this.state.driver} location={this.state.location} />
                                              */
                                              }
                                             <div class="container-fluid" style={{padding: "23px 0px"}}>
                                                <div>
                                                    <DisqualifiedPlanneOrdersBox driverInfo={this.state.selectDriver} />
                                                </div>
                                             </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          </div>
                      </div>
                  </div>
              </div>
      );
  }
}

DisqualifiedPlanneOrdersLayout.contextType = LocationContext;

export default DisqualifiedPlanneOrdersLayout;