import React, { Component } from 'react';
import moment from 'moment-timezone';
import { Formik } from 'formik';
import Input from '../Shared/form/Input';
import { withRouter, Redirect, useNavigate } from 'react-router-dom';
import Time from '../Shared/form/Time';
import Textarea from '../Shared/form/Textarea';
import Checkbox from '../Shared/form/Checkbox';
import Radio from '../Shared/form/Radio';
import Date from '../Shared/form/Date';
import DateTime from '../Shared/form/DateTime';
import Select from '../Shared/form/Select';
import Vendor from '../Shared/form/Vendor';
import Driver from '../Shared/form/Driver';
import PromiseTimeLine from '../Shared/form/PromiseTimeLine';
import model from './PurchaseOrderModel';
import FormSchema from '../Shared/form/formSchema';
import PurchaseFormServices from './Services/PurchaseFormServices'
import PurchaseOrderServices from './Services/PurchaseOrderServices'
import PurchaseTable from './PurchaseTable';
import swal from "sweetalert";
import DispachedServices from '../Dispached/DispachedServices';
import Dropzone from '../Shared/form/Dropzone';
import InvoiceServices from '../Invoice/Services/InvoiceServices';
import { ToastContainer, toast } from 'react-toastify';
import './Style/table.css';
import { BASE_URL } from "../../constants/apiConstants";
import jstz from 'jstz';
import FormState from '../../context/FormState';
import { FormContext } from '../../context/FormContext';
import { encode } from 'html-entities';
import $ from 'jquery';
import axios from "axios";
import { API_BASE_URL, ACCESS_TOKEN_NAME } from '../../constants/apiConstants';
import { createBrowserHistory } from "history";
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Dropdown from 'react-bootstrap/Dropdown';


import { productPurchaseOption, shipTo, invoiceLinkedTo, releasePOLinked, supplierLocation, shipedMehtod, promiseTimeLines, containerOrientation } from './Option/AllOption';
import { isCompositeComponent } from 'react-dom/test-utils';
import ProgressBar from '../ProgressBar';
import ProgressBar2 from '../ProgressBar2';
import EditIcon from '@mui/icons-material/Edit';
import { Box } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl'
import TextField from "@material-ui/core/TextField";

import Modal from '@mui/material/Modal';



const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
};
const { fields } = model;


class PurchaseForm extends Component {

    schema = new FormSchema(fields.id, [
        fields.purchaseOrder,
        fields.supplierVendor,
        fields.pickupLocation,
        fields.productService,
        fields.vendor,
        fields.driver,
        fields.vendorid,
        fields.releaseNumber,
        fields.date,
        fields.shipTo,
        fields.invoiceLinkedTo,
        fields.resalePOLinkedTo,
        fields.releaseDate,
        fields.customerPhone,
        fields.releasePOLinked,
        fields.supplierLocation,
        fields.actualPickupDate,
        fields.customerALtPhone,
        fields.shipedMehtod,
        fields.promiseTimeLine,
        fields.promiseDate,
        fields.containerOrientation,
        fields.expectedDate,
        fields.scheduledDeleiveryDate,
        fields.expected,
        fields.deliveryNotes,
        fields.refounded,
        fields.depositcallCompleted,
        fields.from,
        fields.to,
        fields.deleiveryStatus,
        fields.actualDeleiveryDate,
        fields.billing,
        fields.shipping,
        fields.totalAmount,
        fields.rate,
        fields.container,
        fields.memo,
        fields.messages,
        fields.shipline1,
        fields.shipline2,
        fields.shipline3,
        fields.shipline4,
        fields.shipline5,
        fields.shipcity,
        fields.shipstate,
        fields.shipzip,
        fields.shipcountry,
        fields.pickupNotes,
    ]);

    constructor(props) {
        super(props);
        this.formikRef = React.createRef();
        this.state = {
            customerNote: null,
            vendorNote: null,
            dispatchNote: null,
            transportPOrecordID: null,
            Note: null,
            syncLoading: false,
            customerNoteCollection: "",
            vendorNoteCollection: "",
            dispatchNoteCollection: "",
            postatusloading: false,
            pickupLocation: {},
            vendorOption: [],
            driverOption: [],
            selectedVendor: {},
            transportPOloading: false,
            fetchloading: false,
            transportPORefNumer: undefined,
            sending: false,
            vendorloading: false,
            driverloading: false,
            loading: false,
            polineloading: false,
            loadingFinalizeDelivery: false,
            data: [],
            attachment: [],
            shippingAddress: {},
            orderstatus: null,
            vendorData: [],
            fuelSurcharge: [],
            //billing: null,
            //shipping: null,
            depotChecked: false,
            billingTextbox: false,
            shippingTextbox: false,
            rateChange: false,
            vendorChage: false,
            purchaseOrderStatus: false,
            pdfGeneratorloding: false,
            pdfFinalizeGeneratorloding: false,
            finalizeEmailloding: false,
            totalContainer: 0,
            totalTransportpo: 0,
            rate: 0,
            item: [],
            ShippingError: false,
            ShippinfErrorMessage: 'Invalid Address',
            ShippingCityError: false,
            ShippingStateError: false,
            ShippingPostalError: false,
            ShippingCountryError: false,
            depotStatus: false,
            updateShippingError: false,
            ShipWarning: false,
            driverPhone: '',
            driverName: '',
            showProgressBar: false,
            showProgressBar2: false,
            notesLoading: false,
            tpoStatus: false,
            milesStatus: false,
            distanceDiffStatus: false,
            distanceVariance: 0,
            distanceUpdatedVariance: false,
            multiContainerVariance: false,
            muticontanerDistance: null,
            FuelRate: '',
            pickupPerMileCost: '',
            pickupMinimumRate: '',
            invoiceLineItem_RecordID: '',
            resalePO: '',
            transportPODeliveredDate: '',
            deleteLineItem: [],
            saveClicked: false,
            depoLogDate: null,
            depoLogName: null,
            LogisticsDepotCallCompleted: false,
            open:false,
            overrideDistance: null,
        }
        this.deliveryNotesRef = React.createRef();

    }
    handleChangeValue = (e) => {
        console.log('shshshshhshsdhs')
        this.setState({
            saveClicked: true
        })
    }

    setOverrideDistance = (e) => {
        this.setState({
            overrideDistance: e.target.value
        })
    }

    updateOverrideDistance = async () => {
        try
        {
            console.log('okakakak', this.state.overrideDistance)
            this.setState(state => {
                return {
                    ...state,
                    showProgressBar: true,
                }
            });
        const timezone = jstz.determine();
        window.addEventListener('beforeunload', this.functionToChangeStatus);
        await this.createTransportPurchaseOrder();
        await this.updateTostatus(1, this.state.data.transportPORecordID || this.state.transportPOrecordID);
       
       if(this.state.overrideDistance && this.state.data.transportPORecordID || this.state.transportPOrecordID)
        {
            const distance = await PurchaseFormServices.updateOverrideDistance({ overrideDistance: this.state.overrideDistance, transportPO: this.state.data.transportPORecordID || this.state.transportPOrecordID, overrideDistanceStatus: true, overrideDistanceOn: moment().tz(timezone.name()).format('MM/DD/YYYY  HH:mm A'), overrideDistanceBy: localStorage.getItem('user') , purchaseOrder : (this.state.data.pordeRefNumber) ? this.state.data.pordeRefNumber : this.state.transportPORefNumer })
            if(distance.status == 200)
            {
                this.setState(state => {
                    return {
                        ...state,
                        showProgressBar: false,
                    }
                });
                this.handleClose(false)
                setTimeout(() => {
                    toast.success("Distance updated successfully", {
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true
                    });

                });
                await this.fetchDate();
                window.removeEventListener('beforeunload', this.functionToChangeStatus);
            }
            else
            {
                this.setState(state => {
                    return {
                        ...state,
                        showProgressBar: false,
                    }
                });
                setTimeout(() => {
                    toast.error("Something went wrong", {
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true
                    });

                });
               
            }
        }
        else
        {
            this.setState(state => {
                return {
                    ...state,
                    showProgressBar: false,
                }
            });
            setTimeout(() => {
                toast.warning("Please enter distance", {
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });
    
            });  
        }
       
        } catch (error)
        {
            this.setState(state => {
                return {
                    ...state,
                    showProgressBar: false,
                }
            });
            setTimeout(() => {
                toast.error("Something went wrong", {
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });

            });
        }
        
    }

     handleOpen = () => {
       this.setState({
        open: true
    })
    }

     handleClose = () => {
        this.setState({
            open: false
        })
    };


    handleInputChange = (e) => {
        console.log('eeeeee', e)
        // this.setState({ inputValue: e.target.value });
    }

    createTransportPurchaseOrder = async () => {
        const { match } = this.props;
        this.setState({
            transportPOloading: true
        });
        const results = await PurchaseOrderServices.getransportPurchaseOrderRecordID(match.params.id);
        this.setState({
            transportPOrecordID: results.porecordID,
            transportPOloading: false
        });
        // this.fetchDate();
        // this.fetchAttachment(results.porecordID);
        // this.fetchInvoiceLineItem();
        // this.fetchFuelSurcharge();
    }
    // const navigate = useNavigate();

    logDepotCall = async (e) => {
        console.log('ddddddd', e.target.checked)
        const timezone = jstz.determine();
        if (e.target.checked) {
            console.log('sssssss', e.target.checked)
            await this.setState({
                depoLogDate: moment().tz(timezone.name()).format('MM/DD/YYYY  HH:mm A'),
                depoLogName: localStorage.getItem('user'),
                LogisticsDepotCallCompleted: true
            })
        }
        else {
            await this.setState({
                depoLogDate: null,
                depoLogName: null,
                LogisticsDepotCallCompleted: false
            })
        }
    }

    handleCheck = async (e) => {

        if (e.target.name === 'shipcity' && e.target.value.trim()) {
            this.setState({
                ShippingCityError: false
            });
        }
        if (e.target.name === 'shipstate' && e.target.value.trim()) {
            this.setState({
                ShippingStateError: false
            });
        }
        if (e.target.name === 'shipzip' && e.target.value.trim()) {
            this.setState({
                ShippingPostalError: false
            });
        }
        if (e.target.name === 'shipcountry' && e.target.value.trim()) {
            this.setState({
                ShippingCountryError: false
            });
        }
    }


    // Validate Address Check

    validateAddress = async (shipAddressAddr1, shipAddressAddr2, shipAddressAddr3, shipAddressCity, shipAddressState, shipAddressPostalCode, shipAddressCountry) => {
        const results = await PurchaseOrderServices.validateGoogleAddress(shipAddressAddr1, shipAddressAddr2, shipAddressAddr3, shipAddressCity, shipAddressState, shipAddressPostalCode, shipAddressCountry);
        console.log("lssllslslslsl", results);
        if (results.status === 403) {
            this.setState({
                ShippinfErrorMessage: results.message,
                ShippingError: true
            })
        }
      
    }

    // Delete Line Item

    deleteLineItemArray = async (lineItem) => {


        this.setState({
            deleteLineItem: [...this.state.deleteLineItem, lineItem]
        })
    }


    manualSync = async () => {
        window.addEventListener('beforeunload', this.functionToChangeStatus);
        try {
            this.setState((state) => {
                return {
                    ...state,
                    syncLoading: true
                }
            });
            const history = createBrowserHistory({ forceRefresh: true });
            await InvoiceServices.syncUpdatePurchaseOrder(this.state.data.invoiceLinkedTo);
            const { match } = this.props;
           const checkPlan =  await PurchaseOrderServices.checkPlanning(this.state.data.transportPORecordID || this.state.transportPOrecordID, match.params.id);
           if(checkPlan.status == 400)
           {
            window.removeEventListener('beforeunload', this.functionToChangeStatus);
            window.location.href = `/exitingPurchaseorder/${checkPlan.date}/${this.state.data.transportPORecordID || this.state.transportPOrecordID}`;
           }
       
           //   history.push('/dispached');
            //   document.location.reload();
            await this.fetchDate();
            window.removeEventListener('beforeunload', this.functionToChangeStatus);
            this.setState((state) => {
                return {
                    ...state,
                    syncLoading: false
                }
            });
        } catch (error) {
            this.setState((state) => {
                return {
                    ...state,
                    syncLoading: false
                }
            });
            console.log(error.message, 'manualSync');
        }
    }

    syncData = async () => {
        //console.log("rrrrrrrrrrrrr",this.state.transportPOrecordID);
        const result = await PurchaseFormServices.fetchPruchasedOrderLine(this.state.transportPOrecordID);
        var total = 0;
        result.forEach((element, index) => {
            total = (total + parseFloat(element.amount));
        });
        //var count = (this.state.totalContainer > this.state.totalTransportpo && this.state.totalTransportpo) ? '- ' + (this.state.totalTransportpo) : '';
        this.context.formvalue.purchase_order = (this.state.data.pordeRefNumber) ? this.state.data.pordeRefNumber : this.state.transportPORefNumer;
        var minRate = this.state.pickupLocation?.minimumRate;
        var distance = this.state.data?.distance;
        var perMile = this.state.pickupLocation?.perMilePrice;
        var totalBuget = distance * perMile;
        var totalBugetPrice = Math.max(minRate, totalBuget);
        this.context.formvalue.purchasedOrderLine = new Array();
        this.context.formvalue.deposit_call_completed = (this.state?.depotChecked) ? true : false;
        this.context.formvalue.expedited = (this.state.data?.isExpedited) ? true : false;
        this.context.formvalue.refounded = (this.state.data?.isRefunded) ? true : false;

        this.context.formvalue.customer_notes = this.state.customerNoteCollection;
        this.context.formvalue.vendor_notes = this.state.vendorNoteCollection;
        this.context.formvalue.dispatch_notes = this.state.dispatchNoteCollection;

        this.context.formvalue.record_id = this.state.data?.invoiceLinkedTo
        this.context.formvalue.transportPORecordID = this.state.transportPOrecordID
        this.context.formvalue.transportPO = this.state.transportPOrecordID

        this.context.formvalue.shipAddressAddr1 = this.state.shippingAddress?.shipAddressAddr1
        this.context.formvalue.shipAddressAddr2 = this.state.shippingAddress?.shipAddressAddr2
        this.context.formvalue.shipAddressAddr3 = this.state.shippingAddress?.shipAddressAddr3
        this.context.formvalue.shipAddressCity = this.state.shippingAddress?.shipAddressCity
        this.context.formvalue.shipAddressState = this.state.shippingAddress?.shipAddressState
        this.context.formvalue.shipAddressPostalCode = this.state.shippingAddress?.shipAddressPostalCode
        this.context.formvalue.shipAddressCountry = this.state.shippingAddress?.shipAddressCountry

        this.context.formvalue.billAddressAddr1 = this.state.vendorData?.vendorAddressAddr1
        this.context.formvalue.billAddressAddr2 = this.state.vendorData?.vendorAddressAddr2
        this.context.formvalue.billAddressAddr3 = this.state.vendorData?.vendorAddressAddr3
        this.context.formvalue.billAddressCity = this.state.vendorData?.vendorAddressCity
        this.context.formvalue.billAddressState = this.state.vendorData?.vendorAddressState
        this.context.formvalue.billAddressPostalCode = this.state.vendorData?.vendorAddressPostalCode
        this.context.formvalue.billAddressCountry = this.state.vendorData?.vendorAddressCountry
        this.context.formvalue.amount = total
        this.context.formvalue.quantity = 1
        this.context.formvalue.rate = (this.state.rateChange) ? this.state.rate : this.state.data.totalAmount
        this.context.formvalue.pickupLocationName = this.state.data?.pickupLocationName ? this.state.data?.pickupLocationName : this.state.data?.resalePOPickupLocationName
        this.context.formvalue.pickupCity = this.state.data?.pickupCity
        this.context.formvalue.pickupState = this.state.data?.pickupState

        this.context.formvalue.distance = (this.state.data.transportPORecordID) ? this.state.data.distanceBetweenPickupAndDeliveryAddress : this.state.data.distance
        this.context.formvalue.pickupPerMileCost = (this.state.data.transportPORecordID) ? this.state.data.pickupPerMileCost : this.state.pickupLocation.perMilePrice
        this.context.formvalue.pickupMinimumRate = (this.state.data.transportPORecordID) ? this.state.data.pickupMinimumRate : this.state.pickupLocation.minimumRate
        this.context.formvalue.pickupBudget = (this.state.data.transportPORecordID) ? this.state.data.pickupBudget : totalBugetPrice

        this.context.formvalue.pickupAddress1 = this.state.data?.pickupAddress1
        this.context.formvalue.pickupZipcode = this.state.data?.pickupZipcode
        this.context.formvalue.pickupPhone = this.state.data?.pickupPhone
        this.context.formvalue.pickupEmail = this.state.data?.pickupEmail
        this.context.formvalue.customer = this.state.data?.customer
        this.context.formvalue.resalePOLinkedToRecordId = this.state.data?.resalePOLinkedToRecordId
        this.context.formvalue.pickupLocationRecordId = this.state.pickupLocation?.recordID
        this.context.formvalue.resalePORecordId = this.state.data?.invoicePorecordID

        this.context.formvalue.release_date_utc = (this.context.formvalue.release_date) ? moment(this.context.formvalue.release_date).format('YYYY-MM-DD') : '';
        this.context.formvalue.date_utc = (this.context.formvalue.date) ? moment(this.context.formvalue.date).format('YYYY-MM-DD') : '';
        this.context.formvalue.promise_date_utc = (this.context.formvalue.promise_date) ? moment(this.context.formvalue.promise_date).format('YYYY-MM-DD') : '';
        this.context.formvalue.expected_date_utc = (this.context.formvalue.expected_date) ? moment(this.context.formvalue.expected_date).format('YYYY-MM-DD') : '';
        this.context.formvalue.scheduled_deleivery_date_utc = (this.context.formvalue.scheduled_deleivery_date) ? moment(this.context.formvalue.scheduled_deleivery_date).format('YYYY-MM-DD') : '';
        this.context.formvalue.actual_deleivery_date_utc = (this.context.formvalue.actual_deleivery_date) ? moment(this.context.formvalue.actual_deleivery_date).format('YYYY-MM-DD') : '';
        this.context.formvalue.actual_pickup_date_utc = (this.context.formvalue.actual_pickup_date) ? moment(this.context.formvalue.actual_pickup_date).format('YYYY-MM-DD') : '';
        await PurchaseOrderServices.createOrUpdatePurchaseOrder(this.context.formvalue);
        await this.fetchDate();
        return true;
    }

    fetchFuelSurcharge = async () => {
        try {
            const resultinfo = await PurchaseFormServices.fetchFuelSurcharge();

            console.log(resultinfo, 'resultinfo');

            this.setState({
                fuelSurcharge: resultinfo
            });
        } catch (errors) {
            console.log(errors.message)
        }
    }

    fetchInvoiceLineItem = async () => {
        const resultinfo = await PurchaseFormServices.fetchItems();
        this.setState({
            item: resultinfo
        });
    }

    fetchAttachment = async (recordID) => {
        try {
            const resultinfo = await PurchaseFormServices.fetchAttachment({ tableName: "purchaseOrders", recordID: recordID });
            this.setState({
                attachment: resultinfo
            });
        } catch (errors) {

        }
    }

    cancelDispatchedWithConfirm = async () => {
        await PurchaseOrderServices.updateTransportPOStatus(this.state.transportPOrecordID, 'DO NOT  DELETE CARRIER');
    }


    cancelDispatched = async () => {
        await this.createTransportPurchaseOrder();
        if (this.state.transportPOrecordID == null || this.state.transportPOrecordID == 0) {
            return false;
        }
        const result = await PurchaseOrderServices.getPurchasedOrderByrecordID(this.state.transportPOrecordID);
        if (result[0].purchaseOrderStatus == 'Closed') {
            swal("Success!", "This Transport PO is closed, so you can't delete it. If you need to make changes, then let the accounting team know", "success");
        } else {
            swal({
                title: "Warning!",
                text: "Are you sure want to delete purchase order?",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then(async (willDelete) => {
                    if (willDelete) {
                        // await this.manualSync();
                        const response = await PurchaseOrderServices.updateTransportPOStatus(this.state.transportPOrecordID, 'Delete button');
                        if (response[0].status == 200) {
                            console.log('ddddddd', response[0].status)
                            setTimeout(() => {
                                toast.success("Purchase Order deleted successfully", {
                                    autoClose: 2000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true
                                });

                            });

                            window.removeEventListener('beforeunload', this.functionToChangeStatus);
                            //PurchaseOrderServices.updatePlanningOrderStatus(this.state.data?.invoicePorecordID, 7);
                            // swal("Success!", "Cancel dispatched on " + this.state.data?.resalePOTxnDate?.split('T')[0], "success");
                            setTimeout(function () {
                                window.location.href = "/needsdispatch";
                            }, 2000);
                        }
                        else if (response.status == 400) {
                            swal("Success!", "This Transport PO is closed, so you can't delete it. If you need to make changes, then let the accounting team know", "success");
                        }
                        else {
                            setTimeout(() => {
                                toast.error("Something went wrong!", {
                                    autoClose: 2000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true
                                });

                            });
                        }

                    }
                });
        }
    }

    finalizeSendEmail = async () => {
        window.addEventListener('beforeunload', this.functionToChangeStatus);
        try {
            if (this.state.data.invoiceTerms == 'Rent To Own') {
                this.setState({
                    showProgressBar: true
                });
                const { match } = this.props;
                //const responseinfo = await PurchaseFormServices.getPlanningDriverinfo(match.params.id);
                const deliveryNotes = document.getElementsByName("deliveryNotes")[0]?.value;
                const messages = document.getElementsByName("messages")[0]?.value;
                const response = await PurchaseOrderServices.finalEmailDelivery(this.state.data?.invoicePorecordID, this.context.formvalue.driver, this.state.selectedVendor.vendorid, moment().toDate(), deliveryNotes, messages, this.state.data.transportPORecordID || this.state.transportPOrecordID, this.state.data.invoiceLinkedTo);
                await this.fetchUpdateData();
                window.removeEventListener('beforeunload', this.functionToChangeStatus);
                if (response) {
                    // window.open(BASE_URL + "loadConfirmation.pdf");
                    this.setState({
                        showProgressBar: false
                    });
                    setTimeout(() => {
                        toast.success('Finalize email send successfully', {
                            autoClose: 2000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true
                        });
                    });
                }
            }
            else {
                setTimeout(() => {
                    toast.warning('Order should be Rent to own to finalize send email', {
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true
                    });
                });
                this.setState({
                    showProgressBar: false
                });
            }

        } catch (error) {
            setTimeout(() => {
                toast.error('Please save vendor and diver befor finalize send email', {
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });
            });
            this.setState({
                showProgressBar: false
            });
        }
    }

    finalizePdfGenerator = async () => {
        window.addEventListener('beforeunload', this.functionToChangeStatus);
        if (this.state.data.invoiceTerms == 'Rent To Own') {
            if (this.state.saveClicked) {
                swal("Something has changed, and saving has been initiated.");
                await this.createTransportPurchaseOrder();
            }

            //var count = (this.state.totalContainer > this.state.totalTransportpo && this.state.totalTransportpo) ? '- ' + (this.state.totalTransportpo) : '';
            this.context.formvalue.purchase_order = (this.state.data.pordeRefNumber) ? this.state.data.pordeRefNumber : this.state.transportPORefNumer;
            var minRate = this.state.pickupLocation?.minimumRate;
            var distance = this.state.data?.distance;
            var perMile = this.state.pickupLocation?.perMilePrice;
            var totalBuget = distance * perMile;
            var totalBugetPrice = Math.max(minRate, totalBuget);

            const purchasedOrderLine = new Array();
            var total = 0;
            for (var i = 0; i < this.context.formvalue.length; i++) {
                if (this.context.formvalue['amount' + i.toString()] > 0) {
                    total = (total + parseFloat(this.context.formvalue['amount' + i.toString()]));
                }
                purchasedOrderLine.push({
                    item: this.context.formvalue['item' + i.toString()],
                    description: this.context.formvalue['description' + i.toString()],
                    qty: this.context.formvalue['qty' + i.toString()],
                    rate: this.context.formvalue['rate' + i.toString()],
                    amount: this.context.formvalue['amount' + i.toString()],
                    container: this.context.formvalue['container' + i.toString()],
                    recordID: this.context.formvalue['recordID' + i.toString()]
                });
            }
            this.context.formvalue.purchasedOrderLine = purchasedOrderLine;

            this.context.formvalue.deposit_call_completed = (this.state?.depotChecked) ? true : false;
            this.context.formvalue.expedited = (this.state.data?.isExpedited) ? true : false;
            this.context.formvalue.refounded = (this.state.data?.isRefunded) ? true : false;

            this.context.formvalue.from = moment().format('YYYY-MM-DD') + ' ' + this.context.formvalue.sfrom
            this.context.formvalue.to = moment().format('YYYY-MM-DD') + ' ' + this.context.formvalue.sto

            this.context.formvalue.customer_notes = this.state.customerNoteCollection;
            this.context.formvalue.vendor_notes = this.state.vendorNoteCollection;
            this.context.formvalue.dispatch_notes = this.state.dispatchNoteCollection;

            this.context.formvalue.record_id = this.state.data?.invoiceLinkedTo
            this.context.formvalue.transportPORecordID = this.state.transportPOrecordID
            this.context.formvalue.transportPO = this.state.transportPOrecordID

            this.context.formvalue.shipAddressAddr1 = this.state.shippingAddress?.shipAddressAddr1
            this.context.formvalue.shipAddressAddr2 = this.state.shippingAddress?.shipAddressAddr2
            this.context.formvalue.shipAddressAddr3 = this.state.shippingAddress?.shipAddressAddr3
            this.context.formvalue.shipAddressCity = this.state.shippingAddress?.shipAddressCity
            this.context.formvalue.shipAddressState = this.state.shippingAddress?.shipAddressState
            this.context.formvalue.shipAddressPostalCode = this.state.shippingAddress?.shipAddressPostalCode
            this.context.formvalue.shipAddressCountry = this.state.shippingAddress?.shipAddressCountry
            this.context.formvalue.billAddressAddr1 = this.state.vendorData?.vendorAddressAddr1
            this.context.formvalue.billAddressAddr2 = this.state.vendorData?.vendorAddressAddr2
            this.context.formvalue.billAddressAddr3 = this.state.vendorData?.vendorAddressAddr3
            this.context.formvalue.billAddressCity = this.state.vendorData?.vendorAddressCity
            this.context.formvalue.billAddressState = this.state.vendorData?.vendorAddressState
            this.context.formvalue.billAddressPostalCode = this.state.vendorData?.vendorAddressPostalCode
            this.context.formvalue.billAddressCountry = this.state.vendorData?.vendorAddressCountry
            this.context.formvalue.amount = total;
            this.context.formvalue.quantity = 1
            this.context.formvalue.rate = (this.state.rateChange) ? this.state.rate : this.state.data.totalAmount
            this.context.formvalue.pickupLocationName = this.state.data?.pickupLocationName ? this.state.data?.pickupLocationName : this.state.data?.resalePOPickupLocationName
            this.context.formvalue.pickupCity = this.state.data?.pickupCity
            this.context.formvalue.pickupState = this.state.data?.pickupState

            this.context.formvalue.distance = (this.state.data.transportPORecordID) ? this.state.data.distanceBetweenPickupAndDeliveryAddress : this.state.data.distance
            this.context.formvalue.pickupPerMileCost = (this.state.data.transportPORecordID) ? this.state.data.pickupPerMileCost : this.state.pickupLocation.perMilePrice
            this.context.formvalue.pickupMinimumRate = (this.state.data.transportPORecordID) ? this.state.data.pickupMinimumRate : this.state.pickupLocation.minimumRate
            this.context.formvalue.pickupBudget = (this.state.data.transportPORecordID) ? this.state.data.pickupBudget : totalBugetPrice

            this.context.formvalue.pickupAddress1 = this.state.data?.pickupAddress1
            this.context.formvalue.pickupZipcode = this.state.data?.pickupZipcode
            this.context.formvalue.pickupPhone = this.state.data?.pickupPhone
            this.context.formvalue.pickupEmail = this.state.data?.pickupEmail
            this.context.formvalue.customer = this.state.data?.customer
            this.context.formvalue.resalePOLinkedToRecordId = this.state.data?.resalePOLinkedToRecordId
            this.context.formvalue.pickupLocationRecordId = this.state.pickupLocation?.recordID
            this.context.formvalue.resalePORecordId = this.state.data?.invoicePorecordID

            this.context.formvalue.release_date_utc = (this.context.formvalue.release_date) ? moment(this.context.formvalue.release_date).format('YYYY-MM-DD') : '';
            this.context.formvalue.date_utc = (this.context.formvalue.date) ? moment(this.context.formvalue.date).format('YYYY-MM-DD') : '';
            this.context.formvalue.promise_date_utc = (this.context.formvalue.promise_date) ? moment(this.context.formvalue.promise_date).format('YYYY-MM-DD') : '';
            this.context.formvalue.expected_date_utc = (this.context.formvalue.expected_date) ? moment(this.context.formvalue.expected_date).format('YYYY-MM-DD') : '';
            this.context.formvalue.scheduled_deleivery_date_utc = (this.context.formvalue.scheduled_deleivery_date) ? moment(this.context.formvalue.scheduled_deleivery_date).format('YYYY-MM-DD') : '';
            this.context.formvalue.actual_deleivery_date_utc = (this.context.formvalue.actual_deleivery_date) ? moment(this.context.formvalue.actual_deleivery_date).format('YYYY-MM-DD') : '';
            this.context.formvalue.actual_pickup_date_utc = (this.context.formvalue.actual_pickup_date) ? moment(this.context.formvalue.actual_pickup_date).format('YYYY-MM-DD') : '';

            const { match } = this.props;
            //const responseinfo = await PurchaseFormServices.getPlanningDriverinfo(match.params.id);
            const deliveryNotes = document.getElementsByName("deliveryNotes")[0]?.value;
            const messages = document.getElementsByName("messages")[0]?.value;
            console.log('sdddddddddddddd', this.context.formvalue)
            // console.log('sdddddddddddddd', this.context.formvalue.vendor, this.context.formvalue.driver, this.context.formvalue.scheduled_deleivery_date_utc , this.context.formvalue.from, this.context.formvalue.to)
            if (!this.context.formvalue.vendor || !this.context.formvalue.driver || !this.context.formvalue.scheduled_deleivery_date_utc || !this.context.formvalue.from || !this.context.formvalue.to) {
                //console.log('sdddddddddddddd', this.formikRef.current)
                if (this.formikRef.current) {
                    await this.formikRef.current.submitForm();
                }
                swal("Please fill all the required field");
                // Use the formikRef to access the Formik instance and manually submit the form



                this.setState({
                    pdfFinalizeGeneratorloding: false
                });
                return false;
            }
            if ((this.context.formvalue.vendor && this.context.formvalue.vendor == 'DO NOT  DELETE CARRIER') || (this.context.formvalue.driver && this.context.formvalue.driver == 99434)) {
                swal("Please change vendor and diver befor genreate pdf");
                return false;
            }

            if (!this.context.formvalue.vendor || !this.context.formvalue.driver) {
                swal("Please select vendor and diver befor genreate pdf");
                return false;
            }
            this.setState({
                pdfFinalizeGeneratorloding: true
            });
            if (this.state.saveClicked) {

                await PurchaseOrderServices.createOrUpdatePurchaseOrder(this.context.formvalue);
            }

            await PurchaseFormServices.upldatePlanningDriver(this.context.formvalue.driver, match.params.id);
            try {
                const response = await PurchaseOrderServices.createInvoicePdf(this.state.data?.invoicePorecordID, this.context.formvalue.driver, this.state.selectedVendor.vendorid, moment().toDate(), deliveryNotes, messages, this.state.data.transportPORecordID || this.state.transportPOrecordID, this.state.data.invoiceLinkedTo, this.state.selectedVendor.vendorid);
                await this.fetchUpdateData();
                window.removeEventListener('beforeunload', this.functionToChangeStatus);
                if (response) {
                    window.open(BASE_URL + "Invoice.pdf");
                    this.setState({
                        pdfFinalizeGeneratorloding: false
                    });
                }
            } catch (error) {
                setTimeout(() => {
                    toast.error('Please save vendor and diver befor genreate pdf', {
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true
                    });
                });
                this.setState({
                    pdfFinalizeGeneratorloding: false
                });
            }
        }
        else {
            setTimeout(() => {
                toast.warning('Tpo is not rent to own', {
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });
            });
            this.setState({
                pdfFinalizeGeneratorloding: false
            });
        }


    }


    pdfGenerator = async () => {
        window.addEventListener('beforeunload', this.functionToChangeStatus);

        if (this.state.saveClicked) {
            swal("Something has changed, and saving has been initiated.");
            await this.createTransportPurchaseOrder();
        }

        //var count = (this.state.totalContainer > this.state.totalTransportpo && this.state.totalTransportpo) ? '- ' + (this.state.totalTransportpo) : '';
        this.context.formvalue.purchase_order = (this.state.data.pordeRefNumber) ? this.state.data.pordeRefNumber : this.state.transportPORefNumer;
        var minRate = this.state.pickupLocation?.minimumRate;
        var distance = this.state.data?.distance;
        var perMile = this.state.pickupLocation?.perMilePrice;
        var totalBuget = distance * perMile;
        var totalBugetPrice = Math.max(minRate, totalBuget);

        const purchasedOrderLine = new Array();
        var total = 0;
        for (var i = 0; i < this.context.formvalue.length; i++) {
            if (this.context.formvalue['amount' + i.toString()] > 0) {
                total = (total + parseFloat(this.context.formvalue['amount' + i.toString()]));
            }
            purchasedOrderLine.push({
                item: this.context.formvalue['item' + i.toString()],
                description: this.context.formvalue['description' + i.toString()],
                qty: this.context.formvalue['qty' + i.toString()],
                rate: this.context.formvalue['rate' + i.toString()],
                amount: this.context.formvalue['amount' + i.toString()],
                container: this.context.formvalue['container' + i.toString()],
                recordID: this.context.formvalue['recordID' + i.toString()]
            });
        }
        this.context.formvalue.purchasedOrderLine = purchasedOrderLine;

        this.context.formvalue.deposit_call_completed = (this.state?.depotChecked) ? true : false;
        this.context.formvalue.expedited = (this.state.data?.isExpedited) ? true : false;
        this.context.formvalue.refounded = (this.state.data?.isRefunded) ? true : false;

        this.context.formvalue.from = moment().format('YYYY-MM-DD') + ' ' + this.context.formvalue.sfrom
        this.context.formvalue.to = moment().format('YYYY-MM-DD') + ' ' + this.context.formvalue.sto

        this.context.formvalue.customer_notes = this.state.customerNoteCollection;
        this.context.formvalue.vendor_notes = this.state.vendorNoteCollection;
        this.context.formvalue.dispatch_notes = this.state.dispatchNoteCollection;

        this.context.formvalue.record_id = this.state.data?.invoiceLinkedTo
        this.context.formvalue.transportPORecordID = this.state.transportPOrecordID
        this.context.formvalue.transportPO = this.state.transportPOrecordID

        this.context.formvalue.shipAddressAddr1 = this.state.shippingAddress?.shipAddressAddr1
        this.context.formvalue.shipAddressAddr2 = this.state.shippingAddress?.shipAddressAddr2
        this.context.formvalue.shipAddressAddr3 = this.state.shippingAddress?.shipAddressAddr3
        this.context.formvalue.shipAddressCity = this.state.shippingAddress?.shipAddressCity
        this.context.formvalue.shipAddressState = this.state.shippingAddress?.shipAddressState
        this.context.formvalue.shipAddressPostalCode = this.state.shippingAddress?.shipAddressPostalCode
        this.context.formvalue.shipAddressCountry = this.state.shippingAddress?.shipAddressCountry
        this.context.formvalue.billAddressAddr1 = this.state.vendorData?.vendorAddressAddr1
        this.context.formvalue.billAddressAddr2 = this.state.vendorData?.vendorAddressAddr2
        this.context.formvalue.billAddressAddr3 = this.state.vendorData?.vendorAddressAddr3
        this.context.formvalue.billAddressCity = this.state.vendorData?.vendorAddressCity
        this.context.formvalue.billAddressState = this.state.vendorData?.vendorAddressState
        this.context.formvalue.billAddressPostalCode = this.state.vendorData?.vendorAddressPostalCode
        this.context.formvalue.billAddressCountry = this.state.vendorData?.vendorAddressCountry
        this.context.formvalue.amount = total;
        this.context.formvalue.quantity = 1
        this.context.formvalue.rate = (this.state.rateChange) ? this.state.rate : this.state.data.totalAmount
        this.context.formvalue.pickupLocationName = this.state.data?.pickupLocationName ? this.state.data?.pickupLocationName : this.state.data?.resalePOPickupLocationName
        this.context.formvalue.pickupCity = this.state.data?.pickupCity
        this.context.formvalue.pickupState = this.state.data?.pickupState

        this.context.formvalue.distance = (this.state.data.transportPORecordID) ? this.state.data.distanceBetweenPickupAndDeliveryAddress : this.state.data.distance
        this.context.formvalue.pickupPerMileCost = (this.state.data.transportPORecordID) ? this.state.data.pickupPerMileCost : this.state.pickupLocation.perMilePrice
        this.context.formvalue.pickupMinimumRate = (this.state.data.transportPORecordID) ? this.state.data.pickupMinimumRate : this.state.pickupLocation.minimumRate
        this.context.formvalue.pickupBudget = (this.state.data.transportPORecordID) ? this.state.data.pickupBudget : totalBugetPrice

        this.context.formvalue.pickupAddress1 = this.state.data?.pickupAddress1
        this.context.formvalue.pickupZipcode = this.state.data?.pickupZipcode
        this.context.formvalue.pickupPhone = this.state.data?.pickupPhone
        this.context.formvalue.pickupEmail = this.state.data?.pickupEmail
        this.context.formvalue.customer = this.state.data?.customer
        this.context.formvalue.resalePOLinkedToRecordId = this.state.data?.resalePOLinkedToRecordId
        this.context.formvalue.pickupLocationRecordId = this.state.pickupLocation?.recordID
        this.context.formvalue.resalePORecordId = this.state.data?.invoicePorecordID

        this.context.formvalue.release_date_utc = (this.context.formvalue.release_date) ? moment(this.context.formvalue.release_date).format('YYYY-MM-DD') : '';
        this.context.formvalue.date_utc = (this.context.formvalue.date) ? moment(this.context.formvalue.date).format('YYYY-MM-DD') : '';
        this.context.formvalue.promise_date_utc = (this.context.formvalue.promise_date) ? moment(this.context.formvalue.promise_date).format('YYYY-MM-DD') : '';
        this.context.formvalue.expected_date_utc = (this.context.formvalue.expected_date) ? moment(this.context.formvalue.expected_date).format('YYYY-MM-DD') : '';
        this.context.formvalue.scheduled_deleivery_date_utc = (this.context.formvalue.scheduled_deleivery_date) ? moment(this.context.formvalue.scheduled_deleivery_date).format('YYYY-MM-DD') : '';
        this.context.formvalue.actual_deleivery_date_utc = (this.context.formvalue.actual_deleivery_date) ? moment(this.context.formvalue.actual_deleivery_date).format('YYYY-MM-DD') : '';
        this.context.formvalue.actual_pickup_date_utc = (this.context.formvalue.actual_pickup_date) ? moment(this.context.formvalue.actual_pickup_date).format('YYYY-MM-DD') : '';

        const { match } = this.props;
        //const responseinfo = await PurchaseFormServices.getPlanningDriverinfo(match.params.id);
        const deliveryNotes = document.getElementsByName("deliveryNotes")[0]?.value;
        const messages = document.getElementsByName("messages")[0]?.value;
        console.log('sdddddddddddddd', this.context.formvalue)
        // console.log('sdddddddddddddd', this.context.formvalue.vendor, this.context.formvalue.driver, this.context.formvalue.scheduled_deleivery_date_utc , this.context.formvalue.from, this.context.formvalue.to)
        if (!this.context.formvalue.vendor || !this.context.formvalue.driver || !this.context.formvalue.scheduled_deleivery_date_utc || !this.context.formvalue.from || !this.context.formvalue.to) {
            //console.log('sdddddddddddddd', this.formikRef.current)
            if (this.formikRef.current) {
                await this.formikRef.current.submitForm();
            }
            swal("Please fill all the required field");
            // Use the formikRef to access the Formik instance and manually submit the form



            this.setState({
                pdfFinalizeGeneratorloding: false
            });
            return false;
        }
        if ((this.context.formvalue.vendor && this.context.formvalue.vendor == 'DO NOT  DELETE CARRIER') || (this.context.formvalue.driver && this.context.formvalue.driver == 99434)) {
            swal("Please change vendor and diver befor genreate pdf");
            return false;
        }

        if (!this.context.formvalue.vendor || !this.context.formvalue.driver) {
            swal("Please select vendor and diver befor genreate pdf");
            return false;
        }
        this.setState({
            pdfFinalizeGeneratorloding: true
        });
        if (this.state.saveClicked) {

            await PurchaseOrderServices.createOrUpdatePurchaseOrder(this.context.formvalue);
        }

        await PurchaseFormServices.upldatePlanningDriver(this.context.formvalue.driver, match.params.id);
        try {
            const response = await PurchaseOrderServices.pdfGenerator(this.state.data?.invoicePorecordID, this.context.formvalue.driver, this.state.selectedVendor.vendorid, moment().toDate(), deliveryNotes, messages);
            await this.fetchUpdateData();
            window.removeEventListener('beforeunload', this.functionToChangeStatus);
            if (response) {
                window.open(BASE_URL + "loadConfirmation.pdf");
                this.setState({
                    pdfFinalizeGeneratorloding: false
                });
            }
        } catch (error) {
            setTimeout(() => {
                toast.error('Please save vendor and diver befor genreate pdf', {
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });
            });
            this.setState({
                pdfFinalizeGeneratorloding: false
            });
        }
    }




    handleSubmit = async (values) => {
        console.log('formSubmit', values)
        console.log('okokokokkkkkkkkkk', this.state.deleteLineItem)

        await this.setState({
            tpoStatus: true,
            saveClicked: false
        })
        if (this.state.depotChecked) {

            await this.fetchAllNotes();
            window.addEventListener('beforeunload', this.functionToChangeStatus);
            await this.updateTostatus(1, this.state.data.transportPORecordID || this.state.transportPOrecordID);
            this.setState({
                loading: true
            });
            const { match } = this.props;
            await PurchaseFormServices.upldatePlanningDriver(values.driver, match.params.id, this.state.driverName);
            await this.createTransportPurchaseOrder();
            if (this.state.deleteLineItem.length > 0) {
                await PurchaseFormServices.deleteLineItemPurchaseOrder(this.state.deleteLineItem)
            }


            const purchasedOrderLine = new Array();
            var total = 0;
            for (var i = 0; i < values.length; i++) {
                if (values['amount' + i.toString()] > 0) {
                    total = (total + parseFloat(values['amount' + i.toString()]));
                }
                purchasedOrderLine.push({
                    item: values['item' + i.toString()],
                    description: values['description' + i.toString()],
                    qty: values['qty' + i.toString()],
                    rate: values['rate' + i.toString()],
                    amount: values['amount' + i.toString()],
                    container: values['container' + i.toString()],
                    recordID: values['recordID' + i.toString()]
                });
            }

            //var count = (this.state.totalContainer > this.state.totalTransportpo && this.state.totalTransportpo) ? '- ' + (this.state.totalTransportpo) : '';
            values.purchase_order = (this.state.data.pordeRefNumber) ? this.state.data.pordeRefNumber : this.state.transportPORefNumer;
            var minRate = this.state.pickupLocation?.minimumRate;
            var distance = this.state.data.distanceBetweenPickupAndDeliveryAddress;
            var perMile = this.state.pickupLocation?.perMilePrice;
            var totalBuget = distance * perMile;
            var totalBugetPrice = Math.max(minRate, totalBuget);



            values.purchasedOrderLine = purchasedOrderLine;
            values.deposit_call_completed = (this.state?.depotChecked) ? true : false;
            values.expedited = (this.state.data?.isExpedited) ? true : false;
            values.refounded = (this.state.data?.isRefunded) ? true : false;

            values.from = moment().format('YYYY-MM-DD') + ' ' + values.sfrom
            values.to = moment().format('YYYY-MM-DD') + ' ' + values.sto

            values.customer_notes = this.state.customerNoteCollection;
            values.vendor_notes = this.state.vendorNoteCollection;
            values.dispatch_notes = this.state.dispatchNoteCollection;


            values.record_id = this.state.data?.invoiceLinkedTo
            values.transportPORecordID = this.state.transportPOrecordID
            values.transportPO = this.state.transportPOrecordID

            values.shipAddressAddr1 = this.state.shippingAddress?.shipAddressAddr1
            values.shipAddressAddr2 = this.state.shippingAddress?.shipAddressAddr2
            values.shipAddressAddr3 = this.state.shippingAddress?.shipAddressAddr3
            values.shipAddressCity = this.state.shippingAddress?.shipAddressCity
            values.shipAddressState = this.state.shippingAddress?.shipAddressState
            values.shipAddressPostalCode = this.state.shippingAddress?.shipAddressPostalCode
            values.shipAddressCountry = this.state.shippingAddress?.shipAddressCountry

            values.billAddressAddr1 = this.state.vendorData?.vendorAddressAddr1
            values.billAddressAddr2 = this.state.vendorData?.vendorAddressAddr2
            values.billAddressAddr3 = this.state.vendorData?.vendorAddressAddr3
            values.billAddressCity = this.state.vendorData?.vendorAddressCity
            values.billAddressState = this.state.vendorData?.vendorAddressState
            values.billAddressPostalCode = this.state.vendorData?.vendorAddressPostalCode
            values.billAddressCountry = this.state.vendorData?.vendorAddressCountry
            values.amount = total
            values.pickupLocationName = this.state.data?.pickupLocationName ? this.state.data?.pickupLocationName : this.state.data?.resalePOPickupLocationName
            values.pickupCity = this.state.data?.pickupCity
            values.pickupState = this.state.data?.pickupState

            values.distance = (this.state.data.transportPORecordID) ? this.state.data.distanceBetweenPickupAndDeliveryAddress : this.state.data.distanceBetweenPickupAndDeliveryAddress
            values.pickupPerMileCost = (this.state.data.transportPORecordID) ? this.state.data.pickupPerMileCost : this.state.pickupLocation.perMilePrice
            values.pickupMinimumRate = (this.state.data.transportPORecordID) ? this.state.data.pickupMinimumRate : this.state.pickupLocation.minimumRate
            values.pickupBudget = (this.state.data.transportPORecordID) ? this.state.data.pickupBudget : totalBugetPrice

            values.pickupAddress1 = this.state.data?.pickupAddress1
            values.pickupZipcode = this.state.data?.pickupZipcode
            values.pickupPhone = this.state.data?.pickupPhone
            values.pickupEmail = this.state.data?.pickupEmail
            values.customer = this.state.data?.customer
            values.resalePOLinkedToRecordId = this.state.data?.resalePOLinkedToRecordId
            values.pickupLocationRecordId = this.state.pickupLocation?.recordID
            values.resalePORecordId = this.state.data?.invoicePorecordID

            values.release_date_utc = (values.release_date) ? moment(values.release_date).format('YYYY-MM-DD') : null;
            values.date_utc = (values.date) ? moment(values.date).format('YYYY-MM-DD') : null;
            values.promise_date_utc = (values.promise_date) ? moment(values.promise_date).format('YYYY-MM-DD') : null;
            values.expected_date_utc = (values.expected_date) ? moment(values.expected_date).format('YYYY-MM-DD') : null;
            values.scheduled_deleivery_date_utc = (values.scheduled_deleivery_date) ? moment(values.scheduled_deleivery_date).format('YYYY-MM-DD') : null;
            values.actual_deleivery_date_utc = (values.actual_deleivery_date) ? moment(values.actual_deleivery_date).format('YYYY-MM-DD') : null;
            values.actual_pickup_date_utc = (values.actual_pickup_date) ? moment(values.actual_pickup_date).format('YYYY-MM-DD') : null;
            values.LogisticsDepotCallCompleted = this.state.LogisticsDepotCallCompleted;
            values.LogisticsDepotCallCompletedOn = this.state.depoLogDate;
            values.LogisticsDepotCallCompletedBy = this.state.depoLogName;
            console.log('checkValuessssssss', values)

            // return
            ///values.fuelRate = (this.state.fuelSurcharge[0]) ? parseFloat(this.state.fuelSurcharge[0].fuelSurchargeRate).toFixed(2) : ""
            //console.log('fuel Rate', values)
            // return
            try {
                const result = await PurchaseOrderServices.createOrUpdatePurchaseOrder(values);

                if (result) {

                    //await PurchaseOrderServices.updatePurchaseOrderStatus(this.state.data?.invoicePorecordID, 10);
                    //PurchaseOrderServices.notifyToDriver(this.state.data?.invoicePorecordID, this.state.selectedVendor.driverid, moment().toDate(), values.deliveryNotes, values.messages);

                    this.state.data.transportPORecordID = result.purchaseOrderRecordId;
                    await this.updateTostatus(0, this.state.data.transportPORecordID || this.state.transportPOrecordID);
                    await this.fetchUpdateData();
                    window.removeEventListener('beforeunload', this.functionToChangeStatus);
                    this.setState({
                        data: this.state.data,
                        loading: false,
                        tpoStatus: false
                    });

                    setTimeout(() => {
                        toast.success(result.messages, {
                            autoClose: 2000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true
                        });
                    });
                }
            } catch (err) {
                this.setState({
                    loading: false
                });
            }
        }
        else {
           
            setTimeout(() => {
                toast.error(`Depot Call is not Completed`, {
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });
            });

            // this.setState({
            //     depotStatus: true,
            // })
        }
    };

    fetPlanningDriverinfo = async () => {
        const { match } = this.props;

        this.setState(state => {
            return {
                ...state,
                vendorloading: true,

            }
        });
        let response;
        if (match.path == '/exitingPurchaseorder/:id/:recordID') {
            response = await PurchaseFormServices.getPlanningDriverinfoBytransport(match.params.id);
        } else {
            response = await PurchaseFormServices.getPlanningDriverinfo(match.params.id);
        }

        if (response.length != 0) {
            let vendorObject;
            if (match.path == '/exitingPurchaseorder/:id/:recordID') {
                vendorObject = await PurchaseFormServices.fetchActiveDriverByVendor(response[0]?.id);
            } else {
                vendorObject = await PurchaseFormServices.fetchActiveDriverByVendor(response[0]?.id);
            }
            ///console.log("hhhhhhhhhhhhhhhhh", response[0]?.id)
            await this.fetchAllDriverByVendor(response[0]?.id);
            let driverPhone = '';
            if (response[0]?.driverId) {
                driverPhone = await PurchaseFormServices.fetchDriverData(response[0]?.driverId);
            }

            console.log('check111111111', driverPhone)
            this.setState(state => {
                return {
                    ...state,
                    selectedVendor: { overrideStatus: response[0].overrideStatus, value: response[0]?.name, label: response[0]?.companyName, name: response[0]?.name, vendorid: response[0]?.id, driverid: response[0]?.driverId },
                    vendorloading: false,
                    vendorData: vendorObject[0],
                    driverPhone: driverPhone ? driverPhone[0]?.phone : '',
                    driverName: driverPhone ? driverPhone[0]?.name : ''
                }
            });
            localStorage.setItem('vendor', response[0]?.name);
        } else {
            this.setState(state => {
                return {
                    ...state,
                    vendorloading: false,
                }
            });
        }

        if (this.state.vendorOption.filter((item) => item.value == this.state.selectedVendor.value).length == 0) {
            this.setState((old) => {
                return {
                    ...old,
                    vendorOption: [...old.vendorOption, this.state.selectedVendor]
                }
            });
        }
    }

    showTextBox = () => {

        if (this.state.data.PurchaseOrderStatus_RecordID == 2 || this.state.data.purchaseOrderStatus == "Closed") {
            swal("Address cannot be updated since this order has already been delivered.");
            return false;
        }
        this.setState({
            ShippingError: false,
        })
        this.setState((state) => {
            if (state.shippingTextbox) {
                return {
                    ...state,
                    shippingTextbox: false,


                }
            } else {
                return {
                    ...state,
                    shippingTextbox: true,

                }
            }
        });

        if (!this.state.shippingAddress.shipAddressCity) {
            this.setState({
                ShippingCityError: true
            });
        }


        if (!this.state.shippingAddress.shipAddressPostalCode) {
            this.setState({
                ShippingPostalError: true
            })
        }
        if (!this.state.shippingAddress.shipAddressState) {
            this.setState({
                ShippingStateError: true
            })
        }
    }

    emailTrigger = async () => {
        this.setState(state => {
            return {
                ...state,
                showProgressBar: true,
            }
        });
        window.addEventListener('beforeunload', this.functionToChangeStatus);
        if (this.state.saveClicked) {
            swal("Something has changed, and saving has been initiated.");
            await this.createTransportPurchaseOrder();
        }


        //var count = (this.state.totalContainer > this.state.totalTransportpo && this.state.totalTransportpo) ? '- ' + (this.state.totalTransportpo) : '';
        this.context.formvalue.purchase_order = (this.state.data.pordeRefNumber) ? this.state.data.pordeRefNumber : this.state.transportPORefNumer;
        var minRate = this.state.pickupLocation?.minimumRate;
        var distance = this.state.data?.distance;
        var perMile = this.state.pickupLocation?.perMilePrice;
        var totalBuget = distance * perMile;
        var totalBugetPrice = Math.max(minRate, totalBuget);


        const purchasedOrderLine = new Array();
        var total = 0;
        for (var i = 0; i < this.context.formvalue.length; i++) {
            if (this.context.formvalue['amount' + i.toString()] > 0) {
                total = (total + parseFloat(this.context.formvalue['amount' + i.toString()]));
            }
            purchasedOrderLine.push({
                item: this.context.formvalue['item' + i.toString()],
                description: this.context.formvalue['description' + i.toString()],
                qty: this.context.formvalue['qty' + i.toString()],
                rate: this.context.formvalue['rate' + i.toString()],
                amount: this.context.formvalue['amount' + i.toString()],
                container: this.context.formvalue['container' + i.toString()],
                recordID: this.context.formvalue['recordID' + i.toString()]
            });
        }
        this.context.formvalue.purchasedOrderLine = purchasedOrderLine;

        this.context.formvalue.deposit_call_completed = (this.state?.depotChecked) ? true : false;
        this.context.formvalue.expedited = (this.state.data?.isExpedited) ? true : false;
        this.context.formvalue.refounded = (this.state.data?.isRefunded) ? true : false;

        this.context.formvalue.customer_notes = this.state.customerNoteCollection;
        this.context.formvalue.vendor_notes = this.state.vendorNoteCollection;
        this.context.formvalue.dispatch_notes = this.state.dispatchNoteCollection;

        this.context.formvalue.record_id = this.state.data?.invoiceLinkedTo
        this.context.formvalue.transportPORecordID = this.state.transportPOrecordID
        this.context.formvalue.transportPO = this.state.transportPOrecordID

        this.context.formvalue.from = moment().format('YYYY-MM-DD') + ' ' + this.context.formvalue.sfrom
        this.context.formvalue.to = moment().format('YYYY-MM-DD') + ' ' + this.context.formvalue.sto

        this.context.formvalue.shipAddressAddr1 = this.state.shippingAddress?.shipAddressAddr1
        this.context.formvalue.shipAddressAddr2 = this.state.shippingAddress?.shipAddressAddr2
        this.context.formvalue.shipAddressAddr3 = this.state.shippingAddress?.shipAddressAddr3
        this.context.formvalue.shipAddressCity = this.state.shippingAddress?.shipAddressCity
        this.context.formvalue.shipAddressState = this.state.shippingAddress?.shipAddressState
        this.context.formvalue.shipAddressPostalCode = this.state.shippingAddress?.shipAddressPostalCode
        this.context.formvalue.shipAddressCountry = this.state.shippingAddress?.shipAddressCountry
        this.context.formvalue.billAddressAddr1 = this.state.vendorData?.vendorAddressAddr1
        this.context.formvalue.billAddressAddr2 = this.state.vendorData?.vendorAddressAddr2
        this.context.formvalue.billAddressAddr3 = this.state.vendorData?.vendorAddressAddr3
        this.context.formvalue.billAddressCity = this.state.vendorData?.vendorAddressCity
        this.context.formvalue.billAddressState = this.state.vendorData?.vendorAddressState
        this.context.formvalue.billAddressPostalCode = this.state.vendorData?.vendorAddressPostalCode
        this.context.formvalue.billAddressCountry = this.state.vendorData?.vendorAddressCountry
        this.context.formvalue.amount = total
        this.context.formvalue.quantity = 1
        this.context.formvalue.rate = (this.state.rateChange) ? this.state.rate : this.state.data.totalAmount
        this.context.formvalue.pickupLocationName = this.state.data?.pickupLocationName ? this.state.data?.pickupLocationName : this.state.data?.resalePOPickupLocationName
        this.context.formvalue.pickupCity = this.state.data?.pickupCity
        this.context.formvalue.pickupState = this.state.data?.pickupState

        this.context.formvalue.distance = (this.state.data.transportPORecordID) ? this.state.data.distanceBetweenPickupAndDeliveryAddress : this.state.data.distance
        this.context.formvalue.pickupPerMileCost = (this.state.data.transportPORecordID) ? this.state.data.pickupPerMileCost : this.state.pickupLocation.perMilePrice
        this.context.formvalue.pickupMinimumRate = (this.state.data.transportPORecordID) ? this.state.data.pickupMinimumRate : this.state.pickupLocation.minimumRate
        this.context.formvalue.pickupBudget = (this.state.data.transportPORecordID) ? this.state.data.pickupBudget : totalBugetPrice

        this.context.formvalue.pickupAddress1 = this.state.data?.pickupAddress1
        this.context.formvalue.pickupZipcode = this.state.data?.pickupZipcode
        this.context.formvalue.pickupPhone = this.state.data?.pickupPhone
        this.context.formvalue.pickupEmail = this.state.data?.pickupEmail
        this.context.formvalue.customer = this.state.data?.customer
        this.context.formvalue.resalePOLinkedToRecordId = this.state.data?.resalePOLinkedToRecordId
        this.context.formvalue.pickupLocationRecordId = this.state.pickupLocation?.recordID
        this.context.formvalue.resalePORecordId = this.state.data?.invoicePorecordID

        this.context.formvalue.release_date_utc = (this.context.formvalue.release_date) ? moment(this.context.formvalue.release_date).format('YYYY-MM-DD') : '';
        this.context.formvalue.date_utc = (this.context.formvalue.date) ? moment(this.context.formvalue.date).format('YYYY-MM-DD') : '';
        this.context.formvalue.promise_date_utc = (this.context.formvalue.promise_date) ? moment(this.context.formvalue.promise_date).format('YYYY-MM-DD') : '';
        this.context.formvalue.expected_date_utc = (this.context.formvalue.expected_date) ? moment(this.context.formvalue.expected_date).format('YYYY-MM-DD') : '';
        this.context.formvalue.scheduled_deleivery_date_utc = (this.context.formvalue.scheduled_deleivery_date) ? moment(this.context.formvalue.scheduled_deleivery_date).format('YYYY-MM-DD') : '';
        this.context.formvalue.actual_deleivery_date_utc = (this.context.formvalue.actual_deleivery_date) ? moment(this.context.formvalue.actual_deleivery_date).format('YYYY-MM-DD') : '';
        this.context.formvalue.actual_pickup_date_utc = (this.context.formvalue.actual_pickup_date) ? moment(this.context.formvalue.actual_pickup_date).format('YYYY-MM-DD') : '';
        if (!this.context.formvalue.vendor || !this.context.formvalue.driver || !this.context.formvalue.scheduled_deleivery_date_utc || !this.context.formvalue.from || !this.context.formvalue.to) {
            //console.log('sdddddddddddddd', this.formikRef.current)
            if (this.formikRef.current) {
                await this.formikRef.current.submitForm();
            }
            swal("Please fill all the required field");
            // Use the formikRef to access the Formik instance and manually submit the form



            this.setState(state => {
                return {
                    ...state,
                    showProgressBar: false,
                }
            });
            return false;
        }
        if ((this.context.formvalue.vendor && this.context.formvalue.vendor == 'DO NOT  DELETE CARRIER') || (this.context.formvalue.driver && this.context.formvalue.driver == 99434)) {
            swal("Please change vendor and diver befor trigger email");
            return false;
        }

        if (!this.context.formvalue.vendor || !this.context.formvalue.driver) {
            swal("Please select vendor and diver befor trigger email");
            return false;
        }

        const { match } = this.props;
        if (this.state.saveClicked) {

            await PurchaseOrderServices.createOrUpdatePurchaseOrder(this.context.formvalue);

        }
        await PurchaseFormServices.upldatePlanningDriver(this.context.formvalue.driver, match.params.id);

        //const responseinfo = await PurchaseFormServices.getPlanningDriverinfo(match.params.id);
        const deliveryNotes = document.getElementsByName("deliveryNotes")[0]?.value;
        const messages = document.getElementsByName("messages")[0]?.value;
        try {

            // this.setState({
            //     sending: true
            // });

            const response = await PurchaseOrderServices.notifyToDriver(this.state.data?.invoicePorecordID, this.context.formvalue.driver, this.state.selectedVendor.vendorid, moment().toDate(), deliveryNotes, messages);
            window.removeEventListener('beforeunload', this.functionToChangeStatus);

            console.log('rrrrrrrrrrrrrrrrrrrrrrrrrrrrrr', response)
            if (response.status == 200) {

                await this.fetchUpdateData();
                this.setState(state => {
                    return {
                        ...state,
                        showProgressBar: false,
                    }
                });
                // this.setState({
                //     sending: false
                // });
                swal("Success!", "Mail has triggered to the vendor and driver", "success");

            }

            else if (result.status == 500) {
                toast.error(result.message, {
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });
            }
            else {
                toast.error('Oops something went wrong please try again!!', {
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });
            }
        } catch (error) {
            console.log('rrrrrrrrrrrrrrrrrrrrrrrrrrrrrr', error)
            setTimeout(() => {
                toast.error('Oops something went wrong please try again!!', {
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });
            });
            this.setState({
                sending: false
            });
        }
    }

    updateShipping = async () => {

        this.setState({
            updateShippingError: true,
            saveClicked: false
        });

        window.addEventListener('beforeunload', this.functionToChangeStatus);
        await this.createTransportPurchaseOrder();
        await this.updateTostatus(1, this.state.data.transportPORecordID || this.state.transportPOrecordID);
        if (this.state.transportPOrecordID) {
            if (
                this.state.shippingAddress.shipAddressAddr1 != document.getElementsByName("shipline1")[0]?.value
                || this.state.shippingAddress.shipAddressAddr2 != document.getElementsByName("shipline2")[0]?.value
                || this.state.shippingAddress.shipAddressAddr3 != document.getElementsByName("shipline3")[0]?.value
                || this.state.shippingAddress.shipAddressAddr4 != document.getElementsByName("shipline4")[0]?.value
                || this.state.shippingAddress.shipAddressAddr5 != document.getElementsByName("shipline5")[0]?.value
                || this.state.shippingAddress.shipAddressCity != document.getElementsByName("shipcity")[0]?.value
                || this.state.shippingAddress.shipAddressCountry != document.getElementsByName("shipcountry")[0]?.value
                || this.state.shippingAddress.shipAddressPostalCode != document.getElementsByName("shipzip")[0]?.value
                || this.state.shippingAddress.shipAddressState != document.getElementsByName("shipstate")[0]?.value
            ) {
                await InvoiceServices.updateShippingTopurchaseorder({
                    shipAddressAddr1: document.getElementsByName("shipline1")[0]?.value,
                    shipAddressAddr2: document.getElementsByName("shipline2")[0]?.value,
                    shipAddressAddr3: document.getElementsByName("shipline3")[0]?.value,
                    shipAddressAddr4: document.getElementsByName("shipline4")[0]?.value,
                    shipAddressAddr5: document.getElementsByName("shipline5")[0]?.value,
                    shipAddressCity: document.getElementsByName("shipcity")[0]?.value,
                    shipAddressCountry: document.getElementsByName("shipcountry")[0]?.value,
                    shipAddressPostalCode: document.getElementsByName("shipzip")[0]?.value,
                    shipAddressState: document.getElementsByName("shipstate")[0]?.value,
                    invoicerecordID: this.state.data?.invoiceLinkedTo,
                    refnumber: this.state.data?.invoiceId,
                }, this.state.transportPOrecordID);
                await this.fetchUpdateData();

                window.removeEventListener('beforeunload', this.functionToChangeStatus);
                setTimeout(() => {
                    toast.success('Shipping address successfully updated', {
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true
                    });
                });


                this.setState((old) => {
                    return {
                        ...old,
                        shippingAddress: {
                            shipAddressAddr1: document.getElementsByName("shipline1")[0]?.value,
                            shipAddressAddr2: document.getElementsByName("shipline2")[0]?.value,
                            shipAddressAddr3: document.getElementsByName("shipline3")[0]?.value,
                            shipAddressAddr4: document.getElementsByName("shipline4")[0]?.value,
                            shipAddressAddr5: document.getElementsByName("shipline5")[0]?.value,
                            shipAddressCity: document.getElementsByName("shipcity")[0]?.value,
                            shipAddressCountry: document.getElementsByName("shipcountry")[0]?.value,
                            shipAddressPostalCode: document.getElementsByName("shipzip")[0]?.value,
                            shipAddressState: document.getElementsByName("shipstate")[0]?.value
                        },
                        updateShippingError: false,
                        shippingTextbox: false,

                    }
                });
                await this.fetchDate();
            } else {
                this.setState((state) => {
                    return {
                        ...state,
                        updateShippingError: false,
                        shippingTextbox: false
                    }
                });
            }
        } else {
            setTimeout(() => {
                toast.error('Please create transport purchased order before update shipping address', {
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });
            });
        }
    }

    pushAttachementFile = async (location, token, filename) => {
        this.setState((state) => {
            return {
                ...state,
                attachment: [...state.attachment, { location, token, filename }]
            }
        });
    }

    deleteAttachementFile = async (e) => {
        var tokeninfo = e.target.attributes.valueinfo.value;
        swal({
            text: "Deleting this file attachment will permanently remove it from logistic app",
            icon: "warning",
            dangerMode: true,
            buttons: true,
        }).then(async (status) => {
            if (status) {
                await PurchaseFormServices.deleteAttachment({ token: tokeninfo, tableName: "purchaseOrders", recordID: this.state.data.transportPORecordID || this.state.transportPOrecordID });
                await this.fetchAttachment(this.state.data.transportPORecordID || this.state.transportPOrecordID);
            }
        });
    }

    fetchUpdateData = async () => {
        const { match } = this.props;

        if (match.path == '/exitingPurchaseorder/:id/:recordID') {
            var response = await PurchaseOrderServices.getTransportPurchaseOrderInfo(match.params.recordID);
        } else {
            var response = await PurchaseFormServices.getData(match.params.id);
        }
        this.setState(state => {
            return {
                ...state,
                data: response[0]
            }
        });
    }

    fetchDate = async () => {
        this.setState(state => {
            return {
                ...state,
                fetchloading: true,
            }
        });
        const { match } = this.props;

        await this.fetchFuelSurcharge();

        if (match.path == '/exitingPurchaseorder/:id/:recordID') {
            var response = await PurchaseOrderServices.getTransportPurchaseOrderInfo(match.params.recordID);
            if (match.params.recordID != 15577) {
                await this.fetallOptionAll();
            }

        } else {
            var updateDistance = await PurchaseFormServices.updateDistance(match.params.id);
            var response = await PurchaseFormServices.getData(match.params.id);
            this.fetallOption();
        }

        console.log(response, 'responseinfo');

        //await this.fetchPickupLocation(response[0]?.pickupLocationName ? response[0]?.pickupLocationName : response[0]?.resalePOPickupLocationName, response[0]?.pickupAddress1 ? response[0]?.pickupAddress1 : response[0]?.resalePOPickupAddress1, response[0]?.pickupLocation ? response[0]?.pickupLocation : response[0]?.resalePOPickupLocationRecordIDNew);
        await this.fetchPickupLocation(response[0]?.resalePOPickupLocationName ? response[0]?.resalePOPickupLocationName : response[0]?.pickupLocationName, response[0]?.resalePOPickupAddress1 ? response[0]?.resalePOPickupAddress1 : response[0]?.pickupAddress1, response[0]?.resalePOPickupLocationRecordIDNew ? response[0]?.resalePOPickupLocationRecordIDNew : response[0]?.pickupLocation);
        const result = await PurchaseFormServices.transportCount(response[0]?.invoiceId, response[0]?.invoicePorecordID);
        this.setState({
            transportPORefNumer: result.transportPORefNumer
        });

        var shippingObject = (response[0]?.shipAddressAddr1) ? response[0]?.shipAddressAddr1 : ' ' + ' ' + (response[0]?.shipAddressAddr2) ? response[0]?.shipAddressAddr2 : ' ' + ' ' + (response[0]?.shipAddressAddr3) ? response[0]?.shipAddressAddr3 : ' ' + ' ' + (response[0]?.shipAddressCity) ? response[0]?.shipAddressCity : ' ' + ' ' + (response[0]?.shipAddressState) ? response[0]?.shipAddressState : ' ' + ' ' + (response[0]?.shipAddressPostalCode) ? response[0]?.shipAddressPostalCode : ' ' + ' ' + (response[0]?.shipAddressCountry) ? response[0]?.shipAddressCountry : ' ';
        var customerNote = response[0]?.resalePOCustomerNotes?.replace('null', '')?.replace('undefined', '');
        var vendorNote = response[0]?.InvoiceVendorNotes?.replace('null', '')?.replace('undefined', '');
        var dispatchNote = response[0]?.InvoiceDispatchNotes?.replace('null', '')?.replace('undefined', '');

        if (response.length) {

            await this.validateShipAddress(response[0].shipAddressAddr1, response[0].shipAddressAddr2, response[0].shipAddressAddr3)
            if (!response[0].shipAddressCity || !response[0].shipAddressState || !response[0].shipAddressPostalCode) {
                this.setState({
                    ShippingError: true
                })
            }
            else {
                await this.validateAddress(response[0].shipAddressAddr1, response[0].shipAddressAddr2, response[0].shipAddressAddr3, response[0].shipAddressCity, response[0].shipAddressState, response[0].shipAddressPostalCode, response[0].shipAddressCountry);

            }

            if (response[0].milesStatus == 1) {
                this.setState({
                    milesStatus: true
                })
            }
            else {
                this.setState({
                    milesStatus: false
                })
            }
            console.log('ssssssssssseesazsdasa', response[0].distanceUpdated)

            if (response[0].distanceUpdated == 1) {
                this.setState({
                    distanceUpdatedVariance: true
                })
            }
            else {
                this.setState({
                    distanceUpdatedVariance: false
                })
            }

            //this.checkOrderStatus();
            this.setState(state => {
                const { customerNoteCollection, dispatchNoteCollection, vendorNoteCollection } = state;

                return {
                    ...state,
                    shipping: shippingObject,
                    data: response[0],
                    shippingAddress: {
                        shipAddressAddr1: response[0].shipAddressAddr1,
                        shipAddressAddr2: response[0].shipAddressAddr2,
                        shipAddressAddr3: response[0].shipAddressAddr3,
                        shipAddressAddr4: response[0].shipAddressAddr4,
                        shipAddressAddr5: response[0].shipAddressAddr5,
                        shipAddressCity: response[0].shipAddressCity,
                        shipAddressCountry: response[0].shipAddressCountry,
                        shipAddressPostalCode: response[0].shipAddressPostalCode,
                        shipAddressState: response[0].shipAddressState
                    },
                    fetchloading: false,
                    rate: response[0]?.totalAmount,
                    depotChecked: response[0]?.isLastDepotCallCompleted,
                    customerNoteCollection: customerNote,
                    vendorNoteCollection: vendorNote,
                    dispatchNoteCollection: dispatchNote,
                    FuelRate: response[0]?.FuelRate,
                    pickupPerMileCost: response[0].pickupPerMileCost,
                    pickupMinimumRate: response[0].pickupMinimumRate,
                    invoiceLineItem_RecordID: response[0].invoiceLineItem_RecordID,
                    transportPODeliveredDate: response[0].transportPODeliveredDate,
                    resalePO: response[0].resalePO,
                    depoLogDate: response[0].LogisticsDepotCallCompletedOn,
                    depoLogName: response[0].LogisticsDepotCallCompletedBy,
                    LogisticsDepotCallCompleted: response[0].LogisticsDepotCallCompleted
                }
            });

            //this.checkOrderStatus();
            //this.fetchTransportPOCount();
            await this.fechFinalizeStatus();
            //300000
            // Set up a setInterval to call your method every 5 minutes (300,000 milliseconds).
            //this.intervalId = setInterval(this.fetchAllNotes, 20000);

            //await this.fetchAllNotes(this.state.transportPOrecordID || this.state.data.transportPORecordID)
        }
    }



    // setInterval(() => {
    //     const data = await PurchaseFormServices.getAllNotes(recordID);
    //     const InvoiceDispatchNotes = data[0].InvoiceDispatchNotes?.replace('null', '')?.replace('undefined', '');
    //     const InvoiceVendorNotes = data[0].InvoiceVendorNotes?.replace('null', '')?.replace('undefined', '');
    //     const resalePOCustomerNotes = data[0].resalePOCustomerNotes?.replace('null', '')?.replace('undefined', '');
    //     console.log('hhhhhhhhhhhhhhhhhhhsssssssshhhhhhhhhsss', data[0].InvoiceDispatchNotes)?.replace('null', '')?.replace('undefined', '');
    //   }, 1000 * 60 * 5);




    fetchAllNotes = async () => {
        const recordID = this.state.transportPOrecordID || this.state.data.transportPORecordID
        if (recordID) {
            const data = await PurchaseFormServices.getAllNotes(recordID);
            const InvoiceDispatchNotes = data[0].InvoiceDispatchNotes?.replace('null', '')?.replace('undefined', '');
            const InvoiceVendorNotes = data[0].InvoiceVendorNotes?.replace('null', '')?.replace('undefined', '');
            const resalePOCustomerNotes = data[0].resalePOCustomerNotes?.replace('null', '')?.replace('undefined', '');
            if (resalePOCustomerNotes && this.state.customerNoteCollection != resalePOCustomerNotes) {
                this.setState({
                    customerNoteCollection: resalePOCustomerNotes
                })
                console.log('Value Changed of Customer Notes')
            }
            if (InvoiceVendorNotes && this.state.vendorNoteCollection != InvoiceVendorNotes) {
                this.setState({
                    vendorNoteCollection: InvoiceVendorNotes
                })
                console.log('Value Changed of Vendor Notes')
            }
            if (InvoiceDispatchNotes && this.state.dispatchNoteCollection != InvoiceDispatchNotes) {
                this.setState({
                    dispatchNoteCollection: InvoiceDispatchNotes
                })
                console.log('Value Changed of Dispatch Notes')
            }
            console.log('hhhhhhhhhhhhhhhhhhhsssssssshhhhhhhhhsss', data[0].InvoiceDispatchNotes)?.replace('null', '')?.replace('undefined', '');
        }


    }

    handleDepot = async (e) => {
        let isChecked = e.target.checked;
        if (isChecked) {
            this.setState({
                depotChecked: true
            })
        } else {
            this.setState({
                depotChecked: false
            })
        }

    }

    checkOrderStatus = async () => {
        const result = await PurchaseFormServices.getPurchaseOrderStatus(this.state.data?.recordID);
        this.setState(state => {
            return {
                ...state,
                orderstatus: result.status,
            }
        });

        if (result.status) {
            swal("Purchase order has been created.........");
        }
    }

    fetallOptionAll = async () => {
        const response = await PurchaseFormServices.fetchAllVendor();
        console.log('checkkkkAllll', response)
        const option = new Array();
        for (var i = 0; i < response.length; i++) {
            if (response[i].name) {
                option.push({ value: response[i].name, label: response[i].companyName, name: response[i].name, vendorid: response[i].id });
            }
        }

        this.setState(state => {
            return {
                ...state,
                vendorOption: option
            }
        });
    }

    fetallOption = async () => {
        const response = await PurchaseFormServices.getVendor();
        const option = new Array();
        for (var i = 0; i < response.length; i++) {
            if (response[i].name) {
                option.push({ value: response[i].name, label: response[i].companyName, name: response[i].name, vendorid: response[i].id });
            }
        }
        this.setState(state => {
            return {
                ...state,
                vendorOption: option
            }
        });
    }

    fetchAllDriverByVendor = async (id) => {



        this.setState(state => {
            return {
                ...state,
                driverloading: true
            }
        });

        let response;
        const { match } = this.props;
        //console.log("okaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa", id)
        if (match.path == '/exitingPurchaseorder/:id/:recordID') {
            response = await PurchaseFormServices.fetchActiveDriverByVendor(id);
        } else {
            response = await PurchaseFormServices.fetchActiveDriverByVendor(id);
        }

        const option = new Array();
        for (var i = 0; i < response.length; i++) {

            if (response[i].name) {
                option.push({ value: response[i].id, label: response[i].name });
            }
        }

        this.setState(state => {
            return {
                ...state,
                driverOption: option,
                driverloading: false
            }
        });
    }

    multiContainerDiffDistance = async (invoiceRefNumber, invoiceRecordID, transportPo, distance, pickupAddress,refNumber) => {
        try {
            if(refNumber !== invoiceRefNumber+'T')
            {
                const result = await PurchaseOrderServices.multiContainerDistanceVariance(invoiceRefNumber,invoiceRecordID, transportPo, distance, pickupAddress)
                console.log('checkkckkkkkkkkkResult', result.data)
                if (result.data) {
                    console.log('checkkckkkkkkkkkResult', result.distance)
                    this.setState({
                        multiContainerVariance: true,
                        muticontanerDistance: result.distance
                    })
                }
            }
         
        }
        catch (error) {
            this.setState({
                multiContainerVariance: false,
                muticontanerDistance: null
            })
        }
        
    }

    validateZipAddress = async (pickup, ship, distance, address, city, state) => {

        const results = await PurchaseOrderServices.validateZipAddress(pickup, ship, address, city, state);
        console.log("rrrrrrrrrrrrrrrr", results);
        if (results?.distance) {
            const distanceDiffernce = distance - results.distance;
            console.log("rrrrrrrrrrrrrrrrdissssss", distanceDiffernce);
            if (distanceDiffernce > 20) {
                this.setState({
                    distanceDiffStatus: true,
                    distanceVariance: '+' + parseFloat(distanceDiffernce).toFixed(2)
                })
            }
            else if (distanceDiffernce < -20) {
                this.setState({
                    distanceDiffStatus: true,
                    distanceVariance: parseFloat(distanceDiffernce).toFixed(2)
                })
            }
            else {
                this.setState({
                    distanceDiffStatus: false,
                })
            }
        }
        // if(results.status === 403)
        // {
        //     this.setState({
        //         ShippinfErrorMessage: 'Verification Failed',
        //         ShippingError: true
        //     })
        // }
    }

    validateShipAddress = async (shipAddressAddr1, shipAddressAddr2, shipAddressAddr3) => {
        try {
            var hasNumber = /\d/;
            if (hasNumber.test(shipAddressAddr1)) {
                console.log("Number exist")
                if (isNaN(shipAddressAddr1.charAt(0))) {
                    console.log("hey")

                    this.setState({
                        ShipWarning: true
                    })

                }
                else {

                    this.setState({
                        ShipWarning: false
                    })
                }
            }
            else if (hasNumber.test(shipAddressAddr2)) {
                console.log("Number exist")
                if (isNaN(shipAddressAddr2.charAt(0))) {
                    this.setState({
                        ShipWarning: true
                    })

                }
                else {

                    this.setState({
                        ShipWarning: false
                    })
                }
            }
            else if (hasNumber.test(shipAddressAddr3)) {
                console.log("Number exist")
                if (isNaN(shipAddressAddr3.charAt(0))) {
                    this.setState({
                        ShipWarning: true
                    })

                }
                else {

                    this.setState({
                        ShipWarning: false
                    })
                }
            }
            else {

                this.setState({
                    ShipWarning: false
                })
            }
            return true
            // else if(hasNumber.test(shipAddressAddr4)){
            //     console.log("Number exist")
            //     if (isNaN(shipAddressAddr4.charAt(0)))
            //     {
            //         this.setState({
            //             ShipWarning: true 
            //         })
            //         return true;
            //     }
            // }
            // else if(hasNumber.test(shipAddressAddr5)){
            //     console.log("Number exist")
            //     if (isNaN(shipAddressAddr5.charAt(0)))
            //     {
            //         this.setState({
            //             ShipWarning: true 
            //         })
            //         return true;
            //     }
            // }
        }
        catch (error) {
            console.log(error, 'validationError');
        }
        return false;

    }

    fetchPickupLocation = async (pickupLocationName, pickupAddress1, recordID) => {
        try {
            if (pickupLocationName && pickupAddress1) {
                const response = await PurchaseFormServices.getPicklocationByName(pickupLocationName, pickupAddress1, recordID);
                this.setState({
                    pickupLocation: response[0]
                });
            }
        } catch (error) {

        }
    }

    fetchTransportPOCount = async () => {
        try {
            const response = await PurchaseFormServices.fetchTransportPOCount(this.state.data?.invoiceId);
            this.setState({
                totalTransportpo: response[0].transportpo,
                totalContainer: response[0].resalepo
            });
        } catch (error) {

        }
    }

    handleFinalizeDelivery = async () => {
        const finalizeUpdate = {
            invoiceLineItem_RecordID: this.state.invoiceLineItem_RecordID,
            recordID: this.state.data?.invoicePorecordID,
            invoiceRecordID: this.state.data?.invoiceLinkedTo,
            resalePO: await parseInt(this.state.resalePO),
            resalePORefNumber: this.state.data?.resalePOLinkedTo,
            deliveredDate: (this.state.data?.deliveryDate) ? this.state.data?.deliveryDate : moment(this.context.formvalue.actual_deleivery_date),
            trnasportPORefNumber: (this.state.data.pordeRefNumber) ? this.state.data.pordeRefNumber : this.state.transportPORefNumer
        }


        console.log('finalizeUpdate', finalizeUpdate, this.state.saveClicked)




        window.addEventListener('beforeunload', this.functionToChangeStatus);
        if (this.state.data.purchaseOrderStatus && this.state.data.purchaseOrderStatus == "Closed") {
            swal("This order has already been finalized");
            return false;
        }
        if (this.state.saveClicked) {
            swal("Something has changed, and saving has been initiated.");
            await this.createTransportPurchaseOrder();
        }
        //var count = (this.state.totalContainer > this.state.totalTransportpo && this.state.totalTransportpo) ? '- ' + (this.state.totalTransportpo) : '';
        this.context.formvalue.purchase_order = (this.state.data.pordeRefNumber) ? this.state.data.pordeRefNumber : this.state.transportPORefNumer;
        var minRate = this.state.pickupLocation?.minimumRate;
        var distance = this.state.data?.distance;
        var perMile = this.state.pickupLocation?.perMilePrice;
        var totalBuget = distance * perMile;
        var totalBugetPrice = Math.max(minRate, totalBuget);




        const purchasedOrderLine = new Array();
        var total = 0;
        for (var i = 0; i < this.context.formvalue.length; i++) {
            if (this.context.formvalue['amount' + i.toString()] > 0) {
                total = (total + parseFloat(this.context.formvalue['amount' + i.toString()]));
            }
            purchasedOrderLine.push({
                item: this.context.formvalue['item' + i.toString()],
                description: this.context.formvalue['description' + i.toString()],
                qty: this.context.formvalue['qty' + i.toString()],
                rate: this.context.formvalue['rate' + i.toString()],
                amount: this.context.formvalue['amount' + i.toString()],
                container: this.context.formvalue['container' + i.toString()],
                recordID: this.context.formvalue['recordID' + i.toString()]
            });
        }
        this.context.formvalue.purchasedOrderLine = purchasedOrderLine;


        this.context.formvalue.deposit_call_completed = (this.state?.depotChecked) ? true : false;
        this.context.formvalue.expedited = (this.state.data?.isExpedited) ? true : false;
        this.context.formvalue.refounded = (this.state.data?.isRefunded) ? true : false;

        this.context.formvalue.from = moment().format('YYYY-MM-DD') + ' ' + this.context.formvalue.sfrom
        this.context.formvalue.to = moment().format('YYYY-MM-DD') + ' ' + this.context.formvalue.sto

        this.context.formvalue.customer_notes = this.state.customerNoteCollection;
        this.context.formvalue.vendor_notes = this.state.vendorNoteCollection;
        this.context.formvalue.dispatch_notes = this.state.dispatchNoteCollection;

        this.context.formvalue.record_id = this.state.data?.invoiceLinkedTo
        this.context.formvalue.transportPORecordID = this.state.transportPOrecordID
        this.context.formvalue.transportPO = this.state.transportPOrecordID

        this.context.formvalue.shipAddressAddr1 = this.state.shippingAddress?.shipAddressAddr1
        this.context.formvalue.shipAddressAddr2 = this.state.shippingAddress?.shipAddressAddr2
        this.context.formvalue.shipAddressAddr3 = this.state.shippingAddress?.shipAddressAddr3
        this.context.formvalue.shipAddressCity = this.state.shippingAddress?.shipAddressCity
        this.context.formvalue.shipAddressState = this.state.shippingAddress?.shipAddressState
        this.context.formvalue.shipAddressPostalCode = this.state.shippingAddress?.shipAddressPostalCode
        this.context.formvalue.shipAddressCountry = this.state.shippingAddress?.shipAddressCountry

        this.context.formvalue.billAddressAddr1 = this.state.vendorData?.vendorAddressAddr1
        this.context.formvalue.billAddressAddr2 = this.state.vendorData?.vendorAddressAddr2
        this.context.formvalue.billAddressAddr3 = this.state.vendorData?.vendorAddressAddr3
        this.context.formvalue.billAddressCity = this.state.vendorData?.vendorAddressCity
        this.context.formvalue.billAddressState = this.state.vendorData?.vendorAddressState
        this.context.formvalue.billAddressPostalCode = this.state.vendorData?.vendorAddressPostalCode
        this.context.formvalue.billAddressCountry = this.state.vendorData?.vendorAddressCountry
        this.context.formvalue.amount = total
        this.context.formvalue.quantity = 1
        this.context.formvalue.rate = (this.state.rateChange) ? this.state.rate : this.state.data.totalAmount
        this.context.formvalue.pickupLocationName = this.state.data?.pickupLocationName ? this.state.data?.pickupLocationName : this.state.data?.resalePOPickupLocationName
        this.context.formvalue.pickupCity = this.state.data?.pickupCity
        this.context.formvalue.pickupState = this.state.data?.pickupState

        this.context.formvalue.distance = (this.state.data.transportPORecordID) ? this.state.data.distanceBetweenPickupAndDeliveryAddress : this.state.data.distance
        this.context.formvalue.pickupPerMileCost = (this.state.data.transportPORecordID) ? this.state.data.pickupPerMileCost : this.state.pickupLocation.perMilePrice
        this.context.formvalue.pickupMinimumRate = (this.state.data.transportPORecordID) ? this.state.data.pickupMinimumRate : this.state.pickupLocation.minimumRate
        this.context.formvalue.pickupBudget = (this.state.data.transportPORecordID) ? this.state.data.pickupBudget : totalBugetPrice

        this.context.formvalue.pickupAddress1 = this.state.data?.pickupAddress1
        this.context.formvalue.pickupZipcode = this.state.data?.pickupZipcode
        this.context.formvalue.pickupPhone = this.state.data?.pickupPhone
        this.context.formvalue.pickupEmail = this.state.data?.pickupEmail
        this.context.formvalue.customer = this.state.data?.customer
        this.context.formvalue.resalePOLinkedToRecordId = this.state.data?.resalePOLinkedToRecordId
        this.context.formvalue.pickupLocationRecordId = this.state.pickupLocation?.recordID
        this.context.formvalue.resalePORecordId = this.state.data?.invoicePorecordID

        this.context.formvalue.release_date_utc = (this.context.formvalue.release_date) ? moment(this.context.formvalue.release_date).format('YYYY-MM-DD') : '';
        this.context.formvalue.date_utc = (this.context.formvalue.date) ? moment(this.context.formvalue.date).format('YYYY-MM-DD') : '';
        this.context.formvalue.promise_date_utc = (this.context.formvalue.promise_date) ? moment(this.context.formvalue.promise_date).format('YYYY-MM-DD') : '';
        this.context.formvalue.expected_date_utc = (this.context.formvalue.expected_date) ? moment(this.context.formvalue.expected_date).format('YYYY-MM-DD') : '';
        this.context.formvalue.scheduled_deleivery_date_utc = (this.context.formvalue.scheduled_deleivery_date) ? moment(this.context.formvalue.scheduled_deleivery_date).format('YYYY-MM-DD') : '';
        this.context.formvalue.actual_deleivery_date_utc = (this.context.formvalue.actual_deleivery_date) ? moment(this.context.formvalue.actual_deleivery_date).format('YYYY-MM-DD') : '';
        this.context.formvalue.actual_pickup_date_utc = (this.context.formvalue.actual_pickup_date) ? moment(this.context.formvalue.actual_pickup_date).format('YYYY-MM-DD') : '';

        if ((this.context.formvalue.vendor && this.context.formvalue.vendor == 'DO NOT  DELETE CARRIER') || (this.context.formvalue.driver && this.context.formvalue.driver == 99434)) {
            swal("Please change vendor and diver befor finalize delivery");
            return false;
        }

        if (!this.context.formvalue.vendor || !this.context.formvalue.driver) {
            swal("Please select vendor and diver befor finalize delivery");
            return false;
        }

        swal({
            text: "Are you sure you want to finalize the order?",
            icon: "warning",
            dangerMode: true,
            buttons: true,
        }).then(async (status) => {
            try {
                this.setState({
                    loadingFinalizeDelivery: true,
                    showProgressBar: true,
                });


                if (status) {
                    if (this.state.saveClicked) {
                        await PurchaseOrderServices.createOrUpdatePurchaseOrder(this.context.formvalue);
                    }
                    window.removeEventListener('beforeunload', this.functionToChangeStatus);

                    let result = await PurchaseOrderServices.getContainerNumber(this.state.data.transportPORecordID);
                    if (result.filter((item) => item.containerNumber == "" || item.containerNumber == null).length == 1) {
                        swal("Please make sure all the containers numbers are added before finalizing order");
                        this.setState({
                            loadingFinalizeDelivery: false,
                            showProgressBar: false
                        });
                    } else {

                        let result = await PurchaseOrderServices.getPurchasedOrderByrecordID(this.state.data.transportPORecordID);

                        if (result[0].pickupDate == null || result[0].pickupDate == "" || result[0].deliveryDate == null || result[0].deliveryDate == "") {
                            swal("Please make sure pickup and delivery dates are added before finalizing order");
                            this.setState({
                                loadingFinalizeDelivery: false,
                                showProgressBar: false
                            });
                        } else {

                            let billingRecordId = await PurchaseOrderServices.doAddBillingToMethod({ vendor: this.state.selectedVendor.name, object: this.state.data, vendorid: this.state.selectedVendor.vendorid, pickupLocation: this.state.pickupLocation?.recordID, deliveredDate: (this.state.data?.deliveryDate) ? this.state.data?.deliveryDate : moment(this.context.formvalue.actual_deleivery_date), });
                            console.log('finalizeUpdate', finalizeUpdate)
                            const updatePurchaseOrder = await PurchaseOrderServices.updateFinalizeUpdate(finalizeUpdate)
                            console.log('sssssssssdd', updatePurchaseOrder)
                            if (billingRecordId) {
                                const deliveryNotes = document.getElementsByName("deliveryNotes")[0]?.value;
                                        const messages = document.getElementsByName("messages")[0]?.value;
                                        
                                await PurchaseOrderServices.finalizeOrderSync({ billingRecordID: billingRecordId, recordID: this.state.data.transportPORecordID, invoiceRefNumber: this.state.data.invoiceId },this.state.data.invoiceTerms,{ transportPORecordID: this.state.data.transportPORecordID || this.state.transportPOrecordID,recordID : this.state.data?.invoicePorecordID,driverid : this.context.formvalue.driver,date : moment().toDate(),vendorecordID: this.state.selectedVendor.vendorid,deliveryNotes,messages,userID : localStorage.getItem('userId'),invoiceRecordID: this.state.data.invoiceLinkedTo,vendorId: this.state.selectedVendor.vendorid })

                                // await PurchaseOrderServices.doAddPurchaseOrderLineToMethod({ billingRecordID: billingRecordId, recordID: this.state.data.transportPORecordID, invoiceRefNumber: this.state.data.invoiceId }).then(async (item) => {
                                //     if (this.state.data.invoiceTerms == 'Rent To Own') {
                                //         const deliveryNotes = document.getElementsByName("deliveryNotes")[0]?.value;
                                //         const messages = document.getElementsByName("messages")[0]?.value;
                                //         await PurchaseOrderServices.finalEmailDelivery(this.state.data?.invoicePorecordID, this.context.formvalue.driver, this.state.selectedVendor.vendorid, moment().toDate(), deliveryNotes, messages, this.state.data.transportPORecordID || this.state.transportPOrecordID, this.state.data.invoiceLinkedTo, this.state.selectedVendor.vendorid);
                                //     }

                                    this.fechFinalizeStatus();
                                    await this.fetchUpdateData();
                                    this.setState({
                                        loadingFinalizeDelivery: false,
                                        showProgressBar: false
                                    });
                                    swal("Your bill has been successfully created");

                                // });
                            }

                        }
                    }

                } else {
                    this.setState({
                        loadingFinalizeDelivery: false,
                        showProgressBar: false
                    });
                }
            } catch (error) {
                this.setState({
                    loadingFinalizeDelivery: false,
                    showProgressBar: false
                });
                console.log("doAddBillingToMethod error" + error);
            }
        });
    }


    fechFinalizeStatus = async () => {


        if (this.state.data.transportPORecordID == null || this.state.data.transportPORecordID == 0) {
            return false;
        }
        try {
            const response = await PurchaseOrderServices.getPurchasedOrderByrecordID(this.state.data.transportPORecordID);
            this.setState((old) => {
                return {
                    ...old,
                    purchaseOrderStatus: response[0]?.purchaseOrderStatus
                }
            })
        } catch (error) {
            console.log(error, 'fechFinalizeStatus');
        }
    }

    functionToRun = (e) => {
        e.preventDefault();
        e.returnValue = '';
        const transportPOrecordID = this.state.transportPOrecordID || this.state.data.transportPORecordID;
        if (localStorage.getItem('vendor') == "DO NOT  DELETE CARRIER") {
            const token = localStorage.getItem(ACCESS_TOKEN_NAME);
            fetch(`http://34.196.241.152/demo?recordID=` + transportPOrecordID, {
                headers: {
                    Authorization: "Bearer " + token,
                },
            });
        }
    }



    componentDidUpdate() {
        if (this.state.selectedVendor.name && this.state.selectedVendor.name != "DO NOT  DELETE CARRIER") {
            window.removeEventListener('beforeunload', this.functionToRun);
        }
    }

    async updateTostatus(status, transportPOrecordID) {
        await PurchaseOrderServices.updatePOstatus(status, transportPOrecordID);
    }
    
    functionToChangeStatus = (e) => {
        e.preventDefault();
        e.returnValue = e.returnValue = 'Are you sure you want to leave?';
        const token = localStorage.getItem(ACCESS_TOKEN_NAME);
        const transportPOrecordID = this.state.transportPOrecordID || this.state.data.transportPORecordID;
        fetch(`http://34.196.241.152/api/controller/WindowPOStatus?status=0&recordID=` + transportPOrecordID, {
            headers: {
                Authorization: "Bearer " + token,
            },
        });
    }

    async componentDidMount() {

        await this.fetPlanningDriverinfo();
        await this.fetchDate();
        await this.fetchInvoiceLineItem();
        await this.validateZipAddress(this.state.data?.resalePOPickupZipCode ? this.state.data?.resalePOPickupZipCode : this.state.data?.pickupZipcode, this.state.shippingAddress?.shipAddressPostalCode, Math.round(this.state.data.distanceBetweenPickupAndDeliveryAddress), this.state.data?.resalePOPickupAddress1 ? this.state.data?.resalePOPickupAddress1 : this.state.data?.pickupAddress1, (this.state.data.resalePOPickupCity) ? this.state.data?.resalePOPickupCity : this.state.data?.pickupCity, this.state.data.resalePOPickupState ? this.state.data.resalePOPickupState : this.state.data.pickupState)
        await this.multiContainerDiffDistance(this.state.data.invoiceId, this.state.data.invoiceLinkedTo,this.state.data.transportPORecordID || this.state.transportPOrecordID, this.state.data.distanceBetweenPickupAndDeliveryAddress, this.state.data?.resalePOPickupAddress1 ? this.state.data?.resalePOPickupAddress1 : this.state.data?.pickupAddress1,  (this.state.data.pordeRefNumber) ? this.state.data.pordeRefNumber : this.state.transportPORefNumer)
        //document.body.addEventListener('click', this.createTransportPurchaseOrder, true); 
        // await this.fetchFuelSurcharge(); 
        await this.fetchAttachment(this.state.data.transportPORecordID || this.state.transportPOrecordID);


        window.addEventListener('beforeunload', this.functionToChangeStatus);
        window.addEventListener('unload', this.functionToChangeStatus);

        const selectedVendor = this.state.selectedVendor.name;
        if (selectedVendor == "DO NOT  DELETE CARRIER") {
            window.addEventListener('beforeunload', this.functionToRun);
        }

        //this.customerNote.click()
        // await this.updateTostatus(1, this.state.data.transportPORecordID || this.state.transportPOrecordID);
        //await this.syncData();
    }

    updateWindowStatus = async () => {
        await this.updateTostatus(1, this.state.data.transportPORecordID || this.state.transportPOrecordID);
    }



    // Defining the componentWillUnmount method
    async componentWillUnmount() {
        window.removeEventListener('beforeunload', this.functionToChangeStatus);
        window.removeEventListener('unload', this.functionToChangeStatus);
        //await this.updateTostatus(1, this.state.data.transportPORecordID || this.state.transportPOrecordID);
        if (this.state.selectedVendor.name == "DO NOT  DELETE CARRIER") {
            this.cancelDispatchedWithConfirm();
        }
        // Make sure to clear the interval when the component is unmounted to prevent memory leaks.
        clearInterval(this.intervalId);

    }

    customerNote = async () => {


        const timezone = jstz.determine();
        if (this.state.customerNote == null) {
            return false;
        }
        await this.setState({
            notesLoading: true
        })

        await this.fetchAllNotes();

        await this.setState(state => {
            const { customerNote, customerNoteCollection } = state;
            return {
                ...state,
                customerNote: null,
                customerNoteCollection: customerNote + ' - ' + localStorage.getItem('user') + ' ' + '(' + moment(moment().toDate()).tz(timezone.name()).format('MM/DD/YYYY  HH:mm A') + ')\n' + customerNoteCollection
            }
        });
        await this.createTransportPurchaseOrder();
        await this.updateTostatus(1, this.state.data.transportPORecordID || this.state.transportPOrecordID);



        if (this.state.transportPOrecordID) {
            await InvoiceServices.updateCustomerNote({ invoicerecordID: this.state.data.invoiceLinkedTo, invoicePorecordID: this.state.data.invoicePorecordID, category: 'po', type: 'customernote', customer_note: this.state.customerNoteCollection, realePORecordID: this.state.data?.resalePOLinkedToRecordId }, this.state.transportPOrecordID);
            setTimeout(() => {
                toast.success('Customer note updated successfully', {
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });
            });

        } else {
            setTimeout(() => {
                toast.error('Please create transport purchased order before update customer note', {
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });
            });
        }

        this.setState({
            notesLoading: false
        })

        document.getElementById('customerinput').value = null
    }


    vendorNote = async () => {


        const timezone = jstz.determine();
        if (this.state.vendorNote == null) {
            return false;
        }
        await this.setState({
            notesLoading: true
        })

        await this.fetchAllNotes();
        await this.setState(state => {
            const { vendorNote, vendorNoteCollection } = state;
            return {
                ...state,
                vendorNote: null,
                vendorNoteCollection: vendorNote + ' - ' + localStorage.getItem('user') + ' ' + '(' + moment(moment().toDate()).tz(timezone.name()).format('MM/DD/YYYY  HH:mm A') + ')\n' + vendorNoteCollection
            }
        });
        await this.createTransportPurchaseOrder();
        await this.updateTostatus(1, this.state.data.transportPORecordID || this.state.transportPOrecordID);


        if (this.state.transportPOrecordID) {
            await InvoiceServices.updateVendorNote({ invoicerecordID: this.state.data.invoiceLinkedTo, invoicePorecordID: this.state.data.invoicePorecordID, category: 'po', type: 'vendornote', vendor_note: this.state.vendorNoteCollection, realePORecordID: this.state.data?.resalePOLinkedToRecordId }, this.state.transportPOrecordID);
            setTimeout(() => {
                toast.success('Vendor note updated successfully', {
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });
            });

        } else {
            setTimeout(() => {
                toast.error('Please create transport purchased order before update vendor note', {
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });
            });
        }
        this.setState({
            notesLoading: false
        })
        document.getElementById('vendorinput').value = null
    }


    dispatchNote = async () => {

        const timezone = jstz.determine();
        if (this.state.dispatchNote == null) {
            return false;
        }
        await this.setState({
            notesLoading: true
        })
        await this.fetchAllNotes();

        await this.setState(state => {
            const { dispatchNote, dispatchNoteCollection } = state;
            return {
                ...state,
                dispatchNote: null,
                dispatchNoteCollection: dispatchNote + ' - ' + localStorage.getItem('user') + ' ' + '(' + moment(moment().toDate()).tz(timezone.name()).format('MM/DD/YYYY  HH:mm A') + ')\n' + dispatchNoteCollection
            }
        });
        await this.createTransportPurchaseOrder();
        await this.updateTostatus(1, this.state.data.transportPORecordID || this.state.transportPOrecordID);



        if (this.state.transportPOrecordID) {
            await InvoiceServices.updateDispatchNote({ invoicerecordID: this.state.data.invoiceLinkedTo, invoicePorecordID: this.state.data.invoicePorecordID, category: 'po', type: 'dispatchednote', dsipatch_note: this.state.dispatchNoteCollection, realePORecordID: this.state.data?.resalePOLinkedToRecordId }, this.state.transportPOrecordID);

            setTimeout(() => {
                toast.success('Dispatch note updated successfully', {
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });
            });

        } else {
            setTimeout(() => {
                toast.error('Please create transport purchased order before update dispatch note', {
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });
            });
        }
        this.setState({
            notesLoading: false
        })
        document.getElementById('dispatchinput').value = null
    }

    updateloading = (status) => {
        this.setState({
            polineloading: status
        });
    }

    handleChange = (e) => {
        let StateName = e.target.name

        if (this.state[StateName] == 'undefined' || this.state[StateName] != e.target.value) {
            console.log('hshshhshshdgdfgdsggdgdgdg', e.target.name, this.state[e.target.name], e.target.value)
            this.setState({
                saveClicked: true
            })
        }
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    handleRate = (e) => {
        this.setState({
            rate: e.target.value,
            rateChange: true
        });
    }

    driverChange = async (resposne) => {
        console.log('check Response', resposne)
        console.log('sssssssssssssssssssssssssssssssssssssazsdad')
        const driverPhone = await PurchaseFormServices.fetchDriverData(resposne);
        this.setState({
            driverPhone: driverPhone ? driverPhone[0]?.phone : '',
            driverName: driverPhone ? driverPhone[0]?.name : '',
            saveClicked: true
        })
    }

    recevedVendorinfo = async (response, vendorid) => {
        console.log(response[0]?.companyName, "achaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa")


        //this.fetchAllDriverByVendor(vendorid);
        //const driverData = await PurchaseFormServices.fetchActiveDriverByVendor(vendorid);
        //console.log("asddasddddddddddddd", driverData)
        // let driverPhone = ''
        // if(response[0]?.driverId)
        //         {
        //             driverPhone = await PurchaseFormServices.fetchDriverData(response[0]?.driverId);
        //         }
        localStorage.setItem('vendor', response[0]?.fullName);
        await this.setState({
            vendorData: response[0],
            vendorChage: true,
            selectedVendor: { overrideStatus: response[0]?.overrideStatus, value: response[0]?.fullName, label: response[0]?.companyName, name: response[0]?.fullName, vendorid: vendorid, driverid: response[0]?.driverId },
            // driverPhone: driverPhone ? driverPhone[0]?.phone : ''
        });

        //   console.log("asddasddddddddddddd", this.state.selectedVendor)
    }


    initialValues = () => {
        const timezone = jstz.determine();
        //var count = (this.state.totalContainer > this.state.totalTransportpo && this.state.totalTransportpo) ? '- ' + (this.state.totalTransportpo) : '';        
        return this.schema.initialValues({
            [fields.productService.name]: 'Container Transportation',
            [fields.customerPhone.name]: this.state.data?.customerMainPhone,
            [fields.supplierVendor.name]: this.state.data?.supplierVendorInvoiceNumber,
            [fields.customerALtPhone.name]: this.state.data?.customerAltPhone,
            [fields.pickupLocation.name]: this.state.data?.pickupLocationName ? this.state.data?.pickupLocationName : this.state.data?.resalePOPickupLocationName,
            [fields.releaseNumber.name]: this.state.data?.releaseNumber,
            [fields.date.name]: (this.state.data.resalePOTxnDate) ? this.state.data.resalePOTxnDate : moment(moment().toDate(), 'YYYY-MM-DD'),
            [fields.supplierLocation.name]: this.state.data?.SupplierLocation,
            [fields.releaseNumber.name]: this.state.data?.resalePOReleaseNumber,
            [fields.shipedMehtod.name]: this.state.data?.shipMethodMethodField,
            [fields.shipTo.name]: this.state.data?.shiptoname,
            [fields.invoiceLinkedTo.name]: this.state.data?.invoiceLinkedTo,
            [fields.containerOrientation.name]: this.state.data?.containerOrientation,
            [fields.vendor.name]: this.state.selectedVendor.value,
            [fields.vendorid.name]: this.state.selectedVendor.vendorid,
            [fields.promiseTimeLine.name]: this.state.data?.promiseDateTimeline,
            [fields.releaseDate.name]: (this.state.data?.releaseDate) ? moment(this.state.data?.releaseDate.split('T')[0]).format('MM/DD/YYYY') : '',
            [fields.actualPickupDate.name]: (this.state.data?.pickupDate) ? this.state.data?.pickupDate.split('T')[0] : '',
            [fields.depositcallCompleted.name]: (this.state?.depotChecked) ? 1 : '',
            //[fields.purchaseOrder.name]: (this.state.data.pordeRefNumber) ? this.state.data.pordeRefNumber : this.state.data?.invoiceId + 'T' + count,
            [fields.expectedDate.name]: (this.state.data?.expectedDate) ? moment(this.state.data?.expectedDate.split('T')[0]).format('MM/DD/YYYY') : '',
            [fields.scheduledDeleiveryDate.name]: (this.state.data?.scheduledDate) ? moment(this.state.data?.scheduledDate.split('T')[0]).format('MM/DD/YYYY') : '',
            [fields.deleiveryStatus.name]: this.state.data?.deliveryStatus,
            [fields.actualDeleiveryDate.name]: (this.state.data?.deliveryDate) ? moment(this.state.data?.deliveryDate.split('T')[0]).format('MM/DD/YYYY') : '',
            [fields.promiseDate.name]: (this.state.data?.promiseDate) ? this.state.data?.promiseDate.split('T')[0] : '',
            [fields.rate.name]: this.state.data?.totalAmount,
            [fields.totalAmount.name]: this.state.data?.totalAmount,
            [fields.memo.name]: this.state.data?.memo,
            [fields.pickupNotes.name]: this.state.data?.pickupMsg,
            [fields.resalePOLinkedTo.name]: this.state.data?.resalePOLinkedTo,
            [fields.deliveryNotes.name]: this.state.data?.deliveryNotes,
            [fields.messages.name]: this.state.data?.message,

            [fields.from.name]: (this.state.data && this.state.data.scheduledDeliveryTimeFromText) ? moment(this.state.data.scheduledDeliveryTimeFromText).format('HH:mm') : null,
            [fields.to.name]: (this.state.data && this.state.data.scheduledDeliveryTimeToText) ? moment(this.state.data.scheduledDeliveryTimeToText).format('HH:mm') : null,


            [fields.container.name]: this.state.data?.containerNumber,

            [fields.shipline1.name]: this.state.shippingAddress?.shipAddressAddr1,
            [fields.shipline2.name]: this.state.shippingAddress?.shipAddressAddr2,
            [fields.shipline3.name]: this.state.shippingAddress?.shipAddressAddr3,
            [fields.shipline4.name]: this.state.shippingAddress?.shipAddressAddr4,
            [fields.shipline5.name]: this.state.shippingAddress?.shipAddressAddr5,
            [fields.shipcity.name]: this.state.shippingAddress?.shipAddressCity,
            [fields.shipstate.name]: this.state.shippingAddress?.shipAddressState,
            [fields.shipzip.name]: this.state.shippingAddress?.shipAddressPostalCode,
            [fields.shipcountry.name]: this.state.shippingAddress?.shipAddressCountry,

        });
    };




    render() {
        console.log('ccccccccccccc', this.state.data.TransportOverrideDistanceStatus)
        console.log('invoiceLineItem_RecordID', this.state.invoiceLineItem_RecordID)

        console.log('hsssssssssss', this.state.distanceUpdatedVariance)
        console.log('check ResalePORecordID', this.state.data?.resalePOLinkedToRecordId)
        //console.log('okaaaaaaaaaaaaaaaaaaa', this.state.fuelSurcharge[0] ? this.state.fuelSurcharge[0].fuelSurchargeRate)
        // console.log("Line Haul",parseFloat(Math.max(this.state.pickupLocation?.minimumRate, (this.state.data.distanceBetweenPickupAndDeliveryAddress * this.state.pickupLocation?.perMilePrice))).toFixed(2))
        // console.log("rrr",this.state.pickupLocation?.perMilePrice)
        // console.log("22", this.state.vendorOption);
        // console.log("33", this.state.selectedVendor.value);

        // if (this.state.postatusloading || this.state.fetchloading || this.state.driverloading || this.state.vendorloading || (this.state.selectedVendor.value && typeof (this.state.vendorOption.find((item) => item.value == this.state.selectedVendor.value)) == 'undefined')) {

        //     // this.state.fetchloading
        //     // this.state.vendorloading || typeof(this.state.vendorOption.find((item)=>item.value == this.state.selectedVendor.value)) == 'undefined'
        //     return (<i class="fa fa-spinner fa-spin center-position" />);
        // }
        if (this.state.postatusloading || this.state.fetchloading || this.state.driverloading || this.state.vendorloading) {

            // this.state.fetchloading
            // this.state.vendorloading || typeof(this.state.vendorOption.find((item)=>item.value == this.state.selectedVendor.value)) == 'undefined'
            return (<i class="fa fa-spinner fa-spin center-position" />);
        }
        const timezone = jstz.determine();

        var minRate = this.state.pickupLocation?.minimumRate;
        var distance = this.state.data?.distance;
        var perMile = this.state.pickupLocation?.perMilePrice;
        var totalBuget = distance * perMile;
        var totalBugetPrice = Math.max(minRate, totalBuget);
        //var count = (this.state.totalContainer > this.state.totalTransportpo && this.state.totalTransportpo) ? '- ' + (this.state.totalTransportpo) : '';

        return (
            <>
                <div className="row" >
                    <div className="col-sm-6">
                        <h2 className='heading-title'><i className="fa fa-file-text icon-file-text" /> Purchase Order : {
                            (this.state.data.pordeRefNumber) ? this.state.data.pordeRefNumber : this.state.transportPORefNumer
                        }</h2>
                        <ul className="list-item-purchase">
                            <li>{this.state.vendorData?.fullName ? this.state.vendorData?.fullName : ''}</li>
                        </ul>
                    </div>
                    <div className="col-sm-6 total-amount-text">
                        <p>Total Amount: {(this.state.rateChange) ? (this.state.rate) ? parseFloat(this.state.rate)?.toFixed(2) : '' : this.state.data.totalAmount?.toFixed(2)}</p>
                        {
                            this.state.data.purchaseOrderStatus && this.state.data.purchaseOrderStatus == "Closed" && (<p style={{ "color": "green" }}>Finalized Delivery</p>)
                        }
                    </div>
                </div>
                {
                    this.state.showProgressBar
                        ?
                        <ProgressBar />
                        :
                        ''
                }
                {
                    this.state.showProgressBar2
                        ?
                        <ProgressBar2 />
                        :
                        ''
                }


                <ToastContainer />
                <Formik
                    initialValues={this.initialValues()}
                    onSubmit={this.handleSubmit}
                    validationSchema={this.schema.schema}
                    innerRef={this.formikRef}
                >
                    {({ errors, touched, handleSubmit }) => (
                        <form onSubmit={handleSubmit} className="purchase-order-form">
                            <div className="purchase-order-form-list" onClick={this.updateWindowStatus}>
                                <ul>
                                    <li>
                                        <Input
                                            placeholder={'193329T-1'}
                                            name={fields.productService.name}
                                            label={fields.productService.label}
                                            disabled={true}
                                        />


                                        {/* <div class="form-group"><label for="vendor">Vendor</label>
                                                <select
                                                className='form-control'
                                                name={fields.vendor.name}
                                                onChange={this.recevedVendorinfo}
                                                >
                                                    {this.state.vendorOption.map(option => (
                                                <option
                                                    key={option.value}
                                                    value={option.value === this.state.selectedVendor.value ? 'same-as-selected' : ''}
                                                
                                                >
                                                    {option.label}
                                                </option>
                                                ))}
                                                </select>
                                        </div> */}



                                        <Vendor
                                            name={fields.vendor.name}
                                            label={fields.vendor.label}
                                            placeholder={'Select Vendor'}
                                            option={this.state.vendorOption}
                                            defaultValue={this.state.selectedVendor}
                                            value={this.state.selectedVendor.value}
                                            fun={this.recevedVendorinfo}
                                            matchinfo={this.props.match.path}
                                            newFun={this.handleChangeValue}
                                        />

                                        <Driver
                                            name={fields.driver.name}
                                            label={fields.driver.label}
                                            placeholder={'Select Driver'}
                                            option={this.state.driverOption}
                                            defaultValue={this.state.selectedVendor}
                                            value={this.state.selectedVendor}
                                            vendorChage={this.state.vendorChage}
                                            matchinfo={this.props.match.path}
                                            fun={this.driverChange}
                                        />

                                        <FormState />
                                        <div className="myNew-box form-group">
                                            <label className="form-label" for="box">Driver Phone</label>
                                            <input className="form-control" type="text" value={this.state.driverPhone ? this.state.driverPhone : ''} name="box" id="box" disabled />
                                        </div>
                                        <Input
                                            name={fields.shipTo.name}
                                            label={fields.shipTo.label}
                                            placeholder={'Select Ship To'}
                                            disabled={true}
                                        />


                                        <Input
                                            placeholder={'Enter Customer Phone Number'}
                                            name={fields.customerPhone.name}
                                            label={fields.customerPhone.label}
                                            disabled={false}
                                            fun={this.handleChangeValue}
                                        />

                                        <Input
                                            placeholder={'Enter Customer Alt Phone'}
                                            name={fields.customerALtPhone.name}
                                            label={fields.customerALtPhone.label}
                                            disabled={false}
                                            fun={this.handleChangeValue}
                                        />

                                        <div className="form-group">

                                            <label for="ServicePurchase"></label>
                                            <div className="billing-input-icon">Billing
                                                <label className="edit-icon-bill"></label>
                                            </div>

                                            <p class="address-text">
                                                {
                                                    (this.state.vendorData?.vendorAddressAddr1) ?
                                                        <> {this.state.vendorData?.vendorAddressAddr1}<br /></>
                                                        :
                                                        ''
                                                }
                                                {
                                                    (this.state.vendorData?.vendorAddressAddr2) ?
                                                        <> {this.state.vendorData?.vendorAddressAddr2}<br /></>
                                                        :
                                                        ''
                                                }
                                                {
                                                    (this.state.vendorData?.vendorAddressAddr3) ?
                                                        <> {this.state.vendorData?.vendorAddressAddr3}<br /></>
                                                        :
                                                        ''
                                                }

                                                {
                                                    (this.state.vendorData?.vendorAddressAddr4) ?
                                                        <> {this.state.vendorData?.vendorAddressAddr4}<br /></>
                                                        :
                                                        ''
                                                }

                                                {
                                                    (this.state.vendorData?.vendorAddressAddr5) ?
                                                        <> {this.state.vendorData?.vendorAddressAddr5}<br /></>
                                                        :
                                                        ''
                                                }

                                                {
                                                    (this.state.vendorData?.vendorAddressCity || this.state.vendorData?.vendorAddressState) ?
                                                        <>{this.state.vendorData?.vendorAddressCity}&nbsp;{this.state.vendorData.vendorAddressState}<br /></>
                                                        :
                                                        ''
                                                }

                                                {
                                                    (this.state.vendorData?.vendorAddressPostalCode) ?
                                                        <>{this.state.vendorData.vendorAddressPostalCode}<br /></>
                                                        :
                                                        ''
                                                }

                                                {
                                                    (this.state.vendorData?.vendorAddressCountry) ?
                                                        <>{this.state.vendorData.vendorAddressCountry}<br /></>
                                                        :
                                                        ''
                                                }
                                            </p>

                                            {
                                                /*
                                                    <label htmlFor="ServicePurchase" />
                                                    <div className="billing-input-icon">Billing
                                                        <label onClick={()=>this.showTextBox('Billing')} className="edit-icon-bill"><i className="fa fa-pencil" /></label>
                                                    </div>

                                                    {
                                                        (this.state.billingTextbox) ? 
                                                            <textarea name={fields.billing.name}  className="form-control">{this.state.billing}</textarea>
                                                        :
                                                        <p className="address-text">
                                                            {
                                                                this.state.billing
                                                            }
                                                        </p>
                                                    }
                                                */
                                            }
                                        </div>

                                        <div className="form-group">
                                            {
                                                /*
                                                   <div className="billing-input-icon">Shiping
                                                       <label onClick={()=>this.showTextBox('Shiping')} className="edit-icon-bill"><i className="fa fa-pencil" /></label>
                                                   </div>
                                                   {
                                                       (this.state.shippingTextbox) ? 
                                                           <textarea name={fields.shipping.name} className="form-control">{this.state.shipping}</textarea>
                                                       :
                                                       <p className="address-text">
                                                           {
                                                               this.state.shipping
                                                           }
                                                       </p>
                                                   }
                                                */
                                            }

                                            <label for="ServicePurchase"></label>
                                            <div className="billing-input-icon">Shipping

                                                {
                                                    this.state.ShippingError ?
                                                        <div type="button" class="badgeShow">
                                                            {this.state.ShippinfErrorMessage}<span class="bdgIcon"><i class="fa fa-exclamation-triangle" aria-hidden="true"></i></span>
                                                        </div>
                                                        :
                                                        ""
                                                }
                                                {/* &nbsp;&nbsp;<span style={{border:"1px solid Red",display:this.state.ShippingError ? "inlineblock" : "none"}}><strong style={{color:"Red"}}>Invalid Address</strong></span> */}
                                                {
                                                    this.state.shippingTextbox ?
                                                        <label onClick={this.updateShipping} className="edit-icon-bill" style={{ pointerEvents: this.state.updateShippingError ? 'none' : 'auto' }}>Update</label>
                                                        :
                                                        <label onClick={this.showTextBox} className="edit-icon-bill">Edit</label>
                                                }
                                            </div>

                                            {this.state.shippingTextbox
                                                ?
                                                <>
                                                    <Input
                                                        name={fields.shipline1.name}
                                                        placeholder={'Ship Line 1'}
                                                        fun={this.handleChangeValue}
                                                    />

                                                    <Input
                                                        name={fields.shipline2.name}
                                                        placeholder={'Ship Line 2'}
                                                        fun={this.handleChangeValue}
                                                    />

                                                    <Input
                                                        name={fields.shipline3.name}
                                                        placeholder={'Ship Line 3'}
                                                        fun={this.handleChangeValue}
                                                    />

                                                    <Input
                                                        name={fields.shipline4.name}
                                                        placeholder={'Ship Line 4'}
                                                        fun={this.handleChangeValue}
                                                    />

                                                    <Textarea
                                                        name={fields.shipline5.name}
                                                        placeholder={'Ship Line 5'}
                                                        fun={this.handleChangeValue}
                                                    />


                                                    <div class="row">
                                                        <div className={`col-sm-6 ${this.state.ShippingCityError ? 'shipCssErrr' : ''}`}>
                                                            <div className="form-group">
                                                                <input
                                                                    type="text"
                                                                    name={fields.shipcity.name}
                                                                    placeholder={'City'}
                                                                    className="form-control"
                                                                    id="shipcity"
                                                                    onChange={(e) => { this.handleCheck(e); this.handleChange(e); }}
                                                                    defaultValue={this.state.shippingAddress.shipAddressCity}
                                                                />

                                                            </div>
                                                        </div>
                                                        <div className={`col-sm-6 ${this.state.ShippingStateError ? 'shipCssErrr' : ''}`}>
                                                            <div className="form-group">
                                                                <input
                                                                    type="text"
                                                                    name={fields.shipstate.name}
                                                                    placeholder={'State'}
                                                                    className="form-control"
                                                                    id="shipstate"
                                                                    onChange={(e) => { this.handleCheck(e); this.handleChange(e) }}
                                                                    defaultValue={this.state.shippingAddress.shipAddressState}
                                                                />

                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div className={`col-sm-6 ${this.state.ShippingPostalError ? 'shipCssErrr' : ''}`}>
                                                            <div className="form-group">
                                                                <input
                                                                    type="text"
                                                                    name={fields.shipzip.name}
                                                                    placeholder={'Zip / Postal Code'}
                                                                    id="shipzip"
                                                                    className="form-control"
                                                                    onChange={(e) => { this.handleCheck(e); this.handleChange(e) }}
                                                                    defaultValue={this.state.shippingAddress.shipAddressPostalCode}
                                                                />
                                                            </div>

                                                        </div>
                                                        <div className={`col-sm-6 ${this.state.ShippingCountryError ? 'shipCssErrr' : ''}`}>
                                                            <div className="form-group">
                                                                <input
                                                                    type="text"
                                                                    name={fields.shipcountry.name}
                                                                    placeholder={'Country'}
                                                                    id="shipcountry"
                                                                    className="form-control"
                                                                    onChange={(e) => { this.handleCheck(e); this.handleChange(e) }}
                                                                    defaultValue={this.state.shippingAddress.shipAddressCountry ? this.state.shippingAddress.shipAddressCountry : 'United States'}
                                                                />
                                                            </div>


                                                        </div>
                                                    </div>
                                                </>
                                                :
                                                <p class="address-text">
                                                    {
                                                        (this.state.shippingAddress.shipAddressAddr1) ?
                                                            <> {this.state.shippingAddress?.shipAddressAddr1}<br /></>
                                                            :
                                                            ''
                                                    }
                                                    {
                                                        (this.state.shippingAddress.shipAddressAddr2) ?
                                                            <> {this.state.shippingAddress?.shipAddressAddr2}<br /></>
                                                            :
                                                            ''
                                                    }
                                                    {
                                                        (this.state.shippingAddress.shipAddressAddr3) ?
                                                            <> {this.state.shippingAddress?.shipAddressAddr3}<br /></>
                                                            :
                                                            ''
                                                    }

                                                    {
                                                        (this.state.shippingAddress.shipAddressAddr4) ?
                                                            <> {this.state.shippingAddress?.shipAddressAddr4}<br /></>
                                                            :
                                                            ''
                                                    }

                                                    {
                                                        (this.state.shippingAddress.shipAddressAddr5) ?
                                                            <> {this.state.shippingAddress?.shipAddressAddr5}<br /></>
                                                            :
                                                            ''
                                                    }

                                                    {
                                                        (this.state.shippingAddress.shipAddressCity || this.state.shippingAddress?.shipAddressState) ?
                                                            <>{this.state.shippingAddress?.shipAddressCity}&nbsp;{this.state.shippingAddress.shipAddressState}<br /></>
                                                            :
                                                            ''
                                                    }

                                                    {
                                                        (this.state.shippingAddress?.shipAddressPostalCode) ?
                                                            <>{this.state.shippingAddress.shipAddressPostalCode}<br /></>
                                                            :
                                                            ''
                                                    }

                                                    {
                                                        (this.state.shippingAddress?.shipAddressCountry) ?
                                                            <>{this.state.shippingAddress.shipAddressCountry}<br /></>
                                                            :
                                                            ''
                                                    }
                                                    {
                                                        this.state.ShipWarning ?
                                                            <span class="bdgIconLarge1"><i class="fa fa-exclamation-triangle" aria-hidden="true"></i></span>
                                                            :
                                                            ''
                                                    }

                                                </p>
                                            }
                                        </div>

                                    </li>
                                    <li>
                                        <div className="row">
                                            <div className="col">

                                                <div class="form-group">
                                                    <label for="purchase_order">Purchase Order #</label>
                                                    <input type="text" id="purchase_order" class="form-control" name="purchase_order" placeholder="193329T-1" disabled="" value={(this.state.data.pordeRefNumber) ? this.state.data.pordeRefNumber : this.state.transportPORefNumer} />
                                                </div>
                                            </div>
                                        </div>

                                        <Date
                                            name={fields.date.name}
                                            label={fields.date.label}
                                            fun={this.handleChangeValue}
                                        />




                                        <Input
                                            name={fields.invoiceLinkedTo.name}
                                            label={fields.invoiceLinkedTo.label}
                                            disabled={true}
                                        />

                                        <Input
                                            name={fields.resalePOLinkedTo.name}
                                            label={fields.resalePOLinkedTo.label}
                                            disabled={true}
                                        />

                                        <Select
                                            name={fields.shipedMehtod.name}
                                            label={fields.shipedMehtod.label}
                                            option={shipedMehtod}
                                            disabled={true}
                                            valueinfo={this.state.data?.shipMethodMethodField}
                                            fun={this.handleChangeValue}
                                        />

                                        <Select
                                            name={fields.containerOrientation.name}
                                            label={fields.containerOrientation.label}
                                            option={[{ value: '', label: '\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0' }, ...containerOrientation]}
                                            valueinfo={this.state.data?.containerOrientation}
                                            disabled={true}
                                            fun={this.handleChangeValue}
                                        />

                                        <div>
                                            <Checkbox
                                                name={fields.expected.name}
                                                label={fields.expected.label}
                                                disabled={true}
                                                value={(this.state.data?.isExpedited) ? true : false}
                                            />
                                        </div>
                                        <div>
                                            <Checkbox
                                                name={fields.refounded.name}
                                                label={fields.refounded.label}
                                                disabled={true}
                                                value={(this.state.data?.isRefunded) ? true : false}
                                            />
                                        </div>
                                    </li>
                                    <li>

                                        <Input
                                            name={fields.supplierVendor.name}
                                            label={fields.supplierVendor.label}
                                            disabled={true}
                                        />


                                        <Input
                                            placeholder={'Enter Release Number'}
                                            name={fields.releaseNumber.name}
                                            label={fields.releaseNumber.label}
                                            fun={this.handleChangeValue}
                                        />

                                        <DateTime
                                            name={fields.releaseDate.name}
                                            label={fields.releaseDate.label}
                                        />

                                        <Input
                                            name={fields.supplierLocation.name}
                                            label={fields.supplierLocation.label}
                                            placeholder={'Select Supplier Location'}
                                            option={supplierLocation}
                                            disabled={true}
                                        />

                                        <div className="row">
                                            <div className="col-6">
                                                <PromiseTimeLine
                                                    name={fields.promiseTimeLine.name}
                                                    label={fields.promiseTimeLine.label}
                                                    option={promiseTimeLines}
                                                    fun={this.handleChangeValue}
                                                    defaultValue={this.state.data?.promiseDateTimeline}
                                                />
                                            </div>
                                            <div className="col-6">
                                                <Date
                                                    name={fields.promiseDate.name}
                                                    label={fields.promiseDate.label}
                                                    fun={this.handleChangeValue}
                                                />
                                            </div>
                                        </div>
                                        <Date
                                            name={fields.expectedDate.name}
                                            label={fields.expectedDate.label}
                                            fun={this.handleChangeValue}
                                        />
                                        <div >
                                            <Checkbox
                                                name={fields.depositcallCompleted.name}
                                                label={fields.depositcallCompleted.label}
                                                disabled={true}
                                                value={(this.state.depotChecked) ? true : false}
                                            />
                                            <br />
                                            {
                                                this.state.depotStatus ?
                                                    <small style={{ color: "Red" }}>From is a required field</small>
                                                    :
                                                    ''
                                            }
                                            {/* {
                                                (this.state.depotChecked) ?
                                                    <input disabled checked onClick={this.handleDepot} name="depositcallCompleted" type="checkbox" value={this.state.depotChecked} />
                                                    :
                                                    
                                                    <input disabled onClick={this.handleDepot} name="depositcallCompleted" type="checkbox" value={this.state.depotChecked} />
                                            }
                                            <label className="labelinupt">Depot Call Completed</label> */}
                                        </div>
                                        {
                                            (this.state.depotChecked) ?
                                                <div className="row">
                                                    <div className="col-6">
                                                        <p>{(this.state.data?.lastDepotCallTime) ? moment(this.state.data?.lastDepotCallTime).tz(timezone.name()).format('MM/DD/YYYY HH:mm A') : ''}</p>
                                                    </div>
                                                    <div className="col-6">
                                                        <p>{(this.state.data?.lastDepotCallCompletedBy) ? this.state.data?.lastDepotCallCompletedBy : ''}</p>
                                                    </div>
                                                </div>
                                                :
                                                ''
                                        }

                                        <div className='custom-checkbox-mng'>
                                            <label className='cstmlabel-added'>
                                                <input type="checkbox" id="cstm_checkbox" onChange={this.logDepotCall} name="cstm_checkbox" value="true" checked={this.state.LogisticsDepotCallCompleted ? 'checked' : ''} />
                                                <span>Logistics depot call completed</span>
                                            </label>
                                            <div className='cstmshow_data'>
                                                {(this.state.LogisticsDepotCallCompleted && this.state.depoLogDate) ? (
                                                    <div className='firstshow_previewlbl'>
                                                        <p>{this.state.depoLogDate}</p>
                                                    </div>
                                                ) : (
                                                    ''
                                                )}

                                                {(this.state.LogisticsDepotCallCompleted && this.state.depoLogName) ? (
                                                    <div className='firstshow_previewlbl'>
                                                        <p>{this.state.depoLogName}</p>
                                                    </div>
                                                ) : (
                                                    ''
                                                )}
                                            </div>

                                        </div>
                                    </li>
                                    <li>
                                        <div className="row">
                                            <div className="col">
                                                <div className="form-group">
                                                    <label>{fields.pickupLocation.label}</label>
                                                    <select disabled class="form-control">
                                                        <option>{this.state.data?.resalePOPickupLocationName ? this.state.data?.resalePOPickupLocationName : this.state.data?.pickupLocationName}</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row location-detail-list">
                                            <div class="col-12">
                                                <div class="row">
                                                    <div class="col-6">
                                                        <div class="row">
                                                            <div class="col-12">
                                                                <p><label class="mb-0" style={{ fontWeight: 'bolder' }}>Location Details</label></p>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-12">
                                                                <p>{this.state.data?.resalePOPickupLocationName ? this.state.data?.resalePOPickupLocationName : this.state.data?.pickupLocationName}</p>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-12">
                                                                <p>
                                                                    {this.state.data?.resalePOPickupAddress1 ? this.state.data?.resalePOPickupAddress1 : this.state.data?.pickupAddress1}
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-12 pr-0">
                                                                <p>
                                                                    {(this.state.data.resalePOPickupCity) ? this.state.data?.resalePOPickupCity + ', ' : this.state.data?.pickupCity}
                                                                    {this.state.data.resalePOPickupState ? this.state.data.resalePOPickupState : this.state.data.pickupState}&nbsp;{this.state.data?.resalePOPickupZipCode ? this.state.data?.resalePOPickupZipCode : this.state.data?.pickupZipcode}
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-12">
                                                                <p> {this.state.data?.resalePOPickupPhone?.split(/\n|\s/)[0]}</p>
                                                                <p> {this.state.data?.resalePOPickupPhone?.split(/\n|\s/)[1]}</p>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-12">
                                                                <p> {this.state.data?.pickupEmail}</p>
                                                            </div>
                                                        </div>


                                                        {
                                                            this.state.distanceDiffStatus ? 
                                                                <div class="row">
                                                                    <div class="col-12">
                                                                        <div type="button" class="badgeShow badgeDistance" style={{}}>
                                                                        Distance: {this.state.distanceVariance} <span class="bdgIcon" ><i class="fa fa-exclamation-triangle" aria-hidden="true"></i></span>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                  :
                                                                 ''
                                                      }
                                                        <br />
                                                        {
                                                           this.state.data.TransportOverrideDistanceStatus === 1 ? 
                                                            <div class="row">
                                                                <div class="col-12">
                                                                    <div type="button" class="badgeShow badgeDistance" style={{}}>
                                                                    Distance Override <span class="bdgIcon" ><i class="fa fa-exclamation-triangle" aria-hidden="true"></i></span>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                              :
                                                             ''
                                                        } 

                                                        <br />
                                                        {

                                                            this.state.distanceUpdatedVariance ?
                                                                <div class="row">
                                                                    <div class="col-12">
                                                                        <div type="button" class="badgeShow" style={{ marginLeft: "0px", fontWeight: 900 }}>Distance Recalculated<span class="bdgIcon"><i class="fa fa-exclamation-triangle" aria-hidden="true"></i></span></div>                                                            </div>
                                                                </div>
                                                                :

                                                                ''
                                                        }
                                                         <br />
                                                        {

                                                            this.state.multiContainerVariance ?
                                                                <div class="row">
                                                                    <div class="col-12">
                                                                        <div type="button" class="badgeShow" style={{ fontSize:"12px",marginLeft: "0px", fontWeight: 900, minWidth: '120 px' }}>MC Variance {this.state.muticontanerDistance}<span class="bdgIcon"><i class="fa fa-exclamation-triangle" aria-hidden="true"></i></span></div>                                                            </div>
                                                                </div>
                                                                :

                                                                ''
                                                        }
                                                        <br />

                                                        {
                                                            this.state.milesStatus ?
                                                                <div class="row">
                                                                    <div class="col-12">
                                                                        <div type="button" class="badgeShow" style={{ marginLeft: "0px" }}>{this.state.ShippinfErrorMessage}<span class="bdgIcon"><i class="fa fa-exclamation-triangle" aria-hidden="true"></i></span></div>                                                            </div>
                                                                </div>
                                                                :
                                                                ''
                                                        }


                                                    </div>
                                                    <div class="col-6">
                                                        <div class="row">
                                                            <div class="col-12">
                                                                <p><label class="mb-0" style={{ fontWeight: 'bolder' }}>Budget</label></p>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-12 d-flex justify-content-between align-items-center">
                                                                <p className='pt-2'>Distance - {Math.round(this.state.data.distanceBetweenPickupAndDeliveryAddress)}</p>
                                                               {
                                                                localStorage.getItem('role') == 1
                                                                ?
                                                                <button type='button' className='editCstm' onClick={this.handleOpen}><EditIcon/></button>
                                                                : 
                                                                ''
                                                               }
                                                                
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-12">
                                                                <p>Price/mile - {
                                                                    (this.state.pickupLocation && this.state.pickupLocation.perMilePrice) ? parseFloat(this.state.pickupLocation?.perMilePrice).toFixed(2) : ""
                                                                }</p>
                                                            </div>
                                                        </div>

                                                        <div class="row">
                                                            <div class="col-12">
                                                                <p>Line Haul - {parseFloat(Math.max(this.state.pickupLocation?.minimumRate, (this.state.data.distanceBetweenPickupAndDeliveryAddress * this.state.pickupLocation?.perMilePrice))).toFixed(2)}</p>
                                                            </div>
                                                        </div>


                                                        <div class="row">
                                                            <div class="col-12">
                                                                <p>FSC Rate - {(this.state.FuelRate) ? parseFloat(this.state.FuelRate).toFixed(2) : (this.state.fuelSurcharge[0]) ? parseFloat(this.state.fuelSurcharge[0].fuelSurchargeRate).toFixed(2) : ''}</p>
                                                            </div>
                                                        </div>

                                                        <div class="row">
                                                            <div class="col-12">
                                                                <p>FSC Total - {(this.state.data.distanceBetweenPickupAndDeliveryAddress && this.state.FuelRate) ? parseFloat(Math.round(this.state.data.distanceBetweenPickupAndDeliveryAddress) * this.state.FuelRate).toFixed(2) : (this.state.data.distanceBetweenPickupAndDeliveryAddress && this.state.fuelSurcharge[0]) ? parseFloat(Math.round(this.state.data.distanceBetweenPickupAndDeliveryAddress) * this.state.fuelSurcharge[0].fuelSurchargeRate).toFixed(2) : ''}</p>
                                                            </div>
                                                        </div>

                                                        <div class="row">
                                                            <div class="col-12">
                                                                <p>Total Cost - {(this.state.data.distanceBetweenPickupAndDeliveryAddress && this.state.FuelRate) ? (parseFloat((Math.round(this.state.data.distanceBetweenPickupAndDeliveryAddress) * this.state.FuelRate) + parseFloat(Math.max(this.state.pickupLocation?.minimumRate, (this.state.data.distanceBetweenPickupAndDeliveryAddress * this.state.pickupLocation?.perMilePrice)))).toFixed(2)) : (this.state.data.distanceBetweenPickupAndDeliveryAddress && this.state.fuelSurcharge[0]) ? (parseFloat((Math.round(this.state.data.distanceBetweenPickupAndDeliveryAddress) * this.state.fuelSurcharge[0].fuelSurchargeRate) + parseFloat(Math.max(this.state.pickupLocation?.minimumRate, (this.state.data.distanceBetweenPickupAndDeliveryAddress * this.state.pickupLocation?.perMilePrice)))).toFixed(2)) : ''}</p>
                                                            </div>
                                                        </div>

                                                        <div class="row">
                                                            <div class="col-12">
                                                                <p>Min Rate - {

                                                                    (this.state.pickupLocation && this.state.pickupLocation.minimumRate) ? parseFloat(this.state.pickupLocation?.minimumRate).toFixed(2) : ""
                                                                }</p>
                                                            </div>
                                                        </div>


                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-12">

                                                    </div>
                                                </div>
                                            </div>
                                            <Modal
                                open={this.state.open}
                                onClose={this.handleClose}
                                aria-labelledby="child-modal-title"
                                aria-describedby="child-modal-description"
                                className="cstmModal_Sec"
                            >
                                <Box className="modal_ContentBox" sx={{ ...style, width: 300 }}>
                                    <Box className="ModalHeader_Box">
                                        <h2 id="child-modal-title">Purchase Order : {(this.state.data.pordeRefNumber) ? this.state.data.pordeRefNumber : this.state.transportPORefNumer}</h2>
                                        {/* <p id="child-modal-description">
                                            Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                                        </p> */}
                                    </Box>

                                    <Box className="modalFormBox">
                                        <Box className="customFormControlBox">
                                            <FormControl fullWidth className='customFormControl planDate'>
                                            <Box className="modalFormBox">
                                        <Box className="customFormControlBox">
                                            <FormControl fullWidth className='customFormControl planDate'>
                                                <InputLabel variant="standard">
                                                Override distance
                                                </InputLabel>
                                               
                                                <TextField
                                                    className='customDate'
                                                    id="outlined-basic" 
                                                    type="text" 
                                                    onChange={this.setOverrideDistance}
                                                />
                                    
                                                {/* <NativeSelect
                                                    defaultValue={30}
                                                    inputProps={{
                                                        name: 'age',
                                                        id: 'uncontrolled-native',
                                                    }}
                                                >
                                                    <option value={10}>Ten</option>
                                                    <option value={20}>Twenty</option>
                                                    <option value={30}>Thirty</option>
                                                </NativeSelect> */}
                                            </FormControl>
                                        </Box>
                                        </Box>
                                                
                                                {/* <TextField
                                                    hiddenLabel
                                                    id="filled-hidden-label-small"
                                                    variant="filled"
                                                    size="small"
                                                    onChange={getInvoiceValue}
                                                    /> */}
                                    
                                                {/* <NativeSelect
                                                    defaultValue={30}
                                                    inputProps={{
                                                        name: 'age',
                                                        id: 'uncontrolled-native',
                                                    }}
                                                >
                                                    <option value={10}>Ten</option>
                                                    <option value={20}>Twenty</option>
                                                    <option value={30}>Thirty</option>
                                                </NativeSelect> */}
                                            </FormControl>
                                        </Box>

                                   

                                  
                                    </Box>

                                    <Box className="modalFooter_BTNBox">
                                        <Button className='saveBTN' onClick={this.updateOverrideDistance}>Save</Button>
                                        <Button className='cancelBTN' onClick={this.handleClose}>Close</Button>
                                    </Box>
                                </Box>


                                {/* <Box className="modalHeaderBox"></Box> */}



                                {/* <Box className="modalButtonBox"></Box> */}



                            </Modal>
                                        </div>


                                        <Date
                                            name={fields.actualPickupDate.name}
                                            label={fields.actualPickupDate.label}
                                            fun={this.handleChangeValue}
                                        />

                                        <Date
                                            name={fields.scheduledDeleiveryDate.name}
                                            label={fields.scheduledDeleiveryDate.label}
                                            fun={this.handleChangeValue}
                                        />

                                        <div className="row time">
                                            <div className="col-6">
                                                <Time
                                                    placeholder={'From Time'}
                                                    name={fields.from.name}
                                                    label={fields.from.label}
                                                    fun={this.handleChangeValue}
                                                //disabled={true}
                                                />
                                            </div>
                                            <div className="col-6">
                                                <Time
                                                    placeholder={'To Time'}
                                                    name={fields.to.name}
                                                    label={fields.to.label}
                                                    fun={this.handleChangeValue}
                                                //disabled={true}
                                                />
                                            </div>
                                        </div>
                                        <Input
                                            name={fields.deleiveryStatus.name}
                                            label={fields.deleiveryStatus.label}
                                            fun={this.handleChangeValue}
                                        />
                                        <Date
                                            name={fields.actualDeleiveryDate.name}
                                            label={fields.actualDeleiveryDate.label}
                                            fun={this.handleChangeValue}
                                        />


                                    </li>
                                    <li>
                                        <div className="row">

                                            <div className="col-12">
                                                <div className="form-group">
                                                    <label htmlFor="ServicePurchase">Customer Note</label>
                                                    <div className="customer-note-box">
                                                        {
                                                            this.state.customerNoteCollection?.split("\n").map((i, key) => {
                                                                return <p key={key}>{i?.replace('undefined', '')}</p>
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <input type="text" name="customerNote" onChange={this.handleChange} class="form-control customer-note" placeholder="Add Customer Note" id="customerinput" />
                                                    {
                                                        (this.state.notesLoading) ?
                                                            <button disabled type="button" class="btn btn-primary mt-2">Add</button>
                                                            :
                                                            <button type="button" onClick={this.customerNote} class="btn btn-primary mt-2">Add</button>
                                                    }
                                                </div>
                                            </div>

                                            <div className="col-12">
                                                <div className="form-group">
                                                    <label htmlFor="ServicePurchase">Vendor Note</label>
                                                    <div className="customer-note-box">
                                                        {
                                                            this.state.vendorNoteCollection?.split("\n").map((i, key) => {
                                                                return <p key={key}>{i?.replace('undefined', '')}</p>
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <input type="text" name="vendorNote" onChange={this.handleChange} class="form-control vendor-note" placeholder="Add Vendor Note" id="vendorinput" />
                                                    {
                                                        (this.state.notesLoading) ?
                                                            <button disabled type="button" class="btn btn-primary mt-2">Add</button>
                                                            :
                                                            <button type="button" onClick={this.vendorNote} class="btn btn-primary mt-2">Add</button>
                                                    }
                                                </div>
                                            </div>

                                            <div className="col-12">
                                                <div className="form-group">
                                                    <label htmlFor="ServicePurchase">Dispatch Note</label>
                                                    <div className="customer-note-box">
                                                        {
                                                            this.state.dispatchNoteCollection?.split("\n").map((i, key) => {
                                                                return <p key={key}>{i?.replace('undefined', '')}</p>
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <input type="text" name="dispatchNote" onChange={this.handleChange} class="form-control dispatch-note" placeholder="Add Dispatch Note" id="dispatchinput" />

                                                    {
                                                        (this.state.notesLoading) ?
                                                            <button disabled type="button" class="btn btn-primary mt-2">Add</button>
                                                            :
                                                            <button type="button" onClick={this.dispatchNote} class="btn btn-primary mt-2">Add</button>
                                                    }

                                                </div>
                                            </div>

                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <PurchaseTable syncData={this.syncData} cbGet={this.createTransportPurchaseOrder} cbSubmit={handleSubmit} updateloading={this.updateloading} items={this.state.item} stateinfo={this.state} fun={this.updateWindowStatus} deleteLineItemArray={this.deleteLineItemArray} />
                            <div className="row mt-5 mb-5" onClick={this.updateWindowStatus}>
                                <div className="col-sm-4">

                                    <div className="col-sm-12">
                                        <Textarea
                                            name={fields.deliveryNotes.name}
                                            label={fields.deliveryNotes.label}
                                            fun={this.handleChangeValue}
                                        />
                                    </div>

                                    <div className="col-sm-12">
                                        <Textarea
                                            name={fields.pickupNotes.name}
                                            label={fields.pickupNotes.label}
                                            fun={this.handleChangeValue}
                                        />
                                    </div>

                                    <Dropzone tableName={'purchaseOrders'} recordID={this.state.data.transportPORecordID || this.state.transportPOrecordID} allfiles={this.state.attachment} deleteAttachement={this.deleteAttachementFile} attachment={this.pushAttachementFile} />

                                </div>

                                <div className="col-sm-4">
                                    <Textarea
                                        name={fields.memo.name}
                                        label={fields.memo.label}
                                        fun={this.handleChangeValue}
                                    />
                                    <Textarea
                                        name={fields.messages.name}
                                        label={fields.messages.label}
                                        fun={this.handleChangeValue}
                                    />
                                </div>
                                <div className="col-sm-4">
                                    <div className="taxs-amount">
                                        <p>Taxes</p>
                                        <div className="group-amount">
                                            <h3>Total</h3>
                                            <p>{(this.state.rateChange) ? (this.state.rate) ? parseFloat(this.state.rate)?.toFixed(2) : '' : this.state.data.totalAmount?.toFixed(2)}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row mb-5">
                                <div className="col-sm-12 text-right mb-5 d-flex justify-content-end">
                                   
                                <Dropdown className='pr-2' as={ButtonGroup} >
                                    <Button onClick={() => this.pdfGenerator()} className='btn-primary' disabled = { this.state.pdfFinalizeGeneratorloding||this.state.loadingFinalizeDelivery ||this.state.loading ? 'disabled' : ''}>Print</Button>

                                    <Dropdown.Toggle id="dropdown-split-basic" className='btn-primary' disabled={this.state.loadingFinalizeDelivery ||this.state.loading? 'disabled' : ''} />

                                    <Dropdown.Menu>
                                        <Dropdown.Item onClick={() => this.finalizePdfGenerator()} disabled = { this.state.pdfFinalizeGeneratorloding ||this.state.loadingFinalizeDelivery ||this.state.loading ? 'disabled' : ''}>RTO Print</Dropdown.Item>
                                    </Dropdown.Menu>
                                    </Dropdown>
                                    {/* <Dropdown className="pr-2">
                                        <Dropdown.Toggle className="custmBtnRed" id="dropdown-basic" disabled = { this.state.pdfFinalizeGeneratorloding ? 'disabled' : ''}>
                                        Finalize
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu className="custmDropdownList">
                                            <Dropdown.Item onClick={() => this.finalizePdfGenerator()}>
                                             Print
                                            </Dropdown.Item>
                                            <Dropdown.Item onClick={() => this.handleFinalizeDelivery()}>
                                             Delivery
                                            </Dropdown.Item>
                                            <Dropdown.Item onClick={() => this.finalizeSendEmail()}>
                                             Email
                                            </Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown> */}
                                    <Dropdown className="pr-2" as={ButtonGroup} >
                                    <Button onClick={() => this.emailTrigger()} className='btn-primary' disabled={this.state.loadingFinalizeDelivery ||this.state.loading? 'disabled' : ''}>Email</Button>

                            <Dropdown.Toggle  id="dropdown-split-basic" className='btn-primary' disabled={this.state.loadingFinalizeDelivery ||this.state.loading? 'disabled' : ''} />

                            <Dropdown.Menu>
                                <Dropdown.Item onClick={() => this.finalizeSendEmail()} disabled={this.state.loadingFinalizeDelivery ||this.state.loading? 'disabled' : ''}>RTO Email</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                                    
                                    {/* {
                                        (this.state.finalizeEmailloding) ?
                                        <button disabled type="button" className="btn btn-primary">Finalize Email</button>
                                        :
                                        <button type="button" onClick={() => this.finalizeSendEmail()} className="btn btn-primary">Finalize Email</button>
                                    }

                                   
                                    &nbsp;&nbsp; */}
                                    <button onClick={() => this.cancelDispatched()} type="button" className="btn btn-primary" disabled={this.state.loadingFinalizeDelivery ||this.state.loading? 'disabled' : ''}>Delete</button>
                                    &nbsp;&nbsp;

                                    {
                                        (this.state.syncLoading) ?
                                            <button type="button" disabled style={{ "margin-right": "5px" }} onClick={this.manualSync} class="btn btn-primary" >Manual Sync</button>
                                            :
                                            <button type="button" style={{ "margin-right": "5px" }} onClick={this.manualSync} class="btn btn-primary" disabled={this.state.loadingFinalizeDelivery ||this.state.loading? 'disabled' : ''}>Manual Sync</button>
                                    }
                                    {/* &nbsp;&nbsp;
                                    {
                                        (this.state.sending || this.state.loading) ?
                                            <button disabled type="button" className="btn btn-success button-save">Email</button>
                                            :
                                            <button onClick={() => this.emailTrigger()} type="button" className="btn btn-primary">Email</button>
                                    }
                                    &nbsp;&nbsp;
                                    {
                                        (this.state.pdfGeneratorloding || this.state.loading) ?
                                            <button disabled type="button" className="btn btn-success button-save">Print</button>
                                            :
                                            <button onClick={() => this.pdfGenerator()} type="button" className="btn btn-primary">Print</button>
                                    } */}
                                    {/* &nbsp;&nbsp;
                                    {
                                        (this.state.pdfFinalizeGeneratorloding || this.state.loading) ?
                                            <button disabled type="button" className="btn btn-success button-save">Finalize Print</button>
                                            :
                                            <button onClick={() => this.finalizePdfGenerator()} type="button" className="btn btn-primary">Finalize Print</button>
                                    } */}
                                    &nbsp;&nbsp;
                                    {
                                        (this.state.loadingFinalizeDelivery || this.state.loading) ?
                                            <button disabled type="submit" className="btn btn-success button-save">Finalize Delivery</button>
                                            :
                                            <button onClick={() => this.handleFinalizeDelivery()} type="button" className="btn btn-primary">Finalize Delivery</button>
                                    }

                                    &nbsp;&nbsp;
                                    {
                                        (this.state.loading) ?
                                            <button disabled type="submit" className="btn btn-success button-save">loading....</button>
                                            :
                                            <>
                                                {
                                                    (this.state.tpoStatus || this.state.orderstatus || this.state.polineloading||this.state.loadingFinalizeDelivery) ?
                                                        <button disabled type="button" className="btn btn-primary  button-save">Save</button> :
                                                        <button type="submit" className="btn btn-primary  button-save">Save</button>
                                                }
                                            </>
                                    }


                                </div>
                            </div>
                        </form>
                    )}
                </Formik>

                <style jsx="true">{`
                    
                    .form-control {
                        font-size: 13px;
                        border: 1px solid #c8c2c2;
                        border-radius: 0px;
                    } 
                    .css-yk16xz-control{
                        border-color:1px solid #c8c2c2;
                        border-radius:0px;
                    }  
                    .purchase-order-form label {
                        font-weight: 600;
                        font-size: 13px;
                        color:#000;
                    }   
                    .form-control:disabled {
                        background-color:#ffeaea !important;
                        opacity:1 !important;
                    }
                    .billing-input-icon{
                        font-size:13px;
                        font-weight:600;
                        color:#000;

                    }
                    .location-detail-list p{
                        font-size:13px;
                    }
                    .customer-note-box {
                        background-color: #ffeaea !important;
                        font-size:13px;
                        resize: both;
                    }
                    .address-text{
                    font-size:13px;
                    }
                    .heading-title{
                        font-size:20px;
                        font-weight:bold;
                 }
                 .icon-file-text{
                     font-size:2rem;
                 }
                 .group-amount h3{
                     font-weight:bold;
                 }
                 .taxs-amount p{
                     font-weight:bold;
                 }
                 .purchase-order-form-list ul li {
                    padding: 10px;
                 }
                 .purchase-order-form-list ul li:nth-child(4) {
                    width:22%;
                 }
                 .purchase-order-form-list ul li:nth-child(5) {
                    width:18%;
                 }
                 button#dropdown-split-basic {
                    padding: 0 6px;
                }
                `}</style>

            </>
        );
    }
}

PurchaseForm.contextType = FormContext;
export default withRouter(PurchaseForm);