import React, { Component } from 'react';
import { Field } from 'formik';
import  CustomDateTime from './CustomDateTime';


class DateTime extends Component {
    render() {
        return (
            <Field 
                placeholder={this.props.placeholder} 
                name={this.props.name}
                label={this.props.label}
                component={CustomDateTime}
            />
        );
    }
}

export default DateTime;