import GenericField from './genericField';
import * as yup from 'yup';
import moment from 'moment';

export default class ImagesField extends GenericField {
    constructor(
      name,
      label,
      path,
      {
        required = false,
        min = undefined,
        max = undefined,
        size = undefined,
      } = {},
    ) {
      super(name, label);
  
      this.path = path;
      this.required = required;
      this.min = min;
      this.max = max;
      this.size = size;
    }
  
    forFormInitialValue(value) {
      return value;
    }
  
  }
  
