import React,{ useEffect } from 'react';
import { withRouter, useHistory, Link } from 'react-router-dom';
import { ACCESS_TOKEN_NAME, ACCESS_TOKEN_NAME_USER, API_BASE_URL } from '../../constants/apiConstants';
import axios from 'axios'
import "../../assets/css/demo.css";
import "../../assets/css/sidebar-accordion.css";
import "../../images/icon/themify-icons/themify-icons.css";
import "../../images/js/script.js"
import "../../images/js/pcoded.min.js"
import "../../images/js/demo-12.js"
import "../../images/icon/icofont/css/icofont.css";
import truck from '../../images/truck.png'
import file from '../../images/file.png'
import report from '../../images/report.png'
import driver from '../../images/driver.png'
import Sidebar from "./../Layout/Sidebar"
import Navbar from "./../Layout/Navbar";
import Footer from "./../Layout/Footer";
import logo from '../../images/logo.png';
import profilepic from '../../images/avatar-4.jpg';
// import "./Admin.css";
import $ from "jquery";
import Avatar from 'react-avatar';


function Admin(props) {
    let history = useHistory();
    useEffect(() => {
        if(!window.location.hash) {
            window.location = window.location + '#loaded';
            window.location.reload();
        }

        const token = localStorage.getItem('role');
        if(token  == null || token !== '2'){
            redirectToLogin();
        }else{
            history.push('/admin');
        }

        $(document).ready(function() {
            $(".hass-submenu ul").hide();
            $(".hass-submenu").click(function() {
                $(this).children("ul").toggle();
            });
        });

      }, []);
      
    const redirectToLogin = () => {
        history.push('/');
    }

    function handleLogout() {
        localStorage.removeItem(ACCESS_TOKEN_NAME);
        localStorage.removeItem('role');
        localStorage.removeItem('user');
        localStorage.removeItem(ACCESS_TOKEN_NAME_USER);
        props.history.push("/");
      }

    return(
        <div id="pcoded" className="pcoded">
        <div className="pcoded-overlay-box"></div>
        <div className="pcoded-container navbar-wrapper">

            {/* <Navbar /> */}

           
                    
            <nav className="navbar header-navbar pcoded-header" style={{height:'55px', minHeight:'26px'}}> 
        <div className="navbar-wrapper">
            <div className="navbar-logo" style={{height: '52px'}}>
                <a style={{top:'7px'}} className="mobile-menu navabar_menu" id="mobile-collapse" href="#!">
                    <i class="ti-menu" style={{"color":" #000", "font-size" : "22px;"}}></i>
                </a>
                <a className="mobile-search morphsearch-search" href="#">
                    <i className="ti-search"></i>
                </a>
                <Link to="/dashboard">
                    <img style={{width:'30%'}} className="img-fluid logo-img" src={logo} alt="Theme-Logo" />
                </Link>
                <a className="mobile-options">
                    <i className="ti-more"></i>
                </a>
            </div>
            
            <div className="navbar-container container-fluid">
                <ul className="nav-right">
                    <li className="user-profile header-notification" style={{lineHeight: 3.5}}>
                        <a href="#!">
                            <Avatar name={localStorage.getItem('user')} colors={['#ed3028', '#711999', '#383838']} size="40" round="20px" />
                        </a>
                        <ul className="show-notification profile-notification">
                            <li>
                                <a href="#">
                                    <i className="ti-user"></i> Profile
                                </a>
                            </li>

                            <li>
                              <Link to="/resetpassword">
                                <i className="ti-layout-sidebar-left"></i> Change Password
                              </Link>
                            </li>
                            
                            <li>
                                <a href="" onClick={() => handleLogout()}>
                                    <i className="ti-layout-sidebar-left"></i> Logout
                                </a>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        </div>
    </nav>

            
            <div className="pcoded-main-container">
                <div className="pcoded-wrapper">

                    <Sidebar />
                   
                    <div className="pcoded-content page_content">
                        <div className="pcoded-inner-content">
                            <div className="main-body">
                                <div className="page-wrapper">

                                    <div className="page-body">
                                        <div className="row">
                                            <div className="col-md-6 col-xl-3">
                                                <div className="card widget-card-1">
                                                    <div className="card-block-small">
                                                        <div className="row">
                                                            <div className="col-md-5">
                                                                <img src={truck} className="info-img" />
                                                            </div>

                                                            <div className="col-md-7 col-section">
                                                                <h3 className="count-n">2545</h3>
                                                                <small className="order-c">Open Orders</small>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-xl-3">
                                                <div className="card widget-card-1">
                                                    <div className="card-block-small">
                                                        <div className="row">
                                                            <div className="col-md-5">
                                                                <img src={file} className="info-img" />
                                                            </div>

                                                            <div className="col-md-7 col-section">
                                                                <h3 className="count-n">254</h3>
                                                                <small className="order-c">DISPATCH ORDERS</small>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-xl-3">
                                                <div className="card widget-card-1">
                                                    <div className="card-block-small">
                                                        <div className="row">
                                                            <div className="col-md-5">
                                                                <img src={report} className="info-img" />
                                                            </div>

                                                            <div className="col-md-7 col-section">
                                                                <h3 className="count-n">345</h3>
                                                                <small className="order-c">PENDING ORDERS</small>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-xl-3">
                                                <div className="card widget-card-1">
                                                    <div className="card-block-small">
                                                        <div className="row">
                                                            <div className="col-md-5">
                                                                <img src={driver}
                                                                    className="info-img steering" />
                                                            </div>

                                                            <div className="col-md-7 col-section">
                                                                <h3 className="count-n">1200</h3>
                                                                <small className="order-c">AVAILABLE DRIVERS</small>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>


                                        <div className="col-md-12 tabs-ui p-0">
                                            <nav>
                                              <div className="nav nav-tabs" id="nav-tab" role="tablist">
                                                <a className="nav-item nav-link active" id="nav-home-tab" data-toggle="tab" href="#nav-home" role="tab" aria-controls="nav-home" aria-selected="true">Open Order</a>
                                                <a className="nav-item nav-link" id="nav-profile-tab" data-toggle="tab" href="#nav-profile" role="tab" aria-controls="nav-profile" aria-selected="false">Need Dispatch</a>
                                                <a className="nav-item nav-link" id="nav-contact-tab" data-toggle="tab" href="#nav-contact" role="tab" aria-controls="nav-contact" aria-selected="false">Assign Order</a>
                                            </div>
                                        </nav>
                                        <div className="tab-content" id="nav-tabContent">
                                            <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">

                                            <table id="example" className="table table-striped table-bordered w-100">
                                                <thead>
                                                    <tr>
                                                        <th>Customer</th>
                                                        <th>Paid Date</th>
                                                        <th>#Invoice</th>
                                                        <th>Product</th>
                                                        <th>Notes</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td><img src="assets/images/avatar-4.jpg"
                                                                className="img-radius table-user" alt="User-Profile-Image" />
                                                            Construction</td>
                                                        <td>04/02/21</td>
                                                        <td>INV190237C</td>
                                                        <td>20STWWT</td>
                                                        <td>Organize customers, leads.</td>
                                                        <td><i className="fa fa-eye text-primary iconbaraction"></i> 
                                                        <i className="fa fa-edit text-success iconbaraction"></i>
                                                        <i className="fa fa-trash text-danger iconbaraction"></i>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td><img src="assets/images/avatar-4.jpg"
                                                                className="img-radius table-user" alt="User-Profile-Image" />
                                                            Construction</td>
                                                        <td>04/02/21</td>
                                                        <td>INV190237C</td>
                                                        <td>20STWWT</td>
                                                        <td>Organize customers, leads.</td>
                                                        <td><i className="fa fa-eye text-primary iconbaraction"></i> 
                                                        <i className="fa fa-edit text-success iconbaraction"></i>
                                                        <i className="fa fa-trash text-danger iconbaraction"></i></td>
                                                    </tr>

                                                    <tr>
                                                        <td><img src="assets/images/avatar-4.jpg"
                                                                className="img-radius table-user" alt="User-Profile-Image" />
                                                            Construction</td>
                                                        <td>04/02/21</td>
                                                        <td>INV190237C</td>
                                                        <td>20STWWT</td>
                                                        <td>Organize customers, leads.</td>
                                                        <td><i className="fa fa-eye text-primary iconbaraction"></i> 
                                                        <i className="fa fa-edit text-success iconbaraction"></i>
                                                        <i className="fa fa-trash text-danger iconbaraction"></i></td>
                                                    </tr>

                                                    <tr>
                                                        <td><img src="assets/images/avatar-4.jpg"
                                                                className="img-radius table-user" alt="User-Profile-Image" />
                                                            Construction</td>
                                                        <td>04/02/21</td>
                                                        <td>INV190237C</td>
                                                        <td>20STWWT</td>
                                                        <td>Organize customers, leads.</td>
                                                        <td><i className="fa fa-eye text-primary iconbaraction"></i> 
                                                        <i className="fa fa-edit text-success iconbaraction"></i>
                                                        <i className="fa fa-trash text-danger iconbaraction"></i></td>
                                                    </tr>

                                                    <tr>
                                                        <td><img src="assets/images/avatar-4.jpg"
                                                                className="img-radius table-user" alt="User-Profile-Image" />
                                                            Construction</td>
                                                        <td>04/02/21</td>
                                                        <td>INV190237C</td>
                                                        <td>20STWWT</td>
                                                        <td>Organize customers, leads.</td>
                                                        <td><i className="fa fa-eye text-primary iconbaraction"></i> 
                                                        <i className="fa fa-edit text-success iconbaraction"></i>
                                                        <i className="fa fa-trash text-danger iconbaraction"></i></td>
                                                    </tr>

                                                    <tr>
                                                        <td>
                                                            <img src="assets/images/avatar-4.jpg"
                                                                className="img-radius table-user" alt="User-Profile-Image" />
                                                            Construction</td>
                                                        <td>04/02/21</td>
                                                        <td>INV190237C</td>
                                                        <td>20STWWT</td>
                                                        <td>Organize customers, leads.</td>
                                                        <td><i className="fa fa-eye text-primary iconbaraction"></i> 
                                                        <i className="fa fa-edit text-success iconbaraction"></i>
                                                        <i className="fa fa-trash text-danger iconbaraction"></i></td>
                                                    </tr>
                                                </tbody>

                                            </table>

                                            </div>
                                          <div className="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">2</div>
                                          <div className="tab-pane fade" id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab">3</div>
                                      </div>
                                            
                                        </div>

                                        <Footer />

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    )
}

export default withRouter(Admin);