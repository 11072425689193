import React, { Component } from 'react';
import { Field } from 'formik';
import SameTrailerDiscountInvoice from "./SameTrailerDiscountInvoice";


class SameTrailerDiscount extends Component {
    render() {
        return (
            <Field 
                placeholder={this.props.placeholder} 
                name={this.props.name}
                label={this.props.label}
                component={SameTrailerDiscountInvoice}
                disabled={this.props.disabled}
                value={this.props.value}
                labelClassName={this.props.labelClassName}
                bindFunction={this.props.bindFunction}
            />
        );
    }
}

export default SameTrailerDiscount;