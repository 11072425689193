import React, { useState } from "react";
import axios from "axios";
import "./ForgotPassword.css";
import { API_BASE_URL, ACCESS_TOKEN_NAME } from "../../constants/apiConstants";
// import { withRouter } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import logo from '../../images/logo.png';
import { Link } from "react-router-dom";


function ForgotPassword(props) {
  const [state, setState] = useState({
    email: "",
    successMessage: null,
    errorMessage: {},
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(name, value);
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    validate(name, value);
  };

  const validate = (key, value) => {
    setState((prevState) => ({
      ...prevState,
      errorMessage: {
        ...prevState.errorMessage,
        [key]: "",
      },
    }));
    switch (key) {
      case "email":
        let pattern = new RegExp(
          /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
        );
        if (!pattern.test(value)) {
          setState((prevState) => ({
            ...prevState,
            errorMessage: {
              ...prevState.errorMessage,
              email: "Please Enter valid Email",
            },
          }));
        }
        break;
      default:
      // code block
    }
  };
  //const sendDetailsToServer = (e) => {
  const handleSubmitClick = (e) => {
    e.preventDefault();
    validate("email", state.email);
    console.log(
        state.email.length &&
      state.email.length,
    );
    if (state.email.length) {
      setState((prevState) => ({
        ...prevState,
        errorMessage: {},
      }));
      const payload = {
        email: state.email
      };
      console.log("payload..", payload);
      let result = "test";
      axios
        .post(API_BASE_URL + "/forgotPassword", payload)
        .then(function (response) {
          console.log("forgot password response--->>", response.status);
          result = response;
          if (response.status === 200) {
            setState((prevState) => ({
              ...prevState,
            }));
            setState((prevState) => ({
              ...prevState,
              errorMessage: {},
            }));

            setTimeout(() => {
              toast.success('Password set link sent to your email', {
                  autoClose: 2000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true
              });
          });

            redirectToLogin();
          } 
          else {
              console.log('user not registered');
            setState((prevState) => ({
              ...prevState,
              errorMessage: "user not registered",
            }));

              setTimeout(() => {
                toast.success('User not registered', {
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });
            });
              
          }
        })
        .catch(function (error) {
          console.log(error);
          setState((prevState) => ({
            ...prevState,
            errorMessage: {
              ...prevState.errorMessage,
              commonError: "User not registered",
            },
          }));
         
        });
    } else {
      setState((prevState) => ({
        ...prevState,
        errorMessage: {
          ...prevState.errorMessage,
          commonError: "Please enter valid email",
        },
      }));
    }
  };
  const redirectToLogin = () => {
    props.history.push("/login");
  };
  return (
        // <div className="forgot_main" style={{marginTop:"20px"}}>
        // <form>
        //     {state.errorMessage.commonError && (
        //     <span className="red">{state.errorMessage.commonError}</span>
        //     )}
        //     <div className="form-group text-left">
        //     <label htmlFor="exampleInputEmail1">Email address</label>
        //     <input
        //         type="email"
        //         name="email"
        //         className="form-control"
        //         id="email"
        //         aria-describedby="emailHelp"
        //         placeholder="Enter email"
        //         value={state.email}
        //         onChange={handleChange}
        //     />

        //     <small id="emailHelp" className="form-text text-muted">
        //         We'll never share your email with anyone else.
        //     </small>
        //     {state.errorMessage.email && (
        //         <span className="red">{state.errorMessage.email}</span>
        //     )}
        //     </div>
        //     <button
        //     type="submit"
        //     className="btn btn-primary"
        //     onClick={handleSubmitClick}
        //     >
        //     Continue
        //     </button>
        // </form>
        // <div
        //     className="alert alert-success mt-2"
        //     style={{ display: state.successMessage ? "block" : "none" }}
        //     role="alert"
        // >
        //     {state.successMessage}
        // </div>
        // </div>


        <div id="login" className="login-background">
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-6">
                  <img src={logo} className="page-logo" /> 
                </div>
            </div> 

        <div className="login-section">
            <div className="row">
                <div className="col-md-6  content-l">
                      <h3 className="time">IT'S TIME TO FORGOT PASSWORD</h3>
                      {/* <h6 className="get">Get Login To Explore</h6> */}
                </div>

                <div className="col-md-6">
                    <div className="login-card">
                        <h2>Forgot your password?</h2>
                        <p className="login-inner">We'll help you reset it and get back on track.</p>
                        <div className="row form-space">
                        <div className="col-md-12 mt-3 send-pass">
                        {state.errorMessage.commonError && (
                        <span className="red">{state.errorMessage.commonError}</span>
                        )}
                        <div className="form-group">
                        <input
                            type="email"
                            name="email"
                            className="form-control custom-input"
                            id="email"
                            aria-describedby="emailHelp"
                            placeholder="Enter email"
                            value={state.email}
                            onChange={handleChange}
                        />
                            <i className="fa fa-envelope icon-ps" aria-hidden="true"></i>
                          </div>
                        </div>

                        <div className="col-md-12"><button className="btn btn-danger btn-process" onClick={handleSubmitClick}> Send <i className="fa fa-long-arrow-right btn-icn-right" aria-hidden="true"></i></button></div>
                        <div className="col-md-12">
                          <Link to={"/login"}><center style={{"margin-top":"15px"}}><strong>Back To Login</strong></center></Link>
                        </div>

                </div>
                </div>

                </div>
            </div>
        </div>


        <footer>
            <p className="footer-content">©2021, Container One</p>
            </footer>

        </div>
          
        <ToastContainer />
        </div>

  );
}

export default ForgotPassword;
