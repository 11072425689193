import React, { createContext, useContext, useState} from "react";
const LoaderContext = createContext();
export const LoaderProvider = ({ children }) => {
    const [state, setState] = useState(0); 
    
    const toggleState = () => {
        setState(prevState => prevState+1);
      };

      return (
        <LoaderContext.Provider value={{ state, toggleState }}>
          {children}
        </LoaderContext.Provider>
      );
    };
    
    // Custom hook to consume the context
    export const useLoaderContext = () => useContext(LoaderContext);
  
