const productPurchaseOption = [
    { value: 'Container Transportation', label: 'Container Transportation' },
    { value: 'Containers for Resale', label: 'Containers for Resale' },
    { value: 'Storage Fee', label: 'Storage Fee' },
    { value: 'Painting of Containers', label: 'Painting of Containers' }
];

const vendor = [
    { value: 'vender1', label: 'vender1' },
    { value: 'vender2', label: 'vender2' }
];

const shipTo = [
    { value: 'shipto1', label: 'shipto1' },
    { value: 'shipto2', label: 'shipto2' }
];

const invoiceLinkedTo = [
    { value: 'invoiceLinkedTo1', label: 'invoiceLinkedTo1' },
    { value: 'invoiceLinkedTo2', label: 'invoiceLinkedTo2' }
];

const releasePOLinked = [
    { value: '456646', label: '789456' }
];

const supplierLocation = [
    { value: 'Newark, NJ', label: 'Newark, NJ' }
];

const shipedMehtod = [
    { value: 'Tilt-bed', label: 'Tilt-bed' },
    { value: 'Rolloff', label: 'Rolloff' },
    { value: 'Customer Assist', label: 'Customer Assist' },
    { value: 'Customer Pickup', label: 'Customer Pickup' }
];

const promiseTimeLines = [
    { value: 'Before', label: 'Before' },
    { value: 'On', label: 'On' },
    { value: 'After', label: 'After' }
];

const containerOrientation = [
    { value: 'Doors Forward', label: 'Doors Forward' },
    { value: 'Doors Rearward', label: 'Doors Rearward' }
];



export {
    productPurchaseOption,
    vendor,
    shipTo,
    invoiceLinkedTo,
    releasePOLinked,
    supplierLocation,
    shipedMehtod,
    promiseTimeLines,
    containerOrientation
}