import React, { useState, useEffect } from 'react';
import moment from 'moment';
import axios from 'axios';
import avoidNull from '../../../utils/AvoidNull';
import avoidNull2 from '../../../utils/AvoidNull2';
import removeAddress from '../../../utils/RemoveAddress';
import PlanningServices from '../../DriverPlanning/PlanningServices';
import MaterialTable from "material-table";
import { ACCESS_TOKEN_NAME, ACCESS_TOKEN_NAME_USER, API_BASE_URL } from '../../../constants/apiConstants';
import CustomDatePicker from "../../../utils/customDatePicker";
import FilterComponentBox from "../../../utils/FilterComponentBox";
import ResetFillterButton from "../../../utils/ResetFillterButton";
import { useCookies } from 'react-cookie';
import Cookies from 'universal-cookie';


function DataTableNeedsPlanned(props) {
    const [pageRefresh, setPageRefresh] = useState(false);
    const [cookies, setCookie, removeCookie] = useCookies(['orderstatus']);

    const [orderData, setOrderData] = useState();
    const [loading, setloading] = useState(false);

    const fetchDispatchedOrder = async (driverInfo) => {
        setloading(true);
        const response = await axios.post(API_BASE_URL + `/fetchDispatchedOrder`, { driverInfo }, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN_NAME),
            },
        })
        setloading(false);
        setOrderData(response.data);
    }

    
    

    
    const cookiesi = new Cookies();

    const resetFilter = async () => {
        const test = document.querySelectorAll(".material-icons");
        for(let tests of test)
        {
        if(tests.getAttribute("aria-label") == 'clear')
        {
            tests.click();
        }
        }
        
        const cookies = new Cookies();
        if (Object.keys(cookies.getAll()).length > 0) {
            setPageRefresh(true);
        }
        else {
            setPageRefresh(true);
        }
        await removeCookie("orderBy", { path: "/orderstatus" });
        await removeCookie("direction", { path: "/orderstatus" });
        await removeCookie("invoiceRefNumber", { path: "/orderstatus" });
        await removeCookie("invoicePaidDate", { path: "/orderstatus" });
        await removeCookie("invoiceTerms", { path: "/orderstatus" });
        await removeCookie("invoiceLineItemItemNameWithoutParent", { path: "/orderstatus" });
        await removeCookie("invoiceAssignedToName", { path: "/orderstatus" });
        await removeCookie("resalePOPickupCity", { path: "/orderstatus" });
        await removeCookie("resalePORefNumer", { path: "/orderstatus" });
        await removeCookie("resalePOReleaseNumber", { path: "/orderstatus" });
        await removeCookie("resalePOReleaseDate", { path: "/orderstatus" });
        await removeCookie("resalePOTotalPrice", { path: "/orderstatus" });
        await removeCookie("resalePOExpectedDate", { path: "/orderstatus" });
        await removeCookie("resalePOLastDepotCallDate", { path: "/orderstatus" });
        await removeCookie("resalePOLineItemDistance", { path: "/orderstatus" });
        await removeCookie("invoiceShipCity", { path: "/orderstatus" });
        await removeCookie("transportPOLineItemContainerNumber", { path: "/orderstatus" });
        await removeCookie("transportPORefNumer", { path: "/orderstatus" });
        await removeCookie("transportPOVendor", { path: "/orderstatus" });
        await removeCookie("transportPOTotalPrice", { path: "/orderstatus" });
        await removeCookie("transportPOScheduledDeliveryDate", { path: "/orderstatus" });
        await removeCookie("transportPODeliveryStatus", { path: "/orderstatus" });
        await removeCookie("invoicePromisedTimeline", { path: "/orderstatus" });
        await removeCookie("invoicePromisedDate", { path: "/orderstatus" });
        await removeCookie("invoiceTxnDate", { path: "/orderstatus" });
        await removeCookie("invoiceCustomerNotes", { path: "/orderstatus" });
        await removeCookie("invoiceBalanceRemaining", { path: "/orderstatus" });
        await removeCookie("invoiceSupplierLocation", { path: "/orderstatus" });
        await removeCookie("invoiceVendorNotes", { path: "/orderstatus" });
        await removeCookie("resalePOSupplierLocation", { path: "/orderstatus" });
        await removeCookie("invoiceDispatchNotes", { path: "/orderstatus" });
        await removeCookie("transportPODeliveredDate", { path: "/orderstatus" });
        await removeCookie("isCompleted", { path: "/orderstatus" });
        await removeCookie("resalePOVendor", { path: "/orderstatus" });
        await removeCookie("resalePOLineItemContainerNumber", { path: "/orderstatus" });
        await removeCookie("invoiceIsExpedited", { path: "/orderstatus" });
        await removeCookie("plannedStatus", { path: "/orderstatus" });
        await removeCookie("newDistance", { path: "/orderstatus" });
        await removeCookie("invoiceShipMethodMethodFieldName", { path: "/orderstatus" });
    }


    const createPlan = async (product, invoiceRefNumber, resalePORefNumer, resaleVendor, transportPORecordID) => {
        const response = await PlanningServices.getPlanningInfo(invoiceRefNumber);
            if (response[0] && response[0].id) {
                window.open("/exitingPurchaseorder/" + result[0].id + "/" + transportPORecordID);
          
            }
            
        const payload = {
            contactId: 0,
            locationId: 123,
            customerConfirmation: 0,
            driveConfirmation: 0,
            size: 0,
            invoiceId: invoiceRefNumber,
            City: '',
            drivername: '',
            product: product,
            State: '',
            transportPORecordID,
            DropState: '',
            DropCity: '',
            Dist: '',
            resalePORefNumer,
            isExpedited: 0,
            vendorFullName: resaleVendor,
            date: ''
        };
        const result = await PlanningServices.savePlanning(payload);
        if (result) {
            //fetchDispatchedOrder({});
            window.open("/exitingPurchaseorder/" + result[0].lastinsterid + "/" + transportPORecordID);
        }
    }

    return (
        <div className="col-md-12">
            <MaterialTable
                title=""
                key={pageRefresh}
                columns={[
                    { title: "RecordID", field: "RecordID", hidden: true },
                    {
                        title: "Customer", field: "invoiceRefNumber",
                        defaultSort: cookiesi.getAll()['orderBy'] == 'invoiceCustomerFullName' ? cookiesi.getAll()['direction'] : false,
                        filterComponent: ({ columnDef, onFilterChanged }) => (
                            <FilterComponentBox pageName={'orderstatus'} onFilterChanged={onFilterChanged} columnDef={columnDef} columnName={columnDef.field} />
                        ),
                        render: rowData => <a className="breakwordwichpadding" target="_blank" href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID}><strong className="title-strong">{rowData.invoiceCustomerFullName?.split('*')[0]}</strong><br />{rowData.invoiceCustomerFullName?.split('*')[1]}</a>
                    },
                    // {
                    //     title: "Invoice Date",
                    //     defaultSort: cookiesi.getAll()['orderBy'] == 'invoiceTxnDate' ? cookiesi.getAll()['direction'] : false,
                    //     filterComponent: (props) => <CustomDatePicker pageName={'orderstatus'} {...props} />,
                    //     field: "invoiceTxnDate",
                    //     filterComponent: (props) => <CustomDatePicker pageName={'orderstatus'} {...props} />,
                    //     type: 'date', render: rowData => <a target="_blank" href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID}>{(rowData.invoiceTxnDate) ? moment(rowData.invoiceTxnDate).format('MM/DD/YY') : ''}</a>
                    // },
                    {
                        title: "Paid Date",
                        defaultSort: cookiesi.getAll()['orderBy'] == 'invoicePaidDate' ? cookiesi.getAll()['direction'] : false,
                        filterComponent: (props) => <CustomDatePicker pageName={'orderstatus'} {...props} />,
                        field: "invoicePaidDate",
                        filterComponent: (props) => <CustomDatePicker pageName={'orderstatus'} {...props} />,
                        type: 'date', render: rowData => <a target="_blank" href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID}>{(rowData.invoicePaidDate) ? moment(rowData.invoicePaidDate).format('MM/DD/YY') : ''}</a>
                    },
                    {
                        title: "Terms", field: "invoiceTerms",
                        defaultSort: cookiesi.getAll()['orderBy'] == 'invoiceTerms' ? cookiesi.getAll()['direction'] : false,
                        filterComponent: ({ columnDef, onFilterChanged }) => (
                            <FilterComponentBox pageName={'needsplanned'} onFilterChanged={onFilterChanged} columnDef={columnDef} columnName={columnDef.field} />
                        ),
                        render: rowData => <a className="breakwordwichpadding" target="_blank" href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID} style={{ color: rowData.invoiceTerms == "Rent To Own" ? 'Red' : '#303548' }}>{rowData.invoiceTerms}</a>
                    },
                    {
                        title: "Product", field: "invoiceLineItemItemNameWithoutParent",
                        defaultSort: cookiesi.getAll()['orderBy'] == 'invoiceLineItemItemNameWithoutParent' ? cookiesi.getAll()['direction'] : false,
                        filterComponent: ({ columnDef, onFilterChanged }) => (
                            <FilterComponentBox pageName={'orderstatus'} onFilterChanged={onFilterChanged} columnDef={columnDef} columnName={columnDef.field} />
                        ),
                        render: rowData => <a target="_blank" href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID}>{rowData.invoiceLineItemItemNameWithoutParent}</a>
                    },
                    {
                        title: "Assign To", field: "invoiceAssignedToName",
                        defaultSort: cookiesi.getAll()['orderBy'] == 'invoiceAssignedToName' ? cookiesi.getAll()['direction'] : false,
                        filterComponent: ({ columnDef, onFilterChanged }) => (
                            <FilterComponentBox pageName={'orderstatus'} onFilterChanged={onFilterChanged} columnDef={columnDef} columnName={columnDef.field} />
                        ),
                        render: rowData => <a className="breakword" target="_blank" href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID}>{rowData.invoiceAssignedToName}</a>
                    },

                    {
                        title: "Expedited", field: "invoiceIsExpedited",
                        defaultSort: cookiesi.getAll()['orderBy'] == 'invoiceIsExpedited' ? cookiesi.getAll()['direction'] : false,
                        filterComponent: ({ columnDef, onFilterChanged }) => (
                            <FilterComponentBox pageName={'needsplanned'} onFilterChanged={onFilterChanged} columnDef={columnDef} columnName={columnDef.field} />
                        ),
                        render: rowData => <a className="breakword" target="_blank" href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID}><center>{rowData.invoiceIsExpedited}</center></a>
                    },

                    {
                        title: "Container #", field: "resalePOLineItemContainerNumber",
                        defaultSort: cookiesi.getAll()['orderBy'] == 'resalePOLineItemContainerNumber' ? cookiesi.getAll()['direction'] : false,
                        filterComponent: ({ columnDef, onFilterChanged }) => (
                            <FilterComponentBox pageName={'needsplanned'} onFilterChanged={onFilterChanged} columnDef={columnDef} columnName={columnDef.field} />
                        ),
                        render: rowData => <a target="_blank" href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID}>{rowData.resalePOLineItemContainerNumber}</a>
                    },

                    // {
                    //     title: "Customer Notes", field: "invoiceCustomerNotes",
                    //     defaultSort: cookiesi.getAll()['orderBy'] == 'invoiceCustomerNotes' ? cookiesi.getAll()['direction'] : false,
                    //     filterComponent: ({ columnDef, onFilterChanged }) => (
                    //         <FilterComponentBox pageName={'orderstatus'} onFilterChanged={onFilterChanged} columnDef={columnDef} columnName={columnDef.field} />
                    //     ),
                    //     render: rowData => <a target="_blank" href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID}>{rowData.invoiceCustomerNotes?.split(' ').map((item, index) => (index <= 20) ? item + ' ' : '')}</a>
                    // },
                    // {
                    //     title: "Balance", field: "invoiceBalanceRemaining",
                    //     defaultSort: cookiesi.getAll()['orderBy'] == 'invoiceBalanceRemaining' ? cookiesi.getAll()['direction'] : false,
                    //     filterComponent: ({ columnDef, onFilterChanged }) => (
                    //         <FilterComponentBox pageName={'orderstatus'} onFilterChanged={onFilterChanged} columnDef={columnDef} columnName={columnDef.field} />
                    //     ),
                    //     render: rowData => <a target="_blank" href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID}>{rowData.invoiceBalanceRemaining}</a>
                    // },
                    {
                        title: "Supp Loc", field: "invoiceSupplierLocation",
                        defaultSort: cookiesi.getAll()['orderBy'] == 'invoiceSupplierLocation' ? cookiesi.getAll()['direction'] : false,
                        filterComponent: ({ columnDef, onFilterChanged }) => (
                            <FilterComponentBox pageName={'orderstatus'} onFilterChanged={onFilterChanged} columnDef={columnDef} columnName={columnDef.field} />
                        ),
                        render: rowData => <a className="breakword" target="_blank" href={'https://www.google.com/maps/place/' + rowData.invoiceSupplierLocation}>{rowData.invoiceSupplierLocation}</a>
                    },


                    {
                        title: "Res PO#", field: "resalePORefNumer",
                        defaultSort: cookiesi.getAll()['orderBy'] == 'resalePORefNumer' ? cookiesi.getAll()['direction'] : false,
                        filterComponent: ({ columnDef, onFilterChanged }) => (
                            <FilterComponentBox pageName={'orderstatus'} onFilterChanged={onFilterChanged} columnDef={columnDef} columnName={columnDef.field} />
                        ),
                        render: rowData => <a target="_blank" href={'/resalePurchaseOrder/' + rowData.ResalePORecordID}>{rowData.resalePORefNumer}</a>
                    },


                    {
                        title: "Res Ven", field: "resalePOVendor",
                        defaultSort: cookiesi.getAll()['orderBy'] == 'resalePOVendor' ? cookiesi.getAll()['direction'] : false,
                        filterComponent: ({ columnDef, onFilterChanged }) => (
                            <FilterComponentBox pageName={'needsplanned'} onFilterChanged={onFilterChanged} columnDef={columnDef} columnName={columnDef.field} />
                        ),
                        render: rowData => <a className="breakword" target="_blank" href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID}>{rowData.resalePOVendor}</a>
                    },

                    {
                        title: "Rele #", field: "resalePOReleaseNumber",
                        defaultSort: cookiesi.getAll()['orderBy'] == 'resalePOReleaseNumber' ? cookiesi.getAll()['direction'] : false,
                        filterComponent: ({ columnDef, onFilterChanged }) => (
                            <FilterComponentBox pageName={'orderstatus'} onFilterChanged={onFilterChanged} columnDef={columnDef} columnName={columnDef.field} />
                        ),
                        render: rowData => <a target="_blank" href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID}>{rowData.resalePOReleaseNumber}</a>
                    },
                    {
                        title: "Rele Date",
                        defaultSort: cookiesi.getAll()['orderBy'] == 'resalePOReleaseDate' ? cookiesi.getAll()['direction'] : false,
                        filterComponent: (props) => <CustomDatePicker pageName={'orderstatus'} {...props} />,
                        type: 'date', field: "resalePOReleaseDate", render: rowData => <a target="_blank" href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID}>{(rowData.resalePOReleaseDate) ? moment(rowData.resalePOReleaseDate).format('MM/DD/YY') : ''}</a>
                    },

                    {
                        title: "Res Total", field: "resalePOTotalPrice",
                        defaultSort: cookiesi.getAll()['orderBy'] == 'resalePOTotalPrice' ? cookiesi.getAll()['direction'] : false,
                        filterComponent: ({ columnDef, onFilterChanged }) => (
                            <FilterComponentBox pageName={'needsplanned'} onFilterChanged={onFilterChanged} columnDef={columnDef} columnName={columnDef.field} />
                        ),
                        render: rowData => <a target="_blank" href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID}>{rowData.resalePOTotalPrice}</a>
                    },

                    {
                        title: "Exp Date", field: "resalePOExpectedDate", type: 'date',
                        defaultSort: cookiesi.getAll()['orderBy'] == 'resalePOExpectedDate' ? cookiesi.getAll()['direction'] : false,
                        filterComponent: (props) => <CustomDatePicker pageName={'needsplanned'} {...props} />,
                        customFilterAndSearch: (term, rowData) => (rowData.resalePOExpectedDate == moment(term).format('YYYY-MM-DD')) ? true : false,
                        render: rowData => <a target="_blank" href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID}>{(rowData.resalePOExpectedDate) ? moment(rowData.resalePOExpectedDate).format('MM/DD/YY') : ''}</a>
                    },

                    // {
                    //     title: "Vendor Notes", field: "invoiceVendorNotes",
                    //     defaultSort: cookiesi.getAll()['orderBy'] == 'invoiceVendorNotes' ? cookiesi.getAll()['direction'] : false,
                    //     filterComponent: ({ columnDef, onFilterChanged }) => (
                    //         <FilterComponentBox pageName={'orderstatus'} onFilterChanged={onFilterChanged} columnDef={columnDef} columnName={columnDef.field} />
                    //     ),
                    //     render: rowData => <a target="_blank" href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID}>{rowData.invoiceVendorNotes?.split(' ').map((item, index) => (index <= 20) ? item + ' ' : '')}</a>
                    // },

                    // {
                    //     title: "Resale Supp Inv", field: "resalePOSupplierLocation",
                    //     defaultSort: cookiesi.getAll()['orderBy'] == 'resalePOSupplierLocation' ? cookiesi.getAll()['direction'] : false,
                    //     filterComponent: ({ columnDef, onFilterChanged }) => (
                    //         <FilterComponentBox pageName={'orderstatus'} onFilterChanged={onFilterChanged} columnDef={columnDef} columnName={columnDef.field} />
                    //     ),
                    //     render: rowData => <a target="_blank" href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID}>{rowData.resalePOSupplierLocation}</a>
                    // },
                    // {
                    //     title: "Ship City", field: "invoiceShipCity",
                    //     defaultSort: cookiesi.getAll()['orderBy'] == 'invoiceShipCity' ? cookiesi.getAll()['direction'] : false,
                    //     filterComponent: ({ columnDef, onFilterChanged }) => (
                    //         <FilterComponentBox pageName={'orderstatus'} onFilterChanged={onFilterChanged} columnDef={columnDef} columnName={columnDef.field} />
                    //     ),
                    //     render: rowData => <a target="_blank" href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID}>{rowData.invoiceShipCity}</a>
                    // },
                   

                    {
                        title: "Last Depot Call", field: "resalePOLastDepotCallDate",
                        defaultSort: cookiesi.getAll()['orderBy'] == 'resalePOLastDepotCallDate' ? cookiesi.getAll()['direction'] : false,
                        customFilterAndSearch: (term, rowData) => (rowData.resalePOLastDepotCallDate == moment(term).format('YYYY-MM-DD')) ? true : false,
                        filterComponent: (props) => <CustomDatePicker pageName={'needsplanned'} {...props} />,

                        type: 'date', render: rowData => <a target="_blank" href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID}>{(rowData.resalePOLastDepotCallDate) ? moment(rowData.resalePOLastDepotCallDate).format('MM/DD/YY') : ''}</a>
                    },

                    {
                        title: "Planned", field: "plannedStatus",
                        defaultSort: cookiesi.getAll()['orderBy'] == 'plannedStatus' ? cookiesi.getAll()['direction'] : false,
                        filterComponent: ({ columnDef, onFilterChanged }) => (
                            <FilterComponentBox pageName={'needsplanned'} onFilterChanged={onFilterChanged} columnDef={columnDef} columnName={columnDef.field} />
                        ),
                        render: rowData => <a target="_blank" href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID}><center>{rowData.plannedStatus}</center></a>
                    },

                    {
                        title: "Pickup Loc", field: "resalePOPickupCity",
                        defaultSort: cookiesi.getAll()['orderBy'] == 'resalePOPickupCity' ? cookiesi.getAll()['direction'] : false,
                        filterComponent: ({ columnDef, onFilterChanged }) => (
                            <FilterComponentBox pageName={'needsplanned'} onFilterChanged={onFilterChanged} columnDef={columnDef} columnName={columnDef.field} />
                        ),
                        render: rowData => <a className="breakword" target="_blank" href={'https://www.google.com/maps/place/' + rowData.pickupLocation}>{rowData.resalePOPickupCity}<br />{rowData.pickupState}</a>
                    },

                    {
                        title: "Del City/State", field: "invoiceShipCity",
                        defaultSort: cookiesi.getAll()['orderBy'] == 'invoiceShipCity' ? cookiesi.getAll()['direction'] : false,
                        filterComponent: ({ columnDef, onFilterChanged }) => (
                            <FilterComponentBox pageName={'orderstatus'} onFilterChanged={onFilterChanged} columnDef={columnDef} columnName={columnDef.field} />
                        ),
                        render: (rowData) => {
                            return <a className="breakword" target="_blank" href={`https://www.google.com/maps/dir/?api=1&origin=${avoidNull(rowData.resalePOPickupAddress1)}${avoidNull(rowData.resalePOPickupCity)}${avoidNull2(rowData.resalePOPickupZipCode)}&destination=${removeAddress(rowData.invoiceShipAddress1,rowData.shipState)}${removeAddress(rowData.invoiceShipAddress2, rowData.shipState)}${removeAddress(rowData.invoiceShipAddress3, rowData.shipState)}${avoidNull(rowData.invoiceShipCity)}${avoidNull(rowData.shipState)}${avoidNull2(rowData.invoiceShipZip)}`}>{rowData.invoiceShipCity}<br />{rowData.shipState}</a>
                        }
                    },

                    {
                        title: "Miles", field: "newDistance",
                        defaultSort: cookiesi.getAll()['orderBy'] == 'newDistance' ? cookiesi.getAll()['direction'] : false,
                        filterComponent: ({ columnDef, onFilterChanged }) => (
                            <FilterComponentBox pageName={'needsplanned'} onFilterChanged={onFilterChanged} columnDef={columnDef} columnName={columnDef.field} />
                        ),
                        render: rowData => <a target="_blank" href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID}>{ rowData.newDistance ? Math.round(rowData.newDistance) : ''}</a>
                    },

                    {
                        title: "Ship Method", field: "invoiceShipMethodMethodFieldName",
                        defaultSort: cookiesi.getAll()['orderBy'] == 'invoiceShipMethodMethodFieldName' ? cookiesi.getAll()['direction'] : false,
                        filterComponent: ({ columnDef, onFilterChanged }) => (
                            <FilterComponentBox pageName={'needsplanned'} onFilterChanged={onFilterChanged} columnDef={columnDef} columnName={columnDef.field} />
                        ),
                        render: rowData => <a target="_blank" className="breakwordwichpadding" href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID}>{rowData.invoiceShipMethodMethodFieldName}</a>
                    },


                    // {
                    //     title: "Tran PO #", field: "transportPORefNumer",
                    //     defaultSort: cookiesi.getAll()['orderBy'] == 'transportPORefNumer' ? cookiesi.getAll()['direction'] : false,
                    //     filterComponent: ({ columnDef, onFilterChanged }) => (
                    //         <FilterComponentBox pageName={'orderstatus'} onFilterChanged={onFilterChanged} columnDef={columnDef} columnName={columnDef.field} />
                    //     ),
                    //     render: rowData => (rowData.planningId && rowData.TransportPORecordID) ? <a target="_blank" href={'/exitingPurchaseorder/' + rowData.planningId + '/' + rowData.TransportPORecordID}>{rowData.transportPORefNumer}</a> : (rowData.TransportPORecordID) ? <a href="javascript:void(0)" onClick={() => createPlan(rowData.invoiceLineItemItemNameWithoutParent, rowData.orderStatusRecordID, rowData.resalePORefNumer, rowData.transportPOVendor, rowData.TransportPORecordID)} >{rowData.transportPORefNumer}</a> : ''
                    // },
                    // {
                    //     title: "Dispatch Notes", field: "invoiceDispatchNotes",
                    //     defaultSort: cookiesi.getAll()['orderBy'] == 'invoiceDispatchNotes' ? cookiesi.getAll()['direction'] : false,
                    //     filterComponent: ({ columnDef, onFilterChanged }) => (
                    //         <FilterComponentBox pageName={'orderstatus'} onFilterChanged={onFilterChanged} columnDef={columnDef} columnName={columnDef.field} />
                    //     ),
                    //     render: rowData => <a target="_blank" href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID}>{rowData.invoiceDispatchNotes?.split(' ').map((item, index) => (index <= 20) ? item + ' ' : '')}</a>
                    // },



                    // {
                    //     title: "Sched For",
                    //     field: "transportPOScheduledDeliveryDate",
                    //     defaultSort:
                    //         cookiesi.getAll()["orderBy"] == "transportPOScheduledDeliveryDate"
                    //             ? cookiesi.getAll()["direction"]
                    //             : false,
                    //     filterComponent: (props) => (
                    //         <CustomDatePicker pageName={"orderstatus"} {...props} />
                    //     ),
                    //     type: "date",
                    //     render: (rowData) => (
                    //         <a target="_blank" href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID}>
                    //             {rowData.transportPOScheduledDeliveryDate
                    //                 ? moment(rowData.transportPOScheduledDeliveryDate).format("MM/DD/YY")
                    //                 : ""}
                    //         </a>
                    //     ),
                    // },

                    // {
                    //     title: "Delivered On",
                    //     field: "transportPODeliveredDate",
                    //     defaultSort:
                    //         cookiesi.getAll()["orderBy"] == "transportPODeliveredDate"
                    //             ? cookiesi.getAll()["direction"]
                    //             : false,
                    //     filterComponent: (props) => (
                    //         <CustomDatePicker pageName={"orderstatus"} {...props} />
                    //     ),
                    //     type: "date",
                    //     render: (rowData) => (
                    //         <a target="_blank" href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID}>
                    //             {rowData.transportPODeliveredDate
                    //                 ? moment(rowData.transportPODeliveredDate).format("MM/DD/YY")
                    //                 : ""}
                    //         </a>
                    //     ),
                    // },


                    // {
                    //     title: "Delivery Status", field: "transportPODeliveryStatus",
                    //     defaultSort: cookiesi.getAll()['orderBy'] == 'transportPODeliveryStatus' ? cookiesi.getAll()['direction'] : false,
                    //     filterComponent: ({ columnDef, onFilterChanged }) => (
                    //         <FilterComponentBox pageName={'orderstatus'} onFilterChanged={onFilterChanged} columnDef={columnDef} columnName={columnDef.field} />
                    //     ),
                    //     render: rowData => <a target="_blank" href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID}>{rowData.transportPODeliveryStatus}</a>
                    // },
                    // {
                    //     title: "Completed", field: "isCompleted",
                    //     defaultSort: cookiesi.getAll()['orderBy'] == 'isCompleted' ? cookiesi.getAll()['direction'] : false,
                    //     filterComponent: ({ columnDef, onFilterChanged }) => (
                    //         <FilterComponentBox pageName={'orderstatus'} onFilterChanged={onFilterChanged} columnDef={columnDef} columnName={columnDef.field} />
                    //     ),
                    //     render: rowData => <a target="_blank" href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID}>{rowData.isCompleted}</a>
                    // },
                    {
                        title: "Prom Timeline", field: "invoicePromisedTimeline",
                        defaultSort: cookiesi.getAll()['orderBy'] == 'invoicePromisedTimeline' ? cookiesi.getAll()['direction'] : false,
                        filterComponent: ({ columnDef, onFilterChanged }) => (
                            <FilterComponentBox pageName={'orderstatus'} onFilterChanged={onFilterChanged} columnDef={columnDef} columnName={columnDef.field} />
                        ),
                        render: rowData => <a target="_blank" href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID}>{rowData.invoicePromisedTimeline}</a>
                    },
                    {
                        title: "Prom Date",
                        defaultSort: cookiesi.getAll()['orderBy'] == 'invoicePromisedDate' ? cookiesi.getAll()['direction'] : false,
                        filterComponent: (props) => <CustomDatePicker pageName={'orderstatus'} {...props} />,
                        field: "invoicePromisedDate", type: 'date', render: rowData => <a target="_blank" href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID}>{(rowData.invoicePromisedDate) ? moment(rowData.invoicePromisedDate).format('MM/DD/YY') : ''}</a>
                    }

                ]}
                
                data={query =>
                    
                    new Promise((resolve, reject) => {
                        
                        const cookies = new Cookies();
                        cookies.remove('adminer_version');

                        console.log(Object.keys(cookies.getAll()).length, 'fetchOrderBasedOnColumn');

                        if (query.filters.length > 0 || Object.keys(cookies.getAll()).length > 0) {
                            let url = API_BASE_URL + `/fetchOrderBasedOnColumn?`;
                            url += 'size=' + query.pageSize;
                            url += '&page=' + (query.page + 1);
                            url += '&orderNumber=' + props.filterData;
                            if (query.orderBy) {
                                removeCookie("orderBy", { path: "/orderstatus" });
                                removeCookie("direction", { path: "/orderstatus" });
                                if (query.orderBy.field == 'invoiceRefNumber') {
                                    setCookie("orderBy", "invoiceCustomerFullName", { path: '/orderstatus' });
                                }
                                else {
                                    setCookie("orderBy", query.orderBy.field, { path: '/orderstatus' });

                                }

                                setCookie("direction", query.orderDirection, { path: '/orderstatus' });
                            }
                            else {
                               // url += '&sortField=invoiceTxnDate'+props.sortBy;
							   url += '&sortField=invoiceTxnDate';
                                url += '&sortType=asc';
                                url += '&sortField=invoiceCustomerFullName';
                                url += '&sortType=asc';
                                url += '&sortField=resalePORecordID';
                                url += '&sortType=asc';
                            }

                            if (Object.keys(cookies.getAll()).length > 0) {
                                for (const key in cookies.cookies) {
                                    console.log(moment.isDate(cookies.cookies[key].replace('"', '').replace('"', '')), 'momentinfo');

                                    if (cookies.cookies[key] && moment.isDate(cookies.cookies[key])) {
                                        cookies.cookies[key] = moment(cookies.cookies[key]).format('YYYY-MM-DD')
                                    }

                                    if (cookies.cookies[key]) {
                                        if (key == 'invoiceRefNumber' && isNaN(cookies.cookies[key])) {
                                            url += '&columnName=' + 'invoiceCustomerFullName';
                                            url += '&search=' + cookies.cookies[key];
                                        }
                                        else if (key == 'orderBy') {

                                            url += '&sortField=' + cookies.cookies[key]
                                        }
                                        else if (key == 'direction') {
                                            url += '&sortType=' + cookies.cookies[key]
                                        }
                                        else {
                                            url += '&columnName=' + key;
                                            url += '&search=' + cookies.cookies[key];
                                        }
                                    }
                                }
                            } else {
                                query.filters.forEach((item) => {
                                    if (item.value && moment.isDate(item.value)) {
                                        item.value = moment(item.value).format('YYYY-MM-DD')
                                    }

                                    if (item.column.field == 'invoiceRefNumber' && isNaN(parseInt(item.value))) {
                                        url += '&columnName=' + 'invoiceCustomerFullName';
                                        url += '&search=' + item.value;
                                    } else {
                                        url += '&columnName=' + item.column.field;
                                        url += '&search=' + item.value;
                                    }
                                });
                            }


                            axios.get(url, {
                                headers: {
                                    Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN_NAME),
                                },
                            }).then(result => {
                                resolve({
                                    data: result.data.rows.map((data) => ({
                                        orderStatusRecordID: data.recordID,
                                        RecordID: data.invoiceRecordID,
                                        invoiceCustomerFullName: `${data?.invoiceCustomerFullName?.replace(',', ' ')}*${data?.invoiceRefNumber}`,
                                        invoiceTerms: data?.invoiceTerms,
                                        invoiceIsExpedited: (data.invoiceIsExpedited) ? 'Yes' : 'No',
                                        transportPOLineItemContainerNumber: data?.transportPOLineItemContainerNumber,
                                        invoiceRefNumber: data?.invoiceRefNumber,
                                        transportPOVendor: data.transportPOVendor,
                                        Product: data.invoiceLineItemItemNameWithoutParent,
                                        TransportPORecordID: data.transportPORecordID,
                                        TransportPO: (data.transportPORecordID) ? data.transportPORefNumer : data.invoiceRefNumber + 'T',
                                        resalePOSupplierLocation: data?.resalePOSupplierLocation,
                                        transportPOScheduledDeliveryDate: (data.transportPOScheduledDeliveryDate) ? data.transportPOScheduledDeliveryDate?.split('T')[0] : '',
                                        invoicePaidDate: (data.invoicePaidDate) ? data.invoicePaidDate?.split('T')[0] : '',
                                        transportPODeliveredDate: (data.transportPODeliveredDate) ? data.transportPODeliveredDate?.split('T')[0] : '',
                                        invoiceTxnDate: (data.invoiceTxnDate) ? data.invoiceTxnDate?.split('T')[0] : '',
                                        invoiceLineItemItemNameWithoutParent: data?.invoiceLineItemItemNameWithoutParent,
                                        invoiceAssignedToName: data?.invoiceAssignedToName,
                                        solidSurface: data.invoice.solidSurface,
                                        invoiceSupplierLocation: data?.invoiceSupplierLocation,
                                        resalePORefNumer: data?.resalePORefNumer,
                                        invoiceCustomerNotes: data?.invoiceCustomerNotes,
                                        resalePOVendor: data?.resalePOVendor,
                                        isCompleted: (data.isCompleted) ? 'True' : 'False',
                                        newDistance: data.newDistance ? data.newDistance : null,

                                        planningId: (data.planning) ? data.planning.id : null,

                                        invoiceBalanceRemaining: data?.invoiceBalanceRemaining,
                                        invoiceDispatchNotes: data?.invoiceDispatchNotes,
                                        resalePOReleaseNumber: data?.resalePOReleaseNumber,
                                        invoiceVendorNotes: data?.invoiceVendorNotes,
                                        ResalePO: data.resalePO,
                                        ResalePORecordID: data?.resalePORecordID,
                                        resalePODistance: data?.resalePODistance,
                                        invoiceShipMethodMethodFieldName: data?.invoiceShipMethodMethodFieldName,
                                        invoiceShipAddress1: data?.invoiceShipAddress1,
                                        invoiceShipAddress2: data?.invoiceShipAddress2,
                                        invoiceShipAddress3: data?.invoiceShipAddress3,
                                        transportPORefNumer: (data.transportPORecordID) ? data.transportPORefNumer : data.invoiceRefNumber + 'T',
                                        invoiceShipCity: data?.invoiceShipCity,
                                        invoiceShipZip: data?.invoiceShipZip,
                                        resalePOPickupAddress1: data?.resalePOPickupAddress1,
                                        resalePOPickupCity: data?.resalePOPickupCity,
                                        resalePOPickupZipCode: data?.resalePOPickupZipCode,
                                        pickupLocation: (data.resalePOPickupCity && data.resalePOPickupState) ? data.resalePOPickupCity + ', ' + data.resalePOPickupState : '',
                                        resalePOReleaseDate: (data.resalePOReleaseDate) ? data.resalePOReleaseDate?.split('T')[0] : '',
                                        resalePOTotalPrice: data?.resalePOTotalPrice,
                                        plannedStatus: (data.planning && data.planning.plannedStatus == 1) ? 'Yes' : 'No',
                                        resalePOExpectedDate: (data.resalePOExpectedDate) ? data.resalePOExpectedDate?.split('T')[0] : '',
                                        resalePOLastDepotCallDate: (data.resalePOLastDepotCallDate) ? data.resalePOLastDepotCallDate?.split('T')[0] : '',
                                        resalePOLineItemContainerNumber: data.resalePOLineItemContainerNumber,

                                        invoiceShipCity: (data.invoiceShipCity) ? data.invoiceShipCity : '',
                                        shipState: (data.invoiceShipState) ? data.invoiceShipState : '',


                                        invoiceLineItem_RecordID: data.invoiceLineItem_RecordID,
                                        resalePOPickupCity: (data.resalePOPickupCity) ? data.resalePOPickupCity : '',
                                        pickupState: (data.resalePOPickupState) ? data.resalePOPickupState : '',

                                        transportPODeliveryStatus: data?.transportPODeliveryStatus,
                                        invoicePromisedTimeline: (data.invoicePromisedTimeline) ? data.invoicePromisedTimeline : '',
                                        invoicePromisedDate: (data.invoicePromisedDate) ? data.invoicePromisedDate?.split('T')[0] : '',
                                    })),
                                    page: result.data.currentPage - 1,
                                    totalCount: result.data.totalItems,
                                });
                                setPageRefresh(false);
                            });
                        } else {
                            let url = API_BASE_URL + `/fetchCompletedOrder?`;
                            url += 'size=' + query.pageSize;
                            url += '&page=' + (query.page + 1);
                            url += '&orderNumber=' + props.filterData;
                            if (query.orderBy) {
                                if (query.orderBy.field == 'invoiceRefNumber') {
                                    url += '&sortField=invoiceCustomerFullName'
                                    setCookie("orderBy", "invoiceCustomerFullName", { path: '/orderstatus' });
                                }
                                else {
                                    url += '&sortField=' + query.orderBy.field
                                    setCookie("orderBy", query.orderBy.field, { path: '/orderstatus' });

                                }

                                setCookie("direction", query.orderDirection, { path: '/orderstatus' });

                                url += '&sortType=' + query.orderDirection
                            } else {
                                switch (2) {
                                    case '2':
                                        url += '&sortField='+props.sortBy;
                                        url += '&sortType=desc';
                                        url += '&sortField=invoiceCustomerFullName';
                                        url += '&sortType=asc';
                                        url += '&sortField=resalePORecordID';
                                        url += '&sortType=asc';
                                        break;
                                    case '7':
                                        url += '&sortField='+props.sortBy;
                                        url += '&sortType=asc';
                                        url += '&sortField=invoiceCustomerFullName';
                                        url += '&sortType=asc';
                                        url += '&sortField=resalePORecordID';
                                        url += '&sortType=asc';
                                        break;
                                    case '15':
                                        url += '&sortField='+props.sortBy;
                                        url += '&sortType=asc';
                                        url += '&sortField=invoiceCustomerFullName';
                                        url += '&sortType=asc';
                                        url += '&sortField=resalePORecordID';
                                        url += '&sortType=asc';
                                        break;
                                    case '10':
                                        url += '&sortField='+props.sortBy;
                                        url += '&sortType=asc';
                                        url += '&sortField=invoiceCustomerFullName';
                                        url += '&sortType=asc';
                                        url += '&sortField=resalePORecordID';
                                        url += '&sortType=asc';
                                        break;
                                    default:
                                        url += '&sortField='+props.sortBy;
                                        url += '&sortType=asc';
                                        url += '&sortField=invoiceCustomerFullName';
                                        url += '&sortType=asc';
                                        url += '&sortField=resalePORecordID';
                                        url += '&sortType=asc';
                                }
                            }

                            if (query.search) {
                                url += '&search=' + query.search;
                            }

                            if (query.filters && !query.search) {
                                query.filters.forEach((item) => {
                                    url += '&search=' + item.value;
                                });
                            }

                            if (props.endDate && props.startDate) {
                                url += '&from=' + props.startDate;
                                url += '&to=' + props.endDate;
                            }

                            axios.get(url, {
                                headers: {
                                    Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN_NAME),
                                },
                            }).then(result => {
                                resolve({

                                    data: result.data.rows.map((data) => ({
                                        orderStatusRecordID: data.recordID,
                                        RecordID: data.invoiceRecordID,
                                        invoiceCustomerFullName: `${data?.invoiceCustomerFullName?.replace(',', ' ')}*${data?.invoiceRefNumber}`,
                                        invoiceTerms: data?.invoiceTerms,
                                        invoiceIsExpedited: (data.invoiceIsExpedited) ? 'Yes' : 'No',
                                        transportPOLineItemContainerNumber: data?.transportPOLineItemContainerNumber,
                                        invoiceRefNumber: data?.invoiceRefNumber,
                                        transportPOVendor: data.transportPOVendor,
                                        Product: data.invoiceLineItemItemNameWithoutParent,
                                        TransportPORecordID: data.transportPORecordID,
                                        TransportPO: (data.transportPORecordID) ? data.transportPORefNumer : data.invoiceRefNumber + 'T',
                                        resalePOSupplierLocation: data?.resalePOSupplierLocation,
                                        transportPOScheduledDeliveryDate: (data.transportPOScheduledDeliveryDate) ? data.transportPOScheduledDeliveryDate?.split('T')[0] : '',
                                        invoicePaidDate: (data.invoicePaidDate) ? data.invoicePaidDate?.split('T')[0] : '',
                                        transportPODeliveredDate: (data.transportPODeliveredDate) ? data.transportPODeliveredDate?.split('T')[0] : '',
                                        invoiceTxnDate: (data.invoiceTxnDate) ? data.invoiceTxnDate?.split('T')[0] : '',
                                        invoiceLineItemItemNameWithoutParent: data?.invoiceLineItemItemNameWithoutParent,
                                        invoiceAssignedToName: data?.invoiceAssignedToName,
                                        invoiceSupplierLocation: data?.invoiceSupplierLocation,
                                        resalePORefNumer: data?.resalePORefNumer,
                                        invoiceCustomerNotes: data?.invoiceCustomerNotes,
                                        solidSurface: data.invoice.solidSurface,
                                        resalePOVendor: data?.resalePOVendor,
                                        isCompleted: (data.isCompleted) ? 'True' : 'False',
                                        newDistance: data.newDistance ? data.newDistance : null,

                                        planningId: (data.planning) ? data.planning.id : null,

                                        invoiceBalanceRemaining: data?.invoiceBalanceRemaining,
                                        invoiceDispatchNotes: data?.invoiceDispatchNotes,
                                        resalePOReleaseNumber: data?.resalePOReleaseNumber,
                                        invoiceVendorNotes: data?.invoiceVendorNotes,
                                        ResalePO: data.resalePO,
                                        ResalePORecordID: data?.resalePORecordID,
                                        resalePODistance: data?.resalePODistance,
                                        invoiceShipMethodMethodFieldName: data?.invoiceShipMethodMethodFieldName,
                                        invoiceShipAddress1: data?.invoiceShipAddress1,
                                        invoiceShipAddress2: data?.invoiceShipAddress2,
                                        invoiceShipAddress3: data?.invoiceShipAddress3,
                                        transportPORefNumer: (data.transportPORecordID) ? data.transportPORefNumer : data.invoiceRefNumber + 'T',
                                        invoiceShipCity: data?.invoiceShipCity,
                                        invoiceShipZip: data?.invoiceShipZip,
                                        resalePOPickupAddress1: data?.resalePOPickupAddress1,
                                        resalePOPickupCity: data?.resalePOPickupCity,
                                        resalePOPickupZipCode: data?.resalePOPickupZipCode,
                                        pickupLocation: (data.resalePOPickupCity && data.resalePOPickupState) ? data.resalePOPickupCity + ', ' + data.resalePOPickupState : '',
                                        resalePOReleaseDate: (data.resalePOReleaseDate) ? data.resalePOReleaseDate?.split('T')[0] : '',
                                        resalePOTotalPrice: data?.resalePOTotalPrice,
                                        plannedStatus: (data.planning && data.planning.plannedStatus == 1) ? 'Yes' : 'No',
                                        resalePOExpectedDate: (data.resalePOExpectedDate) ? data.resalePOExpectedDate?.split('T')[0] : '',
                                        resalePOLastDepotCallDate: (data.resalePOLastDepotCallDate) ? data.resalePOLastDepotCallDate?.split('T')[0] : '',
                                        resalePOLineItemContainerNumber: data.resalePOLineItemContainerNumber,
                                        invoiceShipCity: (data.invoiceShipCity) ? data.invoiceShipCity : '',
                                        shipState: (data.invoiceShipState) ? data.invoiceShipState : '',

                                        invoiceLineItem_RecordID: data.invoiceLineItem_RecordID,
                                        resalePOPickupCity: (data.resalePOPickupCity) ? data.resalePOPickupCity : '',
                                        pickupState: (data.resalePOPickupState) ? data.resalePOPickupState : '',

                                        transportPODeliveryStatus: data?.transportPODeliveryStatus,
                                        invoicePromisedTimeline: (data.invoicePromisedTimeline) ? data.invoicePromisedTimeline : '',
                                        invoicePromisedDate: (data.invoicePromisedDate) ? data.invoicePromisedDate?.split('T')[0] : '',
                                    })),
                                    page: result.data.currentPage - 1,
                                    totalCount: result.data.totalItems,
                                });
                                setPageRefresh(false);
                            })
                        }
                    })
                }

                components={{
                    Toolbar: props => (
                        <ResetFillterButton elsement={props} resetFilter={resetFilter} />
                    ),
                }}

                options={{
                    thirdSortClick: false,

                    rowStyle: rowData => ({
                       backgroundColor: (rowData.invoiceTerms == "Rent To Own" && rowData.solidSurface == 1 ) ? '#90EE90' : (rowData.invoiceTerms == "Rent To Own") ? '#ffda73' : (rowData.solidSurface == 1) ? '#ADD8E6' : '#fff'
                    }),


                    debounceInterval: 1500,
                    pageSize: 50,
                    pageSizeOptions: [50, 100, 200],
                    filtering: true
                }}
            />

            <style jsx="true">{`

.btn-set{
    position: absolute;
    top: 18px;
    font-size: 17px;
    right: 255px;
    color: #000;
    padding: 4px 6px;
    border: 1px solid #79a3ff;
}

.input-wid-set {
    border: none;
    border-bottom: 1px solid #949494;
    position: relative;
    top: 3px;
    width: 100%;
}

                table td {
                    white-space: break-spaces!important;
                    word-wrap: break-all!important;
                }
                .MuiTableCell-root a {
                    font-size:12px !important;
                    font-family: 'Source Sans Pro', sans-serif;
                    overflow: visible;
                    display: block;
                    text-overflow: clip;
                    word-break: break-word;
                    white-space: break-spaces!important;            
                }
                // .MuiTableHead-root th:nth-child(8) {
                //     width: 129px!important;
                // }
                .MuiTableCell-root {
                    width: fit-content;
                }
                .MuiTableCell-root a {
                    width: 45px;
                }
                th.MuiTableCell-root.MuiTableCell-head.jss13.MuiTableCell-alignLeft:nth-child(2) {
                    width: 70px!important;
                }
                
                th.MuiTableCell-root.MuiTableCell-head.jss13.MuiTableCell-alignLeft:nth-child(3) {
                    width: 70px!important;
                }
                
                .MuiTableCell-root.MuiTableCell-head.MTableHeader-header-13.MuiTableCell-alignLeft:nth-child(6) {
                    width: 300px !important;
                }
                th.MuiTableCell-root.MuiTableCell-head.jss13.MuiTableCell-alignLeft:nth-child(7) {
                    width: 65px!important;
                }
                th.MuiTableCell-root.MuiTableCell-head.jss13.MuiTableCell-alignLeft:nth-child(15) {
                    width: 80px!important;
                }
                th {
                    text-align: inherit;
                    white-space: nowrap;
                }
                td.MuiTableCell-root.MuiTableCell-body.MuiTableCell-alignLeft a {
                    width:100%;
                }
                @media (max-width: 1920px){
            span.MuiButtonBase-root.MuiTableSortLabel-root {
                width: 100%;
            }
            .MuiTableCell-root.MuiTableCell-head.MTableHeader-header-13.MuiTableCell-alignLeft:nth-child(6) {
                width: 160px !important;
            }
            th.MuiTableCell-root.MuiTableCell-head.MTableHeader-header-13.MuiTableCell-alignLeft:nth-child(12) {
                width: 190px!important;
            }
        }

            `}</style>

        </div>

    );
}

export default DataTableNeedsPlanned;