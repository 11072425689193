import React, { useContext } from "react";
import { LocationContext } from "../../context/LocationContext";

function LocationDriver(props) {
    const { updateslectedLocation , slectedLocation } = useContext(LocationContext);
    return (
        <div className="row">
            <div className="col-md-12 col-sm-12 col-xs-12">
                <div className="d-flex flex-wrap justify-content-between  " style={{ alignItems: 'end' }}><div className="form-group d-flex  text-left" style={{ alignItems: 'baseline' }}>
                    
                    <label><b style={{ margin: '5px' }}> Location</b></label>&nbsp;&nbsp;
                    <select onChange={(e)=>{
                        updateslectedLocation(e.target.value);
                    }} className="form-control">
                        {
                            props.location.map((item , index) => (index == 0 || item.supplierlocationid == slectedLocation?.locationid) ? <option selected id={item.supplierlocationid} value={item.supplierlocationid}>{item.supplierloctionname}</option> :  <option id={item.supplierlocationid} value={item.supplierlocationid}>{item.supplierloctionname}</option>)
                        }
                    </select>
                </div>
                    <div className="form-group d-flex  text-left" style={{ alignItems: 'baseline' }}><label><b style={{ margin: '5px' }}>Driver </b> </label>&nbsp;&nbsp;
                        <select onChange={props.handleDriverChange} name="Supplier location" id="supplier location" className="form-control">
                            <option id="all" value="all">All</option>
                            {
                                props.driver.map((item)=><option id={item.contactId} value={item.contactId}>{item.drivername}</option>)
                            }
                        </select>
                    </div>
                    <div style={{ width: '28em' }}>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LocationDriver;