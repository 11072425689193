import React, { Component } from 'react';
import { Field } from 'formik';
import  CustomInput  from './CustomInput';


class Input extends Component {
    render() {
        return (
            <Field 
                placeholder={this.props.placeholder} 
                name={this.props.name}
                label={this.props.label}
                disabled={this.props.disabled}
                component={CustomInput}
                fun={this.props.fun}
            />
        );
    }
}

export default Input;