import React, { Component } from 'react';
import { Field } from 'formik';
import  CustomRadio  from './CustomRadio';


class Radio extends Component {
    render() {
        return (
            <Field 
                placeholder={this.props.placeholder} 
                name={this.props.name}
                label={this.props.label}
                component={CustomRadio}
            />
        );
    }
}

export default Radio;