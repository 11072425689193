import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

const CustomInput = ({
  field,
  form: { touched, errors },
  label,
  disabled,
  inputRef,
  ...props
}) => {
  // Use the state to store the current and previous value
  const [currentValue, setCurrentValue] = React.useState(field.value);
  const [previousValue, setPreviousValue] = React.useState(field.value);

  useEffect(() => {
    // Update the previous value whenever the field value changes
    setPreviousValue(currentValue);
    // Update the current value with the new field value
    setCurrentValue(field.value);

    // Check if the value has changed and perform actions as needed
    if (currentValue !== previousValue) {
      console.log('Value has changed:', currentValue);
      props.fun(true)
      // You can also notify a parent component or trigger other actions here
    }
  }, [field.value, currentValue, previousValue]);

  return (
    <div className="form-group">
      {label && <label htmlFor={field.name}>{label}</label>}
      {disabled ? (
        <input
          type="text"
          id={field.name}
          className="form-control"
          ref={inputRef}
          {...field}
          {...props}
          disabled
        />
      ) : (
        <input
          type="text"
          id={field.name}
          className="form-control"
          ref={inputRef}
          {...field}
          {...props}
        />
      )}
      {touched[field.name] && errors[field.name] ? (
        <small style={{ color: 'red' }}>{errors[field.name]}</small>
      ) : null}
    </div>
  );
};

CustomInput.defaultProps = {
  inputRef: undefined,
};

CustomInput.propTypes = {
  label: PropTypes.string,
  field: PropTypes.object,
  form: PropTypes.object,
  inputRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) })
  ]),
};

export default CustomInput;
