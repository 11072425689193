import React, { Component } from 'react';
import { Field } from 'formik';
import  CustomTime  from './CustomTime';


class Time extends Component {
    render() {
        return (
            <Field 
                placeholder={this.props.placeholder} 
                name={this.props.name}
                label={this.props.label}
                disabled={this.props.disabled}
                fun={this.props.fun}
                component={CustomTime}
            />
        );
    }
}

export default Time;