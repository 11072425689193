import React, { useState, useEffect } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { ACCESS_TOKEN_NAME, ACCESS_TOKEN_NAME_USER, API_BASE_URL } from '../../constants/apiConstants';
import axios from 'axios'
import "datatables.net-dt/js/dataTables.dataTables";
import { ToastContainer, toast } from 'react-toastify';
import "datatables.net-dt/css/jquery.dataTables.min.css";
import moment from 'moment';
import $ from 'jquery';
import Sidebar from "./../Layout/Sidebar";
import Footer from "./../Layout/Footer";
import MaterialTable from "material-table";
import logo from '../../images/logo.png';
import profilepic from '../../images/avatar-4.jpg';
//import './custom.css';
import DatePicker from "react-datepicker";
import Avatar from 'react-avatar';
import avoidNull from '../../utils/AvoidNull';
import avoidNull2 from '../../utils/AvoidNull2';
import removeAddress from '../../utils/RemoveAddress';
import DataTableOrderStatus from '../Shared/DataTableOrderStatus';
import DataTableWelcomeCall from './Datatables/WelcomeCall';
import DataTableNeedsPlanned from './Datatables/NeedPlanned';
import DataTableWatingOnRelease from './Datatables/WaitingOnRelease';
import DataTablePlanned from './Datatables/planned';


function Allorders2(props) {
    const [orderData, setOrderData] = useState();
    const [filterData, setFilterData] = useState();

    const [key, setKey] = useState();

    const [selectedStatus, setselectedStatus] = useState();

    const [display, setdisplay] = useState('none');

    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [sortBy,setSortBy] = useState('invoicePromisedDate');

    const [rowsPerPage, setRowsPerPage] = useState({
        data: ""
    });

    console.log("sdafad", key);


    useEffect(() => {
        $(document).ready(function () {
            setTimeout(function () {
                $('#myTable').DataTable({
                    "scrollX": true,
                    "order": [[3, "desc"]]
                });
            }, 1000);
        });
    }, []);

    const handleChangeRowsPerPage = (e) => {
        console.log('pagination', e.target.value);
        if (e.target.value === "All") {
            setRowsPerPage({ pageSize: orderData.length });
        } else {
            setRowsPerPage({ pageSize: e.target.value });
        }
    };

    // const resetSort = (id) => {
    //     setSortBy('');
    //     if(id == 7)
    //     {
            
    //         setSortBy('invoicePromisedDate');
    //     }
    //     else
    //     {
    //         setSortBy('invoiceTxnDate');
    //     }
    // }

    const handleChange = (e) => {
        console.log("sdadad",e.target.value);
        setSortBy('');
        if(e.target.value == 7 || e.target.value == 16)
        {
            
            setSortBy('invoicePromisedDate');
        }
        else if(e.target.value == 2)
        {
            setSortBy('invoicePaidDate')
        }
        else
        {
            setSortBy('invoiceTxnDate');
        }
       
        const value = e.target.value;
        setFilterData(e.target.value);
        setKey(e.target.value);
        localStorage.setItem('orderstatus', e.target.value);
        
        //fetchSingleData(value);
    }


    const handleView = () => {
        alert('function clicked');
    }

    const [loading, setLoading] = useState(false);

    const fetchSingleData = async (value) => {

        const token = localStorage.getItem(ACCESS_TOKEN_NAME);

        if (value == 0) {
            setLoading(true);
            let results = await axios.get(API_BASE_URL + "/findAllOrder", {
                headers: {
                    Authorization: "Bearer " + token,
                },
            });
            setLoading(false);
            setOrderData(results.data);
        } else {
            setLoading(true);
            const results = await axios.get(API_BASE_URL + `/findSingleOrder?status=${value}`,
                {
                    headers: {
                        Authorization: "Bearer " + token,
                    },
                });
            setLoading(false);
            setOrderData(results.data);
        }

        setselectedStatus(value);
    }

    const handleSubmitSearch = (e) => {
        e.preventDefault();
        setFilterData(localStorage.getItem('orderstatus'))
        setKey(Math.floor(Math.random() * 10));
    }

    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());

    useEffect(() => {
        setFilterData(localStorage.getItem('orderstatus'));
        setKey(1);
    }, []);


    function handleLogout() {
        localStorage.removeItem(ACCESS_TOKEN_NAME);
        localStorage.removeItem('role');
        localStorage.removeItem('user');
        localStorage.removeItem(ACCESS_TOKEN_NAME_USER);
        props.history.push("/");
    }


    const option = () => {

        const orderStatus = [{status:0,label:'All'},{status:1,label:'Has Not Paid'},{status:2,label:'Welcome Call Needed'},{status:16,label:'Needs Planned'},{status:3,label:'Follow Up Call Needed'},
        {status:4,label:'Needs To Buy'},{status:5,label:'Waiting On Release'},{status:6,label:'Inventory Not Paid'},{status:7,label:'Needs Dispatch'},{status:15,label:'Planned'},{status:8,label:'Customer Pickup'},
        {status:9,label:'Expedited Sales'},{status:10,label:'Dispatched'},{status:11,label:'Completed'},{status:12,label:'Inventory'},{status:13,label:'Cancelled'},{status:14,label:'On Hold'},{status:18,label:'Delivered'}];

        return orderStatus.map((item)=>{
            console.log(item.status == localStorage.getItem('orderstatus'), item.status, localStorage.getItem('orderstatus') , 'orderstatus');
            if (item.status == localStorage.getItem('orderstatus')) {
                return <option selected value={item.status}>{item.label}</option>
            } else {
                return <option value={item.status}>{item.label}</option> 
            }
        });
    }

    return (

        <div id="pcoded" className="pcoded">
            <div className="pcoded-overlay-box"></div>
            <div className="pcoded-container navbar-wrapper">

                <nav className="navbar header-navbar pcoded-header" style={{ height: '55px', minHeight: '26px' }}>
                    <div className="navbar-wrapper">
                        <div className="navbar-logo" style={{ height: '52px' }}>
                            <a style={{ top: '7px' }} className="mobile-menu navabar_menu" id="mobile-collapse" href="#!">
                                <i class="ti-menu" style={{ "color": " #000", "font-size": "22px;" }}></i>
                            </a>
                            <a className="mobile-search morphsearch-search" href="#">
                                <i className="ti-search"></i>
                            </a>
                            <Link to="/dashboard">
                                <img style={{ width: '30%' }} className="img-fluid logo-img" src={logo} alt="Theme-Logo" />
                            </Link>
                            <a className="mobile-options">
                                <i className="ti-more"></i>
                            </a>
                        </div>

                        <div className="navbar-container container-fluid">
                            <ul className="nav-left" style={{margin: "1% 0% 0% 8%" }}>
                                <h4>Order Status</h4>
                            </ul>
                
                            <ul className="nav-right">
                                <li className="user-profile header-notification" style={{ lineHeight: 3.5 }}>
                                    <a href="javascript:void(0)" onClick={()=>{
                                        setdisplay((old)=>(old == 'none') ? 'block' : 'none')
                                    }}>
                                        <Avatar name={localStorage.getItem('user')} colors={['#ed3028', '#711999', '#383838']} size="40" round="20px" />
                                    </a>
                                    <ul className="show-notification profile-notification" style={{display:display}}>
                                        <li>
                                            <a href="#">
                                                <i className="ti-user"></i> Profile
                                            </a>
                                        </li>
                                        <li>
                                            <Link to="/resetpassword">
                                                <i className="ti-layout-sidebar-left"></i> Change Password
                                            </Link>
                                        </li>
                                        <li>
                                            <a href="" onClick={() => handleLogout()}>
                                                <i className="ti-layout-sidebar-left"></i> Logout
                                            </a>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>


                <div className="pcoded-main-container">
                    <div className="pcoded-wrapper">

                        <Sidebar />
                        <ToastContainer />

                        <div className="pcoded-content page_content">
                            <div className="pcoded-inner-content">
                                <div className="main-body">
                                    <div className="page-wrapper">
                                        <div className="page-body">
                                            <div className="col-md-12 tabs-ui p-0">
                                                <div className="tab-content" id="nav-tabContent">
                                                    <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">

                                                        <div className="row" style={{ marginLeft: "0px" }}>
                                                            <div className="col-md-4 col-xl-4" >
                                                                <select id="orderListSelection" className="form-control" onChange={handleChange}>
                                                                    {option()}
                                                                </select>
                                                            </div>
                                                            <div className="col-md-8 col-xl-8">
                                                                <form>
                                                                    <div className="row">
                                                                        <div className="col-md-3">
                                                                            <div className="form-group text-left">
                                                                                <DatePicker placeholderText="From" className="form-control" selected={startDate} onChange={(date) => setStartDate(date)} />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-3">
                                                                            <div className="form-group text-left">
                                                                                <DatePicker placeholderText="To" selected={endDate} className="form-control" onChange={(date) => setEndDate(date)} />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-2">
                                                                            <div className="form-group text-left">
                                                                                <button
                                                                                    type="submit"
                                                                                    className="btn btn-primary btn-sm"
                                                                                    onClick={handleSubmitSearch}
                                                                                >
                                                                                    Submit
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </form>
                                                            </div>

                                                        </div>
                                                        { key == 16 && <DataTableNeedsPlanned endDate={endDate} startDate={ startDate } key={key} sortBy={sortBy} filterData={filterData} /> }
                                                        { key == 2 && <DataTableWelcomeCall endDate={endDate} startDate={ startDate } key={key} sortBy={sortBy} filterData={filterData} /> }      
                                                        { key == 5 && <DataTableWatingOnRelease endDate={endDate} startDate={ startDate } key={key} sortBy={sortBy} filterData={filterData} /> }  
                                                        { key == 15 && <DataTablePlanned endDate={endDate} startDate={ startDate } key={key} sortBy={sortBy} filterData={filterData} /> }                                             
                                                        {/* {key != 2 && <DataTableOrderStatus endDate={endDate} startDate={ startDate } key={key} sortBy={sortBy} filterData={filterData}  />}  */}
                                                        


                                                    </div>
                                                </div>

                                            </div>

                                            <Footer />

                                            <style jsx="true">{`
                                                .title-strong {
                                                    font-weight: 550
                                                }

                                                .MuiTableCell-alignLeft {
                                                    z-index:unset !important;
                                                }

                                                .MuiTableCell-root{
                                                    padding: 2px !important;
                                                    font-family: 'Source Sans Pro', sans-serif;
                                                }
                                                .MuiTableCell-root a {
                                                    font-size:12px !important;
                                                    font-family: 'Source Sans Pro', sans-serif;
                                                }

                                                .MuiTableHead-root th {
                                                    font-size: 12px !important;
                                                    font-weight: 600;
                                                    background: #fbf5f5;
                                                }
                                            `}</style>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default withRouter(Allorders2);