import { API_BASE_URL , ACCESS_TOKEN_NAME } from '../../constants/apiConstants';
import axios from 'axios'

export default class DisqualifiedPlanneOrdersServices {
  static async fetchLocation() {
    const userId = localStorage.getItem('userId');
    const token = localStorage.getItem(ACCESS_TOKEN_NAME);
    const response = await axios.get(API_BASE_URL + `/findSupplierLocationByUserid?id=${userId}`, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    return response.data;
  }

  static async fetchDriver(locationId) {
    const token = localStorage.getItem(ACCESS_TOKEN_NAME);
    const response = await axios.get(API_BASE_URL + `/findDriverBySupplierLocationid?id=${locationId}`, {
      headers: {
        Authorization: "Bearer " + token,
      }
    });
    return response.data;
  }

  static async fetchDispatchedinfo(driverId) {
    const token = localStorage.getItem(ACCESS_TOKEN_NAME);
    const response = await axios.get(API_BASE_URL + `/fetchDispatchedinfo?driverId=${driverId}`, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    return response.data;
  }

  static async cancelDispatched(drivername , date , invoiceId) {
    const response = await axios.delete(API_BASE_URL + `/deletePlanningDriverDetails`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN_NAME),
      },
      data: {
        drivername,
        date,
        invoiceId
      }
    });
    return response.data;
  }
}

