
import PropType from 'prop-types';
import React from 'react';

const CustomCheckbox = ({
  field, value , form: { touched, errors }, label, disabled, inputRef, ...props
}) => (
  <>
  {
      (disabled) ?
          (value) ?
            <input
              checked
              type="checkbox"
              id={field.name}
              ref={inputRef}
              {...field}
              {...props}
              disabled
          /> :
          <input
            type="checkbox"
            id={field.name}
            ref={inputRef}
            {...field}
            {...props}
            disabled
          />
      : 
        (value) ?
        <input
          checked
          type="checkbox"
          id={field.name}
          ref={inputRef}
          {...field}
          {...props}
      /> :
      <input
        type="checkbox"
        id={field.name}
        ref={inputRef}
        {...field}
        {...props}
      />
  }

  <label htmlFor={field.name}>&nbsp;&nbsp;{label}&nbsp;&nbsp;</label>
  {touched[field.name] && errors[field.name] ? (
    <small style={{ color: 'red' }}>{errors[field.name]}</small>
  ) : null}
</>
);

CustomCheckbox.defaultProps = {
  inputRef: undefined
};

CustomCheckbox.propTypes = {
  label: PropType.string.isRequired,
  field: PropType.object.isRequired,
  form: PropType.object.isRequired,
  inputRef: PropType.oneOfType([
    PropType.func,
    PropType.shape({ current: PropType.instanceOf(Element) })
  ])
};

export default CustomCheckbox;
