import { API_BASE_URL, ACCESS_TOKEN_NAME } from '../../../constants/apiConstants';
import axios from 'axios'

export default class InvoiceServices {

    static async getPlanningInfo(recordID) {
        const response = await axios.get(API_BASE_URL + `/getPlanningByRecordID?recordID=` + recordID, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN_NAME),
            }
        });
        return response.data;
    }

    static async checkTPO(invoiceRefNumber) {
            const response = await axios.get(API_BASE_URL + `/checkTPO?invoiceRefNumber=` + invoiceRefNumber, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN_NAME),
                }
            });
            return response.data;
    }

    static async getPlanningInfoBasedInvoiceLineItem(invoiceLineItem_RecordID) {
        const response = await axios.get(API_BASE_URL + `/getPlanningByinvoiceLineItemRecordID?invoiceLineItem_RecordID=` + invoiceLineItem_RecordID, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN_NAME),
            }
        });
        return response.data;
    }

    static async getAllInvoiceNotes(id, invoiceLineItem_RecordID)
    {
        const response = await axios.get(API_BASE_URL + `/getAllInvoiceNotes?id=${id}&invoiceLineItem_RecordID=${invoiceLineItem_RecordID}`, {
            headers: {
                 Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN_NAME),
            },
        });
        return response.data;
    }

    static async getAllNotes(resalePORecordId, invoiceRecordID)
    {
        const response = await axios.get(API_BASE_URL + `/getAllResalePoNotes?resalePORecordId=${resalePORecordId}&invoiceRecordID=${invoiceRecordID}`, {
            headers: {
                 Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN_NAME),
            },
        });
        return response.data;
    }

    static async getInvoiceOrder(id,invoiceLineItem_RecordID) {
        const token = localStorage.getItem(ACCESS_TOKEN_NAME);
        const response = await axios.get(API_BASE_URL + `/findOrderRecordId?id=${id}&invoiceLineItem_RecordID=${invoiceLineItem_RecordID}`, {
            headers: {
                Authorization: "Bearer " + token,
            },
        });
        return response.data;
    }

    static async getInvoiceItem(id) {
        const token = localStorage.getItem(ACCESS_TOKEN_NAME);
        const response = await axios.get(API_BASE_URL + `/findInvoiceItem?record=${id}`, {
            headers: {
                Authorization: "Bearer " + token,
            },
        });
        return response.data;
    }


    static async getInvoiceLine() {
        const token = localStorage.getItem(ACCESS_TOKEN_NAME);
        const response = await axios.get(API_BASE_URL + `/findAllSupplierLocation`, {
            headers: {
                Authorization: "Bearer " + token,
            },
        });
        return response.data;
    }


    static async getAllSupplierLocation() {
        const token = localStorage.getItem(ACCESS_TOKEN_NAME);
        const response = await axios.get(API_BASE_URL + `/findAllSupplierLocation`, {
            headers: {
                Authorization: "Bearer " + token,
            },
        });
        return response.data;
    }

    static async getVendorDriver() {
        const token = localStorage.getItem(ACCESS_TOKEN_NAME);
        const response = await axios.get(API_BASE_URL + `/findallVendorDeverinfo`, {
            headers: {
                Authorization: "Bearer " + token,
            },
        });
        return response.data;
    }

    static async updateBilling(payload, id) {
        const response = await axios.post(API_BASE_URL + `/updateInvoiceBillAddress?id=${id}`, payload, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN_NAME),
            },
        });

        return response.data;
    }

    static async updateShipping(payload, id) {
        const response = await axios.post(API_BASE_URL + `/updateInvoiceShipAddress?id=${id}`, payload, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN_NAME),
            },
        });

        return response.data;
    }

    static async syncUpdate(recordID, resaleporecordID) {
        const response = await axios.post(API_BASE_URL + `/syncDataManual`, { recordID, resaleporecordID }, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN_NAME),
            },
        });
        return response.data;
    }

    static async syncUpdatePurchaseOrder(recordID, resaleporecordID) {
        const response = await axios.post(API_BASE_URL + `/syncDataRealTimePurchaseOrder`, { recordID, resaleporecordID }, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN_NAME),
            },
        });
        return response.data;
    }

    static async updateShippingTopurchaseorder(payload, recordID) {
        const response = await axios.post(API_BASE_URL + `/updateShipAddressTopurchaseorder?recordID=${recordID}`, payload, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN_NAME),
            },
        });
        return response.data;
    }
    static async updateInvoice(payload, id) {
        const response = await axios.post(API_BASE_URL + `/updateInvoice?id=${id}`, payload, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN_NAME),
            },
        });

        return response.data;
    }


    static async updateCustomerNote(payload, recordID) {
        // console.log("payload",payload)
        // console.log("recordID", recordID)
        const response = await axios.patch(API_BASE_URL + `/updateNote?recordID=${recordID}`, payload, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN_NAME),
            }
        });
        return response.data;
    }

    static async updateDispatchNote(payload, recordID) {
        const response = await axios.patch(API_BASE_URL + `/updateNote?recordID=${recordID}`, payload, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN_NAME),
            }
        });
        return response.data;
    }

    static async updateVendorNote(payload, recordID) {
        const response = await axios.patch(API_BASE_URL + `/updateNote?recordID=${recordID}`, payload, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN_NAME),
            }
        });
        return response.data;
    }

    static async updatePurchasingNote(payload, recordID) {
        const response = await axios.patch(API_BASE_URL + `/updateNote?recordID=${recordID}`, payload, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN_NAME),
            }
        });
        return response.data;
    }
}
