import React, { useState, useEffect } from "react";
import axios from "axios";
import { useHistory, useParams, Link } from "react-router-dom";
import { API_BASE_URL, ACCESS_TOKEN_NAME, ACCESS_TOKEN_NAME_USER } from "../../constants/apiConstants";
import {Multiselect}  from "multiselect-react-dropdown";
import swal from "sweetalert";
import InputMask from 'react-input-mask';
import logo from '../../images/logo.png';
import profilepic from '../../images/avatar-4.jpg';
import Navbar from "./../Layout/Navbar";
import Sidebar from "./../Layout/Sidebar";
import Footer from "./../Layout/Footer";
import Avatar from 'react-avatar';


const EditContact = (props) => {
  let history = useHistory();
  const params = useParams();

  const [state, setState] = useState({
    firstName : "",
    lastName : "",
    phone : "",
    email : "",
    contactType: "",
    driverPhone: "",
    driverEmail: "",
    truckType: "",
    trailerType: "",
    trailerSize : "",
    successMessage: null,
    errorMessages: {}
  });

  const [display, setdisplay] = useState('none');

  const [error, setError] = useState({
    errorMessage: {}
  });
  const [territory, setTerritory] = useState({
      assignedTerritory: []
  });
  const [errorMessages, setErrorMessage] = useState();


  const [vendorid, setVendorid] = useState();

  const [vendorName, setvendorName] = useState('');


  const [multiSelectOptions, setMultiSelectOptions] = useState([]);
  const [multiSelectedValue, setMultiSelectedValue] = useState([]);

  // const allowNumber = (e) => {

  // }

  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(name, value);
    if(name === 'phone')
    {
      const re = /^[0-9\+b]+$/;

    // if value is not blank, then test the regex

    if (e.target.value === '' || re.test(e.target.value)) {
      setState((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
    }
    else
    {
      setState((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    } 
   
    validate(name, value);
  };

  useEffect(() => {
    async function fetchData() {
      const { id } = params;
      const result = await axios.get(
        API_BASE_URL + `/findSingleContactData?id=${id}`,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN_NAME),
          },
        }
      );

     

      const data = [];
      if (result.status === 200) {
        result.data[0].driverSupplierLocations.forEach(element => {
            data.push({id: element.supplierLocation.id, name: element.supplierLocation.SupplierLocation});
        });
        setMultiSelectedValue(data);
        console.log('selected value',data);
        setTerritory({assignedTerritory: { selectedList: data}});
        setState(result.data[0]);
        setVendorid(result.data[1]);
        setvendorName(result.data[2].vendornameinfo);
      }
    }
    fetchData();

    async function fetchLocation() {
      const result = await axios.get(
          API_BASE_URL + `/findAllSupplierLocation`,
          {
              headers : {
                  Authorization : "Bearer " + localStorage.getItem(ACCESS_TOKEN_NAME),
              }
          },
      );
      const data = [];
      if(result.status === 200){
          result.data.forEach(element => {
            data.push({id: element.id, name: element.SupplierLocation});
            
          });
          console.log('not selected--',data);
          setMultiSelectOptions(data);
      }
    }   
    fetchLocation();

  }, []);

  const onSelect = (selectedList, selectedItem) => {
    console.log('selectedlist',selectedList);
    setTerritory((prevState) => ({
      ...prevState,
      assignedTerritory: {
        selectedList
      },
    }));
  }

  const onRemove = (selectedList, removedItem) => {
    console.log('deselected',selectedList);
    setTerritory((prevState) => ({
      ...prevState,
      assignedTerritory: {
        selectedList
      },
    }));
  }
  
  
  const validate = (key, value) => {
    console.log("hello");
    setState((prevState) => ({
      ...prevState,
      errorMessage: {
        ...prevState.errorMessage,
        [key]: "",
      },
    }));
    switch (key) {
      case "email":
        let pattern = new RegExp(
            /^(("[\w-\s]+")|([+\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
        );
        if (!pattern.test(value)) {
          console.log("hiiii");
          setState((prevState) => ({
            ...prevState,
            errorMessage: {
              ...prevState.errorMessage,
              email: "Please Enter valid Email",
            },
          }));
        }
        break;

      default:
    }
  };
  const handleCancel = (e) => {
    // history.push("/vendordetails");
    history.goBack();
  }
  const handleSubmitClick = async (e) => {
    e.preventDefault();
    if (!state.firstName.trim().length) {
      setError((prevState) => ({
        ...prevState,
        errorMessage: {
          ...prevState.errorMessage,
          firstName: "Please Enter First Name",
        },
      }));
    }
    

    if (!state.lastName.trim().length) {
      setError((prevState) => ({
        ...prevState,
        errorMessage: {
          ...prevState.errorMessage,
          lastName: "Please Enter Last Name",
        },
      }));
    }


    if (!state.phone.trim().length) {
      setError((prevState) => ({
        ...prevState,
        errorMessage: {
          ...prevState.errorMessage,
          phone: "Please Enter Phone Number",
        },
      }));
    }
    if (!state.contactType.length) {
      setError((prevState) => ({
        ...prevState,
        errorMessage: {
          ...prevState.errorMessage,
          contactType: "Please Select Contact Type",
        },
      }));
    }
    await validate("email", state.DriverEmail);
    /*
    if (!state.driverPhone.length) {
      setError((prevState) => ({
        ...prevState,
        errorMessage: {
          ...prevState.errorMessage,
          driverPhone: "Please Enter Additional Phone Number",
        },
      }));
    }

    if (!state.driverEmail.length) {
      setError((prevState) => ({
        ...prevState,
        errorMessage: {
          ...prevState.errorMessage,
          driverEmail: "Please Enter Driver Email",
        },
      }));
    }

    if (!state.truckType.length) {
      setError((prevState) => ({
        ...prevState,
        errorMessage: {
          ...prevState.errorMessage,
          truckType: "Please Select Truck Type",
        },
      }));
    }
    if (!state.trailerType.length) {
      setError((prevState) => ({
        ...prevState,
        errorMessage: {
          ...prevState.errorMessage,
          trailerType: "Please Select Trailer Type",
        },
      }));
    }
    if (!state.trailerSize.length) {
      setError((prevState) => ({
        ...prevState,
        errorMessage: {
          ...prevState.errorMessage,
          trailerSize: "Please Select Trailer Size",
        },
      }));
    }


    if (territory.assignedTerritory.length == 0) {
      setError((prevState) => ({
        ...prevState,
        errorMessage: {
          ...prevState.errorMessage,
          AssignedTerritory: "Please Select Supplier Location",
        },
      }));
    }else{
      setError((prevState) => ({
        ...prevState,
        errorMessage: {
          ...prevState.errorMessage,
          AssignedTerritory: "",
        },
      }));
    }
    */

   

    if (
        state.firstName.trim() && 
        state.lastName.trim() && 
        state.phone.trim() &&
        state.email.trim() &&
        state.contactType.trim() 

        /*
        state.driverPhone &&
        state.driverEmail &&
        state.truckType &&
        state.trailerType &&
        state.trailerSize &&
      

        territory.assignedTerritory.length !== 0
        */
    ) {
      console.log('if condition');
      setState((prevState) => ({
        ...prevState,
        errorMessage: {},
      }));
      const { id } = params;
      const payload = {
        firstName: state.firstName.trim(),
        lastName: state.lastName.trim(),
        Phone: state.phone.trim(),
        Email: state.email.trim(),
        ContactType: state.contactType,
        DriverPhone: state.driverPhone.trim(),
        DriverEmail: state.driverEmail.trim(),
        TruckType: state.truckType,
        TrailerType: state.trailerType,
        TrailerSize: state.trailerSize,
        AssignedTerritory: territory.assignedTerritory
      };
      console.log('updatepayload',payload);
      axios
      .post(API_BASE_URL + `/updateSingleContact?id=${id}`, payload, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN_NAME),
        },
      })
      .then(function (response) {
        if (response.status === 200) {
          swal({  
            title: "Contact Updated!",
            text: "",
            icon: "success",
            button: "OK ",
          }).then((value) => {
            //history.push(`/vendordetails/${vendorid.vendorid}`);
             props.history.goBack()
            
          });
        } else {
          setState((prevState) => ({
            ...prevState,
            errorMessage: "some error occurd",
          }));
        }
      })
      .catch(function (error) {
        console.log(error);
      });

    } else {
      setErrorMessage("Please Fill All The Fields");
    }
  };
  
  function handleLogout() {
    localStorage.removeItem(ACCESS_TOKEN_NAME);
    localStorage.removeItem('role');
    localStorage.removeItem('user');
    localStorage.removeItem(ACCESS_TOKEN_NAME_USER);
    props.history.push("/");
  }


  return (
    <>
    <div id="pcoded" className="pcoded">
        <div className="pcoded-overlay-box"></div>
        <div className="pcoded-container navbar-wrapper">

      
        <nav className="navbar header-navbar pcoded-header" style={{height:'55px', minHeight:'26px'}}> 
        <div className="navbar-wrapper">
            <div className="navbar-logo" style={{height: '52px'}}>
                <a style={{top:'7px'}} className="mobile-menu navabar_menu" id="mobile-collapse" href="#!">
                    <i class="ti-menu" style={{"color":" #000", "font-size" : "22px;"}}></i>
                </a>
                <a className="mobile-search morphsearch-search" href="#">
                    <i className="ti-search"></i>
                </a>
                <Link to="/dashboard">
                    <img style={{width:'30%'}} className="img-fluid logo-img" src={logo} alt="Theme-Logo" />
                </Link>
                <a className="mobile-options">
                    <i className="ti-more"></i>
                </a>
            </div>
            
            <div className="navbar-container container-fluid">
                <ul className="nav-left" style={{margin: "1% 0% 0% 8%" }}>
                  <h4>Edit Driver</h4>
                </ul>
                <ul className="nav-right">
                    <li className="user-profile header-notification" style={{lineHeight: 3.5}}>
                        <a href="javascript:void(0)" onClick={()=>{
                           setdisplay((old)=>(old == 'none') ? 'block' : 'none')
                        }}>
                            <Avatar name={localStorage.getItem('user')} colors={['#ed3028', '#711999', '#383838']} size="40" round="20px" />
                        </a>
                        <ul className="show-notification profile-notification" style={{display:display}}>
                            <li>
                                <a href="#">
                                    <i className="ti-user"></i> Profile
                                </a>
                            </li>
                            <li>
                              <Link to="/resetpassword">
                                <i className="ti-layout-sidebar-left"></i> Change Password
                              </Link>
                            </li>
                            <li>
                                <a href="" onClick={() => handleLogout()}>
                                    <i className="ti-layout-sidebar-left"></i> Logout
                                </a>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
            <div className="pcoded-main-container">
                <div className="pcoded-wrapper">
                   
                   <Sidebar />

                    <div className="pcoded-content page_content">
                        <div className="pcoded-inner-content">
                            <div className="main-body">
                                <div className="page-wrapper">
                                    <div className="page-body">
                                        <div className="row">
                                            <div className="col-md-3">
                                                {vendorName}
                                            </div>
                                            <div className="col-md-9">
                                                {/* <h4>Edit User</h4> */}
                                            </div>
                                        </div>
                                        <div className="col-md-12 tabs-ui p-0">
                                        <div className="tab-content" id="nav-tabContent">
                                            <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                                                <form>
                                                  {errorMessages && (
                                                    <span className="red">{errorMessages}</span>
                                                  )}
                                                  <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group text-left">
                                                        <label>First Name</label>
                                                            <input
                                                            type="text"
                                                            name="firstName"
                                                            className="form-control"
                                                            id="firstName"
                                                            placeholder="Name"
                                                            value={state.firstName}
                                                            onChange={handleChange}
                                                            />
                                                            {error.errorMessage.firstName && (
                                                            <span className="red">{error.errorMessage.firstName}</span>
                                                            )}
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="form-group text-left">
                                                        <label>Last Name</label>
                                                            <input
                                                            type="text"
                                                            name="lastName"
                                                            className="form-control"
                                                            id="lastName"
                                                            placeholder="Last Name"
                                                            value={state.lastName}
                                                            onChange={handleChange}
                                                            />
                                                            {error.errorMessage.lastName && (
                                                            <span className="red">{error.errorMessage.lastName}</span>
                                                            )}
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="form-group text-left">
                                                        <label>Email</label>
                                                            <input
                                                            type="email"
                                                            name="email"
                                                            className="form-control"
                                                            id="email"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Email"
                                                            value={state.email}
                                                            onChange={handleChange}
                                                            />

                                                            {/* {state.errorMessage.email && (
                                                            <span className="red">{state.errorMessage.email}</span>
                                                            )} */}
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="form-group text-left">
                                                        <label>Phone</label>
                                                            <InputMask
                                                            name="phone"
                                                            className="form-control"
                                                            id="mobile"
                                                            placeholder="Phone"
                                                            maxLength="10"
                                                            value={state.phone}
                                                            onChange = {handleChange}
                                                            // onChange={(e) => { handleChange(e); allowNumber(e) }}
                                                            />
                                                            {error.errorMessage.phone && (
                                                            <span className="red">{error.errorMessage.phone}</span>
                                                            )}
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="form-group text-left">
                                                        <label>Driver Type</label>
                                                            <select
                                                            className="form-control"
                                                            name="contactType"
                                                            value={state.contactType}
                                                            onChange={handleChange}
                                                            >
                                                            <option value="">Select Driver Type</option>
                                                            <option value="Driver">Driver</option>
                                                            <option value="Other">Other</option>
                                                            </select>
                                                            {error.errorMessage.contactType && (
                                                            <span className="red">{error.errorMessage.contactType}</span>
                                                            )}
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="form-group text-left">
                                                        <label>Additional Phone</label>
                                                            <InputMask
                                                            name="driverPhone"
                                                            className="form-control"
                                                            id="mobile"
                                                            maxLength="10"
                                                            placeholder="Driver Phone"
                                                            value={state.driverPhone}
                                                            onChange={handleChange}
                                                            />
                                                            {error.errorMessage.driverPhone && (
                                                            <span className="red">{error.errorMessage.driverPhone}</span>
                                                            )}
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="form-group text-left">
                                                        <label>Additional Email</label>
                                                            <input
                                                            type="email"
                                                            name="driverEmail"
                                                            className="form-control"
                                                            id="email"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Driver Email"
                                                            value={state.driverEmail}
                                                            onChange={handleChange}
                                                            />
                                                            {error.errorMessage.driverEmail && (
                                                            <span className="red">{error.errorMessage.driverEmail}</span>
                                                            )}
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="form-group text-left">
                                                        <label>Truck Type</label>
                                                            <select
                                                            className="form-control"
                                                            name="truckType"
                                                            value={state.truckType}
                                                            onChange={handleChange}
                                                            >
                                                            <option value="">Select Truck Type</option>
                                                            <option value="Pick Up Truck">Pick Up Truck</option>
                                                            <option value="Semi">Semi</option>
                                                            <option value="Tow Truck">Tow Truck</option>
                                                            </select>
                                                            {error.errorMessage.truckType && (
                                                            <span className="red">{error.errorMessage.truckType}</span>
                                                            )}
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="form-group text-left">
                                                        <label>Trailer Type</label>
                                                            <select
                                                            className="form-control"
                                                            name="trailerType"
                                                            value={state.trailerType}
                                                            onChange={handleChange}
                                                            >
                                                            <option value="">Select Trailer Type</option>
                                                            <option value="Tilt Deck">Tilt Deck</option>
                                                            <option value="Tow Truck">Tow Truck</option>
                                                            <option value="Flatbed">Flatbed</option>
                                                            </select>
                                                            {error.errorMessage.trailerType && (
                                                            <span className="red">{error.errorMessage.trailerType}</span>
                                                            )}
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="form-group text-left">
                                                        <label>Trailer Size</label>
                                                            <select
                                                            className="form-control"
                                                            name="trailerSize"
                                                            value={state.trailerSize}
                                                            onChange={handleChange}
                                                            >
                                                            <option value="">Select Trailer Size</option>
                                                            <option value="20 ft">20 ft</option>
                                                            <option value="22 ft">22 ft</option>
                                                            <option value="40 ft">40 ft</option>
                                                            </select>
                                                            {error.errorMessage.trailerSize && (
                                                            <span className="red">{error.errorMessage.trailerSize}</span>
                                                            )}
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="form-group text-left">
                                                        <label>Supplier Location</label>
                                                            <Multiselect
                                                            options={multiSelectOptions}
                                                            selectedValues={multiSelectedValue}
                                                            onSelect={onSelect}
                                                            onRemove={onRemove}
                                                            displayValue="name"
                                                            placeholder="Select Supplier"
                                                            />
                                                            {error.errorMessage.assignedTerritory && (
                                                            <span className="red">{error.errorMessage.assignedTerritory}</span>
                                                            )}
                                                        </div>
                                                    </div>

                                                        <div className="col-md-12 cstm-add-btn">
                                                        <button
                                                            type="button"
                                                            className="btn btn-primary mr-2"
                                                            onClick={handleSubmitClick}
                                                        >
                                                            Update
                                                        </button>
                                                        
                                                        <button
                                                            type="button"
                                                            className="btn btn-default"
                                                            onClick={()=>history.goBack()}
                                                        >Cancel</button>
                                                        </div>
                                                  </div>
                                                </form>
                                                <div
                                                  className="alert alert-success mt-2"
                                                  style={{ display: state.successMessage ? "block" : "none" }}
                                                  role="alert"
                                                >
                                                  {state.successMessage}
                                                </div>
                                           

                                            </div>
                                          <div className="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">2</div>
                                          <div className="tab-pane fade" id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab">3</div>
                                      </div>
                                            
                                        </div>

                                        <Footer />

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> 
    </>
  );

};

export default EditContact;
