import React, { useState, useEffect } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { ACCESS_TOKEN_NAME, ACCESS_TOKEN_NAME_USER, API_BASE_URL } from '../../constants/apiConstants';
import axios from 'axios';
import moment from 'moment';
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery';
import Sidebar from "./../Layout/Sidebar";
import Footer from "./../Layout/Footer";
import logo from '../../images/logo.png';
import profilepic from '../../images/avatar-4.jpg';
import DatePicker from "react-datepicker";
//import "react-datepicker/dist/react-datepicker.css";
import Avatar from 'react-avatar';
import { ToastContainer, toast } from 'react-toastify';
import { Center } from '@chakra-ui/react';
import avoidNull from '../../utils/AvoidNull';
import avoidNull2 from '../../utils/AvoidNull2';
import removeAddress from '../../utils/RemoveAddress';
import CustomDatePicker from "../../utils/customDatePicker";
import MaterialTable, { MTableToolbar, Chip } from 'material-table';
import FilterComponentBox from "../../utils/FilterComponentBox";
import ResetFillterButton from "../../utils/ResetFillterButton";
import { useCookies } from 'react-cookie';
import Cookies from 'universal-cookie';
import SyncComponent from '../Shared/SyncComponent';



function WaitingOnRelease(props) {
    const [pageRefresh, setPageRefresh] = useState(false);
    const [cookies, setCookie, removeCookie] = useCookies(['WaitingOnRelease']);
    const [orderData, setOrderData] = useState();
    const [filterData, setFilterData] = useState();

    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();

    const [rowsPerPage, setRowsPerPage] = useState({
        data: ""
    });

    const [display, setdisplay] = useState('none');
    const [pageNameList, setPageNameList] = useState('Waiting On Release')

    const cookiesi = new Cookies();
    // if(cookiesi.getAll()['desc'] || cookiesi.getAll()['asc'])
    // {
    //     setdefaultSortValue()
    // }
    //console.log("cookiesKey",);





    const resetFilter = async () => {

        const test = document.querySelectorAll(".material-icons");
        for(let tests of test)
        {
        if(tests.getAttribute("aria-label") == 'clear')
        {
            tests.click();
        }
        }
        const cookies = new Cookies();

        if (Object.keys(cookies.getAll()).length > 0) {
            setPageRefresh(true);
        }
        else {
            setPageRefresh(true);
        }
        await removeCookie("orderBy", { path: "/WaitingOnRelease" });
        await removeCookie("direction", { path: "/WaitingOnRelease" });
        await removeCookie("invoiceRefNumber", { path: "/WaitingOnRelease" });
        await removeCookie("invoicePaidDate", { path: "/WaitingOnRelease" });
        await removeCookie("invoiceTerms", { path: "/WaitingOnRelease" });
        await removeCookie("invoiceLineItemItemNameWithoutParent", { path: "/WaitingOnRelease" });
        await removeCookie("invoiceAssignedToName", { path: "/WaitingOnRelease" });
        await removeCookie("resalePOPickupCity", { path: "/WaitingOnRelease" });
        await removeCookie("resalePORefNumer", { path: "/WaitingOnRelease" });
        await removeCookie("resalePOReleaseNumber", { path: "/WaitingOnRelease" });
        await removeCookie("resalePOReleaseDate", { path: "/WaitingOnRelease" });
        await removeCookie("resalePOTotalPrice", { path: "/WaitingOnRelease" });
        await removeCookie("resalePOExpectedDate", { path: "/WaitingOnRelease" });
        await removeCookie("resalePOLastDepotCallDate", { path: "/WaitingOnRelease" });
        await removeCookie("resalePOLineItemDistance", { path: "/WaitingOnRelease" });
        await removeCookie("invoiceShipCity", { path: "/WaitingOnRelease" });
        await removeCookie("transportPOLineItemContainerNumber", { path: "/WaitingOnRelease" });
        await removeCookie("transportPORefNumer", { path: "/WaitingOnRelease" });
        await removeCookie("transportPOVendor", { path: "/WaitingOnRelease" });
        await removeCookie("transportPOTotalPrice", { path: "/WaitingOnRelease" });
        await removeCookie("transportPOScheduledDeliveryDate", { path: "/WaitingOnRelease" });
        await removeCookie("transportPODeliveryStatus", { path: "/WaitingOnRelease" });
        await removeCookie("invoicePromisedTimeline", { path: "/WaitingOnRelease" });
        await removeCookie("invoicePromisedDate", { path: "/WaitingOnRelease" });

        await removeCookie("invoiceTxnDate", { path: "/WaitingOnRelease" });
        await removeCookie("invoiceCustomerNotes", { path: "/WaitingOnRelease" });
        await removeCookie("invoiceBalanceRemaining", { path: "/WaitingOnRelease" });
        await removeCookie("invoiceSupplierLocation", { path: "/WaitingOnRelease" });
        await removeCookie("driverName", { path: "/WaitingOnRelease" });
        await removeCookie("vendorName", { path: "/WaitingOnRelease" });
    }


    useEffect(() => {
        $(document).ready(function () {
            setTimeout(function () {
                $('#myTable').DataTable({
                    "scrollX": true,
                });
            }, 1000);
        });

        const fetchData = async () => {
            const token = localStorage.getItem(ACCESS_TOKEN_NAME);
            const response = await axios.get(API_BASE_URL + "/fetchOrderBasedOnColumn?size=20&page=1&orderNumber=5&sortField=invoiceTxnDate&sortType=asc&sortField=invoiceCustomerFullName&sortType=asc&sortField=resalePORecordID&sortType=asc", {
                headers: {
                    Authorization: "Bearer " + token,
                },
            });
            const res = response.data;
            setOrderData(res);
            setRowsPerPage({ data: res.length })
        }
        fetchData();



    }, []);



    const handleChangeRowsPerPage = (e) => {

        if (e.target.value === "All") {
            setRowsPerPage({ pageSize: orderData.length });
        } else {
            setRowsPerPage({ pageSize: e.target.value });
        }
    };

    const handleChange = (e) => {
        console.log("Selected!!", e.target.value);
        const value = e.target.value;
        setFilterData(e.target.value);
        fetchSingleData(value);
    }



    const handleView = () => {
        alert('function clicked');
    }
    const fetchSingleData = async (value) => {
        const token = localStorage.getItem(ACCESS_TOKEN_NAME);
        const results = await axios.get(API_BASE_URL + `/findSingleOrder?status=${value}`,
            {
                headers: {
                    Authorization: "Bearer " + token,
                },
            });
        setOrderData(results.data);
        console.log('selected======', results.data);
    }

    const handleSubmitSearch = (e) => {
        e.preventDefault();
        if (typeof (endDate) == 'undefined' || typeof (startDate) == 'undefined') {

            setTimeout(() => {
                toast.success('Please select a date that is at least 1 business day from now', {
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });
            });

            return true;
        }

        const payload = {
            from: startDate,
            to: endDate,
            status: 2,
        }
        axios
            .post(API_BASE_URL + "/findDataUsingDate", payload)
            .then(function (response) {
                setOrderData(response.data);
            })
            .catch(error => {
                console.log(error);
            })
    }

    function handleLogout() {
        localStorage.removeItem(ACCESS_TOKEN_NAME);
        localStorage.removeItem('role');
        localStorage.removeItem('user');
        localStorage.removeItem(ACCESS_TOKEN_NAME_USER);
        props.history.push("/");
    }

    return (
        <div id="pcoded" className="pcoded">
            <div className="pcoded-overlay-box"></div>
            <div className="pcoded-container navbar-wrapper">
                <nav className="navbar header-navbar pcoded-header" pcoded-header-position="fixed" style={{ height: '55px', minHeight: '26px', position: 'fixed' }}>
                    <div className="navbar-wrapper">
                        <div className="navbar-logo" style={{ height: '52px' }}>
                            <a style={{ top: '7px' }} className="mobile-menu navabar_menu" id="mobile-collapse" href="#!">
                                <i class="ti-menu" style={{ "color": " #000", "font-size": "22px;" }}></i>
                            </a>

                            <Link to="/dashboard">
                                <img style={{ width: '30%' }} className="img-fluid logo-img" src={logo} alt="Theme-Logo" />
                            </Link>
                        </div>

                        <SyncComponent setPageRefresh={setPageRefresh} pageNameList={pageNameList} />
                    </div>
                </nav>


                <div className="pcoded-main-container">
                    <div className="pcoded-wrapper">
                        <Sidebar />
                        <div className="pcoded-content page_content">
                            <div className="pcoded-inner-content">
                                <div className="main-body">
                                    <div className="page-wrapper">
                                        <ToastContainer />
                                        <div className="page-body">
                                            {
                                                /*
                                                    <div className="row">
                                                        <div className="col-md-8 col-xl-8">
                                                            <h4 style={{ float: "left", marginTop: "6px", fontSize: "large", marginLeft: "15px" }}>Welcome Call Needed List</h4>
                                                        </div>
                                                    </div>
                                                */
                                            }
                                            <div className="col-md-12 tabs-ui p-0">
                                            <div className='row justify-content-center align-items-center color-choose'>
                                               <h5 className='cstmstates'><span>RTO</span><span className='statecolor rto'></span></h5>
                                               <h5 className='cstmstates'><span>Solid Surface</span><span className='statecolor solid_surface'></span></h5>      
                                               <h5 className='cstmstates'><span>RTO on Solid Surface</span><span className='statecolor rto_ss'></span></h5>  
                                               <h5 className='cstmstates'><span>Tow Truck</span><span className='statecolor solid_tow'></span></h5>      
                                               <h5 className='cstmstates'><span>RTO + Tow Truck</span><span className='statecolor rto-two'></span></h5>    
                                               <h5 className='cstmstates'><span>Solid Surface + Tow Truck</span><span className='statecolor solid-two'></span></h5>      
                                            </div>
                                                <div className="tab-content" id="nav-tabContent">
                                                    <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">

                                                        <div className="row" style={{ marginLeft: "0px" }}>


                                                            <div className="col-md-8 col-xl-8">
                                                                {
                                                                    /*
                                                                        <form>
                                                                            <div className="row">
                                                                                <div className="col-md-3">
                                                                                    <div className="form-group text-left">
                                                                                        <DatePicker placeholderText="From" className="form-control"  selected={startDate} onChange={(date) => setStartDate(date)} />
                                                                                    </div>
                                                                                </div>

                                                                                <div className="col-md-3">
                                                                                    <div className="form-group text-left">
                                                                                        <DatePicker placeholderText="To" selected={endDate} className="form-control" onChange={(date) => setEndDate(date)} />
                                                                                    </div>
                                                                                </div>

                                                                                <div className="col-md-2">
                                                                                    <div className="form-group text-left">
                                                                                        <button
                                                                                            type="submit"
                                                                                            className="btn btn-primary btn-sm"
                                                                                            onClick={handleSubmitSearch}
                                                                                        >
                                                                                            Submit
                                                                                        </button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </form>
                                                                    */
                                                                }
                                                            </div>

                                                        </div>


                                                        <div className="col-md-12">
                                                            <MaterialTable
                                                                title=""
                                                                key={pageRefresh}
                                                                columns={[
                                                                    { title: "RecordID", field: "RecordID", hidden: true },
                                                                    {
                                                                        title: "Customer", sortDescFirst: "false",
                                                                        defaultSort: cookiesi.getAll()['orderBy'] == 'invoiceCustomerFullName' ? cookiesi.getAll()['direction'] : false,
                                                                        filterComponent: ({ columnDef, onFilterChanged }) => (
                                                                            <FilterComponentBox pageName={'WaitingOnRelease'} onFilterChanged={onFilterChanged} columnDef={columnDef} columnName={columnDef.field} />
                                                                        ),

                                                                        field: "invoiceRefNumber", render: rowData => <a className={rowData.sameTrailerMarker === 1 &&  rowData.invoiceIsExpedited === 'Yes' ? 'markerExp' : rowData.invoiceIsExpedited === 'Yes' ? 'expeditedClass' : rowData.sameTrailerMarker === 1 ? 'sameMarker' : ''} target="_blank" href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID}><strong className="title-strong">{rowData.Customer?.split('*')[0]}</strong><br />{rowData.Customer?.split('*')[1]}</a>
                                                                    },

                                                                    { title: "Inv Date", field: "invoiceTxnDate", defaultSort: cookiesi.getAll()['orderBy'] == 'invoiceTxnDate' ? cookiesi.getAll()['direction'] : false, filterComponent: (props) => <CustomDatePicker pageName={'WaitingOnRelease'} {...props} />, type: 'date', dateSetting: { locale: "en-US" }, render: rowData => <a className={rowData.sameTrailerMarker === 1 &&  rowData.invoiceIsExpedited === 'Yes' ? 'markerExp' : rowData.invoiceIsExpedited === 'Yes' ? 'expeditedClass' : rowData.sameTrailerMarker === 1 ? 'sameMarker' : ''} target="_blank" href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID}>{(rowData.InvoiceDate) ? moment(rowData.InvoiceDate).format('MM/DD/YY') : ''}</a> },
                                                                    { title: "Paid Date", field: "invoicePaidDate", defaultSort: cookiesi.getAll()['orderBy'] == 'invoicePaidDate' ? cookiesi.getAll()['direction'] : false, filterComponent: (props) => <CustomDatePicker pageName={'WaitingOnRelease'} {...props} />, type: 'date', render: rowData => <a className={rowData.sameTrailerMarker === 1 &&  rowData.invoiceIsExpedited === 'Yes' ? 'markerExp' : rowData.invoiceIsExpedited === 'Yes' ? 'expeditedClass' : rowData.sameTrailerMarker === 1 ? 'sameMarker' : ''} target="_blank" href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID}>{(rowData.PaidDate) ? moment(rowData.PaidDate).format('MM/DD/YY') : ''}</a> },


                                                                    {
                                                                        title: "Product", defaultSort: cookiesi.getAll()['orderBy'] == 'invoiceLineItemItemNameWithoutParent' ? cookiesi.getAll()['direction'] : false,
                                                                        filterComponent: ({ columnDef, onFilterChanged }) => (
                                                                            <FilterComponentBox pageName={'WaitingOnRelease'} onFilterChanged={onFilterChanged} columnDef={columnDef} columnName={columnDef.field} />
                                                                        ),
                                                                        field: "invoiceLineItemItemNameWithoutParent", render: rowData => <a className={rowData.sameTrailerMarker === 1 &&  rowData.invoiceIsExpedited === 'Yes' ? 'markerExp' : rowData.invoiceIsExpedited === 'Yes' ? 'expeditedClass' : rowData.sameTrailerMarker === 1 ? 'sameMarker' : ''} target="_blank" href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID}>{rowData.Product}</a>
                                                                    },
                                                                    {
                                                                        title: "Driver", field: "driverName",
                                                                        defaultSort: cookiesi.getAll()['orderBy'] == 'driverName' ? cookiesi.getAll()['direction'] : false,
                                                                        filterComponent: ({ columnDef, onFilterChanged }) => (
                                                                            <FilterComponentBox pageName={'WaitingOnRelease'} onFilterChanged={onFilterChanged} columnDef={columnDef} columnName={columnDef.field} />
                                                                        ),
                                                                        render: (rowData) => {
                                                                            return <a className={`breakword ${rowData.sameTrailerMarker === 1 &&  rowData.invoiceIsExpedited === 'Yes' ? 'markerExp' : rowData.invoiceIsExpedited === 'Yes' ? 'expeditedClass' : rowData.sameTrailerMarker === 1 ? 'sameMarker' : ''}`} target="_blank" href={`https://www.google.com/maps/dir/?api=1&origin=${avoidNull(rowData.resalePOPickupAddress1)}${avoidNull(rowData.resalePOPickupCity)}${avoidNull2(rowData.resalePOPickupZipCode)}&destination=${removeAddress(rowData.invoiceShipAddress1,rowData.shipState)}${removeAddress(rowData.invoiceShipAddress2, rowData.shipState)}${removeAddress(rowData.invoiceShipAddress3, rowData.shipState)}${avoidNull(rowData.invoiceShipCity)}${avoidNull(rowData.shipState)}${avoidNull2(rowData.invoiceShipZip)}`}>{rowData.DriverName}</a>
                                                                        }
                                                                    },
                                                                    {
                                                                        title: "Vendor", field: "vendorName",
                                                                        defaultSort: cookiesi.getAll()['orderBy'] == 'vendorName' ? cookiesi.getAll()['direction'] : false,
                                                                        filterComponent: ({ columnDef, onFilterChanged }) => (
                                                                            <FilterComponentBox pageName={'WaitingOnRelease'} onFilterChanged={onFilterChanged} columnDef={columnDef} columnName={columnDef.field} />
                                                                        ),
                                                                        render: (rowData) => {
                                                                            return <a className={`breakword ${rowData.sameTrailerMarker === 1 &&  rowData.invoiceIsExpedited === 'Yes' ? 'markerExp' : rowData.invoiceIsExpedited === 'Yes' ? 'expeditedClass' : rowData.sameTrailerMarker === 1 ? 'sameMarker' : ''}`} target="_blank" href={`https://www.google.com/maps/dir/?api=1&origin=${avoidNull(rowData.resalePOPickupAddress1)}${avoidNull(rowData.resalePOPickupCity)}${avoidNull2(rowData.resalePOPickupZipCode)}&destination=${removeAddress(rowData.invoiceShipAddress1,rowData.shipState)}${removeAddress(rowData.invoiceShipAddress2, rowData.shipState)}${removeAddress(rowData.invoiceShipAddress3, rowData.shipState)}${avoidNull(rowData.invoiceShipCity)}${avoidNull(rowData.shipState)}${avoidNull2(rowData.invoiceShipZip)}`}>{rowData.vendorName}</a>
                                                                        }
                                                                    },
                                                                    {
                                                                        title: "Assign To", defaultSort: cookiesi.getAll()['orderBy'] == 'invoiceAssignedToName' ? cookiesi.getAll()['direction'] : false,
                                                                        filterComponent: ({ columnDef, onFilterChanged }) => (
                                                                            <FilterComponentBox pageName={'WaitingOnRelease'} onFilterChanged={onFilterChanged} columnDef={columnDef} columnName={columnDef.field} />
                                                                        ),
                                                                        field: "invoiceAssignedToName", render: rowData => <a className={rowData.sameTrailerMarker === 1 &&  rowData.invoiceIsExpedited === 'Yes' ? 'markerExp' : rowData.invoiceIsExpedited === 'Yes' ? 'expeditedClass' : rowData.sameTrailerMarker === 1 ? 'sameMarker' : ''} target="_blank" href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID}>{rowData.AssignedTo}</a>
                                                                    },
                                                                    {
                                                                        title: "Cus Note", defaultSort: cookiesi.getAll()['orderBy'] == 'invoiceCustomerNotes' ? cookiesi.getAll()['direction'] : false,
                                                                        filterComponent: ({ columnDef, onFilterChanged }) => (
                                                                            <FilterComponentBox pageName={'WaitingOnRelease'} onFilterChanged={onFilterChanged} columnDef={columnDef} columnName={columnDef.field} />
                                                                        ),
                                                                        field: "invoiceCustomerNotes", render: rowData => <a className={rowData.sameTrailerMarker === 1 &&  rowData.invoiceIsExpedited === 'Yes' ? 'markerExp' : rowData.invoiceIsExpedited === 'Yes' ? 'expeditedClass' : rowData.sameTrailerMarker === 1 ? 'sameMarker' : ''} target="_blank" href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID}>{rowData.invoiceCustomerNotes?.split(' ').map((item, index) => (index <= 20) ? item + ' ' : '')}</a>
                                                                    },
                                                                    {
                                                                        title: "Balance", defaultSort: cookiesi.getAll()['orderBy'] == 'invoiceBalanceRemaining' ? cookiesi.getAll()['direction'] : false,
                                                                        filterComponent: ({ columnDef, onFilterChanged }) => (
                                                                            <FilterComponentBox pageName={'WaitingOnRelease'} onFilterChanged={onFilterChanged} columnDef={columnDef} columnName={columnDef.field} />
                                                                        ),
                                                                        field: "invoiceBalanceRemaining", render: rowData => <a className={rowData.sameTrailerMarker === 1 &&  rowData.invoiceIsExpedited === 'Yes' ? 'markerExp' : rowData.invoiceIsExpedited === 'Yes' ? 'expeditedClass' : rowData.sameTrailerMarker === 1 ? 'sameMarker' : ''} target="_blank" href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID}>{rowData.Balance}</a>, cellStyle: { textAlign: 'center' }
                                                                    },
                                                                    {
                                                                        title: "Sup Loc", defaultSort: cookiesi.getAll()['orderBy'] == 'invoiceSupplierLocation' ? cookiesi.getAll()['direction'] : false,
                                                                        filterComponent: ({ columnDef, onFilterChanged }) => (
                                                                            <FilterComponentBox pageName={'WaitingOnRelease'} onFilterChanged={onFilterChanged} columnDef={columnDef} columnName={columnDef.field} />
                                                                        ),
                                                                        field: "invoiceSupplierLocation", render: rowData => <a className={rowData.sameTrailerMarker === 1 &&  rowData.invoiceIsExpedited === 'Yes' ? 'markerExp' : rowData.invoiceIsExpedited === 'Yes' ? 'expeditedClass' : rowData.sameTrailerMarker === 1 ? 'sameMarker' : ''} target="_blank" href={'https://www.google.com/maps/place/' + rowData.SupplierLocation}>{rowData.SupplierLocation}</a>
                                                                    },
                                                                    {
                                                                        title: "Resale PO #", defaultSort: cookiesi.getAll()['orderBy'] == 'resalePORefNumer' ? cookiesi.getAll()['direction'] : false,
                                                                        filterComponent: ({ columnDef, onFilterChanged }) => (
                                                                            <FilterComponentBox pageName={'WaitingOnRelease'} onFilterChanged={onFilterChanged} columnDef={columnDef} columnName={columnDef.field} />
                                                                        ),
                                                                        field: "resalePORefNumer", render: rowData => <a className={rowData.sameTrailerMarker === 1 &&  rowData.invoiceIsExpedited === 'Yes' ? 'markerExp' : rowData.invoiceIsExpedited === 'Yes' ? 'expeditedClass' : rowData.sameTrailerMarker === 1 ? 'sameMarker' : ''} target="_blank" href={'/resalePurchaseOrder/' + rowData.ResalePORecordID + '/' + rowData.RecordID}>{rowData.ResalePO}</a>
                                                                    },
                                                                    {
                                                                        title: "Rel#", defaultSort: cookiesi.getAll()['orderBy'] == 'resalePOReleaseNumber' ? cookiesi.getAll()['direction'] : false,
                                                                        filterComponent: ({ columnDef, onFilterChanged }) => (
                                                                            <FilterComponentBox pageName={'WaitingOnRelease'} onFilterChanged={onFilterChanged} columnDef={columnDef} columnName={columnDef.field} />
                                                                        ),
                                                                        field: "resalePOReleaseNumber", render: rowData => <a className={rowData.sameTrailerMarker === 1 &&  rowData.invoiceIsExpedited === 'Yes' ? 'markerExp' : rowData.invoiceIsExpedited === 'Yes' ? 'expeditedClass' : rowData.sameTrailerMarker === 1 ? 'sameMarker' : ''} target="_blank" href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID}>{rowData.Resale}</a>
                                                                    },

                                                                    {
                                                                        title: "Release Date", defaultSort: cookiesi.getAll()['orderBy'] == 'resalePOReleaseDate' ? cookiesi.getAll()['direction'] : false,
                                                                        filterComponent: ({ columnDef, onFilterChanged }) => (
                                                                            <FilterComponentBox pageName={'WaitingOnRelease'} onFilterChanged={onFilterChanged} columnDef={columnDef} columnName={columnDef.field} />
                                                                        ),
                                                                        field: "resalePOReleaseDate", filterComponent: (props) => <CustomDatePicker pageName={'WaitingOnRelease'} {...props} />, type: 'date', render: rowData => <a className={rowData.sameTrailerMarker === 1 &&  rowData.invoiceIsExpedited === 'Yes' ? 'markerExp' : rowData.invoiceIsExpedited === 'Yes' ? 'expeditedClass' : rowData.sameTrailerMarker === 1 ? 'sameMarker' : ''} target="_blank" href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID}>{(rowData.ReleaseDate) ? moment(rowData.ReleaseDate).format('MM/DD/YY') : ''}</a>
                                                                    },

                                                                    {
                                                                        title: "Res Total",
                                                                        defaultSort: cookiesi.getAll()['orderBy'] == 'resalePOTotalPrice' ? cookiesi.getAll()['direction'] : false,
                                                                        filterComponent: ({ columnDef, onFilterChanged }) => (
                                                                            <FilterComponentBox pageName={'WaitingOnRelease'} onFilterChanged={onFilterChanged} columnDef={columnDef} columnName={columnDef.field} />
                                                                        ),
                                                                        field: "resalePOTotalPrice", render: rowData => <a className={rowData.sameTrailerMarker === 1 &&  rowData.invoiceIsExpedited === 'Yes' ? 'markerExp' : rowData.invoiceIsExpedited === 'Yes' ? 'expeditedClass' : rowData.sameTrailerMarker === 1 ? 'sameMarker' : ''} target="_blank" href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID}>{rowData.ResaleTotal}</a>
                                                                    },
                                                                    {
                                                                        title: "Expected",
                                                                        defaultSort: cookiesi.getAll()['orderBy'] == 'resalePOExpectedDate' ? cookiesi.getAll()['direction'] : false,
                                                                        filterComponent: (props) => <CustomDatePicker pageName={'WaitingOnRelease'} {...props} />,
                                                                        field: "resalePOExpectedDate", type: 'date', render: rowData => <a className={rowData.sameTrailerMarker === 1 &&  rowData.invoiceIsExpedited === 'Yes' ? 'markerExp' : rowData.invoiceIsExpedited === 'Yes' ? 'expeditedClass' : rowData.sameTrailerMarker === 1 ? 'sameMarker' : ''} target="_blank" href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID}>{(rowData.Expected) ? moment(rowData.Expected).format('MM/DD/YY') : ''}</a>
                                                                    },


                                                                    {
                                                                        title: "Del City/State", defaultSort: cookiesi.getAll()['orderBy'] == 'invoiceShipCity' ? cookiesi.getAll()['direction'] : false,
                                                                        filterComponent: ({ columnDef, onFilterChanged }) => (
                                                                            <FilterComponentBox pageName={'WaitingOnRelease'} onFilterChanged={onFilterChanged} columnDef={columnDef} columnName={columnDef.field} />
                                                                        ),
                                                                        field: "invoiceShipCity", render: (rowData) => {
                                                                            return <a className={`breakword ${rowData.sameTrailerMarker === 1 &&  rowData.invoiceIsExpedited === 'Yes' ? 'markerExp' : rowData.invoiceIsExpedited === 'Yes' ? 'expeditedClass' : rowData.sameTrailerMarker === 1 ? 'sameMarker' : ''}`} target="_blank" href={`https://www.google.com/maps/dir/?api=1&origin=${avoidNull(rowData.resalePOPickupAddress1)}${avoidNull(rowData.resalePOPickupCity)}${avoidNull2(rowData.resalePOPickupZipCode)}&destination=${removeAddress(rowData.invoiceShipAddress1)}${removeAddress(rowData.invoiceShipAddress2)}${removeAddress(rowData.invoiceShipAddress3)}${avoidNull(rowData.invoiceShipCity)}${avoidNull(rowData.shipState)}${avoidNull2(rowData.invoiceShipZip)}`}>{rowData.shipCity}<br />{rowData.shipState}</a>
                                                                        }
                                                                    },
                                                                    {
                                                                        title: "Del. Status", defaultSort: cookiesi.getAll()['orderBy'] == 'transportPODeliveryStatus' ? cookiesi.getAll()['direction'] : false,
                                                                        filterComponent: ({ columnDef, onFilterChanged }) => (
                                                                            <FilterComponentBox pageName={'WaitingOnRelease'} onFilterChanged={onFilterChanged} columnDef={columnDef} columnName={columnDef.field} />
                                                                        ),
                                                                        field: "transportPODeliveryStatus", render: rowData => <a className={rowData.sameTrailerMarker === 1 &&  rowData.invoiceIsExpedited === 'Yes' ? 'markerExp' : rowData.invoiceIsExpedited === 'Yes' ? 'expeditedClass' : rowData.sameTrailerMarker === 1 ? 'sameMarker' : ''} target="_blank" href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID}>{rowData.DeliveryStatus}</a>
                                                                    },
                                                                    {
                                                                        title: "Pro Timeline", defaultSort: cookiesi.getAll()['orderBy'] == 'invoicePromisedTimeline' ? cookiesi.getAll()['direction'] : false,
                                                                        filterComponent: ({ columnDef, onFilterChanged }) => (
                                                                            <FilterComponentBox pageName={'WaitingOnRelease'} onFilterChanged={onFilterChanged} columnDef={columnDef} columnName={columnDef.field} />
                                                                        ), field: "invoicePromisedTimeline", render: rowData => <a className={rowData.sameTrailerMarker === 1 &&  rowData.invoiceIsExpedited === 'Yes' ? 'markerExp' : rowData.invoiceIsExpedited === 'Yes' ? 'expeditedClass' : rowData.sameTrailerMarker === 1 ? 'sameMarker' : ''} target="_blank" href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID}>{rowData.PromisedTimeline}</a>
                                                                    },


                                                                    { title: "Pro Date", defaultSort: cookiesi.getAll()['orderBy'] == 'invoicePromisedDate' ? cookiesi.getAll()['direction'] : false, field: "invoicePromisedDate", filterComponent: (props) => <CustomDatePicker pageName={'WaitingOnRelease'} {...props} />, type: 'date', render: rowData => <a className={rowData.sameTrailerMarker === 1 &&  rowData.invoiceIsExpedited === 'Yes' ? 'markerExp' : rowData.invoiceIsExpedited === 'Yes' ? 'expeditedClass' : rowData.sameTrailerMarker === 1 ? 'sameMarker' : ''} target="_blank" href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID}>{(rowData.PromisedDate) ? moment(rowData.PromisedDate).format('MM/DD/YY') : ''}</a> }
                                                                ]}

                                                                data={query =>
                                                                    new Promise((resolve, reject) => {

                                                                        const cookies = new Cookies();
                                                                        //cookies.remove('direction');

                                                                        cookies.remove('adminer_version');
                                                                        // setCookie("orderBy",query.orderBy.field,{ path: '/WaitingOnRelease' });
                                                                        // setCookie("direction",query.orderDirection,{ path: '/WaitingOnRelease' });
                                                                        if (query.filters.length > 0 || Object.keys(cookies.getAll()).length > 0) {
                                                                            let url = API_BASE_URL + `/fetchOrderBasedOnColumn?`;
                                                                            url += 'size=' + query.pageSize;
                                                                            url += '&page=' + (query.page + 1);
                                                                            url += '&orderNumber=' + 5;
                                                                            if (query.orderBy) {
                                                                                removeCookie("orderBy", { path: "/WaitingOnRelease" });
                                                                                removeCookie("direction", { path: "/WaitingOnRelease" });
                                                                                if (query.orderBy.field == 'invoiceRefNumber') {

                                                                                    setCookie("orderBy", "invoiceCustomerFullName", { path: '/WaitingOnRelease' });
                                                                                }
                                                                                else {

                                                                                    setCookie("orderBy", query.orderBy.field, { path: '/WaitingOnRelease' });


                                                                                }

                                                                                setCookie("direction", query.orderDirection, { path: '/WaitingOnRelease' });

                                                                            }
                                                                            else {
                                                                                //console.log("achaasfsfsdfdsfsdfaaaa");
                                                                                url += '&sortField=invoiceTxnDate';
                                                                                url += '&sortType=asc';
                                                                                url += '&sortField=invoiceCustomerFullName';
                                                                                url += '&sortType=asc';
                                                                                url += '&sortField=resalePORecordID';
                                                                                url += '&sortType=asc';
                                                                            }

                                                                            if (Object.keys(cookies.getAll()).length > 0) {

                                                                                for (const key in cookies.cookies) {

                                                                                    console.log(moment.isDate(cookies.cookies[key].replace('"', '').replace('"', '')), 'momentinfo', cookies.cookies[key], key);
                                                                                    if(cookies.cookies[key] == 'Invalid date')
                                                                                    {
                                                                                        console.log('okkk')
                                                                                         removeCookie(key, { path: "/WaitingOnRelease" });
                                                                                    }
                                                                                    
                                                                                    else
                                                                                    {
                                                                                        if (cookies.cookies[key] && moment.isDate(cookies.cookies[key])) {
                                                                                        cookies.cookies[key] = moment(cookies.cookies[key]).format('YYYY-MM-DD')
                                                                                    }

                                                                                    if (cookies.cookies[key]) {
                                                                                        if (key == 'invoiceRefNumber' && isNaN(cookies.cookies[key])) {
                                                                                            url += '&columnName=' + 'invoiceCustomerFullName';
                                                                                            if (cookies.cookies[key].toLowerCase() == 'yes') {
                                                                                                url += '&search=' + 1;
                                                                                            } else if (cookies.cookies[key].toLowerCase() == 'no') {
                                                                                                url += '&search=' + 0;
                                                                                            } else {
                                                                                                url += '&search=' + encodeURIComponent(cookies.cookies[key]);
                                                                                            }
                                                                                        }
                                                                                        else if (key == 'orderBy') {

                                                                                            url += '&sortField=' + cookies.cookies[key]

                                                                                            // url += '&sortType=' + cookies.cookies['direction']
                                                                                        }
                                                                                        else if (key == 'direction') {
                                                                                            url += '&sortType=' + cookies.cookies[key]
                                                                                        }
                                                                                        else {
                                                                                            url += '&columnName=' + key;
                                                                                            if (cookies.cookies[key].toLowerCase() == 'yes') {
                                                                                                url += '&search=' + 1;
                                                                                            } else if (cookies.cookies[key].toLowerCase() == 'no') {
                                                                                                url += '&search=' + 0;
                                                                                            }

                                                                                            else {
                                                                                                url += '&search=' + encodeURIComponent(cookies.cookies[key]);
                                                                                            }
                                                                                        }
                                                                                    } 
                                                                                    }

                                                                            
                                                                                }
                                                                            } else {
                                                                                query.filters.forEach((item) => {
                                                                                    if (item.value && moment.isDate(item.value)) {
                                                                                        item.value = moment(item.value).format('YYYY-MM-DD')
                                                                                    }

                                                                                    if (item.column.field == 'invoiceRefNumber' && isNaN(parseInt(item.value))) {
                                                                                        url += '&columnName=' + 'invoiceCustomerFullName';
                                                                                        url += '&search=' + encodeURIComponent(item.value);
                                                                                    } else {
                                                                                        url += '&columnName=' + item.column.field;
                                                                                        url += '&search=' + encodeURIComponent(item.value);
                                                                                    }
                                                                                });
                                                                            }

                                                                            console.log("acha", url);
                                                                            axios.get(url, {
                                                                                headers: {
                                                                                    Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN_NAME),
                                                                                },
                                                                            }).then(result => {
                                                                                resolve({
                                                                                    data: result.data.rows.map((data) => ({
                                                                                        orderStatusRecordID: data.recordID,
                                                                                        RecordID: data.invoiceRecordID,
                                                                                        Customer: `${data.invoiceCustomerFullName.replace(',', ' ')}*${data.invoiceRefNumber}`,
                                                                                        InvoiceRefNumber: data.invoiceRefNumber,
                                                                                        InvoiceDate: (data.invoiceTxnDate) ? data.invoiceTxnDate?.split('T')[0] : '',
                                                                                        PaidDate: (data.invoicePaidDate) ? data.invoicePaidDate?.split('T')[0] : '',
                                                                                        Product: data.invoiceLineItemItemNameWithoutParent,
                                                                                        DriverName: (data.planning) ? data.planning.driverName : null,
                                                                                        vendorName: (data.purchaseOrder) ? data.purchaseOrder.vendor : null,
                                                                                       
                                                                                        AssignedTo: data.invoiceAssignedToName,
                                                                                        invoiceCustomerNotes: data?.invoiceCustomerNotes,
                                                                                        invoiceTerms: data.invoiceTerms,
                                                                                        Balance: (data.invoiceBalanceRemaining) ? data.invoiceBalanceRemaining : '',
                                                                                        SupplierLocation: data.invoiceSupplierLocation,
                                                                                        ResalePO: data.resalePORefNumer,
                                                                                        Resale: data.resalePOReleaseNumber,
                                                                                        invoiceShipAddress1: data.invoiceShipAddress1,
                                                                                        invoiceShipAddress2: data.invoiceShipAddress2,
                                                                                        invoiceShipAddress3: data.invoiceShipAddress3,
                                                                                        invoiceShipCity: data.invoiceShipCity,
                                                                                        invoiceShipZip: data.invoiceShipZip,
                                                                                        resalePOPickupAddress1: data.resalePOPickupAddress1,
                                                                                        resalePOPickupCity: data.resalePOPickupCity,
                                                                                        resalePOPickupZipCode: data.resalePOPickupZipCode,
                                                                                        ResalePORecordID: data.resalePORecordID,
                                                                                        ReleaseDate: (data.resalePOReleaseDate) ? data.resalePOReleaseDate?.split('T')[0] : '',

                                                                                        shipCity: (data.invoiceShipCity) ? data.invoiceShipCity : '',
                                                                                        shipState: (data.invoiceShipState) ? data.invoiceShipState : '',
                                                                                        solidSurface: (data.invoice?.solidSurface) ? data.invoice.solidSurface : 0,
                                                                                        invoiceIsExpedited: data.invoiceIsExpedited
                                                                                        ? "Yes"
                                                                                        : "No",


                                                                                        invoiceShipAddress1: data.invoiceShipAddress1,
                                                                                        invoiceShipAddress2: data.invoiceShipAddress2,
                                                                                        invoiceShipAddress3: data.invoiceShipAddress3,
                                                                                        invoiceShipCity: data.invoiceShipCity,
                                                                                        invoiceShipZip: data.invoiceShipZip,
                                                                                        resalePOPickupAddress1: data.resalePOPickupAddress1,
                                                                                        resalePOPickupCity: data.resalePOPickupCity,
                                                                                        resalePOPickupZipCode: data.resalePOPickupZipCode,
                                                                                        ResaleTotal: data.resalePOTotalPrice,
                                                                                        Expected: (data.resalePOExpectedDate) ? data.resalePOExpectedDate?.split('T')[0] : '',
                                                                                        ExpectedDeliveryDate: (data.resalePOExpectedDate) ? data.resalePOExpectedDate?.split('T')[0] : '',

                                                                                        invoiceLineItem_RecordID: data.invoiceLineItem_RecordID,
                                                                                        towTruck: (data.invoice?.towTruck) ? data.invoice.towTruck : 0,
                                                                                        sameTrailerMarker: data.sameTrailerMarker,
                                                                                        DeliveryStatus: data.transportPODeliveryStatus,
                                                                                        PromisedTimeline: (data.invoicePromisedTimeline) ? data.invoicePromisedTimeline : '',
                                                                                        PromisedDate: (data.invoicePromisedDate) ? data.invoicePromisedDate?.split('T')[0] : '',
                                                                                    })),
                                                                                    page: result.data.currentPage - 1,
                                                                                    totalCount: result.data.totalItems,
                                                                                });
                                                                                setPageRefresh(false);
                                                                            });
                                                                        } else {
                                                                            console.log('HI..KKHGGHJH');
                                                                            let url = API_BASE_URL + `/fetchCompletedOrder?`;
                                                                            url += 'size=' + query.pageSize;
                                                                            url += '&page=' + (query.page + 1);
                                                                            url += '&orderNumber=' + 5;
                                                                            if (query.orderBy) {

                                                                                if (query.orderBy.field == 'invoiceRefNumber') {
                                                                                    url += '&sortField=invoiceRefNumber'
                                                                                    setCookie("orderBy", "invoiceCustomerFullName", { path: '/WaitingOnRelease' });
                                                                                }
                                                                                else {
                                                                                    url += '&sortField=' + query.orderBy.field
                                                                                    setCookie("orderBy", query.orderBy.field, { path: '/WaitingOnRelease' });


                                                                                }
                                                                                url += '&sortType=' + query.orderDirection
                                                                                setCookie("direction", query.orderDirection, { path: '/WaitingOnRelease' });
                                                                            } else {
                                                                                switch (2) {
                                                                                    case '2':
                                                                                        url += '&sortField=invoicePaidDate';
                                                                                        url += '&sortType=desc';
                                                                                        url += '&sortField=invoiceCustomerFullName';
                                                                                        url += '&sortType=asc';
                                                                                        url += '&sortField=resalePORecordID';
                                                                                        url += '&sortType=asc';
                                                                                        break;
                                                                                    case '7':
                                                                                        url += '&sortField=invoicePaidDate';
                                                                                        url += '&sortType=asc';
                                                                                        url += '&sortField=invoiceCustomerFullName';
                                                                                        url += '&sortType=asc';
                                                                                        url += '&sortField=resalePORecordID';
                                                                                        url += '&sortType=asc';
                                                                                        break;
                                                                                    case '15':
                                                                                        url += '&sortField=invoicePaidDate';
                                                                                        url += '&sortType=asc';
                                                                                        url += '&sortField=invoiceCustomerFullName';
                                                                                        url += '&sortType=asc';
                                                                                        url += '&sortField=resalePORecordID';
                                                                                        url += '&sortType=asc';
                                                                                        break;
                                                                                    case '10':
                                                                                        url += '&sortField=transportPOScheduledDeliveryDate';
                                                                                        url += '&sortType=asc';
                                                                                        url += '&sortField=invoiceCustomerFullName';
                                                                                        url += '&sortType=asc';
                                                                                        url += '&sortField=resalePORecordID';
                                                                                        url += '&sortType=asc';
                                                                                        break;
                                                                                    default:
                                                                                        url += '&sortField=invoiceTxnDate';
                                                                                        url += '&sortType=asc';
                                                                                        url += '&sortField=invoiceCustomerFullName';
                                                                                        url += '&sortType=asc';
                                                                                        url += '&sortField=resalePORecordID';
                                                                                        url += '&sortType=asc';
                                                                                }
                                                                            }

                                                                            if (query.search) {
                                                                                url += '&search=' + encodeURIComponent(query.search);
                                                                            }

                                                                            if (query.filters && !query.search) {
                                                                                query.filters.forEach((item) => {
                                                                                    url += '&search=' + encodeURIComponent(item.value);
                                                                                });
                                                                            }

                                                                            if (props.endDate && props.startDate) {
                                                                                url += '&from=' + props.startDate;
                                                                                url += '&to=' + props.endDate;
                                                                            }

                                                                            axios.get(url, {
                                                                                headers: {
                                                                                    Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN_NAME),
                                                                                },
                                                                            }).then(result => {
                                                                                resolve({
                                                                                    data: result.data.rows.map((data) => ({
                                                                                        orderStatusRecordID: data.recordID,
                                                                                        RecordID: data.invoiceRecordID,
                                                                                        Customer: `${data.invoiceCustomerFullName.replace(',', ' ')}*${data.invoiceRefNumber}`,
                                                                                        InvoiceRefNumber: data.invoiceRefNumber,
                                                                                        InvoiceDate: (data.invoiceTxnDate) ? data.invoiceTxnDate?.split('T')[0] : '',
                                                                                        PaidDate: (data.invoicePaidDate) ? data.invoicePaidDate?.split('T')[0] : '',
                                                                                        Product: data.invoiceLineItemItemNameWithoutParent,
                                                                                        DriverName: (data.planning) ? data.planning.driverName : null,
                                                                                        vendorName: (data.purchaseOrder) ? data.purchaseOrder.vendor : null,
                                                                                       
                                                                                        AssignedTo: data.invoiceAssignedToName,
                                                                                        invoiceCustomerNotes: data?.invoiceCustomerNotes,
                                                                                        invoiceTerms: data.invoiceTerms,
                                                                                        Balance: (data.invoiceBalanceRemaining) ? data.invoiceBalanceRemaining : '',
                                                                                        SupplierLocation: data.invoiceSupplierLocation,
                                                                                        ResalePO: data.resalePORefNumer,
                                                                                        Resale: data.resalePOReleaseNumber,
                                                                                        invoiceShipAddress1: data.invoiceShipAddress1,
                                                                                        invoiceShipAddress2: data.invoiceShipAddress2,
                                                                                        invoiceShipAddress3: data.invoiceShipAddress3,
                                                                                        invoiceShipCity: data.invoiceShipCity,
                                                                                        invoiceShipZip: data.invoiceShipZip,
                                                                                        resalePOPickupAddress1: data.resalePOPickupAddress1,
                                                                                        resalePOPickupCity: data.resalePOPickupCity,
                                                                                        resalePOPickupZipCode: data.resalePOPickupZipCode,
                                                                                        ResalePORecordID: data.resalePORecordID,
                                                                                        ReleaseDate: (data.resalePOReleaseDate) ? data.resalePOReleaseDate?.split('T')[0] : '',
                                                                                        invoiceIsExpedited: data.invoiceIsExpedited
                                                                                        ? "Yes"
                                                                                        : "No",

                                                                                        shipCity: (data.invoiceShipCity) ? data.invoiceShipCity : '',
                                                                                        shipState: (data.invoiceShipState) ? data.invoiceShipState : '',
                                                                                        solidSurface: (data.invoice?.solidSurface) ? data.invoice.solidSurface : 0,
                                                                                        sameTrailerMarker: data.sameTrailerMarker,

                                                                                        invoiceShipAddress1: data.invoiceShipAddress1,
                                                                                        invoiceShipAddress2: data.invoiceShipAddress2,
                                                                                        invoiceShipAddress3: data.invoiceShipAddress3,
                                                                                        invoiceShipCity: data.invoiceShipCity,
                                                                                        invoiceShipZip: data.invoiceShipZip,
                                                                                        resalePOPickupAddress1: data.resalePOPickupAddress1,
                                                                                        resalePOPickupCity: data.resalePOPickupCity,
                                                                                        resalePOPickupZipCode: data.resalePOPickupZipCode,
                                                                                        ResaleTotal: data.resalePOTotalPrice,
                                                                                        Expected: (data.resalePOExpectedDate) ? data.resalePOExpectedDate?.split('T')[0] : '',
                                                                                        ExpectedDeliveryDate: (data.resalePOExpectedDate) ? data.resalePOExpectedDate?.split('T')[0] : '',

                                                                                        invoiceLineItem_RecordID: data.invoiceLineItem_RecordID,
                                                                                        towTruck: (data.invoice?.towTruck) ? data.invoice.towTruck : 0,

                                                                                        DeliveryStatus: data.transportPODeliveryStatus,
                                                                                        PromisedTimeline: (data.invoicePromisedTimeline) ? data.invoicePromisedTimeline : '',
                                                                                        PromisedDate: (data.invoicePromisedDate) ? data.invoicePromisedDate?.split('T')[0] : '',
                                                                                    })),
                                                                                    page: result.data.currentPage - 1,
                                                                                    totalCount: result.data.totalItems,
                                                                                });
                                                                                setPageRefresh(false);
                                                                            })
                                                                        }
                                                                    })
                                                                }
                                                                options={{
                                                                    thirdSortClick: false,

                                                                    rowStyle: rowData => ({
                                                                        backgroundColor: (rowData.invoiceTerms == "Rent To Own" && rowData.towTruck == 1 ) ? '#F7AE77' : (rowData.invoiceTerms == "Rent To Own" && rowData.solidSurface == 1 ) ? '#90EE90' : (rowData.towTruck == 1 && rowData.solidSurface == 1 ) ? '#F0BBF0' : (rowData.invoiceTerms == "Rent To Own") ? '#ffda73' : (rowData.solidSurface == 1) ? '#7bede9' : (rowData.towTruck == 1) ? 'pink' : '#fff'
                                                                    }),

                                                                    pageSize: 50,
                                                                    debounceInterval: 1500,
                                                                    pageSizeOptions: [50, 100, 200],
                                                                    filtering: true
                                                                }}

                                                                components={{
                                                                    Toolbar: props => (
                                                                        <ResetFillterButton elsement={props} resetFilter={resetFilter} />
                                                                    ),
                                                                }}
                                                            />
                                                        </div>

                                                    </div>
                                                </div>

                                            </div>

                                            <Footer />

                                            <style jsx="true">{`
                                            
                                                .title-strong {
                                                    font-weight: 550;
                                                }

                                                .btn-set{
                                                    position: absolute;
                                                    top: 18px;
                                                    font-size: 17px;
                                                    right: 255px;
                                                    color: #000;
                                                    padding: 4px 6px;
                                                    border: 1px solid #79a3ff;
                                                }

                                                .input-wid-set {
                                                    border: none;
                                                    border-bottom: 1px solid #949494;
                                                    position: relative;
                                                    top: 3px;
                                                    width: 100%;
                                                }
                                                
                                                .MuiTableCell-alignLeft {
                                                    z-index:unset !important;
                                                    
                                                }
                                                .MuiTableCell-root{
                                                    padding: 2px !important;
                                                    font-family: 'Source Sans Pro', sans-serif;
                                                }

                                                .MuiTableCell-root a {
                                                    font-size:12px !important;
                                                    font-family: 'Source Sans Pro', sans-serif;
                                                    overflow: visible;
                                                    display: block;
                                                    text-overflow: clip;
                                                    word-break: break-word;
                                                    white-space: break-spaces!important;
                                                
                                                }
                                                tr td:first-child.MuiTableCell-root a, tr th:first-child span.MuiButtonBase-root.MuiTableSortLabel-root {
                                                    width: 100%!important;
                                                }

                                                .MuiTableHead-root th {
                                                    font-size: 12px !important;
                                                    font-weight: 600;
                                                    background: #fbf5f5;
                                                }

                                                .MuiTableCell-alignLeft a {
                                                    // white-space: pre !important;
                                                    white-space: pre-line !important;
                                                }
                                                // .MuiTableHead-root th:nth-child(8) {
                                                //     width: 120px!important;
                                                // }
                                                .MuiTableCell-root a {
                                                    width: 45px;
                                                }

                                                th.MuiTableCell-root.MuiTableCell-head.jss13.MuiTableCell-alignLeft:nth-child(2) {
                                                    width: 70px!important;
                                                }
                                                
                                                th.MuiTableCell-root.MuiTableCell-head.jss13.MuiTableCell-alignLeft:nth-child(3) {
                                                    width: 70px!important;
                                                }
                                                
                                                .MuiTableCell-root.MuiTableCell-head.MTableHeader-header-13.MuiTableCell-alignLeft:nth-child(6) {
                                                    width: 174px!important;
                                                }
                                                th.MuiTableCell-root.MuiTableCell-head.jss13.MuiTableCell-alignLeft:nth-child(7) {
                                                    width: 65px!important;
                                                }
                                                th.MuiTableCell-root.MuiTableCell-head.jss13.MuiTableCell-alignLeft:nth-child(15) {
                                                    width: 80px!important;
                                                }
                                                th {
                                                    text-align: inherit;
                                                    white-space: nowrap;
                                                }
                                                td.MuiTableCell-root.MuiTableCell-body.MuiTableCell-alignLeft a {
                                                    width:100%;
                                                }
                                                @media (max-width: 1920px){
                                            span.MuiButtonBase-root.MuiTableSortLabel-root {
                                                width: 100%;
                                            }
                                        }
                                            `}</style>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withRouter(WaitingOnRelease);