import React, { useState } from "react";
import "./App.css";
import LoginForm from "./components/LoginForm/LoginForm";
import AddUser from "./components/AddUser/AddUser";
import UserList from "./components/AddUser/UserList";
import supplierLocation from "./components/orderStatus/SupplierLocation";
import Order from "./components/orderStatus/Order";
import vendor from "./components/orderStatus/Vendor";
import VendorDetails from "./components/orderStatus/VendorDetails";
import EditContact from "./components/orderStatus/EditContact";
import EditVendor from "./components/orderStatus/EditVendor";
import AddVendor from "./components/orderStatus/AddVendor";
import Templatepdf from "./components/PurchaseOrder/pdf/Templatepdf";
import AddContact from "./components/orderStatus/AddContact";
import NeedsDispatch from "./components/orderStatus/NeedsDispatch";

import Planned from "./components/Planned/PlannedLayout";
import Needsplanned from "./components/Needsplanned/NeedsplannedLayout";

import WelcomeCallNeeded from "./components/orderStatus/WelcomeCallNeeded";
import Invoice from "./components/orderStatus/Invoice";
import InvoiceOrder from "./components/Invoice/InvoiceOrder";
import Allorders from "./components/AllOrders/Allorders";
import Allorders2 from "./components/AllOrders2/Allorders2";
import Designer from "./components/orderStatus/Designer";
import RegistrationForm from "./components/RegistrationForm/RegistrationForm";
import Planning from "./components/Planning/Planning";
import SetPassword from "./components/RegistrationForm/SetPassword";
import ForgotPassword from "./components/ForgotPassword/ForgotPassword"
import ResetPasswordLayout from "./components/ResetPassword/ResetPasswordLayout"
import Home from "./components/Home/Home";
import Admin from "./components/Admin/Admin";
import Dashboard from "./components/Dashboard/Dashboard";
import PurchaseOrder from "./components/PurchaseOrder/PurchaseOrder";
import ResalePurchaseOrder from "./components/ResalePurchaseOrder/ResalePurchaseOrder";
import DispachedLayout from "./components/DispachedPurchaseOrder/DispachedLayout";
import DisqualifiedPlanneOrdersLayout from "./components/DisqualifiedPlanneOrders/DisqualifiedPlanneOrdersLayout";
import CompletedLayout from "./components/CompletedPurchaseOrder/CompletedLayout";
import DeliveredLayout from "./components/Delivered/DeliveredLayout";
import DriverPlanning from "./components/DriverPlanning/DriverPlanning";
import driverTable from "./components/Driver/driverTable";
import ReportsLayout from "./components/Reports/ReportsLayout";

import EditUser from "./components/EditUser/EditUser";
import { PrivateRoute, IsLogginRoute } from "./utils/PrivateRoute";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import AlertComponent from "./components/AlertComponent/AlertComponent";
import WaitingOnRelease from "./components/orderStatus/WaitingOnRelease";
import Delivered from "./components/orderStatus/Delivered";
import DispachedDepotLayout from "./components/DispatchedDepot/DispatchedDepotLayout";
import Stopwatch from "./components/stopwatch";

function App() {
  const [title, updateTitle] = useState(null);
  const [errorMessage, updateErrorMessage] = useState(null);
  return (
    <Router>
      <div className="App">
       
        <div className="">
       
          <Switch>
            <Route path="/" exact={true} component={LoginForm} />
            <Route path="/setPassword/:token" exact component={SetPassword} />
            <Route path="/ChangePassword/:token" exact component={SetPassword} />
            <Route path="/ForgotPassword" exact component={ForgotPassword} />
            <Route path="/resetpassword" exact component={ResetPasswordLayout} />
            <PrivateRoute path="/dashboard" exact component={Dashboard} />
            <PrivateRoute path="/home/addUser" exact component={RegistrationForm} updateTitle={updateTitle} />
            <PrivateRoute path="/home/supplierLocation" exact component={supplierLocation} />
            <PrivateRoute path="/vendor" exact component={vendor} />
            <PrivateRoute path="/orderstatus" exact component={Allorders} />
            <PrivateRoute path="/orderstatus2" exact component={Allorders2} />
            <PrivateRoute path="/driver" exact component={driverTable} />
            <PrivateRoute path="/Planning" exact component={Planning} />
            <PrivateRoute path="/driverPlanning" exact component={DriverPlanning} />
            <PrivateRoute path="/purchaseorder/:id" exact component={PurchaseOrder} />
            <PrivateRoute path="/exitingPurchaseorder/:id/:recordID" exact component={PurchaseOrder} />
            <PrivateRoute path="/resalePurchaseOrder/:id/:invoiceRecordID" exact component={ResalePurchaseOrder} />
            <PrivateRoute path="/vendordetails/:id" component={VendorDetails} />
            <PrivateRoute path="/contactedit/:id" exact component={EditContact} />
            <PrivateRoute path="/addvendor" exact component={AddVendor} />
            <PrivateRoute path="/templatepdf/:id" exact component={Templatepdf} />
            <PrivateRoute path="/addcontact/:id" exact component={AddContact} />
            <PrivateRoute path="/needsdispatch" exact component={NeedsDispatch} />
            <PrivateRoute path="/needsplanned" exact component={Needsplanned} />
            <PrivateRoute path="/planned" exact component={Planned} />
            <PrivateRoute path="/welcomeCallNeeded" exact component={WelcomeCallNeeded} />
            <PrivateRoute path="/WaitingOnRelease" exact component={WaitingOnRelease} />
            <PrivateRoute path="/delivered" exact component={Delivered} />
            <PrivateRoute path="/delivered/:filter" exact component={Delivered} />
            <PrivateRoute path="/invoice/:id/:invoiceLineItem_RecordID" exact component={InvoiceOrder} />
            <PrivateRoute path="/orders" exact component={Designer} />
            <PrivateRoute path="/dispatched" exact component={DispachedLayout} />
            <PrivateRoute path="/dispatcheddepot" exact component={DispachedDepotLayout} />
            <PrivateRoute path="/disqualifiedPlanneOrders" exact component={DisqualifiedPlanneOrdersLayout} />
            <PrivateRoute path="/completed" exact component={CompletedLayout} />
            <PrivateRoute path="/vendoredit/:id" component={EditVendor} />
            <Route path="/home/order" exact component={Order} />
            <PrivateRoute path="/home/user" exact component={AddUser} />
            <PrivateRoute path="/userlist" exact component={UserList} />
            <PrivateRoute path="/users/edit/:id" component={EditUser} />
            <PrivateRoute path="/admin" exact component={Admin} />
            <PrivateRoute path="/login" exact component={LoginForm} updateTitle={updateTitle} />
            <PrivateRoute path="/home" exact component={Home} />
            <PrivateRoute path="/reports" exact component={ReportsLayout} />

          </Switch>
          <AlertComponent errorMessage={errorMessage} hideError={updateErrorMessage} />
        </div>
      </div>
    </Router>
  );
}

export default App;
