import { useField } from 'formik';
import PropType from 'prop-types';
import React from 'react';
import CreatableSelect from 'react-select/creatable';

const CustomSelect = (props) => {
  const [field, meta, helpers] = useField(props);
  const {
    options, valueinfo,  defaultValue, label, placeholder, isMulti, type, iid
  } = props;
  const { touched, error } = meta;
  const { setValue } = helpers;

  const handleChange = (newValue) => {
    props.fun(true)
    if (Array.isArray(newValue)) {
      const arr = newValue.map((fieldKey) => fieldKey.value);
      setValue(arr);
    } else {
      setValue(newValue.value);
    }
  };

  const handleKeyDown = (e) => {
    if (type === 'number') {
      const { key } = e.nativeEvent;
      if (/\D/.test(key) && key !== 'Backspace') {
        e.preventDefault();
      }
    }
  };

  console.log(valueinfo,'valueinfo');

  return (
    <div className="form-group">
      <label htmlFor={field.name}>{label}</label>
      <CreatableSelect
        isMulti={isMulti}
        placeholder={placeholder}
        name={field.name}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        defaultValue={options.find((element)=> element.value == valueinfo)}
        options={options}
        instanceId={iid}
      />

        {touched && error ? (
          <small style={{ color: 'red' }}>{error}</small>
        ) : null}
  
    </div>
  );
};

CustomSelect.defaultProps = {
  isMulti: false,
  placeholder: '',
  iid: '',
  options: [],
  type: 'string'
};

CustomSelect.propTypes = {
  options: PropType.arrayOf(PropType.object),
  defaultValue: PropType.oneOfType([
    PropType.object,
    PropType.array
  ]).isRequired,
  label: PropType.string.isRequired,
  placeholder: PropType.string,
  isMulti: PropType.bool,
  type: PropType.string,
  iid: PropType.string
};

export default CustomSelect;
