import React, { useState, useEffect } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { ACCESS_TOKEN_NAME, ACCESS_TOKEN_NAME_USER, API_BASE_URL } from '../../constants/apiConstants';
import axios from 'axios'
import "datatables.net-dt/js/dataTables.dataTables";

import "datatables.net-dt/css/jquery.dataTables.min.css";
import moment from 'moment';
import $ from 'jquery';
import Sidebar from "./../Layout/Sidebar";
import Footer from "./../Layout/Footer";
import MaterialTable from "material-table";
import logo from '../../images/logo.png';
import profilepic from '../../images/avatar-4.jpg';
import './custom.css';
import DatePicker from "react-datepicker";
//import "react-datepicker/dist/react-datepicker.css";
import PlanningServices from '../DriverPlanning/PlanningServices';
import PurchaseOrderServices from '../PurchaseOrder/Services/PurchaseOrderServices';
import Avatar from 'react-avatar';
import { ToastContainer, toast } from 'react-toastify';
import avoidNull from '../../utils/AvoidNull';
import avoidNull2 from '../../utils/AvoidNull2';
import removeAddress from '../../utils/RemoveAddress';
import DataTable from '../Shared/DataTable';
import SyncComponent from '../Shared/SyncComponent';


function NeedsDispatch(props) {
    const [orderData, setOrderData] = useState();
    const [filterData, setFilterData] = useState("7");
    const [key, setKey] = useState();

    const [display, setdisplay] = useState('none');

    const [selectedStatus, setselectedStatus] = useState();
    const [disptachedOrder, setdisptachedOrder] = React.useState([]);

    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();

    const [despatchLoading, setDespatchLoading] = useState(false);

    const [rowsPerPage, setRowsPerPage] = useState({
        data: ""
    });
    const [pageNameList, setPageNameList] = useState('Needs Dispatch')
    
    const [refreshCount, setrefreshCount] = useState(0);


    useEffect(() => {
        $(document).ready(function () {
            setTimeout(function () {
                $('#myTable').DataTable({
                    "scrollX": true,
                    "order": [[3, "desc"]]
                });
            }, 1000);
        });
    }, []);

    const fetchNeedToDispatch = async () => {
        try {
            const result = await PlanningServices.findOrderIdDetails();
            setdisptachedOrder(result);
        } catch (error) {
            console.log(error, 'fetchNeedToDispatch');
        }
    }

    const handleChangeRowsPerPage = (e) => {
        console.log('pagination', e.target.value);
        if (e.target.value === "All") {
            setRowsPerPage({ pageSize: orderData.length });
        } else {
            setRowsPerPage({ pageSize: e.target.value });
        }
    };

    const handleChange = (e) => {
        setFilterData(e.target.value);
        setKey(e.target.value);
    }


    const handleView = () => {
        alert('function clicked');
    }

    const [loading, setLoading] = useState(false);

    const fetchSingleData = async (value) => {

        const token = localStorage.getItem(ACCESS_TOKEN_NAME);

        if (value == 0) {
            setLoading(true);
            let results = await axios.get(API_BASE_URL + "/findAllOrder", {
                headers: {
                    Authorization: "Bearer " + token,
                },
            });
            setLoading(false);
            setOrderData(results.data);
        } else {
            setLoading(true);
            const results = await axios.get(API_BASE_URL + `/findSingleOrder?status=${value}`,
                {
                    headers: {
                        Authorization: "Bearer " + token,
                    },
                });
            setLoading(false);
            setOrderData(results.data);
        }

        setselectedStatus(value);
    }

    const doDispatch = async (invoiceRefnumber, product, recordID, depotCall) => {
        if(depotCall == 1)
        {
            setDespatchLoading(true);
        const today = new Date();
        var days = ['Sun', 'Mon', 'Tus', 'Wed', 'Thu', 'Fri', 'Sat'];
        var months = new Array("Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sep", "Oct", "Nov", "Dec");
        var orders = disptachedOrder.filter((item) => item.invoiceRefNumber == invoiceRefnumber && item.invoicespo_record_id == recordID);

        const payload = {
            contactId: 99434,
            locationId: 0,
            customerConfirmation: 0,
            driveConfirmation: 0,
            size: (orders[0]) ? orders[0].invoiceLineItemItemName : '',
            invoiceId: recordID,
            City: (orders[0]) ? orders[0].City : '',
            drivername: 'DispatchFromInvoie',
            product: (orders[0]) ? orders[0].invoiceLineItemItemNameWithoutParent : '',
            State: (orders[0]) ? orders[0].State : '',
            DropState: (orders[0]) ? orders[0].DropState : '',
            DropCity: (orders[0]) ? orders[0].DropCity : '',
            Dist: (orders[0]) ? orders[0].Dist : '',
            plannedStatus: 0,
            resalePORefNumer: (orders[0]) ? orders[0].resalePORefNumer : '',
            isExpedited: (orders[0]) ? orders[0].invoiceIsExpedited : '',
            date: days[today.getDay()] + ' ' + today.getDate() + ' ' + months[today.getMonth()]
            //date:""
        };

        const result = await PlanningServices.fetchPlanningByRecordID(recordID);

        if (result.length == 1) {
            window.open("/purchaseorder/" + result[0].id);
            setDespatchLoading(false);
        } else {
            const resultinfo = await PlanningServices.savePlanning(payload);
            setDespatchLoading(false);
            window.open("/purchaseorder/" + resultinfo[0].lastinsterid);
        }
        }
        else
        {
            setTimeout(() => {
                toast.error(`Depot call is not completed`, {
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });
            });
        }
        
    }

    const handleSubmitSearch = (e) => {
        e.preventDefault();
        setKey(Math.floor(Math.random() * 10));
    }

    useEffect(() => {
        //this is hardcode
        //fetchSingleData(7);
        setselectedStatus(7);
        //fetchNeedToDispatch();
    }, []);



    function handleLogout() {
        localStorage.removeItem(ACCESS_TOKEN_NAME);
        localStorage.removeItem('role');
        localStorage.removeItem('user');
        localStorage.removeItem(ACCESS_TOKEN_NAME_USER);
        props.history.push("/");
    }


    
    const ingString = (string) => {
        let value = string;
        try {
            if (value && value != '') {
                return `${string},`;
            }
            else {
                return '';
            }
        } catch (err) {
            console.log(err);
            return err;
        }
    };

    function refrencePage(){
        setrefreshCount(refreshCount+1);
    }
    
console.log(orderData?.filter(data=>data.invoiceRefNumber==197831),'1111');
    return (

        <div id="pcoded" className="pcoded">
            <div className="pcoded-overlay-box"></div>
            <div className="pcoded-container navbar-wrapper">

                {/* <Navbar /> */}
                <nav className="navbar header-navbar pcoded-header" style={{ height: '55px', minHeight: '26px' }}>
                    <div className="navbar-wrapper">
                        <div className="navbar-logo" style={{ height: '52px' }}>
                            <a style={{ top: '7px' }} className="mobile-menu navabar_menu" id="mobile-collapse" href="#!">
                                <i class="ti-menu" style={{ "color": " #000", "font-size": "22px;" }}></i>
                            </a>
                            <a className="mobile-search morphsearch-search" href="#">
                                <i className="ti-search"></i>
                            </a>
                            <Link to="/dashboard">
                                <img style={{ width: '30%' }} className="img-fluid logo-img" src={logo} alt="Theme-Logo" />
                            </Link>
                            <a className="mobile-options">
                                <i className="ti-more"></i>
                            </a>
                        </div>

                        <SyncComponent setPageRefresh={refrencePage}   pageNameList={pageNameList} />
                    </div>
                </nav>


                <div className="pcoded-main-container">
                    <div className="pcoded-wrapper">

                        <Sidebar />

                        <div className="pcoded-content page_content">
                            <div className="pcoded-inner-content">
                                <div className="main-body">
                                    <div className="page-wrapper">

                                        <div className="page-body">
                                            {
                                                /*
                                                    <div className="row">
                                                        <div className="col-md-8 col-xl-8">
                                                            <h4 style={{ float: "left", marginTop: "6px", fontSize: "large", marginLeft: "15px"}}>Needs Dispatch</h4>
                                                        </div>
                                                    </div>
                                                */
                                            }



                                            <div className="col-md-12 tabs-ui p-0">
                                            <div className='row justify-content-center align-items-center color-choose'>
                                               <h5 className='cstmstates'><span>RTO</span><span className='statecolor rto'></span></h5>
                                               <h5 className='cstmstates'><span>Solid Surface</span><span className='statecolor solid_surface'></span></h5>      
                                               <h5 className='cstmstates'><span>RTO on Solid Surface</span><span className='statecolor rto_ss'></span></h5>  
                                               <h5 className='cstmstates'><span>Tow Truck</span><span className='statecolor solid_tow'></span></h5>      
                                               <h5 className='cstmstates'><span>RTO + Tow Truck</span><span className='statecolor rto-two'></span></h5>    
                                               <h5 className='cstmstates'><span>Solid Surface + Tow Truck</span><span className='statecolor solid-two'></span></h5>      
                                            </div>
                                                {/* <nav>
                                                    <div className="nav nav-tabs" id="nav-tab" role="tablist">
                                                        <a className="nav-item nav-link active" id="nav-home-tab" data-toggle="tab" href="#nav-home" role="tab" aria-controls="nav-home" aria-selected="true">Open Order</a>
                                                        <a className="nav-item nav-link" id="nav-profile-tab" data-toggle="tab" href="#nav-profile" role="tab" aria-controls="nav-profile" aria-selected="false">Need Dispatch</a>
                                                        <a className="nav-item nav-link" id="nav-contact-tab" data-toggle="tab" href="#nav-contact" role="tab" aria-controls="nav-contact" aria-selected="false">Assign Order</a>
                                                    </div>
                                                </nav> */}
                                                <div className="tab-content" id="nav-tabContent">
                                                    <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">

                                                        <div className="row" style={{ marginLeft: "0px" }}>
                                                            <div className="col-md-4 col-xl-4" >
                                                                <select id="orderListSelection" className="form-control" value={filterData} onChange={handleChange}>
                                                                    <option value="0">All</option>
                                                                    <option value="1">Has Not Paid</option>
                                                                    <option value="2">Welcome Call Needed</option>
                                                                    <option value="16">Needs Planned</option>
                                                                    <option value="3">Follow Up Call Needed</option>
                                                                    <option value="4">Needs To Buy</option>
                                                                    <option value="5">Waiting On Release</option>
                                                                    <option value="6">Inventory Not Paid</option>
                                                                    <option selected value="7">Needs Dispatch</option>                               
                                                                    <option value="15">Planned</option>
                                                                    <option value="8">Customer Pickup</option>
                                                                    <option value="9">Expedited Sales</option>
                                                                    <option value="10">Dispatched</option>
                                                                    <option value="11">Completed</option>
                                                                    <option value="12">Inventory</option>
                                                                    <option value="13">Cancelled</option>
                                                                    <option value="14">On Hold</option>

                                                                </select>
                                                            </div>
                                                            <div className="col-md-8 col-xl-8">
                                                                <form>
                                                                    <div className="row">
                                                                        <div className="col-md-3">
                                                                            <div className="form-group text-left">
                                                                                <DatePicker placeholderText="From" className="form-control" selected={startDate} onChange={(date) => setStartDate(date)} />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-3">
                                                                            <div className="form-group text-left">
                                                                                <DatePicker placeholderText="To" selected={endDate} className="form-control" onChange={(date) => setEndDate(date)} />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-2">
                                                                            <div className="form-group text-left">
                                                                                <button
                                                                                    type="submit"
                                                                                    className="btn btn-primary btn-sm"
                                                                                    onClick={handleSubmitSearch}
                                                                                >
                                                                                    Submit
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </form>
                                                            </div>

                                                        </div>
                                                        <DataTable refreshCount={refreshCount} endDate={endDate} startDate={ startDate } key={key} despatchLoading={despatchLoading} doDispatch={doDispatch} filterData={filterData} />
                                                    </div>
                                                </div>

                                            </div>

                                            <Footer />

                                            <style jsx="true">{`
                                                .title-strong {
                                                    font-weight: 550
                                                }

                                                .MuiTableCell-alignLeft {
                                                    z-index:unset !important;
                                                }

                                                .MuiTableCell-root{
                                                    padding: 2px !important;
                                                    font-family: 'Source Sans Pro', sans-serif;
                                                }
                                                .MuiTableCell-root .breakword {
                                                    font-size: 12px !important;
                                                    font-family: 'Source Sans Pro', sans-serif;
                                                    overflow-wrap: anywhere;
                                                    white-space: pre-line !important;
                                                  }
                                                  
                                                .MuiTableHead-root th {
                                                    font-size: 12px !important;
                                                    font-weight: 600;
                                                    background: #fbf5f5;
                                                }
                                                .MuiInputBase-input {
                                                    font: inherit;
                                                    color: currentColor;
                                                     
                                                    border: 0;
                                                    height: 1.1876em;
                                                    margin: 0;
                                                    display: block;
                                                    padding: 6px 0 7px;
                                                    min-width: 0;
                                                    background: none;
                                                    box-sizing: content-box;
                                                    animation-name: mui-auto-fill-cancel;
                                                    letter-spacing: inherit;
                                                    animation-duration: 10ms;
                                                    -webkit-tap-highlight-color: transparent;
                                                }
                                               
                                                a.breakword {
                                                    white-space: break-spaces;
                                                    word-break: break-word;
                                                }
                                                .MuiTableCell-root a {
                                                    font-size:12px !important;
                                                    font-family: 'Source Sans Pro', sans-serif;
                                                    overflow: visible;
                                                    display: block;
                                                    text-overflow: clip;
                                                    word-break: break-word!important;
                                                    white-space: break-spaces!important;            
                                                }
                                                // .MuiTableHead-root th:nth-child(8) {
                                                //     width: 129px!important;
                                                // }
                                                .MuiTableCell-root {
                                                    width: fit-content;
                                                }
                                                .MuiTableCell-root a {
                                                    width: 100%;
                                                }
                                                tr td:first-child.MuiTableCell-root a, tr th:first-child span.MuiButtonBase-root.MuiTableSortLabel-root {
                                                    width: 100%!important;
                                                }
                                            `}</style>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </div>

    )
}

export default withRouter(NeedsDispatch);