import React, { useState, useEffect } from "react";

import axios from "axios";

import { Route, Link } from "react-router-dom";

import { API_BASE_URL, ACCESS_TOKEN_NAME, ACCESS_TOKEN_NAME_USER } from "../../constants/apiConstants";

import { ToastContainer, toast } from 'react-toastify';

import "./AddUser.css";

import "../../assets/css/demo.css";

import "../../assets/css/sidebar-accordion.css";

import "../../images/icon/themify-icons/themify-icons.css";

import "../../images/js/script.js"

import "../../images/js/pcoded.min.js"

import "../../images/js/demo-12.js"

import "../../images/icon/icofont/css/icofont.css";

import logo from '../../images/logo.png';

import profilepic from '../../images/avatar-4.jpg';

import $ from 'jquery';

import "datatables.net-dt/js/dataTables.dataTables";

import "datatables.net-dt/css/jquery.dataTables.min.css";

import Navbar from "./../Layout/Navbar";

import Sidebar from "./../Layout/Sidebar";

import Footer from "./../Layout/Footer";

import DeactiveUserTable from './DeactiveUserTable';
import ActiveUserTable from './ActiveUserTable';
import Avatar from 'react-avatar';
import SyncComponent from "../Shared/SyncComponent";


const UserList = (props) => {

  useEffect(() => {
    const role = localStorage.getItem('role');
    // if (role == null || role !== '1') {
    //   props.history.push('/');
    // } else {
    //   props.history.push('/userlist');
    // }
  }, []);


  const [display, setdisplay] = useState('none');

  const [users, setUsers] = useState([]);
  const [deActiveUsers, setdeActiveUsers] = useState([]);
  const [pageNameList, setPageNameList] = useState('User')


  useEffect(() => {
    loadUser();
    findDeactiveUser();
  }, []);


  const loadUser = async () => {
    const token = localStorage.getItem(ACCESS_TOKEN_NAME);
    const result = await axios.get(API_BASE_URL + "/findAllUser", {
      headers: {
        Authorization: "Bearer " + token,
      },

    });
    setUsers(result.data);
  };


  const findDeactiveUser = async () => {
    const token = localStorage.getItem(ACCESS_TOKEN_NAME);
    const result = await axios.get(API_BASE_URL + "/findAllInActiveUser", {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    setdeActiveUsers(result.data);
  };



  const sendEmail = (email) => {

    axios.get(API_BASE_URL + `/sendSetPasswordEmail?email=${email}`, {

      headers: {

        Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN_NAME),

      },

    }).then((res) => {
      setTimeout(() => {
        toast.success("Email has been sent", {
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true
        });
      });
    })
  }



  const setUsersActive = (id) => {

    axios.get(API_BASE_URL + `/setUserActive?id=${id}`, {

      headers: {

        Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN_NAME),

      },

    }).then((result) => {
      setTimeout(() => {
        toast.success("User has been activated", {
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true
        });
      });

      setdeActiveUsers((old) => old.filter((item) => item.id != id));
      var removeObject = deActiveUsers.filter((item) => item.id == id)[0];
      setUsers((prevOrderState) => [...prevOrderState, removeObject]);

      loadUser();
    });

  };



  const setUsersInActive = (id) => {

    axios.get(API_BASE_URL + `/setUserInActive?id=${id}`, {

      headers: {
        Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN_NAME),
      },

    }).then((result) => {
      setTimeout(() => {
        toast.success("User has been Inactivated", {
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true
        });
      });

      setUsers((old) => old.filter((item) => item.id != id));
      var removeObject = users.filter((item) => item.id == id)[0];
      if (removeObject) {
        setdeActiveUsers((prevOrderState) => [...prevOrderState, removeObject]);
      } else {
        setdeActiveUsers([]);
      }

      loadUser();
    });

  };


  const [activeDeactive, setactiveDeactive] = useState(false);
  const handleCheck = async (e) => {
    let isChecked = e.target.checked;
    if (isChecked) {
      setactiveDeactive(true);
    } else {
      setactiveDeactive(false)
    }
  }



  function handleLogout() {

    localStorage.removeItem(ACCESS_TOKEN_NAME);

    localStorage.removeItem('role');

    localStorage.removeItem('user');

    localStorage.removeItem(ACCESS_TOKEN_NAME_USER);

    props.history.push("/");

  }



  return (

    <>

      <div id="pcoded" className="pcoded">

        <div className="pcoded-overlay-box"></div>

        <div className="pcoded-container navbar-wrapper">
          <nav className="navbar header-navbar pcoded-header" style={{ height: '55px', minHeight: '26px' }}>

            <div className="navbar-wrapper">

              <div className="navbar-logo" style={{ height: '52px' }}>

                <a style={{ top: '7px' }} className="mobile-menu navabar_menu" id="mobile-collapse" href="#!">

                  <i class="ti-menu" style={{"color":" #000", "font-size" : "22px;"}}></i>

                </a>

                <a className="mobile-search morphsearch-search" href="#">

                  <i className="ti-search"></i>

                </a>

                <Link to="/dashboard">

                  <img style={{width:'30%'}} className="img-fluid logo-img" src={logo} alt="Theme-Logo" />

                </Link>

                <a className="mobile-options">

                  <i className="ti-more"></i>

                </a>

              </div>



              <SyncComponent  pageNameList={pageNameList} />

            </div>

          </nav>



          <div className="pcoded-main-container">

            <div className="pcoded-wrapper">



              <Sidebar />
              <ToastContainer />



              <div className="pcoded-content page_content">

                <div className="pcoded-inner-content">

                  <div className="main-body">

                    <div className="page-wrapper">



                      <div className="page-body">

                        <div className="row">

                          <div className="col-md-8 col-xl-8">
                            {
                              /*
                                <h4 style={{ float: "left", marginTop: "20px", fontSize: "large" }}>Users</h4>
                              */
                            }
                          </div>

                          <div className="col-md-2 col-xl-2 pr-0">

                            <label className="switch float-right" style={{ marginTop: "5px" }}>

                              <input type="checkbox" id="togBtn" onChange={e => handleCheck(e)} />

                              <div className="slider round ">

                                <span className="on">Deactivate</span>

                                <span className="off">Activate</span>

                              </div>

                            </label>

                          </div>
                          {
                             localStorage.getItem('role') == 1 || localStorage.getItem('role') == 2 ?

                          <div className="col-md-2 col-xl-2" style={{ marginTop: "5px" }}>

                            <Link className="btn btn-primary float-right btn-sm" style={{ color: "#ffffff" }} to="/home/addUser">Add User</Link>

                          </div>
                          :
                          ''
}
                        </div>



                        <div className="col-md-12 tabs-ui p-0">

                          <div className="tab-content" id="nav-tabContent">

                            <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">

                              {
                                (activeDeactive) ?
                                  <DeactiveUserTable sendEmail={sendEmail} setUsersActive={setUsersActive} setUsersInActive={setUsersInActive} deActiveUser={deActiveUsers} />
                                  :
                                  <ActiveUserTable sendEmail={sendEmail} setUsersActive={setUsersActive} setUsersInActive={setUsersInActive} activeUser={users} />
                              }

                            </div>

                            <div className="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">2</div>

                            <div className="tab-pane fade" id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab">3</div>

                          </div>
                        </div>
                        <Footer />
                      </div>
                    </div>

                  </div>

                </div>

              </div>

            </div>

          </div>

        </div>
        <style jsx="true">{`
                                                table th, td{
                                                    font-family: 'Source Sans Pro', sans-serif;
                                                  }
                                                  table td a{
                                                      font-size:12px !important;
                                                  }
  
                                                  table th {
                                                      font-size: 12px !important;
                                                      font-weight: 600;
                                                      background: #fbf5f5;
                                                  }
                                                  .on{
                                                    color: white;
                                                    position: absolute;
                                                    transform: translate(-50%,-50%);
                                                    top: 50%;
                                                    left: 37% !important;
                                                    font-size: 10px;
                                                    font-family: Verdana, sans-serif;
                                                    padding-right:7px;
                                                }
                                                a.btn{
                                                  border:none;
                                                  border-radius:5px;
                                                }
                                                .off {
                                                  color: white;
                                                  position: absolute;
                                                  transform: translate(-50%,-50%);
                                                  top: 50%;
                                                  left: 52% !important;
                                                  font-size: 10px;
                                                  font-family: Verdana, sans-serif;
                                              }
                                            `}</style> 

      </div>



    </>

  );



};

export default UserList;