
import IdField from '../Shared/fields/idField';
import StringField from '../Shared/fields/stringField';
import DateField from '../Shared/fields/dateField';
import BooleanField from '../Shared/fields/booleanField';
import ImagesField from '../Shared/fields/imagesField';



const fields = {
  id: new IdField('id', 'id'),

  purchaseOrder: new StringField('purchase_order', 'Purchase Order #'),

  supplierVendor: new StringField('supplier_vendor', 'Supplier / Vendor Invoice #'),

  pickupLocation: new StringField('pickup_location', 'Pickup Location'),

  productService: new StringField('product_service', 'Product / Service to purchase'),

  vendor: new StringField('vendor', 'Vendor', {
    "required": true
  }),

  driver: new StringField('driver', 'Driver', {
    "required": true
  }),

  vendorid: new StringField('vendorid', 'vendorid'),

  releaseNumber: new StringField('release_number', 'Release Number', {
    "required": true,
    "max": 50
  }),

  date: new DateField('date', 'Date', {
    "required": true
  }),

  promiseDate: new DateField('promise_date', 'Promise Date'),

  shipTo: new StringField('ship_to', 'Ship To'),

  invoiceLinkedTo: new StringField('invoice_linked_to', 'Invoice Linked To'),

  resalePOLinkedTo: new StringField('resale_po_linked_to', 'Resale PO Linked To'),

  releaseDate: new StringField('release_date', 'Release Date', {
    "required": true
  }),

  customerPhone: new StringField('customer_phone', 'Customer Phone'),

  releasePOLinked: new StringField('release_po_linked', 'Release PO Linked'),

  supplierLocation: new StringField('supplier_location', 'Supplier Location'),

  actualPickupDate: new DateField('actual_pickup_date', 'Actual Pickup Date'),

  customerALtPhone: new StringField('customer_alt_phone', 'Customer Alt Phone'),

  shipedMehtod: new StringField('shiped_mehtod', 'Ship Method'),

  promiseTimeLine: new StringField('promise_time_line', 'Promise Time Line'),

  containerOrientation: new StringField('container_orientation', 'Container Orientation'),

  expectedDate: new StringField('expected_date', 'Expected Date'),

  scheduledDeleiveryDate: new StringField('scheduled_deleivery_date', 'Scheduled Delivery Date', {
    "required": true
  }),

  expected: new BooleanField('expedited', 'Expedited'),

  refounded: new BooleanField('refounded', 'Refunded'),

  depositcallCompleted: new BooleanField('deposit_call_completed', 'Depot call Completed'),

  from: new StringField('sfrom', 'From',{
    "required": true
  }),

  to: new StringField('sto', 'To',{
    "required": true
  }),

  deleiveryStatus: new StringField('deleivery_status', 'Delivery Status'),

  actualDeleiveryDate: new StringField('actual_deleivery_date', 'Actual Delivery Date'),

  billing: new StringField('billing', 'Biling'),

  shipping: new StringField('shipping', 'Shiping'),

  rate: new StringField('rate', 'Rate'),

  totalAmount: new StringField('totalAmount', 'Total Amount'),

  container: new StringField('container', 'Container'),

  memo: new StringField('memo', 'Memo (Internal)'),

  messages: new StringField('messages', 'Messages'),

  deliveryNotes: new StringField('deliveryNotes', 'Delivery Notes'),

  pickupNotes: new StringField('pickupNotes', 'Pickup Notes'),
  
  shipline1: new StringField('shipline1', 'shipline1'),
  shipline2: new StringField('shipline2', 'shipline2'),
  shipline3: new StringField('shipline3', 'shipline3'),
  shipline4: new StringField('shipline4', 'shipline4'),
  shipline5: new StringField('shipline5', 'shipline5'),
  shipcity: new StringField('shipcity', 'shipcity'),
  shipstate: new StringField('shipstate', 'shipstate'),
  shipzip: new StringField('shipzip', 'shipzip'),
  shipcountry: new StringField('shipcountry', 'shipcountry'),
  
  attachMent: new ImagesField(
    'attachment',
    'Attachment',
    (id) => `purchaseOrder/attachment/${id}`,
    { max: 1 },
  )
};

export default {
  fields,
};
