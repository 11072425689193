import React, { useEffect } from 'react';
import { Link, useLocation  } from "react-router-dom";
import $ from 'jquery'


function LeftNavbar({invoice , ordersDetailsLoading}) {
    useEffect(()=>{
        $(".navabar_menu1").click(function () {
            $("body").toggleClass("toggleNav1");
        });
    }, []);   

    const handlePoliciesClick = (recordID) => {
        window.open("/invoice/"+recordID);
    }

    return (
        <div className="sidebar_navbar1 contenedor-menu" style={{'top':'59px','overflow-y':'auto'}}>
            <table class="table">
            <thead>
                <tr style={{'text-align':'center'}} >
                    <th scope="col">Invoice</th>
                    <th scope="col">Customer</th>
                    <th scope="col">Product</th>
                    <th scope="col">Expedited</th> 
                    <th scope="col">Resale PO</th>
                    <th scope="col">Pick Up City</th>
                    <th scope="col">Drop Off City</th>
                    <th scope="col">Distance</th>
                </tr>
            </thead>
            <tbody>
                {
                    (ordersDetailsLoading) ? 
                        <h1>Loading................</h1>
                    :
                    invoice.map((element)=>
                        <tr>
                            <a onClick={()=>handlePoliciesClick(element.invoicespo_record_id)}  href="javascript:void(0)"><td style={{'padding':'0.75rem 0.75rem 0.75rem 0.75rem'}}>{element.invoiceRefNumber}</td></a>
                            <td style={{'padding':'0.75rem 0.75rem 0.75rem 0.75rem'}}>{element.customer}</td>
                            <td style={{'padding':'0.75rem 0.75rem 0.75rem 0.75rem'}}>{element.invoiceLineItemItemNameWithoutParent}</td>

                            <td style={{'padding':'0.75rem 0.75rem 0.75rem 0.75rem'}}>{(element.invoiceIsExpedited) ? 'E' : ''}</td>
                            
                            <td style={{'padding':'0.75rem 0.75rem 0.75rem 0.75rem'}}>{element.resalePORefNumer}</td>

                            <td style={{'padding':'0.75rem 0.75rem 0.75rem 0.75rem'}}>{element.City}</td>
                            <td style={{'padding':'0.75rem 0.75rem 0.75rem 0.75rem'}}>{element.DropCity}</td>
                            <td style={{'padding':'0.75rem 0.75rem 0.75rem 0.75rem'}}>{element.Dist}</td>
                        </tr>
                    )
                }
            </tbody>
            </table>
        </div>
    );
}

export default LeftNavbar;
