import { API_BASE_URL , ACCESS_TOKEN_NAME } from '../../../constants/apiConstants';
import axios from 'axios'

export default class PurchaseOrderServices {
  static async createOrUpdatePurchaseOrder(data) {
      const token = localStorage.getItem(ACCESS_TOKEN_NAME);
      const response = await axios.post(API_BASE_URL + `/createOrUpdatePurchaseOrder`, data , {
          headers: {
            Authorization: "Bearer " + token,
          },
      });
      return response.data;
  }

  static async updatePurchaseOrderStatus(orderid , statusId) {

    //var orderid = parseInt(orderid.replace("T", ""));
    
    const token = localStorage.getItem(ACCESS_TOKEN_NAME);
    const response = await axios.put(API_BASE_URL + `/updateOrderStatus?recordID=`+orderid, {orderStatus:statusId} , {
        headers: {
          Authorization: "Bearer " + token,
        },
    });
    return response.data;
  }


  static async updatePlanningOrderStatus(orderid , statusId) {
    const token = localStorage.getItem(ACCESS_TOKEN_NAME);
    const response = await axios.put(API_BASE_URL + `/updateOrderStatus?recordID=`+orderid, {orderStatus:statusId} , {
        headers: {
          Authorization: "Bearer " + token,
        },
    });
    return response.data;
  }


  static async notifyToDriver(orderid , driverid , date , deliveryNotes , messages) {
    const token = localStorage.getItem(ACCESS_TOKEN_NAME);
    const response = await axios.post(API_BASE_URL + `/emailNotification`, 
      {
        recordID : orderid,
        driverid : driverid,
        date : date,
        deliveryNotes,
        messages
      },
       
      {
        headers: {
          Authorization: "Bearer " + token,
      },
    });
    return response.data;

  }

  static async getPurchasedOrderStatus(invoice_id) {
    const token = localStorage.getItem(ACCESS_TOKEN_NAME);
    var response = await axios.get(API_BASE_URL + `/getPurchasedOrderStatus?recordID=`+invoice_id, {
        headers: {
          Authorization: "Bearer " + token,
        },
    });
    return response.data;
  }

  static async getPurchasedOrderByrecordID(recordID) {
    const token = localStorage.getItem(ACCESS_TOKEN_NAME);
    var response = await axios.get(API_BASE_URL + `/getPurchasedOrderByrecordID?recordID=`+recordID, {
        headers: {
          Authorization: "Bearer " + token,
        },
    });
    return response.data;
  }

  static async updateTransportPOStatus(purchase_recordid) {
    const token = localStorage.getItem(ACCESS_TOKEN_NAME);
    var response = await axios.get(API_BASE_URL + `/updateTransportPOStatus?recordID=`+purchase_recordid, {
        headers: {
          Authorization: "Bearer " + token,
        },
    });
    return response.data;
  }

  static async getContainerNumber(recordID) {
    const token = localStorage.getItem(ACCESS_TOKEN_NAME);
    var response = await axios.get(API_BASE_URL + `/getContainerNumber?recordID=`+recordID, {
        headers: {
          Authorization: "Bearer " + token,
        },
    });
    return response.data;
  }

  static async doAddBillingToMethod(data){
      const token = localStorage.getItem(ACCESS_TOKEN_NAME);
      const response = await axios.post(API_BASE_URL + `/finalizePurchaseOrder`, data , {
          headers: {
            Authorization: "Bearer " + token,
          },
      });
    return response.data;
  }

  static async doAddPurchaseOrderLineToMethod (data) {
    const token = localStorage.getItem(ACCESS_TOKEN_NAME);
    const response = await axios.post(API_BASE_URL + `/finalizePurchaseOrderLine`, data , {
        headers: {
          Authorization: "Bearer " + token,
        },
    });
    return response.data;
  }

  static async getFinalizeStatus(invoiceid) {
    const refNumber = invoiceid+'T';
    const token = localStorage.getItem(ACCESS_TOKEN_NAME);
    var response = await axios.get(API_BASE_URL + `/getFinalizeStatus?refNumber=`+refNumber, {
        headers: {
          Authorization: "Bearer " + token,
        },
    });
    return response.data;
  }
}

