import React from 'react'

function Footer() {
    return (
        <>
        <footer>
            <p>©2021, Container One</p>
        </footer>
        </>
    )
}
export default Footer;