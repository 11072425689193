import React, { useState, useEffect } from "react";
import $ from 'jquery';
import { Route, Link } from "react-router-dom";
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import ResetServices from '../ResetPassword/ResetServices';
import { ToastContainer, toast } from 'react-toastify';

function ActiveUserTable(props) {

  const [show, setShow] = useState(false);
  const [selectUser, setselectUser] = useState(null);

  const handleClose = () => setShow(false);
  const handleShow = (email) => {
    setShow(true);
    setselectUser(email);
  };
  console.log('checkActiveUser', props.activeUser)
  const initialValues = {
    resetpassword: "",
    conresetpassword: "",
  };

  const [values, setValues] = useState(initialValues);

  const initialError = {
    resetpassword: "",
    conresetpassword: "",
  };

  const [error, setError] = useState(initialError);

  const handleResetPassword = async (e) => {

    if(values.resetpassword == '') {
      setError((old)=>{
        return ({
          ...old,
          resetpassword:'The Password field is required'
        })
      })
    } else {
      setError((old)=>{
        return ({
          ...old,
          resetpassword:''
        })
      })
    }


    if(values.conresetpassword == '') {
      setError((old)=>{
        return ({
          ...old,
          conresetpassword:'The Confirm Password field is required'
        })
      })
    } else if(values.conresetpassword && values.resetpassword && values.conresetpassword != values.resetpassword) {
      setError((old)=>{
        return ({
          ...old,
          conresetpassword:'Confirm password must be the same as the password'
        })
      })
    } else {
      setError((old)=>{
        return ({
          ...old,
          conresetpassword:''
        })
      })
    }

    if(values.conresetpassword && values.resetpassword && values.conresetpassword == values.resetpassword){
      const result = await ResetServices.changePassword(values.resetpassword , selectUser);
      if(result.status == 200){
        setValues(initialValues);
        setError(initialError);
        setShow(false);
        setTimeout(() => {
          toast.success("Password has been changed successfully!", {
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true
          });
      });
      }
    }
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };


  return (
    <>
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
    >

    <Modal.Header closeButton>
      <Modal.Title>Password Reset</Modal.Title>
    </Modal.Header>
    <Modal.Body>
    <ToastContainer />
        <form>
          <div className="form-group">
            <label htmlFor="password">Password</label>
            <input 
              type="password" 
              className="form-control"
              value={values.company}
              onChange={handleInputChange}  
              name="resetpassword" 
              placeholder="Password" 
            />
            <span className="red">{error.resetpassword}</span>
          </div>

          <div className="form-group">
            <label htmlFor="conpassword">Confirm Password</label>
            <input 
              type="password" 
              className="form-control" 
              value={values.company}
              onChange={handleInputChange}  
              name="conresetpassword"
              placeholder="Confirmation Password" 
            />
            <span className="red">{error.conresetpassword}</span>
          </div>
        </form>
    </Modal.Body>
    
    <Modal.Footer>
      <Button variant="secondary" onClick={handleClose}>
        Close
      </Button>
      <Button onClick={handleResetPassword} variant="primary">Submit</Button>
    </Modal.Footer>
    </Modal>

    <table id="activeUserTable" className="table table-hover table-bordered" width="100%">
      <thead>
        <tr>
          <th>Name</th>
          <th>Email</th>
          <th>Office Name</th>
          <th>Supplier</th>
        </tr>
      </thead>
      <tbody>
        {props.activeUser &&
          props.activeUser.map((user) => (
            <tr key={user.id}>
              <td><Link to={`/users/edit/${user.id}`}>{user.name}</Link></td>
              <td><Link to={`/users/edit/${user.id}`}>{user.email}</Link></td>
              <td><Link to={`/users/edit/${user.id}`}>{user.officeLocation.officeName}</Link></td>
              <td><Link to={`/users/edit/${user.id}`}>
                {
                  user.userSupplierLocations && user.userSupplierLocations.map((data , i) =>(
                 
                      (i > 3 && i % 4 == 1) ? 
                        <br/>
                      :
                      <span>{ data.supplierLocation?.SupplierLocation ? data.supplierLocation.SupplierLocation : '' } | </span>
                  ))
                }
                </Link>
              </td>
            </tr>
          ))}
      </tbody>
    </table>
   </>
  );
}

export default ActiveUserTable;