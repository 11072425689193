import PropType from 'prop-types';
import React from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useFormikContext } from 'formik';



const CustomDate = ({
  field, form: { touched, errors }, label,disabled, inputRef, ...props
}) => {
  const { setFieldValue } = useFormikContext();
  return (
    <div className="form-group">
      <label htmlFor={field.name}>{label}</label>&nbsp;&nbsp;<i class="fa fa-calendar" aria-hidden="true"></i>
          <DatePicker
            autoComplete="off"
            {...field} 
            {...props} 
            className="form-control"
            selected={(field.value && new Date(field.value)) || null}
            onChange={val => {
                setFieldValue(field.name, val);
                props.fun(true)
            }}
        />

      {touched[field.name] && errors[field.name] ? (
          <small style={{ color: 'red' }}>{errors[field.name]}</small>
        ) : null}
    
    </div>
  );
};


CustomDate.defaultProps = {
  inputRef: undefined
};

CustomDate.propTypes = {
  label: PropType.string.isRequired,
  field: PropType.object.isRequired,
  form: PropType.object.isRequired,
  inputRef: PropType.oneOfType([
    PropType.func,
    PropType.shape({ current: PropType.instanceOf(Element) })
  ])
};

export default CustomDate;
