import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const CustomTextarea = ({
  field,
  form: { touched, errors, values },
  label,
  disabled,
  inputRef,
  ...props
}) => {
  const [previousValue, setPreviousValue] = useState(field.value);

  useEffect(() => {
    // Check if the value has changed and perform actions as needed
    if (field.value !== previousValue) {
      console.log('Value has changed:', field.value);
      
      // You can also notify a parent component or trigger other actions here
      setPreviousValue(field.value); // Update the previous value
      props.fun(true)
    }
  }, [field.value, previousValue]);

  return (
    <div className="form-group">
      <label htmlFor={field.name}>{label}</label>
      {disabled ? (
        <textarea
          id={field.name}
          className="form-control"
          ref={inputRef}
          {...field}
          {...props}
          disabled
          value={values[field.name]}
        />
      ) : (
        <textarea
          id={field.name}
          className="form-control"
          ref={inputRef}
          {...field}
          {...props}
          value={values[field.name]}
        />
      )}

      {touched[field.name] && errors[field.name] ? (
        <small style={{ color: 'red' }}>{errors[field.name]}</small>
      ) : null}
    </div>
  );
};

CustomTextarea.defaultProps = {
  inputRef: undefined,
};

CustomTextarea.propTypes = {
  label: PropTypes.string.isRequired,
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  inputRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
};

export default CustomTextarea;
