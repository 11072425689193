import React from 'react';
import { Link, useLocation } from "react-router-dom";

function Filter(props) {
    return (
        <div class="row">
            <div class="col-sm-6">
                <h3><i className="fa fa-bar-chart" /> Choose the report type</h3>
            </div>
            <div class="col-sm-4">
                <div style={{"margin-top":"9px"}}>
                    <Link to="/disqualifiedPlanneOrders">
                        <span style={{"font-size":"14px","border":"1px solid #cccccc","padding":"9px","border-radius":"4px"}}>Orders planned Carrier is sudden disqualified</span>
                    </Link>
                </div>
            </div>
            <div class="col-sm-2">
                <div className="input-group">
                    <select className="form-control" onChange={props.function}>
                        <option value={'active'}>Activate</option>
                        <option value={'inactive'}>Deactivate</option>
                        <option value={'disqualified'}>Disqualified</option>
                        <option value={'qualified'}>Qualified </option>
                    </select>
                </div>
            </div>
        </div>
    );
}

export default Filter;