import React, { useState, useEffect } from "react";
import axios from "axios";
import { useHistory, useParams, Link } from "react-router-dom";
import { API_BASE_URL, ACCESS_TOKEN_NAME, ACCESS_TOKEN_NAME_USER } from "../../constants/apiConstants";
import swal from "sweetalert";
import {Multiselect}  from "multiselect-react-dropdown";
import InputMask from 'react-input-mask';
import logo from '../../images/logo.png';
import profilepic from '../../images/avatar-4.jpg';
import Navbar from "./../Layout/Navbar";
import Sidebar from "./../Layout/Sidebar";
import Footer from "./../Layout/Footer";
import AddVendor from "./AddVendor";
import Avatar from 'react-avatar';
import $ from 'jquery';
import { ToastContainer, toast } from 'react-toastify';

let abc;
const EditUser = (props) => {
 
  const [isChecked, setIsChecked] = useState();
  
  useEffect(()=>{
    if(!window.location.hash) {
        window.location = window.location + '#loaded';
        window.location.reload();
    }
  }, []);

  //console.log("roleId",localStorage.getItem('role'));
  const [display, setdisplay] = useState('none');

  let history = useHistory();
  const params = useParams();
  const [state, setState] = useState({
    companyName: "",
    phone: "",
    email: "",
    fax: "",

    vendorAddressAddr1: "",
    vendorAddressAddr2: "",
    vendorAddressAddr3: "",
    vendorAddressAddr4: "",

    vendorAddressCity: "",
    vendorAddressState: "",
    vendorAddressCountry: "",
    vendorAddressPostalCode: "",

    insuranceCompany: "",
    supplierLocations: [],
    officeLocation: "Select Office Location",
    typeofCarrier: "",
    TruckTypes: [], 
    coverageType: "",
    usDOTNumber: "",
    mcNumber: "",
    safetyRating: "",
    notes: "",
    isActive: "",
    insuranceExpirationDate: "",
    TrailerSize: [],
    successMessage: null,
    overrideChecked:"",
    // errorMessage: {},
    errorMessages: {}
  });
  const [error, setError] = useState({
    errorMessage: {}
  });
  const [errorMessages, setErrorMessage] = useState();
  const [multiSelectedValue, setMultiSelectedValue] = useState([]);
  const [multiSelectOptions1, setMultiSelectOptions1] = useState([]);
  
  const [multiSelectOptions, setMultiSelectOptions] = useState({
    options: [{name: '20 ft'},{name: '22 ft'}, {name: '40 ft'}]
  });
  const [trailerSize, setTrailerSize] = useState({
    TrailerSize: [],
  });
  const [truckTypes, setTrailerSize1] = useState({
    truckTypes: [],
  });
  const [supplierLocation, setSupplierLocation] = useState({
    supplierLocations: [],
  });
  const [multiSelectedTrailerSize, setMultiSelectedTrailerSize] = useState([]);
  const [multiSelectedTrailerSize1, setMultiSelectedTrailerSize1] = useState([]);
  
  const handleChange = (e) => {

    const { name, value } = e.target;
    console.log(name, value);
    if(name === 'phone')
    {
      const re = /^[0-9\+b]+$/;

    // if value is not blank, then test the regex

    if (e.target.value === '' || re.test(e.target.value)) {
      setState((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
    }
    else
    {
      setState((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    } 
    validate(name, value);
  };

  useEffect(() => {

    $(function(){
      var dtToday = new Date();
      var month = dtToday.getMonth() + 1;
      var day = dtToday.getDate();
      var year = dtToday.getFullYear();
      if(month < 10)
          month = '0' + month.toString();
      if(day < 10)
          day = '0' + day.toString();
      var maxDate = year + '-' + month + '-' + day;
      $('#txtDate').attr('min', maxDate);
  });

    async function fetchData() {
      const { id } = params;
      const result = await axios.get(
        API_BASE_URL + `/findSingleVendor?id=${id}`,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN_NAME),
          },
        }
      );
      const trailerdata = [];
      const truckTypesdata = []
      const datas = []
      

      if (result.status === 200) {
     
          if(result.data[0].trailerSize){
          console.log('vendordata',result.data[0].trailerSize.split(','));
          let trailerSize = result.data[0].trailerSize.split(',');
          trailerSize.forEach(element => {
            trailerdata.push({name: element});
          });


          console.log('Result----',result.data);
          console.log('trailesize----',trailerdata);
          setMultiSelectedTrailerSize(trailerdata);
          setTrailerSize({TrailerSize: { selectedList: trailerdata}});

        }
        if(result.data[0].truckTypes){
          let truckTypes = result.data[0].truckTypes.split(',');
          truckTypes.forEach(element => {
            truckTypesdata.push({name: element});
          });


          console.log('Result----',result.data);
          console.log('trailesize----',trailerdata);
          setMultiSelectedTrailerSize1(truckTypesdata);
          setTrailerSize1({truckTypes: { selectedList: truckTypesdata}});
        }
         
        if(result.data[0].vendorSupplierLocations){
          result.data[0].vendorSupplierLocations.forEach(element => {
            datas.push({id: element.supplierLocation.id, name: element.supplierLocation.SupplierLocation});
          });
          setMultiSelectedValue(datas);
          console.log('selected value',datas);
          setSupplierLocation({supplierLocations: { selectedList: datas}});

        }

        console.log("allDataaaaaaaaaaaaaaaaa",result.data[0].overrideStatus
        )
        
       if(result.data[0].overrideStatus == 1)
       {
        setIsChecked(true)
       }
       else
       {
        setIsChecked(false)
       }
        setState(result.data[0]);
      }
    }
    fetchData();




    async function fetchLocation() {
      const result = await axios.get(
          API_BASE_URL + `/findAllSupplierLocation`,
          {
              headers : {
                  Authorization : "Bearer " + localStorage.getItem(ACCESS_TOKEN_NAME),
              }
          },
      );
      const data = [];
      if(result.status === 200){
          result.data.forEach(element => {
            data.push({id: element.id, name: element.SupplierLocation});
            
          });
          console.log('not selected--',data);
          setMultiSelectOptions1(data);
      }
    }   
    fetchLocation();
  

  }, []);

  const validate = (key, value) => {
    setState((prevState) => ({
      ...prevState,
      errorMessage: {
        ...prevState.errorMessage,
        [key]: "",
      },
    }));
    // switch (key) {
    //   case "email":
    //     let pattern = new RegExp(
    //         /^(("[\w-\s]+")|([+\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    //     );
    //     if (!pattern.test(value)) {
    //       setState((prevState) => ({
    //         ...prevState,
    //         errorMessage: {
    //           ...prevState.errorMessage,
    //           email: "Please Enter valid Email",
    //         },
    //       }));
    //     }
    //     break;

    //   default:
    // }
  };
  const handleCancel = (e) => {
    history.push("/vendor");
  }

  const onSelect3 = (selectedList, selectedItem) => {
    console.log('selectedlist',selectedList);
    setSupplierLocation((prevState) => ({
      ...prevState,
      supplierLocations: {
        selectedList
      },
    }));
  }

  const onRemove3 = (selectedList, removedItem) => {
    console.log('deselected',selectedList);
    setSupplierLocation((prevState) => ({
      ...prevState,
      supplierLocations: {
        selectedList
      },
    }));
  }





  const onSelect = (selectedList, selectedItem) => {
    console.log('selectedlist',selectedList);
    console.log('onselect', selectedList, selectedItem.id);
    setTrailerSize((prevState) => ({
      ...prevState,
      TrailerSize: {
        selectedList
      },
   
    }));


    setState((prevState) => ({
      ...prevState,
      supplierLocations: {
        selectedList
      },
    }));
  }

  const onSelect1 = (selectedList, selectedItem) => {
    console.log('selectedlist',selectedList);
    console.log('onselect', selectedList, selectedItem.id);
    setTrailerSize1((prevState) => ({
      ...prevState,
      truckTypes: {
        selectedList
      },
   
    }));


    setState((prevState) => ({
      ...prevState,
      supplierLocations: {
        selectedList
      },
    }));
  }

  const onRemove = (selectedList, removedItem) => {
    console.log('deselected',selectedList);
    setTrailerSize((prevState) => ({
      ...prevState,
      TrailerSize: {
        selectedList
      },
    }));
  }

  const onRemove1 = (selectedList, removedItem) => {
    console.log('deselected',selectedList);
    setTrailerSize1((prevState) => ({
      ...prevState,
      truckTypes: {
        selectedList
      },
    }));
  }

  //const sendDetailsToServer = (e) => {
  const handleSubmitClick = (e) => {
    e.preventDefault();


    if (state.companyName.trim() == '') {
      setError((prevState) => ({
        ...prevState,
        errorMessage: {
          ...prevState.errorMessage,
          companyName: "Please Enter Company Name",
        },
      }));
    }
    if (state.email.trim() == '') {
      setError((prevState) => ({
        ...prevState,
        errorMessage: {
          ...prevState.errorMessage,
          email: "Please Enter Email",
        },
      }));
    }
    if (state.phone.trim() == '') {
      setError((prevState) => ({
        ...prevState,
        errorMessage: {
          ...prevState.errorMessage,
          phone: "Please Enter Phone Number",
        },
      }));
    }


    if (state.vendorAddressAddr1.trim() == "") {
      setError((prevState) => ({
        ...prevState,
        errorMessage: {
          ...prevState.errorMessage,
          vendorAddressAddr1: "Please Enter Address1",
        },
      }));
    }

/*

    if (state.vendorAddressAddr2 == "") {
      setError((prevState) => ({
        ...prevState,
        errorMessage: {
          ...prevState.errorMessage,
          vendorAddressAddr2: "Please Enter Address2",
        },
      }));
    }

    if (state.vendorAddressCity == "") {
      setError((prevState) => ({
        ...prevState,
        errorMessage: {
          ...prevState.errorMessage,
          vendorAddressCity: "Please Enter City",
        },
      }));
    }

    if (state.vendorAddressState == "") {
      setError((prevState) => ({
        ...prevState,
        errorMessage: {
          ...prevState.errorMessage,
          State: "Please Enter State",
        },
      }));
    }


    if (state.vendorAddressCountry == "") {
      setError((prevState) => ({
        ...prevState,
        errorMessage: {
          ...prevState.errorMessage,
          vendorAddressCountry: "Please Enter Country",
        },
      }));
    }

    if (state.vendorAddressPostalCode == "") {
      setError((prevState) => ({
        ...prevState,
        errorMessage: {
          ...prevState.errorMessage,
          vendorAddressPostalCode: "Please Enter Postal Code",
        },
      }));
    }

*/
    // console.log("comannnnn",state.companyName.trim());
    // return;

    if (
      state.companyName.trim() &&
      state.email.trim() && state.vendorAddressAddr1.trim() &&
      state.phone.trim()
    ) {

      console.log("condition Setisfied");
    
      setState((prevState) => ({
        ...prevState,
        errorMessage: {},
      }));
      const { id } = params;
      

      if(truckTypes.truckTypes == null){
          abc = "Dedicated";
      }
      else if(truckTypes.truckTypes == undefined){
        abc = "Dedicated";
      }
      else{
        abc = "Dedicated";
      }

     // console.log("wqeq",state.overrideChecked);

      const payload = {
        CompanyName: state.companyName.trim(),
        Email: state.email?.trim(),
        Phone: state.phone?.trim(),
        Fax: state.fax?.trim(),

        Address1: state.vendorAddressAddr1?.trim(),
        Address2: state.vendorAddressAddr2,
        Address3: state.vendorAddressAddr3,
        Address4: state.vendorAddressAddr4,

        City: state.vendorAddressCity,
        State: state.vendorAddressState,
        Country: state.vendorAddressCountry,
        Postalcode: state.vendorAddressPostalCode,

        InsuranceCompany: state.insuranceCompany,
        CoverageType: state.coverageType,
        usDOTNumber: state.usDOTNumber,
        MCNumber: state.mcNumber,
        SafetyRating: state.safetyRating,
        Notes: state.notes,
        supplierLocations: state.supplierLocations,
        InsuranceExpirationDate: state.insuranceExpirationDate,
        TrailerSize: trailerSize.TrailerSize,
        typeofCarrier:abc,
        supplierLocations: supplierLocation.supplierLocations,
        TruckTypes: truckTypes.truckTypes,

        overrideChecked: state.overrideChecked ? state.overrideChecked : state.overrideStatus
        
      };

      console.log("hhh",payload);
   

      axios
      .post(API_BASE_URL + `/updateVendor?id=${id}`, payload, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN_NAME),
        },
      })
      .then(function (response) {
        console.log("rwewqer", response.data.status)
        
        if(response.data.status === 404)
        {
          swal({  
            title: "Vendor not updated!",
            text: response.data.message,
            icon: "error",
            button: "OK ",
          })
        }
        else if (response.status === 200) {
          swal({  
            title: "Vendor Updated!",
            text: "",
            icon: "success",
            button: "OK ",
          }).then((value) => {
            history.push(`/vendordetails/${id}`);
          });
        } else {
          setState((prevState) => ({
            ...prevState,
            errorMessage: "some error occurd",
          }));
        }
      })
      .catch(function (error) {
        console.log(error);
      });

    } else {
      console.log("condition not Setisfied");
      setErrorMessage("Please Fill All The Fields");
    }
  };

  //console.log("ASDas", isChecked)
  
  function handleLogout() {
    localStorage.removeItem(ACCESS_TOKEN_NAME);
    localStorage.removeItem('role');
    localStorage.removeItem('user');
    localStorage.removeItem(ACCESS_TOKEN_NAME_USER);
    props.history.push("/");
  }
  const handleCheckboxChange = async (event) => {
    
    console.log("weqqa",state.isActive)
    if(state.isActive == 1)
    {
      setIsChecked(event.target.checked);
    }

    else
  {
    console.log("heyyyyyyyyy")
    swal("Error!", "Not valid for inactive user please activate first", "error");
  //   setTimeout(() => {
  //     toast.error('Not valid for inactive user please activate first', {
  //         autoClose: 2000,
  //         hideProgressBar: false,
  //         closeOnClick: true,
  //         pauseOnHover: true,
  //         draggable: true
  //     });
  // });
  }
    
  };


  const options = [{name: 'Tilt deck', id: 1},{name: 'Flatbeds', id: 2},{name: 'Tanks', id: 3} , {name: 'Tow Truck', id: 4}];


  return (
    <>
    <div id="pcoded" className="pcoded">
        <div className="pcoded-overlay-box"></div>
        <div className="pcoded-container navbar-wrapper">

        <nav className="navbar header-navbar pcoded-header" style={{height:'55px', minHeight:'26px'}}> 
        <div className="navbar-wrapper">
            <div className="navbar-logo" style={{height: '52px'}}>
                <a style={{top:'7px'}} className="mobile-menu navabar_menu" id="mobile-collapse" href="#!">
                    <i class="ti-menu" style={{"color":" #000", "font-size" : "22px;"}}></i>
                </a>
                <a className="mobile-search morphsearch-search" href="#">
                    <i className="ti-search"></i>
                </a>
                <Link to="/dashboard">
                    <img style={{width:'30%'}} className="img-fluid logo-img" src={logo} alt="Theme-Logo" />
                </Link>
                <a className="mobile-options">
                    <i className="ti-more"></i>
                </a>
            </div>
            
            <div className="navbar-container container-fluid">
                <ul className="nav-right">
                    <li className="user-profile header-notification" style={{lineHeight: 3.5}}>
                    <a href="javascript:void(0)" onClick={()=>{
                                        setdisplay((old)=>(old == 'none') ? 'block' : 'none')
                        }}>
                            <Avatar name={localStorage.getItem('user')} colors={['#ed3028', '#711999', '#383838']} size="40" round="20px" />
                        </a>
                        <ul className="show-notification profile-notification" style={{display:display}}>
                            <li>
                                <a href="#">
                                    <i className="ti-user"></i> Profile
                                </a>
                            </li>
                            <li>
                              <Link to="/resetpassword">
                                <i className="ti-layout-sidebar-left"></i> Change Password
                              </Link>
                            </li>
                            <li>
                                <a href="" onClick={() => handleLogout()}>
                                    <i className="ti-layout-sidebar-left"></i> Logout
                                </a>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
            
            <div className="pcoded-main-container">
                <div className="pcoded-wrapper">
                    <Sidebar />
                    <div className="pcoded-content page_content">
                        <div className="pcoded-inner-content">
                            <div className="main-body">
                                <div className="page-wrapper">
                                    <div className="page-body">
                                        <div className="row">
                                            <div className="col-md-2">
                                                <h4 style={{ marginTop: "20px", fontSize: "large"}}>Edit Carrier</h4>
                                            </div>
                                            <div className="col-md-10">
                                                {/* <h4>Edit User</h4> */}
                                            </div>
                                        </div>
                                        <div className="col-md-12 tabs-ui p-0">
                                        <div className="tab-content" id="nav-tabContent">
                                            <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                                                <form>
                                                  {errorMessages && (
                                                    <span className="red">{errorMessages}</span>
                                                  )}
                                                  <div className="row">
                                                    <div className="col-md-6">
                                                      <div className="form-group text-left">
                                                        <label>Company Name</label>
                                                        <input
                                                          type="text"
                                                          name="companyName"
                                                          className="form-control"
                                                          placeholder="Company Name"
                                                          value={state.companyName}
                                                          onChange={handleChange}
                                                        />
                                                        {error.errorMessage.companyName && (
                                                          <span className="red">{error.errorMessage.companyName}</span>
                                                        )}
                                                      </div>
                                                  </div>
                                                  <div className="col-md-6">
                                                    <div className="form-group text-left">
                                                    <label>Email</label>
                                                      <input
                                                        type="email"
                                                        name="email"
                                                        className="form-control"
                                                        aria-describedby="emailHelp"
                                                        placeholder="Email"
                                                        value={state.email}
                                                        onChange={handleChange}
                                                      
                                                      />

                                                        {error.errorMessage.email && (
                                                        <span className="red">{error.errorMessage.email}</span>
                                                      )}
                                                    </div>
                                                  </div>

                                                  <div className="col-md-6">
                                                    <div className="form-group text-left">
                                                    <label>Phone</label>
                                                      <InputMask
                                                        name="phone"
                                                        className="form-control"
                                                        placeholder="Phone Number"
                                                        value={state.phone}
                                    
                                                        onChange={handleChange}
                                                        // minLength="10"
                                                        // maxLength="10"
                                                      />
                                                       {error.errorMessage.phone && (
                                                        <span className="red">{error.errorMessage.phone}</span>
                                                      )}
                                                    </div>
                                                  </div>
                                                  <div className="col-md-6">
                                                    <div className="form-group text-left">
                                                    <label>Fax</label>
                                                      <input
                                                        type="text"
                                                        name="fax"
                                                        className="form-control"
                                                        placeholder="Fax"
                                                        value={state.fax}
                                                        onChange={handleChange}
                                                      />
                                                       {error.errorMessage.fax && (
                                                        <span className="red">{error.errorMessage.fax}</span>
                                                      )}
                                                    </div>
                                                  </div>

                                                <div className="col-md-12">
                                                  <div className="form-group text-left">
                                                  <label>Billing Address</label>
                                                    <div className="row">
                                                      <div className="col-md-3">
                                                        <input 
                                                          placeholder="Address 1"
                                                          value={state.vendorAddressAddr1}
                                                          onChange={handleChange}
                                                          name="vendorAddressAddr1" 
                                                          type="text" 
                                                          className="form-control" 
                                                        />

                                                        {error.errorMessage.vendorAddressAddr1 && (
                                                          <span className="red">{error.errorMessage.vendorAddressAddr1}</span>
                                                        )}

                                                      </div>
                                                      <div className="col-md-3">
                                                        <input
                                                          placeholder="Address 2"
                                                          value={state.vendorAddressAddr2}
                                                          onChange={handleChange}
                                                          name="vendorAddressAddr2" 
                                                          type="text" 
                                                          className="form-control" 
                                                        />

                                                        {error.errorMessage.vendorAddressAddr2 && (
                                                          <span className="red">{error.errorMessage.vendorAddressAddr2}</span>
                                                        )}

                                                      </div>
                                                      <div className="col-md-3">
                                                        <input
                                                          placeholder="Address 3"
                                                          value={state.vendorAddressAddr3}
                                                          onChange={handleChange}
                                                          name="vendorAddressAddr3"  
                                                          type="text" 
                                                          className="form-control" 
                                                        />

                                                      </div>
                                                      <div className="col-md-3">
                                                        <input
                                                          placeholder="Address 4"
                                                          value={state.vendorAddressAddr4}
                                                          onChange={handleChange}
                                                          name="vendorAddressAddr4"  
                                                          type="text" 
                                                          className="form-control" 
                                                        />

                                                      </div>
                                                    </div><br/>

                                                    <div className="row">
                                                      <div className="col-md-3">
                                                        <input 
                                                          placeholder="City"
                                                          value={state.vendorAddressCity}
                                                          onChange={handleChange}
                                                          name="vendorAddressCity"   
                                                          type="text" 
                                                          className="form-control" 
                                                        />

                                                        {error.errorMessage.vendorAddressCity && (
                                                          <span className="red">{error.errorMessage.vendorAddressCity}</span>
                                                        )}

                                                      </div>
                                                      <div className="col-md-3">
                                                        <input
                                                          placeholder="State"
                                                          value={state.vendorAddressState}
                                                          onChange={handleChange}
                                                          name="vendorAddressState"   
                                                          type="text" 
                                                          className="form-control" 
                                                        />

                                                        {error.errorMessage.vendorAddressState && (
                                                          <span className="red">{error.errorMessage.vendorAddressState}</span>
                                                        )}

                                                      </div>
                                                      <div className="col-md-3">
                                                        <input
                                                          placeholder="Country"
                                                          value={state.vendorAddressCountry}
                                                          onChange={handleChange}
                                                          name="vendorAddressCountry"   
                                                          type="text" 
                                                          className="form-control" 
                                                        />

                                                        {error.errorMessage.vendorAddressCountry && (
                                                          <span className="red">{error.errorMessage.vendorAddressCountry}</span>
                                                        )}

                                                      </div>
                                                      <div className="col-md-3">
                                                        <input
                                                          placeholder="Postal Code"
                                                          value={state.vendorAddressPostalCode}
                                                          onChange={handleChange}
                                                          name="vendorAddressPostalCode"   
                                                          type="text" 
                                                          className="form-control" 
                                                        />

                                                        {error.errorMessage.vendorAddressPostalCode && (
                                                          <span className="red">{error.errorMessage.vendorAddressPostalCode}</span>
                                                        )}

                                                      </div>
                                                    </div>
                                                  </div>
                                              </div>


                                                  <div className="col-md-6">
                                                    <div className="form-group text-left">
                                                    <label>Insurance Company</label>
                                                      <input
                                                        type="text"
                                                        name="insuranceCompany"
                                                        className="form-control"
                                                        placeholder="Insurance Company"
                                                        value={state.insuranceCompany}
                                                        onChange={handleChange}
                                                      />
                                                      {error.errorMessage.insuranceCompany && (
                                                        <span className="red">{error.errorMessage.insuranceCompany}</span>
                                                      )}
                                                    </div>
                                                  </div>

                                                  <div className="col-md-6">
                                                    <div className="form-group text-left">
                                                    <label>Coverage Type</label>
                                                      <input
                                                        type="text"
                                                        name="coverageType"
                                                        className="form-control"
                                                        placeholder="Coverage Type"
                                                        value={state.coverageType}
                                                        onChange={handleChange}
                                                      />
                                                      {error.errorMessage.coverageType && (
                                                        <span className="red">{error.errorMessage.coverageType}</span>
                                                      )}
                                                    </div>
                                                  </div>
                                                  
                                                  <div className="col-md-6">
                                                    <div className="form-group text-left">
                                                    <label>DOT Number</label>
                                                      <input
                                                        type="text"
                                                        name="usDOTNumber"
                                                        className="form-control"
                                                        placeholder="DOT Number"
                                                        value={state.usDOTNumber}
                                                        onChange={handleChange}
                                                      />
                                                       {error.errorMessage.usDOTNumber && (
                                                        <span className="red">{error.errorMessage.usDOTNumber}</span>
                                                      )}
                                                    </div>
                                                  </div>

                                                  <div className="col-md-6">
                                                    <div className="form-group text-left">
                                                    <label>MC Number</label>
                                                      <input
                                                        type="text"
                                                        name="mcNumber"
                                                        className="form-control"
                                                        placeholder="MC Number"
                                                        value={state.mcNumber}
                                                        onChange={handleChange}
                                                      />
                                                      {error.errorMessage.mcNumber && (
                                                        <span className="red">{error.errorMessage.mcNumber}</span>
                                                      )}
                                                    </div>
                                                  </div>

                                                  <div className="col-md-6">
                                                    <div className="form-group text-left">
                                                    <label>Safety Rating</label>
                                                      <select name="safetyRating"     value={state.safetyRating}
                                                   onChange={handleChange} className="form-control">
                                                        <option></option>
                                                        <option value="Satisfactory">Satisfactory</option>
                                                        <option value="Conditional">Conditional</option>
                                                        <option value="None">None</option>
                                                      </select>
                                                      {error.errorMessage.safetyRating && (
                                                        <span className="red">{error.errorMessage.safetyRating}</span>
                                                      )}
                                                    </div>
                                                  </div>

                                                  <div className="col-md-6">
                                                    <div className="form-group text-left">
                                                    <label>Notes</label>
                                                      <input
                                                        type="text"
                                                        name="notes"
                                                        className="form-control"
                                                        placeholder="Notes"
                                                        value={state.notes}
                                                        onChange={handleChange}
                                                      />
                                                      {error.errorMessage.notes && (
                                                        <span className="red">{error.errorMessage.notes}</span>
                                                      )}
                                                    </div>
                                                  </div>

                                                  <div className="col-md-6">
                                                    <div className="form-group text-left">
                                                    <label>Insurance Expiration Date</label>
                                                      <input
                                                        type="date"
                                                        id="txtDate"
                                                        name="insuranceExpirationDate"
                                                        className="form-control"
                                                        placeholder="Insurance Expiration Date"
                                                        value={state.insuranceExpirationDate}
                                                        onChange={handleChange}
                                                      />
                                                      {error.errorMessage.insuranceExpirationDate && (
                                                        <span className="red">{error.errorMessage.insuranceExpirationDate}</span>
                                                      )}
                                                    </div>
                                                  </div>

                                                  <div className="col-md-6">
                                                        <div className="form-group text-left">
                                                        <label>Trailer Size</label>
                                                            <Multiselect
                                                            options={multiSelectOptions.options}
                                                            selectedValues={multiSelectedTrailerSize}
                                                            onSelect={onSelect}
                                                            onRemove={onRemove}
                                                            displayValue="name"
                                                            placeholder="Select Trailer Size"
                                                            />
                                                            {error.errorMessage.TrailerSize && (
                                                        <span className="red">{error.errorMessage.TrailerSize}</span>
                                                      )}
                                                        </div>
                                                    </div>


                                                  <div className="col-md-6">
                                                    <div className="form-group text-left">
                                                            <label>Trailer Types</label>
                                                              <Multiselect
                                                                options={options}
                                                                selectedValues={multiSelectedTrailerSize1}
                                                                onSelect={onSelect1}
                                                                onRemove={onRemove1}
                                                                displayValue="name"
                                                                placeholder="Select Trailer Types"
                                                              />
                                                    </div>
                                                  </div>


                                                <div className="col-md-6">
                                                  
                                                  <div className="form-group text-left">
                                                  <label>Type of Carrier</label>
                                                  <select
                                                    className="form-control"
                                                    name="typeofCarrier"
                                                    value={state.typeofCarrier}
                                                   onChange={handleChange}
                                                  >
                                                    
                              <option value="Dedicated" selected>Dedicated </option>
                              <option value="Non-Dedicated" {...state.typeofCarrier == "Non-Dedicated" ? "selected" : {}} >Non-Dedicated </option>
                                                  </select>
                                                
                                                  </div>
                                                </div>

                                                <div className="col-md-12">
                                                <label>Supplier Location</label>
                                                  <div className="row">
                                                  <div className="col-md-6">
                                                  <div className="form-group text-left">
                                                  
                                                        <Multiselect
                                                        options={multiSelectOptions1}
                                                        selectedValues={multiSelectedValue}
                                                        onSelect={onSelect3}
                                                        onRemove={onRemove3}
                                                        displayValue="name"
                                                        placeholder="Select Supplier"
                                                        />
                                                        

                                        {/* {state.errorMessage.supplierLocations && (
                                                      <span className="red">{state.errorMessage.supplierLocations}</span>
                                                    )} */}
                                                   
                                                  </div>
                                                </div>
                                               
                                                <div className="col-md-6">
                                                  <div className="form-group text-left mt-1">
                                                    <label> </label>
                                                    <label className="mb-0">
                                                      <input
                                                        type="checkbox"
                                                        name="overrideChecked"
                                                      
                                                        onChange={(e) => {handleCheckboxChange(e);handleChange(e);}}
                                                        checked={ isChecked}
                                                        value={  isChecked ? 0 : 1}
                                                        className="mr-2"
                                                        disabled=  {
                                                          localStorage.getItem('role') == 1 || localStorage.getItem('role') == 2
                                                        ? '' : 'disabled' }
                                                      />
                                                     Manager Override to qualified
                                                    </label>
                                                  
                                                  </div>
                                                </div>
                                              
                                                

                                                  </div>
                                                </div>

                                                









                                                    
                                                  <div className="col-md-12 cstm-add-btn">
                                                    <button
                                                      type="button"
                                                      className="btn btn-primary mr-2"
                                                      onClick={handleSubmitClick}
                                                    >
                                                      Update
                                                    </button>
                                                    <button
                                                      type="button"
                                                      className="btn btn-defaule"
                                                      onClick={handleCancel}
                                                    >
                                                      Cancel
                                                    </button>
                                                  </div>

                                                  </div>
                                                </form>
                                                <div
                                                  className="alert alert-success mt-2"
                                                  style={{ display: state.successMessage ? "block" : "none" }}
                                                  role="alert"
                                                >
                                                  {state.successMessage}
                                                </div>
                                           

                                            </div>
                                          <div className="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">2</div>
                                          <div className="tab-pane fade" id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab">3</div>
                                      </div>
                                            
                                        </div>

                                        <Footer />

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> 
    </>
  );

};

export default EditUser;
