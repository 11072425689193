import React,{ useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';

function FilterComponentBox({onFilterChanged, columnDef, columnName, pageName}) {
    const [cookies, setCookie] = useCookies([pageName]);
    const [localStorage, setLocalStorage] = useState({});

    useEffect(()=>{
        setLocalStorage(cookies);
    },[]);
    return (
        <input
            className='input-wid-set'
            value={localStorage[columnName]}
            onChange={(e) => {
            setCookie(columnName, e.target.value, { path: '/'+pageName });
            onFilterChanged(columnDef.tableData.id, e.target.value);
            setLocalStorage({[columnName]:e.target.value});
            }}
        />
    );
}

export default FilterComponentBox;