import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { ACCESS_TOKEN_NAME, ACCESS_TOKEN_NAME_USER, API_BASE_URL } from '../../constants/apiConstants';
import axios from 'axios'
import "../../images/icon/themify-icons/themify-icons.css";
import "../../images/js/script.js"
import "../../images/js/pcoded.min.js"
import "../../images/js/demo-12.js"
import "../../images/icon/icofont/css/icofont.css";
import logo from '../../images/logo.png';
import profilepic from '../../images/avatar-4.jpg';
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery';
import Navbar from "./../Layout/Navbar";
import Sidebar from "./../Layout/Sidebar";
import Footer from "./../Layout/Footer";
import ActiveDriverTable from "./ActiveDriverTable";
import DeactiveDriverTable from "./DeactiveDriverTable";
import InvoiceServices from '../Invoice/Services/InvoiceServices';
import Avatar from 'react-avatar';
import SyncComponent from '../Shared/SyncComponent';


function handleLogout() {
    localStorage.removeItem(ACCESS_TOKEN_NAME);
    localStorage.removeItem('role');
    localStorage.removeItem('user');
    localStorage.removeItem(ACCESS_TOKEN_NAME_USER);
}

class driverTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isActive:1,
            location:[],
            selectedLocation:null,
            display:'none',
            pageNameList: 'Driver'
        }
    }

    handelPorfilebox = () => {
        this.setState((old)=>{
            return {
                ...old,
                display: old.display == 'none' ?  'block' : 'none'
            }
        })
    }

    handleCheck = async (e) => {
        let isChecked = e.target.checked;
        if(isChecked){
            this.setState({
                isActive:0
            })
        } else {
            this.setState({
                isActive:1
            })
        }
    }

    handelLocationChange = async (e) => {
        this.setState({
            selectedLocation:e.target.value
        });
    }

    findLocation = async () => {
        const result = await InvoiceServices.getAllSupplierLocation();
        this.setState((old)=>{
            return {
                ...old,
                location:result
            }
        })
    }

    componentDidMount() {
        this.findLocation();
    }

    render() {
        return (
            <div id="pcoded" className="pcoded">
                <div className="pcoded-overlay-box"></div>
                <div className="pcoded-container navbar-wrapper">
                    <nav className="navbar header-navbar pcoded-header" style={{ height: '55px', minHeight: '26px' }}>
                        <div className="navbar-wrapper">
                            <div className="navbar-logo" style={{ height: '52px' }}>
                                <a style={{ top: '7px' }} className="mobile-menu navabar_menu" id="mobile-collapse" href="#!">
                                    <i class="ti-menu" style={{"color":" #000", "font-size" : "22px;"}}></i>
                                </a>
                                <a className="mobile-search morphsearch-search" href="#">
                                    <i className="ti-search"></i>
                                </a>
                                <Link to="/dashboard">
                                    <img style={{width:'30%'}} className="img-fluid logo-img" src={logo} alt="Theme-Logo" />
                                </Link>
                                <a className="mobile-options">
                                    <i className="ti-more"></i>
                                </a>
                            </div>

                            <SyncComponent pageNameList={this.state.pageNameList} />
                        </div>
                    </nav>

                    <div className="pcoded-main-container">
                        <div className="pcoded-wrapper">
                            <Sidebar />
                            <div className="pcoded-content page_content">
                                <div className="pcoded-inner-content">
                                    <div className="main-body">
                                        <div className="page-wrapper">
                                            <div className="page-body">

                                                <div className="row">
                                                    <div className="col-md-3">
                                                        <select className="form-control" onChange={this.handelLocationChange}>
                                                            <option value="null">All Location</option>
                                                            {
                                                                this.state.location.map((item)=><option value={item.id}>{item.SupplierLocation}</option>)
                                                            }
                                                        </select>
                                                    </div>
                                                </div>
                                                
                                                <div className="row">
                                                    <div className="col-md-10 col-xl-10">
                                                    </div>
                                                    <div className="col-md-2 col-xl-2">
                                                        <label className="switch float-right" style={{ marginTop: "5px" }}>
                                                            <input type="checkbox" id="togBtn" onChange={this.handleCheck} />
                                                            <div className="slider round ">
                                                                <span className="on">Deactivate</span>
                                                                <span className="off">Activate</span>
                                                            </div>
                                                        </label>
                                                    </div>
                                                </div>
                                               
                                                    {
                                                        (this.state.isActive) ?
                                                            <ActiveDriverTable selectedLocation={this.state.selectedLocation} />
                                                        :
                                                            <DeactiveDriverTable selectedLocation={this.state.selectedLocation} />
                                                    }
                                                
                                                <Footer />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <style jsx="true">{`
                                                table.dataTable thead th, table.dataTable thead, th, td{
                                                    font-family: 'Source Sans Pro', sans-serif;
                                                  }
                                                  table.dataTable thead th, table.dataTable thead, td a {
                                                      font-size:12px !important;
                                                  }
  
                                                  table.dataTable thead th, table.dataTable thead th {
                                                      font-size: 12px !important;
                                                      font-weight: 600;
                                                      background: #fbf5f5;
                                                  }

                                                  .on{
                                               
                                                    padding-right:31px;
                                                }

                                            `}</style> 
            </div >
        );
    }
}

export default driverTable;

