import React, { Component } from 'react';
import  CustomSelect  from './CustomSelect';


class Select extends Component {
    render() {
        return (
            <CustomSelect
                name={this.props.name}
                label={this.props.label}
                iid={this.props.name}
                options={this.props.option}
                placeholder={this.props.placeholder}
                defaultValue={this.props.defaultValue}
                valueinfo = {this.props.valueinfo}
                className = {this.props.className}
                fun={this.props.fun}
          />
        );
    }
}

export default Select;