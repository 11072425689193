import React, { Component } from "react";
import Sidebar from "./../Layout/Sidebar";
import { withRouter, Link } from "react-router-dom";
import {
  ACCESS_TOKEN_NAME,
  ACCESS_TOKEN_NAME_USER,
  API_BASE_URL,
} from "../../constants/apiConstants";
import logo from "../../images/logo.png";
import profilepic from "../../images/avatar-4.jpg";
import Avatar from "react-avatar";
import Table from "./Table";
import PlanningTable from "./DeletePlanningTable";
import Filter from "./Filter";
import ReportService from "./ReportService";
import $ from "jquery";
import SyncComponent from "../Shared/SyncComponent";


class ReportsLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      display: "none",
      loading: false,
      reports: [],
      deletePlanningLog: [],
    };
    this.filterHandeling = this.filterHandeling.bind(this);
  }

  handelPorfilebox = () => {
    this.setState((old) => {
      return {
        ...old,
        display: old.display == "none" ? "block" : "none",
      };
    });
  };

  handleLogout = () => {
    localStorage.removeItem(ACCESS_TOKEN_NAME);
    localStorage.removeItem("role");
    localStorage.removeItem("user");
    localStorage.removeItem(ACCESS_TOKEN_NAME_USER);
    this.props.history.push("/");
  };

  fetchReports = async (status) => {
    try {
      this.setState({
        loading: true,
      });
      const response = await ReportService.getReportsWithStatus(status);
      this.setState({
        reports: response,
        loading: false,
      });
      $("#reportTable").DataTable({
        scrollX: false,
        searching: false,
      });
    } catch (error) {}
  };

  //   componentDidMount() {

  //   }
  fetchPlanningReports = async () => {
    try {
      this.setState({
        loading: true,
      });
      const response = await ReportService.getdeletePlanningTPONull();
      console.log("chhhh", response);
      this.setState({
        deletePlanningLog: response,
        loading: false,
      });
      $("#deletePlanningTable").DataTable({
        scrollX: false,
        searching: false,
      });
    } catch (error) {}
  };

  componentDidMount() {
    this.fetchReports("active");
    this.fetchPlanningReports();
  }

  async filterHandeling(e) {
    $("#reportTable").DataTable().destroy();
    this.setState({
      loading: true,
    });
    const response = await ReportService.getReportsWithStatus(e.target.value);
    this.setState({
      reports: response,
      loading: false,
    });
    $("#reportTable").DataTable({
      scrollX: false,
      searching: false,
    });
  }

  render() {
    console.log("deletePlanningLog", this.state.deletePlanningLog);
    return (
      <div id="pcoded" className="pcoded">
        <div className="pcoded-overlay-box"></div>
        <nav
          className="navbar header-navbar pcoded-header"
          style={{ height: "55px", minHeight: "26px" }}
        >
          <div className="navbar-wrapper">
            <div className="navbar-logo" style={{ height: "52px" }}>
              <a
                style={{ top: "7px" }}
                className="mobile-menu navabar_menu"
                id="mobile-collapse"
                href="#!"
              >
                <i
                  class="ti-menu"
                  style={{ color: " #000", "font-size": "22px;" }}
                ></i>
              </a>
              <a className="mobile-search morphsearch-search" href="#">
                <i className="ti-search"></i>
              </a>
              <Link to="/dashboard">
                <img
                  style={{ width: "30%" }}
                  className="img-fluid logo-img"
                  src={logo}
                  alt="Theme-Logo"
                />
              </Link>
              <a className="mobile-options">
                <i className="ti-more"></i>
              </a>
            </div>

            <SyncComponent />

            {/* <div className="navbar-container container-fluid">
              <ul className="nav-right">
                <li
                  className="user-profile header-notification"
                  style={{ lineHeight: 3.5 }}
                >
                  <a href="javascript:void(0)" onClick={this.handelPorfilebox}>
                    <Avatar
                      name={localStorage.getItem("user")}
                      colors={["#ed3028", "#711999", "#383838"]}
                      size="40"
                      round="20px"
                    />
                  </a>
                  <ul
                    className="show-notification profile-notification"
                    style={{ display: this.state.display }}
                  >
                    <li>
                      <a href="#">
                        <i className="ti-user"></i> Profile
                      </a>
                    </li>
                    <li>
                      <Link to="/resetpassword">
                        <i className="ti-layout-sidebar-left"></i> Change
                        Password
                      </Link>
                    </li>
                    <li>
                      <a onClick={this.handleLogout}>
                        <i className="ti-layout-sidebar-left"></i> Logout
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div> */}
          </div>
        </nav>
        <div className="pcoded-container navbar-wrapper">
          <div className="pcoded-main-container">
            <div className="pcoded-wrapper">
              <Sidebar />
              <div className="pcoded-content page_content">
                <div className="pcoded-inner-content">
                  <div className="main-body">
                    <div className="page-wrapper">
                      <div className="page-body">
                        <Filter function={this.filterHandeling} />
                        <Table state={this.state} />
                        <hr></hr>
                        <div class="row">
                          <div class="col-sm-6">
                            <h3>
                              <i className="fa fa-bar-chart" /> Deleted planning
                              Log in sync
                            </h3>
                          </div>
                          <div class="col-sm-4">
                 
                          </div>
                          <div class="col-sm-2">
      
                          </div>
                          <div className="col-12">
                          <PlanningTable state={this.state}/>
                          </div>
                      
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(ReportsLayout);
