const AvoidNull2 = (string) => {
    try {
      if (string && string != '') {
        return escape(`${string}`);
      }
      else {
        return '';
      }
    } catch (err) {
      console.log(err);
      return;
    }
}

export default AvoidNull2;
