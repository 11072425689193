import React, { useState, useEffect } from 'react';
import moment from 'moment';
import MaterialTable, { MTableToolbar, Chip } from 'material-table';
import axios from 'axios';
import PlanningServices from '../DriverPlanning/PlanningServices';
import avoidNull from '../../utils/AvoidNull';
import avoidNull2 from '../../utils/AvoidNull2';
import removeAddress from '../../utils/RemoveAddress';
import CustomDatePicker from "../../utils/customDatePicker";
import FilterComponentBox from "../../utils/FilterComponentBox";
import ResetFillterButton from "../../utils/ResetFillterButton";
import { useCookies } from 'react-cookie';
import Cookies from 'universal-cookie';


import { ACCESS_TOKEN_NAME, ACCESS_TOKEN_NAME_USER, API_BASE_URL } from '../../constants/apiConstants';

const CompletedBox = (props) => {
    const [pageRefresh, setPageRefresh] = useState(false);
    const [cookies, setCookie, removeCookie] = useCookies(['completed']);
    const createPlan = async (product, invoiceRefNumber, resalePORefNumer, resaleVendor, transportPORecordID) => {
        const response = await PlanningServices.getPlanningInfo(invoiceRefNumber);
            if (response[0] && response[0].id) {
                window.open("/exitingPurchaseorder/" + result[0].id + "/" + transportPORecordID);
          
            }
            
        const payload = {
            contactId: 0,
            locationId: 123,
            customerConfirmation: 0,
            driveConfirmation: 0,
            size: 0,
            invoiceId: invoiceRefNumber,
            City: '',
            drivername: '',
            product: product,
            State: '',
            transportPORecordID,
            DropState: '',
            DropCity: '',
            Dist: '',
            resalePORefNumer,
            isExpedited: 0,
            vendorFullName: resaleVendor,
            date: ''
        };
        const result = await PlanningServices.savePlanning(payload);
        if (result) {
            //fetchDispatchedOrder({});
            window.open("/exitingPurchaseorder/" + result[0].lastinsterid + "/" + transportPORecordID);
        }
    }

    const cookiesi = new Cookies();

    const resetFilter = async () => {
        const test = document.querySelectorAll(".material-icons");
        for(let tests of test)
        {
        if(tests.getAttribute("aria-label") == 'clear')
        {
            tests.click();
        }
        }
        
        const cookies = new Cookies();
        if (Object.keys(cookies.getAll()).length > 0) {
            setPageRefresh(true);
        }
        else {
            setPageRefresh(true);
        }
        await removeCookie("orderBy", { path: "/completed" });
        await removeCookie("direction", { path: "/completed" });
        await removeCookie("invoiceRefNumber", { path: "/completed" });
        await removeCookie("invoicePaidDate", { path: "/completed" });
        await removeCookie("invoiceTerms", { path: "/completed" });
        await removeCookie("invoiceLineItemItemNameWithoutParent", { path: "/completed" });
        await removeCookie("invoiceAssignedToName", { path: "/completed" });
        await removeCookie("resalePOPickupCity", { path: "/completed" });
        await removeCookie("resalePORefNumer", { path: "/completed" });
        await removeCookie("resalePOReleaseNumber", { path: "/completed" });
        await removeCookie("resalePOReleaseDate", { path: "/completed" });
        await removeCookie("resalePOTotalPrice", { path: "/completed" });
        await removeCookie("resalePOExpectedDate", { path: "/completed" });
        await removeCookie("resalePOLastDepotCallDate", { path: "/completed" });
        await removeCookie("newDistance", { path: "/completed" });
        await removeCookie("transportPODistance", { path: "/completed" });
        await removeCookie("invoiceShipCity", { path: "/completed" });
        await removeCookie("transportPOLineItemContainerNumber", { path: "/completed" });
        await removeCookie("transportPORefNumer", { path: "/completed" });
        await removeCookie("transportPOVendor", { path: "/completed" });
        await removeCookie("transportPOTotalPrice", { path: "/completed" });
        await removeCookie("transportPOScheduledDeliveryDate", { path: "/completed" });
        await removeCookie("transportPODeliveryStatus", { path: "/completed" });
        await removeCookie("invoicePromisedTimeline", { path: "/completed" });
        await removeCookie("invoicePromisedDate", { path: "/completed" });
        await removeCookie("driverName", { path: "/completed" });
        // await removeCookie("vendorName", { path: "/completed" });

    }
    return (
        <div className="col-md-12">
            {
                (props.data.loading) ?
                    <i class="fa fa-spinner fa-spin center-position" />
                    :
                    <MaterialTable
                        title=""
                        key={pageRefresh}
                        columns={[
                            { title: "RecordID", field: "RecordID", hidden: true },

                            {
                                title: "Customer",
                                defaultSort: cookiesi.getAll()['orderBy'] == 'invoiceCustomerFullName' ? cookiesi.getAll()['direction'] : false,
                                filterComponent: ({ columnDef, onFilterChanged }) => (
                                    <FilterComponentBox pageName={'completed'} onFilterChanged={onFilterChanged} columnDef={columnDef} columnName={columnDef.field} />
                                ),
                                field: "invoiceRefNumber", render: rowData => <a className={`breakwordwichpadding ${rowData.sameTrailerMarker === 1 &&  rowData.invoiceIsExpedited === 'Yes' ? 'markerExp' : rowData.invoiceIsExpedited === 'Yes' ? 'expeditedClass' : rowData.sameTrailerMarker === 1 ? 'sameMarker' : ''}`} target="_blank" href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID}><strong style={{ "font-weight": "550" }} className="title-strong">{rowData.invoiceCustomerFullName?.split(',')[0]}</strong><br />{rowData.invoiceCustomerFullName?.split(',')[1]}</a>
                            },



                            {
                                title: "Paid Date",
                                defaultSort: cookiesi.getAll()['orderBy'] == 'invoicePaidDate' ? cookiesi.getAll()['direction'] : false,
                                filterComponent: (props) => <CustomDatePicker pageName={'completed'} {...props} />,
                                field: "invoicePaidDate", type: 'date', render: rowData => <a className={rowData.sameTrailerMarker === 1 &&  rowData.invoiceIsExpedited === 'Yes' ? 'markerExp' : rowData.invoiceIsExpedited === 'Yes' ? 'expeditedClass' : rowData.sameTrailerMarker === 1 ? 'sameMarker' : ''} target="_blank" href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID}>{(rowData.invoicePaidDate) ? moment(rowData.invoicePaidDate).format('MM/DD/YY') : ''}</a>
                            },



                            {
                                title: "Terms",
                                field: "invoiceTerms",
                                defaultSort: cookiesi.getAll()['orderBy'] == 'invoiceTerms' ? cookiesi.getAll()['direction'] : false,
                                filterComponent: ({ columnDef, onFilterChanged }) => (
                                    <FilterComponentBox pageName={'completed'} onFilterChanged={onFilterChanged} columnDef={columnDef} columnName={columnDef.field} />
                                ),
                                render: rowData => <a className={rowData.sameTrailerMarker === 1 &&  rowData.invoiceIsExpedited === 'Yes' ? 'markerExp' : rowData.invoiceIsExpedited === 'Yes' ? 'expeditedClass' : rowData.sameTrailerMarker === 1 ? 'sameMarker' : ''} target="_blank" href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID}>{rowData.invoiceTerms}</a>
                            },



                            {
                                title: "Product",
                                defaultSort: cookiesi.getAll()['orderBy'] == 'invoiceLineItemItemNameWithoutParent' ? cookiesi.getAll()['direction'] : false,
                                filterComponent: ({ columnDef, onFilterChanged }) => (
                                    <FilterComponentBox pageName={'completed'} onFilterChanged={onFilterChanged} columnDef={columnDef} columnName={columnDef.field} />
                                ),
                                field: "invoiceLineItemItemNameWithoutParent", render: rowData => <a className={rowData.sameTrailerMarker === 1 &&  rowData.invoiceIsExpedited === 'Yes' ? 'markerExp' : rowData.invoiceIsExpedited === 'Yes' ? 'expeditedClass' : rowData.sameTrailerMarker === 1 ? 'sameMarker' : ''} target="_blank" href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID}>{rowData.invoiceLineItemItemNameWithoutParent}</a>
                            },



                            {
                                title: "Assign To",
                                field: "invoiceAssignedToName",
                                defaultSort: cookiesi.getAll()['orderBy'] == 'invoiceAssignedToName' ? cookiesi.getAll()['direction'] : false,
                                filterComponent: ({ columnDef, onFilterChanged }) => (
                                    <FilterComponentBox pageName={'completed'} onFilterChanged={onFilterChanged} columnDef={columnDef} columnName={columnDef.field} />
                                ),
                                render: rowData => <a className={`breakword ${rowData.sameTrailerMarker === 1 &&  rowData.invoiceIsExpedited === 'Yes' ? 'markerExp' : rowData.invoiceIsExpedited === 'Yes' ? 'expeditedClass' : rowData.sameTrailerMarker === 1 ? 'sameMarker' : ''}`} target="_blank" href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID}>{rowData.invoiceAssignedToName}</a>
                            },



                            {
                                title: "Pickup Loc",
                                defaultSort: cookiesi.getAll()['orderBy'] == 'resalePOPickupCity' ? cookiesi.getAll()['direction'] : false,
                                filterComponent: ({ columnDef, onFilterChanged }) => (
                                    <FilterComponentBox pageName={'completed'} onFilterChanged={onFilterChanged} columnDef={columnDef} columnName={columnDef.field} />
                                ),
                                field: "resalePOPickupCity", render: rowData => <a className={`breakword ${rowData.sameTrailerMarker === 1 &&  rowData.invoiceIsExpedited === 'Yes' ? 'markerExp' : rowData.invoiceIsExpedited === 'Yes' ? 'expeditedClass' : rowData.sameTrailerMarker === 1 ? 'sameMarker' : ''}`} target="_blank" href={'https://www.google.com/maps/place/' + rowData.pickupLocation}>{rowData.resalePOPickupCity}<br />{rowData.pickupState}</a>
                            },

                            {
                                title: "Res PO #",
                                defaultSort: cookiesi.getAll()['orderBy'] == 'resalePORefNumer' ? cookiesi.getAll()['direction'] : false,
                                filterComponent: ({ columnDef, onFilterChanged }) => (
                                    <FilterComponentBox pageName={'completed'} onFilterChanged={onFilterChanged} columnDef={columnDef} columnName={columnDef.field} />
                                ),
                                field: "resalePORefNumer", render: rowData => <a className={rowData.sameTrailerMarker === 1 &&  rowData.invoiceIsExpedited === 'Yes' ? 'markerExp' : rowData.invoiceIsExpedited === 'Yes' ? 'expeditedClass' : rowData.sameTrailerMarker === 1 ? 'sameMarker' : ''} target="_blank" href={'/resalePurchaseOrder/' + rowData.ResalePORecordID + '/' + rowData.RecordID}>{rowData.resalePORefNumer}</a>
                            },

                            {
                                title: "Rele #",
                                defaultSort: cookiesi.getAll()['orderBy'] == 'resalePOReleaseNumber' ? cookiesi.getAll()['direction'] : false,
                                filterComponent: ({ columnDef, onFilterChanged }) => (
                                    <FilterComponentBox pageName={'completed'} onFilterChanged={onFilterChanged} columnDef={columnDef} columnName={columnDef.field} />
                                ),
                                field: "resalePOReleaseNumber", render: rowData => <a className={rowData.sameTrailerMarker === 1 &&  rowData.invoiceIsExpedited === 'Yes' ? 'markerExp' : rowData.invoiceIsExpedited === 'Yes' ? 'expeditedClass' : rowData.sameTrailerMarker === 1 ? 'sameMarker' : ''} target="_blank" href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID}>{rowData.resalePOReleaseNumber}</a>
                            },

                            {
                                title: "Release Date",
                                defaultSort: cookiesi.getAll()['orderBy'] == 'resalePOReleaseDate' ? cookiesi.getAll()['direction'] : false,
                                filterComponent: (props) => <CustomDatePicker pageName={'completed'} {...props} />,
                                field: "resalePOReleaseDate", type: 'date', render: rowData => <a className={rowData.sameTrailerMarker === 1 &&  rowData.invoiceIsExpedited === 'Yes' ? 'markerExp' : rowData.invoiceIsExpedited === 'Yes' ? 'expeditedClass' : rowData.sameTrailerMarker === 1 ? 'sameMarker' : ''} target="_blank" href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID}>{(rowData.resalePOReleaseDate) ? moment(rowData.resalePOReleaseDate).format('MM/DD/YY') : ''}</a>
                            },

                            {
                                title: "Res Total",
                                defaultSort: cookiesi.getAll()['orderBy'] == 'resalePOTotalPrice' ? cookiesi.getAll()['direction'] : false,
                                filterComponent: ({ columnDef, onFilterChanged }) => (
                                    <FilterComponentBox pageName={'completed'} onFilterChanged={onFilterChanged} columnDef={columnDef} columnName={columnDef.field} />
                                ),
                                field: "resalePOTotalPrice", render: rowData => <a className={rowData.sameTrailerMarker === 1 &&  rowData.invoiceIsExpedited === 'Yes' ? 'markerExp' : rowData.invoiceIsExpedited === 'Yes' ? 'expeditedClass' : rowData.sameTrailerMarker === 1 ? 'sameMarker' : ''} target="_blank" href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID}>{rowData.resalePOTotalPrice}</a>
                            },

                            
                            // {
                            //     title: "Vendor", field: "vendorName",
                            //     defaultSort: cookiesi.getAll()['orderBy'] == 'vendorName' ? cookiesi.getAll()['direction'] : false,
                            //     filterComponent: ({ columnDef, onFilterChanged }) => (
                            //         <FilterComponentBox pageName={'completed'} onFilterChanged={onFilterChanged} columnDef={columnDef} columnName={columnDef.field} />
                            //     ),
                            //     render: (rowData) => {
                            //         return <a className={`breakword ${rowData.sameTrailerMarker === 1 &&  rowData.invoiceIsExpedited === 'Yes' ? 'markerExp' : rowData.invoiceIsExpedited === 'Yes' ? 'expeditedClass' : rowData.sameTrailerMarker === 1 ? 'sameMarker' : ''}`} target="_blank" href={`https://www.google.com/maps/dir/?api=1&origin=${avoidNull(rowData.resalePOPickupAddress1)}${avoidNull(rowData.resalePOPickupCity)}${avoidNull2(rowData.resalePOPickupZipCode)}&destination=${removeAddress(rowData.invoiceShipAddress1,rowData.shipState)}${removeAddress(rowData.invoiceShipAddress2, rowData.shipState)}${removeAddress(rowData.invoiceShipAddress3, rowData.shipState)}${avoidNull(rowData.invoiceShipCity)}${avoidNull(rowData.shipState)}${avoidNull2(rowData.invoiceShipZip)}`}>{rowData.vendorName}</a>
                            //     }
                            // },

                            {
                                title: "Expected",
                                defaultSort: cookiesi.getAll()['orderBy'] == 'resalePOExpectedDate' ? cookiesi.getAll()['direction'] : false,
                                filterComponent: (props) => <CustomDatePicker pageName={'completed'} {...props} />,
                                type: 'date', field: "resalePOExpectedDate", render: rowData => <a className={rowData.sameTrailerMarker === 1 &&  rowData.invoiceIsExpedited === 'Yes' ? 'markerExp' : rowData.invoiceIsExpedited === 'Yes' ? 'expeditedClass' : rowData.sameTrailerMarker === 1 ? 'sameMarker' : ''} target="_blank" href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID}>{(rowData.resalePOExpectedDate) ? moment(rowData.resalePOExpectedDate).format('MM/DD/YY') : ''}</a>
                            },
                            {
                                title: "Last Depot Call",
                                defaultSort: cookiesi.getAll()['orderBy'] == 'resalePOLastDepotCallDate' ? cookiesi.getAll()['direction'] : false,
                                filterComponent: (props) => <CustomDatePicker pageName={'completed'} {...props} />,
                                field: "resalePOLastDepotCallDate", type: 'date', render: rowData => <a className={rowData.sameTrailerMarker === 1 &&  rowData.invoiceIsExpedited === 'Yes' ? 'markerExp' : rowData.invoiceIsExpedited === 'Yes' ? 'expeditedClass' : rowData.sameTrailerMarker === 1 ? 'sameMarker' : ''} target="_blank" href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID}>{(rowData.resalePOLastDepotCallDate) ? moment(rowData.resalePOLastDepotCallDate).format('MM/DD/YY') : ''}</a>
                            },
                            
                            // {
                            //     title: "Miles",
                            //     defaultSort: cookiesi.getAll()['orderBy'] == 'newDistance' ? cookiesi.getAll()['direction'] : false,
                            //     filterComponent: ({ columnDef, onFilterChanged }) => (
                            //         <FilterComponentBox pageName={'completed'} onFilterChanged={onFilterChanged} columnDef={columnDef} columnName={columnDef.field} />
                            //     ),
                            //     field: "newDistance", render: rowData => <a target="_blank" href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID}>{  rowData.newDistance ? Math.round(rowData.newDistance) : ''}</a>
                            // },
                            {
                                title: "Miles",
                                defaultSort: cookiesi.getAll()['orderBy'] == 'transportPODistance' ? cookiesi.getAll()['direction'] : false,
                                filterComponent: ({ columnDef, onFilterChanged }) => (
                                    <FilterComponentBox pageName={'completed'} onFilterChanged={onFilterChanged} columnDef={columnDef} columnName={columnDef.field} />
                                ),
                                field: "transportPODistance", render: rowData => <a className={rowData.sameTrailerMarker === 1 &&  rowData.invoiceIsExpedited === 'Yes' ? 'markerExp' : rowData.invoiceIsExpedited === 'Yes' ? 'expeditedClass' : rowData.sameTrailerMarker === 1 ? 'sameMarker' : ''} target="_blank" href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID}>{  rowData.transportPODistance ? Math.round(rowData.transportPODistance) : ''}</a>
                            },

                            {
                                title: "Del City/State",
                                defaultSort: cookiesi.getAll()['orderBy'] == 'invoiceShipCity' ? cookiesi.getAll()['direction'] : false,
                                filterComponent: ({ columnDef, onFilterChanged }) => (
                                    <FilterComponentBox pageName={'completed'} onFilterChanged={onFilterChanged} columnDef={columnDef} columnName={columnDef.field} />
                                ),
                                field: "invoiceShipCity", render: (rowData) => {
                                    return <a className={`breakword ${rowData.sameTrailerMarker === 1 &&  rowData.invoiceIsExpedited === 'Yes' ? 'markerExp' : rowData.invoiceIsExpedited === 'Yes' ? 'expeditedClass' : rowData.sameTrailerMarker === 1 ? 'sameMarker' : ''}`} target="_blank" href={`https://www.google.com/maps/dir/?api=1&origin=${avoidNull(rowData.resalePOPickupAddress1)}${avoidNull(rowData.resalePOPickupCity)}${avoidNull2(rowData.resalePOPickupZipCode)}&destination=${removeAddress(rowData.invoiceShipAddress1)}${removeAddress(rowData.invoiceShipAddress2)}${removeAddress(rowData.invoiceShipAddress3)}${avoidNull(rowData.invoiceShipCity)}${avoidNull(rowData.shipState)}${avoidNull2(rowData.invoiceShipZip)}`}>{rowData.shipCity}<br />{rowData.shipState}</a>
                                }
                            },

                            {
                                title: "Container #",
                                defaultSort: cookiesi.getAll()['orderBy'] == 'transportPOLineItemContainerNumber' ? cookiesi.getAll()['direction'] : false,
                                filterComponent: ({ columnDef, onFilterChanged }) => (
                                    <FilterComponentBox pageName={'completed'} onFilterChanged={onFilterChanged} columnDef={columnDef} columnName={columnDef.field} />
                                ),
                                field: "transportPOLineItemContainerNumber", render: rowData => <a className={rowData.sameTrailerMarker === 1 &&  rowData.invoiceIsExpedited === 'Yes' ? 'markerExp' : rowData.invoiceIsExpedited === 'Yes' ? 'expeditedClass' : rowData.sameTrailerMarker === 1 ? 'sameMarker' : ''} target="_blank" href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID}>{rowData.transportPOLineItemContainerNumber}</a>
                            },

                            {
                                title: "Tran PO #",
                                defaultSort: cookiesi.getAll()['orderBy'] == 'transportPORefNumer' ? cookiesi.getAll()['direction'] : false,
                                filterComponent: ({ columnDef, onFilterChanged }) => (
                                    <FilterComponentBox pageName={'completed'} onFilterChanged={onFilterChanged} columnDef={columnDef} columnName={columnDef.field} />
                                ),
                                field: "transportPORefNumer", render: rowData => (rowData.planningId) ? <a className={rowData.sameTrailerMarker === 1 &&  rowData.invoiceIsExpedited === 'Yes' ? 'markerExp' : rowData.invoiceIsExpedited === 'Yes' ? 'expeditedClass' : rowData.sameTrailerMarker === 1 ? 'sameMarker' : ''} target="_blank" href={'/exitingPurchaseorder/' + rowData.planningId + '/' + rowData.TransportPORecordID}>{rowData.transportPORefNumer}</a> : <a className={rowData.sameTrailerMarker === 1 &&  rowData.invoiceIsExpedited === 'Yes' ? 'markerExp' : rowData.invoiceIsExpedited === 'Yes' ? 'expeditedClass' : rowData.sameTrailerMarker === 1 ? 'sameMarker' : ''} href="javascript:void(0)" onClick={() => createPlan(rowData.invoiceLineItemItemNameWithoutParent, rowData.orderStatusRecordID, rowData.resalePORefNumer, rowData.transportPOVendor, rowData.TransportPORecordID)} >{rowData.transportPORefNumer}</a>
                            },

                            {
                                title: "Driver", field: "driverName",
                                defaultSort: cookiesi.getAll()['orderBy'] == 'driverName' ? cookiesi.getAll()['direction'] : false,
                                filterComponent: ({ columnDef, onFilterChanged }) => (
                                    <FilterComponentBox pageName={'completed'} onFilterChanged={onFilterChanged} columnDef={columnDef} columnName={columnDef.field} />
                                ),
                                render: (rowData) => {
                                    return <a className={`breakword ${rowData.sameTrailerMarker === 1 &&  rowData.invoiceIsExpedited === 'Yes' ? 'markerExp' : rowData.invoiceIsExpedited === 'Yes' ? 'expeditedClass' : rowData.sameTrailerMarker === 1 ? 'sameMarker' : ''}`} target="_blank" href={`https://www.google.com/maps/dir/?api=1&origin=${avoidNull(rowData.resalePOPickupAddress1)}${avoidNull(rowData.resalePOPickupCity)}${avoidNull2(rowData.resalePOPickupZipCode)}&destination=${removeAddress(rowData.invoiceShipAddress1,rowData.shipState)}${removeAddress(rowData.invoiceShipAddress2, rowData.shipState)}${removeAddress(rowData.invoiceShipAddress3, rowData.shipState)}${avoidNull(rowData.invoiceShipCity)}${avoidNull(rowData.shipState)}${avoidNull2(rowData.invoiceShipZip)}`}>{rowData.DriverName}</a>
                                }
                            },

                            {
                                title: "Dri & Car",
                                defaultSort: cookiesi.getAll()['orderBy'] == 'transportPOVendor' ? cookiesi.getAll()['direction'] : false,
                                filterComponent: ({ columnDef, onFilterChanged }) => (
                                    <FilterComponentBox pageName={'completed'} onFilterChanged={onFilterChanged} columnDef={columnDef} columnName={columnDef.field} />
                                ),
                                field: "transportPOVendor", render: rowData => <a className={`breakword ${rowData.sameTrailerMarker === 1 &&  rowData.invoiceIsExpedited === 'Yes' ? 'markerExp' : rowData.invoiceIsExpedited === 'Yes' ? 'expeditedClass' : rowData.sameTrailerMarker === 1 ? 'sameMarker' : ''}`} target="_blank"  href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID}>{rowData.transportPOVendor}<br /> {rowData.driverName}</a>
                            },

                            {
                                title: "Tran Total",
                                defaultSort: cookiesi.getAll()['orderBy'] == 'transportPOTotalPrice' ? cookiesi.getAll()['direction'] : false,
                                filterComponent: ({ columnDef, onFilterChanged }) => (
                                    <FilterComponentBox pageName={'completed'} onFilterChanged={onFilterChanged} columnDef={columnDef} columnName={columnDef.field} />
                                ),
                                field: "transportPOTotalPrice", render: rowData => <a className={rowData.sameTrailerMarker === 1 &&  rowData.invoiceIsExpedited === 'Yes' ? 'markerExp' : rowData.invoiceIsExpedited === 'Yes' ? 'expeditedClass' : rowData.sameTrailerMarker === 1 ? 'sameMarker' : ''} target="_blank" href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID}>{rowData.transportPOTotalPrice}</a>
                            },

                            {
                                title: "Sch For",
                                defaultSort: cookiesi.getAll()['orderBy'] == 'transportPOScheduledDeliveryDate' ? cookiesi.getAll()['direction'] : false,
                                filterComponent: (props) => <CustomDatePicker pageName={'completed'} {...props} />,
                                field: "transportPOScheduledDeliveryDate", type: 'date', render: rowData => <a className={rowData.sameTrailerMarker === 1 &&  rowData.invoiceIsExpedited === 'Yes' ? 'markerExp' : rowData.invoiceIsExpedited === 'Yes' ? 'expeditedClass' : rowData.sameTrailerMarker === 1 ? 'sameMarker' : ''} target="_blank" href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID}>{(rowData.transportPOScheduledDeliveryDate) ? moment(rowData.transportPOScheduledDeliveryDate).format('MM/DD/YY') : ''}</a>
                            },

                            {
                                title: "Del Status",
                                defaultSort: cookiesi.getAll()['orderBy'] == 'transportPODeliveryStatus' ? cookiesi.getAll()['direction'] : false,
                                filterComponent: ({ columnDef, onFilterChanged }) => (
                                    <FilterComponentBox pageName={'completed'} onFilterChanged={onFilterChanged} columnDef={columnDef} columnName={columnDef.field} />
                                ),
                                field: "transportPODeliveryStatus", render: rowData => <a className={rowData.sameTrailerMarker === 1 &&  rowData.invoiceIsExpedited === 'Yes' ? 'markerExp' : rowData.invoiceIsExpedited === 'Yes' ? 'expeditedClass' : rowData.sameTrailerMarker === 1 ? 'sameMarker' : ''} target="_blank" href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID}>{rowData.transportPODeliveryStatus}</a>
                            },

                            {
                                title: "Prom Timeline",
                                defaultSort: cookiesi.getAll()['orderBy'] == 'invoicePromisedTimeline' ? cookiesi.getAll()['direction'] : false,
                                filterComponent: ({ columnDef, onFilterChanged }) => (
                                    <FilterComponentBox pageName={'completed'} onFilterChanged={onFilterChanged} columnDef={columnDef} columnName={columnDef.field} />
                                ),
                                field: "invoicePromisedTimeline", render: rowData => <a className={rowData.sameTrailerMarker === 1 &&  rowData.invoiceIsExpedited === 'Yes' ? 'markerExp' : rowData.invoiceIsExpedited === 'Yes' ? 'expeditedClass' : rowData.sameTrailerMarker === 1 ? 'sameMarker' : ''} target="_blank" href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID}>{rowData.invoicePromisedTimeline}</a>
                            },

                            {
                                title: "Prom Date",
                                defaultSort: cookiesi.getAll()['orderBy'] == 'invoicePromisedDate' ? cookiesi.getAll()['direction'] : false,
                                filterComponent: (props) => <CustomDatePicker pageName={'completed'} {...props} />,
                                field: "invoicePromisedDate", type: 'date', render: rowData => <a className={rowData.sameTrailerMarker === 1 &&  rowData.invoiceIsExpedited === 'Yes' ? 'markerExp' : rowData.invoiceIsExpedited === 'Yes' ? 'expeditedClass' : rowData.sameTrailerMarker === 1 ? 'sameMarker' : ''} target="_blank" href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID}>{(rowData.invoicePromisedDate) ? moment(rowData.invoicePromisedDate).format('MM/DD/YY') : ''}</a>
                            },
                        ]}
                        data={query =>

                            new Promise((resolve, reject) => {
                                const cookies = new Cookies();
                                cookies.remove('adminer_version');
                                if (query.filters.length > 0 || Object.keys(cookies.getAll()).length > 0) {
                                    let url = API_BASE_URL + `/fetchOrderBasedOnColumn?`;
                                    url += 'size=' + query.pageSize;
                                    url += '&page=' + (query.page + 1);
                                    url += '&orderNumber=' + 11;
                                    if (query.orderBy) {
                                        removeCookie("orderBy", { path: "/completed" });
                                        removeCookie("direction", { path: "/completed" });
                                        if (query.orderBy.field == 'invoiceRefNumber') {

                                            setCookie("orderBy", "invoiceCustomerFullName", { path: '/completed' });
                                        }
                                        else {
                                            setCookie("orderBy", query.orderBy.field, { path: '/completed' });


                                        }
                                        setCookie("direction", query.orderDirection, { path: '/completed' });
                                    }
                                    else {
                                        url += '&sortField=transportPODeliveredDate';
                                        url += '&sortType=desc';
                                        url += '&sortField=invoiceCustomerFullName';
                                        url += '&sortType=asc';
                                        url += '&sortField=resalePORecordID';
                                        url += '&sortType=asc';
                                    }

                                    if (Object.keys(cookies.getAll()).length > 0) {

                                        for (const key in cookies.cookies) {

                                            console.log(moment.isDate(cookies.cookies[key].replace('"', '').replace('"', '')), 'momentinfo', cookies.cookies[key], key);
                                            if(cookies.cookies[key] == 'Invalid date')
                                            {
                                                console.log('okkk')
                                                 removeCookie(key, { path: "/completed" });
                                            }
                                            
                                            else
                                            {
                                                if (cookies.cookies[key] && moment.isDate(cookies.cookies[key])) {
                                                cookies.cookies[key] = moment(cookies.cookies[key]).format('YYYY-MM-DD')
                                            }

                                            if (cookies.cookies[key]) {
                                                if (key == 'invoiceRefNumber' && isNaN(cookies.cookies[key])) {
                                                    url += '&columnName=' + 'invoiceCustomerFullName';
                                                    if (cookies.cookies[key].toLowerCase() == 'yes') {
                                                        url += '&search=' + 1;
                                                    } else if (cookies.cookies[key].toLowerCase() == 'no') {
                                                        url += '&search=' + 0;
                                                    } else {
                                                        url += '&search=' + encodeURIComponent(cookies.cookies[key]);
                                                    }
                                                }
                                                else if (key == 'orderBy') {

                                                    url += '&sortField=' + cookies.cookies[key]

                                                    // url += '&sortType=' + cookies.cookies['direction']
                                                }
                                                else if (key == 'direction') {
                                                    url += '&sortType=' + cookies.cookies[key]
                                                }
                                                else {
                                                    url += '&columnName=' + key;
                                                    if (cookies.cookies[key].toLowerCase() == 'yes') {
                                                        url += '&search=' + 1;
                                                    } else if (cookies.cookies[key].toLowerCase() == 'no') {
                                                        url += '&search=' + 0;
                                                    }

                                                    else {
                                                        url += '&search=' + encodeURIComponent(cookies.cookies[key]);
                                                    }
                                                }
                                            } 
                                            }

                                    
                                        }
                                    } else {
                                        query.filters.forEach((item) => {
                                            if (item.value && moment.isDate(item.value)) {
                                                item.value = moment(item.value).format('YYYY-MM-DD')
                                            }

                                            if (item.column.field == 'invoiceRefNumber' && isNaN(parseInt(item.value))) {
                                                url += '&columnName=' + 'invoiceCustomerFullName';
                                                url += '&search=' + encodeURIComponent(item.value);
                                            } else {
                                                url += '&columnName=' + item.column.field;
                                                url += '&search=' + encodeURIComponent(item.value);
                                            }
                                        });
                                    }


                                    axios.get(url, {
                                        headers: {
                                            Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN_NAME),
                                        },
                                    }).then(result => {
                                        resolve({
                                            data: result.data.rows.map((data) => ({
                                                orderStatusRecordID: data.recordID,
                                                RecordID: data.invoiceRecordID,
                                                invoiceCustomerFullName: `${data.invoiceCustomerFullName},${data.invoiceRefNumber}`,
                                                InvoiceRefNumber: data.invoiceRefNumber,
                                                invoicePaidDate: (data.invoicePaidDate) ? data.invoicePaidDate?.split('T')[0] : '',
                                                invoiceTerms: data.invoiceTerms,
                                                resalePOExpectedDate: (data.resalePOExpectedDate) ? data.resalePOExpectedDate?.split('T')[0] : '',
                                                transportPORefNumer: (data.transportPORecordID) ? data.transportPORefNumer : data.invoiceRefNumber + 'T',
                                                TransportPORecordID: data.transportPORecordID,
                                                transportPOTotalPrice: data.transportPOTotalPrice,
                                                transportPOScheduledDeliveryDate: (data.transportPOScheduledDeliveryDate) ? data.transportPOScheduledDeliveryDate?.split('T')[0] : '',
                                                invoiceLineItemItemNameWithoutParent: data.invoiceLineItemItemNameWithoutParent,
                                                invoiceAssignedToName: data.invoiceAssignedToName,
                                                SupplierLocation: data.invoiceSupplierLocation,
                                                resalePORefNumer: data.resalePORefNumer,
                                                ResalePORecordID: data.recordID,
                                                resalePOPickupCity: (data.resalePOPickupCity) ? data.resalePOPickupCity : '',
                                                pickupState: (data.resalePOPickupState) ? data.resalePOPickupState : '',
                                                planningId: (data.planning) ? data.planning.id : null,
                                                resalePODistance: data.resalePODistance,
                                                newDistance: data.newDistance ? data.newDistance : null,
                                                transportPODistance: data.transportPODistance ? data.transportPODistance : null,
                                                transportPOVendor: data.transportPOVendor,
                                                driverName: data.transportPODriverContactFullName,
                                                solidSurface: (data.invoice?.solidSurface) ? data.invoice.solidSurface : 0,
                                                towTruck: (data.invoice?.towTruck) ? data.invoice.towTruck : 0,
                                                resalePOPickupAddress1: data.resalePOPickupAddress1,
                                                invoiceShipAddress1: data.invoiceShipAddress1,
                                                invoiceShipAddress2: data.invoiceShipAddress2,
                                                invoiceShipAddress3: data.invoiceShipAddress3,
                                                shipCity: (data.invoiceShipCity) ? data.invoiceShipCity : '',
                                                shipState: (data.invoiceShipState) ? data.invoiceShipState : '',
                                                invoiceIsExpedited: data.invoiceIsExpedited
                                                ? "Yes"
                                                : "No",

                                                invoiceLineItem_RecordID: data.invoiceLineItem_RecordID,
                                                DriverName: (data.planning) ? data.planning.driverName : null,
                                                // vendorName: (data.purchaseOrder) ? data.purchaseOrder.vendor : null,
                                                sameTrailerMarker: data.sameTrailerMarker,
                                                resalePOPickupZipCode: data.resalePOPickupZipCode,
                                                transportPOLineItemContainerNumber: (data.transportPOLineItemContainerNumber) ? data.transportPOLineItemContainerNumber : '',
                                                ResaleVendor: data.resalePOVendor,
                                                resalePOReleaseNumber: data.resalePOReleaseNumber,
                                                resalePOReleaseDate: (data.resalePOReleaseDate) ? data.resalePOReleaseDate?.split('T')[0] : '',
                                                resalePOTotalPrice: data.resalePOTotalPrice,
                                                ExpectedDeliveryDate: (data.resalePOExpectedDate) ? data.resalePOExpectedDate?.split('T')[0] : '',
                                                resalePOLastDepotCallDate: (data.resalePOLastDepotCallDate) ? data.resalePOLastDepotCallDate?.split('T')[0] : '',
                                                ShippingCity: (data.invoiceShipCity && data.invoiceShipState) ? data.invoiceShipCity + ' ' + data.invoiceShipState : '',
                                                transportPODeliveryStatus: data.transportPODeliveryStatus,
                                                invoicePromisedTimeline: (data.invoicePromisedTimeline) ? data.invoicePromisedTimeline : '',
                                                invoicePromisedDate: (data.invoicePromisedDate) ? data.invoicePromisedDate?.split('T')[0] : '',
                                            })),
                                            page: result.data.currentPage - 1,
                                            totalCount: result.data.totalItems,
                                        });
                                        setPageRefresh(false);
                                    });
                                } else {
                                    let url = API_BASE_URL + `/fetchCompletedOrder?`;
                                    url += 'size=' + query.pageSize;
                                    url += '&page=' + (query.page + 1);
                                    url += '&orderNumber=' + 11;
                                    if (query.orderBy) {
                                        if (query.orderBy.field == 'invoiceRefNumber') {
                                            url += '&sortField=invoiceRefNumber'
                                            setCookie("orderBy", "invoiceCustomerFullName", { path: '/completed' });
                                        }
                                        else {
                                            setCookie("orderBy", query.orderBy.field, { path: '/completed' });
                                            url += '&sortField=' + query.orderBy.field

                                        }
                                        setCookie("direction", query.orderDirection, { path: '/completed' });
                                        url += '&sortType=' + query.orderDirection
                                    } else {
                                        url += '&sortField=transportPODeliveredDate';
                                        url += '&sortType=desc';
                                        url += '&sortField=invoiceCustomerFullName';
                                        url += '&sortType=asc';
                                        url += '&sortField=resalePORecordID';
                                        url += '&sortType=asc';
                                    }

                                    if (query.search) {
                                        url += '&search=' + encodeURIComponent(query.search);
                                    }

                                    if (query.filters && !query.search) {
                                        query.filters.forEach((item) => {
                                            url += '&search=' + encodeURIComponent(item.value);
                                        });
                                    }
                                    axios.get(url, {
                                        headers: {
                                            Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN_NAME),
                                        },
                                    }).then(result => {
                                        resolve({
                                            data: result.data.rows.map((data) => ({
                                                orderStatusRecordID: data.recordID,
                                                RecordID: data.invoiceRecordID,
                                                invoiceCustomerFullName: `${data.invoiceCustomerFullName},${data.invoiceRefNumber}`,
                                                InvoiceRefNumber: data.invoiceRefNumber,
                                                invoicePaidDate: (data.invoicePaidDate) ? data.invoicePaidDate?.split('T')[0] : '',
                                                invoiceTerms: data.invoiceTerms,
                                                resalePOExpectedDate: (data.resalePOExpectedDate) ? data.resalePOExpectedDate?.split('T')[0] : '',
                                                transportPORefNumer: (data.transportPORecordID) ? data.transportPORefNumer : data.invoiceRefNumber + 'T',
                                                TransportPORecordID: data.transportPORecordID,
                                                transportPOTotalPrice: data.transportPOTotalPrice,
                                                transportPOScheduledDeliveryDate: (data.transportPOScheduledDeliveryDate) ? data.transportPOScheduledDeliveryDate?.split('T')[0] : '',
                                                invoiceLineItemItemNameWithoutParent: data.invoiceLineItemItemNameWithoutParent,
                                                invoiceAssignedToName: data.invoiceAssignedToName,
                                                SupplierLocation: data.invoiceSupplierLocation,
                                                resalePORefNumer: data.resalePORefNumer,
                                                ResalePORecordID: data.recordID,
                                                resalePOPickupCity: (data.resalePOPickupCity) ? data.resalePOPickupCity : '',
                                                pickupState: (data.resalePOPickupState) ? data.resalePOPickupState : '',
                                                planningId: (data.planning) ? data.planning.id : null,
                                                resalePODistance: data.resalePODistance,
                                                newDistance: data.newDistance ? data.newDistance : null,
                                                transportPODistance: data.transportPODistance ? data.transportPODistance : null,
                                               // resalePOLineItemDistance: (data.resalePOLineItemDistance) ? data.resalePOLineItemDistance : data.purchaseOrder?.distanceBetweenPickupAndDeliveryAddress,
                                                transportPOVendor: data.transportPOVendor,
                                                driverName: data.transportPODriverContactFullName,
                                                solidSurface: (data.invoice?.solidSurface) ? data.invoice.solidSurface : 0,
                                                towTruck: (data.invoice?.towTruck) ? data.invoice.towTruck : 0,
                                                resalePOPickupAddress1: data.resalePOPickupAddress1,
                                                invoiceShipAddress1: data.invoiceShipAddress1,
                                                invoiceShipAddress2: data.invoiceShipAddress2,
                                                invoiceShipAddress3: data.invoiceShipAddress3,
                                                shipCity: (data.invoiceShipCity) ? data.invoiceShipCity : '',
                                                shipState: (data.invoiceShipState) ? data.invoiceShipState : '',
                                                invoiceIsExpedited: data.invoiceIsExpedited
                                                ? "Yes"
                                                : "No",
                                                sameTrailerMarker: data.sameTrailerMarker,
                                                invoiceLineItem_RecordID: data.invoiceLineItem_RecordID,
                                                DriverName: (data.planning) ? data.planning.driverName : null,
                                                // vendorName: (data.purchaseOrder) ? data.purchaseOrder.vendor : null,
                                                
                                                transportPOLineItemContainerNumber: (data.transportPOLineItemContainerNumber) ? data.transportPOLineItemContainerNumber : '',
                                                ResaleVendor: data.resalePOVendor,
                                                resalePOReleaseNumber: data.resalePOReleaseNumber,
                                                resalePOReleaseDate: (data.resalePOReleaseDate) ? data.resalePOReleaseDate?.split('T')[0] : '',
                                                resalePOTotalPrice: data.resalePOTotalPrice,
                                                ExpectedDeliveryDate: (data.resalePOExpectedDate) ? data.resalePOExpectedDate?.split('T')[0] : '',
                                                resalePOLastDepotCallDate: (data.resalePOLastDepotCallDate) ? data.resalePOLastDepotCallDate?.split('T')[0] : '',
                                                ShippingCity: (data.invoiceShipCity && data.invoiceShipState) ? data.invoiceShipCity + ' ' + data.invoiceShipState : '',
                                                transportPODeliveryStatus: data.transportPODeliveryStatus,
                                                invoicePromisedTimeline: (data.invoicePromisedTimeline) ? data.invoicePromisedTimeline : '',
                                                invoicePromisedDate: (data.invoicePromisedDate) ? data.invoicePromisedDate?.split('T')[0] : '',
                                            })),
                                            page: result.data.currentPage - 1,
                                            totalCount: result.data.totalItems,
                                        });
                                        setPageRefresh(false);
                                    });
                                }
                            })
                        }

                        components={{
                            Toolbar: props => (
                                <ResetFillterButton elsement={props} resetFilter={resetFilter} />
                            ),
                        }}

                        options={{
                            thirdSortClick: false,

                            // rowStyle: rowData => ({
                            //     backgroundColor: (rowData.invoiceTerms == "Rent To Own" && rowData.solidSurface == 1 ) ? '#90EE90' : (rowData.invoiceTerms == "Rent To Own") ? '#ffda73' : (rowData.solidSurface == 1) ? '#7bede9' : '#fff'
                            // }),
                            rowStyle: rowData => ({
                                backgroundColor: (rowData.invoiceTerms == "Rent To Own" && rowData.towTruck == 1 ) ? '#F7AE77' : (rowData.invoiceTerms == "Rent To Own" && rowData.solidSurface == 1 ) ? '#90EE90' : (rowData.towTruck == 1 && rowData.solidSurface == 1 ) ? '#F0BBF0' : (rowData.invoiceTerms == "Rent To Own") ? '#ffda73' : (rowData.solidSurface == 1) ? '#7bede9' : (rowData.towTruck == 1) ? 'pink' : '#fff'
                            }),

                            debounceInterval: 1500,
                            pageSize: 50,
                            pageSizeOptions: [50, 100, 200],
                            filtering: true
                        }}
                    />
            }
            <style jsx="true">{`

        .btn-set{
            position: absolute;
            top: 18px;
            font-size: 17px;
            right: 255px;
            color: #000;
            padding: 4px 6px;
            border: 1px solid #79a3ff;
        }
            .MuiTableCell-root{
                padding: 2px !important;
                font-family: 'Source Sans Pro', sans-serif;
            }
            .input-wid-set {
                border: none;
                border-bottom: 1px solid #949494;
                position: relative;
                top: 3px;
                width: 100%;
            }
            .MuiTableCell-root a {
                font-size:12px !important;
                font-family: 'Source Sans Pro', sans-serif;
                overflow: visible;
                display: block;
                text-overflow: clip;
                word-break: break-word;
                white-space: break-spaces!important;            
            }
            .MuiTableHead-root th {
                font-size: 12px !important;
                font-weight: 600;
                background: #fbf5f5;
            }
            tr td:first-child.MuiTableCell-root a, tr th:first-child span.MuiButtonBase-root.MuiTableSortLabel-root {
                width: 100%!important;
            }
        `}</style>
        </div>
    );
};

export default CompletedBox;