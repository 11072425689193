import { API_BASE_URL, ACCESS_TOKEN_NAME } from '../../../constants/apiConstants';
import axios from 'axios'
import moment from 'moment-timezone';

export default class PurchaseOrderServices {
  static async createOrUpdatePurchaseOrder(values) {
    const token = localStorage.getItem(ACCESS_TOKEN_NAME);
    const response = await axios.post(API_BASE_URL + `/createOrUpdatePurchaseOrder`, values , {
        headers: {
          Authorization: "Bearer " + token,
        },
    });
    return response.data;
  }

  static async checkPlanning(transportPO, planningId) {
    const token = localStorage.getItem(ACCESS_TOKEN_NAME);
    const obj = {
      transportPO: transportPO,
      planningId: planningId
    }

    const response = await axios.post(API_BASE_URL + `/checkPlanning`, obj, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    return response.data;
  }

  static async updateFinalizeUpdate(finalizeUpdate) {
    const token = localStorage.getItem(ACCESS_TOKEN_NAME);

    const response = await axios.post(API_BASE_URL + `/updateFinalizeUpdate`, finalizeUpdate, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    return response.data;
  }

  static async updatePurchaseOrderStatus(orderid , statusId) {

    //var orderid = parseInt(orderid.replace("T", ""));
    
    const token = localStorage.getItem(ACCESS_TOKEN_NAME);
    const response = await axios.put(API_BASE_URL + `/updateOrderStatus?recordID=`+orderid, {orderStatus:statusId} , {
        headers: {
          Authorization: "Bearer " + token,
        },
    });
    return response.data;
  }


  static async updatePlanningOrderStatus(orderid , statusId) {
    const token = localStorage.getItem(ACCESS_TOKEN_NAME);
    const response = await axios.put(API_BASE_URL + `/updateOrderStatus?recordID=`+orderid, {orderStatus:statusId} , {
        headers: {
          Authorization: "Bearer " + token,
        },
    });
    return response.data;
  }

  static async createInvoicePdf(orderid, driverid,vendorecordID, date , deliveryNotes , messages, transportPORecordID, invoiceRecordID, vendorId) {
    const token = localStorage.getItem(ACCESS_TOKEN_NAME);
    const response = await axios.post(API_BASE_URL + `/createInvoicePdf`, 
      {
        transportPORecordID: transportPORecordID,
        recordID : orderid,
        driverid : driverid,
        date : date,
        vendorecordID,
        deliveryNotes,
        messages,
        userID : localStorage.getItem('userId'),
        invoiceRecordID,
        vendorId
      },
      {
        headers: {
          Authorization: "Bearer " + token,
      },
    });
    return response.data;
  }


  static async finalizeOrderSync(obj1,invoiceTerm,obj2) {
    const obj ={obj1,invoiceTerm:invoiceTerm,obj2}
    console.log(obj, 'checkonnnnnnn')
    const token = localStorage.getItem(ACCESS_TOKEN_NAME);
    const response = await axios.post(API_BASE_URL + `/finalizeOrderSync`, obj,
    {
      headers: {
        Authorization: "Bearer " + token,
    },
  })
  return response.data;
  }



  static async finalEmailDelivery(orderid, driverid,vendorecordID, date , deliveryNotes , messages, transportPORecordID, invoiceRecordID, vendorId) {
    const token = localStorage.getItem(ACCESS_TOKEN_NAME);
    const response = await axios.post(API_BASE_URL + `/finalEmailDelivery`, 
      {
        transportPORecordID: transportPORecordID,
        recordID : orderid,
        driverid : driverid,
        date : date,
        vendorecordID,
        deliveryNotes,
        messages,
        userID : localStorage.getItem('userId'),
        invoiceRecordID,
        vendorId
      },
      {
        headers: {
          Authorization: "Bearer " + token,
      },
    });
    return response.data;
  }
  

  static async pdfGenerator(orderid, driverid,vendorecordID, date , deliveryNotes , messages) {
    const token = localStorage.getItem(ACCESS_TOKEN_NAME);
    const response = await axios.post(API_BASE_URL + `/pdfgenerate`, 
      {
        recordID : orderid,
        driverid : driverid,
        date : date,
        vendorecordID,
        deliveryNotes,
        messages,
        userID : localStorage.getItem('userId')
      },
      {
        headers: {
          Authorization: "Bearer " + token,
      },
    });
    return response.data;
  }

  static async validateGoogleAddress(shipAddressAddr1,shipAddressAddr2,shipAddressAddr3,shipAddressCity,shipAddressState,shipAddressPostalCode,shipAddressCountry) {
    const token = localStorage.getItem(ACCESS_TOKEN_NAME);
    var response = await axios.post(API_BASE_URL + `/validateAddress`, {
      shipAddressAddr1 : shipAddressAddr1,
      shipAddressAddr2 : shipAddressAddr2,
      shipAddressAddr3 : shipAddressAddr3,
      shipAddressCity:shipAddressCity,
      shipAddressState:shipAddressState,
      shipAddressPostalCode:shipAddressPostalCode,
      shipAddressCountry : shipAddressCountry
    },
     {
      headers: {
        Authorization: "Bearer " + token,
      },
  });
  return response.data;
  }

  static async validateZipAddress(pickup,ship,pickupAddress,pickupCity,pickupState)
  {
    const token = localStorage.getItem(ACCESS_TOKEN_NAME);
    var response = await axios.post(API_BASE_URL + `/validateZipAddress`, {
      PickupZipCode : pickup,
      ShipZip : ship,
      pickupAddress: pickupAddress,
      pickupCity: pickupCity,
      pickupState: pickupState,
       },
     {
      headers: {
        Authorization: "Bearer " + token,
      },
  });
  return response.data;
  }


  static async notifyToDriver(orderid , driverid , vendorecordID, date , deliveryNotes , messages) {
    const token = localStorage.getItem(ACCESS_TOKEN_NAME);
    const response = await axios.post(API_BASE_URL + `/emailNotification`, 
      {
        recordID : orderid,
        driverid : driverid,
        date : date,
        vendorecordID,
        deliveryNotes,
        messages,
        userID : localStorage.getItem('userId')
      },
       
      {
        headers: {
          Authorization: "Bearer " + token,
      },
    });
    return response.data;

  }

  static async multiContainerDistanceVariance(invoiceRefNumber, invoiceRecordID, transportPO, distance, pickupAddress) {
    const token = localStorage.getItem(ACCESS_TOKEN_NAME);
    var response = await axios.post(API_BASE_URL + `/multiContainerDistanceVariance?invoiceRefNumber`,
    {
      invoiceRefNumber : invoiceRefNumber,
      invoiceRecordID : invoiceRecordID,
      transportPo : transportPO,
      distance: distance,
      pickupAddress: pickupAddress
    },
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    return response.data;
  }

  static async getPurchasedOrderStatus(invoice_id) {
    const token = localStorage.getItem(ACCESS_TOKEN_NAME);
    var response = await axios.get(API_BASE_URL + `/getPurchasedOrderStatus?recordID=`+invoice_id, {
        headers: {
          Authorization: "Bearer " + token,
        },
    });
    return response.data;
  }

  static async getPurchasedOrderByrecordID(recordID) {
    const token = localStorage.getItem(ACCESS_TOKEN_NAME);
    var response = await axios.get(API_BASE_URL + `/getPurchasedOrderByrecordID?recordID=`+recordID, {
        headers: {
          Authorization: "Bearer " + token,
        },
    });
    return response.data;
  }

  static async updateTransportPOStatus(purchase_recordid,action) {
    const token = localStorage.getItem(ACCESS_TOKEN_NAME);
    var response = await axios.get(API_BASE_URL + `/updateTransportPOStatus?recordID=`+purchase_recordid+`&action=`+action, {
        headers: {
          Authorization: "Bearer " + token,
        },
    });
    return response.data;
  }

  static async getContainerNumber(recordID) {
    const token = localStorage.getItem(ACCESS_TOKEN_NAME);
    var response = await axios.get(API_BASE_URL + `/getContainerNumber?recordID=`+recordID, {
        headers: {
          Authorization: "Bearer " + token,
        },
    });
    return response.data;
  }

  static async doAddBillingToMethod(data){
      const token = localStorage.getItem(ACCESS_TOKEN_NAME);
      const response = await axios.post(API_BASE_URL + `/finalizePurchaseOrder`, data , {
          headers: {
            Authorization: "Bearer " + token,
          },
      });
    return response.data;
  }

  static async doAddPurchaseOrderLineToMethod (data) {
    const token = localStorage.getItem(ACCESS_TOKEN_NAME);
    const response = await axios.post(API_BASE_URL + `/finalizePurchaseOrderLine`, data , {
        headers: {
          Authorization: "Bearer " + token,
        },
    });
    return response.data;
  }

  static async getFinalizeStatus(invoiceid) {
    const refNumber = invoiceid+'T';
    const token = localStorage.getItem(ACCESS_TOKEN_NAME);
    var response = await axios.get(API_BASE_URL + `/getFinalizeStatus?refNumber=`+refNumber, {
        headers: {
          Authorization: "Bearer " + token,
        },
    });
    return response.data;
  }
  
  static async getTransportPurchaseOrderInfo(recordID) {
    const token = localStorage.getItem(ACCESS_TOKEN_NAME);
    var response = await axios.get(API_BASE_URL + `/getTransportPurchaseOrderInfo?recordId=`+recordID, {
        headers: {
          Authorization: "Bearer " + token,
        },
    });
    return response.data;
  }

  static async getransportPurchaseOrderRecordID(planningID) {
    const token = localStorage.getItem(ACCESS_TOKEN_NAME);
    var response = await axios.post(API_BASE_URL + `/createTransportPurchaseOrder`, {planningID}, {
        headers: {
          Authorization: "Bearer " + token,
        },
    });
    return response.data;
  }

  static async updatePOstatus(status , recordID) {
    const token = localStorage.getItem(ACCESS_TOKEN_NAME);
    var response = await axios.get(API_BASE_URL + `/WindowPOStatus?status=${status}&recordID=`+recordID, {
        headers: {
          Authorization: "Bearer " + token,
        },
    });
    return response.data;
  }

  static async getPurchasedOrderLine(purchaseOrecordID) {
    const token = localStorage.getItem(ACCESS_TOKEN_NAME);
    var response = await axios.get(API_BASE_URL + `/getPurchasedOrderLine?recordId=`+purchaseOrecordID, {
        headers: {
          Authorization: "Bearer " + token,
        },
    });
    return response.data;
  }
}

