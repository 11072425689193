import { useField } from 'formik';
import PropType from 'prop-types';
import React  from 'react';
import CreatableSelect from 'react-select/creatable';
import PurchaseFormServices from '../../PurchaseOrder/Services/PurchaseFormServices';
import { useFormikContext } from 'formik';
import { useEffect , useState } from 'react';

const CustomDriver = (props) => {

  const [field, meta, helpers] = useField(props);
  const [vendorchage, setVendorchage] = useState(false);
  const [selectedValue, setselectedValue] = useState({});
  const [option, setOption] = useState([]);

  const {
    options, value, handleVendorChange,  vendorChage , defaultValue, label, placeholder, isMulti, type, iid
  } = props;

  
  
  const { touched, error } = meta;
  const { setValue } = helpers;
  const { setFieldValue } = useFormikContext();

  const fetchAllDriverByVendor = async (vendorid) => {
    let response;
    if (props.matchinfo == '/exitingPurchaseorder/:id/:recordID') {
      response = await PurchaseFormServices.fetchActiveDriverByVendor(vendorid);
    } else {
      response = await PurchaseFormServices.fetchActiveDriverByVendor(vendorid);
    }

    const option = new Array();
    for (var i = 0; i < response.length; i++) {
        if (response[i].name) {
            option.push({ value: response[i].id, label: response[i].name });
        }
    }
    setOption(option);
  }


  useEffect(()=>{
    if (value.vendorid) {
      fetchAllDriverByVendor(value.vendorid);
    }
  },[value]);


  useEffect(()=>{
    console.log('vendorChageqqqqqqqqqqqq', vendorChage)
    setVendorchage(vendorChage);
    setselectedValue(options.find((item)=>item.value == defaultValue.driverid))
  },[vendorChage]);

  const handleChange = async (newValue) => {

    setVendorchage(false);

    setselectedValue(options.find((item)=>item.value == newValue.value))
    if (Array.isArray(newValue)) {
      const arr = newValue.map((fieldKey) => fieldKey.value);
      setValue(arr);
      props.fun(arr)
    } else {
      setValue(newValue.value);
      props.fun(newValue.value)
    }
  };

  const handleKeyDown = (e) => {
    if (type === 'number') {
      const { key } = e.nativeEvent;
      if (/\D/.test(key) && key !== 'Backspace') {
        e.preventDefault();
      }
    }
  };



  return (
    <div className="form-group">
      <label htmlFor={field.name}>{label}</label>
      <CreatableSelect
        isMulti={isMulti}
        placeholder={placeholder}
        name={field.name}
        //getOptionLabel={option => <p>{option.label} <br/><br/>{option.name} <br /> Vendor </p> }
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        defaultValue={option.find((item)=>item.value == defaultValue.driverid)}
        options={option}
        instanceId={iid}
        value={
          (vendorchage) ? null : 
          selectedValue
        }
      />

        {touched && error ? (
          <small style={{ color: 'red' }}>{error}</small>
        ) : null}
  
    </div>
  );
};

CustomDriver.defaultProps = {
  isMulti: false,
  placeholder: '',
  iid: '',
  options: [],
  type: 'string'
};

CustomDriver.propTypes = {
  options: PropType.arrayOf(PropType.object),
  defaultValue: PropType.oneOfType([
    PropType.object,
    PropType.array
  ]).isRequired,
  label: PropType.string.isRequired,
  placeholder: PropType.string,
  isMulti: PropType.bool,
  type: PropType.string,
  iid: PropType.string
};

export default CustomDriver;
