import React,{ useState, useEffect } from 'react';

import { withRouter, Link } from 'react-router-dom';

import { ACCESS_TOKEN_NAME, ACCESS_TOKEN_NAME_USER, API_BASE_URL } from '../../constants/apiConstants';

import PurchaseOrderServices from '../PurchaseOrder/Services/PurchaseOrderServices';

import axios from 'axios'

// import "../../images/icon/themify-icons/themify-icons.css";

// import "../../images/js/script.js"

// import "../../images/js/pcoded.min.js"

// import "../../images/js/demo-12.js"

// import "../../images/icon/icofont/css/icofont.css";

import "datatables.net-dt/js/dataTables.dataTables"

import "datatables.net-dt/css/jquery.dataTables.min.css"

import $ from 'jquery';

import Sidebar from "./../Layout/Sidebar";
import LeftNavbar from "./../Layout/LeftNavbar";

import Footer from "./../Layout/Footer";

import MaterialTable from "material-table";



import { Button,Form,Col,InputGroup } from 'react-bootstrap';

import swal from "sweetalert";

import zIndex from '@material-ui/core/styles/zIndex';
import logo from '../../images/logo.png';
import profilepic from '../../images/avatar-4.jpg';
import Avatar from 'react-avatar';

 

function Planning(props) {

    const [orderData, setOrderData] = useState();

    const [location, setLocation] = useState([]);

    const [locationId, setLocationId] = useState('');

    const [dispatch, setDispatch] = useState({});

    const [getId, setId] = useState('');
    const [selectedOrder, setselectedOrder] = useState({});


    const [secondRowCount, setSecondRowCount] = useState(1);

    const [thirdRowCount, setThirdRowCount] = useState(1);

    const [fourthRowCount, setFourthRowCount] = useState(1);

    const [fifthRowCount, setFifthRowCount] = useState(1);

    const [sixthRowCount, setSixthRowCount] = useState(1);

    const [seventhRowCount, setSevethRowCount] = useState(1);

    const [eightRowCount, setEightRowCount] = useState(1);

    const [ninthRowCount, setNinthRowCount] = useState(1);

    const [tenthRowCount, setTenthRowCount] = useState(1);

    const [eleventhRowCount, setEleventhRowCount] = useState(1);

    const [twelthRowCount, setTwelthRowCount] = useState(1);

    const [tableData, setTableData] = useState({

      myArray:[]

    });

    const [drivers, setDrivers] = useState([]);

    const [getval, setVal] = useState({});

    const [drivers1, setDrivers1] = useState([]);

    const [selectedDriver, setSelectedDriver] = useState();

    const [ordersDetails, setOrderID] = useState([]);

    //add pink and drop location 
    const [order, setOrder] = useState([]);

    const userId = localStorage.getItem('userId');

    const [planing, setPlaning] = useState([]);


    const [filterData, setFilterData] = useState();

    const [filterDateWise, setFilterDatewise] = useState({

        from: "",

        to: ""

    })

    const [rowsPerPage, setRowsPerPage] = useState({

        data: ""

    });

 

    // useEffect(()=>{

    //     if(!window.location.hash) {

    //         window.location = window.location + '#loaded';

    //         window.location.reload();

    //     }

    // }, []);

    async function fetchDriverPlaning(dirverid){
    const token = localStorage.getItem(ACCESS_TOKEN_NAME);
    try {
        const planingObject = new Array();
        for(var i = 0; i < dirverid.length; i++){
          const response = await axios.get(API_BASE_URL + `/getorderIDdetailsondrivers?contactId=${dirverid[i].contactId}`, {
            headers: {
              Authorization: "Bearer " + token,
            },
          });

          if(response.data){
            response.data.forEach((element)=>{
              planingObject.push(element);
            });
          }
        }

        console.log(planingObject,'planingObject');
      setPlaning(planingObject);
    } catch(error) {
      console.log(error);
    }
  }



  const fetchData = async () => {

    const token = localStorage.getItem(ACCESS_TOKEN_NAME);
      const response = await axios.get(API_BASE_URL + `/findSupplierLocationByUserid?id=${userId}`, {

        headers: {

          Authorization: "Bearer " + token,

        },

      });

      const res = response.data;

      console.log('first==========',res);

      setLocation(res);

      setLocationId(res[0].supplierlocationid);



      const driverResponse = await axios.get(API_BASE_URL + `/findDriverBySupplierLocationid?id=${res[0].supplierlocationid}`, {

        headers: {

          Authorization: "Bearer " + token,

        },

      });

      const driversdata = driverResponse && driverResponse.data;


      fetchDriverPlaning(driversdata);

      setDrivers(driversdata);
      

      setDrivers1(driversdata);



    // calling OrderdetailsID Api....



      const orderDetails = await axios.get(API_BASE_URL + `/findOrderIdDetails?id=7`, {

        headers: {

          Authorization: "Bearer " + token,

        },

      });

      const res2 = orderDetails.data;

      console.log('Order Details==========',res2);

      setOrderID(res2);  

  }


    

  useEffect(() => {
    $(document).ready(function () {

        setTimeout(function(){

        $('#myTable').DataTable({

            "scrollX": true,

            "order": [[ 3, "desc" ]]

        });

         } ,1000);

    });
  
  fetchData();
  incrementcolomnthrough();

  }, []);

 

    const handleChangeRowsPerPage = (e) => {

        console.log('pagination', e.target.value);

        if(e.target.value === "All"){

            setRowsPerPage({pageSize: orderData.length});

        }else{

            setRowsPerPage({pageSize: e.target.value});

        }

    };

 

  const handleChange = async (e) => {

    setOrder([]);

    setDispatch({});

    setVal({});

    const {value, name} =  e.target;


    $('.abcdata').text(value);

    const selected = location.filter((data) => data.supplierloctionname === value );

  setLocationId(selected[0].supplierlocationid);

    const token = localStorage.getItem(ACCESS_TOKEN_NAME);

    

    const response = await axios.get(API_BASE_URL + `/findDriverBySupplierLocationid?id=${selected[0].supplierlocationid}`, {

      headers: {

        Authorization: "Bearer " + token,

      },

    });

    const driverData = response && response.data;
    fetchDriverPlaning(driverData);
    setDrivers(driverData);
    setDrivers1(driverData);



  }

  // Orderhandlechange on orderid to fetch the pickup city and drop city location

 

  const orderhandleChange = (drivername , parentindex,  childindx , date) => async (event) => {
    const {value, name} =  event.target;
    if(value !== null && value !== '') {
      let classname = event.target.className;
      let newClass = classname.split(' ');
      let getClass = newClass[3];
  
      const token = localStorage.getItem(ACCESS_TOKEN_NAME);
  
      const response3 = await axios.get(API_BASE_URL + `/findPickupDropLocationByInvoiceid?id=${value}`, {
        headers: { Authorization: "Bearer " + token }
      });
  
      const OrData = response3 && response3.data;
      const pick = (OrData[0] && OrData[0].City) ? OrData[0].City : '';
      const drop = (OrData[0] && OrData[0].DropCity) ? OrData[0].DropCity : '';
      const Dist = (OrData[0] && OrData[0].Dist) ? OrData[0].Dist : '';

      const DropState = (OrData[0] && OrData[0].DropState) ? OrData[0].DropState : '';
      const State = (OrData[0] && OrData[0].State) ? OrData[0].State : '';

 
      let newOrder = {
        date , parentindex , childindx , drivername , pick, drop, Dist , DropState , State , orderid:value
      };

      // find whether the product already exists
      const isOrderPresent = order.some((item) => item.parentindex === parentindex  && item.childindx === childindx && item.date === date);
      // if found update its quantity 
      if (isOrderPresent) {
        const updatedOrder = order.map((item) => {
          if (item.parentindex === parentindex && item.childindx === childindx && item.date === date) {
            return {...item, pick:pick, drop:drop ,Dist: Dist,DropState: DropState, orderid:value, State: State};
          }
          return item;
        });
        setOrder(updatedOrder);
      } else {
        // if not found add the new order along with the existing order
        setOrder((prevOrderState) => [...prevOrderState, newOrder]);
      }
      }
  }

 

  console.log(order,'1111111111111111111111111111');

 

  const handleChange1 = (e) => {

      const {name, value, key} = e.target;

     

      console.log('fromto',name, value, key);

      setFilterDatewise((prevState) => ({

        ...prevState,

        [name]: value,

      }));

  }

  const handleView = () => {

      alert('function clicked');

  }

  const fetchSingleData = async (value) => {

    const token = localStorage.getItem(ACCESS_TOKEN_NAME);

    const results = await axios.get(API_BASE_URL + `/findSingleOrder?status=${value}`,

    {

        headers: {

          Authorization: "Bearer " + token,

        },

    });

    setOrderData(results.data);

    console.log('selected======',results.data);

  }

 

  const handleSubmitSearch = (e) => {

    e.preventDefault();

    const payload = {

        from: filterDateWise.from,

        to: filterDateWise.to

    }

    axios

    .post(API_BASE_URL + "/findDataUsingDate", payload)

    .then(function (response) {

        console.log('filterdatadatewise-----',response.data);

        setOrderData(response.data);

    })

    .catch(error => {

        console.log(error);

    })

  }

  

 

  const addData = ()=>{

 

 

    setTableData(prev=>({myArray:[...prev.myArray,

     

  ]}

  

  ));

    

  }

  function range(end) {

    let ran = [];

    for(let i=1; i<=end; i++) {

      ran.push(i);

    }

    return ran;

  }

  

 

  const driverHandleChange = (event) => {
    if(event.target.value == 'All'){
      setDrivers1(drivers);
    } else {
      setDrivers1([{drivername:event.target.value}]);
    }
  }

 

  console.log(drivers1,',==============drivers1');

  let driversToRender = selectedDriver ? [selectedDriver] : drivers;

  let driversToRende = selectedDriver ? [selectedDriver] : drivers1;

  console.log("*******driverToRender***", driversToRender);

 
async function updateplanning(dbid, parent, child, date , orderid , dispatchvalue) {  


  var invoiceObject = ordersDetails.filter((item) => item.invoiceRefNumber == orderid);
 
  var findOrderid = null;
  if(invoiceObject[0] && invoiceObject[0].recordID) {
    findOrderid = invoiceObject[0].recordID;
  } else {
    findOrderid = planing.filter((item)=>item.invoiceId == orderid)[0].orderno
  }


  console.log(findOrderid , 'findOrderid');

  
  var dispatch = getval[parent+child];
  if (dispatch == undefined) {
    dispatch = dispatchvalue;
  }
  
  const token = localStorage.getItem(ACCESS_TOKEN_NAME);
  const response3 = await axios.get(API_BASE_URL + `/findPickupDropLocationByInvoiceid?id=${(selectedOrder.orderid) ? selectedOrder.orderid : orderid}`, {
    headers: { Authorization: "Bearer " + token }
  });


  const OrData = response3 && response3.data;
  const pick = (OrData[0] && OrData[0].City) ? OrData[0].City : '';
  const drop = (OrData[0] && OrData[0].DropCity) ? OrData[0].DropCity : '';
  const Dist = (OrData[0] && OrData[0].Dist) ? OrData[0].Dist : '';
  const DropState = (OrData[0] && OrData[0].DropState) ? OrData[0].DropState : '';
  const State = (OrData[0] && OrData[0].State) ? OrData[0].State : '';

  await axios.put(API_BASE_URL + `/updateOrderDetailslOnPlanning?id=${dbid}`,{
    City:pick,
    State:State,
    DropCity:drop,
    DropState:DropState,
    Dist:Dist,
    invoiceId:orderid,
    orderId: (selectedOrder.orderid) ? selectedOrder.orderid : findOrderid,
    dispatch:dispatch
  }, {
    headers: {
      Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN_NAME),
    },
  }).then((response) => {
    window.open("/purchaseorder/"+dbid);
    /*
    const result =  PurchaseOrderServices.updatePlanningOrderStatus(findOrderid , 9);
    result.then(()=>{
      window.open("/purchaseorder/"+dbid);
      window.location.reload(false);
    });
    */
  }).catch((error)=>{
    console.log(error)
  })
}
  

 

function sendData(pick, State,drop,DropState,Dist,drivername,date,orderid, newid){
  console.log('driver ser------------')

  if(Dist.length == 0){
    swal({
      title: "Error!",
      text: "Please select order",
      icon: "warning",
    });
    return false;
  }

  if(typeof getval[newid] === "undefined"){
    swal({
      title: "Error!",
      text: "Please select yes or no",
      icon: "warning",
    });
    return false;
  }

//var invoiceObject = ordersDetails.filter((item)=>item.invoiceRefNumber == orderid);
//var findOrderid = invoiceObject[0].recordID;

var contactId = drivers.filter((element)=> element.drivername ==  drivername);
console.log('driver---',contactId,drivername);

/*
if(findOrderid.length == 0){
  swal({
    title: "Error!",
    text: "Please select order",
    icon: "warning",
  });
  return false;
}
*/

swal({
  title: "Warning!",
  text: "Are you sure want to plan?",
  icon: "warning",
  buttons: true,
  dangerMode: true,
})

.then((willDelete) => {

  if (willDelete) {
    const payload = {
      contactId:(contactId[0].contactId)? contactId[0].contactId : null,

      locationId:locationId,

      dispatch:getval[newid],
      invoiceId:orderid,

      //orderId:findOrderid,

      City:(pick.length > 0) ? pick : null,

      State:(State.length > 0) ? State : null,

      drivername:(drivername.length > 0) ? drivername : null,

      DropState:(DropState.length > 0 ) ? DropState : null,

      DropCity: (drop.length > 0 ) ? drop : null,

      Dist:(Dist.length > 0 ) ? Dist : null,

      date:date

    };

 

    // save the data planning data ...//

 

    axios

      .post(API_BASE_URL + `/saveplanning`, payload, {

        headers: {

          Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN_NAME),

        },

      })

      .then(function (response) {
        if (response.status === 200) {
          const result =  PurchaseOrderServices.updatePlanningOrderStatus(response.data[0].orderId , 9);
          result.then(()=>{
            window.open("/purchaseorder/"+response.data[0].lastinsterid);
            window.location.reload(false);
          });
        }
      })

      .catch(function (error) {
        console.log(error);
      });

 

/// planning data ends here ....///

 

    console.log(payload,'submit');

  

 

  } else {

    swal("You Choose Cancel Planning!");

  }

});

 

  }

const getDispatch = (event) =>{ 
  const {name, value} = event.target;
  setVal((prev)=>({...prev,[name]:value}));
}



  var currentDate = new Date;
  var day = currentDate.getDay();
  var firstDay = currentDate.getDate() + (day == 0 ? -6 : 1)-day;
  var firstMonday = firstDay;
  var nextMonday = firstDay + 7;

  var days = ['Mon','Tus','Wed' ,'Thu','Fri','Sat'];
  var colour = ['#ffbfca', '#bfc3ff','#d2f9ec', '#ffd9be', '#ddbcfd','#cdce9c'];

  var months = new Array("Jan","Feb","Mar","Apr", "May","Jun","Jul" , "Aug","Sep", "Oct","Nov", "Dec");

  var firstcolumn = [];

  var secondcolumn = [];

 
  function daysInMonth (month, year) { 
    return new Date(year, month, 0).getDate();
  }
  
  var lastdate = nextMonday - daysInMonth(currentDate.getMonth(), currentDate.getFullYear());
  for(var i = 0; i < 6; i++){
      var date = 1;
    if(daysInMonth(currentDate.getMonth(), currentDate.getFullYear()) <  nextMonday + i) {
       date =  lastdate;
       secondcolumn.push(days[i]+' '+date+' '+months[currentDate.getMonth()+1]);
      lastdate++;
    } else {
       date = nextMonday + i;
       secondcolumn.push(days[i]+' '+date+' '+months[currentDate.getMonth()]);
    }
  }



 
  var lastdateofmonth = 1;
  for(var i = 0; i < 6; i++) {
      var date = firstMonday + i;
    if(daysInMonth(currentDate.getMonth(), currentDate.getFullYear()) < firstMonday + i){
       date = lastdateofmonth;
      firstcolumn.push(days[i]+' '+date+' '+months[currentDate.getMonth()+1]);
      lastdateofmonth++;
    } else {
      if (date <= 0 ) {
        firstcolumn.push(days[i]+' '+ (date+daysInMonth(currentDate.getMonth(), currentDate.getFullYear())) +' '+months[currentDate.getMonth()-1]);
      } else {
        firstcolumn.push(days[i]+' '+date+' '+months[currentDate.getMonth()]);
      }
    }
  }

  const [firstcolumnrow, firstcolumnrowincrement] = useState([]);
  const [secondcolumnrow, secondcolumnrowincrement] = useState([]);

  
 
  const incrementcolomnthrough = async () => {
    const firstcolumnrowArray = new Array();
    const secondcolumnrowArray = new Array();
  
    const alldriver = [];

    const token = localStorage.getItem(ACCESS_TOKEN_NAME);
    const response = await axios.get(API_BASE_URL + `/getdriverList`, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });

  
    response.data.forEach(element => {
      alldriver.push(element.drivername);
    });
  


    alldriver.map((drivername)=>{
      for(var i = 0; i < 6; i++){
        firstcolumnrowArray.push({index : i , counter: 1, driver : drivername});
        secondcolumnrowArray.push({index : i , counter: 1, driver : drivername});
      }
    });
    firstcolumnrowincrement(firstcolumnrowArray);
    secondcolumnrowincrement(secondcolumnrowArray);
  }

  function incrementfirstcolumnrow(index , drivername){
    firstcolumnrowincrement(
      firstcolumnrow.map((x) => {
        if (x.index === index && x.driver === drivername)
          return {
            ...x,
            counter: x.counter + 1,
          };
          return x;
      })
    );
  }

  function incrementsecondcolumnrow(index , drivername){
    secondcolumnrowincrement(
      secondcolumnrow.map((x) => {
        if (x.index === index && x.driver === drivername)
          return {
            ...x,
            counter: x.counter + 1,
          };
          return x;
      })
    );
  }


  function incrementColumnRowAccordingExitRecod(date, drivername){
    const newArray = planing.filter((element)=>element.driverName == drivername && element.Dt == date); 
    if(newArray.length > 0){
      return newArray.length -1
    } else {
      return newArray.length
    }
  }

  async function updateOrderChange(value, date, parentindex, childindx, id, drivername) {



    setselectedOrder({orderid:value});

    const token = localStorage.getItem(ACCESS_TOKEN_NAME);
  
    const response3 = await axios.get(API_BASE_URL + `/findPickupDropLocationByInvoiceid?id=${value}`, {
      headers: { Authorization: "Bearer " + token }
    });

    const OrData = response3 && response3.data;
    const pick = (OrData[0] && OrData[0].City) ? OrData[0].City : '';
    const drop = (OrData[0] && OrData[0].DropCity) ? OrData[0].DropCity : '';
    const Dist = (OrData[0] && OrData[0].Dist) ? OrData[0].Dist : '';

    const DropState = (OrData[0] && OrData[0].DropState) ? OrData[0].DropState : '';
    const State = (OrData[0] && OrData[0].State) ? OrData[0].State : '';

    if(response3.data.length > 0){
      let newOrder = {
        date , parentindex , childindx , drivername , pick, drop, Dist , DropState , State , orderid:value , id
      };
  
      // find whether the product already exists
      const isOrderPresent = order.some((item) => item.parentindex === parentindex  && item.childindx === childindx && item.date === date);
      // if found update its quantity 
      if (isOrderPresent) {
        const updatedOrder = order.map((item) => {
          if (item.parentindex === parentindex && item.childindx === childindx && item.date === date) {
            return {...item, pick:pick, drop:drop ,Dist: Dist,DropState: DropState, orderid:value, State: State};
          }
          return item;
        });
        setOrder(updatedOrder);
      } else {
        // if not found add the new order along with the existing order
        setOrder((prevOrderState) => [...prevOrderState, newOrder]);
      }
    }
  }


  function handleLogout() {
    localStorage.removeItem(ACCESS_TOKEN_NAME);
    localStorage.removeItem('role');
    localStorage.removeItem('user');
    localStorage.removeItem(ACCESS_TOKEN_NAME_USER);
    props.history.push("/");
  }

  const [unavaliableObject, setUnavaliable] = useState([]);

  function unavaliable(date, drivername, status) {
    const payload = {
      drivername,
      date,
      status
    };

    if(status == 0){
      swal({
        text: "Are you sure want to unavailable on "+date,
        icon: "warning",
        dangerMode: true,
        buttons: true,
      })
      .then(innsert => {
        if(innsert){
          setUnavaliable((state) => [...state, {drivername, date , status}]);

          axios
          .post(API_BASE_URL + `/unplanning`, payload, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN_NAME),
            },
          })
          .then(function (response) {
            swal("Success!", "unavailable on "+date, "success");
          });
        }
      });
    } else {
      swal({
        text: "Are you sure want to available on "+date,
        icon: "warning",
        dangerMode: true,
        buttons: true,
      })
      .then(innsert => {
        if(innsert){
          setUnavaliable((state) => state.map((x)=> {
            if (x.drivername === drivername && x.date === date)
              return {
                ...x,
                status:1
              };
              return x;
          }));

          axios
          .post(API_BASE_URL + `/unplanning`, payload, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN_NAME),
            },
          })
          .then(function (response) {
            swal("Success!", "unavailable on "+date, "success");
          });
        }
      });
    }
  }

  function unplannedaction(date, drivername , orderid) {
    var contactId = drivers.filter((element) => element.drivername ==  drivername);
    const payload = {
      contactId:(contactId[0].contactId)? contactId[0].contactId : null,
      locationId:null,
      dispatch:null,
      invoiceId:orderid,
      orderId:null,
      City:null,
      State:null,
      drivername:drivername,
      DropState:null,
      DropCity:null,
      Dist:null,
      date:date
    };

    swal({
      text: "Are you sure want to unplan on "+date,
      icon: "warning",
      dangerMode: true,
      buttons: true,
    })
    .then(innsert => {
      if(innsert){
        axios
        .delete(API_BASE_URL + `/deletePlanningDriverDetails`, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN_NAME),
          },
          data: payload
        })
        .then(function (response) {
          if (response.status === 200) {
            const result =  PurchaseOrderServices.updatePlanningOrderStatus(response.data[0].orderid , 7);
            result.then(()=>{
              swal("Success!", "unplan on "+date, "success");
            })
          }
        
        });
      }
    });

  }


    const fetchUnavaliable = async () => {
    const token = localStorage.getItem(ACCESS_TOKEN_NAME);

    const results = await axios.get(API_BASE_URL + `/getUnplanning`,
    {
        headers: {
          Authorization: "Bearer " + token,
        },
    });
    
    setUnavaliable(results.data);
  }

  const [invoiceObject, setinvoiceObject] = useState([]);
  const fetchNeedToDespatch = async (value) => {
    const token = localStorage.getItem(ACCESS_TOKEN_NAME);
    const results = await axios.get(API_BASE_URL + `/findSingleOrder?status=${value}`,
    {
        headers: {
          Authorization: "Bearer " + token,
        },
    });
    setinvoiceObject(results.data);
  }

  useEffect(()=>{
    fetchNeedToDespatch(7);
    fetchUnavaliable();
  },[]);

    return(

        <div id="pcoded" className="pcoded">

            <div className="pcoded-overlay-box"></div>

 

        <nav className="navbar header-navbar pcoded-header" style={{height:'55px', minHeight:'26px'}}> 
        <div className="navbar-wrapper">
            <div className="navbar-logo" style={{height: '52px'}}>
                <a style={{top:'7px'}} className="mobile-menu navabar_menu" id="mobile-collapse" href="#!">
                    <i class="ti-menu" style={{"color":" #000", "font-size" : "22px;"}}></i>
                </a>
                <a className="mobile-search morphsearch-search" href="#">
                    <i className="ti-search"></i>
                </a>
                <Link to="/dashboard">
                    <img style={{width:'30%'}} className="img-fluid logo-img" src={logo} alt="Theme-Logo" />
                </Link>
                <a className="mobile-options">
                    <i className="ti-more"></i>
                </a>
            </div>
            
            <div className="navbar-container container-fluid">
                <ul className="nav-right">
                  {
                   
                      <li class="user-profile header-notification">
                        <a className="mobile-menu navabar_menu1" id="mobile-collapse1"><i className="ti-menu" style={{"font-size":"24px"}}></i></a>
                      </li>
                   
                  }
                    <li className="user-profile header-notification" style={{lineHeight: 3.5}}>
                        <a href="#!">
                          <Avatar name={localStorage.getItem('user')} colors={['#ed3028', '#711999', '#383838']} size="40" round="20px" />
                        </a>
                        <ul className="show-notification profile-notification">
                            <li>
                                <a href="#">
                                    <i className="ti-user"></i> Profile
                                </a>
                            </li>
                            <li>
                              <Link to="/resetpassword">
                                <i className="ti-layout-sidebar-left"></i> Change Password
                              </Link>
                            </li>
                            <li>
                                <a href="" onClick={() => handleLogout()}>
                                    <i className="ti-layout-sidebar-left"></i> Logout
                                </a>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        </div>
    </nav>

 

            <div className="pcoded-container navbar-wrapper">

 

                <div className="pcoded-main-container">

                    <div className="pcoded-wrapper">

 

                        <Sidebar />
                        {
                         
                            <LeftNavbar invoice={ordersDetails} />
                         
                        }
                        
                        <div className="pcoded-content">

        <div className="pcoded-inner-content">

          <div className="main-body">

            <div className="page-wrapper">

              <div className="page-body">

                <div className="row">

                  <div className="col-md-12 col-sm-12 col-xs-12">

                    <h3 className="driver-planning"  style={{fontSize:'20px'}}>Planning : <span style={{fontSize:'12px'}}><b className="abcdata">{location.length && location[0].supplierloctionname}</b></span></h3>

                    <div className="d-flex flex-wrap justify-content-between  " style={{alignItems:'end'}}>

                 

 

                  <div className="form-group d-flex  text-left" style={{alignItems:'baseline'}}>

            

               <label><b style={{fontWeight: 600},{margin: 5}}> Location</b></label>&nbsp;&nbsp;

                <select  id="supplier location" onChange={handleChange}  className="form-control"  >

                                                    

                        {location.length && location.map(({supplierloctionname, supplierlocationid})=>

                 <option id={supplierlocationid}   key={supplierlocationid} value={supplierlocationid,supplierloctionname}  >{ supplierloctionname }</option>

 

                        )}

 

                  </select>

                  </div>

 

                  <div className="form-group d-flex  text-left" style={{alignItems:'baseline'}}>

               <label><b style={{fontWeight: 600}, {margin: 5}}>Driver </b> </label>&nbsp;&nbsp;

                <select onChange={driverHandleChange} name="Supplier location" id="supplier location"  className="form-control">

                <option value='All'>All</option>

                {drivers && drivers.map( (driver) =>  <option value={driver.drivername} >{driver.drivername}</option>)}

                            </select>

                  <button style={{margin:'0 10px',padding: '12px 6px', color: 'white',background: '#ed3028'}} className="btn btn-primary past-user-btn btn-sm">See Past User</button>

                </div>

 

 

 

       <div style={{width: '28em'}}>

                  <Form.Row>

                    <Form.Group as={Col} style={{marginBottom:'0px'}}>

                        <InputGroup>

                            <InputGroup.Prepend>

                                <InputGroup.Text>

                                    {/* <FontAwesomeIcon icon="search" /> */}

                                </InputGroup.Text>

                            </InputGroup.Prepend>

                            <Form.Control

                                type="text"

                                placeholder="Search here.."

                            />

                        </InputGroup>

                    </Form.Group>

                </Form.Row>

                  </div>

 

                    

                    </div>

                  </div>

                </div>

                              

                {/*table start*/}

      

              

                <div className="container-fluid" style={{padding: '23px 0px'}}>

 

                

 

<div>  

 

{drivers1 && drivers1.map( (driver, index) =>(

 

<> 

<div style={{marginBottom: '20px'}} className={`box-effect`}>

<h5 className="text-center"> {driver.drivername}</h5>

<div className="row" >

  

<div style={{padding:'0px'}} className="col-md-6">

 

  <div className="space">

<div className="table-responsive customTable">

                  <table className="table table-bordered">

                    <thead>

                      <tr>

                 
                        

                        <th>Day</th>

                        <th style={{padding: '10px 46px'}}> 

                          Order

                        </th>

                        <th>Pick Up City</th>

                        <th>Drop Off City</th>

                        <th>Dist</th>

                        <th>Cust Conf</th>

                        <th>Dispach?</th>

                        <th>Action</th>

                      </tr>

                    </thead>

                    <tbody>

                      

                     {

 

                    firstcolumn.map((date, index) => (

                      firstcolumnrow.map((incrementRow , indexrow)=>(

                        (incrementRow.index == index && incrementRow.driver == driver.drivername) ?
                      
                        range(incrementRow.counter + incrementColumnRowAccordingExitRecod(date , driver.drivername)).map((ind) => (

                        
                        <tr key={ind} style={
                          (ind == 1) ? 
                          {"background-color":colour[index]}
                          :{"background-color":"#fff"}
                          }>

                        <td  style={{"background-color":colour[index]}}>
                          <div style={{display:'flex',flexDirection:'column',alignItems:'center',fontWeight:'700',fontSize:'11px'}}>
                            {
                              (ind == 1) ? 
                                <>
                                  <span>{date}</span> 
                                  {
                                    (unavaliableObject.filter((element)=>element.date == date && element.driverName == driver.drivername && element.status == 0).length  > 0 ) ? 
                                    <a onClick={()=>unavaliable(date, driver.drivername , 1)} style={{"cursor": "pointer"}} >
                                       <span href="#" style={{'background-color':'red'}}  className="badge badge-success">Unavailable</span>
                                    </a>
                                  :
                                    <a onClick={()=>unavaliable(date, driver.drivername , 0)} style={{"cursor": "pointer"}} >
                                      <span href="#" className="badge badge-success">Available</span>
                                    </a>
                                  }
                                </>
                              :
                                null
                            }

                          </div>

                        </td>

                        {
                          (planing.filter((element)=>element.driverName == driver.drivername && element.Dt == date)[ind-1]) ? 
                            <>

                            
                      <td> 
                        
                        {
                          <input
                        onChange={e =>updateOrderChange(
                          e.target.value,
                          date,
                          indexrow,
                          ind,
                          planing.filter((element)=>element.driverName == driver.drivername && element.Dt == date)[ind-1].id,
                          driver.drivername
                        )}

                        value={(selectedOrder.orderid == undefined) ? 
                          planing.filter((element)=>element.driverName == driver.drivername && element.Dt == date)[ind-1].invoiceId
                          : 
                          (selectedOrder.parentindex == indexrow && selectedOrder.childindex == ind && selectedOrder.date == date) ?
                          selectedOrder.invoiceId 
                          :
                          null
                        
                        }
                        list="browsers"
                        className="form-control form-group d-flex"
                      />

                        }

                        <datalist  id="browsers">
                          {ordersDetails.length && ordersDetails.map(({invoiceRefNumber,recordID, City,State,DropCity,DropState}, ordersId)=>
                          <>
                            <option value={`${invoiceRefNumber}`} id={invoiceRefNumber}>{invoiceRefNumber}</option>
                            <option value={`${invoiceRefNumber}`}>{City}</option>
                            <option value={`${invoiceRefNumber}`}>{DropCity} - {DropState}</option>
                            <option value={`${invoiceRefNumber}`}>........................................</option>
                          </>
                          )} 
                        </datalist>
                      </td>

                      <td  className="12order-pic">
                        
                        {                        
                        (order.filter((element , index)=>element.parentindex == indexrow && element.childindx == ind && element.date == date).length == 0) ? 
                          planing.filter((element)=>element.driverName == driver.drivername && element.Dt == date)[ind-1].Pc
                        :
                          order.map((element , index)=>{
                            if(element.parentindex == indexrow && element.childindx == ind && element.date == date){
                              return  element.pick
                            }
                          })
                        } <br/>

                        {/*
                          (order.filter((element , index)=>element.parentindex == indexrow && element.childindx == ind && element.date == date).length == 0 ) ? 
                          planing.filter((element)=>element.driverName == driver.drivername && element.Dt == date)[ind-1].Ps
                          :
                          order.map((element , index)=>{
                            if(element.parentindex == indexrow && element.childindx == ind && element.date == date){
                              return  element.State
                            }
                          })
                          */
                        }
                        
                        </td>
                        <td  className="12order-pic">

                          {

                            (order.filter((element , index)=>element.parentindex == indexrow && element.childindx == ind && element.date == date).length == 0) ?
                            planing.filter((element)=>element.driverName == driver.drivername && element.Dt == date)[ind-1].Dc
                            :
                                   order.map((element , index)=>{
                                    if(element.parentindex == indexrow && element.childindx == ind && element.date == date){
                                      return  element.drop
                                    }
                                  })
                          } <br/>
                          {

                            (order.filter((element , index)=>element.parentindex == indexrow && element.childindx == ind && element.date == date).length == 0) ?

                            planing.filter((element)=>element.driverName == driver.drivername && element.Dt == date)[ind-1].DS
                            :
                                order.map((element , index)=>{
                                if(element.parentindex == indexrow && element.childindx == ind && element.date == date){
                                  return  element.DropState
                                }
                              })
                          }
                        </td>
                        <td  className="12order-pic">

                          {

                            (order.filter((element , index)=>element.parentindex == indexrow && element.childindx == ind && element.date == date).length == 0) ?
                            planing.filter((element)=>element.driverName == driver.drivername && element.Dt == date)[ind-1].Dist
                            :
                                   order.map((element , index)=>{
                                    if(element.parentindex == indexrow && element.childindx == ind && element.date == date){
                                      return  element.Dist
                                    }
                                  })
                          }
                        </td>

                          
                        {
                
                        <td><select name={(indexrow+ind)} onChange={getDispatch} className="form-control form-group d-flex">

                        <option value=""></option>

                        {(planing.filter((element)=>element.driverName == driver.drivername && element.Dt == date)[ind-1].Dispatch == 1) ?
                          <>
                          <option selected value="1">Yes</option>
                          <option value="0">No</option>
                          </>

                        :
                        <>
                        <option value="1">Yes</option>
                        <option selected value="0">No</option>
                        </>
                        }

                        

                      </select></td> 

                        }


                           <td></td>
                           <td> 
                             
                           {
                            <>
                            <button style={{"background-color":"white"}} id="addBtn"  onClick={()=>incrementfirstcolumnrow(index , driver.drivername)} className="btn-sm plus-btn"><i className="fa fa-plus"></i></button>
                           <button style={{"background-color":"white"}} onClick={()=>updateplanning(

                              planing.filter((element)=>element.driverName == driver.drivername && element.Dt == date)[ind-1].id,
                              indexrow,
                              ind,
                              date,
                              planing.filter((element)=>element.driverName == driver.drivername && element.Dt == date)[ind-1].invoiceId,
                              planing.filter((element)=>element.driverName == driver.drivername && element.Dt == date)[ind-1].Dispatch,

                            )} className="btn-sm btn-primary"><i className="fa fa-truck"></i></button>

                            
                           
                            <button style={{"background-color":"white"}} className="btn-sm btn-primary" onClick={()=>unplannedaction(date, driver.drivername,
                              planing.filter((element)=>element.driverName == driver.drivername && element.Dt == date)[ind-1].invoiceId
                              )} ><i className="fa fa-times"></i></button>

                            </>
                           }

                            </td>
                           </>
                            
                            : 

                           <>
                           
                        <td> 

                        {  
                            <input  onChange={orderhandleChange(driver.drivername , indexrow , ind , date)} list="browsers" name="browser" id="browser" className="form-control form-group d-flex"/>
                        }
                        <datalist   id="browsers">
                          {ordersDetails.length && ordersDetails.map(({invoiceRefNumber,City,recordID,State,DropCity,DropState}, ordersId)=>
                            <>
                            <option value={`${invoiceRefNumber}`} id={invoiceRefNumber}>{invoiceRefNumber}</option>
                          
                            <option value={`${invoiceRefNumber}`}>{City}</option>
                            <option value={`${invoiceRefNumber}`}>{DropCity} - {DropState}</option>
                            <option value={`${invoiceRefNumber}`}>........................................</option>
                            </>
                          )} 
                        </datalist>
                      </td>

                        <td  className="12order-pic">{
                          order.map((element , index)=>{
                            if(element.parentindex == indexrow && element.childindx == ind && element.date == date){
                              return  element.pick
                            }
                          })
                        } <br/>
                        {/*
                          order.map((element , index)=>{
                            if(element.parentindex == indexrow && element.childindx == ind && element.date == date){
                              return  element.State
                            }
                          })
                          */
                        }
                        
                        </td>
                        <td  className="12order-pic ii">

                          {
                                   order.map((element , index)=>{
                                    if(element.parentindex == indexrow && element.childindx == ind && element.date == date){
                                      return  element.drop
                                    }
                                  })
                          } <br/>
                          {
                                order.map((element , index)=>{
                                if(element.parentindex == indexrow && element.childindx == ind && element.date == date){
                                  return  element.DropState
                                }
                              })
                          }
                        </td>
                        <td  className="12order-pic">

                          {
                                   order.map((element , index)=>{
                                    if(element.parentindex == indexrow && element.childindx == ind && element.date == date){
                                      return  element.Dist
                                    }
                                  })
                          }
                        </td>

                      <td>
                        
                        { 
                          <select name={(indexrow+ind)} onChange={getDispatch} className="form-control form-group d-flex">

                          <option value=""></option>

                          <option value="1">Yes</option>

                          <option value="0">No</option>

                        </select>
                        }

                       </td>


                        
                        <td>{dispatch[driver?.contactId+index] }</td>

      

                        <td>
                          
                          {
                          <>
                           <button id="addBtn" style={{"background-color":"white"}} onClick={()=>incrementfirstcolumnrow(index , driver.drivername)} className="btn-sm plus-btn"><i className="fa fa-plus"></i></button>
                            <button style={{"background-color":"white"}} onClick={()=>sendData(
                              order.map((element , index)=>{
                                if(element.parentindex == indexrow && element.childindx == ind && element.date == date){
                                  return  element.pick
                                }
                              }),

                              order.map((element , index)=>{
                                if(element.parentindex == indexrow && element.childindx == ind && element.date == date){
                                  return  element.State
                                }
                              }),

                              order.map((element , index)=>{
                                if(element.parentindex == indexrow && element.childindx == ind && element.date == date){
                                  return  element.drop
                                }
                              }),

                              order.map((element , index)=>{
                                if(element.parentindex == indexrow && element.childindx == ind && element.date == date){
                                  return  element.DropState
                                }
                              }),

                              order.map((element , index)=>{
                                if(element.parentindex == indexrow && element.childindx == ind && element.date == date){
                                  return  element.Dist
                                }
                              }),
                              driver.drivername
                              ,
                              date,
                                 order.map((element , index)=>{
                                if(element.parentindex == indexrow && element.childindx == ind && element.date == date){
                                  return  element.orderid
                                }
                              }),
                              (indexrow+ind)
                              )} className="btn-sm btn-primary"><i className="fa fa-truck"></i></button>

                              {
                                
                                  <button style={{"background-color":"white"}} className="btn-sm btn-primary"><i className="fa fa-times"></i></button>
                               
                              }
                              
                              </>

                            }

                            </td>
                            </>

                        }

                              </tr>  
                        )) : ''

                        ))

                      ))}

 

                     </tbody>

                  </table>

 

                  {

                    tableData.myArray && tableData.myArray.map((ele) => ele)

                  }

 

                 

                  <table className="table table-bordered add-day-sec">

                    <tbody id="TextBoxContainer">

                    </tbody>

                  </table>

                  

                </div>

             

  </div>    

</div>

 

<div style={{padding:'0px'}} className="col-md-6">

<div className="space">

<div className="table-responsive customTable">

                  <table className="table table-bordered">

                    <thead>

                      <tr>

                        <th>Day</th>

                        <th style={{padding: '10px 46px'}}> 

                          Order

                        </th>

                        <th>Pick Up City</th>

                        <th>Drop Off City</th>

                        <th>Dist</th>

                        <th>Cust Conf</th>

                        <th>Dispach?</th>

                        <th>Action</th>

                      </tr>

                    </thead>

                    <tbody>

                      

                      {

                      secondcolumn.map((date, index) => (

                        secondcolumnrow.map((incrementRow , indexrow)=>(

                          (incrementRow.index == index && incrementRow.driver == driver.drivername) ?
              
                          range(incrementRow.counter + incrementColumnRowAccordingExitRecod(date , driver.drivername)).map((ind) => (

                            <tr key={ind} style={
                              (ind == 1) ? 
                              {"background-color":colour[index]}
                              :{"background-color":"#fff"}
                            }>

                          <td style={ {"background-color":colour[index]} }>

                          <div style={{display:'flex',flexDirection:'column',alignItems:'center',fontWeight:'700',fontSize:'11px'}}>

                          {
                            (ind == 1) ? 
                              <>
                                <span>{date}</span> 
                                {
                                  (unavaliableObject.filter((element)=>element.date == date && element.driverName == driver.drivername && element.status == 0).length  > 0 ) ? 
                                    <a onClick={()=>unavaliable(date, driver.drivername , 1)} style={{"cursor": "pointer"}} >
                                      <span href="#" style={{'background-color':'red'}} className="badge badge-success">Unavailable</span>
                                    </a>
                                  :
                                    <a onClick={()=>unavaliable(date, driver.drivername , 0)} style={{"cursor": "pointer"}} >
                                      <span href="#" className="badge badge-success">Available</span>
                                    </a>
                                }
                              </>
                              :
                              null
                          }

</div>

</td>

{
                          (planing.filter((element)=>element.driverName == driver.drivername && element.Dt == date)[ind-1]) ? 
                          <>

                          
                    <td> 
                      

                    
                    {
                      <input
                      onChange={e =>updateOrderChange(
                        e.target.value,
                        date,
                        indexrow,
                        ind,
                        planing.filter((element)=>element.driverName == driver.drivername && element.Dt == date)[ind-1].id,
                        driver.drivername
                      )}

                      value={(selectedOrder.orderid == undefined) ? 
                        planing.filter((element)=>element.driverName == driver.drivername && element.Dt == date)[ind-1].invoiceId
                        : 
                        (selectedOrder.parentindex == indexrow && selectedOrder.childindex == ind && selectedOrder.date == date) ?
                        selectedOrder.invoiceId 
                        :
                        null
                      
                      }
                      list="browsers"
                      className="form-control form-group d-flex"
                    />
                    }
                   

                   

                      
                      <datalist  id="browsers">
                        {ordersDetails.length && ordersDetails.map(({invoiceRefNumber, recordID , City,State,DropCity,DropState}, ordersId)=>
                          <>
                          <option value={`${invoiceRefNumber}`} id={invoiceRefNumber}>{invoiceRefNumber}</option>
                        
                          <option value={`${invoiceRefNumber}`}>{City}</option>
                          <option value={`${invoiceRefNumber}`}>{DropCity} - {DropState}</option>
                          <option value={`${invoiceRefNumber}`}>........................................</option>
                          </>
                        )} 
                      </datalist>
                    </td>

                       
                      <td  className="12order-pic">
                        
                        {                        
                        (order.filter((element , index)=>element.parentindex == indexrow && element.childindx == ind && element.date == date).length == 0) ? 
                          planing.filter((element)=>element.driverName == driver.drivername && element.Dt == date)[ind-1].Pc
                        :
                          order.map((element , index)=>{
                            if(element.parentindex == indexrow && element.childindx == ind && element.date == date){
                              return  element.pick
                            }
                          })
                        } <br/>

                        { /*
                          (order.filter((element , index)=>element.parentindex == indexrow && element.childindx == ind && element.date == date).length == 0 ) ? 
                          planing.filter((element)=>element.driverName == driver.drivername && element.Dt == date)[ind-1].Ps
                          :
                          order.map((element , index)=>{
                            if(element.parentindex == indexrow && element.childindx == ind && element.date == date){
                              return  element.State
                            }
                          })
                          */
                        }
                        
                        </td>
                        <td  className="12order-pic">

                          {

                            (order.filter((element , index)=>element.parentindex == indexrow && element.childindx == ind && element.date == date).length == 0) ?
                            planing.filter((element)=>element.driverName == driver.drivername && element.Dt == date)[ind-1].Dc
                            :
                                   order.map((element , index)=>{
                                    if(element.parentindex == indexrow && element.childindx == ind && element.date == date){
                                      return  element.drop
                                    }
                                  })
                          } <br/>
                          {

                            (order.filter((element , index)=>element.parentindex == indexrow && element.childindx == ind && element.date == date).length == 0) ?

                            planing.filter((element)=>element.driverName == driver.drivername && element.Dt == date)[ind-1].DS
                            :
                                order.map((element , index)=>{
                                if(element.parentindex == indexrow && element.childindx == ind && element.date == date){
                                  return  element.DropState
                                }
                              })
                          }
                        </td>
                        <td  className="12order-pic">

                          {

                            (order.filter((element , index)=>element.parentindex == indexrow && element.childindx == ind && element.date == date).length == 0) ?
                            planing.filter((element)=>element.driverName == driver.drivername && element.Dt == date)[ind-1].Dist
                            :
                                   order.map((element , index)=>{
                                    if(element.parentindex == indexrow && element.childindx == ind && element.date == date){
                                      return  element.Dist
                                    }
                                  })
                          }
                        </td>



                         <td>
                           
                         {
                           <select name={(indexrow+ind)} onChange={getDispatch} className="form-control form-group d-flex">

                        <option value=""></option>

                        {(planing.filter((element)=>element.driverName == driver.drivername && element.Dt == date)[ind-1].dispatch == 1) ?
                          <>
                          <option selected value="1">Yes</option>
                          <option value="0">No</option>
                          </>

                        :
                        <>
                        <option value="1">Yes</option>
                        <option selected value="0">No</option>
                        </>
                        }

                        

                      </select>
                      
                      }</td>


                         <td></td>
 <td> 
   
   {
              <>
            <button style={{"background-color":"white"}} id="addBtn" onClick={ () => incrementsecondcolumnrow(index , driver.drivername)} className="btn-sm plus-btn"><i className="fa fa-plus"></i></button>

            <button style={{"background-color":"white"}} onClick={()=>updateplanning(
           
           planing.filter((element)=>element.driverName == driver.drivername && element.Dt == date)[ind-1].id,
           indexrow,
           ind,
           date,
           planing.filter((element)=>element.driverName == driver.drivername && element.Dt == date)[ind-1].invoiceId,
           planing.filter((element)=>element.driverName == driver.drivername && element.Dt == date)[ind-1].Dispatch,
           
           )} className="btn-sm btn-primary"><i className="fa fa-truck"></i></button>

           {
             <button style={{"background-color":"white"}}  className="btn-sm btn-primary"  onClick={()=>unplannedaction(date, driver.drivername,
            planing.filter((element)=>element.driverName == driver.drivername && element.Dt == date)[ind-1].invoiceId
            )} ><i className="fa fa-times"></i></button>
           }
           </>
   }


                                </td>
 </>
  
  : 

 <>
<td> 

  {

            <input  onChange={orderhandleChange(driver.drivername , indexrow , ind , date)} list="browsers" name="browser" id="browser" className="form-control form-group d-flex"/>

  }

<datalist   id="browsers">
{ordersDetails.length && ordersDetails.map(({invoiceRefNumber,City,State,recordID,DropCity,DropState}, ordersId)=>
             <>
             <option value={`${invoiceRefNumber}`} id={invoiceRefNumber}>{invoiceRefNumber}</option>
           
             <option value={`${invoiceRefNumber}`}>{City}</option>
             <option value={`${invoiceRefNumber}`}>{DropCity} - {DropState}</option>
             <option value={`${invoiceRefNumber}`}>........................................</option>
             </>
)} 
</datalist>
</td>

<td  className="12order-pic">{
order.map((element , index)=>{
  if(element.parentindex == indexrow && element.childindx == ind && element.date == date){
    return  element.pick
  }
})
} <br/>
{ /*
order.map((element , index)=>{
  if(element.parentindex == indexrow && element.childindx == ind && element.date == date){
    return  element.State
  }
})
*/
}

</td>
<td  className="12order-pic">

{
         order.map((element , index)=>{
          if(element.parentindex == indexrow && element.childindx == ind && element.date == date){
            return  element.drop
          }
        })
} <br/>
{
      order.map((element , index)=>{
      if(element.parentindex == indexrow && element.childindx == ind && element.date == date){
        return  element.DropState
      }
    })
}
</td>
<td  className="12order-pic">

{
         order.map((element , index)=>{
          if(element.parentindex == indexrow && element.childindx == ind && element.date == date){
            return  element.Dist
          }
        })
}
</td>

<td>
  
  {
    <select name={(indexrow+ind)} onChange={getDispatch} className="form-control form-group d-flex">

    <option value=""></option>
    
    <option value="1">Yes</option>
    
    <option value="0">No</option>
    
    </select>

  }
</td>



<td>{dispatch[driver?.contactId+index] }</td>



                            <td> 
                              
                              {
                                <>
                                 <button style={{"background-color":"white"}}  id="addBtn" onClick={ () => incrementsecondcolumnrow(index , driver.drivername)} className="btn-sm plus-btn"><i className="fa fa-plus"></i></button>

                                  <button style={{"background-color":"white"}} onClick={()=>sendData(


                                  order.map((element , index)=>{
                                  if(element.parentindex == indexrow && element.childindx == ind && element.date == date){
                                  return  element.pick
                                  }
                                  }),

                                  order.map((element , index)=>{
                                  if(element.parentindex == indexrow && element.childindx == ind && element.date == date){
                                  return  element.State
                                  }
                                  }),

                                  order.map((element , index)=>{
                                  if(element.parentindex == indexrow && element.childindx == ind && element.date == date){
                                  return  element.drop
                                  }
                                  }),

                                  order.map((element , index)=>{
                                  if(element.parentindex == indexrow && element.childindx == ind && element.date == date){
                                  return  element.DropState
                                  }
                                  }),

                                  order.map((element , index)=>{
                                  if(element.parentindex == indexrow && element.childindx == ind && element.date == date){
                                  return  element.Dist
                                  }
                                  }),
                                  driver.drivername
                                  ,
                                  date,
                                  order.map((element , index)=>{
                                  if(element.parentindex == indexrow && element.childindx == ind && element.date == date){
                                  return  element.orderid
                                  }
                                  }),
                                  (indexrow+ind)
                                  )} className="btn-sm btn-primary"><i className="fa fa-truck"></i></button>

                                  {
                                    <button style={{"background-color":"white"}}  className="btn-sm btn-primary"><i className="fa fa-times"></i></button>
                                  }
                                  
                                </>

                              }
                              
                             

                                </td>

                            </>

                        }

                              </tr>  
                        )) : ''

                        ))

                      ))}

 

                     </tbody>

                  </table>

                  {

                    tableData.myArray && tableData.myArray.map((ele) => ele)

                  }

 

                 

                  <table className="table table-bordered add-day-sec">

                    <tbody id="TextBoxContainer">

                    </tbody>

                  </table>

                  

                </div>

                </div>         

         

</div>

 

   

 

 

 

</div>

 

</div>

</>

))}

 

</div>

                </div>

 

                <style jsx="true">{`

                    .form-control {

    margin: 0;  

}

 

.table thead{

  font-size:11px

}

 

.newtd{
  display: flex;
    flex-direction: column;
    text-align: center;

}

 

.box-effect{

  padding: 10px 24px 42px;

  MARGIN: 7px 22px 32px;

  box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);

}

.space{

  margin-right: 8px;

    box-shadow: 0 3px 6px rgb(0 0 0 / 16%), 0 3px 6px rgb(0 0 0 / 23%);

}

 

.btn-sm {

  padding: 5px 10px;

    line-height: 10px;

    font-size: 12px;

    margin-inline: 3px;

  background-color: transparent;

  color: #da0f23;

}

 

.blue-btn{

  margin: 0 4px;

    background: #0069d9;

    border: none;

}

 

.green-btn{

  margin: 0 4px;

    background: green;

    border: none;

    color:white

}

 

.btn-transparent{

  backgroundColor: 'rgba(0,0,0,0)' 

}

 

.green-btn::hover{

 

    background: #026b02;

    

}

 

.table td {

  padding: 6px 2px;}

 

  .badge{

    font-size:8px

  }

 

  .plus-btn{

    background: transparent;

    color: black;

  }


  .swal-text{
    text-align: center;
  }
  
`}

                    

                  </style> 

               

                {/*table end 1*/}

              

              

               

              </div>

            </div>

          </div>

        </div>

      </div>

                  

                    </div>

                </div>

            </div>

        </div>

    )

}

 

export default withRouter(Planning);