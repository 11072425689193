import React,{ useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { ACCESS_TOKEN_NAME, API_BASE_URL } from '../../constants/apiConstants';
import axios from 'axios'
import Sidebar from "./../Sidebar/Sidebar"
import Header from "./../Header/Header"
import "./Home.css"


function Home(props) {
  useEffect(() => {
    const token = localStorage.getItem('role');
    if(token  == null || token !== '1'){
        redirectToLogin();
    }else{
        props.history.push('/home');
    }
  }, []);
   
  function redirectToLogin() {
  props.history.push('/');
  }

    return(
        <div>
        <Header />
          <div className="sidebar_main">
            <Sidebar/>
          </div>
        </div>
    )
}

export default withRouter(Home);