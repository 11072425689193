const phoneFormat = (phoneNumber) => {
    try {
        if (phoneNumber) {
            const filterNumber = phoneNumber.replace(/\D/g, '').match(/.{1,11}/g);
            if (filterNumber) {
                filterNumber.forEach((element, index) => {
                    if (element.charAt(0) == 1) {
                        element = element.substring(1);
                    }
                    let match = element.match(/^(\d{3})(\d{3})(\d{4})$/);
                    if (match) {
                        filterNumber[index] = '(' + match[1] + ') ' + match[2] + '-' + match[3];
                    }
                });
                return filterNumber.join(", ") || '';
            }
        }
        return;
    } catch (err) {
        return err;
    }
}

export default phoneFormat;
