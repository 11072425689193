
import PropType from 'prop-types';
import React from 'react';

const CustomRadio = ({
  field, form: { touched, errors }, label, inputRef, ...props
}) => (
  <>
    <input
      type="radio"
      id={field.name}
      ref={inputRef}
      {...field}
      {...props}
    />
  <label htmlFor={field.name}>&nbsp;&nbsp;{label}&nbsp;&nbsp;</label>
  {touched[field.name] && errors[field.name] ? (
    <small style={{ color: 'red' }}>{errors[field.name]}</small>
  ) : null}
</>
);

CustomRadio.defaultProps = {
  inputRef: undefined
};

CustomRadio.propTypes = {
  label: PropType.string.isRequired,
  field: PropType.object.isRequired,
  form: PropType.object.isRequired,
  inputRef: PropType.oneOfType([
    PropType.func,
    PropType.shape({ current: PropType.instanceOf(Element) })
  ])
};

export default CustomRadio;
