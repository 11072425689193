import React, { useState, useEffect } from "react";
import axios from "axios";
import { useHistory, useParams, Link } from "react-router-dom";
import { API_BASE_URL, ACCESS_TOKEN_NAME, ACCESS_TOKEN_NAME_USER } from "../../constants/apiConstants";
import {Multiselect}  from "multiselect-react-dropdown";
import swal from "sweetalert";
import InputMask from 'react-input-mask';
import logo from '../../images/logo.png';
import profilepic from '../../images/avatar-4.jpg';
import Sidebar from "./../Layout/Sidebar";
import Footer from "./../Layout/Footer";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import ResetServices from '../ResetPassword/ResetServices';
import Avatar from 'react-avatar';
import { ToastContainer, toast } from 'react-toastify';

const EditUser = (props) => {

  const [show, setShow] = useState(false);
  const [selectUser, setselectUser] = useState(null);

  const handleClose = () => setShow(false);

  const[roles, setRoles] = useState();

  const handleShow = (email) => {
    setShow(true);
    setselectUser(email);
  };


  let history = useHistory();
  const params = useParams();

  const [state, setState] = useState({
    name: "",
    email: "",
    mobile: "",
    rolesId: "",
    id: "",
    secondaryMobile: "",
    officeLocation: "",
    successMessage: null,
    errorMessages: {}
  });

  const [oldSelectedLocation, setoldSelectedLocation] = useState();

  const [location, setLocation] = useState();

  const [display, setdisplay] = useState('none');

  const [errorMessages, setErrorMessage] = useState();
  const [errorMessage, setError] = useState({
    errorMessage: {}
  });
  const [supplierLocation, setSupplierLocation] = useState({
    supplierLocations: [],
  });
  const [multiSelectOptions, setMultiSelectOptions] = useState([]);
  const [multiSelectedValue, setMultiSelectedValue] = useState([]);


  const setUsersActive = (id) => {
    axios.get(API_BASE_URL + `/setUserActive?id=${id}`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN_NAME),
      },
    }).then((result) => {
      fetchData();
      setTimeout(() => {
        toast.success('User has been activated', {
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true
        });
      });
    });
  }

  const initialValues = {
    resetpassword: "",
    conresetpassword: "",
  };

  const [values, setValues] = useState(initialValues);

  const initialError = {
    resetpassword: "",
    conresetpassword: "",
  };

  const [error, seterror] = useState(initialError);

  const handleResetPassword = async (e) => {
    if(values.resetpassword == '') {
      seterror((old)=>{
        return ({
          ...old,
          resetpassword:'The Password field is required'
        })
      })
    } else {
      seterror((old)=>{
        return ({
          ...old,
          resetpassword:''
        })
      })
    }


    if(values.conresetpassword == '') {
      seterror((old)=>{
        return ({
          ...old,
          conresetpassword:'The Confirm Password field is required'
        })
      })
    } else if(values.conresetpassword && values.resetpassword && values.conresetpassword != values.resetpassword) {
      seterror((old)=>{
        return ({
          ...old,
          conresetpassword:'Confirm password must be the same as the password'
        })
      })
    } else {
      seterror((old)=>{
        return ({
          ...old,
          conresetpassword:''
        })
      })
    }

    if(values.conresetpassword && values.resetpassword && values.conresetpassword == values.resetpassword){
      const result = await ResetServices.changePassword(values.resetpassword , selectUser);
      if(result.status == 200){
        setValues(initialValues);
        seterror(initialError);
        setShow(false);
        setTimeout(() => {
          toast.success('Password has been changed successfully!', {
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true
          });
        });
      }
    }
  }

  const setUsersInActive = (id) => {
    axios.get(API_BASE_URL + `/setUserInActive?id=${id}`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN_NAME),
      },
    }).then((result) => {
      fetchData();
      setTimeout(() => {
        toast.success('User has been deactivated', {
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true
        });
      });
    });
  }


  const handleChange = (e) => {
    const { name, value } = e.target;

    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    validate(name, value);
  };

  async function fetchData() {
    const { id } = params;
    const result = await axios.get(
      API_BASE_URL + `/findSingleUser?id=${id}`,
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN_NAME),
        },
      }
    );
    const datas = [];
    if (result.status === 200) {
      result.data.Data[0].userSupplierLocations.forEach(element => {
        datas.push({id: element.supplierLocation.id, name: element.supplierLocation.SupplierLocation});
      });
      setMultiSelectedValue(datas);
      setSupplierLocation({supplierLocations: { selectedList: datas}});
      setState(result.data.Data[0]);
      setState((prevState) => ({
        ...prevState,
        officeLocation:result.data.Data[0].officeLocation.id
      }));
    }
  }

  useEffect(() => {
    fetchData();

    async function getOfficeLocation(){
      const result = await axios.get(API_BASE_URL + '/findAllOfficeLocation', {
        headers : {
          Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN_NAME),
        },
      });
      if (result.status === 200) {
        setLocation(result.data);
      }
    }
    getOfficeLocation();

    const fetchAllRoles = async () => {
      const result = await axios.get(API_BASE_URL + '/userRoles', {
        headers : {
          Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN_NAME),
        },
      });
      console.log("allRoles1", result)
      if (result.status === 200) {
        setRoles(result.data);
      }
    }
    fetchAllRoles()

    async function fetchLocation() {
      const result = await axios.get(
          API_BASE_URL + `/findAllSupplierLocation`,
          {
              headers : {
                  Authorization : "Bearer " + localStorage.getItem(ACCESS_TOKEN_NAME),
              }
          },
      );
      const data = [];
      if(result.status === 200){
          result.data.forEach(element => {
            data.push({id: element.id, name: element.SupplierLocation});
            
          });
          console.log('not selected--',data);
          setMultiSelectOptions(data);
      }
    }   
    fetchLocation();

  }, []);

  const onSelect = (selectedList, selectedItem) => {
    console.log('selectedlist',selectedList);
    setSupplierLocation((prevState) => ({
      ...prevState,
      supplierLocations: {
        selectedList
      },
    }));
  }

  const onRemove = (selectedList, removedItem) => {
    console.log('deselected',selectedList);
    setSupplierLocation((prevState) => ({
      ...prevState,
      supplierLocations: {
        selectedList
      },
    }));
  }

  const sendEmail = (email) => {
    axios.get(API_BASE_URL + `/sendSetPasswordEmail?email=${email}`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN_NAME),
      },
    }).then((res) => {
      setTimeout(() => {
        toast.success('Email has been sent', {
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true
        });
      });
    });
  }

  const allLocation = async (e) => {
      setoldSelectedLocation(supplierLocation.supplierLocations.selectedList);
      if(e.target.checked){
        try{
          await axios.post(API_BASE_URL + `/updateLocationForSuperAdmin?id=${state.id}`, {allLocation : multiSelectOptions}, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN_NAME),
            },
          });
          fetchData();
          setTimeout(() => {
            toast.success('All location added to the user successfully', {
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true
            });
          });
        }catch(error){
          console.log(error,'errorinfo to allLocation method');
        }
      } else {
        try{
          await axios.post(API_BASE_URL + `/updateLocationForSuperAdmin?id=${state.id}`, {allLocation : oldSelectedLocation}, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN_NAME),
            },
          });
          fetchData();
          setTimeout(() => {
            toast.success('Location revert to the user successfully', {
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true
            });
          });
        }catch(error){
          console.log(error,'errorinfo to allLocation method');
        }
    }
  }
  
  
  const validate = (key, value) => {
    setError((prevState) => ({
      ...prevState,
      errorMessage: {
        ...prevState.errorMessage,
        [key]: "",
      },
    }));

  }
  const handleCancel = (e) => {
    history.push("/userlist");
  }
  const handleSubmitClick = (e) => {
    e.preventDefault();

    if (!state.name.length) {
      console.log('called name')
      setError((prevState) => ({
        ...prevState,
        errorMessage: {
          ...prevState.errorMessage,
          name: "Please Enter Name",
        },
      }));
    }
    if (state.mobile == "") {
      setError((prevState) => ({
        ...prevState,
        errorMessage: {
          ...prevState.errorMessage,
          mobile: "Please Enter Office Phone No",
        },
      }));
    }else if (state.mobile.length < 10) {
      setError((prevState) => ({
        ...prevState,
        errorMessage: {
          ...prevState.errorMessage,
          mobile: "Please Enter Validate Office Phone No",
        },
      }));
    }

    if (supplierLocation.supplierLocations.selectedList == 0) {
      setError((prevState) => ({
        ...prevState,
        errorMessage: {
          ...prevState.errorMessage,
          supplierLocations: "Please Select Supplier Location",
        },
      }));
    }else{
      setError((prevState) => ({
        ...prevState,
        errorMessage: {
          ...prevState.errorMessage,
          supplierLocations: "",
        },
      }));
    }

    if (!state.officeLocation.length) {
      setError((prevState) => ({
        ...prevState,
        errorMessage: {
          ...prevState.errorMessage,
          officeLocation: "Please Select Office Location",
        },
      })); 
    }

    if (state.name && state.email && supplierLocation.supplierLocations.selectedList != 0 && state.officeLocation && state.mobile && state.mobile.length >= 10) {
      setState((prevState) => ({
        ...prevState,
        errorMessage: {},
      }));

      const { id } = params;

      const payload = {
        name: state.name,
        email: state.email,
        mobile : state.mobile,
        secondaryMobile : state.secondaryMobile,
        officeLocation : state.officeLocation,
        supplierLocations: supplierLocation.supplierLocations,
        role: state.rolesId,
      };
      
      axios
      .post(API_BASE_URL + `/updateSingleUser?id=${id}`, payload, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN_NAME),
        },
      })
      .then(function (response) {
        if (response.status === 200) {
          swal({  
            title: "User Updated!",
            text: "",
            icon: "success",
            button: "OK ",
          }).then((value) => {
            history.push("/userlist");
          });
        } else {
          setState((prevState) => ({
            ...prevState,
            errorMessage: "some error occurd",
          }));
        }
      })
      .catch(function (error) {
        console.log(error);
      });
      
    } else {
      setErrorMessage("Please Fill All The Fields");
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };
  
  function handleLogout() {
    localStorage.removeItem(ACCESS_TOKEN_NAME);
    localStorage.removeItem('role');
    localStorage.removeItem('user');
    localStorage.removeItem(ACCESS_TOKEN_NAME_USER);
    props.history.push("/");
  }

  return (
    <>

<Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
    >

    <Modal.Header closeButton>
      <Modal.Title>Password Reset</Modal.Title>
    </Modal.Header>
    <Modal.Body>
    <ToastContainer />
      <form autoComplete="off">
          <div className="form-group">
            <label htmlFor="password">Password</label>
            <input 
              type="password" 
              className="form-control"
              value={values.company}
              onChange={handleInputChange}  
              name="resetpassword" 
              placeholder="Password" 
            />
            <span className="red">{error.resetpassword}</span>
          </div>

          <div className="form-group">
            <label htmlFor="conpassword">Confirm Password</label>
            <input 
              type="password" 
              className="form-control" 
              value={values.company}
              onChange={handleInputChange}  
              name="conresetpassword"
              placeholder="Confirmation Password" 
            />
            <span className="red">{error.conresetpassword}</span>
          </div>
        </form>
    </Modal.Body>
  
    <Modal.Footer>
      <Button variant="secondary" onClick={handleClose}>Close</Button>
      <Button onClick={handleResetPassword} variant="primary">Submit</Button>
    </Modal.Footer>
    </Modal>

    <div id="pcoded" className="pcoded">
        <div className="pcoded-overlay-box"></div>
        <div className="pcoded-container navbar-wrapper">

       
        <nav className="navbar header-navbar pcoded-header" style={{height:'55px', minHeight:'26px'}}> 
        <div className="navbar-wrapper">
            <div className="navbar-logo" style={{height: '52px'}}>
                <a style={{top:'7px'}} className="mobile-menu navabar_menu" id="mobile-collapse" href="#!">
                    <i class="ti-menu" style={{"color":" #000", "font-size" : "22px;"}}></i>
                </a>
                <a className="mobile-search morphsearch-search" href="#">
                    <i className="ti-search"></i>
                </a>
                <Link to="/dashboard">
                    <img style={{width:'30%'}} className="img-fluid logo-img" src={logo} alt="Theme-Logo" />
                </Link>
                <a className="mobile-options">
                    <i className="ti-more"></i>
                </a>
            </div>
            
            <div className="navbar-container container-fluid">
                <ul className="nav-left" style={{margin: "1% 0% 0% 8%" }}>
                    <h4>Edit User</h4>
                </ul>
                <ul className="nav-right">
                    <li className="user-profile header-notification" style={{lineHeight: 3.5}}>
                          <a href="javascript:void(0)" onClick={()=>{
                             setdisplay((old)=>(old == 'none') ? 'block' : 'none')
                          }}>
                            <Avatar name={localStorage.getItem('user')} colors={['#ed3028', '#711999', '#383838']} size="40" round="20px" />
                        </a>
                        <ul className="show-notification profile-notification" style={{display:display}}>
                            <li>
                                <a href="#">
                                    <i className="ti-user"></i> Profile
                                </a>
                            </li>
                            <li>
                              <Link to="/resetpassword">
                                <i className="ti-layout-sidebar-left"></i> Change Password
                              </Link>
                            </li>
                            <li>
                                <a href="" onClick={() => handleLogout()}>
                                    <i className="ti-layout-sidebar-left"></i> Logout
                                </a>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
            <div className="pcoded-main-container">
                <div className="pcoded-wrapper">

                  <Sidebar />
                    
                    <div className="pcoded-content page_content">
                        <div className="pcoded-inner-content">
                            <div className="main-body">
                                <div className="page-wrapper">
                                    <div className="page-body">
                                        <div className="col-md-12 tabs-ui p-0" >
                                        <div className="tab-content" id="nav-tabContent">
                                            <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                                                <form>
                                                  {/* {errorMessages && (
                                                    <span className="red">{errorMessages}</span>
                                                  )} */}
                                                  <div className="row">
                                                    <div className="col-md-6">
                                                      <div className="form-group text-left">
                                                      <label>Name</label>
                                                      <input
                                                        type="text"
                                                        name="name"
                                                        className="form-control"
                                                        id="name"
                                                        placeholder="Name"
                                                        value={state.name}
                                                        onChange={handleChange}
                                                        readOnly={localStorage.getItem('role') == 1 || localStorage.getItem('role') == 2 ? '' : 'readOnly' }
                                                      />
                                                      {errorMessage.errorMessage.name && (
                                                        <span className="red">{errorMessage.errorMessage.name}</span>
                                                      )}
                                                      </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                      <div className="form-group text-left">
                                                      <label>Email</label>
                                                      <input
                                                        type="email"
                                                        name="email"
                                                        className="form-control"
                                                        id="email"
                                                        aria-describedby="emailHelp"
                                                        placeholder="Email"
                                                        value={state.email}
                                                        onChange={handleChange}
                                                        readOnly
                                                      />

                                                      {errorMessage.errorMessage.email && (
                                                        <span className="red">{errorMessage.errorMessage.email}</span>
                                                      )}
                                                    </div>
                                                  </div>  
                                                  
                                                  <div className="col-md-6">
                                                    <div className="form-group text-left">
                                                    <label>Office Phone</label>
                                                      <InputMask
                                                        name="mobile"
                                                        className="form-control"
                                                        placeholder="Office Phone"
                                                        value={state.mobile}
                                                        onChange={handleChange}
                                                        maxLength="10"
                                                        readOnly={localStorage.getItem('role') == 1 || localStorage.getItem('role') == 2 ? '' : 'readOnly' }
                                                      />
                                                      {errorMessage.errorMessage.mobile && (
                                                        <span className="red">{errorMessage.errorMessage.mobile}</span>
                                                      )}
                                                    </div>
                                                  </div>    
                                                  
                                                  <div className="col-md-6">
                                                    <div className="form-group text-left">
                                                    <label>Cell Phone</label>
                                                      <InputMask
                                                        name="secondaryMobile"
                                                        className="form-control"
                                                        id="secondaryMobile"
                                                        placeholder="Cell Phone"
                                                        maxLength="10"
                                                        value={state.secondaryMobile}
                                                        onChange={handleChange}
                                                        readOnly={localStorage.getItem('role') == 1 || localStorage.getItem('role') == 2 ? '' : 'readOnly' }
                                                      />
                                                      {errorMessage.errorMessage.secondaryMobile && (
                                                        <span className="red">{errorMessage.errorMessage.secondaryMobile}</span>
                                                      )}
                                                    </div>
                                                  </div>
                                                  

                                                  <div className="col-md-6">
                                                    <div className="form-group text-left">
                                                    <label>Office Location</label>
                                                    <select
                                                      className="form-control"
                                                      name="officeLocation"
                                                      value={state.officeLocation}
                                                      onChange={handleChange}
                                                      disabled={localStorage.getItem('role') == 1 || localStorage.getItem('role') == 2 ? '' : 'disabled' }
                                                    >
                                                    {location &&
                                                      location.map((data, key) => (
                                                          <option key={key} value={data.id}>{data.officeName}</option>
                                                    ))}                                            
                                                    </select>
                                      
                                                    </div>
                                                  </div>

                                                  <div className="col-md-6">
                                                  <div className="form-group text-left">
                                                  <label>Role</label>
                                                  <select
                                                    className="form-control"
                                                    name="rolesId"
                                                    value={state.rolesId}
                                                    onChange={handleChange}
                                                    disabled={localStorage.getItem('role') == 1 || localStorage.getItem('role') == 2 ? '' : 'disabled' }
                                                  >
                                                    <option disabled>Select role</option>
                                                  {roles &&
                                                          roles.map((data, index) => ( 
                                                            <option key={data.id} value={data.id}>{data.roleName}</option>
                                                          ))}
                                                  </select>
                                                  {/* {state.errorMessage.roleName && (
                                                      <span className="red">{state.errorMessage.roleName}</span>
                                                    )} */}
                                                  </div>
                                                </div>
                                                  
                                                  <div className="col-md-12">
                                                    <div className="form-group text-left">
                                                    <label>Supplier Location</label>
                                                        <Multiselect
                                                        options={multiSelectOptions}
                                                        selectedValues={multiSelectedValue}
                                                        onSelect={onSelect}
                                                        onRemove={onRemove}
                                                        displayValue="name"
                                                        placeholder="Select Supplier"
                                                        />
                                                        {errorMessage.errorMessage.supplierLocations && (
                                                        <span className="red">{errorMessage.errorMessage.supplierLocations}</span>
                                                      )}
                                                    </div>

                                                    {
                                                      (localStorage.getItem('role') == 1 && (
                                                        <>
                                                          <div className="form-group text-right">
                                                            <label>Apply All Location</label> &nbsp;
                                                            <input type="checkbox" onClick={allLocation} />&nbsp;&nbsp;
                                                          </div>
                                                        </>
                                                      ))
                                                    }
                                                  </div>
                                                  
                                                  <div className="col-md-12 cstm-mob-view">

                                                    {
                                                      (state.isActive) ? 
                                                        <button type="button" className="btn btn-primary mr-2"  onClick={()=>setUsersInActive(state.id)}>Deactive</button>
                                                      :
                                                        <button  type="button" className="btn btn-primary mr-2" onClick={()=>setUsersActive(state.id)}>Activate</button>
                                                    }

                                                    <button  type="button" className="btn btn-primary mr-2" onClick={()=>handleShow(state.email)}>Password Reset</button>
                                                    <button  type="button" className="btn btn-primary mr-2" onClick={()=>sendEmail(state.email)}>Send Email</button>
                                      
                                                    <button
                                                      type="submit"
                                                      className="btn btn-primary mr-2"
                                                      onClick={handleSubmitClick}
                                                    >
                                                      Update User
                                                    </button>
                                                    <button
                                                      type="submit"
                                                      className="btn btn-default"
                                                      onClick={handleCancel}
                                                    >
                                                      Cancel
                                                    </button>
                                                  </div>

                                                                                                   
                                                  </div>
                                                </form>
                                                <div
                                                  className="alert alert-success mt-2"
                                                  style={{ display: state.successMessage ? "block" : "none" }}
                                                  role="alert"
                                                >
                                                  {state.successMessage}
                                                </div>
                                           

                                            </div>
                                          <div className="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">2</div>
                                          <div className="tab-pane fade" id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab">3</div>
                                      </div>
                                            
                                        </div>

                                        <Footer />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> 
    </>
  );

};

export default EditUser;
