import React, { useState, useEffect } from 'react';
import { ACCESS_TOKEN_NAME, ACCESS_TOKEN_NAME_USER, API_BASE_URL } from '../../constants/apiConstants';
import axios from 'axios';
import { withRouter, Link } from 'react-router-dom';
import $ from 'jquery';
import { ToastContainer, toast } from 'react-toastify';
import NumberFormate from '../../utils/NumberFormate';

function ActiveDriverTable(props) {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);

    const updateContactStatus = async (id , status) => {
        const result = await axios.patch(
            API_BASE_URL + "/updateContactStatus",
            {
                id,
                status
            },
            {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN_NAME),
                }
            },
        );
        if(result.status == 200){
            setTimeout(() => {
                toast.success("Driver has been deactivated successfully", {
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });
            });
            setData((old)=> old.filter((item)=>item.id != id));
        }
    }

    const fetchDriverinfo = async () => {
        setLoading(true);
        const result = await axios.get(
            API_BASE_URL + "/findallDeverinfo?status="+1+"&filer="+props.selectedLocation,
            {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN_NAME),
                }
            },
        );
        setLoading(false);
        setData(result.data);
        $('#activeDriverTable').DataTable({
             "scrollX": false,
        });
    }

    useEffect(() => {  
        $('#activeDriverTable').DataTable().destroy(); 
        fetchDriverinfo();
    },[props.selectedLocation]);

    return (
      <>
        {
            (loading) ?
                <i class="fa fa-spinner fa-spin center-position" />
                :
                
                <div className='table-responsive'>
                    <table id="activeDriverTable" className="table table-hover table-bordered" width="100%" data-page-length='50'>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Carrier</th>
                            <th>Supplier Location</th>
                            <th>Phone</th>
                            <th>Driver Email</th>
                            <th>Truck Type</th>
                            <th>Trailer Type</th>
                            <th>Trailer Size</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data &&
                            data.map((data, index) => (
                                <tr key={data.id}>
                                    <td><Link to={`/contactedit/${data.id}`} >{data.name}</Link></td>
                                    <td><Link to={`/contactedit/${data.id}`} >{data.vfullName}</Link></td>
                                    <td><Link to={`/contactedit/${data.id}`} >
                                        {

                                            (data.SupplierLocation) ?
                                                data.SupplierLocation.split("*").map((item) => (item) ? <><span className="box-design"> {item} </span> &nbsp;</> : '')
                                                :
                                                ''
                                        }</Link>
                                    </td>
                                    <td><Link to={`/contactedit/${data.id}`} >{NumberFormate(data.phone)}</Link></td>
                                    <td><Link to={`/contactedit/${data.id}`} >{data.DriverEmail}</Link></td>
                                    <td><Link to={`/contactedit/${data.id}`} >{data.truckType}</Link></td>
                                    <td><Link to={`/contactedit/${data.id}`} >{data.trailerType}</Link></td>
                                    <td><Link to={`/contactedit/${data.id}`} >{data.trailerSize}</Link></td>
                                    <td><i onClick={()=>updateContactStatus(data.id , 0)} className="fa fa-trash text-danger iconbaraction" data-toggle="tooltip" data-placement="top" title="Delete" aria-hidden="true"></i></td>
                                </tr>
                            ))}
                    </tbody>
                </table>
                <ToastContainer />
            </div>
        }
    </>
    );
}

export default ActiveDriverTable;