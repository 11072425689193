import React, { Component } from 'react';
import  CustomDriver  from './CustomDriver';
import { Field } from 'formik';

class Driver extends Component {
    render() {
        return (
            <CustomDriver
                name={this.props.name}
                label={this.props.label}
                iid={this.props.name}
                options={this.props.option}
                placeholder={this.props.placeholder}
                defaultValue={this.props.defaultValue}
                value={this.props.value}
                vendorChage={this.props.vendorChage}
                matchinfo = {this.props.matchinfo}
                fun={this.props.fun}
          />
        );
    }
}

export default Driver;