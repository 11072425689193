

import React, { Component } from 'react';
import './Sidebar.css';
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";




class Sidebar extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isVisible: false,
        };
    }

    navigateToDashBoard = () => {
        this.props.history.push('/dashboard')
    }


    render() {
        return (
            <div>
                <div className="">
                    <div>
                        <div onClick={this.navigateToDashBoard}>Dashboard</div>
                        <Link to="/home/user">Users</Link><br/>
                        <Link to="/home/order">Order</Link><br/>
                        <Link to="/home/supplierLocation">Location</Link>
                    </div>
                </div>
            </div>
        );
    }
}
export default withRouter(Sidebar)