import React, { useState, useEffect } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { ACCESS_TOKEN_NAME, ACCESS_TOKEN_NAME_USER, API_BASE_URL } from '../../constants/apiConstants';
import axios from 'axios';
import moment from 'moment';
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery';
import Sidebar from "../Layout/Sidebar";
import Footer from "../Layout/Footer";
import logo from '../../images/logo.png';
import profilepic from '../../images/avatar-4.jpg';
import DatePicker from "react-datepicker";
//import "react-datepicker/dist/react-datepicker.css";
import Avatar from 'react-avatar';
import { ToastContainer, toast } from 'react-toastify';
import { Center } from '@chakra-ui/react';
import avoidNull from '../../utils/AvoidNull';
import avoidNull2 from '../../utils/AvoidNull2';
import removeAddress from '../../utils/RemoveAddress';
import CustomDatePicker from "../../utils/customDatePicker";
import MaterialTable, { MTableToolbar, Chip } from 'material-table';
import FilterComponentBox from "../../utils/FilterComponentBox";
import ResetFillterButton from "../../utils/ResetFillterButton";
import { useCookies } from 'react-cookie';
import Cookies from 'universal-cookie';
import PlanningServices from '../DriverPlanning/PlanningServices';
import { createBrowserHistory } from "history";
import jstz from 'jstz';
import 'react-dates/initialize';
import { DateRangePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import { useParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import SyncComponent from '../Shared/SyncComponent';



function Delivered(props) {
    const [pageRefresh, setPageRefresh] = useState(false);
    const [cookies, setCookie, removeCookie] = useCookies(['Delivered']);
    const [orderData, setOrderData] = useState();
    const [filterData, setFilterData] = useState();


    const [focusedInput, setFocusedInput] = useState(null);

    const [rowsPerPage, setRowsPerPage] = useState({
        data: ""
    });

    const [display, setdisplay] = useState('none');
    const [pageNameList, setPageNameList] = useState('Delivered')

    const cookiesi = new Cookies();
    const [startDate, setStartDate] = useState(cookiesi.getAll().startDate ? moment(cookiesi.getAll().startDate) : null);
    const [endDate, setEndDate] = useState(cookiesi.getAll().endDate ? moment(cookiesi.getAll().endDate) : null);
    // if(cookiesi.getAll()['desc'] || cookiesi.getAll()['asc'])
    // {
    //     setdefaultSortValue()
    // }
    //console.log("cookiesKey",);


    const resetFilter = async () => {
        const test = document.querySelectorAll(".material-icons");
        for (let tests of test) {
            if (tests.getAttribute("aria-label") == 'clear') {
                tests.click();
            }
        }

        const cookies = new Cookies();

        if (Object.keys(cookies.getAll()).length > 0) {
            setPageRefresh(true);
        }
        else {
            setPageRefresh(true);
        }
        await removeCookie("orderBy", { path: "/delivered" });
        await removeCookie("direction", { path: "/delivered" });
        await removeCookie("invoiceRefNumber", { path: "/delivered" });
        await removeCookie("invoicePaidDate", { path: "/delivered" });
        await removeCookie("invoiceTerms", { path: "/delivered" });
        await removeCookie("invoiceLineItemItemNameWithoutParent", { path: "/delivered" });
        await removeCookie("invoiceAssignedToName", { path: "/delivered" });
        await removeCookie("resalePOPickupCity", { path: "/delivered" });
        await removeCookie("resalePORefNumer", { path: "/delivered" });
        await removeCookie("resalePOReleaseNumber", { path: "/delivered" });
        await removeCookie("resalePOReleaseDate", { path: "/delivered" });
        await removeCookie("resalePOTotalPrice", { path: "/delivered" });
        await removeCookie("resalePOExpectedDate", { path: "/delivered" });
        await removeCookie("resalePOLastDepotCallDate", { path: "/delivered" });
        await removeCookie("resalePOLineItemDistance", { path: "/delivered" });
        await removeCookie("invoiceShipCity", { path: "/delivered" });
        await removeCookie("transportPOLineItemContainerNumber", { path: "/delivered" });
        await removeCookie("transportPORefNumer", { path: "/delivered" });
        await removeCookie("transportPOVendor", { path: "/delivered" });
        await removeCookie("transportPOTotalPrice", { path: "/delivered" });
        await removeCookie("transportPOScheduledDeliveryDate", { path: "/delivered" });
        await removeCookie("transportPODeliveryStatus", { path: "/delivered" });
        await removeCookie("invoicePromisedTimeline", { path: "/delivered" });
        await removeCookie("invoicePromisedDate", { path: "/delivered" });

        await removeCookie("invoiceTxnDate", { path: "/delivered" });
        await removeCookie("invoiceCustomerNotes", { path: "/delivered" });
        await removeCookie("invoiceBalanceRemaining", { path: "/delivered" });
        await removeCookie("invoiceSupplierLocation", { path: "/delivered" });
        await removeCookie("transportPOLineItemContainerNumber", { path: "/delivered" });
        await removeCookie("resalePOVendor", { path: "/delivered" });
        await removeCookie("resalePOSupplierLocation", { path: "/delivered" });
        await removeCookie("invoiceVendorNotes", { path: "/delivered" });
        await removeCookie("invoiceShipMethodMethodFieldName", { path: "/delivered" });
        await removeCookie("resalePOTxnDate", { path: "/delivered" });
        await removeCookie("resalePOSupplierInvoice", { path: "/delivered" });
        await removeCookie("transportPODeliveredDate", { path: "/delivered" });
        await removeCookie("startDate", { path: "/delivered" });
        await removeCookie("endDate", { path: "/delivered" });
        await removeCookie("driverName", { path: "/delivered" });
        setStartDate('')
        setEndDate('')

        // await removeCookie("vendorName", { path: "/delivered" });
    }
    const location = useLocation();
    const modifyUrl = () => {

        // Get the current URL from location object
        let currentUrl = location.pathname + location.search;


        // Modify the URL by removing specified text
        const newUrl = currentUrl.replace(location.search, '');

        // Replace the current URL with the modified URL
        window.history.replaceState(null, '', newUrl);
    };

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const filterValue = urlParams.get('filter');
        if (filterValue) {
            const timezone = jstz.determine();
            switch (filterValue) {
                case '1':
                    removeCookie("startDate", { path: "/delivered" });
                    removeCookie("endDate", { path: "/delivered" });
                    setCookie("transportPODeliveredDate", moment().tz(timezone.name()).format('YYYY-MM-DD'), { path: "/delivered" })
                    break;
                case '2':
                    removeCookie("startDate", { path: "/delivered" });
                    removeCookie("endDate", { path: "/delivered" });
                    setCookie("transportPODeliveredDate", moment().tz(timezone.name()).subtract(1, 'days').format('YYYY-MM-DD'), { path: "/delivered" })
                    break;
                case '3':
                    removeCookie("transportPODeliveredDate", { path: "/delivered" });



                    const startDate = moment().tz(timezone.name()).startOf('month').format('MM-DD-yyyy')
                    const endDate = moment().tz(timezone.name()).format('MM-DD-yyyy')
                    console.log(startDate, endDate, 'ddddsfdfgff')
                    // console.log(`${currentDate}-${currentMonth}-${currentYear}`, 'checkDateeeeeee');
                    setCookie('startDate', startDate, { path: '/delivered' });
                    setCookie('endDate', endDate, { path: '/delivered' });
                    console.log('deliveryDate', startDate, endDate)
                    setStartDate( moment(startDate));
                    setEndDate( moment(endDate));

                    break;
                case '4':
                    removeCookie("transportPODeliveredDate", { path: "/delivered" });
                    let firstDayOfLastMonth = moment().tz(timezone.name()).subtract(1, 'months').startOf('month').format('YYYY-MM-DD');
                    var lastDayOfLastMonth = moment().tz(timezone.name()).subtract(1, 'months').endOf('month').format('YYYY-MM-DD');
                    setCookie('startDate', firstDayOfLastMonth, { path: '/delivered' });
                    setCookie('endDate', lastDayOfLastMonth, { path: '/delivered' });
                    console.log('all Date', firstDayOfLastMonth, lastDayOfLastMonth)
                    setStartDate( moment(firstDayOfLastMonth));
                    setEndDate( moment(lastDayOfLastMonth));
                    //setPageRefresh(true)
                    break;
                default:
                    break;
            }
            //modifyUrl();
        }
        console.log('Filter value:', filterValue);
        $(document).ready(function () {
            setTimeout(function () {
                $('#myTable').DataTable({
                    "scrollX": true,
                });
            }, 1000);

        });






        const fetchData = async () => {

            const token = localStorage.getItem(ACCESS_TOKEN_NAME);
            const response = await axios.get(API_BASE_URL + "/fetchOrderBasedOnColumn?size=20&page=1&orderNumber=18&sortField=invoiceTxnDate&sortType=desc&sortField=invoiceCustomerFullName&sortType=asc&sortField=resalePORecordID&sortType=asc", {
                headers: {
                    Authorization: "Bearer " + token,
                },
            });
            const res = response.data;
            setOrderData(res);
            setRowsPerPage({ data: res.length })
        }
        fetchData();



    }, []);

    useEffect(() => {

    });





    const setDeliveryDate = (startDate, endDate) => {
        console.log('checkSTartDate', startDate, endDate)
        let s_date = moment(startDate), e_date = moment(endDate);

        // console.log('checkAllDateeeeeeee', startDate, endDate)
        //moment(startDate).tz(timezone.name()).format('mm-dd-yyyyy');

        const timezone = jstz.determine();

        if (s_date._isValid) {
            setStartDate(startDate);
            setCookie('startDate', moment(startDate).tz(timezone.name()).format('MM-DD-yyyy'), { path: '/delivered' });

        }

        if (e_date._isValid) {

            setEndDate(endDate);
            setCookie('endDate', moment(endDate).tz(timezone.name()).format('MM-DD-yyyy'), { path: '/delivered' });
        }

        if (s_date._isValid && e_date._isValid) {
            setPageRefresh(true)
        }


    }

    const handleChangeRowsPerPage = (e) => {

        if (e.target.value === "All") {
            setRowsPerPage({ pageSize: orderData.length });
        } else {
            setRowsPerPage({ pageSize: e.target.value });
        }
    };

    const clearDates = () => {
        removeCookie("startDate", { path: "/delivered" });
        removeCookie("endDate", { path: "/delivered" });
        setStartDate('');
        setEndDate('');
        setPageRefresh(true);
    };

    const handleChange = (e) => {
        console.log("Selected!!", e.target.value);
        const value = e.target.value;
        setFilterData(e.target.value);
        fetchSingleData(value);
    }



    const handleView = () => {
        alert('function clicked');
    }
    const fetchSingleData = async (value) => {
        const token = localStorage.getItem(ACCESS_TOKEN_NAME);
        const results = await axios.get(API_BASE_URL + `/findSingleOrder?status=${value}`,
            {
                headers: {
                    Authorization: "Bearer " + token,
                },
            });
        setOrderData(results.data);
        console.log('selected======', results.data);
    }



    const createPlan = async (product, invoiceRefNumber, resalePORefNumer, resaleVendor, transportPORecordID) => {
        const response = await PlanningServices.getPlanningInfo(invoiceRefNumber);
        if (response[0] && response[0].id) {
            window.open("/exitingPurchaseorder/" + result[0].id + "/" + transportPORecordID);

        }

        const payload = {
            contactId: 0,
            locationId: 123,
            customerConfirmation: 0,
            driveConfirmation: 0,
            size: 0,
            invoiceId: invoiceRefNumber,
            City: '',
            drivername: '',
            product: product,
            State: '',
            transportPORecordID,
            DropState: '',
            DropCity: '',
            Dist: '',
            resalePORefNumer,
            isExpedited: 0,
            vendorFullName: resaleVendor,
            date: ''
        };
        const result = await PlanningServices.savePlanning(payload);
        if (result) {
            //fetchDispatchedOrder({});
            window.open("/exitingPurchaseorder/" + result[0].lastinsterid + "/" + transportPORecordID);
        }
    }

    const handleSubmitSearch = (e) => {
        e.preventDefault();
        if (typeof (endDate) == 'undefined' || typeof (startDate) == 'undefined') {

            setTimeout(() => {
                toast.success('Please select a date that is at least 1 business day from now', {
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });
            });

            return true;
        }

        const payload = {
            from: startDate,
            to: endDate,
            status: 2,
        }
        axios
            .post(API_BASE_URL + "/findDataUsingDate", payload)
            .then(function (response) {
                setOrderData(response.data);
            })
            .catch(error => {
                console.log(error);
            })
    }

    function handleLogout() {
        localStorage.removeItem(ACCESS_TOKEN_NAME);
        localStorage.removeItem('role');
        localStorage.removeItem('user');
        localStorage.removeItem(ACCESS_TOKEN_NAME_USER);
        props.history.push("/");
    }

    // for format date of date range picker
    useEffect(() => {
        const startDateElement = document.querySelector('#s_id');
        const endDateElement = document.querySelector('#e_id');

        const startDateHandleEvent = () => {

            // console.log('Event triggered', startDateElement.value);
            console.log('start date call', startDateElement.value)

            let s_date = startDateElement.value;
            if (s_date.length == 8) {
                s_date = moment(s_date, 'MM-DD-YYYY');

                if (s_date._isValid) {
                    setStartDate(s_date);
                    setDeliveryDate(moment(startDateElement.value), moment(endDateElement.value))
                }
                else {

                    toast.warning("Date is invalid!", {
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true
                    });
                }



            }

        };
        const endDateHandleEvent = () => {

            console.log('end date call', endDateElement.value)
            let e_date = endDateElement.value;
            // console.log(/^-?[\d.]+(?:e-?\d+)?$/.test(e_date),'data is number')
            if (e_date.length == 8) {
                e_date = moment(e_date, 'MM-DD-YYYY');
                if (e_date._isValid) {
                    setEndDate(e_date);
                    setDeliveryDate(moment(startDateElement.value), moment(endDateElement.value))

                }
                else {
                    toast.warning("Date is Invalid!", {
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true
                    });
                }

            }
        };

        const startDateCheck = () => {

            let s_date = moment(startDateElement.value, 'MMDDYYYY');
            if (s_date._isValid) {
                // setStartDate(s_date);
                // setDeliveryDate(moment(startDateElement.value), moment(endDateElement.value))
            }
            else {
                toast.warning("Start Date is Invalid!", {
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });
            }

        };

        const endDateCheck = () => {

            let e_date = moment(endDateElement.value, 'MM-DD-YYYY');
            //  console.log('check end date',endDateElement.value,endScheduledDate,e_date,e_date.format("YYYY-MM-DD")==endScheduledDate.format("YYYY-MM-DD"))

            if (e_date._isValid) {
                // setEndDate(e_date);
                // setDeliveryDate(moment(startDateElement.value), moment(endDateElement.value))

            }
            else if (endDateElement.value && endDateElement.value.length > 0) {
                toast.warning("End Date is Invalid!", {
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });
            }

        }

        const startDateclear = () => {
            setStartDate('');
        }
        const endDateclear = () => {
            setEndDate('');
        }

        if (startDateElement) {
            startDateElement.addEventListener('input', startDateHandleEvent);
            startDateElement.addEventListener('focusout', startDateCheck);
            startDateElement.addEventListener('focusin', startDateclear);

        }

        if (endDateElement) {
            endDateElement.addEventListener('input', endDateHandleEvent);
            endDateElement.addEventListener('focusout', endDateCheck);
            endDateElement.addEventListener('focusin', endDateclear);

        }

        // Cleanup function to remove the event listener when the component unmounts
        return () => {
            if (startDateElement) {
                startDateElement.addEventListener('input', startDateHandleEvent);
                startDateElement.addEventListener('focusout', startDateCheck);
                startDateElement.addEventListener('focusin', startDateclear);

            }
            if (endDateElement) {
                endDateElement.addEventListener('input', endDateHandleEvent);
                endDateElement.addEventListener('focusout', endDateCheck);
                endDateElement.addEventListener('focusin', endDateclear);

            }
        };
    }, []);

    return (
        <div id="pcoded" className="pcoded">
            <div className="pcoded-overlay-box"></div>
            <div className="pcoded-container navbar-wrapper">
                <nav className="navbar header-navbar pcoded-header" pcoded-header-position="fixed" style={{ height: '55px', minHeight: '26px', position: 'fixed' }}>
                    <div className="navbar-wrapper">
                        <div className="navbar-logo" style={{ height: '52px' }}>
                            <a style={{ top: '7px' }} className="mobile-menu navabar_menu" id="mobile-collapse" href="#!">
                                <i class="ti-menu" style={{ "color": " #000", "font-size": "22px;" }}></i>
                            </a>

                            <Link to="/dashboard">
                                <img style={{ width: '30%' }} className="img-fluid logo-img" src={logo} alt="Theme-Logo" />
                            </Link>
                        </div>

                        <SyncComponent setPageRefresh={setPageRefresh} pageNameList={pageNameList} />
                    </div>
                </nav>


                <div className="pcoded-main-container">
                    <div className="pcoded-wrapper">
                        <Sidebar />
                        <div className="pcoded-content page_content">
                            <div className="pcoded-inner-content">
                                <div className="main-body">
                                    <div className="page-wrapper">
                                        <ToastContainer />
                                        <div className="page-body">
                                            {
                                                /*
                                                    <div className="row">
                                                        <div className="col-md-8 col-xl-8">
                                                            <h4 style={{ float: "left", marginTop: "6px", fontSize: "large", marginLeft: "15px" }}>Welcome Call Needed List</h4>
                                                        </div>
                                                    </div>
                                                */
                                            }
                                            <div className="col-md-12 tabs-ui p-0">
                                                <div className='row justify-content-center align-items-center color-choose'>
                                                    <h5 className='cstmstates'><span>RTO</span><span className='statecolor rto'></span></h5>
                                                    <h5 className='cstmstates'><span>Solid Surface</span><span className='statecolor solid_surface'></span></h5>
                                                    <h5 className='cstmstates'><span>RTO on Solid Surface</span><span className='statecolor rto_ss'></span></h5>
                                                    <h5 className='cstmstates'><span>Tow Truck</span><span className='statecolor solid_tow'></span></h5>
                                                    <h5 className='cstmstates'><span>RTO + Tow Truck</span><span className='statecolor rto-two'></span></h5>
                                                    <h5 className='cstmstates'><span>Solid Surface + Tow Truck</span><span className='statecolor solid-two'></span></h5>
                                                </div>
                                                <div className="tab-content" id="nav-tabContent">
                                                    <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">

                                                        <div className="row" style={{ marginLeft: "0px" }}>


                                                            <div className="col-md-8 col-xl-8">
                                                                {
                                                                    /*
                                                                        <form>
                                                                            <div className="row">
                                                                                <div className="col-md-3">
                                                                                    <div className="form-group text-left">
                                                                                        <DatePicker placeholderText="From" className="form-control"  selected={startDate} onChange={(date) => setStartDate(date)} />
                                                                                    </div>
                                                                                </div>

                                                                                <div className="col-md-3">
                                                                                    <div className="form-group text-left">
                                                                                        <DatePicker placeholderText="To" selected={endDate} className="form-control" onChange={(date) => setEndDate(date)} />
                                                                                    </div>
                                                                                </div>

                                                                                <div className="col-md-2">
                                                                                    <div className="form-group text-left">
                                                                                        <button
                                                                                            type="submit"
                                                                                            className="btn btn-primary btn-sm"
                                                                                            onClick={handleSubmitSearch}
                                                                                        >
                                                                                            Submit
                                                                                        </button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </form>
                                                                    */
                                                                }
                                                            </div>

                                                        </div>


                                                        <div className="col-md-12">


                                                            <div className="App">
                                                                {<><h5 className="mb-3">Delivered Date</h5><div className='row pl-3'>
                                                                    <DateRangePicker
                                                                        startDate={startDate}
                                                                        endDate={endDate}


                                                                        startDateId="s_id"
                                                                        endDateId="e_id"
                                                                        onDatesChange={({ startDate, endDate }) => { setDeliveryDate(startDate, endDate); }}
                                                                        focusedInput={focusedInput}
                                                                        onFocusChange={e => setFocusedInput(e)}
                                                                        displayFormat="MM/DD/YYYY" // Use the desired format here
                                                                        isOutsideRange={() => false} // Allow selection of past and future dates
                                                                    />
                                                                    <button className='btn btn-dark rounded-5 mx-3' onClick={clearDates}>x</button>
                                                                </div></>}

                                                            </div>



                                                            <MaterialTable
                                                                title=""
                                                                key={pageRefresh}
                                                                columns={[
                                                                    { title: "RecordID", field: "RecordID", hidden: true },
                                                                    {
                                                                        title: "Customer", sortDescFirst: "false",
                                                                        defaultSort: cookiesi.getAll()['orderBy'] == 'invoiceCustomerFullName' ? cookiesi.getAll()['direction'] : false,
                                                                        filterComponent: ({ columnDef, onFilterChanged }) => (
                                                                            <FilterComponentBox pageName={'delivered'} onFilterChanged={onFilterChanged} columnDef={columnDef} columnName={columnDef.field} />
                                                                        ),
                                                                        field: "invoiceRefNumber", render: rowData => <a target="_blank" className={`${rowData.sameTrailerMarker === 1 &&  rowData.invoiceIsExpedited === 'Yes' ? 'markerExp' : rowData.invoiceIsExpedited === 'Yes' ? 'expeditedClass' : rowData.sameTrailerMarker === 1 ? 'sameMarker' : ''}`} href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID}><strong className="title-strong">{rowData.Customer?.split('*')[0]}</strong><br />{rowData.Customer?.split('*')[1]}</a>
                                                                    },

                                                                    //{ title: "Inv Date", field: "invoiceTxnDate", defaultSort: cookiesi.getAll()['orderBy'] == 'invoiceTxnDate' ? cookiesi.getAll()['direction'] : false, filterComponent: (props) => <CustomDatePicker pageName={'delivered'} {...props} />, type: 'date', dateSetting: { locale: "en-US" }, render: rowData => <a target="_blank" href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID}>{(rowData.InvoiceDate) ? moment(rowData.InvoiceDate).format('MM/DD/YY') : ''}</a> },
                                                                    { title: "Paid Date", field: "invoicePaidDate", defaultSort: cookiesi.getAll()['orderBy'] == 'invoicePaidDate' ? cookiesi.getAll()['direction'] : false, filterComponent: (props) => <CustomDatePicker pageName={'delivered'} {...props} />, type: 'date', render: rowData => <a target="_blank" className={`${rowData.sameTrailerMarker === 1 &&  rowData.invoiceIsExpedited === 'Yes' ? 'markerExp' : rowData.invoiceIsExpedited === 'Yes' ? 'expeditedClass' : rowData.sameTrailerMarker === 1 ? 'sameMarker' : ''}`} href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID}>{(rowData.PaidDate) ? moment(rowData.PaidDate).format('MM/DD/YY') : ''}</a> },


                                                                    {
                                                                        title: "Product", defaultSort: cookiesi.getAll()['orderBy'] == 'invoiceLineItemItemNameWithoutParent' ? cookiesi.getAll()['direction'] : false,
                                                                        filterComponent: ({ columnDef, onFilterChanged }) => (
                                                                            <FilterComponentBox pageName={'delivered'} onFilterChanged={onFilterChanged} columnDef={columnDef} columnName={columnDef.field} />
                                                                        ),
                                                                        field: "invoiceLineItemItemNameWithoutParent", render: rowData => <a target="_blank" className={`${rowData.sameTrailerMarker === 1 &&  rowData.invoiceIsExpedited === 'Yes' ? 'markerExp' : rowData.invoiceIsExpedited === 'Yes' ? 'expeditedClass' : rowData.sameTrailerMarker === 1 ? 'sameMarker' : ''}`} href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID}>{rowData.Product}</a>
                                                                    },
                                                                    {
                                                                        title: "Assign To", defaultSort: cookiesi.getAll()['orderBy'] == 'invoiceAssignedToName' ? cookiesi.getAll()['direction'] : false,
                                                                        filterComponent: ({ columnDef, onFilterChanged }) => (
                                                                            <FilterComponentBox pageName={'delivered'} onFilterChanged={onFilterChanged} columnDef={columnDef} columnName={columnDef.field} />
                                                                        ),
                                                                        field: "invoiceAssignedToName", render: rowData => <a target="_blank" className={`${rowData.sameTrailerMarker === 1 &&  rowData.invoiceIsExpedited === 'Yes' ? 'markerExp' : rowData.invoiceIsExpedited === 'Yes' ? 'expeditedClass' : rowData.sameTrailerMarker === 1 ? 'sameMarker' : ''}`} href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID}>{rowData.AssignedTo}</a>
                                                                    },


                                                                    // {
                                                                    //     title: "Vendor", field: "vendorName",
                                                                    //     defaultSort: cookiesi.getAll()['orderBy'] == 'vendorName' ? cookiesi.getAll()['direction'] : false,
                                                                    //     filterComponent: ({ columnDef, onFilterChanged }) => (
                                                                    //         <FilterComponentBox pageName={'delivered'} onFilterChanged={onFilterChanged} columnDef={columnDef} columnName={columnDef.field} />
                                                                    //     ),
                                                                    //     render: (rowData) => {
                                                                    //         return <a className={`breakword ${rowData.invoiceIsExpedited === 'Yes' ? 'expeditedClass' : ''}`} target="_blank" href={`https://www.google.com/maps/dir/?api=1&origin=${avoidNull(rowData.resalePOPickupAddress1)}${avoidNull(rowData.resalePOPickupCity)}${avoidNull2(rowData.resalePOPickupZipCode)}&destination=${removeAddress(rowData.invoiceShipAddress1,rowData.shipState)}${removeAddress(rowData.invoiceShipAddress2, rowData.shipState)}${removeAddress(rowData.invoiceShipAddress3, rowData.shipState)}${avoidNull(rowData.invoiceShipCity)}${avoidNull(rowData.shipState)}${avoidNull2(rowData.invoiceShipZip)}`}>{rowData.vendorName}</a>
                                                                    //     }
                                                                    // },
                                                                    // {
                                                                    //     title: "Cus Note", defaultSort: cookiesi.getAll()['orderBy'] == 'invoiceCustomerNotes' ? cookiesi.getAll()['direction'] : false,
                                                                    //     filterComponent: ({ columnDef, onFilterChanged }) => (
                                                                    //         <FilterComponentBox pageName={'delivered'} onFilterChanged={onFilterChanged} columnDef={columnDef} columnName={columnDef.field} />
                                                                    //     ),
                                                                    //     field: "invoiceCustomerNotes", render: rowData => <a target="_blank" href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID}>{rowData.invoiceCustomerNotes?.split(' ').map((item, index) => (index <= 20) ? item + ' ' : '')}</a>
                                                                    // },
                                                                    // {
                                                                    //     title: "Balance", defaultSort: cookiesi.getAll()['orderBy'] == 'invoiceBalanceRemaining' ? cookiesi.getAll()['direction'] : false,
                                                                    //     filterComponent: ({ columnDef, onFilterChanged }) => (
                                                                    //         <FilterComponentBox pageName={'delivered'} onFilterChanged={onFilterChanged} columnDef={columnDef} columnName={columnDef.field} />
                                                                    //     ),
                                                                    //     field: "invoiceBalanceRemaining", render: rowData => <a target="_blank" href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID}>{rowData.Balance}</a>, cellStyle: { textAlign: 'center' }
                                                                    // },
                                                                    {
                                                                        title: "Sup Loc", defaultSort: cookiesi.getAll()['orderBy'] == 'invoiceSupplierLocation' ? cookiesi.getAll()['direction'] : false,
                                                                        filterComponent: ({ columnDef, onFilterChanged }) => (
                                                                            <FilterComponentBox pageName={'delivered'} onFilterChanged={onFilterChanged} columnDef={columnDef} columnName={columnDef.field} />
                                                                        ),
                                                                        field: "invoiceSupplierLocation", render: rowData => <a target="_blank" className={`${rowData.sameTrailerMarker === 1 &&  rowData.invoiceIsExpedited === 'Yes' ? 'markerExp' : rowData.invoiceIsExpedited === 'Yes' ? 'expeditedClass' : rowData.sameTrailerMarker === 1 ? 'sameMarker' : ''}`} href={'https://www.google.com/maps/place/' + rowData.SupplierLocation}>{rowData.SupplierLocation}</a>
                                                                    },




                                                                    {
                                                                        title: "Resale PO #", defaultSort: cookiesi.getAll()['orderBy'] == 'resalePORefNumer' ? cookiesi.getAll()['direction'] : false,
                                                                        filterComponent: ({ columnDef, onFilterChanged }) => (
                                                                            <FilterComponentBox pageName={'delivered'} onFilterChanged={onFilterChanged} columnDef={columnDef} columnName={columnDef.field} />
                                                                        ),
                                                                        field: "resalePORefNumer", render: rowData => <a target="_blank"  className={`${rowData.sameTrailerMarker === 1 &&  rowData.invoiceIsExpedited === 'Yes' ? 'markerExp' : rowData.invoiceIsExpedited === 'Yes' ? 'expeditedClass' : rowData.sameTrailerMarker === 1 ? 'sameMarker' : ''}`} href={'/resalePurchaseOrder/' + rowData.ResalePORecordID + '/' + rowData.RecordID}>{rowData.ResalePO}</a>
                                                                    },



                                                                    // {
                                                                    //     title: "Res Supp Loc", field: "resalePOSupplierLocation",
                                                                    //     defaultSort: cookiesi.getAll()['orderBy'] == 'resalePOSupplierLocation' ? cookiesi.getAll()['direction'] : false,
                                                                    //     filterComponent: ({ columnDef, onFilterChanged }) => (
                                                                    //         <FilterComponentBox pageName={'delivered'} onFilterChanged={onFilterChanged} columnDef={columnDef} columnName={columnDef.field} />
                                                                    //     ),
                                                                    //     render: rowData => <a target="_blank" href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID}>{rowData.resalePOSupplierLocation}</a>
                                                                    // },

                                                                    // {
                                                                    //     title: "Rel#", defaultSort: cookiesi.getAll()['orderBy'] == 'resalePOReleaseNumber' ? cookiesi.getAll()['direction'] : false,
                                                                    //     filterComponent: ({ columnDef, onFilterChanged }) => (
                                                                    //         <FilterComponentBox pageName={'delivered'} onFilterChanged={onFilterChanged} columnDef={columnDef} columnName={columnDef.field} />
                                                                    //     ),
                                                                    //     field: "resalePOReleaseNumber", render: rowData => <a target="_blank" href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID}>{rowData.Resale}</a>
                                                                    // },

                                                                    {
                                                                        title: "Release Date", defaultSort: cookiesi.getAll()['orderBy'] == 'resalePOReleaseDate' ? cookiesi.getAll()['direction'] : false,
                                                                        filterComponent: ({ columnDef, onFilterChanged }) => (
                                                                            <FilterComponentBox pageName={'delivered'} onFilterChanged={onFilterChanged} columnDef={columnDef} columnName={columnDef.field} />
                                                                        ),
                                                                        field: "resalePOReleaseDate", filterComponent: (props) => <CustomDatePicker pageName={'delivered'} {...props} />, type: 'date', render: rowData => <a target="_blank" className={`${rowData.sameTrailerMarker === 1 &&  rowData.invoiceIsExpedited === 'Yes' ? 'markerExp' : rowData.invoiceIsExpedited === 'Yes' ? 'expeditedClass' : rowData.sameTrailerMarker === 1 ? 'sameMarker' : ''}`}  href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID}>{(rowData.ReleaseDate) ? moment(rowData.ReleaseDate).format('MM/DD/YY') : ''}</a>
                                                                    },
                                                                    {
                                                                        title: "Del City/State", defaultSort: cookiesi.getAll()['orderBy'] == 'invoiceShipCity' ? cookiesi.getAll()['direction'] : false,
                                                                        filterComponent: ({ columnDef, onFilterChanged }) => (
                                                                            <FilterComponentBox pageName={'delivered'} onFilterChanged={onFilterChanged} columnDef={columnDef} columnName={columnDef.field} />
                                                                        ),
                                                                        field: "invoiceShipCity", render: (rowData) => {
                                                                            return <a className={`breakword ${rowData.sameTrailerMarker === 1 &&  rowData.invoiceIsExpedited === 'Yes' ? 'markerExp' : rowData.invoiceIsExpedited === 'Yes' ? 'expeditedClass' : rowData.sameTrailerMarker === 1 ? 'sameMarker' : ''}`} target="_blank" href={`https://www.google.com/maps/dir/?api=1&origin=${avoidNull(rowData.resalePOPickupAddress1)}${avoidNull(rowData.resalePOPickupCity)}${avoidNull2(rowData.resalePOPickupZipCode)}&destination=${removeAddress(rowData.invoiceShipAddress1)}${removeAddress(rowData.invoiceShipAddress2)}${removeAddress(rowData.invoiceShipAddress3)}${avoidNull(rowData.invoiceShipCity)}${avoidNull(rowData.shipState)}${avoidNull2(rowData.invoiceShipZip)}`}>{rowData.shipCity}<br />{rowData.shipState}</a>
                                                                        }
                                                                    },
                                                                    {
                                                                        title: "Container Number #", field: "transportPOLineItemContainerNumber",
                                                                        defaultSort: cookiesi.getAll()['orderBy'] == 'transportPOLineItemContainerNumber' ? cookiesi.getAll()['direction'] : false,
                                                                        filterComponent: ({ columnDef, onFilterChanged }) => (
                                                                            <FilterComponentBox pageName={'delivered'} onFilterChanged={onFilterChanged} columnDef={columnDef} columnName={columnDef.field} />
                                                                        ),
                                                                        render: rowData => <a target="_blank" className={`${rowData.sameTrailerMarker === 1 &&  rowData.invoiceIsExpedited === 'Yes' ? 'markerExp' : rowData.invoiceIsExpedited === 'Yes' ? 'expeditedClass' : rowData.sameTrailerMarker === 1 ? 'sameMarker' : ''}`} href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID}>{rowData.transportPOLineItemContainerNumber}</a>
                                                                    },

                                                                    // {
                                                                    //     title: "Last Depot Call",
                                                                    //     filterComponent: (props) => <CustomDatePicker pageName={'delivered'} {...props} />,
                                                                    //     field: "resalePOLastDepotCallDate", type: 'date', render: rowData => <a target="_blank" href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID}>{(rowData.LastDepotCall) ? moment(rowData.LastDepotCall).format('MM/DD/YY') : ''}</a>
                                                                    // },

                                                                    // {
                                                                    //     title: "Vendor Notes", field: "invoiceVendorNotes",
                                                                    //     defaultSort: cookiesi.getAll()['orderBy'] == 'invoiceVendorNotes' ? cookiesi.getAll()['direction'] : false,
                                                                    //     filterComponent: ({ columnDef, onFilterChanged }) => (
                                                                    //         <FilterComponentBox pageName={'delivered'} onFilterChanged={onFilterChanged} columnDef={columnDef} columnName={columnDef.field} />
                                                                    //     ),
                                                                    //     render: rowData => <a target="_blank" href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID}>{rowData.invoiceVendorNotes?.split(' ').map((item, index) => (index <= 20) ? item + ' ' : '')}</a>
                                                                    // },



                                                                    // {
                                                                    //     title: "ResalePO Date",
                                                                    //     defaultSort: cookiesi.getAll()['orderBy'] == 'resalePOTxnDate' ? cookiesi.getAll()['direction'] : false,
                                                                    //     filterComponent: (props) => <CustomDatePicker pageName={'delivered'} {...props} />,
                                                                    //     field: "resalePOTxnDate", type: 'date', render: rowData => <a target="_blank" href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID}>{(rowData.resalePOTxnDate) ? moment(rowData.resalePOTxnDate).format('MM/DD/YY') : ''}</a>
                                                                    // },

                                                                    // {
                                                                    //     title: "Res Total",
                                                                    //     defaultSort: cookiesi.getAll()['orderBy'] == 'resalePOTotalPrice' ? cookiesi.getAll()['direction'] : false,
                                                                    //     filterComponent: ({ columnDef, onFilterChanged }) => (
                                                                    //         <FilterComponentBox pageName={'delivered'} onFilterChanged={onFilterChanged} columnDef={columnDef} columnName={columnDef.field} />
                                                                    //     ),
                                                                    //     field: "resalePOTotalPrice", render: rowData => <a target="_blank" href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID}>{rowData.ResaleTotal}</a>
                                                                    // },
                                                                    // {
                                                                    //     title: "Expected",
                                                                    //     defaultSort: cookiesi.getAll()['orderBy'] == 'resalePOExpectedDate' ? cookiesi.getAll()['direction'] : false,
                                                                    //     filterComponent: (props) => <CustomDatePicker pageName={'delivered'} {...props} />,
                                                                    //     field: "resalePOExpectedDate", type: 'date', render: rowData => <a target="_blank" href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID}>{(rowData.Expected) ? moment(rowData.Expected).format('MM/DD/YY') : ''}</a>
                                                                    // },

                                                                    // {
                                                                    //     title: "Resale Supplier Inv",
                                                                    //     defaultSort: cookiesi.getAll()['orderBy'] == 'resalePOSupplierInvoice' ? cookiesi.getAll()['direction'] : false,
                                                                    //     filterComponent: ({ columnDef, onFilterChanged }) => (
                                                                    //         <FilterComponentBox pageName={'delivered'} onFilterChanged={onFilterChanged} columnDef={columnDef} columnName={columnDef.field} />
                                                                    //     ),
                                                                    //     field: "resalePOSupplierInvoice", render: rowData => <a target="_blank" href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID}>{rowData.resalePOSupplierInvoice}</a>
                                                                    // },





                                                                    {
                                                                        title: "Tran PO #", field: "transportPORefNumer",
                                                                        defaultSort: cookiesi.getAll()['orderBy'] == 'transportPORefNumer' ? cookiesi.getAll()['direction'] : false,
                                                                        filterComponent: ({ columnDef, onFilterChanged }) => (
                                                                            <FilterComponentBox pageName={'delivered'} onFilterChanged={onFilterChanged} columnDef={columnDef} columnName={columnDef.field} />
                                                                        ),
                                                                        render: rowData => (rowData.planningId && rowData.TransportPORecordID) ? <a target="_blank" className={`${rowData.sameTrailerMarker === 1 &&  rowData.invoiceIsExpedited === 'Yes' ? 'markerExp' : rowData.invoiceIsExpedited === 'Yes' ? 'expeditedClass' : rowData.sameTrailerMarker === 1 ? 'sameMarker' : ''}`} href={'/exitingPurchaseorder/' + rowData.planningId + '/' + rowData.TransportPORecordID}>{rowData.transportPORefNumer}</a> : (rowData.TransportPORecordID) ? <a href="javascript:void(0)" className={`${rowData.sameTrailerMarker === 1 &&  rowData.invoiceIsExpedited === 'Yes' ? 'markerExp' : rowData.invoiceIsExpedited === 'Yes' ? 'expeditedClass' : rowData.sameTrailerMarker === 1 ? 'sameMarker' : ''}`} onClick={() => createPlan(rowData.invoiceLineItemItemNameWithoutParent, rowData.orderStatusRecordID, rowData.resalePORefNumer, rowData.transportPOVendor, rowData.TransportPORecordID)} >{rowData.transportPORefNumer}</a> : ''
                                                                    },
                                                                    {
                                                                        title: "Driver", field: "driverName",
                                                                        defaultSort: cookiesi.getAll()['orderBy'] == 'driverName' ? cookiesi.getAll()['direction'] : false,
                                                                        filterComponent: ({ columnDef, onFilterChanged }) => (
                                                                            <FilterComponentBox pageName={'delivered'} onFilterChanged={onFilterChanged} columnDef={columnDef} columnName={columnDef.field} />
                                                                        ),
                                                                        render: (rowData) => {
                                                                            return <a className={`breakword ${rowData.sameTrailerMarker === 1 &&  rowData.invoiceIsExpedited === 'Yes' ? 'markerExp' : rowData.invoiceIsExpedited === 'Yes' ? 'expeditedClass' : rowData.sameTrailerMarker === 1 ? 'sameMarker' : ''}`} target="_blank" href={`https://www.google.com/maps/dir/?api=1&origin=${avoidNull(rowData.resalePOPickupAddress1)}${avoidNull(rowData.resalePOPickupCity)}${avoidNull2(rowData.resalePOPickupZipCode)}&destination=${removeAddress(rowData.invoiceShipAddress1, rowData.shipState)}${removeAddress(rowData.invoiceShipAddress2, rowData.shipState)}${removeAddress(rowData.invoiceShipAddress3, rowData.shipState)}${avoidNull(rowData.invoiceShipCity)}${avoidNull(rowData.shipState)}${avoidNull2(rowData.invoiceShipZip)}`}>{rowData.DriverName}</a>
                                                                        }
                                                                    },

                                                                    {
                                                                        title: "Tran Ven", field: "transportPOVendor",
                                                                        defaultSort: cookiesi.getAll()['orderBy'] == 'transportPOVendor' ? cookiesi.getAll()['direction'] : false,
                                                                        filterComponent: ({ columnDef, onFilterChanged }) => (
                                                                            <FilterComponentBox pageName={'delivered'} onFilterChanged={onFilterChanged} columnDef={columnDef} columnName={columnDef.field} />
                                                                        ),
                                                                        render: rowData => <a target="_blank" className={`breakword ${rowData.sameTrailerMarker === 1 &&  rowData.invoiceIsExpedited === 'Yes' ? 'markerExp' : rowData.invoiceIsExpedited === 'Yes' ? 'expeditedClass' : rowData.sameTrailerMarker === 1 ? 'sameMarker' : ''}`} href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID}>{rowData.transportPOVendor}</a>
                                                                    },

                                                                    {
                                                                        title: "Sch For",
                                                                        defaultSort: cookiesi.getAll()['orderBy'] == 'transportPOScheduledDeliveryDate' ? cookiesi.getAll()['direction'] : false,
                                                                        filterComponent: (props) => <CustomDatePicker pageName={'delivered'} {...props} />,
                                                                        field: "transportPOScheduledDeliveryDate", type: 'date', render: rowData => <a target="_blank" className={`${rowData.sameTrailerMarker === 1 &&  rowData.invoiceIsExpedited === 'Yes' ? 'markerExp' : rowData.invoiceIsExpedited === 'Yes' ? 'expeditedClass' : rowData.sameTrailerMarker === 1 ? 'sameMarker' : ''}`} href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID}>{(rowData.ScheduledFor) ? moment(rowData.ScheduledFor).format('MM/DD/YY') : ''}</a>
                                                                    },

                                                                    {
                                                                        title: "Delivered On",
                                                                        field: "transportPODeliveredDate",
                                                                        defaultSort:
                                                                            cookiesi.getAll()["orderBy"] == "transportPODeliveredDate"
                                                                                ? cookiesi.getAll()["direction"]
                                                                                : false,
                                                                        filterComponent: (props) => (
                                                                            <CustomDatePicker pageName={"delivered"} {...props} />
                                                                        ),
                                                                        type: "date",
                                                                        render: (rowData) => (
                                                                            <a target="_blank" className={`${rowData.sameTrailerMarker === 1 &&  rowData.invoiceIsExpedited === 'Yes' ? 'markerExp' : rowData.invoiceIsExpedited === 'Yes' ? 'expeditedClass' : rowData.sameTrailerMarker === 1 ? 'sameMarker' : ''}`} href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID}>
                                                                                {rowData.transportPODeliveredDate
                                                                                    ? moment(rowData.transportPODeliveredDate).format("MM/DD/YY")
                                                                                    : ""}
                                                                            </a>
                                                                        ),
                                                                    },
                                                                    {
                                                                        title: "Del. Status", defaultSort: cookiesi.getAll()['orderBy'] == 'transportPODeliveryStatus' ? cookiesi.getAll()['direction'] : false,
                                                                        filterComponent: ({ columnDef, onFilterChanged }) => (
                                                                            <FilterComponentBox pageName={'delivered'} onFilterChanged={onFilterChanged} columnDef={columnDef} columnName={columnDef.field} />
                                                                        ),
                                                                        field: "transportPODeliveryStatus", render: rowData => <a target="_blank" className={`${rowData.sameTrailerMarker === 1 &&  rowData.invoiceIsExpedited === 'Yes' ? 'markerExp' : rowData.invoiceIsExpedited === 'Yes' ? 'expeditedClass' : rowData.sameTrailerMarker === 1 ? 'sameMarker' : ''}`} href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID}>{rowData.DeliveryStatus}</a>
                                                                    },
                                                                    // {
                                                                    //     title: "Ship Method", field: "invoiceShipMethodMethodFieldName",
                                                                    //     defaultSort: cookiesi.getAll()['orderBy'] == 'invoiceShipMethodMethodFieldName' ? cookiesi.getAll()['direction'] : false,
                                                                    //     filterComponent: ({ columnDef, onFilterChanged }) => (
                                                                    //         <FilterComponentBox pageName={'delivered'} onFilterChanged={onFilterChanged} columnDef={columnDef} columnName={columnDef.field} />
                                                                    //     ),
                                                                    //     render: rowData => <a target="_blank" className="breakwordwichpadding" href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID}>{rowData.invoiceShipMethodMethodFieldName}</a>
                                                                    // },
                                                                    {
                                                                        title: "Pro Timeline", defaultSort: cookiesi.getAll()['orderBy'] == 'invoicePromisedTimeline' ? cookiesi.getAll()['direction'] : false,
                                                                        filterComponent: ({ columnDef, onFilterChanged }) => (
                                                                            <FilterComponentBox pageName={'delivered'} onFilterChanged={onFilterChanged} columnDef={columnDef} columnName={columnDef.field} />
                                                                        ), field: "invoicePromisedTimeline", render: rowData => <a target="_blank" className={`${rowData.sameTrailerMarker === 1 &&  rowData.invoiceIsExpedited === 'Yes' ? 'markerExp' : rowData.invoiceIsExpedited === 'Yes' ? 'expeditedClass' : rowData.sameTrailerMarker === 1 ? 'sameMarker' : ''}`} href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID}>{rowData.PromisedTimeline}</a>
                                                                    },


                                                                    { title: "Pro Date", defaultSort: cookiesi.getAll()['orderBy'] == 'invoicePromisedDate' ? cookiesi.getAll()['direction'] : false, field: "invoicePromisedDate", filterComponent: (props) => <CustomDatePicker pageName={'delivered'} {...props} />, type: 'date', render: rowData => <a target="_blank" className={`${rowData.sameTrailerMarker === 1 &&  rowData.invoiceIsExpedited === 'Yes' ? 'markerExp' : rowData.invoiceIsExpedited === 'Yes' ? 'expeditedClass' : rowData.sameTrailerMarker === 1 ? 'sameMarker' : ''}`} href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID}>{(rowData.PromisedDate) ? moment(rowData.PromisedDate).format('MM/DD/YY') : ''}</a> }
                                                                ]}

                                                                data={query =>
                                                                    new Promise((resolve, reject) => {

                                                                        const cookies = new Cookies();
                                                                        //cookies.remove('direction');
                                                                        const { match } = props;
                                                                        cookies.remove('adminer_version');
                                                                        // setCookie("orderBy",query.orderBy.field,{ path: '/delivered' });
                                                                        // setCookie("direction",query.orderDirection,{ path: '/delivered' });
                                                                        let filterDelivered = ''
                                                                        if (match.params.filter) {
                                                                            // if( match.params.id == 'today')
                                                                            // {
                                                                            //     filterDelivered = ''
                                                                            // }
                                                                            // else if(match.params.id == 'yesterday')
                                                                            // {

                                                                            // }
                                                                            // else if(match.parmas.id == 'month')
                                                                            // {

                                                                            // }
                                                                            // else if(match.params.id == 'lastMonth')
                                                                            // {

                                                                            // }
                                                                            const timezone = jstz.determine();
                                                                            let url = API_BASE_URL + `/fetchOrderBasedOnColumn?`;
                                                                            url += 'size=' + query.pageSize;
                                                                            url += '&page=' + (query.page + 1);
                                                                            url += '&orderNumber=' + 18;
                                                                            url += '&' + match.params.filter + '=' + moment().tz(timezone.name()).format('MM/DD/YYYY');
                                                                            console.log('asdasdasdas', url)
                                                                            if (query.orderBy) {
                                                                                removeCookie("orderBy", { path: "/delivered" });
                                                                                removeCookie("direction", { path: "/delivered" });
                                                                                if (query.orderBy.field == 'invoiceRefNumber') {

                                                                                    setCookie("orderBy", "invoiceCustomerFullName", { path: '/delivered' });
                                                                                }
                                                                                else {

                                                                                    setCookie("orderBy", query.orderBy.field, { path: '/delivered' });


                                                                                }

                                                                                setCookie("direction", query.orderDirection, { path: '/delivered' });

                                                                            }
                                                                            else {
                                                                                //console.log("achaasfsfsdfdsfsdfaaaa");

                                                                                url += '&sortField=invoiceTxnDate';
                                                                                url += '&sortType=desc';
                                                                                url += '&sortField=invoiceCustomerFullName';
                                                                                url += '&sortType=asc';
                                                                                url += '&sortField=resalePORecordID';
                                                                                url += '&sortType=asc';


                                                                            }
                                                                        }
                                                                        else if (query.filters.length > 0 || Object.keys(cookies.getAll()).length > 0) {
                                                                            let url = API_BASE_URL + `/fetchOrderBasedOnColumn?`;
                                                                            url += 'size=' + query.pageSize;
                                                                            url += '&page=' + (query.page + 1);
                                                                            url += '&orderNumber=' + 18;
                                                                            if (query.orderBy) {
                                                                                removeCookie("orderBy", { path: "/delivered" });
                                                                                removeCookie("direction", { path: "/delivered" });
                                                                                if (query.orderBy.field == 'invoiceRefNumber') {

                                                                                    setCookie("orderBy", "invoiceCustomerFullName", { path: '/delivered' });
                                                                                }
                                                                                else {

                                                                                    setCookie("orderBy", query.orderBy.field, { path: '/delivered' });


                                                                                }

                                                                                setCookie("direction", query.orderDirection, { path: '/delivered' });

                                                                            }
                                                                            else {
                                                                                //console.log("achaasfsfsdfdsfsdfaaaa");

                                                                                url += '&sortField=invoiceTxnDate';
                                                                                url += '&sortType=desc';
                                                                                url += '&sortField=invoiceCustomerFullName';
                                                                                url += '&sortType=asc';
                                                                                url += '&sortField=resalePORecordID';
                                                                                url += '&sortType=asc';


                                                                            }

                                                                            if (Object.keys(cookies.getAll()).length > 0) {

                                                                                for (const key in cookies.cookies) {

                                                                                    console.log(moment.isDate(cookies.cookies[key].replace('"', '').replace('"', '')), 'momentinfo', cookies.cookies[key], key);
                                                                                    if (cookies.cookies[key] == 'Invalid date') {
                                                                                        console.log('okkk')
                                                                                        removeCookie(key, { path: "/delivered" });
                                                                                    }

                                                                                    else {
                                                                                        if (cookies.cookies[key] && moment.isDate(cookies.cookies[key])) {
                                                                                            cookies.cookies[key] = moment(cookies.cookies[key]).format('YYYY-MM-DD')
                                                                                        }
                                                                                        if (cookies.cookies[key]) {

                                                                                            if (key == 'invoiceRefNumber' && isNaN(cookies.cookies[key])) {
                                                                                                url += '&columnName=' + 'invoiceCustomerFullName';
                                                                                                url += '&search=' + encodeURIComponent(cookies.cookies[key]);
                                                                                            }
                                                                                            else if (key == 'orderBy') {

                                                                                                url += '&sortField=' + cookies.cookies[key]
                                                                                            }
                                                                                            else if (key == 'direction') {
                                                                                                url += '&sortType=' + cookies.cookies[key]
                                                                                            }

                                                                                            else {
                                                                                                url += '&columnName=' + key;
                                                                                                url += '&search=' + encodeURIComponent(cookies.cookies[key]);


                                                                                            }
                                                                                        }
                                                                                    }


                                                                                }
                                                                            } else {
                                                                                query.filters.forEach((item) => {
                                                                                    console.log('sssssssdfsd', moment.isDate(item.value), item.value)
                                                                                    if (item.value && moment.isDate(item.value)) {
                                                                                        item.value = moment(item.value).format('YYYY-MM-DD')
                                                                                    }

                                                                                    if (item.column.field == 'invoiceRefNumber' && isNaN(parseInt(item.value))) {
                                                                                        url += '&columnName=' + 'invoiceCustomerFullName';
                                                                                        url += '&search=' + encodeURIComponent(item.value);
                                                                                    } else {
                                                                                        url += '&columnName=' + item.column.field;
                                                                                        url += '&search=' + encodeURIComponent(item.value);
                                                                                    }
                                                                                });
                                                                            }

                                                                            console.log("acha", url);
                                                                            axios.get(url, {
                                                                                headers: {
                                                                                    Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN_NAME),
                                                                                },
                                                                            }).then(result => {
                                                                                resolve({
                                                                                    data: result.data.rows.map((data) => ({
                                                                                        orderStatusRecordID: data.recordID,
                                                                                        RecordID: data.invoiceRecordID,
                                                                                        Customer: `${data.invoiceCustomerFullName.replace(',', ' ')}*${data.invoiceRefNumber}`,
                                                                                        InvoiceRefNumber: data.invoiceRefNumber,
                                                                                        InvoiceDate: (data.invoiceTxnDate) ? data.invoiceTxnDate?.split('T')[0] : '',
                                                                                        PaidDate: (data.invoicePaidDate) ? data.invoicePaidDate?.split('T')[0] : '',
                                                                                        Product: data.invoiceLineItemItemNameWithoutParent,
                                                                                        AssignedTo: data.invoiceAssignedToName,
                                                                                        invoiceCustomerNotes: data?.invoiceCustomerNotes,
                                                                                        DriverName: (data.planning) ? data.planning.driverName : null,
                                                                                        // vendorName: (data.purchaseOrder) ? data.purchaseOrder.vendor : null,

                                                                                        invoiceTerms: data.invoiceTerms,
                                                                                        Balance: (data.invoiceBalanceRemaining) ? data.invoiceBalanceRemaining : '',
                                                                                        SupplierLocation: data.invoiceSupplierLocation,
                                                                                        ResalePO: data.resalePORefNumer,
                                                                                        Resale: data.resalePOReleaseNumber,
                                                                                        invoiceShipAddress1: data.invoiceShipAddress1,
                                                                                        invoiceShipAddress2: data.invoiceShipAddress2,
                                                                                        invoiceShipAddress3: data.invoiceShipAddress3,
                                                                                        invoiceShipCity: data.invoiceShipCity,
                                                                                        invoiceShipZip: data.invoiceShipZip,
                                                                                        resalePOPickupAddress1: data.resalePOPickupAddress1,
                                                                                        resalePOPickupCity: data.resalePOPickupCity,
                                                                                        resalePOPickupZipCode: data.resalePOPickupZipCode,
                                                                                        ResalePORecordID: data.resalePORecordID,
                                                                                        ReleaseDate: (data.resalePOReleaseDate) ? data.resalePOReleaseDate?.split('T')[0] : '',
                                                                                        LastDepotCall: (data.resalePOLastDepotCallDate) ? data.resalePOLastDepotCallDate?.split('T')[0] : '',
                                                                                        invoiceVendorNotes: data?.invoiceVendorNotes,
                                                                                        transportPOVendor: data.transportPOVendor,

                                                                                        shipCity: (data.invoiceShipCity) ? data.invoiceShipCity : '',
                                                                                        shipState: (data.invoiceShipState) ? data.invoiceShipState : '',
                                                                                        TransportPO: (data.transportPORecordID) ? data.transportPORefNumer : data.invoiceRefNumber + 'T',
                                                                                        transportPORefNumer: (data.transportPORecordID) ? data.transportPORefNumer : data.invoiceRefNumber + 'T',
                                                                                        TransportPORecordID: data.transportPORecordID,
                                                                                        transportPODeliveredDate: (data.transportPODeliveredDate) ? data.transportPODeliveredDate?.split('T')[0] : '',
                                                                                        planningId: (data.planning) ? data.planning.id : null,
                                                                                        solidSurface: (data.invoice?.solidSurface) ? data.invoice.solidSurface : 0,
                                                                                        towTruck: (data.invoice?.towTruck) ? data.invoice.towTruck : 0,

                                                                                        invoiceShipAddress1: data.invoiceShipAddress1,
                                                                                        invoiceShipAddress2: data.invoiceShipAddress2,
                                                                                        invoiceShipAddress3: data.invoiceShipAddress3,
                                                                                        invoiceShipCity: data.invoiceShipCity,
                                                                                        invoiceShipZip: data.invoiceShipZip,
                                                                                        resalePOPickupAddress1: data.resalePOPickupAddress1,
                                                                                        resalePOPickupCity: data.resalePOPickupCity,
                                                                                        resalePOPickupZipCode: data.resalePOPickupZipCode,
                                                                                        resalePOVendor: data.resalePOVendor,
                                                                                        resalePOSupplierLocation: data?.resalePOSupplierLocation,
                                                                                        resalePOSupplierInvoice: data?.resalePOSupplierInvoice,
                                                                                        ResaleTotal: data.resalePOTotalPrice,
                                                                                        Expected: (data.resalePOExpectedDate) ? data.resalePOExpectedDate?.split('T')[0] : '',
                                                                                        ExpectedDeliveryDate: (data.resalePOExpectedDate) ? data.resalePOExpectedDate?.split('T')[0] : '',
                                                                                        invoiceShipMethodMethodFieldName: data?.invoiceShipMethodMethodFieldName,
                                                                                        ScheduledFor: (data.transportPOScheduledDeliveryDate) ? data.transportPOScheduledDeliveryDate?.split('T')[0] : '',

                                                                                        invoiceLineItem_RecordID: data.invoiceLineItem_RecordID,

                                                                                        transportPOLineItemContainerNumber: data.transportPOLineItemContainerNumber,
                                                                                        resalePOTxnDate: (data.resalePOTxnDate) ? data.resalePOTxnDate?.split('T')[0] : '',
                                                                                        sameTrailerMarker: data.sameTrailerMarker,
                                                                                        DeliveryStatus: data.transportPODeliveryStatus,
                                                                                        PromisedTimeline: (data.invoicePromisedTimeline) ? data.invoicePromisedTimeline : '',
                                                                                        PromisedDate: (data.invoicePromisedDate) ? data.invoicePromisedDate?.split('T')[0] : '',
                                                                                    })),
                                                                                    page: result.data.currentPage - 1,
                                                                                    totalCount: result.data.totalItems,
                                                                                });
                                                                                setPageRefresh(false);
                                                                            });
                                                                        } else {
                                                                            console.log('HI..KKHGGHJH');
                                                                            let url = API_BASE_URL + `/fetchCompletedOrder?`;
                                                                            url += 'size=' + query.pageSize;
                                                                            url += '&page=' + (query.page + 1);
                                                                            url += '&orderNumber=' + 18;
                                                                            if (query.orderBy) {

                                                                                if (query.orderBy.field == 'invoiceRefNumber') {
                                                                                    url += '&sortField=invoiceRefNumber'
                                                                                    setCookie("orderBy", "invoiceCustomerFullName", { path: '/delivered' });
                                                                                }
                                                                                else {
                                                                                    url += '&sortField=' + query.orderBy.field
                                                                                    setCookie("orderBy", query.orderBy.field, { path: '/delivered' });


                                                                                }
                                                                                url += '&sortType=' + query.orderDirection
                                                                                setCookie("direction", query.orderDirection, { path: '/delivered' });
                                                                            } else {
                                                                                console.log("heyyyy");
                                                                                switch (2) {
                                                                                    case '2':
                                                                                        url += '&sortField=transportPODeliveredDate';
                                                                                        url += '&sortType=asc';
                                                                                        url += '&sortField=invoiceCustomerFullName';
                                                                                        url += '&sortType=asc';
                                                                                        url += '&sortField=resalePORecordID';
                                                                                        url += '&sortType=asc';
                                                                                        break;
                                                                                    case '7':
                                                                                        url += '&sortField=invoicePaidDate';
                                                                                        url += '&sortType=asc';
                                                                                        url += '&sortField=invoiceCustomerFullName';
                                                                                        url += '&sortType=asc';
                                                                                        url += '&sortField=resalePORecordID';
                                                                                        url += '&sortType=asc';
                                                                                        break;
                                                                                    case '15':
                                                                                        url += '&sortField=invoicePaidDate';
                                                                                        url += '&sortType=asc';
                                                                                        url += '&sortField=invoiceCustomerFullName';
                                                                                        url += '&sortType=asc';
                                                                                        url += '&sortField=resalePORecordID';
                                                                                        url += '&sortType=asc';
                                                                                        break;
                                                                                    case '10':
                                                                                        url += '&sortField=transportPOScheduledDeliveryDate';
                                                                                        url += '&sortType=asc';
                                                                                        url += '&sortField=invoiceCustomerFullName';
                                                                                        url += '&sortType=asc';
                                                                                        url += '&sortField=resalePORecordID';
                                                                                        url += '&sortType=asc';
                                                                                        break;
                                                                                    default:
                                                                                        url += '&sortField=transportPODeliveredDate';
                                                                                        url += '&sortType=desc';
                                                                                        url += '&sortField=invoiceCustomerFullName';
                                                                                        url += '&sortType=asc';
                                                                                        url += '&sortField=resalePORecordID';
                                                                                        url += '&sortType=asc';
                                                                                }
                                                                            }

                                                                            if (match.params.filter) {
                                                                                const timezone = jstz.determine();
                                                                                url += '&' + match.params.filter + '=' + moment().tz(timezone.name()).format('MM/DD/YYYY');
                                                                            }

                                                                            if (query.search) {
                                                                                url += '&search=' + encodeURIComponent(query.search);
                                                                            }

                                                                            if (query.filters && !query.search) {
                                                                                query.filters.forEach((item) => {
                                                                                    url += '&search=' + encodeURIComponent(item.value);
                                                                                });
                                                                            }

                                                                            if (props.endDate && props.startDate) {
                                                                                url += '&from=' + props.startDate;
                                                                                url += '&to=' + props.endDate;
                                                                            }

                                                                            axios.get(url, {
                                                                                headers: {
                                                                                    Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN_NAME),
                                                                                },
                                                                            }).then(result => {
                                                                                resolve({
                                                                                    data: result.data.rows.map((data) => ({
                                                                                        orderStatusRecordID: data.recordID,
                                                                                        RecordID: data.invoiceRecordID,
                                                                                        Customer: `${data.invoiceCustomerFullName.replace(',', ' ')}*${data.invoiceRefNumber}`,
                                                                                        InvoiceRefNumber: data.invoiceRefNumber,
                                                                                        InvoiceDate: (data.invoiceTxnDate) ? data.invoiceTxnDate?.split('T')[0] : '',
                                                                                        PaidDate: (data.invoicePaidDate) ? data.invoicePaidDate?.split('T')[0] : '',
                                                                                        Product: data.invoiceLineItemItemNameWithoutParent,
                                                                                        AssignedTo: data.invoiceAssignedToName,
                                                                                        invoiceCustomerNotes: data?.invoiceCustomerNotes,
                                                                                        invoiceTerms: data.invoiceTerms,
                                                                                        Balance: (data.invoiceBalanceRemaining) ? data.invoiceBalanceRemaining : '',
                                                                                        SupplierLocation: data.invoiceSupplierLocation,
                                                                                        DriverName: (data.planning) ? data.planning.driverName : null,
                                                                                        // vendorName: (data.purchaseOrder) ? data.purchaseOrder.vendor : null,

                                                                                        ResalePO: data.resalePORefNumer,
                                                                                        Resale: data.resalePOReleaseNumber,
                                                                                        invoiceShipAddress1: data.invoiceShipAddress1,
                                                                                        invoiceShipAddress2: data.invoiceShipAddress2,
                                                                                        invoiceShipAddress3: data.invoiceShipAddress3,
                                                                                        invoiceShipCity: data.invoiceShipCity,
                                                                                        invoiceShipZip: data.invoiceShipZip,
                                                                                        resalePOPickupAddress1: data.resalePOPickupAddress1,
                                                                                        resalePOPickupCity: data.resalePOPickupCity,
                                                                                        resalePOPickupZipCode: data.resalePOPickupZipCode,
                                                                                        ResalePORecordID: data.resalePORecordID,
                                                                                        resalePOVendor: data.resalePOVendor,
                                                                                        resalePOSupplierLocation: data?.resalePOSupplierLocation,
                                                                                        resalePOSupplierInvoice: data?.resalePOSupplierInvoice,
                                                                                        ReleaseDate: (data.resalePOReleaseDate) ? data.resalePOReleaseDate?.split('T')[0] : '',
                                                                                        resalePOTxnDate: (data.resalePOTxnDate) ? data.resalePOTxnDate?.split('T')[0] : '',
                                                                                        LastDepotCall: (data.resalePOLastDepotCallDate) ? data.resalePOLastDepotCallDate?.split('T')[0] : '',
                                                                                        invoiceVendorNotes: data?.invoiceVendorNotes,
                                                                                        ResaleTotal: data.resalePOTotalPrice,
                                                                                        Expected: (data.resalePOExpectedDate) ? data.resalePOExpectedDate?.split('T')[0] : '',
                                                                                        ExpectedDeliveryDate: (data.resalePOExpectedDate) ? data.resalePOExpectedDate?.split('T')[0] : '',
                                                                                        invoiceShipMethodMethodFieldName: data?.invoiceShipMethodMethodFieldName,
                                                                                        transportPOVendor: data.transportPOVendor,
                                                                                        solidSurface: (data.invoice?.solidSurface) ? data.invoice.solidSurface : 0,
                                                                                        towTruck: (data.invoice?.towTruck) ? data.invoice.towTruck : 0,
                                                                                        shipCity: (data.invoiceShipCity) ? data.invoiceShipCity : '',
                                                                                        shipState: (data.invoiceShipState) ? data.invoiceShipState : '',
                                                                                        sameTrailerMarker: data.sameTrailerMarker,

                                                                                        invoiceLineItem_RecordID: data.invoiceLineItem_RecordID,

                                                                                        transportPOLineItemContainerNumber: data.transportPOLineItemContainerNumber,
                                                                                        transportPODeliveredDate: (data.transportPODeliveredDate) ? data.transportPODeliveredDate?.split('T')[0] : '',
                                                                                        planningId: (data.planning) ? data.planning.id : null,
                                                                                        ScheduledFor: (data.transportPOScheduledDeliveryDate) ? data.transportPOScheduledDeliveryDate?.split('T')[0] : '',

                                                                                        invoiceShipAddress1: data.invoiceShipAddress1,
                                                                                        invoiceShipAddress2: data.invoiceShipAddress2,
                                                                                        invoiceShipAddress3: data.invoiceShipAddress3,
                                                                                        invoiceShipCity: data.invoiceShipCity,
                                                                                        invoiceShipZip: data.invoiceShipZip,
                                                                                        resalePOPickupAddress1: data.resalePOPickupAddress1,
                                                                                        resalePOPickupCity: data.resalePOPickupCity,
                                                                                        resalePOPickupZipCode: data.resalePOPickupZipCode,

                                                                                        TransportPO: (data.transportPORecordID) ? data.transportPORefNumer : data.invoiceRefNumber + 'T',
                                                                                        transportPORefNumer: (data.transportPORecordID) ? data.transportPORefNumer : data.invoiceRefNumber + 'T',
                                                                                        TransportPORecordID: data.transportPORecordID,
                                                                                        DeliveryStatus: data.transportPODeliveryStatus,
                                                                                        PromisedTimeline: (data.invoicePromisedTimeline) ? data.invoicePromisedTimeline : '',
                                                                                        PromisedDate: (data.invoicePromisedDate) ? data.invoicePromisedDate?.split('T')[0] : '',
                                                                                    })),
                                                                                    page: result.data.currentPage - 1,
                                                                                    totalCount: result.data.totalItems,
                                                                                });
                                                                                setPageRefresh(false);
                                                                            })
                                                                        }
                                                                    })
                                                                }
                                                                options={{
                                                                    thirdSortClick: false,

                                                                    // rowStyle: rowData => ({
                                                                    //     backgroundColor: (rowData.invoiceTerms == "Rent To Own" && rowData.solidSurface == 1 ) ? '#90EE90' : (rowData.invoiceTerms == "Rent To Own") ? '#ffda73' : (rowData.solidSurface == 1) ? '#7bede9' : '#fff'
                                                                    // }),
                                                                    rowStyle: rowData => ({
                                                                        backgroundColor: (rowData.invoiceTerms == "Rent To Own" && rowData.towTruck == 1) ? '#F7AE77' : (rowData.invoiceTerms == "Rent To Own" && rowData.solidSurface == 1) ? '#90EE90' : (rowData.towTruck == 1 && rowData.solidSurface == 1) ? '#F0BBF0' : (rowData.invoiceTerms == "Rent To Own") ? '#ffda73' : (rowData.solidSurface == 1) ? '#7bede9' : (rowData.towTruck == 1) ? 'pink' : '#fff'
                                                                    }),


                                                                    debounceInterval: 1500,
                                                                    pageSize: 50,
                                                                    pageSizeOptions: [50, 100, 200],
                                                                    filtering: true
                                                                }}

                                                                components={{
                                                                    Toolbar: props => (
                                                                        <ResetFillterButton elsement={props} resetFilter={resetFilter} />
                                                                    ),
                                                                }}
                                                            />
                                                        </div>

                                                    </div>
                                                </div>

                                            </div>

                                            <Footer />

                                            <style jsx="true">{`
                                            
                                                .title-strong {
                                                    font-weight: 550;
                                                }

                                                .btn-set{
                                                    position: absolute;
                                                    top: 18px;
                                                    font-size: 17px;
                                                    right: 255px;
                                                    color: #000;
                                                    padding: 4px 6px;
                                                    border: 1px solid #79a3ff;
                                                }

                                                .input-wid-set {
                                                    border: none;
                                                    border-bottom: 1px solid #949494;
                                                    position: relative;
                                                    top: 3px;
                                                    width: 100%;
                                                }
                                                
                                                .MuiTableCell-alignLeft {
                                                    z-index:unset !important;
                                                    
                                                }
                                                .MuiTableCell-root{
                                                    padding: 2px !important;
                                                    font-family: 'Source Sans Pro', sans-serif;
                                                }

                                                .MuiTableCell-root a {
                                                    font-size:12px !important;
                                                    font-family: 'Source Sans Pro', sans-serif;
                                                    overflow: visible;
                                                    display: block;
                                                    text-overflow: clip;
                                                    word-break: break-word;
                                                    white-space: break-spaces!important;
                                                
                                                }
                                                tr td:first-child.MuiTableCell-root a, tr th:first-child span.MuiButtonBase-root.MuiTableSortLabel-root {
                                                    width: 100%!important;
                                                }

                                                .MuiTableHead-root th {
                                                    font-size: 12px !important;
                                                    font-weight: 600;
                                                    background: #fbf5f5;
                                                }

                                                .MuiTableCell-alignLeft a {
                                                    // white-space: pre !important;
                                                    white-space: pre-line !important;
                                                }
                                                // .MuiTableHead-root th:nth-child(8) {
                                                //     width: 120px!important;
                                                // }
                                                .MuiTableCell-root a {
                                                    width: 45px;
                                                }

                                                th.MuiTableCell-root.MuiTableCell-head.jss13.MuiTableCell-alignLeft:nth-child(2) {
                                                    width: 70px!important;
                                                }
                                                
                                                th.MuiTableCell-root.MuiTableCell-head.jss13.MuiTableCell-alignLeft:nth-child(3) {
                                                    width: 70px!important;
                                                }
                                                
                                                .MuiTableCell-root.MuiTableCell-head.MTableHeader-header-13.MuiTableCell-alignLeft:nth-child(6) {
                                                    width: 174px!important;
                                                }
                                                th.MuiTableCell-root.MuiTableCell-head.jss13.MuiTableCell-alignLeft:nth-child(7) {
                                                    width: 65px!important;
                                                }
                                                th.MuiTableCell-root.MuiTableCell-head.jss13.MuiTableCell-alignLeft:nth-child(15) {
                                                    width: 80px!important;
                                                }
                                                th {
                                                    text-align: inherit;
                                                    white-space: nowrap;
                                                }
                                                td.MuiTableCell-root.MuiTableCell-body.MuiTableCell-alignLeft a {
                                                    width:100%;
                                                }
                                                @media (max-width: 1920px){
                                            span.MuiButtonBase-root.MuiTableSortLabel-root {
                                                width: 100%;
                                            }
                                        }
                                            `}</style>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withRouter(Delivered);