import React, { useState, useEffect } from "react";
import axios from "axios";
import { Route, Link } from "react-router-dom";
import Header from "../../components/Header/Header";
import { API_BASE_URL, ACCESS_TOKEN_NAME } from "../../constants/apiConstants";
import "./AddUser.css";
import { ToastContainer, toast } from 'react-toastify';

const AddUser = () => {
  const [users, setUsers] = useState();

  useEffect(() => {
    loadUser();
  }, []);

  const loadUser = async () => {
    console.log(localStorage.getItem('access token name',ACCESS_TOKEN_NAME));
    const token = localStorage.getItem(ACCESS_TOKEN_NAME);
    const result = await axios.get(API_BASE_URL + "/findAllUser", {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    setUsers(result.data);
  };

  const sendEmail = (email) => {
    axios.get(API_BASE_URL + `/sendSetPasswordEmail?email=${email}`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN_NAME),
      },
    }).then((res) => {
      setTimeout(() => {
        toast.error('Email has been sent', {
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true
        });
      });
    })
  };
  
  const setUsersActive = (id) => {
    axios.get(API_BASE_URL + `/setUserActive?id=${id}`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN_NAME),
      },
    }).then((result) => {
      setTimeout(() => {
        toast.error('User has been activated', {
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true
        });
      });
      loadUser();
    });
  };

  const setUsersInActive = (id) => {
    axios.get(API_BASE_URL + `/setUserInActive?id=${id}`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN_NAME),
      },
    }).then((result) => {
      setTimeout(() => {
        toast.error('User has been Inactivated', {
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true
        });
      });
      loadUser();
    });
  };

  const handleCheck = async(e) => {
    console.log('checked',e);
    let isChecked = e.target.checked;
    if(isChecked){
      const token = localStorage.getItem(ACCESS_TOKEN_NAME);
      const result = await axios.get(API_BASE_URL + "/findAllInActiveUser", {
        headers: {
          Authorization: "Bearer " + token,
        },
      });
      setUsers(result.data);
    }else{
      loadUser();
    }
  }

  return (
    <>
      <div style={{ width: "540px", height: "50px", marginTop: "20px", position: "absolute"}}>
        <Link to="/home/addUser">Add User</Link>
      </div>
      <div>
      <label className="switch" style={{marginTop: "10px"}}>
        <input type="checkbox" id="togBtn" onChange={e=>handleCheck(e)} />
        <div className="slider round">
          <span className="on">Deactivate</span>
          <span className="off">Activate</span>
        </div>
        </label>
      </div>
      <div className="container">
        <div>
          <table className="table border shadow">
            <thead className="thead-dark">
              <tr>
                <th scope="col">Name</th>
                <th scope="col">Email</th>
                <th scope="col">Office Name</th>
                <th scope="col">Supplier</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {users &&
                users.map((user, index) => (
                  <tr key={user.id}>
                    <td>{user.name}</td>
                    <td>{user.email}</td>
                    <td>{user.officeLocation.officeName}</td>
                    <td>
                    {user.userSupplierLocations && user.userSupplierLocations.map((data, index) => (
                      <p>{data.supplierLocation.SupplierLocation}</p>
                    ))}
                    </td>
                    
                    
                    <td colSpan="3">
                      <Link
                        className="btn btn-outline-primary mr-2 btn-sm"
                        to={`/users/edit/${user.id}`}
                      >
                        Edit
                      </Link>
                      {user.isPasswordSet !== false ? (
                        <div></div>
                      ) : (
                        <div
                        className="btn btn-outline-primary btn-sm mr-2"
                        onClick={() => sendEmail(user.email)}
                      >
                        Send Email
                      </div>
                      )}

                      {user.isActive !== false ? (
                        <div className="btn btn-outline-success btn-sm mr-2" onClick={() => setUsersInActive(user.id)}>InActive</div>
                      ): (
                        <div className="btn btn-outline-danger btn-sm mr-2" onClick={() => setUsersActive(user.id)} >Activate</div>
                      )}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};
export default AddUser;

