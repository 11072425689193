import { API_BASE_URL , ACCESS_TOKEN_NAME } from '../constants/apiConstants';
import axios from 'axios'

export default class PlanningServices {
  static async updatePickupLocationSync() {
    const token = localStorage.getItem(ACCESS_TOKEN_NAME);
    const response = await axios.post(API_BASE_URL + `/updatePickupLocationSync`, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    return response.data;
  }

  static async updateSupplierLocationSync() {
    const token = localStorage.getItem(ACCESS_TOKEN_NAME);
    const response = await axios.post(API_BASE_URL + `/updateSupplierLocationSync`, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    return response.data;
  }

  static async updateVendorIntervalSync() {
    const token = localStorage.getItem(ACCESS_TOKEN_NAME);
    const response = await axios.post(API_BASE_URL + `/updateVendorIntervalSync`, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    return response.data;
  }

  static async updateContactIntervalSync() {
    const token = localStorage.getItem(ACCESS_TOKEN_NAME);
    const response = await axios.post(API_BASE_URL + `/updateContactIntervalSync`, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    return response.data;
  }

  static async updateFuelSurchargeSync() {
    const token = localStorage.getItem(ACCESS_TOKEN_NAME);
    const response = await axios.post(API_BASE_URL + `/updateFuelSurchargeSync`, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    return response.data;
  }

  static async WelcomeCallSync() {
    const token = localStorage.getItem(ACCESS_TOKEN_NAME);
    const response = await axios.post(API_BASE_URL + `/WelcomeCallSync`, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    return response.data;
  }

  static async DispatchAppSync() {
    const token = localStorage.getItem(ACCESS_TOKEN_NAME);
    const response =  await axios.get(API_BASE_URL + `/manuallyRunDispatchedSync`, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    return response.data;
  }

  static async WelcomeCallAppSync() {
    const token = localStorage.getItem(ACCESS_TOKEN_NAME);
    const response = await axios.get(API_BASE_URL + `/WelcomeCallAppSync`, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    return response.data
  }

  static async fmcsSyncConditinalSync() {
    const token = localStorage.getItem(ACCESS_TOKEN_NAME);
    const response = await axios.post(API_BASE_URL + `/fmcsSyncConditinalSync`, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    return response.data;
  }

  static async updateManuallyRecordId(invoiceNumber) {
    const token = localStorage.getItem(ACCESS_TOKEN_NAME);
    const response = await axios.get(API_BASE_URL + `/updateManuallyRecordId?id=${invoiceNumber}`, {
        headers: {

        },
    });
    return response.data;
  }


}





