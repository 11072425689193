const RemoveAddress = (string,state) => {
    try {
      var hasNumber = /\d/;   
      
      if (string && string != '') {
        let firstChar = string.replace(/^\s+|\s+$/gm, '').charAt(0);
        if((state == 'Wisconsin' || state == 'WI') && hasNumber.test(string))
        {
          return `${string}, `;
        }
        else if (firstChar <= '9' && firstChar >= '0') {
          return `${string}, `;
        }
      }
      return '';
    } catch (err) {
      console.log(err);
      return;
    }
}

export default RemoveAddress;