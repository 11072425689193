import React, { Component } from 'react';
import Sidebar from "./../Layout/Sidebar";
import { withRouter, Link } from 'react-router-dom';
import logo from '../../images/logo.png';
import profilepic from '../../images/avatar-4.jpg';
import { ACCESS_TOKEN_NAME, ACCESS_TOKEN_NAME_USER, API_BASE_URL } from '../../constants/apiConstants';
import DeliveredServices from './DeliveredServices';
import $ from 'jquery';
import './delivered.css'
import moment from 'moment';
import Avatar from 'react-avatar';
import DispachedServices from '../DispachedPurchaseOrder/DispachedServices';

class DeliveredLayout extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loding: false,
            location:[],
            data: [],
            errorinfo: null,
            display:'none'
        }
    }

    async storeLocation(){
        try{
           const result = await DispachedServices.fetchLocation();
           this.setState({location:result});
        } catch(error) {
           console.log(error,'storeLocation');
        }
    }

    handelPorfilebox = () => {
        this.setState((old)=>{
            return {
                ...old,
                display: old.display == 'none' ?  'block' : 'none'
            }
        })
    }

    handleLogout = () => {
        localStorage.removeItem(ACCESS_TOKEN_NAME);
        localStorage.removeItem('role');
        localStorage.removeItem('user');
        localStorage.removeItem(ACCESS_TOKEN_NAME_USER);
        this.props.history.push("/");
    }

    handleLocationChange = async (event) => {
        $('#delivered').DataTable().destroy(); 
        this.setState({
            loding: true
        })
        if (event.target.value == 0) {
            this.fetchData();
        } else {
            const result = await DeliveredServices.fetchDeliveredbyLocation(event.target.value);
            this.setState({loding: false, data: result});
            $('#delivered').DataTable();
        }
    }

    fetchData = async () => {
        try {
            this.setState({
                loding: true
            })
            const result = await DeliveredServices.fetchDelivered();
            this.setState({
                loding: false,
                data: result
            })
            $('#delivered').DataTable();
        } catch (error) {
            this.setState({
                loding: false,
                errorinfo: error,
                data: []
            })
        }
    }

    componentDidMount() {
        this.fetchData();
        this.storeLocation();
    }

    render() {

        return (
            <div id="pcoded" className="pcoded">
                <div className="pcoded-overlay-box"></div>
                <div className="pcoded-container navbar-wrapper">
                    <nav className="navbar header-navbar pcoded-header" style={{ height: '55px', minHeight: '26px' }}>
                        <div className="navbar-wrapper">
                            <div className="navbar-logo" style={{ height: '52px' }}>
                                <a style={{ top: '7px' }} className="mobile-menu navabar_menu" id="mobile-collapse" href="#!">
                                    <i class="ti-menu" style={{ "color": " #000", "font-size": "22px;" }}></i>
                                </a>
                                <a className="mobile-search morphsearch-search" href="#">
                                    <i className="ti-search"></i>
                                </a>
                                <Link to="/dashboard">
                                    <img style={{ width: '30%' }} className="img-fluid logo-img" src={logo} alt="Theme-Logo" />
                                </Link>
                                <a className="mobile-options">
                                    <i className="ti-more"></i>
                                </a>
                            </div>

                            <div className="navbar-container container-fluid">
                                <ul className="nav-left" style={{ margin: "1% 0% 0% 8%" }}>
                                    <h4>Completed</h4>
                                </ul>
                                <ul className="nav-right">
                                    <li className="user-profile header-notification" style={{ lineHeight: 3.5 }}>
                                        <a href="javascript:void(0)" onClick={this.handelPorfilebox}>
                                        <Avatar name={localStorage.getItem('user')} colors={['#ed3028', '#711999', '#383838']} size="40" round="20px" />
                                        </a>
                                        <ul className="show-notification profile-notification" style={{display: this.state.display}}>
                                            <li>
                                                <a href="#">
                                                    <i className="ti-user"></i> Profile
                                                </a>
                                            </li>
                                            <li>
                                                <Link to="/resetpassword">
                                                    <i className="ti-layout-sidebar-left"></i> Change Password
                                                </Link>
                                            </li>
                                            <li>
                                                <a onClick={this.handleLogout}>
                                                    <i className="ti-layout-sidebar-left"></i> Logout
                                                </a>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                            
                        </div>
                    </nav>

                    <div className="pcoded-main-container">
                        <div className="pcoded-wrapper">
                            <Sidebar />
                            <div className="pcoded-content page_content">
                                <div className="pcoded-inner-content">
                                    <div className="main-body">
                                        <div className="page-wrapper">
                                            <div class="col-md-3">
                                                <div class="form-group d-flex  text-left" style={{"align-items":"baseline"}}>
                                                    <label><b style={{"margin":"5px"}}>Location</b></label>&nbsp;&nbsp;
                                                    <select class="form-control" onChange={this.handleLocationChange} >
                                                        <option id="0" value="0">All Orders</option>
                                                            {
                                                                this.state.location.map((item)=><option value={item.supplierloctionname}>{item.supplierloctionname}</option>)
                                                            }
                                                    </select>
                                                </div>
                                            </div>                                  
                                            <div className="page-body">
                                                <div className="col-md-12">


                                                    {
                                                        (this.state.loding) ?
                                                            <i class="fa fa-spinner fa-spin center-position" />
                                                            :
                                                            <div className='table-responsive'>
                                                                <table id="delivered" className="table-hover table-bordered">
                                                                    {/* <table id="delivered" className="table table-hover table-bordered"> */}
                                                                    <thead>
                                                                        <tr>
                                                                            <th>Customer</th>
                                                                            <th>Supplier Location</th>
                                                                            <th>Invoice Number</th>
                                                                            <th>Invoice Date</th>
                                                                            <th>Delivered Date</th>
                                                                            <th>Product</th>
                                                                            <th>Shipping City</th>
                                                                            <th>Shipping State</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {
                                                                            this.state.data.map((item) =>
                                                                                <>
                                                                                    <tr>
                                                                                        <td>{item.invoiceCustomerFullName}</td>
                                                                                        <td>{item.invoiceSupplierLocation}</td>
                                                                                        <td>{item.invoiceRefNumber}</td>
                                                                                        <td>{(item.invoiceTxnDate) ? moment(item.invoiceTxnDate.split("T")[0]).format('MM/DD/YYYY') : ''}</td>
                                                                                        <td>{(item.transportPODeliveredDate) ? moment(item.transportPODeliveredDate.split("T")[0]).format('MM/DD/YYYY') : ''}</td>
                                                                                        <td>{item.invoiceLineItemItemNameWithoutParent}</td>
                                                                                        <td>{item.invoiceShipCity}</td>
                                                                                        <td>{item.invoiceShipState}</td>
                                                                                    </tr>
                                                                                </>
                                                                            )
                                                                        }
                                                                    </tbody>
                                                                </table>
                                                            </div>

                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <style jsx="true">{`
                                              
                                           
                                           table.dataTable thead th, table.dataTable thead, th, td{
                                                  font-family: 'Source Sans Pro', sans-serif;
                                                }
                                                table.dataTable thead th, table.dataTable thead, td {
                                                    font-size:12px !important;
                                                }

                                                table.dataTable thead th, table.dataTable thead th {
                                                    font-size: 12px !important;
                                                    font-weight: 600;
                                                    background: #fbf5f5;
                                                }
                                                
                                            `}</style>   
            </div>
        );
    }
}


export default DeliveredLayout;