import React, { Component } from 'react';
import './Style/index.css';
import '../Dispached/CustomStyle.css';
import { Formik } from 'formik';
import model from './InvoiceModel';
import FormSchema from '../Shared/form/formSchema';
import Input from '../Shared/form/Input';
import Date from '../Shared/form/Date';
import Checkbox from '../Shared/form/Checkbox';
import moment from 'moment-timezone';
import swal from "sweetalert";
import CheckboxInvoice from '../Shared/form/CheckboxInvoice';
import CustomerCallCompleted from '../Shared/form/CustomerCallCompleted';
import PromiseTimeLine from '../Shared/form/PromiseTimeLine';
import { promiseTimeLines, shipedMehtod, containerOrientation } from '../PurchaseOrder/Option/AllOption';

import PurchaseFormServices from '../PurchaseOrder/Services/PurchaseFormServices'
import InvoiceServices from '../Invoice/Services/InvoiceServices';
import PlanningServices from '../DriverPlanning/PlanningServices';
import { withRouter, Redirect } from "react-router";
import Textarea from '../Shared/form/Textarea';
import InvoiceTable from './InvoiceTable';
import Select from '../Shared/form/Select';
//import ManualDispatch from './ManualDispatch';
import jstz from 'jstz';
import DatePicker from "react-datepicker";
import Dropzone from '../Shared/form/Dropzone';
import he from 'he';
import { ToastContainer, toast } from 'react-toastify';
import SolidSurface from '../Shared/form/SolidSurface';
import TowTruck from '../Shared/form/TowTruck';
import SameTrailerDiscount from '../Shared/form/SameTrailerDiscount';

const { fields } = model;
class InvoiceForm extends Component {
    schema = new FormSchema(fields.id, [
        fields.customer,
        fields.invoice,
        fields.wooCommerce,
        fields.assignedto,
        fields.billemail,
        fields.date,
        fields.due,
        fields.terms,
        fields.onhold,
        fields.supplier_location,
        fields.container_orientation,
        fields.ship_method,
        fields.promiseDate,
        fields.promiseTimeLine,
        fields.onhold,
        fields.expedited,
        fields.order_cancelled,
        fields.order_refunded,
        fields.customer_completed,
        fields.solid_surface,
        fields.towTruck,
        fields.accessories_approved,
        fields.approved_accounts,
        fields.IsSameTrailerDiscount,
        fields.emailed,
        fields.billline1,
        fields.billline2,
        fields.billline3,
        fields.billline4,
        fields.billline5,
        fields.billcity,
        fields.billstate,
        fields.billzip,
        fields.billcountry,
        fields.shipline1,
        fields.shipline2,
        fields.shipline3,
        fields.shipline4,
        fields.shipline5,
        fields.shipcity,
        fields.shipstate,
        fields.shipzip,
        fields.shipcountry,
        fields.customerALtPhone,
        fields.customerPhone,
        fields.memo
    ]);

    constructor(props) {
        super(props);
        this.state = {
            initLoading: false,
            syncLoading: false,
            initSupplierLoading: false,
            initUpdating: false,
            invoiceData: {},
            billingAddress: {},
            setdisptachedOrder: [],
            attachment: [],
            shippingAddress: {},
            billingvisibility: false,
            shippingvisibility: false,
            customerNoteCollection: "",
            vendorNoteCollection: "",
            customerCallCompleted: false,
            sameTrailerDiscount: false,
            solidSurface: false,
            towTruck: false,
            dispatchedNoteCollection: "",
            purchasingNoteCollection: "",
            supplierLocation: [],
            recordIDInvoice: null,
            ShippingError: false,
            ShippinfErrorMessage: 'Invalid Address',
            ShippingCityError: false,
            ShippingStateError: false,
            ShippingPostalError: false,
            ShippingCountryError: false,
            ShipWarning: false,
            checkTPOCount: 0,
            loader: { customerNote: false, vendorNote: false, purchasingNote:false, dispatchNote:false}
        }
    }

    checkedInvoiceRecordID = async (id) => {
        this.setState({
            recordIDInvoice: id
        });
        console.log("IDdddddddddrecorddsafs", id);

    }
    handleChangeValue = async () => {
        console.log('okkk')
    }

    handleCheck = async (e) => {

        if (e.target.name === 'shipcity' && e.target.value.trim()) {
            this.setState({
                ShippingCityError: false
            });
        }
        if (e.target.name === 'shipstate' && e.target.value.trim()) {
            this.setState({
                ShippingStateError: false
            });
        }
        if (e.target.name === 'shipzip' && e.target.value.trim()) {
            this.setState({
                ShippingPostalError: false
            });
        }
        if (e.target.name === 'shipcountry' && e.target.value.trim()) {
            this.setState({
                ShippingCountryError: false
            });
        }
    }

    validateShipAddress = async (shipAddressAddr1, shipAddressAddr2, shipAddressAddr3) => {
        try {
            var hasNumber = /\d/;
            if (hasNumber.test(shipAddressAddr1)) {
                console.log("Number exist")
                if (isNaN(shipAddressAddr1.charAt(0))) {
                    console.log("hey")

                    this.setState({
                        ShipWarning: true
                    })

                }
                else {

                    this.setState({
                        ShipWarning: false
                    })
                }
            }
            else if (hasNumber.test(shipAddressAddr2)) {
                console.log("Number exist")
                if (isNaN(shipAddressAddr2.charAt(0))) {
                    this.setState({
                        ShipWarning: true
                    })

                }
                else {

                    this.setState({
                        ShipWarning: false
                    })
                }
            }
            else if (hasNumber.test(shipAddressAddr3)) {
                console.log("Number exist")
                if (isNaN(shipAddressAddr3.charAt(0))) {
                    this.setState({
                        ShipWarning: true
                    })

                }
                else {

                    this.setState({
                        ShipWarning: false
                    })
                }
            }
            else {

                this.setState({
                    ShipWarning: false
                })
            }
            return true
            // else if(hasNumber.test(shipAddressAddr4)){
            //     console.log("Number exist")
            //     if (isNaN(shipAddressAddr4.charAt(0)))
            //     {
            //         this.setState({
            //             ShipWarning: true 
            //         })
            //         return true;
            //     }
            // }
            // else if(hasNumber.test(shipAddressAddr5)){
            //     console.log("Number exist")
            //     if (isNaN(shipAddressAddr5.charAt(0)))
            //     {
            //         this.setState({
            //             ShipWarning: true 
            //         })
            //         return true;
            //     }
            // }
        }
        catch (error) {
            console.log(error, 'validationError');
        }
        return false;

    }


    fetchDispatchOrder = async () => {
        try {
            const result = await PlanningServices.findOrderIdDetails();
            this.setState((state) => {
                return {
                    ...state,
                    setdisptachedOrder: result
                }
            });

        } catch (error) {
            console.log(error, 'fetchDispatchOrder');
        }
    }

    // Validate Address Check

    validateAddress = async (shipAddressAddr1, shipAddressAddr2, shipAddressAddr3, shipAddressCity, shipAddressState, shipAddressPostalCode, shipAddressCountry) => {
        const results = await PlanningServices.validateGoogleAddress(shipAddressAddr1, shipAddressAddr2, shipAddressAddr3, shipAddressCity, shipAddressState, shipAddressPostalCode, shipAddressCountry);
        console.log("lssllslslslsl", results);
        if (results.status === 403) {
            this.setState({
                ShippinfErrorMessage: 'Verification Failed',
                ShippingError: true
            })
        }
    }

    manualSync = async () => {
        const { match } = this.props;
        try {
            this.setState((state) => {
                return {
                    ...state,
                    syncLoading: true
                }
            });
            await InvoiceServices.syncUpdate(this.state.invoiceData.recordID, match.params.id);
            await this.fetchInvoiceOrder();
            this.setState((state) => {
                return {
                    ...state,
                    syncLoading: false
                }
            });
        } catch (error) {
            this.setState((state) => {
                return {
                    ...state,
                    syncLoading: false
                }
            });
            console.log(error.message, 'manualSync');
        }
    }

    redirectToDispatch = async (invoiceRefnumber, recordID, depotCall) => {
        if (depotCall == 1) {
            // const countTPO = await InvoiceServices.checkTPO(this.state.invoiceData.refNumber ? this.state.invoiceData.refNumber : '')
            if (this.state.checkTPOCount == 0) {
                setTimeout(() => {
                    toast.warning(`All container already dispatched`, {
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true
                    });
                });
                return
            }


            if (recordID) {
                const response = await InvoiceServices.getPlanningInfo(recordID);
                if (response[0] && response[0].id) {
                    window.open("/purchaseorder/" + response[0]?.id)
                } else {
                    const today = new Date();
                    var days = ['Sun', 'Mon', 'Tus', 'Wed', 'Thu', 'Fri', 'Sat'];
                    var months = new Array("Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sep", "Oct", "Nov", "Dec");
                    var orders = this.state.setdisptachedOrder.filter((item) => item.invoiceRefNumber == invoiceRefnumber && item.invoicespo_record_id == recordID);

                    const payload = {
                        contactId: 99434,
                        locationId: 0,
                        customerConfirmation: 0,
                        driveConfirmation: 0,
                        size: (orders[0]) ? orders[0].invoiceLineItemItemName : '',
                        invoiceId: recordID,
                        City: (orders[0]) ? orders[0].City : '',
                        drivername: 'DispatchFromInvoie',
                        product: (orders[0]) ? orders[0].invoiceLineItemItemNameWithoutParent : '',
                        State: (orders[0]) ? orders[0].State : '',
                        DropState: (orders[0]) ? orders[0].DropState : '',
                        DropCity: (orders[0]) ? orders[0].DropCity : '',
                        Dist: (orders[0]) ? orders[0].Dist : '',
                        plannedStatus: 0,
                        resalePORefNumer: (orders[0]) ? orders[0].resalePORefNumer : '',
                        isExpedited: (orders[0]) ? orders[0].invoiceIsExpedited : '',
                        date: ''
                    };
                    const resultinfo = await PlanningServices.savePlanning(payload);
                    console.log('sssssssss', resultinfo)
                    window.open("/purchaseorder/" + resultinfo[0]?.lastinsterid);
                }
            }

            else {
                setTimeout(() => {
                    toast.error(`Please select atleast one container to dispatch`, {
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true
                    });
                });
            }
        }
        else {
            setTimeout(() => {
                toast.error(`Depot Call is not Completed`, {
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });
            });
        }

    }


    pushAttachementFile = async (location, token, filename) => {
        this.setState((state) => {
            return {
                ...state,
                attachment: [...state.attachment, { location, token, filename }]
            }
        });
    }

    fetchAttachment = async (recordID) => {
        const resultinfo = await PurchaseFormServices.fetchAttachment({ tableName: "invoices", recordID: this.state.invoiceData.recordID });
        this.setState({
            attachment: resultinfo
        });
    }


    deleteAttachementFile = async (e) => {
        var tokeninfo = e.target.attributes.valueinfo.value;
        swal({
            text: "Deleting this file attachment will permanently remove it from logistic app",
            icon: "warning",
            dangerMode: true,
            buttons: true,
        }).then(async (status) => {
            if (status) {
                await PurchaseFormServices.deleteAttachment({ token: tokeninfo, tableName: "invoices", recordID: this.state.invoiceData.recordID });
                this.fetchAttachment(this.state.invoiceData.recordID);
            }
        });
    }


    billingvisibilityControl = () => {
        this.setState({
            billingvisibility: true
        })
    }

    handleChange = (e) => {
        console.log('ggggggg', e.target.name, e.target.value)
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    customerNote = async () => {

       
        if (this.state.customerNote == null || this.state.loader.customerNote) {
            return false;
        }
        console.log('customer---')

         // set state for loader
        await this.setState(state => {
            return {
                ...state,
                loader: { ...state.loader, customerNote: true }
            }
        });

     
        const { match } = this.props;
        const timezone = jstz.determine();
       
        await this.getAllInvoiceNotes();

        await this.setState(state => {
            const { customerNote, customerNoteCollection } = state;
            return {
                ...state,
                customerNote: null,
                customerNoteCollection: customerNote + ' - ' + localStorage.getItem('user') + ' ' + '(' + moment(moment().toDate()).tz(timezone.name()).format('MM/DD/YYYY  hh:mm A') + ')\n' + customerNoteCollection
            }
        });


        await InvoiceServices.updateCustomerNote({ resaleporecordID: match.params.id, category: 'invoice', type: 'customernote', customer_note: this.state.customerNoteCollection, url: window.location.href }, this.state.invoiceData.recordID);
        document.getElementById('customerinput').value =null;
        await this.setState(state => {
            return {
                ...state,
                loader: { ...state.loader, customerNote: false }
            }
        });
        setTimeout(() => {
            toast.success('Customer note updated successfully', {
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true
            });
        });
    }



    vendorNote = async () => {

        if (this.state.vendorNote == null||this.state.loader.vendorNote) {
            return false;
        }

        // set state for loader
        await this.setState(state => {
            return {
                ...state,
                loader: { ...state.loader, vendorNote: true }
            }
        });

        const { match } = this.props;
        const timezone = jstz.determine();
       
        await this.getAllInvoiceNotes();

        await this.setState(state => {
            const { vendorNote, vendorNoteCollection } = state;
            return {
                ...state,
                vendorNote: null,
                vendorNoteCollection: vendorNote + ' - ' + localStorage.getItem('user') + ' ' + '(' + moment(moment().toDate()).tz(timezone.name()).format('MM/DD/YYYY  hh:mm A') + ')\n' + vendorNoteCollection
            }
        });

        console.log("ttttt");

        await InvoiceServices.updateVendorNote({ resaleporecordID: match.params.id, category: 'invoice', type: 'vendornote', vendor_note: this.state.vendorNoteCollection }, this.state.invoiceData.recordID);
        document.getElementById('vendorNote').value = null
        await this.setState(state => {
            return {
                ...state,
                loader: { ...state.loader, vendorNote: false }
            }

        });

        setTimeout(() => {
            toast.success('Vendor note updated successfully', {
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true
            });
        });
    }

    purchasingNote = async () => {

        if (this.state.purchasingNote == null||this.state.loader.purchasingNote) {
            return false;
        }

        // set state for loader
        await this.setState(state => {
            return {
                ...state,
                loader: { ...state.loader, purchasingNote: true }
            }
        });


        // console.log('this.state.purchasingNote', this.state.purchasingNote)
        const { match } = this.props;
        const timezone = jstz.determine();
        
        await this.getAllInvoiceNotes();

        await this.setState(state => {
            const { purchasingNote, purchasingNoteCollection } = state;
            return {
                ...state,
                purchasingNote: null,
                purchasingNoteCollection: purchasingNote + ' - ' + localStorage.getItem('user') + ' ' + '(' + moment(moment().toDate()).tz(timezone.name()).format('MM/DD/YYYY  hh:mm A') + ')' + (purchasingNoteCollection ? '\n' + purchasingNoteCollection : '')
            }
        });

        console.log('sssssssss', this.state.purchasingNoteCollection)

        await InvoiceServices.updatePurchasingNote({ resaleporecordID: match.params.id, category: 'invoice', type: 'purchasingnote', purchasing_note: this.state.purchasingNoteCollection }, this.state.invoiceData.recordID);
        document.getElementById('purchasingNote').value = null;
        await this.setState(state => {
            return {
                ...state,
                loader: { ...state.loader, purchasingNote: false }
            }
        });

        setTimeout(() => {
            toast.success('Purchasing note updated successfully', {
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true
            });
        });
    }

    dispatchNote = async () => {

       
        if (this.state.dispatchNote == null || this.state.loader.dispatchNote) {
            return false;
        }

         // set state for loader
        await this.setState(state => {
            return {
                ...state,
                loader: { ...state.loader, dispatchNote: true }
            }
        });

        const { match } = this.props;
        const timezone = jstz.determine();
      
        await this.getAllInvoiceNotes();
        await this.setState(state => {
            const { dispatchNote, dispatchedNoteCollection } = state;
            return {
                ...state,
                dispatchNote: null,
                dispatchedNoteCollection: dispatchNote + ' - ' + localStorage.getItem('user') + ' ' + '(' + moment(moment().toDate()).tz(timezone.name()).format('MM/DD/YYYY  hh:mm A') + ')\n' + dispatchedNoteCollection
            }
        });

        await InvoiceServices.updateDispatchNote({ resaleporecordID: match.params.id, category: 'invoice', type: 'dispatchednote', dsipatch_note: this.state.dispatchedNoteCollection }, this.state.invoiceData.recordID);
        document.getElementById('dispatchNote').value = null;
        await this.setState(state => {
            return {
                ...state,
                loader: { ...state.loader, dispatchNote: false }
            }
        });
        setTimeout(() => {
            toast.success('Dispatch note updated successfully', {
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true
            });
        });
    }

    updateBilling = async () => {

        if (
            this.state.billingAddress.billAddressAddr1 != document.getElementsByName("billline1")[0].value
            || this.state.billingAddress.billAddressAddr2 != document.getElementsByName("billline2")[0].value
            || this.state.billingAddress.billAddressAddr3 != document.getElementsByName("billline3")[0].value
            || this.state.billingAddress.billAddressAddr4 != document.getElementsByName("billline4")[0].value
            || this.state.billingAddress.billAddressAddr5 != document.getElementsByName("billline5")[0].value
            || this.state.billingAddress.billAddressCity != document.getElementsByName("billcity")[0].value
            || this.state.billingAddress.billAddressCountry != document.getElementsByName("billcountry")[0].value
            || this.state.billingAddress.billAddressPostalCode != document.getElementsByName("billzip")[0].value
            || this.state.billingAddress.billAddressState != document.getElementsByName("billstate")[0].value
        ) {
            try {
                await InvoiceServices.updateBilling({
                    billAddressAddr1: document.getElementsByName("billline1")[0].value,
                    billAddressAddr2: document.getElementsByName("billline2")[0].value,
                    billAddressAddr3: document.getElementsByName("billline3")[0].value,
                    billAddressAddr4: document.getElementsByName("billline4")[0].value,
                    billAddressAddr5: document.getElementsByName("billline5")[0].value,
                    billAddressCity: document.getElementsByName("billcity")[0].value,
                    billAddressCountry: document.getElementsByName("billcountry")[0].value,
                    billAddressPostalCode: document.getElementsByName("billzip")[0].value,
                    billAddressState: document.getElementsByName("billstate")[0].value
                }, this.props.match.params.id);
                setTimeout(() => {
                    toast.success('Billing address successfully updated', {
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true
                    });
                });
                this.setState((old) => {
                    return {
                        ...old,
                        billingAddress: {
                            billAddressAddr1: document.getElementsByName("billline1")[0].value,
                            billAddressAddr2: document.getElementsByName("billline2")[0].value,
                            billAddressAddr3: document.getElementsByName("billline3")[0].value,
                            billAddressAddr4: document.getElementsByName("billline4")[0].value,
                            billAddressAddr5: document.getElementsByName("billline5")[0].value,
                            billAddressCity: document.getElementsByName("billcity")[0].value,
                            billAddressCountry: document.getElementsByName("billcountry")[0].value,
                            billAddressPostalCode: document.getElementsByName("billzip")[0].value,
                            billAddressState: document.getElementsByName("billstate")[0].value
                        },
                        billingvisibility: false
                    }
                });
            } catch (error) {
                console.log(error.message, 'errorinfo');
            }
        } else {
            this.setState({
                billingvisibility: false
            });
        }
    }

    shippingvisibilityControl = () => {

        this.setState({
            ShippingError: false,
            shippingvisibility: true
        });
        if (!document.getElementsByName("shipcity")[0].value) {

            this.setState({
                ShippingCityError: true
            });
        }
        if (!document.getElementsByName("shipstate")[0].value) {
            this.setState({
                ShippingStateError: true
            });
        }
        if (!document.getElementsByName("shipzip")[0].value) {
            this.setState({
                ShippingPostalError: true
            })
        }
    }


    updateShipping = async () => {













        if (
            this.state.shippingAddress.shipAddressAddr1 != document.getElementsByName("shipline1")[0].value
            || this.state.shippingAddress.shipAddressAddr2 != document.getElementsByName("shipline2")[0].value
            || this.state.shippingAddress.shipAddressAddr3 != document.getElementsByName("shipline3")[0].value
            || this.state.shippingAddress.shipAddressAddr4 != document.getElementsByName("shipline4")[0].value
            || this.state.shippingAddress.shipAddressAddr5 != document.getElementsByName("shipline5")[0].value
            || this.state.shippingAddress.shipAddressCity != document.getElementsByName("shipcity")[0].value
            || this.state.shippingAddress.shipAddressCountry != document.getElementsByName("shipcountry")[0].value
            || this.state.shippingAddress.shipAddressPostalCode != document.getElementsByName("shipzip")[0].value
            || this.state.shippingAddress.shipAddressState != document.getElementsByName("shipstate")[0].value
        ) {
            try {



                // await this.validateShipAddress(document.getElementsByName("shipline1")[0].value,document.getElementsByName("shipline2")[0].value,document.getElementsByName("shipline3")[0].value)



                await InvoiceServices.updateShipping({
                    shipAddressAddr1: document.getElementsByName("shipline1")[0].value,
                    shipAddressAddr2: document.getElementsByName("shipline2")[0].value,
                    shipAddressAddr3: document.getElementsByName("shipline3")[0].value,
                    shipAddressAddr4: document.getElementsByName("shipline4")[0].value,
                    shipAddressAddr5: document.getElementsByName("shipline5")[0].value,
                    shipAddressCity: document.getElementsByName("shipcity")[0].value,
                    shipAddressCountry: document.getElementsByName("shipcountry")[0].value,
                    shipAddressPostalCode: document.getElementsByName("shipzip")[0].value,
                    shipAddressState: document.getElementsByName("shipstate")[0].value
                }, this.props.match.params.id);
                setTimeout(() => {
                    toast.success('Shipping address successfully updated', {
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true
                    });
                });
                this.setState((old) => {
                    return {
                        ...old,
                        shippingAddress: {
                            shipAddressAddr1: document.getElementsByName("shipline1")[0].value,
                            shipAddressAddr2: document.getElementsByName("shipline2")[0].value,
                            shipAddressAddr3: document.getElementsByName("shipline3")[0].value,
                            shipAddressAddr4: document.getElementsByName("shipline4")[0].value,
                            shipAddressAddr5: document.getElementsByName("shipline5")[0].value,
                            shipAddressCity: document.getElementsByName("shipcity")[0].value,
                            shipAddressCountry: document.getElementsByName("shipcountry")[0].value,
                            shipAddressPostalCode: document.getElementsByName("shipzip")[0].value,
                            shipAddressState: document.getElementsByName("shipstate")[0].value
                        },
                        shippingvisibility: false
                    }
                });
                // if(!document.getElementsByName("shipcity")[0].value || !document.getElementsByName("shipzip")[0].value || !document.getElementsByName("shipstate")[0].value)
                //     {
                //         this.setState({
                //             ShippingError: true
                //         });
                //     }
                await this.fetchInvoiceOrder();
            } catch (error) {
                console.log(error.message, 'errorinfo');
            }
        } else {
            // if(!document.getElementsByName("shipcity")[0].value || !document.getElementsByName("shipzip")[0].value || !document.getElementsByName("shipstate")[0].value)
            //             {
            //                 console.log("hhhhhhhhhhhhhhhhhhh");
            //                 await this.fetchInvoiceOrder();
            //                 this.setState({
            //                     ShippingError: true
            //                 });
            //             }
            this.setState({
                shippingvisibility: false
            });

        }

    }

    handleSubmit = async (values) => {
        await this.getAllInvoiceNotes();
        values.customer_note = this.state.customerNoteCollection;
        values.purchasing_note = this.state.purchasingNoteCollection;
        values.invoiceLineItemsLinkedToResalePOrecordID = this.props.match.params.id
        values.vendor_note = this.state.vendorNoteCollection;
        values.dsipatch_note = this.state.dispatchedNoteCollection;


        values.values = (values.promise_date) ? moment(values.promise_date).format('YYYY-MM-DD') : null;
        values.promise_date_formate = (values.promise_date) ? moment(values.promise_date).format('YYYY-MM-DD') : null;


        if (this.state.invoiceData.customerCallCompleted == '1') {
            values.customerCallCompletedBy = (this.state.invoiceData.customerCallCompleted == '1' && this.state.invoiceData.customerCallCompletedBy) ? this.state.invoiceData.customerCallCompletedBy : null;
            values.customerCallCompletedOn = (this.state.invoiceData.customerCallCompleted == '1' && this.state.invoiceData.customerCallCompletedOn) ? moment(this.state.invoiceData.customerCallCompletedOn).format('MM/DD/YYYY') : null;
            values.customerCallCompletedOnMethod = (this.state.invoiceData.customerCallCompleted == '1' && this.state.invoiceData.customerCallCompletedOn) ? this.state.invoiceData.customerCallCompletedOn : null;
        } else {
            values.customerCallCompletedBy = (values.customer_completed) ? localStorage.getItem('user') : null;
            values.customerCallCompletedOn = (values.customer_completed) ? moment(this.props.dateObject).format('MM/DD/YYYY') : null;
            //values.customerCallCompletedOnMethod = (values.customer_completed) ? moment(this.props.dateObject).format() : null;
        }
        try {
            this.setState({
                initUpdating: true
            });
            const result = await InvoiceServices.updateInvoice(values, this.props.match.params.id);
            if (result) {
                //this.fetchInvoiceOrder();
                setTimeout(() => {
                    toast.success('Invoice updated successfully', {
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true
                    });
                });
                this.setState({
                    billingvisibility: false,
                    shippingvisibility: false,
                    initUpdating: false
                });
            }
        } catch (error) {
            console.log(error, 'errorinfo to the handleSubmit method');
        }

    }

    initialValues = () => {
        return this.schema.initialValues({
            [fields.onhold.name]: (this.state.invoiceData?.isOnHold == 1) ? true : false,
            [fields.expedited.name]: (this.state.invoiceData?.isExpedited == 1) ? true : false,
            [fields.order_cancelled.name]: (this.state.invoiceData?.isCancelled == 1) ? true : false,
            [fields.order_refunded.name]: (this.state.invoiceData?.isRefunded == 1) ? true : false,
            [fields.customer_completed.name]: (this.state.invoiceData?.customerCallCompleted == 1) ? true : false,
            [fields.solid_surface.name]: (this.state.invoiceData?.solidSurface == 1) ? true : false,
            [fields.towTruck.name]: (this.state.invoiceData?.towTruck == 1) ? true : false,
            [fields.accessories_approved.name]: (this.state.invoiceData?.isApprovedAccessory == 1) ? true : false,
            [fields.approved_accounts.name]: (this.state.invoiceData?.approvedForRentToOwn == 1) ? true : false,
            [fields.IsSameTrailerDiscount.name]: (this.state.invoiceData?.IsSameTrailerDiscount == 1) ? true : false,
            [fields.customer.name]: this.state.invoiceData?.customer,
            [fields.invoice.name]: this.state.invoiceData?.refNumber,
            [fields.wooCommerce.name]: this.state.invoiceData?.wooCommerceNumber,
            [fields.assignedto.name]: this.state.invoiceData?.assignedTo,
            [fields.billemail.name]: this.state.invoiceData?.billEmail,
            [fields.supplier_location.name]: this.state.invoiceData?.supplierLocation,
            [fields.container_orientation.name]: this.state.invoiceData?.containerOrientation,
            [fields.ship_method.name]: this.state.invoiceData?.shipMethodMethodField,
            [fields.customerALtPhone.name]: this.state.invoiceData?.customerAltPhone,
            [fields.customerPhone.name]: this.state.invoiceData?.customerMainPhone,
            [fields.terms.name]: this.state.invoiceData?.terms,
            [fields.memo.name]: this.state.invoiceData?.memo,
            [fields.date.name]: (this.state.invoiceData.txnDate) ? this.state.invoiceData.txnDate.split('T')[0] : '',
            [fields.due.name]: (this.state.invoiceData.dueDate) ? this.state.invoiceData.dueDate.split('T')[0] : '',
            [fields.billline1.name]: this.state.invoiceData?.billAddressAddr1,
            [fields.billline2.name]: this.state.invoiceData?.billAddressAddr2,
            [fields.billline3.name]: this.state.invoiceData?.billAddressAddr3,
            [fields.billline4.name]: this.state.invoiceData?.billAddressAddr4,
            [fields.billline5.name]: this.state.invoiceData?.billAddressAddr5,
            [fields.billcity.name]: this.state.invoiceData?.billAddressCity,
            [fields.billstate.name]: this.state.invoiceData?.billAddressState,
            [fields.billzip.name]: this.state.invoiceData?.billAddressPostalCode,
            [fields.billcountry.name]: this.state.invoiceData?.billAddressCountry,
            [fields.shipline1.name]: this.state.invoiceData?.shipAddressAddr1,
            [fields.shipline2.name]: this.state.invoiceData?.shipAddressAddr2,
            [fields.shipline3.name]: this.state.invoiceData?.shipAddressAddr3,
            [fields.shipline4.name]: this.state.invoiceData?.shipAddressAddr4,
            [fields.shipline5.name]: this.state.invoiceData?.shipAddressAddr5,
            [fields.shipcity.name]: this.state.invoiceData?.shipAddressCity,
            [fields.shipstate.name]: this.state.invoiceData?.shipAddressState,
            [fields.shipzip.name]: this.state.invoiceData?.shipAddressPostalCode,
            [fields.shipcountry.name]: this.state.invoiceData?.shipAddressCountry,
            [fields.promiseTimeLine.name]: this.state.invoiceData?.promiseDateTimeline,
            [fields.promiseDate.name]: (this.state.invoiceData.promiseDate) ? this.state.invoiceData.promiseDate.split('T')[0] : '',
        });
    }

    fetchInvoiceOrder = async () => {
        this.setState({
            initLoading: true
        });
        try {
            const result = await InvoiceServices.getInvoiceOrder(this.props.match.params.id, this.props.match.params.invoiceLineItem_RecordID);
            if (!result.shipAddressCity || !result.shipAddressState || !result.shipAddressPostalCode) {
                this.setState({
                    ShippingError: true
                })
            }
            else {
                await this.validateAddress(result.shipAddressAddr1, result.shipAddressAddr2, result.shipAddressAddr3, result.shipAddressCity, result.shipAddressState, result.shipAddressPostalCode, result.shipAddressCountry);

            }
            await this.validateShipAddress(result.shipAddressAddr1, result.shipAddressAddr2, result.shipAddressAddr3)

            await this.setState(state => {
                const { customerNoteCollection, vendorNoteCollection, dispatchedNoteCollection, purchasingNoteCollection } = state;
                return {
                    initLoading: false,
                    invoiceData: result,
                    billingAddress: {
                        billAddressAddr1: result.billAddressAddr1,
                        billAddressAddr2: result.billAddressAddr2,
                        billAddressAddr3: result.billAddressAddr3,
                        billAddressAddr4: result.billAddressAddr4,
                        billAddressAddr5: result.billAddressAddr5,
                        billAddressCity: result.billAddressCity,
                        billAddressState: result.billAddressState,
                        billAddressPostalCode: result.billAddressPostalCode,
                        billAddressCountry: result.billAddressCountry
                    },
                    shippingAddress: {
                        shipAddressAddr1: result.shipAddressAddr1,
                        shipAddressAddr2: result.shipAddressAddr2,
                        shipAddressAddr3: result.shipAddressAddr3,
                        shipAddressAddr4: result.shipAddressAddr4,
                        shipAddressAddr5: result.shipAddressAddr5,
                        shipAddressCity: result.shipAddressCity,
                        shipAddressState: result.shipAddressState,
                        shipAddressPostalCode: result.shipAddressPostalCode,
                        shipAddressCountry: result.shipAddressCountry,
                    },
                    customerNoteCollection: result?.invoiceCustomerNotes,
                    purchasingNoteCollection: result?.purchasingNotes,
                    vendorNoteCollection: result?.vendorNotes,
                    dispatchedNoteCollection: result?.dispatchNotes,
                    resalePOIsDepotCallCompleted: result?.resalePOIsDepotCallCompleted

                }
            });

            await this.fetchTPOCreated()

            this.fetchAttachment(this.state.invoiceData.recordID);
        } catch (error) {
            console.log(error, 'error to getInvoiceOrder');
        }
    }

    fetchSupplierLocation = async () => {
        const supplierLocation = new Array();
        this.setState(state => {
            return {
                ...state,
                initSupplierLoading: true
            }
        });
        try {
            const result = await InvoiceServices.getAllSupplierLocation();
            result.forEach((item) => {
                supplierLocation.push({ value: item.SupplierLocation, label: item.SupplierLocation });
            });
            this.setState(state => {
                return {
                    ...state,
                    supplierLocation: supplierLocation,
                    initSupplierLoading: false
                }
            });
        } catch (error) {
            console.log(error, 'error to getInvoiceOrder');
        }
    }

    handleCustomerCallChange = (e) => {
        if (e.target.checked) {
            this.setState({
                customerCallCompleted: e.target.checked
            });

        } else {
            this.setState({
                customerCallCompleted: e.target.checked
            });
        }
    }

    handleSolidSurface = (e) => {
        if (e.target.checked) {
            this.setState({
                solidSurface: e.target.checked
            });
        } else {
            this.setState({
                solidSurface: e.target.checked
            })
        }
    }

    handleTowTruck = (e) => {
        if (e.target.checked) {
            this.setState({
                towTruck: e.target.checked
            });
        } else {
            this.setState({
                towTruck: e.target.checked
            })
        }
    }

    handleSameTrailerDiscount = (e) => {
        if (e.target.checked) {
            this.setState({
                sameTrailerDiscount: e.target.checked
            });

        } else {
            this.setState({
                sameTrailerDiscount: e.target.checked
            })

        }
    }

    fetchTPOCreated = async () => {
        const countTPO = await InvoiceServices.checkTPO(this.state.invoiceData.refNumber ? this.state.invoiceData.refNumber : '');

        console.log(countTPO, 'countTPO')
        if (countTPO.lengthTPO > 0) {
            this.setState({
                checkTPOCount: countTPO.lengthTPO
            })

        }


    }
    //  ShippError = async () =>
    //  {
    //     console.log("fields.shipcity",fields.shipcity);
    //     if(!fields.shipcity && !fields.shipstate && !fields.shipzip && !fields.shipcountry)
    //     {
    //         this.setState({
    //             ShippingError: true
    //         })
    //     }
    //  }

    componentDidMount() {
        this.fetchInvoiceOrder();
        this.fetchSupplierLocation();
        this.fetchDispatchOrder();
        //this.intervalId = setInterval(this.getAllInvoiceNotes, 20000);
        // this.ShippError();

    }

    componentWillUnmount() {
        clearInterval(this.intervalId);
    }

    getAllInvoiceNotes = async () => {
        //const recordID = this.state.transportPOrecordID || this.state.data.transportPORecordID
        const data = await InvoiceServices.getAllInvoiceNotes(this.props.match.params.id, this.props.match.params.invoiceLineItem_RecordID);
        console.log('check Data', data)
        //const data = await PurchaseFormServices.getAllNotes(recordID);
        const InvoiceDispatchNotes = data.dispatchNotes?.replace('null', '')?.replace('undefined', '');
        const InvoiceVendorNotes = data.vendorNotes?.replace('null', '')?.replace('undefined', '');
        const resalePOCustomerNotes = data.invoiceCustomerNotes?.replace('null', '')?.replace('undefined', '');
        if (resalePOCustomerNotes && this.state.customerNoteCollection != resalePOCustomerNotes) {
            this.setState({
                customerNoteCollection: resalePOCustomerNotes
            })
            console.log('Value Changed of Customer Notes')
        }
        if (InvoiceVendorNotes && this.state.vendorNoteCollection != InvoiceVendorNotes) {
            this.setState({
                vendorNoteCollection: InvoiceVendorNotes
            })
            console.log('Value Changed of Vendor Notes')
        }

        if (InvoiceDispatchNotes && this.state.dispatchedNoteCollection != InvoiceDispatchNotes) {
            this.setState({
                dispatchedNoteCollection: InvoiceDispatchNotes
            })
            console.log('Value Changed of Dispatch Notes')
        }
    }

    render() {
        if (this.state.initLoading || this.state.initSupplierLoading) {
            return (<i class="fa fa-spinner fa-spin center-position" />);
        }
        const timezone = jstz.determine();
        return (
            <>
                <div className="row">
                    <div className="col-6">
                        <h4><i className="fa fa-file-text icon-file-text" /> <b style={{ color: '#6e6e71' }}> Invoice : {this.state.invoiceData?.refNumber}</b></h4>
                        <ul className="list-item-purchase">
                            <li> <i className="fa fa-user" /><b>{this.state.invoiceData?.customer}</b></li>
                        </ul>
                    </div>
                    <div className="col-6 total-amount-text">
                        <span className="amount-text"><strong>{this.state.invoiceData.balanceRemaining}</strong></span> <br />
                        Balance Due

                    </div>
                    <div className="col-sm-12">
                        <div className="line-bottom" />
                    </div>
                </div>
                <ToastContainer />
                <Formik
                    initialValues={this.initialValues()}
                    onSubmit={this.handleSubmit}
                    validationSchema={this.schema.schema}
                >
                    {props => (
                        <form onSubmit={props.handleSubmit} className="purchase-order-form">
                            <div className="invoice-order-form-list">
                                <ul>
                                    <li>

                                        <Input
                                            name={fields.customer.name}
                                            label={fields.customer.label}
                                            disabled={true}
                                            fun={this.handleChangeValue}
                                        />

                                        <Input
                                            name={fields.billemail.name}
                                            label={fields.billemail.label}
                                            fun={this.handleChangeValue}
                                        />

                                        <div>
                                            <label htmlFor="ServicePurchase" />
                                            <div className="billing-input-icon">
                                                <span><strong>Billing Address</strong></span>
                                                <hr />
                                                {
                                                    (!this.state.billingvisibility) ?
                                                        <label onClick={this.billingvisibilityControl} className="edit-icon-bill">Edit</label>
                                                        :
                                                        <label onClick={this.updateBilling} className="edit-icon-bill">Update</label>

                                                }

                                            </div>
                                            <p className={(!this.state.billingvisibility) ? "show-box" : "hidden-box"}>
                                                {
                                                    (this.state.billingAddress.billAddressAddr1) ?
                                                        <> {this.state.billingAddress?.billAddressAddr1}<br /></>
                                                        :
                                                        ''
                                                }
                                                {
                                                    (this.state.billingAddress.billAddressAddr2) ?
                                                        <> {this.state.billingAddress?.billAddressAddr2}<br /></>
                                                        :
                                                        ''
                                                }
                                                {
                                                    (this.state.billingAddress.billAddressAddr3) ?
                                                        <> {this.state.billingAddress?.billAddressAddr3}<br /></>
                                                        :
                                                        ''
                                                }

                                                {
                                                    (this.state.billingAddress.billAddressAddr4) ?
                                                        <> {this.state.billingAddress?.billAddressAddr4}<br /></>
                                                        :
                                                        ''
                                                }

                                                {
                                                    (this.state.billingAddress.billAddressAddr5) ?
                                                        <> {this.state.billingAddress?.billAddressAddr5}<br /></>
                                                        :
                                                        ''
                                                }

                                                {
                                                    (this.state.billingAddress.billAddressCity || this.state.billingAddress.billAddressState) ?
                                                        <>{this.state.billingAddress?.billAddressCity}&nbsp;{this.state.billingAddress.billAddressState}<br /></>
                                                        :
                                                        ''
                                                }

                                                {
                                                    (this.state.billingAddress?.billAddressPostalCode) ?
                                                        <>{this.state.billingAddress.billAddressPostalCode}<br /></>
                                                        :
                                                        ''
                                                }

                                                {
                                                    (this.state.billingAddress?.billAddressCountry) ?
                                                        <>{this.state.billingAddress.billAddressCountry}<br /></>
                                                        :
                                                        ''
                                                }
                                            </p>
                                        </div>


                                        <div className={(this.state.billingvisibility) ? "show-box" : "hidden-box"}>

                                            <Input
                                                name={fields.billline1.name}
                                                placeholder={'Bill Line 1'}
                                                fun={this.handleChangeValue}
                                            />

                                            <Input
                                                name={fields.billline2.name}
                                                placeholder={'Bill Line 2'}
                                                fun={this.handleChangeValue}
                                            />

                                            <Input
                                                name={fields.billline3.name}
                                                placeholder={'Bill Line 3'}
                                                fun={this.handleChangeValue}
                                            />

                                            <Input
                                                name={fields.billline4.name}
                                                placeholder={'Bill Line 4'}
                                                fun={this.handleChangeValue}
                                            />

                                            <Textarea
                                                name={fields.billline5.name}
                                                placeholder={'Bill Line 5'}
                                                fun={this.handleChangeValue}
                                            />


                                            <div class="row" >
                                                <div class="col-sm-6">

                                                    <Input
                                                        name={fields.billcity.name}
                                                        placeholder={'City'}
                                                        fun={this.handleChangeValue}

                                                    //style = {{border: "1px solid #ff0707f5", outline: "0",boxShadow: "0 0 0 0.2rem rgba(247, 232, 232, 0.904)"}}


                                                    />

                                                </div>
                                                <div class="col-sm-6">

                                                    <Input
                                                        name={fields.billstate.name}
                                                        placeholder={'State'}
                                                        style={this.state.ShippingStateError ? { border: "1px solid #ff0707f5", outline: "0", boxShadow: "0 0 0 0.2rem rgba(247, 232, 232, 0.904)" } : {}}
                                                        fun={this.handleChangeValue}
                                                    />

                                                </div>
                                            </div>

                                            <div class="row">
                                                <div class="col-sm-6">

                                                    <Input
                                                        name={fields.billzip.name}
                                                        placeholder={'Zip / Postal Code'}
                                                        style={this.state.ShippingPostalError ? { border: "1px solid #ff0707f5", outline: "0", boxShadow: "0 0 0 0.2rem rgba(247, 232, 232, 0.904)" } : {}}
                                                        fun={this.handleChangeValue}
                                                    />

                                                </div>
                                                <div class="col-sm-6">

                                                    <Input
                                                        name={fields.billcountry.name}
                                                        placeholder={'Country'}
                                                        style={this.state.ShippingCountryError ? { border: "1px solid #ff0707f5", outline: "0", boxShadow: "0 0 0 0.2rem rgba(247, 232, 232, 0.904)" } : {}}
                                                        fun={this.handleChangeValue}
                                                    />

                                                </div>
                                            </div>
                                        </div>

                                        <div className="form-group">
                                            <div className="billing-input-icon">
                                                <span><strong>Shipping Address</strong></span>
                                                {
                                                    this.state.ShippingError ?
                                                        <div type="button" class="badgeShow" style={{ minWidth: "100px" }}>
                                                            {this.state.ShippinfErrorMessage}<span class="bdgIcon"><i class="fa fa-exclamation-triangle" aria-hidden="true"></i></span>
                                                        </div>
                                                        :
                                                        ""
                                                }

                                                {
                                                    (!this.state.shippingvisibility) ?
                                                        <label onClick={this.shippingvisibilityControl} className="edit-icon-bill">Edit</label>
                                                        :
                                                        <label onClick={this.updateShipping} className="edit-icon-bill">Update</label>

                                                }

                                            </div>
                                            <p className={(!this.state.shippingvisibility) ? "show-box" : "hidden-box"}>
                                                {
                                                    (this.state.shippingAddress.shipAddressAddr1) ?
                                                        <> {this.state.shippingAddress?.shipAddressAddr1}<br /></>
                                                        :
                                                        ''
                                                }
                                                {
                                                    (this.state.shippingAddress.shipAddressAddr2) ?
                                                        <> {this.state.shippingAddress?.shipAddressAddr2}<br /></>
                                                        :
                                                        ''
                                                }
                                                {
                                                    (this.state.shippingAddress.shipAddressAddr3) ?
                                                        <> {this.state.shippingAddress?.shipAddressAddr3}<br /></>
                                                        :
                                                        ''
                                                }

                                                {
                                                    (this.state.shippingAddress.shipAddressAddr4) ?
                                                        <> {this.state.shippingAddress?.shipAddressAddr4}<br /></>
                                                        :
                                                        ''
                                                }

                                                {
                                                    (this.state.shippingAddress.shipAddressAddr5) ?
                                                        <> {this.state.shippingAddress?.shipAddressAddr5}<br /></>
                                                        :
                                                        ''
                                                }

                                                {
                                                    (this.state.shippingAddress.shipAddressCity || this.state.shippingAddress?.shipAddressState) ?
                                                        <>{this.state.shippingAddress?.shipAddressCity}&nbsp;{this.state.shippingAddress.shipAddressState}<br /></>
                                                        :
                                                        ''
                                                }

                                                {
                                                    (this.state.shippingAddress?.shipAddressPostalCode) ?
                                                        <>{this.state.shippingAddress.shipAddressPostalCode}<br /></>
                                                        :
                                                        ''
                                                }

                                                {
                                                    (this.state.shippingAddress?.shipAddressCountry) ?
                                                        <>{this.state.shippingAddress.shipAddressCountry}<br /></>
                                                        :
                                                        ''
                                                }
                                                {
                                                    this.state.ShipWarning ?
                                                        <span class="bdgIconLarge"><i class="fa fa-exclamation-triangle" aria-hidden="true"></i></span>
                                                        :
                                                        ''
                                                }

                                            </p>
                                        </div>


                                        <div className={(this.state.shippingvisibility) ? "show-box" : "hidden-box"}>

                                            <Input
                                                name={fields.shipline1.name}
                                                placeholder={'Ship Line 1'}
                                                fun={this.handleChangeValue}
                                            />

                                            <Input
                                                name={fields.shipline2.name}
                                                placeholder={'Ship Line 2'}
                                                fun={this.handleChangeValue}
                                            />

                                            <Input
                                                name={fields.shipline3.name}
                                                placeholder={'Ship Line 3'}
                                                fun={this.handleChangeValue}
                                            />

                                            <Input
                                                name={fields.shipline4.name}
                                                placeholder={'Ship Line 4'}
                                                fun={this.handleChangeValue}
                                            />

                                            <Textarea
                                                name={fields.shipline5.name}
                                                placeholder={'Ship Line 5'}
                                                fun={this.handleChangeValue}
                                            />


                                            <div class="row">
                                                <div className={`col-sm-6 ${this.state.ShippingCityError ? 'shipCssErrr' : ''}`}>
                                                    <div className="form-group">
                                                        <input
                                                            type="text"
                                                            name={fields.shipcity.name}
                                                            placeholder={'City'}
                                                            className="form-control"
                                                            id="shipcity"
                                                            onChange={(e) => { this.handleCheck(e); this.handleChange(e) }}
                                                            defaultValue={this.state.shippingAddress?.shipAddressCity}
                                                        />

                                                    </div>
                                                </div>
                                                <div className={`col-sm-6 ${this.state.ShippingStateError ? 'shipCssErrr' : ''}`}>
                                                    <div className="form-group">
                                                        <input
                                                            type="text"
                                                            name={fields.shipstate.name}
                                                            placeholder={'State'}
                                                            className="form-control"
                                                            id="shipstate"
                                                            onChange={(e) => { this.handleCheck(e); this.handleChange(e) }}
                                                            defaultValue={this.state.shippingAddress?.shipAddressState}
                                                        />

                                                    </div>

                                                </div>
                                            </div>

                                            <div class="row">
                                                <div className={`col-sm-6 ${this.state.ShippingPostalError ? 'shipCssErrr' : ''}`}>
                                                    <div className="form-group">
                                                        <input
                                                            type="text"
                                                            name={fields.shipzip.name}
                                                            placeholder={'Zip / Postal Code'}
                                                            id="shipzip"
                                                            className="form-control"
                                                            onChange={(e) => { this.handleCheck(e); this.handleChange(e) }}
                                                            defaultValue={this.state.shippingAddress?.shipAddressPostalCode}
                                                        />
                                                    </div>

                                                </div>
                                                <div className={`col-sm-6 ${this.state.ShippingCountryError ? 'shipCssErrr' : ''}`}>
                                                    <div className="form-group">
                                                        <input
                                                            type="text"
                                                            name={fields.shipcountry.name}
                                                            placeholder={'Country'}
                                                            id="shipcountry"
                                                            className="form-control"
                                                            onChange={(e) => { this.handleCheck(e); this.handleChange(e) }}
                                                            defaultValue={this.state.shippingAddress?.shipAddressCountry ? this.state.shippingAddress?.shipAddressCountry : 'United States'}
                                                        />
                                                    </div>


                                                </div>
                                            </div>
                                        </div>



                                        <Input
                                            name={fields.customerPhone.name}
                                            label={fields.customerPhone.label}
                                            fun={this.handleChangeValue}
                                        />

                                        <Input
                                            name={fields.customerALtPhone.name}
                                            label={fields.customerALtPhone.label}
                                            fun={this.handleChangeValue}
                                        />

                                    </li>
                                    <li>

                                        <Input
                                            name={fields.invoice.name}
                                            label={fields.invoice.label}
                                            disabled={true}
                                            fun={this.handleChangeValue}
                                        />

                                        <Input
                                            name={fields.wooCommerce.name}
                                            label={fields.wooCommerce.label}
                                            disabled={true}
                                            fun={this.handleChangeValue}
                                        />


                                        <div className="form-group">
                                            <label htmlFor={fields.date.label}>{fields.date.label}</label>&nbsp;&nbsp;<i class="fa fa-calendar" aria-hidden="true"></i>
                                            <DatePicker
                                                disabled
                                                className="form-control"
                                                value={moment(this.state.invoiceData.txnDate).format('MM/DD/YYYY')}
                                            />
                                        </div>

                                        <div className="form-group">
                                            <label htmlFor={fields.due.label}>{fields.due.label}</label>&nbsp;&nbsp;<i class="fa fa-calendar" aria-hidden="true"></i>
                                            <DatePicker
                                                disabled
                                                className="form-control"
                                                value={moment(this.state.invoiceData.dueDate).format('MM/DD/YYYY')}
                                            />
                                        </div>

                                        <Select
                                            name={fields.supplier_location.name}
                                            label={fields.supplier_location.label}
                                            option={this.state.supplierLocation}
                                            valueinfo={this.state.invoiceData?.supplierLocation}
                                            fun={this.handleChangeValue}
                                        />

                                        <Select
                                            name={fields.container_orientation.name}
                                            label={fields.container_orientation.label}
                                            option={[{ value: '', label: '\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0' }, ...containerOrientation]}
                                            valueinfo={this.state.invoiceData?.containerOrientation}
                                            fun={this.handleChangeValue}
                                        />

                                        {/* <select className='form-control'>
                                            <option value=''></option>
                                            <option>Doors Oreintation</option>
                                        </select> */}

                                        <Select
                                            name={fields.ship_method.name}
                                            label={fields.ship_method.label}
                                            option={shipedMehtod}
                                            valueinfo={this.state.invoiceData?.shipMethodMethodField}
                                            fun={this.handleChangeValue}
                                        />



                                    </li>
                                    <li>

                                        <Input
                                            name={fields.assignedto.name}
                                            label={fields.assignedto.label}
                                            disabled={true}
                                            fun={this.handleChangeValue}
                                        />

                                        <Input
                                            name={fields.terms.name}
                                            label={fields.terms.label}
                                            disabled={true}
                                            fun={this.handleChangeValue}
                                        />


                                        <div>
                                            <CheckboxInvoice
                                                name={fields.onhold.name}
                                                label={fields.onhold.label}
                                                disabled={true}
                                                labelClassName={'check-input-lable'}
                                            />

                                            <br />

                                            <CheckboxInvoice
                                                name={fields.expedited.name}
                                                label={fields.expedited.label}
                                                labelClassName={'check-input-lable'}
                                            />

                                            <br />

                                            <CheckboxInvoice
                                                name={fields.order_cancelled.name}
                                                label={fields.order_cancelled.label}
                                                disabled={true}
                                                labelClassName={'check-input-lable'}
                                            />

                                            <br />

                                            <CheckboxInvoice
                                                name={fields.order_refunded.name}
                                                label={fields.order_refunded.label}
                                                disabled={true}
                                                labelClassName={'check-input-lable'}
                                            />

                                            <br />

                                            <CustomerCallCompleted
                                                name={fields.customer_completed.name}
                                                label={fields.customer_completed.label}
                                                labelClassName={'check-input-lable'}
                                                bindFunction={this.handleCustomerCallChange}
                                            />
                                            <br />



                                            <SolidSurface
                                                name={fields.solid_surface.name}
                                                label={fields.solid_surface.label}
                                                labelClassName={'check-input-label'}
                                                bindFunction={this.handleSolidSurface}
                                            />

                                            <br />

                                            <TowTruck
                                                name={fields.towTruck.name}
                                                label={fields.towTruck.label}
                                                labelClassName={'check-input-label'}
                                                bindFunction={this.handleTowTruck}
                                            />

                                            <br />

                                            <CheckboxInvoice
                                                name={fields.accessories_approved.name}
                                                label={fields.accessories_approved.label}
                                                disabled={true}
                                                labelClassName={'check-input-lable'}
                                            />

                                            <br />

                                            <CheckboxInvoice
                                                name={fields.approved_accounts.name}
                                                label={fields.approved_accounts.label}
                                                disabled={true}
                                                labelClassName={'check-input-lable'}
                                            />
                                            <br />


                                            <SameTrailerDiscount
                                                name={fields.IsSameTrailerDiscount.name}
                                                label={fields.IsSameTrailerDiscount.label}
                                                bindFunction={this.handleSameTrailerDiscount}
                                                labelClassName={'check-input-lable'}
                                            />

                                            {
                                                (this.state.invoiceData?.approvedForRentToOwn == 1) ?

                                                    <div className="row">
                                                        <div className="col-5">
                                                            <label htmlFor="ServicePurchase">Rent to Own Approved By</label><br />
                                                            <label htmlFor="ServicePurchase" className="check-input-lable">{this.state.invoiceData.rentToOwnApprovedBy}</label>
                                                        </div>

                                                        <div className="col-7">
                                                            <label htmlFor="ServicePurchase">Rent to Own Approved Date</label><br />
                                                            <label htmlFor="ServicePurchase" className="check-input-lable">{(this.state.invoiceData.rentToOwnApprovedDate) ? moment(this.state.invoiceData.rentToOwnApprovedDate).tz(timezone.name()).format('MM/DD/YYYY  HH:mm A') : ''}</label>
                                                        </div>
                                                        <div className="col-12">
                                                            <div class="form-group">
                                                                <label for="terms">Ren to Own Contract #</label>
                                                                <input type="text" disabled class="form-control" value={this.state.invoiceData?.contractNumber} />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    :

                                                    ''
                                            }

                                            {
                                                (this.state.invoiceData?.customerCallCompleted == 1) ?

                                                    <div className="row">
                                                        <div className="col-5">
                                                            <label htmlFor="ServicePurchase">Completed By</label><br />
                                                            <label htmlFor="ServicePurchase" className="check-input-lable">{this.state.invoiceData.customerCallCompletedBy}</label>
                                                        </div>
                                                        <div className="col-7">
                                                            <label htmlFor="ServicePurchase">Completed On</label><br />
                                                            <label htmlFor="ServicePurchase" className="check-input-lable">{(this.state.invoiceData.customerCallCompletedOn) ? moment(this.state.invoiceData.customerCallCompletedOn).tz(timezone.name()).format('MM/DD/YYYY  HH:mm A') : ''}</label>
                                                        </div>
                                                    </div>
                                                    :
                                                    ''
                                            }

                                            <div className="row">
                                                <div className="col-5" style={{ "display": (this.state.customerCallCompleted && this.state.invoiceData?.customerCallCompleted == 0) ? "block" : "none" }}>
                                                    <label htmlFor="ServicePurchase">Completed By</label><br />
                                                    <label htmlFor="ServicePurchase" className="check-input-lable">{localStorage.getItem('user')}</label>
                                                </div>

                                                <div className="col-7" style={{ "display": (this.state.customerCallCompleted && this.state.invoiceData?.customerCallCompleted == 0) ? "block" : "none" }}>
                                                    <label htmlFor="ServicePurchase">Completed On</label><br />
                                                    <label htmlFor="ServicePurchase" className="check-input-lable">{moment(this.props.dateObject).tz(timezone.name()).format('MM/DD/YYYY  HH:mm A')}</label>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-6">
                                                <PromiseTimeLine
                                                    name={fields.promiseTimeLine.name}
                                                    label={fields.promiseTimeLine.label}
                                                    option={promiseTimeLines}
                                                    defaultValue={this.state.invoiceData?.promiseDateTimeline}
                                                    fun={this.handleChangeValue}
                                                />
                                            </div>
                                            <div className="col-6">
                                                <Date
                                                    name={fields.promiseDate.name}
                                                    label={fields.promiseDate.label}
                                                    fun={this.handleChangeValue}
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <p><b>Delivered On :-  </b><span>{this.state.invoiceData?.deliveryDate?.split('T')[0]}</span></p>
                                            <p><b>Terms &amp; Conditions Accepted On</b></p>
                                            <p htmlFor="ServicePurchase">{(this.state.invoiceData.acceptedTermsAndConditionsOn) ? moment(this.state.invoiceData.acceptedTermsAndConditionsOn).subtract(7, 'hours').format('MM/DD/YYYY  HH:mm A') : ''}</p>

                                            <Checkbox
                                                name={fields.emailed.name}
                                                label={fields.emailed.label}
                                                disabled={true}
                                                value={(this.state.invoiceData?.isEmailed == 1) ? true : false}
                                            />

                                        </div>
                                    </li>
                                    <li>
                                        <div className="row">
                                            <div className="col">
                                                <div className="form-group">
                                                    <label htmlFor="ServicePurchase">Customer Note</label>
                                                    <div className="customer-note-box">
                                                        {
                                                            this.state.customerNoteCollection?.split("\n").map((i, key) => {
                                                                return <p key={key}>{i}</p>
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <input type="text" name="customerNote" onChange={this.handleChange} class="form-control customer-note" placeholder="Add Customer Note" id="customerinput" />
                                                    <button type="button" onClick={this.customerNote} class="btn btn-primary mt-2">{this.state.loader.customerNote?<i class="fa fa-spinner fa-spin" style={{fontSize:"20px"}}></i>:'Add'}</button>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col">
                                                <div className="form-group">
                                                    <label htmlFor="ServicePurchase">Vendor Note</label>
                                                    <div className="customer-note-box">
                                                        {
                                                            this.state.vendorNoteCollection?.split("\n").map((i, key) => {
                                                                return <p key={key}>{i}</p>
                                                            })
                                                        }
                                                    </div>
                                                </div>

                                                <div className="form-group">
                                                    <input type="text" name="vendorNote" onChange={this.handleChange} class="form-control vendor-note" placeholder="Add Vendor Note" id="vendorNote" />
                                                    <button type="button" onClick={this.vendorNote} class="btn btn-primary mt-2">{this.state.loader.vendorNote?<i class="fa fa-spinner fa-spin" style={{fontSize:"20px"}}></i>:'Add'}</button>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="row">
                                            <div className="col">
                                                <div className="form-group">
                                                    <label htmlFor="ServicePurchase">Dispatch Note</label>
                                                    <div className="customer-note-box">
                                                        {
                                                            this.state.dispatchedNoteCollection?.split("\n").map((i, key) => {
                                                                return <p key={key}>{i}</p>
                                                            })
                                                        }
                                                    </div>
                                                </div>

                                                <div className="form-group">
                                                    <input type="text" name="dispatchNote" onChange={this.handleChange} class="form-control customer-note" placeholder="Add Dispatch Note" id="dispatchNote" />
                                                    <button type="button" onClick={this.dispatchNote} class="btn btn-primary mt-2">{this.state.loader.dispatchNote?<i class="fa fa-spinner fa-spin" style={{fontSize:"20px"}}></i>:'Add'}</button>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="row">
                                            <div className="col">
                                                <div className="form-group">
                                                    <label htmlFor="ServicePurchase">Purchasing Note</label>
                                                    <div className="customer-note-box">
                                                        {
                                                            this.state.purchasingNoteCollection?.split("\n").map((i, key) => {
                                                                return <p key={key}>{i}</p>
                                                            })
                                                        }
                                                    </div>
                                                </div>

                                                <div className="form-group">
                                                    <input type="text" name="purchasingNote" onChange={this.handleChange} class="form-control customer-note" placeholder="Add Purchasing Note" id="purchasingNote" />
                                                    <button type="button" onClick={this.purchasingNote} class="btn btn-primary mt-2">{this.state.loader.purchasingNote?<i class="fa fa-spinner fa-spin" style={{fontSize:"20px"}}></i>:'Add'}</button>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>

                            <InvoiceTable invoiceLineItemDesc={this.state.invoiceData?.invoiceLineItemDesc} invoiceRecordID={this.state.invoiceData?.recordID} txnDate={this.state.invoiceData.txnDate} checkedInvoiceRecordID={this.checkedInvoiceRecordID} />
                            <div className="row" style={{ "margin-top": "45px" }}>

                                <div className="col-sm-4">
                                    <Dropzone tableName={'invoices'} recordID={this.state.invoiceData?.recordID} allfiles={this.state.attachment} deleteAttachement={this.deleteAttachementFile} attachment={this.pushAttachementFile} />
                                </div>

                                <div className="col-sm-4">
                                    <label htmlFor="ServicePurchase">Memo (Internal)</label>
                                    <Textarea
                                        name={fields.memo.name}
                                        fun={this.handleChangeValue}
                                    />

                                </div>
                                <div className="col-sm-4">
                                    <div className="row" style={{ "margin-top": "9%" }}>
                                        <div className="col-sm-6">
                                            <p>Subtotal</p>
                                        </div>
                                        <div className="col-sm-6">
                                            <p>{this.state.invoiceData?.subtotal}</p>
                                        </div>
                                        <div className="col-sm-6">
                                            <p>Total</p>
                                        </div>
                                        <div className="col-sm-6">
                                            <p>{this.state.invoiceData?.amount}</p>
                                        </div>
                                        <div className="col-sm-6">
                                            <p>Payment/Credits</p>
                                        </div>
                                        <div className="col-sm-6">
                                            <p>{this.state.invoiceData?.appliedAmount}</p>
                                        </div>
                                        <div className="col-sm-12">
                                            <h4>Balance Due <span className="amount-text"><strong>{this.state.invoiceData.balanceRemaining}</strong></span></h4>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row mt-3 mb-3">
                                <div class="col-sm-6">
                                </div>
                                <div class="col-sm-6 text-right">
                                    {
                                        (this.state.syncLoading) ?
                                            <button type="button" disabled style={{ "margin-right": "5px" }} onClick={this.manualSync} class="btn btn-primary">Manual Sync</button>
                                            :
                                            <button type="button" style={{ "margin-right": "5px" }} onClick={this.manualSync} class="btn btn-primary">Manual Sync</button>
                                    }
                                    <button type="button" style={{ "margin-right": "5px" }} onClick={() => this.redirectToDispatch(this.state.invoiceData.refNumber, this.state.recordIDInvoice, this.state.resalePOIsDepotCallCompleted)} class="btn btn-primary">Dispatch</button>
                                    {
                                        (this.state.initUpdating) ?
                                            <button type="submit" disabled="true" class="btn btn-primary">Loading....</button>
                                            :
                                            <button type="submit" class="btn btn-primary">Save</button>
                                    }

                                </div>
                            </div>
                        </form>
                    )}
                </Formik>
                <style jsx="true">{`
                                            
                .form-control {
                    font-size: 13px;
                    border: 1px solid #c8c2c2;
                    border-radius: 0px;
                } 
                .css-yk16xz-control{
                    border-color:1px solid #c8c2c2;
                    border-radius:0px;
                }  
                .purchase-order-form label {
                    font-weight: 600;
                    font-size: 13px;
                    color:#000;
                  }   
                  
                .form-control:disabled {
                    background-color:#ffeaea !important;
                    opacity:1 !important;
                }
                .billing-input-icon{
                    font-size:13px;
                }
              
                .show-box {
                    display: block;
                    font-size: 13px;
                  }
                .customer-note-box {
                    background-color:#ffeaea !important;
                    resize: both;
                  
                }
                .billing-input-icon{
                    font-weight:600;
                    color:#000;
                }
                .check-input-lable{
                    font-weight:500 !important;
                }
                 `}</style>
            </>
        );
    }
}

export default withRouter(InvoiceForm);
