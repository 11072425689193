import React, { useState, useEffect } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { ACCESS_TOKEN_NAME, ACCESS_TOKEN_NAME_USER, API_BASE_URL } from '../../constants/apiConstants';
import axios from 'axios'
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import moment from 'moment';
import $ from 'jquery';
import Sidebar from "./../Layout/Sidebar";
import Footer from "./../Layout/Footer";
import MaterialTable from "material-table";
import logo from '../../images/logo.png';
import profilepic from '../../images/avatar-4.jpg';
import './custom.css';
import DatePicker from "react-datepicker";
import PlanningServices from '../DriverPlanning/PlanningServices';
import PurchaseOrderServices from '../PurchaseOrder/Services/PurchaseOrderServices';
import Avatar from 'react-avatar';
import { ToastContainer, toast } from 'react-toastify';
import avoidNull from '../../utils/AvoidNull';
import avoidNull2 from '../../utils/AvoidNull2';
import removeAddress from '../../utils/RemoveAddress';
import CustomDatePicker from "../../utils/customDatePicker";
import FilterComponentBox from "../../utils/FilterComponentBox";
import ResetFillterButton from "../../utils/ResetFillterButton";
import { useCookies } from 'react-cookie';
import Cookies from 'universal-cookie';
import SyncComponent from '../Shared/SyncComponent';


function NeedsplannedLayout(props) {
    const [pageRefresh, setPageRefresh] = useState(false);
    const [cookies, setCookie, removeCookie] = useCookies(['needsplanned']);

    const [orderData, setOrderData] = useState();
    const [filterData, setFilterData] = useState();

    const [display, setdisplay] = useState('none');

    const [selectedStatus, setselectedStatus] = useState();
    const [disptachedOrder, setdisptachedOrder] = React.useState([]);
    const [despatchLoading, setDespatchLoading] = useState(false);

    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [pageNameList, setPageNameList] = useState('Needs Planned')

    const [rowsPerPage, setRowsPerPage] = useState({
        data: ""
    });

    const cookiesi = new Cookies();


    useEffect(() => {
        $(document).ready(function () {
            setTimeout(function () {
                $('#myTable').DataTable({
                    "scrollX": true,
                    "order": [[3, "desc"]]
                });
            }, 1000);
        });
    }, []);

    const resetFilter = async () => {
        const test = document.querySelectorAll(".material-icons");
        for(let tests of test)
        {
        if(tests.getAttribute("aria-label") == 'clear')
        {
            tests.click();
        }
        }
        const cookies = new Cookies();
        if (Object.keys(cookies.getAll()).length > 0) {
            setPageRefresh(true);
        }
        else {
            setPageRefresh(true);
        }
        await removeCookie("orderBy", { path: "/needsplanned" });
        await removeCookie("direction", { path: "/needsplanned" });
        await removeCookie("invoiceRefNumber", { path: "/needsplanned" });
        await removeCookie("invoicePaidDate", { path: "/needsplanned" });
        await removeCookie("invoiceTerms", { path: "/needsplanned" });
        await removeCookie("invoiceLineItemItemNameWithoutParent", { path: "/needsplanned" });
        await removeCookie("invoiceAssignedToName", { path: "/needsplanned" });
        await removeCookie("resalePOPickupCity", { path: "/needsplanned" });
        await removeCookie("resalePORefNumer", { path: "/needsplanned" });
        await removeCookie("resalePOReleaseNumber", { path: "/needsplanned" });
        await removeCookie("resalePOReleaseDate", { path: "/needsplanned" });
        await removeCookie("resalePOTotalPrice", { path: "/needsplanned" });
        await removeCookie("resalePOExpectedDate", { path: "/needsplanned" });
        await removeCookie("resalePOLastDepotCallDate", { path: "/needsplanned" });
        await removeCookie("resalePOLineItemDistance", { path: "/needsplanned" });
       // await removeCookie("invoiceShipCity", { path: "/needsplanned" });
	    await removeCookie("newDistance", { path: "/needsplanned" });
        await removeCookie("transportPOLineItemContainerNumber", { path: "/needsplanned" });
        await removeCookie("transportPORefNumer", { path: "/needsplanned" });
        await removeCookie("transportPOVendor", { path: "/needsplanned" });
        await removeCookie("transportPOTotalPrice", { path: "/needsplanned" });
        await removeCookie("transportPOScheduledDeliveryDate", { path: "/needsplanned" });
        await removeCookie("transportPODeliveryStatus", { path: "/needsplanned" });
        await removeCookie("invoicePromisedTimeline", { path: "/needsplanned" });
        await removeCookie("invoicePromisedDate", { path: "/needsplanned" });
        await removeCookie("invoiceTxnDate", { path: "/needsplanned" });
        await removeCookie("invoiceCustomerNotes", { path: "/needsplanned" });
        await removeCookie("invoiceBalanceRemaining", { path: "/needsplanned" });
        await removeCookie("invoiceSupplierLocation", { path: "/needsplanned" });
        await removeCookie("invoiceIsExpedited", { path: "/needsplanned" });
        await removeCookie("resalePOLineItemContainerNumber", { path: "/needsplanned" });
        await removeCookie("resalePOVendor", { path: "/needsplanned" });
        await removeCookie("plannedStatus", { path: "/needsplanned" });
        await removeCookie("invoiceShipMethodMethodFieldName", { path: "/needsplanned" });
    }

    const fetchNeedToDispatch = async () => {
        try {
            const result = await PlanningServices.findOrderIdDetails();
            setdisptachedOrder(result);
        } catch (error) {
            console.log(error, 'fetchNeedToDispatch');
        }
    }

    const [loading, setLoading] = useState(false);

    const fetchNeedsplanned = async () => {
        const token = localStorage.getItem(ACCESS_TOKEN_NAME);
        setLoading(true);
        let results = await axios.get(API_BASE_URL + "/getNeedsplanned", {
            headers: {
                Authorization: "Bearer " + token,
            },
        });
        setLoading(false);
        setOrderData(results.data);
    }

    const doDispatch = async (invoiceRefnumber, product, recordID) => {
        setDespatchLoading(true);
        const today = new Date();
        var days = ['Sun', 'Mon', 'Tus', 'Wed', 'Thu', 'Fri', 'Sat'];
        var months = new Array("Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sep", "Oct", "Nov", "Dec");
        var orders = disptachedOrder.filter((item) => item.invoiceRefNumber == invoiceRefnumber && item.invoicespo_record_id == recordID);

        const payload = {
            contactId: 99434,
            locationId: 0,
            customerConfirmation: 0,
            driveConfirmation: 0,
            size: (orders[0]) ? orders[0].invoiceLineItemItemName : '',
            invoiceId: recordID,
            City: (orders[0]) ? orders[0].City : '',
            drivername: 'DispatchFromInvoie',
            product: (orders[0]) ? orders[0].invoiceLineItemItemNameWithoutParent : '',
            State: (orders[0]) ? orders[0].State : '',
            DropState: (orders[0]) ? orders[0].DropState : '',
            DropCity: (orders[0]) ? orders[0].DropCity : '',
            Dist: (orders[0]) ? orders[0].Dist : '',
            plannedStatus: 0,
            resalePORefNumer: (orders[0]) ? orders[0].resalePORefNumer : '',
            isExpedited: (orders[0]) ? orders[0].invoiceIsExpedited : '',
            date: days[today.getDay()] + ' ' + today.getDate() + ' ' + months[today.getMonth()]
            //date: ""
        };

        const result = await PlanningServices.fetchPlanningByRecordID(recordID);

        if (result.length == 1) {
            window.open("/purchaseorder/" + result[0].id);
            setDespatchLoading(false);
        } else {
            const resultinfo = await PlanningServices.savePlanning(payload);
            setDespatchLoading(false);
            window.open("/purchaseorder/" + resultinfo[0].lastinsterid);
        }
    }

    const handleSubmitSearch = (e) => {
        e.preventDefault();

        if (typeof (endDate) == 'undefined' || typeof (startDate) == 'undefined') {

            setTimeout(() => {
                toast.success('Please select a date that is at least 1 business day from now', {
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });
            });
            return true;
        }

        const payload = {
            from: startDate,
            to: endDate,
            status: selectedStatus
        }
        axios
            .post(API_BASE_URL + "/findDataUsingDate", payload)
            .then(function (response) {
                console.log('filterdatadatewise-----', response.data);
                setOrderData(response.data);
            })
            .catch(error => {
                console.log(error);
            })
    }


    useEffect(() => {
        //this is hardcode
        fetchNeedsplanned();
        setselectedStatus(7);
        fetchNeedToDispatch();
    }, []);



    function handleLogout() {
        localStorage.removeItem(ACCESS_TOKEN_NAME);
        localStorage.removeItem('role');
        localStorage.removeItem('user');
        localStorage.removeItem(ACCESS_TOKEN_NAME_USER);
        props.history.push("/");
    }


    console.log(moment('2021-10-04').format('MM-DD-YY'), 'dateinfo');

    return (

        <div id="pcoded" className="pcoded">
            <div className="pcoded-overlay-box"></div>
            <div className="pcoded-container navbar-wrapper">

                {/* <Navbar /> */}


                <nav className="navbar header-navbar pcoded-header" style={{ height: '55px', minHeight: '26px' }}>
                    <div className="navbar-wrapper">
                        <div className="navbar-logo" style={{ height: '52px' }}>
                            <a style={{ top: '7px' }} className="mobile-menu navabar_menu" id="mobile-collapse" href="#!">
                                <i class="ti-menu" style={{ "color": " #000", "font-size": "22px;" }}></i>
                            </a>
                            <a className="mobile-search morphsearch-search" href="#">
                                <i className="ti-search"></i>
                            </a>
                            <Link to="/dashboard">
                                <img style={{ width: '30%' }} className="img-fluid logo-img" src={logo} alt="Theme-Logo" />
                            </Link>
                            <a className="mobile-options">
                                <i className="ti-more"></i>
                            </a>
                        </div>

                        <SyncComponent setPageRefresh={setPageRefresh} pageNameList={pageNameList} />
                        
                    </div>
                </nav>


                <div className="pcoded-main-container">
                    <div className="pcoded-wrapper">

                        <Sidebar />

                        <div className="pcoded-content page_content">
                            <div className="pcoded-inner-content">
                                <div className="main-body">
                                    <div className="page-wrapper">
                                        <div className="page-body">
                                            <div className="col-md-12 tabs-ui p-0">
                                            <div className='row justify-content-center align-items-center color-choose'>
                                               <h5 className='cstmstates'><span>RTO</span><span className='statecolor rto'></span></h5>
                                               <h5 className='cstmstates'><span>Solid Surface</span><span className='statecolor solid_surface'></span></h5>      
                                               <h5 className='cstmstates'><span>RTO on Solid Surface</span><span className='statecolor rto_ss'></span></h5>  
                                               <h5 className='cstmstates'><span>Tow Truck</span><span className='statecolor solid_tow'></span></h5>      
                                               <h5 className='cstmstates'><span>RTO + Tow Truck</span><span className='statecolor rto-two'></span></h5>    
                                               <h5 className='cstmstates'><span>Solid Surface + Tow Truck</span><span className='statecolor solid-two'></span></h5>      
                                            </div>
                                                <div className="tab-content" id="nav-tabContent">
                                                    <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">

                                                        <div className="col-md-12 pr-0">
                                                            <MaterialTable
                                                                key={pageRefresh}
                                                                columns={[
                                                                    { title: "RecordID", field: "RecordID", hidden: true },
                                                                    {
                                                                        title: "Customer", field: "invoiceRefNumber",
                                                                        defaultSort: cookiesi.getAll()['orderBy'] == 'invoiceCustomerFullName' ? cookiesi.getAll()['direction'] : false,
                                                                        filterComponent: ({ columnDef, onFilterChanged }) => (
                                                                            <FilterComponentBox pageName={'needsplanned'} onFilterChanged={onFilterChanged} columnDef={columnDef} columnName={columnDef.field} />
                                                                        ),
                                                                        render: rowData => <a className={`breakwordwichpadding ${rowData.sameTrailerMarker === 1 &&  rowData.invoiceIsExpedited === 'Yes' ? 'markerExp' : rowData.invoiceIsExpedited === 'Yes' ? 'expeditedClass' : rowData.sameTrailerMarker === 1 ? 'sameMarker' : ''}`} target="_blank" href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID}><strong className="title-strong">{rowData.Customer?.split('*')[0]}</strong><br />{rowData.Customer?.split('*')[1]}</a>
                                                                    },

                                                                    {
                                                                        title: "Paid Date", field: "invoicePaidDate",
                                                                        defaultSort: cookiesi.getAll()['orderBy'] == 'invoicePaidDate' ? cookiesi.getAll()['direction'] : false,
                                                                        customFilterAndSearch: (term, rowData) => (rowData.invoicePaidDate == moment(term).format('YYYY-MM-DD')) ? true : false,
                                                                        filterComponent: (props) => <CustomDatePicker pageName={'needsplanned'} {...props} />,

                                                                        type: 'date', render: rowData => <a className={rowData.sameTrailerMarker === 1 &&  rowData.invoiceIsExpedited === 'Yes' ? 'markerExp' : rowData.invoiceIsExpedited === 'Yes' ? 'expeditedClass' : rowData.sameTrailerMarker === 1 ? 'sameMarker' : ''} target="_blank" href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID}>{(rowData.invoicePaidDate) ? moment(rowData.invoicePaidDate).format('MM/DD/YY') : ''}</a>
                                                                    },
                                                                    {
                                                                        title: "Terms", field: "invoiceTerms",
                                                                        defaultSort: cookiesi.getAll()['orderBy'] == 'invoiceTerms' ? cookiesi.getAll()['direction'] : false,
                                                                        filterComponent: ({ columnDef, onFilterChanged }) => (
                                                                            <FilterComponentBox pageName={'needsplanned'} onFilterChanged={onFilterChanged} columnDef={columnDef} columnName={columnDef.field} />
                                                                        ),
                                                                        render: rowData => <a className={`breakwordwichpadding ${rowData.sameTrailerMarker === 1 &&  rowData.invoiceIsExpedited === 'Yes' ? 'markerExp' : rowData.invoiceIsExpedited === 'Yes' ? 'expeditedClass' : rowData.sameTrailerMarker === 1 ? 'sameMarker' : ''}`} target="_blank" href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID} style={{ color: rowData.invoiceTerms == "Rent To Own" ? 'Red' : '#303548' }}>{rowData.invoiceTerms}</a>
                                                                    },
                                                                    {
                                                                        title: "Product", field: "invoiceLineItemItemNameWithoutParent",
                                                                        defaultSort: cookiesi.getAll()['orderBy'] == 'invoiceLineItemItemNameWithoutParent' ? cookiesi.getAll()['direction'] : false,
                                                                        filterComponent: ({ columnDef, onFilterChanged }) => (
                                                                            <FilterComponentBox pageName={'needsplanned'} onFilterChanged={onFilterChanged} columnDef={columnDef} columnName={columnDef.field} />
                                                                        ),
                                                                        render: rowData => <a className={rowData.sameTrailerMarker === 1 &&  rowData.invoiceIsExpedited === 'Yes' ? 'markerExp' : rowData.invoiceIsExpedited === 'Yes' ? 'expeditedClass' : rowData.sameTrailerMarker === 1 ? 'sameMarker' : ''} target="_blank" href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID}>{rowData.invoiceLineItemItemNameWithoutParent}</a>
                                                                    },
                                                                    {
                                                                        title: "Assign To", field: "invoiceAssignedToName",
                                                                        defaultSort: cookiesi.getAll()['orderBy'] == 'invoiceAssignedToName' ? cookiesi.getAll()['direction'] : false,
                                                                        filterComponent: ({ columnDef, onFilterChanged }) => (
                                                                            <FilterComponentBox pageName={'needsplanned'} onFilterChanged={onFilterChanged} columnDef={columnDef} columnName={columnDef.field} />
                                                                        ),
                                                                        render: rowData => <a className={`breakword ${rowData.sameTrailerMarker === 1 &&  rowData.invoiceIsExpedited === 'Yes' ? 'markerExp' : rowData.invoiceIsExpedited === 'Yes' ? 'expeditedClass' : rowData.sameTrailerMarker === 1 ? 'sameMarker' : ''}`} target="_blank" href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID}>{rowData.invoiceAssignedToName}</a>
                                                                    },


                                                                    {
                                                                        title: "Expedited", field: "invoiceIsExpedited",
                                                                        defaultSort: cookiesi.getAll()['orderBy'] == 'invoiceIsExpedited' ? cookiesi.getAll()['direction'] : false,
                                                                        filterComponent: ({ columnDef, onFilterChanged }) => (
                                                                            <FilterComponentBox pageName={'needsplanned'} onFilterChanged={onFilterChanged} columnDef={columnDef} columnName={columnDef.field} />
                                                                        ),
                                                                        render: rowData => <a className={`breakword ${rowData.sameTrailerMarker === 1 &&  rowData.invoiceIsExpedited === 'Yes' ? 'markerExp' : rowData.invoiceIsExpedited === 'Yes' ? 'expeditedClass' : rowData.sameTrailerMarker === 1 ? 'sameMarker' : ''}`} target="_blank" href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID}><center>{rowData.invoiceIsExpedited}</center></a>
                                                                    },


                                                                    {
                                                                        title: "Container #", field: "resalePOLineItemContainerNumber",
                                                                        defaultSort: cookiesi.getAll()['orderBy'] == 'resalePOLineItemContainerNumber' ? cookiesi.getAll()['direction'] : false,
                                                                        filterComponent: ({ columnDef, onFilterChanged }) => (
                                                                            <FilterComponentBox pageName={'needsplanned'} onFilterChanged={onFilterChanged} columnDef={columnDef} columnName={columnDef.field} />
                                                                        ),
                                                                        render: rowData => <a className={rowData.sameTrailerMarker === 1 &&  rowData.invoiceIsExpedited === 'Yes' ? 'markerExp' : rowData.invoiceIsExpedited === 'Yes' ? 'expeditedClass' : rowData.sameTrailerMarker === 1 ? 'sameMarker' : ''} target="_blank" href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID}>{rowData.resalePOLineItemContainerNumber}</a>
                                                                    },
                                                                    {
                                                                        title: "Supp Loc", field: "invoiceSupplierLocation",
                                                                        defaultSort: cookiesi.getAll()['orderBy'] == 'invoiceSupplierLocation' ? cookiesi.getAll()['direction'] : false,
                                                                        filterComponent: ({ columnDef, onFilterChanged }) => (
                                                                            <FilterComponentBox pageName={'needsplanned'} onFilterChanged={onFilterChanged} columnDef={columnDef} columnName={columnDef.field} />
                                                                        ),
                                                                        render: rowData => <a className={`breakword ${rowData.sameTrailerMarker === 1 &&  rowData.invoiceIsExpedited === 'Yes' ? 'markerExp' : rowData.invoiceIsExpedited === 'Yes' ? 'expeditedClass' : rowData.sameTrailerMarker === 1 ? 'sameMarker' : ''}`} target="_blank" href={'https://www.google.com/maps/place/' + rowData.invoiceSupplierLocation}>{rowData.invoiceSupplierLocation}</a>
                                                                    },
                                                                    {
                                                                        title: "Res PO", field: "resalePORefNumer",
                                                                        defaultSort: cookiesi.getAll()['orderBy'] == 'resalePORefNumer' ? cookiesi.getAll()['direction'] : false,
                                                                        filterComponent: ({ columnDef, onFilterChanged }) => (
                                                                            <FilterComponentBox pageName={'needsplanned'} onFilterChanged={onFilterChanged} columnDef={columnDef} columnName={columnDef.field} />
                                                                        ),
                                                                        render: rowData => <a className={rowData.sameTrailerMarker === 1 &&  rowData.invoiceIsExpedited === 'Yes' ? 'markerExp' : rowData.invoiceIsExpedited === 'Yes' ? 'expeditedClass' : rowData.sameTrailerMarker === 1 ? 'sameMarker' : ''} target="_blank" href={'/resalePurchaseOrder/' + rowData.ResalePORecordID + '/' + rowData.RecordID}>{rowData.resalePORefNumer}</a>
                                                                    },
                                                                    {
                                                                        title: "Res Ven", field: "resalePOVendor",
                                                                        defaultSort: cookiesi.getAll()['orderBy'] == 'resalePOVendor' ? cookiesi.getAll()['direction'] : false,
                                                                        filterComponent: ({ columnDef, onFilterChanged }) => (
                                                                            <FilterComponentBox pageName={'needsplanned'} onFilterChanged={onFilterChanged} columnDef={columnDef} columnName={columnDef.field} />
                                                                        ),
                                                                        render: rowData => <a className={`breakword ${rowData.sameTrailerMarker === 1 &&  rowData.invoiceIsExpedited === 'Yes' ? 'markerExp' : rowData.invoiceIsExpedited === 'Yes' ? 'expeditedClass' : rowData.sameTrailerMarker === 1 ? 'sameMarker' : ''}`} target="_blank" href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID}>{rowData.resalePOVendor}</a>
                                                                    },
                                                                    {
                                                                        title: "Rele #", field: "resalePOReleaseNumber",
                                                                        defaultSort: cookiesi.getAll()['orderBy'] == 'resalePOReleaseNumber' ? cookiesi.getAll()['direction'] : false,
                                                                        filterComponent: ({ columnDef, onFilterChanged }) => (
                                                                            <FilterComponentBox pageName={'needsplanned'} onFilterChanged={onFilterChanged} columnDef={columnDef} columnName={columnDef.field} />
                                                                        ),
                                                                        render: rowData => <a className={rowData.sameTrailerMarker === 1 &&  rowData.invoiceIsExpedited === 'Yes' ? 'markerExp' : rowData.invoiceIsExpedited === 'Yes' ? 'expeditedClass' : rowData.sameTrailerMarker === 1 ? 'sameMarker' : ''} target="_blank" href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID}>{rowData.resalePOReleaseNumber}</a>
                                                                    },
                                                                    {
                                                                        title: "Release Date", field: "resalePOReleaseDate",
                                                                        defaultSort: cookiesi.getAll()['orderBy'] == 'resalePOReleaseDate' ? cookiesi.getAll()['direction'] : false,
                                                                        customFilterAndSearch: (term, rowData) => (rowData.resalePOReleaseDate == moment(term).format('YYYY-MM-DD')) ? true : false,
                                                                        filterComponent: (props) => <CustomDatePicker pageName={'needsplanned'} {...props} />,

                                                                        type: 'date', render: rowData => <a className={rowData.sameTrailerMarker === 1 &&  rowData.invoiceIsExpedited === 'Yes' ? 'markerExp' : rowData.invoiceIsExpedited === 'Yes' ? 'expeditedClass' : rowData.sameTrailerMarker === 1 ? 'sameMarker' : ''} target="_blank" href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID}>{(rowData.ReleaseDate) ? moment(rowData.ReleaseDate).format('MM/DD/YY') : ''}</a>
                                                                    },
                                                                    {
                                                                        title: "Res Total", field: "resalePOTotalPrice",
                                                                        defaultSort: cookiesi.getAll()['orderBy'] == 'resalePOTotalPrice' ? cookiesi.getAll()['direction'] : false,
                                                                        filterComponent: ({ columnDef, onFilterChanged }) => (
                                                                            <FilterComponentBox pageName={'needsplanned'} onFilterChanged={onFilterChanged} columnDef={columnDef} columnName={columnDef.field} />
                                                                        ),
                                                                        render: rowData => <a className={rowData.sameTrailerMarker === 1 &&  rowData.invoiceIsExpedited === 'Yes' ? 'markerExp' : rowData.invoiceIsExpedited === 'Yes' ? 'expeditedClass' : rowData.sameTrailerMarker === 1 ? 'sameMarker' : ''} target="_blank" href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID}>{rowData.resalePOTotalPrice}</a>
                                                                    },

                                                                    {
                                                                        title: "Exp Date", field: "resalePOExpectedDate", type: 'date',
                                                                        defaultSort: cookiesi.getAll()['orderBy'] == 'resalePOExpectedDate' ? cookiesi.getAll()['direction'] : false,
                                                                        filterComponent: (props) => <CustomDatePicker pageName={'needsplanned'} {...props} />,
                                                                        customFilterAndSearch: (term, rowData) => (rowData.resalePOExpectedDate == moment(term).format('YYYY-MM-DD')) ? true : false,
                                                                        render: rowData => <a className={rowData.sameTrailerMarker === 1 &&  rowData.invoiceIsExpedited === 'Yes' ? 'markerExp' : rowData.invoiceIsExpedited === 'Yes' ? 'expeditedClass' : rowData.sameTrailerMarker === 1 ? 'sameMarker' : ''} target="_blank" href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID}>{(rowData.resalePOExpectedDate) ? moment(rowData.resalePOExpectedDate).format('MM/DD/YY') : ''}</a>
                                                                    },


                                                                    {
                                                                        title: "Last Depot Call", field: "resalePOLastDepotCallDate",
                                                                        defaultSort: cookiesi.getAll()['orderBy'] == 'resalePOLastDepotCallDate' ? cookiesi.getAll()['direction'] : false,
                                                                        customFilterAndSearch: (term, rowData) => (rowData.resalePOLastDepotCallDate == moment(term).format('YYYY-MM-DD')) ? true : false,
                                                                        filterComponent: (props) => <CustomDatePicker pageName={'needsplanned'} {...props} />,

                                                                        type: 'date', render: rowData => <a className={rowData.sameTrailerMarker === 1 &&  rowData.invoiceIsExpedited === 'Yes' ? 'markerExp' : rowData.invoiceIsExpedited === 'Yes' ? 'expeditedClass' : rowData.sameTrailerMarker === 1 ? 'sameMarker' : ''} target="_blank" href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID}>{(rowData.resalePOLastDepotCallDate) ? moment(rowData.resalePOLastDepotCallDate).format('MM/DD/YY') : ''}</a>
                                                                    },
                                                                    {
                                                                        title: "Planned", field: "plannedStatus",
                                                                        defaultSort: cookiesi.getAll()['orderBy'] == 'plannedStatus' ? cookiesi.getAll()['direction'] : false,
                                                                        filterComponent: ({ columnDef, onFilterChanged }) => (
                                                                            <FilterComponentBox pageName={'needsplanned'} onFilterChanged={onFilterChanged} columnDef={columnDef} columnName={columnDef.field} />
                                                                        ),
                                                                        render: rowData => <a className={rowData.sameTrailerMarker === 1 &&  rowData.invoiceIsExpedited === 'Yes' ? 'markerExp' : rowData.invoiceIsExpedited === 'Yes' ? 'expeditedClass' : rowData.sameTrailerMarker === 1 ? 'sameMarker' : ''} target="_blank" href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID}><center>{rowData.plannedStatus}</center></a>
                                                                    },

                                                                    {
                                                                        title: "Pickup Loc", field: "resalePOPickupCity",
                                                                        defaultSort: cookiesi.getAll()['orderBy'] == 'resalePOPickupCity' ? cookiesi.getAll()['direction'] : false,
                                                                        filterComponent: ({ columnDef, onFilterChanged }) => (
                                                                            <FilterComponentBox pageName={'needsplanned'} onFilterChanged={onFilterChanged} columnDef={columnDef} columnName={columnDef.field} />
                                                                        ),
                                                                        render: rowData => <a className={`breakword ${rowData.sameTrailerMarker === 1 &&  rowData.invoiceIsExpedited === 'Yes' ? 'markerExp' : rowData.invoiceIsExpedited === 'Yes' ? 'expeditedClass' : rowData.sameTrailerMarker === 1 ? 'sameMarker' : ''}`} target="_blank" href={'https://www.google.com/maps/place/' + rowData.pickupLocation}>{rowData.resalePOPickupCity}<br />{rowData.pickupState}</a>
                                                                    },


                                                                    {
                                                                        title: "Del City/State", field: "invoiceShipCity",
                                                                        defaultSort: cookiesi.getAll()['orderBy'] == 'invoiceShipCity' ? cookiesi.getAll()['direction'] : false,
                                                                        filterComponent: ({ columnDef, onFilterChanged }) => (
                                                                            <FilterComponentBox pageName={'needsplanned'} onFilterChanged={onFilterChanged} columnDef={columnDef} columnName={columnDef.field} />
                                                                        ),
                                                                        render: (rowData) => {
                                                                            return <a className={`breakword ${rowData.sameTrailerMarker === 1 &&  rowData.invoiceIsExpedited === 'Yes' ? 'markerExp' : rowData.invoiceIsExpedited === 'Yes' ? 'expeditedClass' : rowData.sameTrailerMarker === 1 ? 'sameMarker' : ''}`} target="_blank" href={`https://www.google.com/maps/dir/?api=1&origin=${avoidNull(rowData.resalePOPickupAddress1)}${avoidNull(rowData.resalePOPickupCity)}${avoidNull2(rowData.resalePOPickupZipCode)}&destination=${removeAddress(rowData.invoiceShipAddress1,rowData.shipState)}${removeAddress(rowData.invoiceShipAddress2, rowData.shipState)}${removeAddress(rowData.invoiceShipAddress3, rowData.shipState)}${avoidNull(rowData.invoiceShipCity)}${avoidNull(rowData.shipState)}${avoidNull2(rowData.invoiceShipZip)}`}>{rowData.invoiceShipCity}<br />{rowData.shipState}</a>
                                                                        }
                                                                    },

                                                        
																	{
                                                                        title: "Miles", field: "newDistance",
                                                                        defaultSort: cookiesi.getAll()['orderBy'] == 'newDistance' ? cookiesi.getAll()['direction'] : false,
                                                                        filterComponent: ({ columnDef, onFilterChanged }) => (
                                                                            <FilterComponentBox pageName={'needsplanned'} onFilterChanged={onFilterChanged} columnDef={columnDef} columnName={columnDef.field} />
                                                                        ),
                                                                        render: rowData => <a className={rowData.sameTrailerMarker === 1 &&  rowData.invoiceIsExpedited === 'Yes' ? 'markerExp' : rowData.invoiceIsExpedited === 'Yes' ? 'expeditedClass' : rowData.sameTrailerMarker === 1 ? 'sameMarker' : ''} target="_blank" href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID}>{ rowData.newDistance ? Math.round(rowData.newDistance) : ''}</a>
                                                                    },


                                                                    {
                                                                        title: "Ship Method", field: "invoiceShipMethodMethodFieldName",
                                                                        defaultSort: cookiesi.getAll()['orderBy'] == 'invoiceShipMethodMethodFieldName' ? cookiesi.getAll()['direction'] : false,
                                                                        filterComponent: ({ columnDef, onFilterChanged }) => (
                                                                            <FilterComponentBox pageName={'needsplanned'} onFilterChanged={onFilterChanged} columnDef={columnDef} columnName={columnDef.field} />
                                                                        ),
                                                                        render: rowData => <a className={rowData.sameTrailerMarker === 1 &&  rowData.invoiceIsExpedited === 'Yes' ? 'markerExp' : rowData.invoiceIsExpedited === 'Yes' ? 'expeditedClass' : rowData.sameTrailerMarker === 1 ? 'sameMarker' : ''} target="_blank" href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID}>{rowData.invoiceShipMethodMethodFieldName}</a>
                                                                    },

                                                                    {
                                                                        title: "Prom Timeline", field: "invoicePromisedTimeline",
                                                                        defaultSort: cookiesi.getAll()['orderBy'] == 'invoicePromisedTimeline' ? cookiesi.getAll()['direction'] : false,
                                                                        filterComponent: ({ columnDef, onFilterChanged }) => (
                                                                            <FilterComponentBox pageName={'needsplanned'} onFilterChanged={onFilterChanged} columnDef={columnDef} columnName={columnDef.field} />
                                                                        ),
                                                                        render: rowData => <a className={rowData.sameTrailerMarker === 1 &&  rowData.invoiceIsExpedited === 'Yes' ? 'markerExp' : rowData.invoiceIsExpedited === 'Yes' ? 'expeditedClass' : rowData.sameTrailerMarker === 1 ? 'sameMarker' : ''} target="_blank" href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID}>{rowData.invoicePromisedTimeline}</a>
                                                                    },

                                                                    {
                                                                        title: "Prom Date", field: "invoicePromisedDate",
                                                                        defaultSort: cookiesi.getAll()['orderBy'] == 'invoicePromisedDate' ? cookiesi.getAll()['direction'] : false,
                                                                        filterComponent: (props) => <CustomDatePicker pageName={'needsplanned'} {...props} />,
                                                                        type: 'date', render: rowData => <a className={rowData.sameTrailerMarker === 1 &&  rowData.invoiceIsExpedited === 'Yes' ? 'markerExp' : rowData.invoiceIsExpedited === 'Yes' ? 'expeditedClass' : rowData.sameTrailerMarker === 1 ? 'sameMarker' : ''} target="_blank" href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID}>{(rowData.invoicePromisedDate) ? moment(rowData.invoicePromisedDate).format('MM/DD/YY') : ''}</a>
                                                                    },

                                                                    {
                                                                        title: "Inv Num",
                                                                        cellStyle: {
                                                                            display: 'none'
                                                                        },

                                                                        headerStyle: {
                                                                            display: 'none'
                                                                        },

                                                                        field: "invoiceRefNumber", render: rowData => <a href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID}>{rowData.invoiceRefNumber}</a>
                                                                    },

                                                                    { render: rowData => <button onClick={() => (despatchLoading) ? <button disabled class="btn btn-primary custome-btn">Dispatch</button> : doDispatch(rowData.InvoiceRefNumber, rowData.Product, rowData.orderStatusRecordID)} class="btn btn-primary custome-btn">Dispatch</button> }


                                                                ]}

                                                                data={query =>
                                                                    new Promise((resolve, reject) => {
                                                                        const cookies = new Cookies();
                                                                        cookies.remove('adminer_version');

                                                                        console.log(Object.keys(cookies.getAll()).length, 'queryFilterslength');

                                                                        if (query.filters.length > 0 || Object.keys(cookies.getAll()).length > 0) {
                                                                            let url = API_BASE_URL + `/fetchOrderBasedOnColumn?`;
                                                                            url += 'size=' + query.pageSize;
                                                                            url += '&page=' + (query.page + 1);
                                                                            url += '&orderNumber=' + 16;
                                                                            if (query.orderBy) {
                                                                                removeCookie("orderBy", { path: "/needsplanned" });
                                                                                removeCookie("direction", { path: "/needsplanned" });
                                                                                if (query.orderBy.field == 'invoiceRefNumber') {
                                                                                    setCookie("orderBy", "invoiceCustomerFullName", { path: '/needsplanned' });
                                                                                }
                                                                                else {
                                                                                    setCookie("orderBy", query.orderBy.field, { path: '/needsplanned' });


                                                                                }
                                                                                setCookie("direction", query.orderDirection, { path: '/needsplanned' });
                                                                            }
                                                                            else {
                                                                                url += '&sortField=invoicePromisedDate';
                                                                                url += '&sortType=asc';
                                                                                url += '&sortField=invoiceCustomerFullName';
                                                                                url += '&sortType=asc';
                                                                                url += '&sortField=resalePORecordID';
                                                                                url += '&sortType=asc';
                                                                            }

                                                                            if (Object.keys(cookies.getAll()).length > 0) {

                                                                                for (const key in cookies.cookies) {

                                                                                    console.log(moment.isDate(cookies.cookies[key].replace('"', '').replace('"', '')), 'momentinfo', cookies.cookies[key], key);
                                                                                    if(cookies.cookies[key] == 'Invalid date')
                                                                                    {
                                                                                        console.log('okkk')
                                                                                         removeCookie(key, { path: "/needsplanned" });
                                                                                    }
                                                                                    
                                                                                    else
                                                                                    {
                                                                                        if (cookies.cookies[key] && moment.isDate(cookies.cookies[key])) {
                                                                                        cookies.cookies[key] = moment(cookies.cookies[key]).format('YYYY-MM-DD')
                                                                                    }

                                                                                    if (cookies.cookies[key]) {
                                                                                        if (key == 'invoiceRefNumber' && isNaN(cookies.cookies[key])) {
                                                                                            url += '&columnName=' + 'invoiceCustomerFullName';
                                                                                            if (cookies.cookies[key].toLowerCase() == 'yes') {
                                                                                                url += '&search=' + 1;
                                                                                            } else if (cookies.cookies[key].toLowerCase() == 'no') {
                                                                                                url += '&search=' + 0;
                                                                                            } else {
                                                                                                url += '&search=' + encodeURIComponent(cookies.cookies[key]);
                                                                                            }
                                                                                        }
                                                                                        else if (key == 'orderBy') {

                                                                                            url += '&sortField=' + cookies.cookies[key]

                                                                                            // url += '&sortType=' + cookies.cookies['direction']
                                                                                        }
                                                                                        else if (key == 'direction') {
                                                                                            url += '&sortType=' + cookies.cookies[key]
                                                                                        }
                                                                                        else {
                                                                                            url += '&columnName=' + key;
                                                                                            if (cookies.cookies[key].toLowerCase() == 'yes') {
                                                                                                url += '&search=' + 1;
                                                                                            } else if (cookies.cookies[key].toLowerCase() == 'no') {
                                                                                                url += '&search=' + 0;
                                                                                            }

                                                                                            else {
                                                                                                url += '&search=' + encodeURIComponent(cookies.cookies[key]);
                                                                                            }
                                                                                        }
                                                                                    } 
                                                                                    }

                                                                            
                                                                                }
                                                                            } else {
                                                                                query.filters.forEach((item) => {
                                                                                    if (item.value && moment.isDate(item.value)) {
                                                                                        item.value = moment(item.value).format('YYYY-MM-DD')
                                                                                    }

                                                                                    if (item.column.field == 'invoiceRefNumber' && isNaN(parseInt(item.value))) {
                                                                                        url += '&columnName=' + 'invoiceCustomerFullName';
                                                                                        url += '&search=' + encodeURIComponent(item.value);
                                                                                    } else {
                                                                                        url += '&columnName=' + item.column.field;
                                                                                        url += '&search=' + encodeURIComponent(item.value);
                                                                                    }
                                                                                });
                                                                            }


                                                                            axios.get(url, {
                                                                                headers: {
                                                                                    Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN_NAME),
                                                                                },
                                                                            }).then(result => {
                                                                                resolve({
                                                                                    data: result.data.rows.map((data) => ({
                                                                                        RecordID: data.invoiceRecordID,
                                                                                        orderStatusRecordID: data.recordID,
                                                                                        Customer: `${data.invoiceCustomerFullName.replace(',', ' ')}*${data.invoiceRefNumber}`,
                                                                                        invoiceRefNumber: data.invoiceRefNumber,
                                                                                        InvoiceDate: (data.invoiceTxnDate) ? data.invoiceTxnDate?.split('T')[0] : '',
                                                                                        invoicePaidDate: (data.invoicePaidDate) ? data.invoicePaidDate?.split('T')[0] : '',
                                                                                        invoiceLineItemItemNameWithoutParent: data.invoiceLineItemItemNameWithoutParent,
                                                                                        invoiceAssignedToName: data.invoiceAssignedToName,
                                                                                        invoiceCustomerNotes: data?.invoiceCustomerNotes,
                                                                                        invoiceTerms: data.invoiceTerms,
                                                                                        Balance: (data.invoiceBalanceRemaining) ? data.invoiceBalanceRemaining : '',
                                                                                        invoiceSupplierLocation: data.invoiceSupplierLocation,
                                                                                        resalePORefNumer: data.resalePORefNumer,
                                                                                        resalePOReleaseNumber: data.resalePOReleaseNumber,
                                                                                        invoiceShipMethodMethodFieldName: data?.invoiceShipMethodMethodFieldName,
                                                                                        resalePOExpectedDate: data.resalePOExpectedDate,
                                                                                        resalePOVendor: data.resalePOVendor,
                                                                                        resalePOTotalPrice: data.resalePOTotalPrice,
                                                                                        invoiceShipAddress1: data.invoiceShipAddress1,
                                                                                        invoiceShipAddress2: data.invoiceShipAddress2,
                                                                                        invoiceShipAddress3: data.invoiceShipAddress3,
                                                                                        resalePOLineItemContainerNumber: data.resalePOLineItemContainerNumber,
                                                                                        invoiceShipCity: data.invoiceShipCity,
                                                                                        invoiceShipZip: data.invoiceShipZip,
                                                                                         newDistance: data.newDistance ? data.newDistance : null,
																						//resalePOLineItemDistance: (data.resalePOLineItemDistance && data.resalePOLineItemDistance != 0) ? data.resalePOLineItemDistance : (data.purchaseOrder) ? data.purchaseOrder.distanceBetweenPickupAndDeliveryAddress : null,
                                                                                        invoiceIsExpedited: (data.invoiceIsExpedited) ? 'Yes' : 'No',
                                                                                        invoicePromisedTimeline: (data.invoicePromisedTimeline) ? data.invoicePromisedTimeline : '',
                                                                                        invoicePromisedDate: (data.invoicePromisedDate) ? data.invoicePromisedDate?.split('T')[0] : '',
                                                                                        resalePOPickupAddress1: data.resalePOPickupAddress1,
                                                                                        resalePOPickupCity: data.resalePOPickupCity,
                                                                                        resalePOPickupZipCode: data.resalePOPickupZipCode,
                                                                                        ResalePORecordID: data.resalePORecordID,
                                                                                        resalePOLastDepotCallDate: (data.resalePOLastDepotCallDate) ? data.resalePOLastDepotCallDate?.split('T')[0] : '',
                                                                                        resalePOReleaseDate: (data.resalePOReleaseDate) ? data.resalePOReleaseDate?.split('T')[0] : '',

                                                                                        shipCity: (data.invoiceShipCity) ? data.invoiceShipCity : '',
                                                                                        shipState: (data.invoiceShipState) ? data.invoiceShipState : '',
                                                                                        plannedStatus: (data.planning && data.planning.plannedStatus == 1) ? 'Yes' : 'No',

                                                                                        solidSurface: (data.invoice?.solidSurface) ? data.invoice.solidSurface : 0,
                                                                                        towTruck: (data.invoice?.towTruck) ? data.invoice.towTruck : 0,

                                                                                        invoiceShipAddress1: data.invoiceShipAddress1,
                                                                                        invoiceShipAddress2: data.invoiceShipAddress2,
                                                                                        invoiceShipAddress3: data.invoiceShipAddress3,
                                                                                        invoiceShipCity: data.invoiceShipCity,
                                                                                        invoiceShipZip: data.invoiceShipZip,
                                                                                        resalePOPickupAddress1: data.resalePOPickupAddress1,
                                                                                        resalePOPickupCity: data.resalePOPickupCity,
                                                                                        resalePOPickupZipCode: data.resalePOPickupZipCode,
                                                                                        sameTrailerMarker: data.sameTrailerMarker,
                                                                                        invoiceLineItem_RecordID: data.invoiceLineItem_RecordID,

                                                                                        DeliveryStatus: data.transportPODeliveryStatus,
                                                                                        PromisedTimeline: (data.invoicePromisedTimeline) ? data.invoicePromisedTimeline : '',
                                                                                        PromisedDate: (data.invoicePromisedDate) ? data.invoicePromisedDate?.split('T')[0] : '',
                                                                                    })),
                                                                                    page: result.data.currentPage - 1,
                                                                                    totalCount: result.data.totalItems,
                                                                                });
                                                                                setPageRefresh(false);
                                                                            });
                                                                        } else {
                                                                            let url = API_BASE_URL + `/fetchCompletedOrder?`;
                                                                            url += 'size=' + query.pageSize;
                                                                            url += '&page=' + (query.page + 1);
                                                                            url += '&orderNumber=' + 16;
                                                                            if (query.orderBy) {
                                                                                if (query.orderBy.field == 'invoiceRefNumber') {
                                                                                    url += '&sortField=invoiceRefNumber'
                                                                                    setCookie("orderBy", "invoiceCustomerFullName", { path: '/needsplanned' });
                                                                                }
                                                                                else {
                                                                                    setCookie("orderBy", query.orderBy.field, { path: '/needsplanned' });
                                                                                    url += '&sortField=' + query.orderBy.field

                                                                                }
                                                                                setCookie("direction", query.orderDirection, { path: '/needsplanned' });
                                                                                url += '&sortType=' + query.orderDirection
                                                                            } else {
                                                                                switch (2) {
                                                                                    case '2':
                                                                                        url += '&sortField=invoicePromisedDate';
                                                                                        url += '&sortType=asc';
                                                                                        url += '&sortField=invoiceCustomerFullName';
                                                                                        url += '&sortType=asc';
                                                                                        url += '&sortField=resalePORecordID';
                                                                                        url += '&sortType=asc';
                                                                                        break;
                                                                                    case '7':
                                                                                        url += '&sortField=invoicePromisedDate';
                                                                                        url += '&sortType=asc';
                                                                                        url += '&sortField=invoiceCustomerFullName';
                                                                                        url += '&sortType=asc';
                                                                                        url += '&sortField=resalePORecordID';
                                                                                        url += '&sortType=asc';
                                                                                        break;
                                                                                    case '15':
                                                                                        url += '&sortField=invoicePromisedDate';
                                                                                        url += '&sortType=asc';
                                                                                        url += '&sortField=invoiceCustomerFullName';
                                                                                        url += '&sortType=asc';
                                                                                        url += '&sortField=resalePORecordID';
                                                                                        url += '&sortType=asc';
                                                                                        break;
                                                                                    case '10':
                                                                                        url += '&sortField=invoicePromisedDate';
                                                                                        url += '&sortType=asc';
                                                                                        url += '&sortField=invoiceCustomerFullName';
                                                                                        url += '&sortType=asc';
                                                                                        url += '&sortField=resalePORecordID';
                                                                                        url += '&sortType=asc';
                                                                                        break;
                                                                                    default:
                                                                                        url += '&sortField=invoicePromisedDate';
                                                                                        url += '&sortType=asc';
                                                                                        url += '&sortField=invoiceCustomerFullName';
                                                                                        url += '&sortType=asc';
                                                                                        url += '&sortField=resalePORecordID';
                                                                                        url += '&sortType=asc';
                                                                                }
                                                                            }

                                                                            if (query.search) {
                                                                                url += '&search=' + encodeURIComponent(query.search);
                                                                            }

                                                                            if (query.filters && !query.search) {
                                                                                query.filters.forEach((item) => {
                                                                                    url += '&search=' + encodeURIComponent(item.value);
                                                                                });
                                                                            }

                                                                            if (props.endDate && props.startDate) {
                                                                                url += '&from=' + props.startDate;
                                                                                url += '&to=' + props.endDate;
                                                                            }

                                                                            axios.get(url, {
                                                                                headers: {
                                                                                    Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN_NAME),
                                                                                },
                                                                            }).then(result => {
                                                                                resolve({
                                                                                    data: result.data.rows.map((data) => ({
                                                                                        RecordID: data.invoiceRecordID,
                                                                                        orderStatusRecordID: data.recordID,
                                                                                        Customer: `${data.invoiceCustomerFullName.replace(',', ' ')}*${data.invoiceRefNumber}`,
                                                                                        invoiceRefNumber: data.invoiceRefNumber,
                                                                                        InvoiceDate: (data.invoiceTxnDate) ? data.invoiceTxnDate?.split('T')[0] : '',
                                                                                        invoicePaidDate: (data.invoicePaidDate) ? data.invoicePaidDate?.split('T')[0] : '',
                                                                                        invoiceLineItemItemNameWithoutParent: data.invoiceLineItemItemNameWithoutParent,
                                                                                        invoiceAssignedToName: data.invoiceAssignedToName,
                                                                                        invoiceCustomerNotes: data?.invoiceCustomerNotes,
                                                                                        invoiceTerms: data.invoiceTerms,
                                                                                        Balance: (data.invoiceBalanceRemaining) ? data.invoiceBalanceRemaining : '',
                                                                                        invoiceSupplierLocation: data.invoiceSupplierLocation,
                                                                                        resalePORefNumer: data.resalePORefNumer,
                                                                                        resalePOReleaseNumber: data.resalePOReleaseNumber,
                                                                                        invoiceShipMethodMethodFieldName: data?.invoiceShipMethodMethodFieldName,
                                                                                        resalePOExpectedDate: data.resalePOExpectedDate,
                                                                                        resalePOVendor: data.resalePOVendor,
                                                                                        resalePOTotalPrice: data.resalePOTotalPrice,
                                                                                        invoiceShipAddress1: data.invoiceShipAddress1,
                                                                                        invoiceShipAddress2: data.invoiceShipAddress2,
                                                                                        invoiceShipAddress3: data.invoiceShipAddress3,
                                                                                        invoiceShipCity: data.invoiceShipCity,
                                                                                        invoiceShipZip: data.invoiceShipZip,
                                                                                        sameTrailerMarker: data.sameTrailerMarker,
																						 newDistance: data.newDistance ? data.newDistance : null,
                                                                                       // resalePOLineItemDistance: (data.resalePOLineItemDistance && data.resalePOLineItemDistance != 0) ? data.resalePOLineItemDistance : (data.purchaseOrder) ? data.purchaseOrder.distanceBetweenPickupAndDeliveryAddress : null,
                                                                                        invoiceIsExpedited: (data.invoiceIsExpedited) ? 'Yes' : 'No',
                                                                                        invoicePromisedTimeline: (data.invoicePromisedTimeline) ? data.invoicePromisedTimeline : '',
                                                                                        invoicePromisedDate: (data.invoicePromisedDate) ? data.invoicePromisedDate?.split('T')[0] : '',
                                                                                        resalePOPickupAddress1: data.resalePOPickupAddress1,
                                                                                        resalePOPickupCity: data.resalePOPickupCity,
                                                                                        resalePOPickupZipCode: data.resalePOPickupZipCode,
                                                                                        resalePOLineItemContainerNumber: data.resalePOLineItemContainerNumber,
                                                                                        ResalePORecordID: data.resalePORecordID,
                                                                                        resalePOLastDepotCallDate: (data.resalePOLastDepotCallDate) ? data.resalePOLastDepotCallDate?.split('T')[0] : '',
                                                                                        resalePOReleaseDate: (data.resalePOReleaseDate) ? data.resalePOReleaseDate?.split('T')[0] : '',

                                                                                        shipCity: (data.invoiceShipCity) ? data.invoiceShipCity : '',
                                                                                        shipState: (data.invoiceShipState) ? data.invoiceShipState : '',
                                                                                        plannedStatus: (data.planning && data.planning.plannedStatus == 1) ? 'Yes' : 'No',

                                                                                        solidSurface: (data.invoice?.solidSurface) ? data.invoice.solidSurface : 0,
                                                                                        towTruck: (data.invoice?.towTruck) ? data.invoice.towTruck : 0,

                                                                                        invoiceShipAddress1: data.invoiceShipAddress1,
                                                                                        invoiceShipAddress2: data.invoiceShipAddress2,
                                                                                        invoiceShipAddress3: data.invoiceShipAddress3,
                                                                                        invoiceShipCity: data.invoiceShipCity,
                                                                                        invoiceShipZip: data.invoiceShipZip,
                                                                                        resalePOPickupAddress1: data.resalePOPickupAddress1,
                                                                                        resalePOPickupCity: data.resalePOPickupCity,
                                                                                        resalePOPickupZipCode: data.resalePOPickupZipCode,

                                                                                        invoiceLineItem_RecordID: data.invoiceLineItem_RecordID,

                                                                                        DeliveryStatus: data.transportPODeliveryStatus,
                                                                                        PromisedTimeline: (data.invoicePromisedTimeline) ? data.invoicePromisedTimeline : '',
                                                                                        PromisedDate: (data.invoicePromisedDate) ? data.invoicePromisedDate?.split('T')[0] : '',

                                                                                    })),
                                                                                    page: result.data.currentPage - 1,
                                                                                    totalCount: result.data.totalItems,
                                                                                });
                                                                                setPageRefresh(false);
                                                                            })
                                                                        }
                                                                    })
                                                                }

                                                                title="Order"
                                                                options={{
                                                                    columnsButton: false,
                                                                    actionsColumnIndex: -1,

                                                                    // rowStyle: rowData => ({
                                                                    //     backgroundColor: (rowData.invoiceTerms == "Rent To Own" && rowData.solidSurface == 1 ) ? '#90EE90' : (rowData.invoiceTerms == "Rent To Own") ? '#ffda73' : (rowData.solidSurface == 1) ? '#7bede9' : '#fff'
                                                                    // }),
                                                                    rowStyle: rowData => ({
                                                                        backgroundColor: (rowData.invoiceTerms == "Rent To Own" && rowData.towTruck == 1 ) ? '#F7AE77' : (rowData.invoiceTerms == "Rent To Own" && rowData.solidSurface == 1 ) ? '#90EE90' : (rowData.towTruck == 1 && rowData.solidSurface == 1 ) ? '#F0BBF0' : (rowData.invoiceTerms == "Rent To Own") ? '#ffda73' : (rowData.solidSurface == 1) ? '#7bede9' : (rowData.towTruck == 1) ? 'pink' : '#fff'
                                                                    }),
                                                                    selection: false,
                                                                    toolbar: true,
                                                                    showTitle: false,
                                                                    search: true,
                                                                    thirdSortClick: false,
                                                                    sorting: true,
                                                                    paging: true,
                                                                    filtering: true,
                                                                    exportButton: false,
                                                                    pageSize: 50,
                                                                    pageSizeOptions: [50, 100, 200],
                                                                    // pageSizeOptions: [25, 50, 100, 200, { value: rowsPerPage.data, label: 'All' }],
                                                                    // headerStyle: {
                                                                    //     backgroundColor: '#ef3028',
                                                                    //     color: '#FFF'
                                                                    //   }, 
                                                                }}

                                                                components={{
                                                                    Toolbar: props => (
                                                                        <ResetFillterButton elsement={props} resetFilter={resetFilter} />
                                                                    ),
                                                                }}

                                                                actions={[
                                                                    // {
                                                                    //     icon: 'save',
                                                                    //     tooltip: 'Save User',
                                                                    //     // onClick: (event, rowData) => alert("You saved " + rowData.invoiceCustomerFullName)
                                                                    //     onClick: () => handleView()
                                                                    // },
                                                                    // {
                                                                    //     icon: 'edit',
                                                                    //     tooltip: 'Edit ',
                                                                    //     onClick: (event, rowData) => 
                                                                    //       <Link to={`/vendordetails`}>Edit</Link> 
                                                                    //   }
                                                                    // {
                                                                    //     icon: 'save',
                                                                    //     tooltip: 'Save User',
                                                                    //     onClick: (event, rowData) => alert("You saved " + rowData.name)
                                                                    // }
                                                                ]}
                                                            />
                                                        </div>



                                                    </div>
                                                </div>

                                            </div>

                                            <Footer />

                                            <style jsx="true">{`
                                                .title-strong {
                                                    font-weight: 550
                                                }
                                                tr td:first-child.MuiTableCell-root a, tr th:first-child span.MuiButtonBase-root.MuiTableSortLabel-root {
                                                    width: 100%!important;
                                                }

                                                .btn-set{
                                                    position: absolute;
                                                    top: 18px;
                                                    font-size: 17px;
                                                    right: 255px;
                                                    color: #000;
                                                    padding: 4px 6px;
                                                    border: 1px solid #79a3ff;
                                                }

                                                .input-wid-set {
                                                    border: none;
                                                    border-bottom: 1px solid #949494;
                                                    position: relative;
                                                    top: 3px;
                                                    width: 100%;
                                                }
                                                

                                                .MuiTableCell-alignLeft {
                                                    z-index:unset !important;
                                                }

                                                .MuiTableCell-root{
                                                    padding: 2px !important;
                                                    font-family: 'Source Sans Pro', sans-serif;
                                                    width: auto !important;
                                                }
                                                .MuiTableCell-root a {
                                                    font-size: 12px !important;
                                                    font-family: 'Source Sans Pro', sans-serif;
                                                    overflow: visible;
                                                    display: block;
                                                    text-overflow: clip;
                                                    word-break: break-word;
                                                    white-space: break-spaces !important;
                                                  }

                                                .MuiTableHead-root th {
                                                    font-size: 12px !important;
                                                    font-weight: 600;
                                                    background: #fbf5f5;
                                                } 
                                                // .MuiTableHead-root th:nth-child(8) {
                                                //     width: 129px!important;
                                                // }
                                                .MuiTableCell-root {
                                                    width: fit-content;
                                                }
                                                .MuiTableCell-root a {
                                                    width: 100%;
                                                }
                                            `}</style>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </div>

    )
}

export default withRouter(NeedsplannedLayout);