import React, { useState, useEffect } from 'react';
import $ from 'jquery';
import moment from 'moment';
import ReportService from './ReportService';

function Table(props) {

    if(props.state.loading) {
      return (<i class="fa fa-spinner fa-spin center-position" />);
    }

    return (
        <div className="table-responsive">
        <table id="reportTable" className="table table-bordered">
          <thead>
            <tr>
              <td className="text-left">Carrier Name</td>
              <td className="text-left">Email</td>
              <td className="text-left">City</td>
              <td className="text-right">State</td>
              <td className="text-right">Dot Number</td>
              <td className="text-right">Expiration date</td>
              <td className="text-right">Contact</td>
            </tr>
          </thead>
          <tbody>
            { 
              props.state.reports.map((item , index) => 
                <tr>
                  <td className="text-left">{item.fullName}</td>
                  <td className="text-right">{item.email}</td>
                  <td className="text-left">{item.vendorAddressCity}</td>
                  <td className="text-left">{item.vendorAddressState}</td>
                  <td className="text-right">{item.usDOTNumber}</td>
                  <td className="text-right">{ (item.insuranceExpirationDate) ? moment(item.insuranceExpirationDate).format('MM/DD/YY') : '' }</td>
                  <td className="text-right">{item.phone}</td>
                </tr>
              )
            }
          </tbody>
        </table>
      </div>
    );
}

export default Table;