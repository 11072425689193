import React, { Component } from 'react';
import InvoiceServices from '../Invoice/Services/InvoiceServices';
import moment from 'moment';
import he from 'he'

class InvoiceTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            initLoading: false,
            invoiceItem: [],
            invoiceChecked: null,
            countR: 0,
            countTPO: 0,
        }
    }

    

    checkedInvoice = (id) => {

        if(this.state.invoiceChecked == id){
            this.setState({ invoiceChecked: null }); 
            this.props.checkedInvoiceRecordID(null);
        }
        else
        {
            this.setState({ invoiceChecked: id });
            this.props.checkedInvoiceRecordID(id);
        }
     
    }



    fetchInvoiceItem = async () => {
        this.setState({
            initLoading: true
        });
        try {
            const result = await InvoiceServices.getInvoiceItem(this.props.invoiceRecordID);
            console.log("reeeeeeeeeee", result);
            let i = 0;
            let recId = '';
            result.forEach(element => {
                if (element.recordID != null && (!(element.item.search("Discount") > 0 || element.item.search("Expedited") > 0))) {
                    i++;
                    recId = element.recordID;
                }

            });


            this.setState({
                invoiceItem: result
            })
            if (i == 1) {
                this.setState({ invoiceChecked: recId });
                this.props.checkedInvoiceRecordID(recId);
            }
        } catch (error) {
            console.log(error, 'error to fetchInvoiceItem');
        }
    }

    componentDidMount() {
        this.fetchInvoiceItem();
    }

    render() {
        return (
            <div className="row">
                <div className="col-sm-12 table-responsive invoice-inner-table">
                    <table className="table table-bordered record-table">
                        <thead>
                            <tr>
                                <th></th>
                                <th>Date &nbsp;</th>
                                <th>Item</th>
                                <th className="breakwordwichpadding">Description</th>
                                <th>QTY</th>
                                <th>Rate</th>
                                <th>Amount</th>
                                <th>Tax</th>
                                <th>Refunded</th>
                                <th>Supplier Location </th>
                                <th>Res PO#</th>
                                <th>Accessories</th>
                                <th>Tier</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.state.invoiceItem.map((element) => (

                                    <tr>
                                        <td>
                                            {
                                                (element.recordID && (!(element.item.search("Discount") > 0 || element.item.search("Expedited") > 0)) && element.transportPORecordID)
                                                ?
                                                <label class="checkbox" >
                                                <input type="checkbox" checked disabled/>
                                                <span class="checkmark"></span>
                                            </label>
                                                // <input type="checkbox" className='disabledColor' onChange={(e) => {
                                                //     this.checkedInvoice(
                                                //         element.recordID
                                                //     )
                                                // }} value={element.recordID} 
                                                // disabled />
                                                :
                                                (element.recordID && (!(element.item.search("Discount") > 0 || element.item.search("Expedited") > 0)))
                                                    ?
                                                 
                                                    <label class="checkbox active" >
                                                <input type="checkbox" 
                                                onChange={(e) => {
                                                        this.checkedInvoice(
                                                            element.recordID
                                                        )
                                                    }} value={element.recordID} checked={this.state.invoiceChecked != null && this.state.invoiceChecked == element.recordID ? "checked" : ""}
                                                />
                                                <span class="checkmark"></span>
                                            </label>
                                                    // <input type="checkbox" onChange={(e) => {
                                                    //     this.checkedInvoice(
                                                    //         element.recordID
                                                    //     )
                                                    // }} value={element.recordID} checked={this.state.invoiceChecked != null && this.state.invoiceChecked == element.recordID ? "checked" : ""} />
                                                    :
                                                    ''

                                            }


                                        </td>
                                        <td>{moment(this.props.txnDate?.split('T')[0]).format('ll')}</td>
                                        <td>{element.item}</td>
                                        <td>{element.descr}</td>
                                        <td>{element.quantity}</td>
                                        <td>{element.rate}</td>
                                        <td>{element.totalAmount}</td>
                                        <td>Non</td>
                                        <td className='textCenter'>
                                            {
                                                (element.isRefunded) ? <input disabled checked type="checkbox" /> : <input disabled type="checkbox" />
                                            }
                                        </td>
                                        <td>{element.supplierLocation}</td>
                                        <td>{element.resalePORefNumer}</td>
                                        <td className='textCenter'>
                                            {
                                                (element.accessoryPOCreated) ? <input disabled checked type="checkbox" /> : <input disabled type="checkbox" />
                                            }
                                        </td>
                                        <td className='textCenter'>
                                            <input disabled type="checkbox" />
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
            </div >
        );
    }
}

export default InvoiceTable;