import { useField } from 'formik';
import PropType from 'prop-types';
import React, { useEffect, useState } from 'react';
import CreatableSelect from 'react-select/creatable';
import PurchaseFormServices from '../../PurchaseOrder/Services/PurchaseFormServices';

import { useFormikContext } from 'formik';

const CustomVendor = (props) => {
  const [field, meta, helpers] = useField(props);
  const [overrideStatus, setOverrideStatus] = useState(0);
  const {
    options, match, handleVendorChange, defaultValue, label, placeholder, isMulti, type, iid
  } = props;

  const { touched, error } = meta;
  const { setValue } = helpers;

  const { setFieldValue } = useFormikContext();

  useEffect(() => {
    setFieldValue('driver', defaultValue.driverid)
    setOverrideStatus(defaultValue.overrideStatus)
    
  }, []);

  const handleChange = async (newValue) => {
    const newoverrideStatus = await PurchaseFormServices.getOverrideStatus(newValue.vendorid);
    await setOverrideStatus(newoverrideStatus.overrideStatus)
    console.log("overrideStatus", newoverrideStatus.overrideStatus)
    console.log("overrideStatus", overrideStatus)
    let vendor;
    if (props.matchinfo == '/exitingPurchaseorder/:id/:recordID') {
      vendor = await PurchaseFormServices.fetchAllDriverByVendor(newValue.vendorid);
    } else {
      vendor = await PurchaseFormServices.fetchActiveDriverByVendor(newValue.vendorid);
    }

    
    props.newFun(true)

    //setFieldValue('billing', vendor[0]);
    setFieldValue('vendorid', newValue.vendorid);
    setFieldValue('driver', null)
    console.log('cheeeeeeeeeee', vendor[0])
    if (vendor[0]) {
      props.fun(vendor, newValue.vendorid);
    }

    if (Array.isArray(newValue)) {
      const arr = newValue.map((fieldKey) => fieldKey.value);
      setValue(arr);
    } else {
      setValue(newValue.value);
    }
  };

  const customFilterOption = (option, rawInput) => {
    return (
      (option.data.label && option.data.label.toLowerCase().match(rawInput.toLowerCase()) || option.data.value.toLowerCase().match(rawInput.toLowerCase())) ? true : false
    )
  };

  const handleKeyDown = (e) => {
    if (type === 'number') {
      const { key } = e.nativeEvent;
      if (/\D/.test(key) && key !== 'Backspace') {
        e.preventDefault();
      }
    }
  };


  return (
    <div className="form-group">
      <label htmlFor={field.name}>{label} </label>
      {
        overrideStatus ==1 ?
        <span style={{ color:"Red",float:"right",fontWeight:600 }}>Disqualified active</span>
        : 
        ''
      }
      

      <CreatableSelect
        isMulti={isMulti}
        placeholder={placeholder}
        name={field.name}
        filterOption={customFilterOption}
        getOptionLabel={option => <p>{option.label} <br /><br />{option.name} <br /> Vendor </p>}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        defaultValue={options.find((item) => item.value == defaultValue.value)}
        options={options}
        instanceId={iid}
      />
      {/* <hr style={{ borderColor: "Red",border:"4 px" }}/>
   */}
      {touched && error ? (
        <small style={{ color: 'red' }}>{error}</small>
      ) : null}
    </div>
   
  );
};

CustomVendor.defaultProps = {
  isMulti: false,
  placeholder: '',
  iid: '',
  options: [],
  type: 'string'
};

CustomVendor.propTypes = {
  options: PropType.arrayOf(PropType.object),
  defaultValue: PropType.oneOfType([
    PropType.object,
    PropType.array
  ]).isRequired,
  label: PropType.string.isRequired,
  placeholder: PropType.string,
  isMulti: PropType.bool,
  type: PropType.string,
  iid: PropType.string
};

export default CustomVendor;
