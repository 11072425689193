import React, { useContext, useState } from 'react';
import { useFormikContext } from 'formik';
import { FormContext } from '../context/FormContext';

const FormState = () => {
  const context = useContext(FormContext);
  const { values, submitForm } = useFormikContext();
  context.setFormvalue(values);
  return true
};


export default FormState;

