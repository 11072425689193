import React, { useContext, useEffect, useState, useRef } from 'react';
import DatePicker from "react-datepicker";
import { LocationContext } from "../../context/LocationContext";
import { useCookies } from 'react-cookie';
import Cookies from 'universal-cookie';


function LocationDriver(props) {
    const [cookies, setCookie, removeCookie] = useCookies(['driverPlanning']);
    const cookie = new Cookies();
    const textInput = useRef(null);

    const { updateslectedLocation, slectedLocation } = useContext(LocationContext);
    const [selectLocation, SetselectLocation] = useState(0);

    const [search, setTearch] = useState(null);

    function addDriverNameCookies(id) {
        if (id > 0) {
            setCookie('driverName', id, { path: '/driverPlanning' });
        }
        else {
            removeCookie("driverName", { path: "/driverPlanning" });
        }
    }

    function addDriverLocationCookies(id) {
        if (id > 0) {
            setCookie('driverLocation', id, { path: '/driverPlanning' });
        }
        else {
            removeCookie("driverLocation", { path: "/driverPlanning" });
        }
    }

    const onFormSubmit = e => {
        e.preventDefault();
        props.search(search);
        // send state to server with e.g. `window.fetch`
    }

    // const handleSubmit()
    // {

    // }



    useEffect(() => {
            const cookies1 = new Cookies();
        if(Object.keys(cookies1.getAll()).length > 0){

            // for (const key in cookies1.cookies) {

            //     if (key == 'driverLocation') {
            //         console.log("hellooooo", cookies1.cookies[key]);
            //         SetselectLocation(cookies1.cookies[key]);
            //     }
            // }
        //     if(cookie.getAll().driverLocation)
        // {
        //     console.log(cookie.getAll().driverLocation, 'driverLocation');
        //     SetselectLocation(cookie.getAll().driverLocation);
        //     updateslectedLocation(cookie.getAll().driverLocation);
        // }
        // if(cookie.getAll().driverName)
        // {
        //     console.log(cookie.getAll().driverName, 'driverName');
        //     props.changeDriver(cookie.getAll().driverName)
        //     // SetselectLocation(cookie.getAll().driverLocation);
        //     // updateslectedLocation(cookie.getAll().driverLocation);
        // }
        }
        



    }, []);





    return (
        <div className="row planning-page-filter-header">
            <div className="col-md-12 col-sm-12 col-xs-12">
                <div className="d-flex justify-content-between" style={{ alignItems: 'end' }}>
                    <div className="form-group d-flex  text-left" style={{ alignItems: 'baseline' }}>

                        <label><b style={{ margin: '5px' }}> Location</b></label>&nbsp;
                        <select defaultValue={selectLocation} id="driverLocation" onChange={(e) => {
                            removeCookie("driverName", { path: "/driverPlanning" });
                            addDriverLocationCookies(e.target.value)
                            updateslectedLocation(e.target.value);

                            SetselectLocation(e.target.value)
                        }} className="form-control">
                            {
                                props.location.map((item, index) => (index == 0 || item.supplierlocationid == slectedLocation?.locationid || item.supplierlocationid == cookie.getAll().driverLocation) ? <option selected id={item.supplierlocationid} value={item.supplierlocationid}>{item.supplierloctionname}</option> : <option id={item.supplierlocationid} value={item.supplierlocationid}>{item.supplierloctionname}</option>)
                            }

                        </select>
                    </div>
                    <div className="form-group d-flex  text-left" style={{ alignItems: 'baseline' }}>
                        <label><b style={{ margin: '5px' }}>Driver </b> </label>&nbsp;&nbsp;
                        <select onChange={(e) => {
                            addDriverNameCookies(e.target.value)

                            props.handleDriverChange(e);
                        }
                        } name="Supplier location" id="supplier location" className="form-control">
                            <option id="all" value="all">All</option>
                            {
                                props.driver.map((item) => (item.contactId == cookie.getAll().driverName) ? <option selected id={item.contactId} value={item.contactId}>{item.drivername}</option> : <option id={item.contactId} value={item.contactId}>{item.drivername}</option>)
                            }
                        </select>
                    </div>
                    <div className="form-group d-flex  text-left" style={{ alignItems: 'baseline' }}>
                        <label><b style={{ margin: '5px' }}>Date</b></label>&nbsp;&nbsp;
                        <DatePicker className="form-control" selected={(cookie.getAll().planningDate) ? new Date(cookie.getAll().planningDate) : props.dateinfo} onChange={(e) => {
                            setCookie('planningDate', e, { path: '/driverPlanning' });

                            props.handelDate(e);
                        }
                        } />
                    </div>

                    <div className="d-flex flex-wrap justify-content-between" style={{ alignItems: 'end' }}>
                        <div className="form-group d-flex  text-left" style={{ alignItems: 'baseline' }}>
                            <label><b style={{ margin: '5px' }}>Dispatcher</b></label>&nbsp;&nbsp;
                            <select onChange={(e) => {
                                // setCookie('planningDispacher', e.target.value, { path: '/driverPlanning' });
                                removeCookie("driverLocation", { path: "/driverPlanning" });
                                removeCookie("driverName", { path: "/driverPlanning" });
                                removeCookie("planningDate", { path: "/driverPlanning" });

                                props.handleDispatcher(e);
                            }
                            } className="form-control">
                                {
                                    props.activeUser.map((item) => <option value={item.id}>{item.name}</option>)
                                }
                            </select>
                        </div>
                    </div>

                    <div className='searchArea'>
                        <div className="form-row">
                            <div style={{ marginBottom: '0px' }} className="form-group col-md-10">
                                <div className="input-group">
                                    {
                                        <>
                                            <form style={{ "display": "flex" }} onSubmit={onFormSubmit}>
                                                <input ref={textInput} value={search} onChange={(e) => setTearch(e.target.value)} placeholder="Search here.." type="text" className="form-control" />
                                                <button type="submit" style={{ "cursor": "pointer" }} className="input-group-prepend"><i class="fa fa-search search-icon-set input-group-text" aria-hidden="true"></i></button>
                                            </form>
                                        </>
                                    }
                                </div>
                            </div>
                            <a href="javascript:void(0)" onClick={(e) => {
                                textInput.current.value = null;
                                removeCookie("driverLocation", { path: "/driverPlanning" });
                                removeCookie("driverName", { path: "/driverPlanning" });
                                removeCookie("planningDate", { path: "/driverPlanning" });
                                removeCookie("planningDispacher", { path: "/driverPlanning" });
                                SetselectLocation('');
                                setTearch(null);
                                document.getElementById("driverLocation").selectedIndex = 0;
                                updateslectedLocation(0);
                                SetselectLocation(0);
                                props.handelDate(new Date());
                                props.storeLocation(false);
                            }} className='col-md-2 text-center'><i class="fa fa-bars btn-set" aria-hidden="true"></i></a>
                        </div>
                    </div>



                    {/* <div style={{ width: '28em' }}>
                        <div className="form-row">
                            <div style={{ marginBottom: '0px' }} className="form-group col">
                                <div className="input-group">
                                    
                                        
                                            <div className="input-group-prepend"><span className="input-group-text" /></div>
                                            <input placeholder="Search here.." type="text" className="form-control" />
                                        
                                    
                                </div>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
        </div>
    );
}

export default LocationDriver;