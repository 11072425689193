import React, { useState, useEffect } from "react";
import axios from "axios";
import { useHistory, useParams, Link } from "react-router-dom";
import { API_BASE_URL, ACCESS_TOKEN_NAME, ACCESS_TOKEN_NAME_USER } from "../../constants/apiConstants";
import swal from "sweetalert";
import logo from '../../images/logo.png';
import profilepic from '../../images/avatar-4.jpg';
import Navbar from "./../Layout/Navbar";
import Sidebar from "./../Layout/Sidebar";
import Footer from "./../Layout/Footer";
import $ from 'jquery';
import Avatar from 'react-avatar';


const Designer = (props) => {
  useEffect(()=>{
    if(!window.location.hash) {
        window.location = window.location + '#loaded';
        window.location.reload();
    }

    $(document).ready(function () {
        setTimeout(function(){
        $('#myTable').DataTable({
            "scrollX": true,
            "responsive":true,
            "order": [[ 1, "desc" ]]
        });
         } ,1000);
    });

  }, []);

 
  let history = useHistory();
  const params = useParams();
  const [state, setState] = useState({
    recordID: "",
    invoiceCustomerFullName : "",
    invoiceRefNumber : "",
    customerAssignedToName : "",
    invoiceBillEmail: "",
    invoiceTxnDate: "",
    dueDate: "",
    terms: "",
    invoiceSupplierLocation: "",
    resalePOPickupPhone: "",
    checked: false,
    addNotes: [],
    resalePOPickupLocationName: "",
  });
  const [invoice, setInvoice] = useState({
    billAddressAddr1: "",
    billAddressAddr2: "",
    billAddressAddr3: "",
    billAddressAddr4: "",
    billAddressAddr5: "",
    billAddressCity: "",
    billAddressCountry: "",
    billAddressPostalCode: "",
    billAddressState: "",
    shipAddressAddr1: "",
    shipAddressAddr2: "",
    shipAddressAddr3: "",
    shipAddressAddr4: "",
    shipAddressAddr5: "",
    shipAddressCity: "",
    shipAddressCountry: "",
    shipAddressNote: "",
    shipAddressPostalCode: "",
    shipAddressState: "",
    customerCallCompletedBy: "",
    customerCallCompletedOn: "",
    vendorNotes: "",
    dispatchNotes: "",
    wooCommerceNumber: "",
  })
  const [notes, setNotes] = useState({
    resalePOCustomerNotes: "",
  })

  useEffect(() => {

    const fetchData = async () => {
        const { id } = params;
        const token = localStorage.getItem(ACCESS_TOKEN_NAME);
        const response = await axios.get(API_BASE_URL + `/findOrderRecordId?id=${id}`, {
          headers: {
            Authorization: "Bearer " + token,
          },
        });
        console.log('invoice',response.data);
        setState(response.data);
        // setInvoice(response.data.invoice);
        // setNotes({resalePOCustomerNotes: response.data.resalePOCustomerNotes})
    }
    fetchData();

  }, []);

    const handleCheck = () => {
        setState({ checked: !state.checked });
    }

  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(name, value);
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // const handleChanges = (e) => {
  //   const { name, value } = e.target;
  //   console.log(name, value);
  //   setInvoice((prevState) => ({
  //     ...prevState,
  //     [name]: value,
  //   }));
  // };

  const handleAddNotes = (e) => {
    e.preventDefault();
    // setNotes({resalePOCustomerNotes: state.addNotes});
    setState({addNotes: ""});
    const { id } = params;
    const payload = {
        resalePOCustomerNotes: notes.resalePOCustomerNotes + state.addNotes + '-' + localStorage.getItem('user'),
    };
    console.log('updatepayload',payload);
    axios
    .post(API_BASE_URL + `/addCustomerNotes?id=${id}`, payload, {
        headers: {
        Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN_NAME),
        },
    })
    .then(function (response) {
        if (response.status === 200) {
        swal({  
            title: "Customer Notes Added!",
            text: "",
            icon: "success",
            button: "OK ",
        }).then((value) => {
            console.log('added data');
        });
        } else {
        setState((prevState) => ({
            ...prevState,
            errorMessage: "some error occurd",
        }));
        }
    })
    .catch(function (error) {
        console.log(error);
    });

  }


  const handleUpdateBillAdd = (e) => {
      e.preventDefault();
      e.preventDefault();
        const { id } = params;
        const payload = {
            billAddressAddr1: invoice.billAddressAddr1,
            billAddressAddr2: invoice.billAddressAddr2,
            billAddressAddr3: invoice.billAddressAddr3,
            billAddressAddr4: invoice.billAddressAddr4,
            billAddressAddr5: invoice.billAddressAddr5,
            billAddressCity: invoice.billAddressCity,
            billAddressCountry: invoice.billAddressCountry,
            billAddressPostalCode: invoice.billAddressPostalCode,
            billAddressState: invoice.billAddressState,
        };
        console.log('updatepayload',payload);
        axios
        .post(API_BASE_URL + `/updateInvoiceBillAddress?id=${id}`, payload, {
            headers: {
            Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN_NAME),
            },
        })
        .then(function (response) {
            if (response.status === 200) {
            swal({  
                title: "Billing Address Updated!",
                text: "",
                icon: "success",
                button: "OK ",
            }).then((value) => {
                console.log('added data');
            });
            } else {
            setState((prevState) => ({
                ...prevState,
                errorMessage: "some error occurd",
            }));
            }
        })
        .catch(function (error) {
            console.log(error);
        });
  }

  const handleUpdateShipAdd = (e) => {
    e.preventDefault();
      const { id } = params;
      const payload = {
        shipAddressAddr1: invoice.shipAddressAddr1,
        shipAddressAddr2: invoice.shipAddressAddr2,
        shipAddressAddr3: invoice.shipAddressAddr3,
        shipAddressAddr4: invoice.shipAddressAddr4,
        shipAddressAddr5: invoice.shipAddressAddr5,
        shipAddressCity: invoice.shipAddressCity,
        shipAddressCountry: invoice.shipAddressCountry,
        shipAddressPostalCode: invoice.shipAddressPostalCode,
        shipAddressState: invoice.shipAddressState,
      };
      console.log('updatepayload',payload);
      axios
      .post(API_BASE_URL + `/updateInvoiceShipAddress?id=${id}`, payload, {
          headers: {
          Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN_NAME),
          },
      })
      .then(function (response) {
          if (response.status === 200) {
          swal({  
              title: "Shipping Address Updated!",
              text: "",
              icon: "success",
              button: "OK ",
          }).then((value) => {
              console.log('added data');
          });
          } else {
          setState((prevState) => ({
              ...prevState,
              errorMessage: "some error occurd",
          }));
          }
      })
      .catch(function (error) {
          console.log(error);
      });
}

    const handleCancel =() => {
        props.history.push('/needsdispatch');
    }

  const handleSubmitClick = (e) => {
    e.preventDefault();
    if (
        invoice.customerCallCompletedBy !== "" && 
        invoice.customerCallCompletedOn !== ""
    ) {
      console.log('if condition');
      setState((prevState) => ({
        ...prevState,
        errorMessage: {},
      }));
      const { id } = params;
      const payload = {
        customerCallCompletedBy: invoice.customerCallCompletedBy,
        customerCallCompletedOn: invoice.customerCallCompletedOn
      };
      console.log('updatepayload',payload);
      axios
      .post(API_BASE_URL + `/updateInvoice?id=${id}`, payload, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN_NAME),
        },
      })
      .then(function (response) {
        if (response.status === 200) {
          swal({  
            title: "Data Updated!",
            text: "",
            icon: "success",
            button: "OK ",
          }).then((value) => {
              console.log('added data');
          });
        } else {
          setState((prevState) => ({
            ...prevState,
            errorMessage: "some error occurd",
          }));
        }
      })
      .catch(function (error) {
        console.log(error);
      });

    } else {
        console.log('error')
    }
  };
  
  function handleLogout() {
    localStorage.removeItem(ACCESS_TOKEN_NAME);
    localStorage.removeItem('role');
    localStorage.removeItem('user');
    localStorage.removeItem(ACCESS_TOKEN_NAME_USER);
    props.history.push("/");
  }

    return ( 
    <>
<div id="pcoded" className="pcoded">
        <div className="pcoded-overlay-box"></div>
        <div className="pcoded-container navbar-wrapper">

      
                    
        <nav className="navbar header-navbar pcoded-header" style={{height:'55px', minHeight:'26px'}}> 
        <div className="navbar-wrapper">
            <div className="navbar-logo" style={{height: '52px'}}>
                <a style={{top:'7px'}} className="mobile-menu navabar_menu" id="mobile-collapse" href="#!">
                    <i class="ti-menu" style={{"color":" #000", "font-size" : "22px;"}}></i>
                </a>
                <a className="mobile-search morphsearch-search" href="#">
                    <i className="ti-search"></i>
                </a>
                <Link to="/dashboard">
                    <img style={{width:'30%'}} className="img-fluid logo-img" src={logo} alt="Theme-Logo" />
                </Link>
                <a className="mobile-options">
                    <i className="ti-more"></i>
                </a>
            </div>
            
            <div className="navbar-container container-fluid">
                <ul className="nav-right">
                    <li className="user-profile header-notification" style={{lineHeight: 3.5}}>
                        <a href="#!">
                        <Avatar name={localStorage.getItem('user')} colors={['#ed3028', '#711999', '#383838']} size="40" round="20px" />
                        </a>
                        <ul className="show-notification profile-notification">
                            <li>
                                <a href="#">
                                    <i className="ti-user"></i> Profile
                                </a>
                            </li>
                            <li>
                              <Link to="/resetpassword">
                                <i className="ti-layout-sidebar-left"></i> Change Password
                              </Link>
                            </li>
                            <li>
                                <a href="" onClick={() => handleLogout()}>
                                    <i className="ti-layout-sidebar-left"></i> Logout
                                </a>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
            <div className="pcoded-main-container">
                <div className="pcoded-wrapper">
                    
                    <Sidebar />

                    <div className="pcoded-content page_content">
                        <div className="pcoded-inner-content">
                            <div className="main-body">
                                <div className="page-wrapper">
                                    <div className="page-body">
                                        <div className="row">
                                            <div className="col-md-3">
                                                <h4 style={{ marginTop: "20px", fontSize: "large"}}>Purchase Order :</h4>
                                            </div>
                                            <div className="col-md-9 text-right">
                                                <h4 className="text-success">CLOSED</h4>
                                                <small>Total amount : </small>
                                                {/* <h4>Edit User</h4> */}
                                            </div>
                                        </div>
                                        <div className="col-md-12 tabs-ui p-0">
                                        <div className="tab-content" id="nav-tabContent">
                                            <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                                                <form>
                                                  {/* {errorMessages && (
                                                    <span className="red">{errorMessages}</span>
                                                  )} */}
                                                  <div className="row">
                                                     
                                                      <div className="col-md-4">
                                                            <div className="form-group text-left">
                                                                <label>Product / Service to Purchase</label>
                                                                <select
                                                                className="form-control"
                                                                name="productPurchase"
                                                                onChange={handleChange}
                                                                >
                                                                <option value="">Containers For Resale</option>
                                                                <option value="Driver">Driver</option>
                                                                <option value="Other">Other</option>
                                                                </select>
                                                               
                                                            </div>
                                                       
                                                            <div className="form-group text-left">
                                                            <label>Purchase Order #</label>
                                                                <input
                                                                type="text"
                                                                name="purchaseOrder"
                                                                className="form-control"
                                                                id="purchaseOrder"
                                                                value="12345"                                                            
                                                                onChange={handleChange}
                                                                />

                                                               
                                                            </div>
                                                            <div className="form-group text-left">
                                                            <label className="required">Carrier</label>
                                                                <select
                                                                className="form-control"
                                                                name="Vendor"
                                                                value=""
                                                                onChange={handleChange}
                                                                >
                                                                <option value="">Select Carrier</option>
                                                                <option value="Driver">Driver</option>
                                                                <option value="Other">Other</option>
                                                                </select>
                                                              
                                                            </div>
                                                            <div className="form-group text-left">
                                                            <label>Release Number</label>
                                                                <input
                                                                type="text"
                                                                name="releaseNumber"
                                                                className="form-control"
                                                                id="releaseNumber"
                                                               
                                                                value=""
                                                                onChange={handleChange}
                                                                />
                                                                
                                                            </div>
                                                            <div className="form-group text-left">
                                                            <label>Ship To</label>
                                                                <select
                                                                className="form-control"
                                                                name="shipTo"
                                                                value="shipTo"
                                                                onChange={handleChange}
                                                                >
                                                                <option value="">Select Ship To</option>
                                                                <option value="20 ft">20 ft</option>
                                                                <option value="22 ft">22 ft</option>
                                                                <option value="40 ft">40 ft</option>
                                                                </select>
                                                                
                                                            </div>
                                                            <div className="form-group text-left">
                                                        <label>Release Date</label>
                                                      <input type="date" className="form-control"/>
                                                         </div>
                                                         <div className="form-group text-left">
                                                            <label>Ship Method</label>
                                                            <select
                                                                className="form-control"
                                                                name="ShipMethod"
                                                                value=""
                                                                onChange={handleChange}
                                                                >
                                                                <option value="">Select Ship Method</option>
                                                                <option value="20 ft">20 ft</option>
                                                                <option value="22 ft">22 ft</option>
                                                                <option value="40 ft">40 ft</option>
                                                                </select>  
                                                                 
                                                            </div>
                                                            <div className="form-group text-left">
                                                            <label>Customer Alt Phone</label>
                                                            <input type="tel" pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}" placeholder="+91 985698986 "className="form-control" autoComplete="off" value="" onChange={handleChange}/>  
                                                                 
                                                            </div>
                                                            <div className="form-group text-left d-flex">
                                                                <div className="col-6 p-0 mr-1">
                                                                <label>Promise Timeline</label>
                                                            <select
                                                                className="form-control"
                                                                name="promiseTimeline"
                                                                value=""
                                                                onChange={handleChange}
                                                                >
                                                                <option value="">Select Promise Timeline</option>
                                                                <option value="20 ft">20 ft</option>
                                                                <option value="22 ft">22 ft</option>
                                                                <option value="40 ft">40 ft</option>
                                                                </select>
                                                                </div>
                                                                <div className="col-6 p-0 mr-1">
                                                                <label>Promise Date</label>  
                                                                <input type="date" className="form-control"/>
                                                                </div> 
                                                            </div>
                                                            <div className="form-group text-left">
                                                            <label>Actual Pickup Date</label>  
                                                                <input type="date" className="form-control"/>
                                                            </div>
                                                        </div>
                                                        {/* column1 end here */}
                                                        <div className="col-md-4">
                                                            <div className="form-group text-left">
                                                            <label>Supplier Vendor Invoice #</label>
                                                                <input
                                                                type="text"
                                                                name="suplierVendorInvoice"
                                                                className="form-control"
                                                                id="suplierVendorInvoice"
                                                                value="82108831"
                                                              
                                                            
                                                                onChange={handleChange}
                                                                />

                                                        
                                                            </div>
                                                        
                                                            <div className="form-group text-left">
                                                            <label>Pickup Location</label>
                                                             
                                                         <select
                                                                className="form-control"
                                                                name="pickLocation"
                                                                value=""
                                                                onChange={handleChange}
                                                                >
                                                                <option value="">DNJ Transportation</option>
                                                                <option value="Driver">Driver</option>
                                                                <option value="Other">Other</option>
                                                                </select>

                                                        
                                                            </div>
                                                           
                                                       
                                                            <div className="form-group text-left">
                                                            <label>Date</label>
                                                               <input type="date" className="form-control" placeholder=""/>
                                                            </div>
                                                            <div className="form-group text-left d-flex">
                                                                <div className="col-6">
                                                                <label>Location Details</label>
                                                                
                                                               <address>
                                                               Written by <a href="mailto:webmaster@example.com">Jon Doe</a>.<br/>
Visit us at:<br/>
Example.com<br/>
Box 564, Disneyland<br/>
USA
                                                               </address>

                                                               
                                                                </div>
                                                                <div className="col-6">
                                                                <label>Budget</label>
                                                                <address>
                                                               Written by <a href="mailto:webmaster@example.com">Jon Doe</a>.<br/>
Visit us at:<br/>
Example.com<br/>
Box 564, Disneyland<br/>
USA
                                                               </address>
                                                                </div>
                                                            
                                                                
                                                            </div>
                                                            <div className="form-group text-left ">
                                                                <label>Invoice Linked To</label>
                                                                <div className="d-flex">
                                                                <select
                                                                className="form-control mr-2"
                                                                name="invoiceLinked"
                                                                value=""
                                                                onChange={handleChange}
                                                                >
                                                                <option value="">Select Invoicee</option>
                                                                <option value="20 ft">20 ft</option>
                                                                <option value="22 ft">22 ft</option>
                                                                <option value="40 ft">40 ft</option>
                                                                </select>
                                                            <button
                                                                type="submit"
                                                                className="btn btn-primary btn-add"
                                                                onClick={handleSubmitClick}>
                                                            
                                                               View
                                                            </button>
                                                            </div>
                                                            </div>
                                                            <div className="form-group text-left">
                                                            <label>Customer Phone</label>
                                                            <input type="tel" pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}" placeholder="+91 985698986 "className="form-control" autoComplete="off" value="" onChange={handleChange}/>  
                                                                 
                                                            </div>
                                                            <div className="form-group text-left">
                                                            <label>Supplier Location</label>
                                                            <select
                                                                className="form-control"
                                                                name="SupplierLocation"
                                                                value=""
                                                                onChange={handleChange}
                                                                >
                                                                <option value="">Select Supplier Location</option>
                                                                <option value="20 ft">20 ft</option>
                                                                <option value="22 ft">22 ft</option>
                                                                <option value="40 ft">40 ft</option>
                                                                </select>  
                                                                 
                                                            </div>
                                                            <div className="form-group text-left">
                                                            <label>Container Orientation</label>
                                                            <select
                                                                className="form-control"
                                                                name="containerOrientation"
                                                                value=""
                                                                onChange={handleChange}
                                                                >
                                                                <option value="">Select Container Orientation</option>
                                                                <option value="20 ft">20 ft</option>
                                                                <option value="22 ft">22 ft</option>
                                                                <option value="40 ft">40 ft</option>
                                                                </select>  
                                                                 
                                                            </div>
                                                            
                                                        </div>
                                                        {/* column2 end here */}
                                                        {/* column 3 start here */}
                                                        <div className="col-md-4">
                                                            
                                                            
                                                            <div className="form-group text-left d-flex margin-top-3">
                                                            <input type="text" className="form-control mr-2" value="" placeholder="Add Customer Note" autoComplete="off" />
                                                            <button
                                                                type="submit"
                                                                className="btn btn-primary btn-add"
                                                                onClick={handleSubmitClick}>
                                                            
                                                                Add
                                                            </button>
                                                            </div>

                                                          
                                                                <div className="table-responsive">
                                                                    <table  className="table table-hover table-bordered" width="100%">
                                                                        <thead>
                                                                            <tr>
                                                                                <th>Notes</th>
                                                                                <th>User Name</th>
                                                                                <th>Date-Time</th>
                                                                                
                                                                                </tr>
                                                                                
                                                                        </thead>
                                                                        <tbody>
                                                                            <tr>
                                                                                <td>1</td>
                                                                                <td>John</td>
                                                                                <td>7-7-2021</td>
                                                                            </tr>
                                                                        </tbody>

                                                                    </table>
                                                                </div>
                                                                <div className="form-group text-left">
                                                            <label>Dispatch Notes</label>
                                                                
                                                                
                                                            <textarea class="form-control" rows="5" id="customerNotes"></textarea>
                                                               
                                                            </div>
                                                            <div className="form-group text-left">
                                                            <label>Vendor Notes</label>
                                                                
                                                                
                                                            <textarea class="form-control" rows="5" id="customerNotes"></textarea>
                                                               
                                                            </div>
                                                            
                                                           
                                                        </div>
                                                          {/* column 3 end here */}
                                                        </div>
                                                       
                                                        {/* row1 end here */}
                                                        
                                                        <div className="row">
                                                         <div className="col-md-2">
                                                        <div className="form-group text-left">
                                                           
                                                        <div className="d-flex justify-content-between">
                                                                <label>
                                                                    Billing
                                                                </label>
                                                                <div className="bill-update">
                                                                <a href="#">Update</a>
                                                                </div>
                                                               
                                                                </div>
                                                                <hr></hr>
                                                               
                                                                <input type="text" className="form-control mb-3" autoComplete="off" value=""/>
                                                                <input type="text" className="form-control mb-3" autoComplete="off" value=""/>
                                                                <input type="text" className="form-control mb-3" placeholder="Bill Line 1" autoComplete="off" value=""/>
                                                                <input type="text" className="form-control mb-3" placeholder="Bill Line 2" autoComplete="off" value=""/>
                                                                <textarea class="form-control mb-3" placeholder="Bill Line 3" rows="5" id="customerNotes"></textarea>
                                                                <div className="d-flex">
                                                                <input type="text" className="form-control mb-3 mr-1" autoComplete="off" value="" placeholder="Quincy"/>
                                                                <input type="text" className="form-control mb-3" autoComplete="off" value="" placeholder="MA"/>
                                                                </div>
                                                                <div className="d-flex">
                                                                <input type="text" className="form-control mb-3 mr-1" autoComplete="off" value="" placeholder="Pin code"/>
                                                                <input type="text" className="form-control mb-3" autoComplete="off" value="" placeholder="Country"/>
                                                                </div>
                                                                
                                                                <div className="form-group text-left">
                                                                    <label>Vendor Phone Number</label>
                                                                    <input type="tel" className="form-control" autoComplete="off" placeholder="6173285400"/>
                                                                </div>
                                                              

                                                            </div>
                                                        </div>  

                                                        <div className="col-md-2">
                                                        <div className="form-group text-left">
                                                        <div class="form-check">
                                                        <label className="form-check-label">
                                                         <input type="checkbox" className ="form-check-input" value=""/>Expedited
                                                      </label>
                                                       </div>
                                                     <div className="form-check">
                                                     <label className="form-check-label">
                                                    <input type="checkbox" class="form-check-input" value=""/>Refunded
                                                    </label>
                                                    </div>

                                                            </div>
                                                        </div>
                                                        <div className="col-md-2">
                                                        <div className="form-group text-left">
                                                        
                                                        <label>Expected Date</label>
                                                        <input type="date" className="form-control"  value=""/>
                                                      
                                                           
                                                            </div>
                                                            <div className="form-group text-left">
                                                            <div className="form-check">
                                                     <label className="form-check-label">
                                                    <input type="checkbox"  checked className="form-check-input" value=""/>Depot all completed
                                                    </label>
                                                    </div>
                                                    </div>
                                                    <div className="form-group text-left d-flex">
                                                        <div className="col-6 p-0">
                                                        <label for="date">8/7/2021</label>
                                                        <label for="time">5:30pm</label>
                                                        </div>
                                                        <div className="col-6 p-0">
                                                        <label for="name">Christine Iyer</label>
                                                        </div>
                                                      
                                                        </div>
                                                        </div>
                                                        {/* Billing column2 */}

                                                        <div className="col-md-2"> 
                                                        <div className="form-group text-left">
                                                        <label>Scheduled Delivery Date</label>
                                                        <input type="date" className="form-control"  value=""/>
                                                      
                                                            </div>
                                                            <div className="form-group text-left d-flex">
                                                                <div className="col-6 p-0 mr-2">
                                                                <label>From Date</label>
                                                        <input type="date" className="form-control"  value=""/>
                                                                </div>
                                                                <div className="col-6 p-0 mr-2">
                                                                <label>To Date</label>
                                                        <input type="date" className="form-control"  value=""/>
                                                                </div>
                                                       
                                                      
                                                            </div>
                                                            <div className="form-group text-left">
                                                            <label>Delivery Status</label>
                                                            <input type="text" className="form-control" autoComplete="off" value=""/>
                                                            </div>
                                                            <div className="form-group text-left">
                                                            <label>Actual Delivery Date</label>
                                                            <input type="date" className="form-control"/>
                                                            </div>
                                                        </div>


                                                        </div>


                                                        {/* Billing row end here */}

                                                        {/* shipping row start here */}
                                                        <div className="row">
                                                         <div className="col-md-2">
                                                        <div className="form-group text-left">
                                                           
                                                        <div className="d-flex justify-content-between">
                                                                <label>
                                                                    Shipping
                                                                </label>
                                                                <div className="bill-update">
                                                                <a href="#">Update</a>
                                                                </div>
                                                               
                                                                </div>
                                                                <hr></hr>
                                                               
                                                                <input type="text" className="form-control mb-3" autoComplete="off" value=""/>
                                                                <input type="text" className="form-control mb-3" autoComplete="off" value=""/>
                                                                <input type="text" className="form-control mb-3" placeholder="Ship Line 1" autoComplete="off" value=""/>
                                                                <input type="text" className="form-control mb-3" placeholder="Ship Line 2" autoComplete="off" value=""/>
                                                                <textarea class="form-control mb-3" placeholder="Ship Line 3" rows="5" id="customerNotes"></textarea>
                                                                <div className="d-flex">
                                                                <input type="text" className="form-control mb-3 mr-1" autoComplete="off" value="" placeholder="Quincy"/>
                                                                <input type="text" className="form-control mb-3" autoComplete="off" value="" placeholder="MA"/>
                                                                </div>
                                                                <div className="d-flex">
                                                                <input type="text" className="form-control mb-3 mr-1" autoComplete="off" value="" placeholder="Pin code"/>
                                                                <input type="text" className="form-control mb-3" autoComplete="off" value="" placeholder="Country"/>
                                                                </div>
                                                                
                                                                <div className="form-group text-left">
                                                                    <label>Vendor Phone Number</label>
                                                                    <input type="tel" className="form-control" autoComplete="off" placeholder="6173285400"/>
                                                                </div>
                                                              

                                                            </div>
                                                        </div>  
                                                        </div>

                                                        {/* Shipping row end here */}

                                                        {/* Table section start here */}
                                                        <div className="row">
                                                            <div className="col-md-12">
                                                            <div className="form-group text-left">
                                                            <div class="form-check-inline mr-4">
                                                           <label class="form-check-label">
                                                           <input type="radio" className="form-check-input" checked name="optradio" />Items Details
                                                         </label>
                                                        </div>
                                                      <div className="form-check-inline">
                                                      <label className="form-check-label">
                                                      <input type="radio" className="form-check-input" name="optradio" />Account Details
                                                       </label>
                                                          </div>
                                                          </div>
                                                          </div>
                                                          {/* Table Details start here  */}
                                                          <div className="col-md-12">
                                                          <div className="form-group text-left">
                                                              <div className="table-responsive">
                                                                  <table id="" className="table table-hover table-bordered" width="100%">
                                                                      <thead>
                                                                          <tr>
                                                                              <th>Record Id</th>
                                                                              <th>Items</th>
                                                                              <th>Description</th>
                                                                              <th>Qty</th>
                                                                              <th>Rate</th>
                                                                              <th>Amount</th>
                                                                              <th>Customer</th>
                                                                              <th>Container #</th>
                                                                              <th></th>
                                                                          </tr>
                                                                      </thead>
                                                                      <tbody>
                                                                          <tr>
                                                                              <td>1234</td>
                                                                              <td></td>
                                                                              <td></td>
                                                                              <td></td>
                                                                              <td></td>
                                                                              <td></td>
                                                                              <td></td>
                                                                              <td></td>
                                                                              <td className="text-center"><i class="fa fa-trash"></i></td>
                                                                              </tr>
                                                                      </tbody>
                                                                      

                                                                  </table>
                                                              </div>
                                                              </div>
                                                          {/* Table end here */}
                                                            </div>
                                                            {/* column1 end here */}
                                                        </div>
                                                        {/* Table section end here */}

                                                        {/* Attachments section start here */}

                                                        <div className="row">
                                                        <div className="col-md-4">
                                                            <div className="form-group text-left">
                                                                
                                                                   
                                                                        <label>Attachments</label>
                                                                        <div className="attach-note inputDnD">
                                                                        <input type="file" class="form-control-file font-weight-bold" id="inputFile" onchange="readUrl(this)" data-title="Drag files here or click to browse" />
                                                                        <small class="file-info">Individual file size limit is 50MB.</small>
                                                                        
                                                                        </div>

                                                                    
                                                                </div>
                                                            </div>

                                                            <div className="col-md-4">
                                                                <div className="form-group text-left">
                                                                    <label>
                                                                      Memo (Internal  )
                                                                    </label>
                                                                    <textarea class="form-control" rows="5" id="memoInternal"></textarea>
                                                                </div>
                                                                <div className="form-group text-left">
                                                                    <label>
                                                                      Message 
                                                                    </label>
                                                                    <textarea class="form-control" rows="5" id="message"></textarea>
                                                                </div>
                                                            </div>

                                                            <div className="col-md-4">
                                                                <div className="form-group text-left">
                                                                    <label>Taxes</label>
                                                                    <div className="tax-details">
                                                                     <h4>Total</h4>
                                                                     <h4>2200</h4>
                                                                    </div>
                                                                    </div>
                                                                    </div>

                                                                    

                                                        </div>
                                                        {/* Attachments section end here */}



                                                        <div className="col-md-12 text-center">
                                                        <button
                                                                type="submit"
                                                                className="btn btn-default mr-2"
                                                                onClick={handleSubmitClick}
                                                            >
                                                               Delete
                                                            </button>
                                                            <button
                                                                type="submit"
                                                                className="btn btn-default mr-2"
                                                                onClick={handleSubmitClick}
                                                            >
                                                               Email
                                                            </button>
                                                            <button
                                                                type="submit"
                                                                className="btn btn-default mr-2"
                                                                onClick={handleSubmitClick}
                                                            >
                                                               Print
                                                            </button>
                                                            <button
                                                                type="submit"
                                                                className="btn btn-default mr-2"
                                                                onClick={handleSubmitClick}
                                                            >
                                                               Update Transport PO Info
                                                            </button>
                                                            <button
                                                                type="submit"
                                                                className="btn btn-default mr-2"
                                                                onClick={handleSubmitClick}
                                                            >
                                                                Save and New
                                                            </button>
                                                            <button
                                                                type="submit"
                                                                className="btn btn-primary"
                                                                onClick={handleCancel}
                                                            >
                                                               Save
                                                            </button>
                                                        </div>
                                                       
                                                 
                                                </form>
                                                
                                           

                                            </div>
                                      </div>
                                            
                                        </div>

                                        <Footer />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> 
        
        </>
    )
}

export default Designer