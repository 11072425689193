import React,{ useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { ACCESS_TOKEN_NAME, API_BASE_URL } from '../../constants/apiConstants';
import axios from 'axios'
import Header from "../../components/Header/Header";


function SupplierLocation(props) {
    const [data, setData] = useState();
  useEffect(() => {
    async function fetchLocation() {
        const result = await axios.get(
            API_BASE_URL + `/findAllSupplierLocation`,
            {
                headers : {
                    Authorization : "Bearer " + localStorage.getItem(ACCESS_TOKEN_NAME),
                }
            },
        );
        if(result.status === 200){
            console.log('locationdata -----',result.data);
            setData(result.data);
        }
    }   
    fetchLocation(); 
  }, []);

    return(
        <>
        <Header />
        <div className="container">
            <div style={{marginTop: "10px", width:"400px", marginLeft: "350px"}}>
                <table className="table border shadow">
                    <thead className="thead-dark">
                    <tr>
                        <th scope="col">Supplier Location</th>
                    </tr>
                    </thead>
                    <tbody>
                    

                    {data &&
                        data.map((data, index) => (
                        <tr key={data.id}>
                            <td>{data.SupplierLocation}</td>
                        </tr>
                        ))}

                    </tbody>
                </table>
          </div>
        </div>
        </>
    )
}

export default withRouter(SupplierLocation);