import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import MaterialTable from "material-table";
import axios from 'axios';
import PlanningServices from '../DriverPlanning/PlanningServices';
import avoidNull from '../../utils/AvoidNull';
import avoidNull2 from '../../utils/AvoidNull2';
import removeAddress from '../../utils/RemoveAddress';
import { ACCESS_TOKEN_NAME, ACCESS_TOKEN_NAME_USER, API_BASE_URL } from '../../constants/apiConstants';
import CustomDatePicker from "../../utils/customDatePicker";
import FilterComponentBox from "../../utils/FilterComponentBox";
import ResetFillterButton from "../../utils/ResetFillterButton";
import { useCookies } from 'react-cookie';
import Cookies from 'universal-cookie';
import 'react-dates/initialize';
import { DateRangePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import jstz from 'jstz';
import { ToastContainer, toast } from 'react-toastify';




const DispachedBox = (props) => {
    const cookiesi = new Cookies();
    const [pageRefresh, setPageRefresh] = useState(false);
    const [cookies, setCookie, removeCookie] = useCookies(['dispatched']);

    const [orderData, setOrderData] = useState();

    const [loading, setloading] = useState(false);
    const [startScheduledDate, setStartDate] = useState(cookiesi.getAll().startScheduledDate ? moment(cookiesi.getAll().startScheduledDate) : null);
    const [endScheduledDate, setEndDate] = useState(cookiesi.getAll().endScheduledDate ? moment(cookiesi.getAll().endScheduledDate) : null);
    const [focusedInput, setFocusedInput] = useState(null);

    const fetchDispatchedOrder = async (driverInfo) => {
        setloading(true);
        const response = await axios.post(API_BASE_URL + `/fetchDispatchedOrder`, { driverInfo }, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN_NAME),
            },
        })
        setloading(false);
        setOrderData(response.data);
    }

    const tableRef = useRef(null);

    const createPlan = async (product, invoiceRefNumber, resalePORefNumer, resaleVendor, transportPORecordID) => {
        const response = await PlanningServices.getPlanningInfo(invoiceRefNumber);
        if (response[0] && response[0].id) {
            window.open("/exitingPurchaseorder/" + result[0].id + "/" + transportPORecordID);

        }

        const payload = {
            contactId: 0,
            locationId: 123,
            customerConfirmation: 0,
            driveConfirmation: 0,
            size: 0,
            invoiceId: invoiceRefNumber,
            City: '',
            drivername: '',
            product: product,
            State: '',
            transportPORecordID,
            DropState: '',
            DropCity: '',
            Dist: '',
            resalePORefNumer,
            isExpedited: 0,
            vendorFullName: resaleVendor,
            date: ''
        };
        const result = await PlanningServices.savePlanning(payload);
        if (result) {
            //fetchDispatchedOrder({});
            window.open("/exitingPurchaseorder/" + result[0].lastinsterid + "/" + transportPORecordID);
        }
    }
    /*
    useEffect(()=>{
        fetchDispatchedOrder(props.driverInfo);
    },[props.driverInfo]);

    */


    useEffect(() => {
        fetchDispatchedOrder({});
    }, []);

    const resetFilter = async () => {

        const test = document.querySelectorAll(".material-icons");
        for (let tests of test) {
            if (tests.getAttribute("aria-label") == 'clear') {
                tests.click();
            }
        }
        const cookies = new Cookies();
        if (Object.keys(cookies.getAll()).length > 0) {
            setPageRefresh(true);
        }
        else {
            setPageRefresh(true);
        }
        await removeCookie("orderBy", { path: "/dispatched" });
        await removeCookie("direction", { path: "/dispatched" });
        await removeCookie("invoiceRefNumber", { path: "/dispatched" });
        await removeCookie("invoicePaidDate", { path: "/dispatched" });
        await removeCookie("invoiceTerms", { path: "/dispatched" });
        await removeCookie("invoiceLineItemItemNameWithoutParent", { path: "/dispatched" });
        await removeCookie("invoiceAssignedToName", { path: "/dispatched" });
        await removeCookie("resalePOPickupCity", { path: "/dispatched" });
        await removeCookie("resalePORefNumer", { path: "/dispatched" });
        await removeCookie("resalePOReleaseNumber", { path: "/dispatched" });
        await removeCookie("resalePOReleaseDate", { path: "/dispatched" });
        await removeCookie("resalePOTotalPrice", { path: "/dispatched" });
        await removeCookie("resalePOExpectedDate", { path: "/dispatched" });
        await removeCookie("resalePOLastDepotCallDate", { path: "/dispatched" });
        await removeCookie("newDistance", { path: "/dispatched" });
        await removeCookie("transportPODistance", { path: "/dispatched" })
        await removeCookie("invoiceShipCity", { path: "/dispatched" });
        await removeCookie("transportPOLineItemContainerNumber", { path: "/dispatched" });
        await removeCookie("transportPORefNumer", { path: "/dispatched" });
        await removeCookie("transportPOVendor", { path: "/dispatched" });
        await removeCookie("transportPOTotalPrice", { path: "/dispatched" });
        await removeCookie("transportPOScheduledDeliveryDate", { path: "/dispatched" });
        await removeCookie("transportPODeliveryStatus", { path: "/dispatched" });
        await removeCookie("invoicePromisedTimeline", { path: "/dispatched" });
        await removeCookie("invoicePromisedDate", { path: "/dispatched" });

        await removeCookie("invoiceTxnDate", { path: "/dispatched" });
        await removeCookie("invoiceCustomerNotes", { path: "/dispatched" });
        await removeCookie("invoiceBalanceRemaining", { path: "/dispatched" });
        await removeCookie("invoiceSupplierLocation", { path: "/dispatched" });
        await removeCookie("resalePO", { path: "/dispatched" });
        await removeCookie("InvoiceLineHasTier1AddOn", { path: "/dispatched" });
        await removeCookie("startScheduledDate", { path: "/dispatched" });
        await removeCookie("endScheduledDate", { path: "/dispatched" });
        await removeCookie("driverName", { path: "/dispatched" });
        // await removeCookie("vendorName", { path: "/dispatched" });
        setStartDate(null)
        setEndDate(null)
        //await tableRef.current.onAllSelected(false);
        //await tableRef.current.onSearchChange("")

    }

    const setScheduledDate = (startDate, endDate) => {

        let s_date = moment(startDate), e_date = moment(endDate);
        //transportPOScheduledDeliveryDate
        console.log('checkAllDateeeeeeee', startDate, endDate, startDate && endDate, s_date._isValid, e_date._isValid)
        const timezone = jstz.determine();
        //moment(startDate).tz(timezone.name()).format('mm-dd-yyyyy');

        if (s_date._isValid) {
            setStartDate(startDate);

            setCookie('startScheduledDate', moment(startDate).tz(timezone.name()).format('MM-DD-yyyy'), { path: '/dispatched' });

        }
        if (e_date._isValid) {
            setEndDate(endDate);

            setCookie('endScheduledDate', moment(endDate).tz(timezone.name()).format('MM-DD-yyyy'), { path: '/dispatched' });

        }
        if (s_date._isValid && e_date._isValid) {
            setPageRefresh(true)
        }


    }

    const clearDates = () => {
        removeCookie("startScheduledDate", { path: "/dispatched" });
        removeCookie("endScheduledDate", { path: "/dispatched" });
        setStartDate('')
        setEndDate('')
        setPageRefresh(true);
    };

    useEffect(() => {


        setPageRefresh(true);




    }, [props.refreshCount]);

    useEffect(() => {
        const startDateElement = document.querySelector('#s_id');
        const endDateElement = document.querySelector('#e_id');

        const startDateHandleEvent = () => {

            // console.log('Event triggered', startDateElement.value);
            console.log('start date call', startDateElement.value)

            let s_date = startDateElement.value;
            if (s_date.length == 8) {
                s_date = moment(s_date, 'MMDDYYYY');

                if (s_date._isValid) {
                    setStartDate(s_date);
                    setScheduledDate(moment(startDateElement.value), moment(endDateElement.value))
                }
                else {

                    toast.warning("Date is invalid!", {
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true
                    });
                }



            }

        };
        const endDateHandleEvent = () => {

            console.log('end date call', endDateElement.value)
            let e_date = endDateElement.value;
            // console.log(/^-?[\d.]+(?:e-?\d+)?$/.test(e_date),'data is number')
            if (e_date.length == 8) {
                e_date = moment(e_date, 'MM-DD-YYYY');
                if (e_date._isValid) {
                    setEndDate(e_date);
                    setScheduledDate(moment(startDateElement.value), moment(endDateElement.value))

                }
                else {
                    toast.warning("Date is Invalid!", {
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true
                    });
                }

            }
        };

        const startDateCheck = () => {

            let s_date = moment(startDateElement.value, 'MM-DD-YYYY');
            if (s_date._isValid) {
                // setStartDate(s_date);
                // setScheduledDate(moment(startDateElement.value), moment(endDateElement.value))
            }
            else {
                toast.warning("Start Date is Invalid!", {
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });
            }

        };

        const endDateCheck = () => {

            let e_date = moment(endDateElement.value, 'MM-DD-YYYY');
            //  console.log('check end date',endDateElement.value,endScheduledDate,e_date,e_date.format("YYYY-MM-DD")==endScheduledDate.format("YYYY-MM-DD"))

            if (e_date._isValid) {
                // setEndDate(e_date);
                // setScheduledDate(moment(startDateElement.value), moment(endDateElement.value))

            }
            else if (endDateElement.value && endDateElement.value.length > 0) {
                toast.warning("End Date is Invalid!", {
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });
            }

        }

        const startDateclear = () => {
            setStartDate('');
        }
        const endDateclear = () => {
            setEndDate('');
        }

        if (startDateElement) {
            startDateElement.addEventListener('input', startDateHandleEvent);
            startDateElement.addEventListener('focusout', startDateCheck);
            startDateElement.addEventListener('focusin', startDateclear);

        }

        if (endDateElement) {
            endDateElement.addEventListener('input', endDateHandleEvent);
            endDateElement.addEventListener('focusout', endDateCheck);
            endDateElement.addEventListener('focusin', endDateclear);

        }

        // Cleanup function to remove the event listener when the component unmounts
        return () => {
            if (startDateElement) {
                startDateElement.addEventListener('input', startDateHandleEvent);
                startDateElement.addEventListener('focusout', startDateCheck);
                startDateElement.addEventListener('focusin', startDateclear);

            }
            if (endDateElement) {
                endDateElement.addEventListener('input', endDateHandleEvent);
                endDateElement.addEventListener('focusout', endDateCheck);
                endDateElement.addEventListener('focusin', endDateclear);

            }
        };
    }, []);





    return (
        <div className="col-md-12">
            <ToastContainer />
            <div className="App">
                {<><h5 className="mb-3">Scheduled Date</h5><div className='row pl-3'>
                    <DateRangePicker
                        startDate={startScheduledDate}
                        startDateId="s_id"
                        endDate={endScheduledDate}
                        endDateId="e_id"
                        onDatesChange={({ startDate, endDate }) => { setScheduledDate(startDate, endDate); }}
                        focusedInput={focusedInput}
                        onFocusChange={e => setFocusedInput(e)}

                        displayFormat={(date) => {

                            const formats = ['MM/DD/YYYY', 'M/D/YYYY', 'MM/DD/YY', 'M/D/YY', 'MMDDYYYY'];
                            for (const format of formats) {
                                // console.log(date,'abc');
                                const parsed = moment(date, format, true);
                                if (parsed.isValid()) {
                                    return format;
                                }
                            }
                            return 'MM/DD/YYYY'; // Default format if none match
                        }}
                        isOutsideRange={() => false} // Allow selection of past and future dates
                    />
                    <button className='btn btn-dark rounded-5 mx-3' onClick={clearDates}>x</button>
                </div></>}
            </div>

            <MaterialTable
                title=""
                tableRef={tableRef}
                key={pageRefresh}
                columns={[
                    { title: "RecordID", field: "RecordID", hidden: true },
                    {
                        title: "Customer", field: "invoiceRefNumber",
                        defaultSort: cookiesi.getAll()['orderBy'] == 'invoiceRefNumber' ? cookiesi.getAll()['direction'] : false,
                        filterComponent: ({ columnDef, onFilterChanged }) => (
                            <FilterComponentBox pageName={'dispatched'} onFilterChanged={onFilterChanged} columnDef={columnDef} columnName={columnDef.field} />
                        ),
                        render: rowData => <a className={`breakwordwichpadding ${rowData.sameTrailerMarker === 1 &&  rowData.invoiceIsExpedited === 'Yes' ? 'markerExp' : rowData.invoiceIsExpedited === 'Yes' ? 'expeditedClass' : rowData.sameTrailerMarker === 1 ? 'sameMarker' : ''}`} target="_blank" href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID}><strong style={{ "font-weight": "550" }} className="title-strong">{rowData.Customer?.split('*')[0]}</strong><br />{rowData.Customer?.split('*')[1]}</a>
                    },
                    {
                        title: "Paid Date",
                        defaultSort: cookiesi.getAll()['orderBy'] == 'invoicePaidDate' ? cookiesi.getAll()['direction'] : false,
                        filterComponent: (props) => <CustomDatePicker pageName={'dispatched'} {...props} />,
                        field: "invoicePaidDate",
                        filterComponent: (props) => <CustomDatePicker pageName={'dispatched'} {...props} />,
                        type: 'date', render: rowData => <a className={rowData.sameTrailerMarker === 1 &&  rowData.invoiceIsExpedited === 'Yes' ? 'markerExp' : rowData.invoiceIsExpedited === 'Yes' ? 'expeditedClass' : rowData.sameTrailerMarker === 1 ? 'sameMarker' : ''} target="_blank" href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID}>{(rowData.PaidDate) ? moment(rowData.PaidDate).format('MM/DD/YY') : ''}</a>
                    },

                    {
                        title: "Terms", field: "invoiceTerms",
                        defaultSort: cookiesi.getAll()['orderBy'] == 'invoiceTerms' ? cookiesi.getAll()['direction'] : false,
                        filterComponent: ({ columnDef, onFilterChanged }) => (
                            <FilterComponentBox pageName={'dispatched'} onFilterChanged={onFilterChanged} columnDef={columnDef} columnName={columnDef.field} />
                        ),
                        render: rowData => <a className={rowData.sameTrailerMarker === 1 &&  rowData.invoiceIsExpedited === 'Yes' ? 'markerExp' : rowData.invoiceIsExpedited === 'Yes' ? 'expeditedClass' : rowData.sameTrailerMarker === 1 ? 'sameMarker' : ''} target="_blank" href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID}>{rowData.Terms}</a>
                    },
                    {
                        title: "Product", field: "invoiceLineItemItemNameWithoutParent",
                        defaultSort: cookiesi.getAll()['orderBy'] == 'invoiceLineItemItemNameWithoutParent' ? cookiesi.getAll()['direction'] : false,
                        filterComponent: ({ columnDef, onFilterChanged }) => (
                            <FilterComponentBox pageName={'dispatched'} onFilterChanged={onFilterChanged} columnDef={columnDef} columnName={columnDef.field} />
                        ),
                        render: rowData => <a className={rowData.sameTrailerMarker === 1 &&  rowData.invoiceIsExpedited === 'Yes' ? 'markerExp' : rowData.invoiceIsExpedited === 'Yes' ? 'expeditedClass' : rowData.sameTrailerMarker === 1 ? 'sameMarker' : ''} target="_blank" href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID}>{rowData.Product}</a>
                    },
                    {
                        title: "Assign To", field: "invoiceAssignedToName",
                        defaultSort: cookiesi.getAll()['orderBy'] == 'invoiceAssignedToName' ? cookiesi.getAll()['direction'] : false,
                        filterComponent: ({ columnDef, onFilterChanged }) => (
                            <FilterComponentBox pageName={'dispatched'} onFilterChanged={onFilterChanged} columnDef={columnDef} columnName={columnDef.field} />
                        ),
                        render: rowData => <a className={`breakword ${rowData.sameTrailerMarker === 1 &&  rowData.invoiceIsExpedited === 'Yes' ? 'markerExp' : rowData.invoiceIsExpedited === 'Yes' ? 'expeditedClass' : rowData.sameTrailerMarker === 1 ? 'sameMarker' : ''}`} target="_blank" href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID}>{rowData.AssignedTo}</a>
                    },
                    {
                        title: "Supp Loc", field: "invoiceSupplierLocation",
                        defaultSort: cookiesi.getAll()['orderBy'] == 'invoiceSupplierLocation' ? cookiesi.getAll()['direction'] : false,
                        filterComponent: ({ columnDef, onFilterChanged }) => (
                            <FilterComponentBox pageName={'dispatched'} onFilterChanged={onFilterChanged} columnDef={columnDef} columnName={columnDef.field} />
                        ),
                        render: rowData => <a className={`breakword ${rowData.sameTrailerMarker === 1 &&  rowData.invoiceIsExpedited === 'Yes' ? 'markerExp' : rowData.invoiceIsExpedited === 'Yes' ? 'expeditedClass' : rowData.sameTrailerMarker === 1 ? 'sameMarker' : ''}`} target="_blank" href={'https://www.google.com/maps/place/' + rowData.resalePOSupplierLocation}>{rowData.resalePOSupplierLocation}</a>
                    },

                    // {
                    //     title: "Vendor", field: "vendorName",
                    //     defaultSort: cookiesi.getAll()['orderBy'] == 'vendorName' ? cookiesi.getAll()['direction'] : false,
                    //     filterComponent: ({ columnDef, onFilterChanged }) => (
                    //         <FilterComponentBox pageName={'dispatched'} onFilterChanged={onFilterChanged} columnDef={columnDef} columnName={columnDef.field} />
                    //     ),
                    //     render: (rowData) => {
                    //         return <a className={`breakword ${rowData.sameTrailerMarker === 1 &&  rowData.invoiceIsExpedited === 'Yes' ? 'markerExp' : rowData.invoiceIsExpedited === 'Yes' ? 'expeditedClass' : rowData.sameTrailerMarker === 1 ? 'sameMarker' : ''}`} target="_blank" href={`https://www.google.com/maps/dir/?api=1&origin=${avoidNull(rowData.resalePOPickupAddress1)}${avoidNull(rowData.resalePOPickupCity)}${avoidNull2(rowData.resalePOPickupZipCode)}&destination=${removeAddress(rowData.invoiceShipAddress1,rowData.shipState)}${removeAddress(rowData.invoiceShipAddress2, rowData.shipState)}${removeAddress(rowData.invoiceShipAddress3, rowData.shipState)}${avoidNull(rowData.invoiceShipCity)}${avoidNull(rowData.shipState)}${avoidNull2(rowData.invoiceShipZip)}`}>{rowData.vendorName}</a>
                    //     }
                    // },
                    {
                        title: "Res PO #", field: "resalePO",
                        defaultSort: cookiesi.getAll()['orderBy'] == 'resalePO' ? cookiesi.getAll()['direction'] : false,
                        filterComponent: ({ columnDef, onFilterChanged }) => (
                            <FilterComponentBox pageName={'dispatched'} onFilterChanged={onFilterChanged} columnDef={columnDef} columnName={columnDef.field} />
                        ),
                        render: rowData => <a className={rowData.sameTrailerMarker === 1 &&  rowData.invoiceIsExpedited === 'Yes' ? 'markerExp' : rowData.invoiceIsExpedited === 'Yes' ? 'expeditedClass' : rowData.sameTrailerMarker === 1 ? 'sameMarker' : ''} target="_blank" href={'/resalePurchaseOrder/' + rowData.ResalePORecordID + '/' + rowData.RecordID}>{rowData.ResalePO}</a>
                    },

                    {
                        title: "Rele #", field: "resalePOReleaseNumber",
                        defaultSort: cookiesi.getAll()['orderBy'] == 'resalePOReleaseNumber' ? cookiesi.getAll()['direction'] : false,
                        filterComponent: ({ columnDef, onFilterChanged }) => (
                            <FilterComponentBox pageName={'dispatched'} onFilterChanged={onFilterChanged} columnDef={columnDef} columnName={columnDef.field} />
                        ),
                        render: rowData => <a className={rowData.sameTrailerMarker === 1 &&  rowData.invoiceIsExpedited === 'Yes' ? 'markerExp' : rowData.invoiceIsExpedited === 'Yes' ? 'expeditedClass' : rowData.sameTrailerMarker === 1 ? 'sameMarker' : ''} target="_blank" href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID}>{rowData.Resale}</a>
                    },

                    {
                        title: "Release Date",
                        defaultSort: cookiesi.getAll()['orderBy'] == 'resalePOReleaseDate' ? cookiesi.getAll()['direction'] : false,
                        filterComponent: (props) => <CustomDatePicker pageName={'dispatched'} {...props} />,
                        field: "resalePOReleaseDate", type: 'date', render: rowData => <a className={rowData.sameTrailerMarker === 1 &&  rowData.invoiceIsExpedited === 'Yes' ? 'markerExp' : rowData.invoiceIsExpedited === 'Yes' ? 'expeditedClass' : rowData.sameTrailerMarker === 1 ? 'sameMarker' : ''} target="_blank" href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID}>{(rowData.ReleaseDate) ? moment(rowData.ReleaseDate).format('MM/DD/YY') : ''}</a>
                    },

                    {
                        title: "Res Total",
                        defaultSort: cookiesi.getAll()['orderBy'] == 'resalePOTotalPrice' ? cookiesi.getAll()['direction'] : false,
                        filterComponent: ({ columnDef, onFilterChanged }) => (
                            <FilterComponentBox pageName={'dispatched'} onFilterChanged={onFilterChanged} columnDef={columnDef} columnName={columnDef.field} />
                        ),
                        field: "resalePOTotalPrice", render: rowData => <a className={rowData.sameTrailerMarker === 1 &&  rowData.invoiceIsExpedited === 'Yes' ? 'markerExp' : rowData.invoiceIsExpedited === 'Yes' ? 'expeditedClass' : rowData.sameTrailerMarker === 1 ? 'sameMarker' : ''} target="_blank" href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID}>{rowData.ResaleTotal}</a>
                    },
                    {
                        title: "Expected",
                        defaultSort: cookiesi.getAll()['orderBy'] == 'resalePOExpectedDate' ? cookiesi.getAll()['direction'] : false,
                        filterComponent: (props) => <CustomDatePicker pageName={'dispatched'} {...props} />,
                        field: "resalePOExpectedDate", type: 'date', render: rowData => <a className={rowData.sameTrailerMarker === 1 &&  rowData.invoiceIsExpedited === 'Yes' ? 'markerExp' : rowData.invoiceIsExpedited === 'Yes' ? 'expeditedClass' : rowData.sameTrailerMarker === 1 ? 'sameMarker' : ''} target="_blank" href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID}>{(rowData.Expected) ? moment(rowData.Expected).format('MM/DD/YY') : ''}</a>
                    },
                    {
                        title: "Last Depot Call",
                        filterComponent: (props) => <CustomDatePicker pageName={'dispatched'} {...props} />,
                        field: "resalePOLastDepotCallDate", type: 'date', render: rowData => <a className={rowData.sameTrailerMarker === 1 &&  rowData.invoiceIsExpedited === 'Yes' ? 'markerExp' : rowData.invoiceIsExpedited === 'Yes' ? 'expeditedClass' : rowData.sameTrailerMarker === 1 ? 'sameMarker' : ''} target="_blank" href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID}>{(rowData.LastDepotCall) ? moment(rowData.LastDepotCall).format('MM/DD/YY') : ''}</a>
                    },
                    {
                        title: "Pickup Loc",
                        defaultSort: cookiesi.getAll()['orderBy'] == 'resalePOPickupCity' ? cookiesi.getAll()['direction'] : false,
                        filterComponent: ({ columnDef, onFilterChanged }) => (
                            <FilterComponentBox pageName={'dispatched'} onFilterChanged={onFilterChanged} columnDef={columnDef} columnName={columnDef.field} />
                        ),
                        field: "resalePOPickupCity",
                        filterComponent: ({ columnDef, onFilterChanged }) => (
                            <FilterComponentBox pageName={'dispatched'} onFilterChanged={onFilterChanged} columnDef={columnDef} columnName={columnDef.field} />
                        ),
                        render: rowData => <a className={`breakword ${rowData.sameTrailerMarker === 1 &&  rowData.invoiceIsExpedited === 'Yes' ? 'markerExp' : rowData.invoiceIsExpedited === 'Yes' ? 'expeditedClass' : rowData.sameTrailerMarker === 1 ? 'sameMarker' : ''}`} target="_blank" href={'https://www.google.com/maps/place/' + rowData.pickupLocation}>{rowData.pickupCity}<br />{rowData.pickupState}</a>
                    },
                    {
                        title: "Del City/State", field: "invoiceShipCity",
                        defaultSort: cookiesi.getAll()['orderBy'] == 'invoiceShipCity' ? cookiesi.getAll()['direction'] : false,
                        filterComponent: ({ columnDef, onFilterChanged }) => (
                            <FilterComponentBox pageName={'dispatched'} onFilterChanged={onFilterChanged} columnDef={columnDef} columnName={columnDef.field} />
                        ),
                        render: (rowData) => {
                            return <a className={`breakword ${rowData.sameTrailerMarker === 1 &&  rowData.invoiceIsExpedited === 'Yes' ? 'markerExp' : rowData.invoiceIsExpedited === 'Yes' ? 'expeditedClass' : rowData.sameTrailerMarker === 1 ? 'sameMarker' : ''}`} target="_blank" href={`https://www.google.com/maps/dir/?api=1&origin=${avoidNull(rowData.resalePOPickupAddress1)}${avoidNull(rowData.resalePOPickupCity)}${avoidNull2(rowData.resalePOPickupZipCode)}&destination=${removeAddress(rowData.invoiceShipAddress1, rowData.shipState)}${removeAddress(rowData.invoiceShipAddress2, rowData.shipState)}${removeAddress(rowData.invoiceShipAddress3, rowData.shipState)}${avoidNull(rowData.invoiceShipCity)}${avoidNull(rowData.shipState)}${avoidNull2(rowData.invoiceShipZip)}`}>{rowData.invoiceShipCity}<br />{rowData.shipState}</a>
                        }
                    },

                    {
                        title: "Container #", field: "transportPOLineItemContainerNumber",
                        defaultSort: cookiesi.getAll()['orderBy'] == 'transportPOLineItemContainerNumber' ? cookiesi.getAll()['direction'] : false,
                        filterComponent: ({ columnDef, onFilterChanged }) => (
                            <FilterComponentBox pageName={'dispatched'} onFilterChanged={onFilterChanged} columnDef={columnDef} columnName={columnDef.field} />
                        ),
                        render: rowData => <a className={rowData.sameTrailerMarker === 1 &&  rowData.invoiceIsExpedited === 'Yes' ? 'markerExp' : rowData.invoiceIsExpedited === 'Yes' ? 'expeditedClass' : rowData.sameTrailerMarker === 1 ? 'sameMarker' : ''} target="_blank" href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID}>{rowData.transportPOLineItemContainerNumber}</a>
                    },
                    {
                        title: "Tran PO #", field: "transportPORefNumer",
                        defaultSort: cookiesi.getAll()['orderBy'] == 'transportPORefNumer' ? cookiesi.getAll()['direction'] : false,
                        filterComponent: ({ columnDef, onFilterChanged }) => (
                            <FilterComponentBox pageName={'dispatched'} onFilterChanged={onFilterChanged} columnDef={columnDef} columnName={columnDef.field} />
                        ),
                        render: rowData => (rowData.planningId) ? <a className={rowData.sameTrailerMarker === 1 &&  rowData.invoiceIsExpedited === 'Yes' ? 'markerExp' : rowData.invoiceIsExpedited === 'Yes' ? 'expeditedClass' : rowData.sameTrailerMarker === 1 ? 'sameMarker' : ''} target="_blank" href={'/exitingPurchaseorder/' + rowData.planningId + '/' + rowData.TransportPORecordID}>{rowData.TransportPO}</a> : <a href="javascript:void(0)" onClick={() => createPlan(rowData.Product, rowData.orderStatusRecordID, rowData.ResalePO, rowData.transportPOVendor, rowData.TransportPORecordID)} >{rowData.TransportPO}</a>
                    },
                    {
                        title: "Driver", field: "driverName",
                        defaultSort: cookiesi.getAll()['orderBy'] == 'driverName' ? cookiesi.getAll()['direction'] : false,
                        filterComponent: ({ columnDef, onFilterChanged }) => (
                            <FilterComponentBox pageName={'dispatched'} onFilterChanged={onFilterChanged} columnDef={columnDef} columnName={columnDef.field} />
                        ),
                        render: rowData => <a target="_blank" className={`breakword ${rowData.sameTrailerMarker === 1 &&  rowData.invoiceIsExpedited === 'Yes' ? 'markerExp' : rowData.invoiceIsExpedited === 'Yes' ? 'expeditedClass' : rowData.sameTrailerMarker === 1 ? 'sameMarker' : ''}`} href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID}>{rowData.driverName}</a>
                    },
                    {
                        title: "Tran Ven", field: "transportPOVendor",
                        defaultSort: cookiesi.getAll()['orderBy'] == 'transportPOVendor' ? cookiesi.getAll()['direction'] : false,
                        filterComponent: ({ columnDef, onFilterChanged }) => (
                            <FilterComponentBox pageName={'dispatched'} onFilterChanged={onFilterChanged} columnDef={columnDef} columnName={columnDef.field} />
                        ),
                        render: rowData => <a target="_blank" className={`breakword ${rowData.sameTrailerMarker === 1 &&  rowData.invoiceIsExpedited === 'Yes' ? 'markerExp' : rowData.invoiceIsExpedited === 'Yes' ? 'expeditedClass' : rowData.sameTrailerMarker === 1 ? 'sameMarker' : ''}`} href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID}>{rowData.transportPOVendor}</a>
                    },
                    {
                        title: "Tran Tot", field: "transportPOTotalPrice",
                        defaultSort: cookiesi.getAll()['orderBy'] == 'transportPOTotalPrice' ? cookiesi.getAll()['direction'] : false,
                        filterComponent: ({ columnDef, onFilterChanged }) => (
                            <FilterComponentBox pageName={'dispatched'} onFilterChanged={onFilterChanged} columnDef={columnDef} columnName={columnDef.field} />
                        ),
                        render: rowData => <a className={rowData.sameTrailerMarker === 1 &&  rowData.invoiceIsExpedited === 'Yes' ? 'markerExp' : rowData.invoiceIsExpedited === 'Yes' ? 'expeditedClass' : rowData.sameTrailerMarker === 1 ? 'sameMarker' : ''} target="_blank" href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID}>{rowData.TransportTotal}</a>
                    },

                    {
                        title: "Sch For",
                        defaultSort: cookiesi.getAll()['orderBy'] == 'transportPOScheduledDeliveryDate' ? cookiesi.getAll()['direction'] : false,
                        filterComponent: (props) => <CustomDatePicker pageName={'dispatched'} {...props} />,
                        field: "transportPOScheduledDeliveryDate", type: 'date', render: rowData => <a className={rowData.sameTrailerMarker === 1 &&  rowData.invoiceIsExpedited === 'Yes' ? 'markerExp' : rowData.invoiceIsExpedited === 'Yes' ? 'expeditedClass' : rowData.sameTrailerMarker === 1 ? 'sameMarker' : ''} target="_blank" href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID}>{(rowData.ScheduledFor) ? moment(rowData.ScheduledFor).format('MM/DD/YY') : ''}</a>
                    },
                    // {
                    //     title: "Sch For",
                    //     defaultSort: cookiesi.getAll()['orderBy'] == 'transportPOScheduledDeliveryDate' ? moment(cookiesi.getAll()['direction']).format('MM/DD/YY') : false,
                    //     filterComponent: (props) => <CustomDatePicker pageName={'dispatched'} {...props} />,
                    //     field: "transportPOScheduledDeliveryDate", type: 'date', render: rowData => <a target="_blank" href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID}>{(rowData.ScheduledFor) ? moment(rowData.ScheduledFor).format('MM/DD/YY') : ''}</a>
                    // },
                    // {
                    //     title: "Sch For",
                    //     defaultSort: cookiesi.getAll()['orderBy'] == 'transportPOScheduledDeliveryDate' ? cookiesi.getAll()['direction'] : false,
                    //     filterComponent: (props) => <CustomDatePicker pageName={'dispatched'} {...props} />,
                    //     field: "transportPOScheduledDeliveryDate", type: 'date', render: rowData => 
                    //     {
                    //         const [dateString, timeString] = rowData.ScheduledFor.split(" ");
                    //         const [YY, MM, DD] = dateString.split("-");
                    //        return <a target="_blank" href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID}>{(rowData.ScheduledFor) ? MM + '/' + DD + '/' + YY.slice(-2)  : ''}</a>
                    //     }

                    // },

                    //    {
                    //     title: "Sch For",
                    //     defaultSort: cookiesi.getAll()['orderBy'] == 'transportPOScheduledDeliveryDate' ? cookiesi.getAll()['direction'] : false,
                    //     filterComponent: (props) => <CustomDatePicker pageName={'dispatched'} {...props} />,
                    //     field: "transportPOScheduledDeliveryDate", type: 'date', render: rowData => 
                    //     {
                    //         // const [dateString, timeString] = rowData.ScheduledFor.split(" ");
                    //         // const [YY, MM, DD] = dateString.split("-");

                    //         // // Create a Date object with the stored date in the EST timezone
                    //         // const estDate = new Date(Date.UTC(YY, MM - 1, DD));

                    //         // // Get the components of the date in the EST timezone
                    //         // const estYY = estDate.getUTCFullYear();
                    //         // const estMM = estDate.getUTCMonth() + 1;
                    //         // const estDD = estDate.getUTCDate();
                    //        return <a target="_blank" href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID}>{(rowData.ScheduledFor) ? `${estMM}/${estDD}/${estYY.toString().slice(-2)}`  : ''}</a>
                    //     }

                    // },


                    // {
                    //     title: "Miles", field: "newDistance",
                    //     defaultSort: cookiesi.getAll()['orderBy'] == 'newDistance' ? cookiesi.getAll()['direction'] : false,
                    //     filterComponent: ({ columnDef, onFilterChanged }) => (
                    //         <FilterComponentBox pageName={'dispatched'} onFilterChanged={onFilterChanged} columnDef={columnDef} columnName={columnDef.field} />
                    //     ),
                    //     render: rowData => <a target="_blank" href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID}>{ rowData.newDistance ? Math.round(rowData.newDistance) : ''}</a>
                    // },

                    {
                        title: "Miles", field: "transportPODistance",
                        defaultSort: cookiesi.getAll()['orderBy'] == 'transportPODistance' ? cookiesi.getAll()['direction'] : false,
                        filterComponent: ({ columnDef, onFilterChanged }) => (
                            <FilterComponentBox pageName={'dispatched'} onFilterChanged={onFilterChanged} columnDef={columnDef} columnName={columnDef.field} />
                        ),
                        render: rowData => <a className={rowData.sameTrailerMarker === 1 &&  rowData.invoiceIsExpedited === 'Yes' ? 'markerExp' : rowData.invoiceIsExpedited === 'Yes' ? 'expeditedClass' : rowData.sameTrailerMarker === 1 ? 'sameMarker' : ''} target="_blank" href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID}>{rowData.transportPODistance ? Math.round(rowData.transportPODistance) : ''}</a>
                    },
                    {
                        title: "Del Status", field: "transportPODeliveryStatus",
                        defaultSort: cookiesi.getAll()['orderBy'] == 'transportPODeliveryStatus' ? cookiesi.getAll()['direction'] : false,
                        filterComponent: ({ columnDef, onFilterChanged }) => (
                            <FilterComponentBox pageName={'dispatched'} onFilterChanged={onFilterChanged} columnDef={columnDef} columnName={columnDef.field} />
                        ), render: rowData => <a className={rowData.sameTrailerMarker === 1 &&  rowData.invoiceIsExpedited === 'Yes' ? 'markerExp' : rowData.invoiceIsExpedited === 'Yes' ? 'expeditedClass' : rowData.sameTrailerMarker === 1 ? 'sameMarker' : ''} target="_blank" href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID}>{rowData.DeliveryStatus}</a>
                    },

                    {
                        title: "Prom Timeline", field: "invoicePromisedTimeline",
                        defaultSort: cookiesi.getAll()['orderBy'] == 'invoicePromisedTimeline' ? cookiesi.getAll()['direction'] : false,
                        filterComponent: ({ columnDef, onFilterChanged }) => (
                            <FilterComponentBox pageName={'dispatched'} onFilterChanged={onFilterChanged} columnDef={columnDef} columnName={columnDef.field} />
                        ),
                        render: rowData => <a className={rowData.sameTrailerMarker === 1 &&  rowData.invoiceIsExpedited === 'Yes' ? 'markerExp' : rowData.invoiceIsExpedited === 'Yes' ? 'expeditedClass' : rowData.sameTrailerMarker === 1 ? 'sameMarker' : ''} target="_blank" href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID}>{rowData.PromisedTimeline}</a>
                    },
                    {
                        title: "Prom Date",
                        defaultSort: cookiesi.getAll()['orderBy'] == 'invoicePromisedDate' ? cookiesi.getAll()['direction'] : false,
                        filterComponent: (props) => <CustomDatePicker pageName={'dispatched'} {...props} />,
                        field: "invoicePromisedDate", type: 'date', render: rowData => <a className={rowData.sameTrailerMarker === 1 &&  rowData.invoiceIsExpedited === 'Yes' ? 'markerExp' : rowData.invoiceIsExpedited === 'Yes' ? 'expeditedClass' : rowData.sameTrailerMarker === 1 ? 'sameMarker' : ''} target="_blank" href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID}>{(rowData.PromisedDate) ? moment(rowData.PromisedDate).format('MM/DD/YY') : ''}</a>
                    },
                    {
                        title: "Tier 1",
                        defaultSort: cookiesi.getAll()['orderBy'] == 'InvoiceLineHasTier1AddOn' ? cookiesi.getAll()['direction'] : false,
                        filterComponent: ({ columnDef, onFilterChanged }) => (
                            <FilterComponentBox pageName={'dispatched'} onFilterChanged={onFilterChanged} columnDef={columnDef} columnName={columnDef.field} />
                        ),
                        field: "InvoiceLineHasTier1AddOn", render: rowData => <a className={rowData.sameTrailerMarker === 1 &&  rowData.invoiceIsExpedited === 'Yes' ? 'markerExp' : rowData.invoiceIsExpedited === 'Yes' ? 'expeditedClass' : rowData.sameTrailerMarker === 1 ? 'sameMarker' : ''} target="_blank" href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID}>{rowData.InvoiceLineHasTier1AddOn}</a>
                    }
                ]}
                data={query =>
                    new Promise((resolve, reject) => {
                        const cookies = new Cookies();
                        cookies.remove('adminer_version');
                        if (query.filters.length > 0 || Object.keys(cookies.getAll()).length > 0) {
                            let url = API_BASE_URL + `/fetchOrderBasedOnColumn?`;
                            url += 'size=' + query.pageSize;
                            url += '&page=' + (query.page + 1);
                            url += '&orderNumber=' + 10;
                            if (query.orderBy) {
                                removeCookie("orderBy", { path: "/dispatched" });
                                removeCookie("direction", { path: "/dispatched" });
                                if (query.orderBy.field == 'invoiceRefNumber') {
                                    // url += '&sortField=invoiceRefNumber'
                                    setCookie("orderBy", "invoiceCustomerFullName", { path: '/dispatched' });
                                }
                                else {
                                    setCookie("orderBy", query.orderBy.field, { path: '/dispatched' });
                                    // url += '&sortField=' + query.orderBy.field

                                }
                                setCookie("direction", query.orderDirection, { path: '/dispatched' });
                                // setCookie("orderBy", query.orderBy.field, { path: '/dispatched' });
                                // setCookie("direction", query.orderDirection, { path: '/dispatched' });
                                // url += '&sortField=' + query.orderBy.field
                                // url += '&sortType=' + query.orderDirection
                            }
                            else {
                                url += '&sortField=invoiceTxnDate';
                                url += '&sortType=desc';
                                url += '&sortField=invoiceCustomerFullName';
                                url += '&sortType=asc';
                                url += '&sortField=resalePORecordID';
                                url += '&sortType=asc';
                            }

                            if (Object.keys(cookies.getAll()).length > 0) {

                                for (const key in cookies.cookies) {

                                    // console.log(moment.isDate(cookies.cookies[key].replace('"', '').replace('"', '')), 'momentinfo', cookies.cookies[key], key);
                                    if (cookies.cookies[key] == 'Invalid date') {
                                        // console.log('okkk')
                                        removeCookie(key, { path: "/dispatched" });
                                    }

                                    else {
                                        if (cookies.cookies[key] && moment.isDate(cookies.cookies[key])) {
                                            cookies.cookies[key] = moment(cookies.cookies[key]).format('YYYY-MM-DD')
                                        }

                                        if (cookies.cookies[key]) {
                                            if (key == 'invoiceRefNumber' && isNaN(cookies.cookies[key])) {
                                                url += '&columnName=' + 'invoiceCustomerFullName';
                                                if (cookies.cookies[key].toLowerCase() == 'yes') {
                                                    url += '&search=' + 1;
                                                } else if (cookies.cookies[key].toLowerCase() == 'no') {
                                                    url += '&search=' + 0;
                                                } else {
                                                    url += '&search=' + encodeURIComponent(cookies.cookies[key]);
                                                }
                                            }
                                            else if (key == 'orderBy') {
                                                // console.log('okkkkka', cookies.cookies[key])
                                                url += '&sortField=' + cookies.cookies[key]

                                                // url += '&sortType=' + cookies.cookies['direction']
                                            }
                                            else if (key == 'direction') {
                                                url += '&sortType=' + cookies.cookies[key]
                                            }
                                            else {
                                                url += '&columnName=' + key;
                                                if (cookies.cookies[key].toLowerCase() == 'yes') {
                                                    url += '&search=' + 1;
                                                } else if (cookies.cookies[key].toLowerCase() == 'no') {
                                                    url += '&search=' + 0;
                                                }

                                                else {
                                                    url += '&search=' + encodeURIComponent(cookies.cookies[key]);
                                                }
                                            }
                                        }
                                    }


                                }
                            } else {
                                query.filters.forEach((item) => {
                                    if (item.value && moment.isDate(item.value)) {
                                        item.value = moment(item.value).format('YYYY-MM-DD')
                                    }

                                    if (item.column.field == 'invoiceRefNumber' && isNaN(parseInt(item.value))) {
                                        url += '&columnName=' + 'invoiceCustomerFullName';
                                        url += '&search=' + encodeURIComponent(item.value);
                                    } else {
                                        url += '&columnName=' + item.column.field;
                                        url += '&search=' + encodeURIComponent(item.value);
                                    }
                                });
                            }


                            axios.get(url, {
                                headers: {
                                    Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN_NAME),
                                },
                            }).then(result => {
                                resolve({
                                    data: result.data.rows.map((data) => ({
                                        orderStatusRecordID: data.recordID,
                                        RecordID: data.invoiceRecordID,
                                        // Miles: data.resalePODistance,
                                        newDistance: data.newDistance ? data.newDistance : null,
                                        transportPODistance: data.transportPODistance ? data.transportPODistance : null,
                                        Miles: (data.resalePOLineItemDistance && data.resalePOLineItemDistance != 0) ? data.resalePOLineItemDistance : data.distanceBetweenPickupAndDeliveryAddress,
                                        Customer: `${data.invoiceCustomerFullName.replace(',', ' ')}*${data.invoiceRefNumber}`,
                                        InvoiceRefNumber: data.invoiceRefNumber,
                                        PaidDate: (data.invoicePaidDate) ? data.invoicePaidDate?.split('T')[0] : '',
                                        Terms: data.invoiceTerms,
                                        Expected: (data.resalePOExpectedDate) ? data.resalePOExpectedDate?.split('T')[0] : '',
                                        TransportPO: (data.transportPORecordID) ? data.transportPORefNumer : data.invoiceRefNumber + 'T',
                                        transportPOVendor: data.transportPOVendor,
                                        TransportTotal: data.transportPOTotalPrice,
                                        ScheduledFor: (data.transportPOScheduledDeliveryDate) ? data.transportPOScheduledDeliveryDate?.split('T')[0] : '',
                                        PromisedDate: data.invoicePromisedDate,
                                        Product: data.invoiceLineItemItemNameWithoutParent,
                                        transportPORefNumer: (data.transportPORecordID) ? data.transportPORefNumer : data.invoiceRefNumber + 'T',
                                        AssignedTo: data.invoiceAssignedToName,
                                        SupplierLocation: data.invoiceSupplierLocation,
                                        ResalePO: data.resalePORefNumer,
                                        TransportPORecordID: data.transportPORecordID,
                                        InvoiceLineHasTier1AddOn: (data.InvoiceLineHasTier1AddOn) ? 'True' : 'False',
                                        transportPOLineItemContainerNumber: data.transportPOLineItemContainerNumber,
                                        pickupCity: data.invoiceLineSupplierLocation,
                                        planningId: (data.planning) ? data.planning.id : null,

                                        pickupLocation: (data.resalePOPickupCity && data.resalePOPickupState) ? data.resalePOPickupCity + ', ' + data.resalePOPickupState : '',

                                        shipCity: (data.invoiceShipCity) ? data.invoiceShipCity : '',
                                        shipState: (data.invoiceShipState) ? data.invoiceShipState : '',

                                        pickupCity: (data.resalePOPickupCity) ? data.resalePOPickupCity : '',
                                        pickupState: (data.resalePOPickupState) ? data.resalePOPickupState : '',

                                        invoiceShipAddress1: data.invoiceShipAddress1,
                                        invoiceShipAddress2: data.invoiceShipAddress2,
                                        invoiceShipAddress3: data.invoiceShipAddress3,
                                        invoiceShipCity: data.invoiceShipCity,
                                        invoiceShipZip: data.invoiceShipZip,
                                        resalePOPickupAddress1: data.resalePOPickupAddress1,
                                        resalePOPickupCity: data.resalePOPickupCity,
                                        resalePOPickupZipCode: data.resalePOPickupZipCode,
                                        invoiceIsExpedited: data.invoiceIsExpedited
                                            ? "Yes"
                                            : "No",

                                        invoiceLineItem_RecordID: data.invoiceLineItem_RecordID,
                                        solidSurface: (data.invoice?.solidSurface) ? data.invoice.solidSurface : 0,
                                        towTruck: (data.invoice?.towTruck) ? data.invoice.towTruck : 0,
                                        resalePOSupplierLocation: data.resalePOSupplierLocation,
                                        ResalePORecordID: data.resalePORecordID,
                                        Dropoff: (data.resalePOShipCity && data.resalePOShipState) ? data.resalePOShipCity + ' ' + data.resalePOShipState : '',
                                        distance: data.resalePODistance,
                                        entity: data.entity,
                                        driverName: (data.planning?.driverName) ? data.planning.driverName : '',
                                        // vendorName: (data.purchaseOrder) ? data.purchaseOrder.vendor : null,
                                        container: (data.resalePOLineItemContainerNumber) ? data.resalePOLineItemContainerNumber : '',
                                        ResaleVendor: data.resalePOVendor,
                                        Resale: data.resalePOReleaseNumber,
                                        ReleaseDate: (data.resalePOReleaseDate) ? data.resalePOReleaseDate?.split('T')[0] : '',
                                        ResaleTotal: data.resalePOTotalPrice,
                                        ExpectedDeliveryDate: (data.resalePOExpectedDate) ? data.resalePOExpectedDate?.split('T')[0] : '',
                                        LastDepotCall: (data.resalePOLastDepotCallDate) ? data.resalePOLastDepotCallDate?.split('T')[0] : '',
                                        sameTrailerMarker: data.sameTrailerMarker,
                                        DeliveryStatus: data.transportPODeliveryStatus,
                                        PromisedTimeline: (data.invoicePromisedTimeline) ? data.invoicePromisedTimeline : '',
                                        PromisedDate: (data.invoicePromisedDate) ? data.invoicePromisedDate?.split('T')[0] : '',
                                    })),
                                    page: result.data.currentPage - 1,
                                    totalCount: result.data.totalItems,
                                });
                                setPageRefresh(false);
                            });
                        } else {
                            let url = API_BASE_URL + `/fetchCompletedOrder?`;
                            url += 'size=' + query.pageSize;
                            url += '&page=' + (query.page + 1);
                            url += '&orderNumber=' + 10;
                            if (query.orderBy) {
                                setCookie("orderBy", query.orderBy.field, { path: '/dispatched' });
                                setCookie("direction", query.orderDirection, { path: '/dispatched' });
                                url += '&sortField=' + query.orderBy.field
                                url += '&sortType=' + query.orderDirection
                            } else {
                                switch (2) {
                                    case '2':
                                        url += '&sortField=invoicePaidDate';
                                        url += '&sortType=desc';
                                        url += '&sortField=invoiceCustomerFullName';
                                        url += '&sortType=asc';
                                        url += '&sortField=resalePORecordID';
                                        url += '&sortType=asc';
                                        break;
                                    case '7':
                                        url += '&sortField=invoicePaidDate';
                                        url += '&sortType=asc';
                                        url += '&sortField=invoiceCustomerFullName';
                                        url += '&sortType=asc';
                                        url += '&sortField=resalePORecordID';
                                        url += '&sortType=asc';
                                        break;
                                    case '15':
                                        url += '&sortField=invoicePaidDate';
                                        url += '&sortType=asc';
                                        url += '&sortField=invoiceCustomerFullName';
                                        url += '&sortType=asc';
                                        url += '&sortField=resalePORecordID';
                                        url += '&sortType=asc';
                                        break;
                                    case '10':
                                        url += '&sortField=transportPOScheduledDeliveryDate';
                                        url += '&sortType=asc';
                                        url += '&sortField=invoiceCustomerFullName';
                                        url += '&sortType=asc';
                                        url += '&sortField=resalePORecordID';
                                        url += '&sortType=asc';
                                        break;
                                    default:
                                        url += '&sortField=invoiceTxnDate';
                                        url += '&sortType=desc';
                                        url += '&sortField=invoiceCustomerFullName';
                                        url += '&sortType=asc';
                                        url += '&sortField=resalePORecordID';
                                        url += '&sortType=asc';
                                }
                            }

                            if (query.search) {
                                url += '&search=' + encodeURIComponent(query.search);
                            }

                            if (query.filters && !query.search) {
                                query.filters.forEach((item) => {
                                    url += '&search=' + encodeURIComponent(item.value);
                                });
                            }

                            if (props.endDate && props.startDate) {
                                url += '&from=' + props.startDate;
                                url += '&to=' + props.endDate;
                            }

                            axios.get(url, {
                                headers: {
                                    Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN_NAME),
                                },
                            }).then(result => {
                                resolve({
                                    data: result.data.rows.map((data) => ({
                                        orderStatusRecordID: data.recordID,
                                        RecordID: data.invoiceRecordID,
                                        // Miles: data.resalePODistance,
                                        newDistance: data.newDistance ? data.newDistance : null,
                                        transportPODistance: data.transportPODistance ? data.transportPODistance : null,
                                        Miles: (data.resalePOLineItemDistance && data.resalePOLineItemDistance != 0) ? data.resalePOLineItemDistance : data.distanceBetweenPickupAndDeliveryAddress,
                                        Customer: `${data.invoiceCustomerFullName.replace(',', ' ')}*${data.invoiceRefNumber}`,
                                        InvoiceRefNumber: data.invoiceRefNumber,
                                        PaidDate: (data.invoicePaidDate) ? data.invoicePaidDate?.split('T')[0] : '',
                                        Terms: data.invoiceTerms,
                                        Expected: (data.resalePOExpectedDate) ? data.resalePOExpectedDate?.split('T')[0] : '',
                                        TransportPO: (data.transportPORecordID) ? data.transportPORefNumer : data.invoiceRefNumber + 'T',
                                        transportPOVendor: data.transportPOVendor,
                                        TransportTotal: data.transportPOTotalPrice,
                                        ScheduledFor: (data.transportPOScheduledDeliveryDate) ? data.transportPOScheduledDeliveryDate?.split('T')[0] : '',
                                        PromisedDate: data.invoicePromisedDate,
                                        Product: data.invoiceLineItemItemNameWithoutParent,
                                        transportPORefNumer: (data.transportPORecordID) ? data.transportPORefNumer : data.invoiceRefNumber + 'T',
                                        AssignedTo: data.invoiceAssignedToName,
                                        SupplierLocation: data.invoiceSupplierLocation,
                                        ResalePO: data.resalePORefNumer,
                                        TransportPORecordID: data.transportPORecordID,
                                        InvoiceLineHasTier1AddOn: (data.InvoiceLineHasTier1AddOn) ? 'True' : 'False',
                                        transportPOLineItemContainerNumber: data.transportPOLineItemContainerNumber,
                                        pickupCity: data.invoiceLineSupplierLocation,
                                        planningId: (data.planning) ? data.planning.id : null,
                                        invoiceIsExpedited: data.invoiceIsExpedited
                                            ? "Yes"
                                            : "No",

                                        pickupLocation: (data.resalePOPickupCity && data.resalePOPickupState) ? data.resalePOPickupCity + ', ' + data.resalePOPickupState : '',

                                        shipCity: (data.invoiceShipCity) ? data.invoiceShipCity : '',
                                        shipState: (data.invoiceShipState) ? data.invoiceShipState : '',

                                        pickupCity: (data.resalePOPickupCity) ? data.resalePOPickupCity : '',
                                        pickupState: (data.resalePOPickupState) ? data.resalePOPickupState : '',

                                        invoiceShipAddress1: data.invoiceShipAddress1,
                                        invoiceShipAddress2: data.invoiceShipAddress2,
                                        invoiceShipAddress3: data.invoiceShipAddress3,
                                        invoiceShipCity: data.invoiceShipCity,
                                        invoiceShipZip: data.invoiceShipZip,
                                        resalePOPickupAddress1: data.resalePOPickupAddress1,
                                        resalePOPickupCity: data.resalePOPickupCity,
                                        resalePOPickupZipCode: data.resalePOPickupZipCode,
                                        sameTrailerMarker: data.sameTrailerMarker,

                                        invoiceLineItem_RecordID: data.invoiceLineItem_RecordID,
                                        solidSurface: (data.invoice?.solidSurface) ? data.invoice.solidSurface : 0,
                                        towTruck: (data.invoice?.towTruck) ? data.invoice.towTruck : 0,
                                        resalePOSupplierLocation: data.resalePOSupplierLocation,
                                        ResalePORecordID: data.resalePORecordID,
                                        Dropoff: (data.resalePOShipCity && data.resalePOShipState) ? data.resalePOShipCity + ' ' + data.resalePOShipState : '',
                                        distance: data.resalePODistance,
                                        entity: data.entity,
                                        driverName: (data.planning?.driverName) ? data.planning.driverName : '',
                                        // vendorName: (data.purchaseOrder) ? data.purchaseOrder.vendor : null,
                                        container: (data.resalePOLineItemContainerNumber) ? data.resalePOLineItemContainerNumber : '',
                                        ResaleVendor: data.resalePOVendor,
                                        Resale: data.resalePOReleaseNumber,
                                        ReleaseDate: (data.resalePOReleaseDate) ? data.resalePOReleaseDate?.split('T')[0] : '',
                                        ResaleTotal: data.resalePOTotalPrice,
                                        ExpectedDeliveryDate: (data.resalePOExpectedDate) ? data.resalePOExpectedDate?.split('T')[0] : '',
                                        LastDepotCall: (data.resalePOLastDepotCallDate) ? data.resalePOLastDepotCallDate?.split('T')[0] : '',

                                        DeliveryStatus: data.transportPODeliveryStatus,
                                        PromisedTimeline: (data.invoicePromisedTimeline) ? data.invoicePromisedTimeline : '',
                                        PromisedDate: (data.invoicePromisedDate) ? data.invoicePromisedDate?.split('T')[0] : '',
                                    })),
                                    page: result.data.currentPage - 1,
                                    totalCount: result.data.totalItems,
                                });
                                setPageRefresh(false);
                            })
                        }
                    })
                }
                components={{
                    Toolbar: props => (
                        <ResetFillterButton elsement={props} resetFilter={resetFilter} />
                    )
                }}

                options={{
                    thirdSortClick: false,

                    // rowStyle: rowData => ({
                    //     backgroundColor: (rowData.Terms == "Rent To Own" && rowData.solidSurface == 1 ) ? '#90EE90' : (rowData.Terms == "Rent To Own") ? '#ffda73' : (rowData.solidSurface == 1) ? '#7bede9' : '#fff'
                    // }),
                    // rowStyle: rowData => ({
                    //     backgroundColor: (rowData.invoiceTerms == "Rent To Own" && rowData.towTruck == 1 ) ? '#F7AE77' : (rowData.invoiceTerms == "Rent To Own" && rowData.solidSurface == 1 ) ? '#90EE90' : (rowData.towTruck == 1 && rowData.solidSurface == 1 ) ? '#F0BBF0' : (rowData.invoiceTerms == "Rent To Own") ? '#ffda73' : (rowData.solidSurface == 1) ? '#7bede9' : (rowData.towTruck == 1) ? 'pink' : '#fff'
                    // }),
                    rowStyle: rowData => ({
                        backgroundColor: (rowData.Terms == "Rent To Own" && rowData.towTruck == 1) ? '#F7AE77' : (rowData.Terms == "Rent To Own" && rowData.solidSurface == 1) ? '#90EE90' : (rowData.towTruck == 1 && rowData.solidSurface == 1) ? '#F0BBF0' : (rowData.Terms == "Rent To Own") ? '#ffda73' : (rowData.solidSurface == 1) ? '#7bede9' : (rowData.towTruck == 1) ? 'pink' : '#fff'
                    }),

                    pageSize: 50,
                    debounceInterval: 1500,
                    pageSizeOptions: [50, 100, 200],
                    filtering: true
                }}
            />

            <style jsx="true">{`
            .MuiTableCell-root{
                padding: 2px !important;
                font-family: 'Source Sans Pro', sans-serif;
            }

            .btn-set{
                position: absolute;
                top: 18px;
                font-size: 17px;
                right: 255px;
                color: #000;
                padding: 4px 6px;
                border: 1px solid #79a3ff;
            }

            .input-wid-set {
                border: none;
                border-bottom: 1px solid #949494;
                position: relative;
                top: 3px;
                width: 100%;
            }

            .MuiTableCell-root a {
                font-size:12px !important;
                font-family: 'Source Sans Pro', sans-serif;
                overflow: visible;
                display: block;
                text-overflow: clip;
                word-break: break-word;
                white-space: break-spaces!important;            
            }

            .MuiTableHead-root th {
                font-size: 12px !important;
                font-weight: 600;
                background: #fbf5f5;
            }
            .MuiTableHead-root th:nth-child(6) {
                width: 65px!important;
            }
            .MuiTableCell-root {
                width: fit-content;
            }
            .MuiTableCell-root a {
                width: 45px;
            }
            tr td:first-child.MuiTableCell-root a, tr th:first-child span.MuiButtonBase-root.MuiTableSortLabel-root {
                width: 100%!important;
            }
        `}</style>
        </div>
    );
};

export default DispachedBox;