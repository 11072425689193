import { API_BASE_URL , ACCESS_TOKEN_NAME } from '../../constants/apiConstants';
import axios from 'axios'

export default class DashboardServices {
  static async fetchDashboardCompanyCount(date) {
    const token = localStorage.getItem(ACCESS_TOKEN_NAME);
    const response = await axios.get(API_BASE_URL + `/getDashboardCompanyCount?date=${date}`, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    return response.data;
  }

  static async fetchDashboardCount(date) {
    const token = localStorage.getItem(ACCESS_TOKEN_NAME);
    const response = await axios.get(API_BASE_URL + `/getDashboardCount?userId=${localStorage.getItem('userId')}&date=${date}`, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    return response.data;
  }

  static async fetchInvoice(id) {
    const token = localStorage.getItem(ACCESS_TOKEN_NAME);
    const response = await axios.get(API_BASE_URL + `/findSingleOrder?status=${id}`, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    return response.data;
  }
}

