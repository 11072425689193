import GenericField from './genericField';
import * as yup from 'yup';
import moment from 'moment';

export default class DateField extends GenericField {
  constructor(name, label, { required = false } = {}) {
    super(name, label);
    this.required = required;
  }

  forView(value) {
    return value;
  }

  forFormInitialValue(value) {
    return value ? moment(value, 'YYYY-MM-DD') : null;
  }

  forForm() {
    let yupChain = yup
      .mixed()
      .nullable(true)
      .label(this.label)
      .test(
        'is-date',
        'is invalid',
        (value) => {
          if (!value) {
            return true;
          }

          return moment(value, 'YYYY-MM-DD').isValid();
        },
      )
      .transform(
        (value) =>
          value ? moment(value).format('YYYY-MM-DD') : null,
      );

    if (this.required) {
      yupChain = yupChain.required();
    }

    return yupChain;
  }

}
