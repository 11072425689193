import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import MaterialTable from "material-table";
import axios from 'axios';
import PlanningServices from '../DriverPlanning/PlanningServices';
import avoidNull from '../../utils/AvoidNull';
import avoidNull2 from '../../utils/AvoidNull2';
import removeAddress from '../../utils/RemoveAddress';
import { ACCESS_TOKEN_NAME, ACCESS_TOKEN_NAME_USER, API_BASE_URL } from '../../constants/apiConstants';
import CustomDatePicker from "../../utils/customDatePicker";
import FilterComponentBox from "../../utils/FilterComponentBox";
import ResetFillterButton from "../../utils/ResetFillterButton";
import { useCookies } from 'react-cookie';
import Cookies from 'universal-cookie';

const DispachedDepotBox = (props) => {
    const [pageRefresh, setPageRefresh] = useState(false);
    const [cookies, setCookie, removeCookie] = useCookies(['dispatcheddepot']);

    const [orderData, setOrderData] = useState();
    const [loading, setloading] = useState(false);

    const fetchDispatchedOrder = async (driverInfo) => {
        setloading(true);
        const response = await axios.post(API_BASE_URL + `/fetchDispatchedOrder`, { driverInfo }, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN_NAME),
            },
        })
        setloading(false);
        setOrderData(response.data);
    }

    const tableRef = useRef(null);

    const createPlan = async (product, invoiceRefNumber, resalePORefNumer, resaleVendor, transportPORecordID) => {
        const response = await PlanningServices.getPlanningInfo(invoiceRefNumber);
            if (response[0] && response[0].id) {
                window.open("/exitingPurchaseorder/" + result[0].id + "/" + transportPORecordID);
          
            }
            
        const payload = {
            contactId: 0,
            locationId: 123,
            customerConfirmation: 0,
            driveConfirmation: 0,
            size: 0,
            invoiceId: invoiceRefNumber,
            City: '',
            drivername: '',
            product: product,
            State: '',
            transportPORecordID,
            DropState: '',
            DropCity: '',
            Dist: '',
            resalePORefNumer,
            isExpedited: 0,
            vendorFullName: resaleVendor,
            date: ''
        };
        const result = await PlanningServices.savePlanning(payload);
        if (result) {
            //fetchDispatchedOrder({});
            window.open("/exitingPurchaseorder/" + result[0].lastinsterid + "/" + transportPORecordID);
        }
    }
    /*
    useEffect(()=>{
        fetchDispatchedOrder(props.driverInfo);
    },[props.driverInfo]);

    */
    const cookiesi = new Cookies();

    useEffect(() => {
        fetchDispatchedOrder({});
    }, []);

    const resetFilter = async () => {

        const test = document.querySelectorAll(".material-icons");
        for(let tests of test)
        {
        if(tests.getAttribute("aria-label") == 'clear')
        {
            tests.click();
        }
        }
        const cookies = new Cookies();
        if (Object.keys(cookies.getAll()).length > 0) {
            setPageRefresh(true);
        }
        else {
            setPageRefresh(true);
        }
        await removeCookie("orderBy", { path: "/dispatcheddepot" });
        await removeCookie("direction", { path: "/dispatcheddepot" });
        await removeCookie("invoiceRefNumber", { path: "/dispatcheddepot" });
        await removeCookie("invoicePaidDate", { path: "/dispatcheddepot" });
        await removeCookie("invoiceTerms", { path: "/dispatcheddepot" });
        await removeCookie("invoiceLineItemItemNameWithoutParent", { path: "/dispatcheddepot" });
        await removeCookie("invoiceAssignedToName", { path: "/dispatcheddepot" });
        await removeCookie("resalePOPickupCity", { path: "/dispatcheddepot" });
        await removeCookie("resalePORefNumer", { path: "/dispatcheddepot" });
        await removeCookie("resalePOReleaseNumber", { path: "/dispatcheddepot" });
        await removeCookie("resalePOReleaseDate", { path: "/dispatcheddepot" });
        await removeCookie("resalePOTotalPrice", { path: "/dispatcheddepot" });
        await removeCookie("resalePOExpectedDate", { path: "/dispatcheddepot" });
        await removeCookie("resalePOLastDepotCallDate", { path: "/dispatcheddepot" });
        await removeCookie("newDistance", { path: "/dispatcheddepot" });
        await removeCookie("transportPODistance", { path: "/dispatcheddepot" })
        await removeCookie("invoiceShipCity", { path: "/dispatcheddepot" });
        await removeCookie("transportPOLineItemContainerNumber", { path: "/dispatcheddepot" });
        await removeCookie("transportPORefNumer", { path: "/dispatcheddepot" });
        await removeCookie("transportPOVendor", { path: "/dispatcheddepot" });
        await removeCookie("transportPOTotalPrice", { path: "/dispatcheddepot" });
        await removeCookie("transportPOScheduledDeliveryDate", { path: "/dispatcheddepot" });
        await removeCookie("transportPODeliveryStatus", { path: "/dispatcheddepot" });
        await removeCookie("invoicePromisedTimeline", { path: "/dispatcheddepot" });
        await removeCookie("invoicePromisedDate", { path: "/dispatcheddepot" });

        await removeCookie("invoiceTxnDate", { path: "/dispatcheddepot" });
        await removeCookie("invoiceCustomerNotes", { path: "/dispatcheddepot" });
        await removeCookie("invoiceBalanceRemaining", { path: "/dispatcheddepot" });
        await removeCookie("invoiceSupplierLocation", { path: "/dispatcheddepot" });
        await removeCookie("resalePO", { path: "/dispatcheddepot" });
        await removeCookie("InvoiceLineHasTier1AddOn", { path: "/dispatched" });
        await removeCookie("driverName", { path: "/dispatcheddepot" });
        // await removeCookie("vendorName", { path: "/dispatcheddepot" });
        //await tableRef.current.onAllSelected(false);
        //await tableRef.current.onSearchChange("")
        
    }



    return (
        <div className="col-md-12">

            <MaterialTable
                title=""
                tableRef={tableRef}
                key={pageRefresh}
                columns={[
                    { title: "RecordID", field: "RecordID", hidden: true },
                    {
                        title: "Customer", field: "invoiceRefNumber",
                        defaultSort: cookiesi.getAll()['orderBy'] == 'invoiceRefNumber' ? cookiesi.getAll()['direction'] : false,
                        filterComponent: ({ columnDef, onFilterChanged }) => (
                            <FilterComponentBox pageName={'dispatcheddepot'} onFilterChanged={onFilterChanged} columnDef={columnDef} columnName={columnDef.field} />
                        ),
                        render: rowData => <a className={`breakwordwichpadding ${rowData.sameTrailerMarker === 1 &&  rowData.invoiceIsExpedited === 'Yes' ? 'markerExp' : rowData.invoiceIsExpedited === 'Yes' ? 'expeditedClass' : rowData.sameTrailerMarker === 1 ? 'sameMarker' : ''}`} target="_blank" href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID}><strong style={{ "font-weight": "550" }} className="title-strong">{rowData.Customer?.split('*')[0]}</strong><br />{rowData.Customer?.split('*')[1]}</a>
                    },
                    {
                        title: "Paid Date",
                        defaultSort: cookiesi.getAll()['orderBy'] == 'invoicePaidDate' ? cookiesi.getAll()['direction'] : false,
                        filterComponent: (props) => <CustomDatePicker pageName={'dispatcheddepot'} {...props} />,
                        field: "invoicePaidDate",
                        filterComponent: (props) => <CustomDatePicker pageName={'dispatcheddepot'} {...props} />,
                        type: 'date', render: rowData => <a className={rowData.sameTrailerMarker === 1 &&  rowData.invoiceIsExpedited === 'Yes' ? 'markerExp' : rowData.invoiceIsExpedited === 'Yes' ? 'expeditedClass' : rowData.sameTrailerMarker === 1 ? 'sameMarker' : ''} target="_blank" href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID}>{(rowData.PaidDate) ? moment(rowData.PaidDate).format('MM/DD/YY') : ''}</a>
                    },

                    {
                        title: "Terms", field: "invoiceTerms",
                        defaultSort: cookiesi.getAll()['orderBy'] == 'invoiceTerms' ? cookiesi.getAll()['direction'] : false,
                        filterComponent: ({ columnDef, onFilterChanged }) => (
                            <FilterComponentBox pageName={'dispatcheddepot'} onFilterChanged={onFilterChanged} columnDef={columnDef} columnName={columnDef.field} />
                        ),
                        render: rowData => <a className={rowData.sameTrailerMarker === 1 &&  rowData.invoiceIsExpedited === 'Yes' ? 'markerExp' : rowData.invoiceIsExpedited === 'Yes' ? 'expeditedClass' : rowData.sameTrailerMarker === 1 ? 'sameMarker' : ''} target="_blank" href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID}>{rowData.Terms}</a>
                    },
                    {
                        title: "Product", field: "invoiceLineItemItemNameWithoutParent",
                        defaultSort: cookiesi.getAll()['orderBy'] == 'invoiceLineItemItemNameWithoutParent' ? cookiesi.getAll()['direction'] : false,
                        filterComponent: ({ columnDef, onFilterChanged }) => (
                            <FilterComponentBox pageName={'dispatcheddepot'} onFilterChanged={onFilterChanged} columnDef={columnDef} columnName={columnDef.field} />
                        ),
                        render: rowData => <a className={rowData.sameTrailerMarker === 1 &&  rowData.invoiceIsExpedited === 'Yes' ? 'markerExp' : rowData.invoiceIsExpedited === 'Yes' ? 'expeditedClass' : rowData.sameTrailerMarker === 1 ? 'sameMarker' : ''} target="_blank" href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID}>{rowData.Product}</a>
                    },
                    {
                        title: "Assign To", field: "invoiceAssignedToName",
                        defaultSort: cookiesi.getAll()['orderBy'] == 'invoiceAssignedToName' ? cookiesi.getAll()['direction'] : false,
                        filterComponent: ({ columnDef, onFilterChanged }) => (
                            <FilterComponentBox pageName={'dispatcheddepot'} onFilterChanged={onFilterChanged} columnDef={columnDef} columnName={columnDef.field} />
                        ),
                        render: rowData => <a className={`breakword ${rowData.sameTrailerMarker === 1 &&  rowData.invoiceIsExpedited === 'Yes' ? 'markerExp' : rowData.invoiceIsExpedited === 'Yes' ? 'expeditedClass' : rowData.sameTrailerMarker === 1 ? 'sameMarker' : ''}`} target="_blank" href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID}>{rowData.AssignedTo}</a>
                    },
                    {
                        title: "Supp Loc", field: "invoiceSupplierLocation",
                        defaultSort: cookiesi.getAll()['orderBy'] == 'invoiceSupplierLocation' ? cookiesi.getAll()['direction'] : false,
                        filterComponent: ({ columnDef, onFilterChanged }) => (
                            <FilterComponentBox pageName={'dispatcheddepot'} onFilterChanged={onFilterChanged} columnDef={columnDef} columnName={columnDef.field} />
                        ),
                        render: rowData => <a className={`breakword ${rowData.sameTrailerMarker === 1 &&  rowData.invoiceIsExpedited === 'Yes' ? 'markerExp' : rowData.invoiceIsExpedited === 'Yes' ? 'expeditedClass' : rowData.sameTrailerMarker === 1 ? 'sameMarker' : ''}`} target="_blank" href={'https://www.google.com/maps/place/' + rowData.resalePOSupplierLocation}>{rowData.resalePOSupplierLocation}</a>
                    },
                    {
                        title: "Res PO #", field: "resalePO",
                        defaultSort: cookiesi.getAll()['orderBy'] == 'resalePO' ? cookiesi.getAll()['direction'] : false,
                        filterComponent: ({ columnDef, onFilterChanged }) => (
                            <FilterComponentBox pageName={'dispatcheddepot'} onFilterChanged={onFilterChanged} columnDef={columnDef} columnName={columnDef.field} />
                        ),
                        render: rowData => <a className={rowData.sameTrailerMarker === 1 &&  rowData.invoiceIsExpedited === 'Yes' ? 'markerExp' : rowData.invoiceIsExpedited === 'Yes' ? 'expeditedClass' : rowData.sameTrailerMarker === 1 ? 'sameMarker' : ''} target="_blank" href={'/resalePurchaseOrder/' + rowData.ResalePORecordID + '/' + rowData.RecordID}>{rowData.ResalePO}</a>
                    },

                    {
                        title: "Rele #", field: "resalePOReleaseNumber",
                        defaultSort: cookiesi.getAll()['orderBy'] == 'resalePOReleaseNumber' ? cookiesi.getAll()['direction'] : false,
                        filterComponent: ({ columnDef, onFilterChanged }) => (
                            <FilterComponentBox pageName={'dispatcheddepot'} onFilterChanged={onFilterChanged} columnDef={columnDef} columnName={columnDef.field} />
                        ),
                        render: rowData => <a className={rowData.sameTrailerMarker === 1 &&  rowData.invoiceIsExpedited === 'Yes' ? 'markerExp' : rowData.invoiceIsExpedited === 'Yes' ? 'expeditedClass' : rowData.sameTrailerMarker === 1 ? 'sameMarker' : ''} target="_blank" href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID}>{rowData.Resale}</a>
                    },

                    {
                        title: "Release Date",
                        defaultSort: cookiesi.getAll()['orderBy'] == 'resalePOReleaseDate' ? cookiesi.getAll()['direction'] : false,
                        filterComponent: (props) => <CustomDatePicker pageName={'dispatcheddepot'} {...props} />,
                        field: "resalePOReleaseDate", type: 'date', render: rowData => <a className={rowData.sameTrailerMarker === 1 &&  rowData.invoiceIsExpedited === 'Yes' ? 'markerExp' : rowData.invoiceIsExpedited === 'Yes' ? 'expeditedClass' : rowData.sameTrailerMarker === 1 ? 'sameMarker' : ''} target="_blank" href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID}>{(rowData.ReleaseDate) ? moment(rowData.ReleaseDate).format('MM/DD/YY') : ''}</a>
                    },

                    {
                        title: "Res Total",
                        defaultSort: cookiesi.getAll()['orderBy'] == 'resalePOTotalPrice' ? cookiesi.getAll()['direction'] : false,
                        filterComponent: ({ columnDef, onFilterChanged }) => (
                            <FilterComponentBox pageName={'dispatcheddepot'} onFilterChanged={onFilterChanged} columnDef={columnDef} columnName={columnDef.field} />
                        ),
                        field: "resalePOTotalPrice", render: rowData => <a className={rowData.sameTrailerMarker === 1 &&  rowData.invoiceIsExpedited === 'Yes' ? 'markerExp' : rowData.invoiceIsExpedited === 'Yes' ? 'expeditedClass' : rowData.sameTrailerMarker === 1 ? 'sameMarker' : ''} target="_blank" href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID}>{rowData.ResaleTotal}</a>
                    },
                    {
                        title: "Expected",
                        defaultSort: cookiesi.getAll()['orderBy'] == 'resalePOExpectedDate' ? cookiesi.getAll()['direction'] : false,
                        filterComponent: (props) => <CustomDatePicker pageName={'dispatcheddepot'} {...props} />,
                        field: "resalePOExpectedDate", type: 'date', render: rowData => <a className={rowData.sameTrailerMarker === 1 &&  rowData.invoiceIsExpedited === 'Yes' ? 'markerExp' : rowData.invoiceIsExpedited === 'Yes' ? 'expeditedClass' : rowData.sameTrailerMarker === 1 ? 'sameMarker' : ''} target="_blank" href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID}>{(rowData.Expected) ? moment(rowData.Expected).format('MM/DD/YY') : ''}</a>
                    },
                    {
                        title: "Last Depot Call",
                        filterComponent: (props) => <CustomDatePicker pageName={'dispatcheddepot'} {...props} />,
                        field: "resalePOLastDepotCallDate", type: 'date', render: rowData => <a className={rowData.sameTrailerMarker === 1 &&  rowData.invoiceIsExpedited === 'Yes' ? 'markerExp' : rowData.invoiceIsExpedited === 'Yes' ? 'expeditedClass' : rowData.sameTrailerMarker === 1 ? 'sameMarker' : ''} target="_blank" href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID}>{(rowData.LastDepotCall) ? moment(rowData.LastDepotCall).format('MM/DD/YY') : ''}</a>
                    },
                    {
                        title: "Pickup Loc",
                        defaultSort: cookiesi.getAll()['orderBy'] == 'resalePOPickupCity' ? cookiesi.getAll()['direction'] : false,
                        filterComponent: ({ columnDef, onFilterChanged }) => (
                            <FilterComponentBox pageName={'dispatcheddepot'} onFilterChanged={onFilterChanged} columnDef={columnDef} columnName={columnDef.field} />
                        ),
                        field: "resalePOPickupCity",
                        filterComponent: ({ columnDef, onFilterChanged }) => (
                            <FilterComponentBox pageName={'dispatcheddepot'} onFilterChanged={onFilterChanged} columnDef={columnDef} columnName={columnDef.field} />
                        ),
                        render: rowData => <a className={`breakword ${rowData.sameTrailerMarker === 1 &&  rowData.invoiceIsExpedited === 'Yes' ? 'markerExp' : rowData.invoiceIsExpedited === 'Yes' ? 'expeditedClass' : rowData.sameTrailerMarker === 1 ? 'sameMarker' : ''}`} target="_blank" href={'https://www.google.com/maps/place/' + rowData.pickupLocation}>{rowData.pickupCity}<br />{rowData.pickupState}</a>
                    },
                    {
                        title: "Del City/State", field: "invoiceShipCity",
                        defaultSort: cookiesi.getAll()['orderBy'] == 'invoiceShipCity' ? cookiesi.getAll()['direction'] : false,
                        filterComponent: ({ columnDef, onFilterChanged }) => (
                            <FilterComponentBox pageName={'dispatcheddepot'} onFilterChanged={onFilterChanged} columnDef={columnDef} columnName={columnDef.field} />
                        ),
                        render: (rowData) => {
                            return <a className={`breakword ${rowData.sameTrailerMarker === 1 &&  rowData.invoiceIsExpedited === 'Yes' ? 'markerExp' : rowData.invoiceIsExpedited === 'Yes' ? 'expeditedClass' : rowData.sameTrailerMarker === 1 ? 'sameMarker' : ''}`} target="_blank" href={`https://www.google.com/maps/dir/?api=1&origin=${avoidNull(rowData.resalePOPickupAddress1)}${avoidNull(rowData.resalePOPickupCity)}${avoidNull2(rowData.resalePOPickupZipCode)}&destination=${removeAddress(rowData.invoiceShipAddress1,rowData.shipState)}${removeAddress(rowData.invoiceShipAddress2, rowData.shipState)}${removeAddress(rowData.invoiceShipAddress3, rowData.shipState)}${avoidNull(rowData.invoiceShipCity)}${avoidNull(rowData.shipState)}${avoidNull2(rowData.invoiceShipZip)}`}>{rowData.invoiceShipCity}<br />{rowData.shipState}</a>
                        }
                    },
                    
                    // {
                    //     title: "Vendor", field: "vendorName",
                    //     defaultSort: cookiesi.getAll()['orderBy'] == 'vendorName' ? cookiesi.getAll()['direction'] : false,
                    //     filterComponent: ({ columnDef, onFilterChanged }) => (
                    //         <FilterComponentBox pageName={'dispatcheddepot'} onFilterChanged={onFilterChanged} columnDef={columnDef} columnName={columnDef.field} />
                    //     ),
                    //     render: (rowData) => {
                    //         return <a className={`breakword ${rowData.sameTrailerMarker === 1 &&  rowData.invoiceIsExpedited === 'Yes' ? 'markerExp' : rowData.invoiceIsExpedited === 'Yes' ? 'expeditedClass' : rowData.sameTrailerMarker === 1 ? 'sameMarker' : ''}`} target="_blank" href={`https://www.google.com/maps/dir/?api=1&origin=${avoidNull(rowData.resalePOPickupAddress1)}${avoidNull(rowData.resalePOPickupCity)}${avoidNull2(rowData.resalePOPickupZipCode)}&destination=${removeAddress(rowData.invoiceShipAddress1,rowData.shipState)}${removeAddress(rowData.invoiceShipAddress2, rowData.shipState)}${removeAddress(rowData.invoiceShipAddress3, rowData.shipState)}${avoidNull(rowData.invoiceShipCity)}${avoidNull(rowData.shipState)}${avoidNull2(rowData.invoiceShipZip)}`}>{rowData.vendorName}</a>
                    //     }
                    // },
                    {
                        title: "Container #", field: "transportPOLineItemContainerNumber",
                        defaultSort: cookiesi.getAll()['orderBy'] == 'transportPOLineItemContainerNumber' ? cookiesi.getAll()['direction'] : false,
                        filterComponent: ({ columnDef, onFilterChanged }) => (
                            <FilterComponentBox pageName={'dispatcheddepot'} onFilterChanged={onFilterChanged} columnDef={columnDef} columnName={columnDef.field} />
                        ),
                        render: rowData => <a className={rowData.sameTrailerMarker === 1 &&  rowData.invoiceIsExpedited === 'Yes' ? 'markerExp' : rowData.invoiceIsExpedited === 'Yes' ? 'expeditedClass' : rowData.sameTrailerMarker === 1 ? 'sameMarker' : ''} target="_blank" href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID}>{rowData.transportPOLineItemContainerNumber}</a>
                    },
                    {
                        title: "Tran PO #", field: "transportPORefNumer",
                        defaultSort: cookiesi.getAll()['orderBy'] == 'transportPORefNumer' ? cookiesi.getAll()['direction'] : false,
                        filterComponent: ({ columnDef, onFilterChanged }) => (
                            <FilterComponentBox pageName={'dispatcheddepot'} onFilterChanged={onFilterChanged} columnDef={columnDef} columnName={columnDef.field} />
                        ),
                        render: rowData => (rowData.planningId) ? <a className={rowData.sameTrailerMarker === 1 &&  rowData.invoiceIsExpedited === 'Yes' ? 'markerExp' : rowData.invoiceIsExpedited === 'Yes' ? 'expeditedClass' : rowData.sameTrailerMarker === 1 ? 'sameMarker' : ''} target="_blank" href={'/exitingPurchaseorder/' + rowData.planningId + '/' + rowData.TransportPORecordID}>{rowData.TransportPO}</a> : <a href="javascript:void(0)" onClick={() => createPlan(rowData.Product, rowData.orderStatusRecordID, rowData.ResalePO, rowData.transportPOVendor, rowData.TransportPORecordID)} >{rowData.TransportPO}</a>
                    },
                    {
                        title: "Driver", field: "driverName",
                        defaultSort: cookiesi.getAll()['orderBy'] == 'driverName' ? cookiesi.getAll()['direction'] : false,
                        filterComponent: ({ columnDef, onFilterChanged }) => (
                            <FilterComponentBox pageName={'dispatcheddepot'} onFilterChanged={onFilterChanged} columnDef={columnDef} columnName={columnDef.field} />
                        ),
                        render: (rowData) => {
                            return <a className={`breakword ${rowData.sameTrailerMarker === 1 &&  rowData.invoiceIsExpedited === 'Yes' ? 'markerExp' : rowData.invoiceIsExpedited === 'Yes' ? 'expeditedClass' : rowData.sameTrailerMarker === 1 ? 'sameMarker' : ''}`} target="_blank" href={`https://www.google.com/maps/dir/?api=1&origin=${avoidNull(rowData.resalePOPickupAddress1)}${avoidNull(rowData.resalePOPickupCity)}${avoidNull2(rowData.resalePOPickupZipCode)}&destination=${removeAddress(rowData.invoiceShipAddress1,rowData.shipState)}${removeAddress(rowData.invoiceShipAddress2, rowData.shipState)}${removeAddress(rowData.invoiceShipAddress3, rowData.shipState)}${avoidNull(rowData.invoiceShipCity)}${avoidNull(rowData.shipState)}${avoidNull2(rowData.invoiceShipZip)}`}>{rowData.DriverName}</a>
                        }
                    },
                    {
                        title: "Tran Ven", field: "transportPOVendor",
                        defaultSort: cookiesi.getAll()['orderBy'] == 'transportPOVendor' ? cookiesi.getAll()['direction'] : false,
                        filterComponent: ({ columnDef, onFilterChanged }) => (
                            <FilterComponentBox pageName={'dispatcheddepot'} onFilterChanged={onFilterChanged} columnDef={columnDef} columnName={columnDef.field} />
                        ),
                        render: rowData => <a target="_blank" className={`breakword ${rowData.sameTrailerMarker === 1 &&  rowData.invoiceIsExpedited === 'Yes' ? 'markerExp' : rowData.invoiceIsExpedited === 'Yes' ? 'expeditedClass' : rowData.sameTrailerMarker === 1 ? 'sameMarker' : ''}`} href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID}>{rowData.transportPOVendor}</a>
                    },
                    {
                        title: "Tran Tot", field: "transportPOTotalPrice",
                        defaultSort: cookiesi.getAll()['orderBy'] == 'transportPOTotalPrice' ? cookiesi.getAll()['direction'] : false,
                        filterComponent: ({ columnDef, onFilterChanged }) => (
                            <FilterComponentBox pageName={'dispatcheddepot'} onFilterChanged={onFilterChanged} columnDef={columnDef} columnName={columnDef.field} />
                        ),
                        render: rowData => <a className={rowData.sameTrailerMarker === 1 &&  rowData.invoiceIsExpedited === 'Yes' ? 'markerExp' : rowData.invoiceIsExpedited === 'Yes' ? 'expeditedClass' : rowData.sameTrailerMarker === 1 ? 'sameMarker' : ''} target="_blank" href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID}>{rowData.TransportTotal}</a>
                    },

                    {
                        title: "Sch For",
                        defaultSort: cookiesi.getAll()['orderBy'] == 'transportPOScheduledDeliveryDate' ? cookiesi.getAll()['direction'] : false,
                        filterComponent: (props) => <CustomDatePicker pageName={'dispatcheddepot'} {...props} />,
                        field: "transportPOScheduledDeliveryDate", type: 'date', render: rowData => <a className={rowData.sameTrailerMarker === 1 &&  rowData.invoiceIsExpedited === 'Yes' ? 'markerExp' : rowData.invoiceIsExpedited === 'Yes' ? 'expeditedClass' : rowData.sameTrailerMarker === 1 ? 'sameMarker' : ''} target="_blank" href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID}>{(rowData.ScheduledFor) ? moment(rowData.ScheduledFor).format('MM/DD/YY') : ''}</a>
                    },
                    // {
                    //     title: "Sch For",
                    //     defaultSort: cookiesi.getAll()['orderBy'] == 'transportPOScheduledDeliveryDate' ? moment(cookiesi.getAll()['direction']).format('MM/DD/YY') : false,
                    //     filterComponent: (props) => <CustomDatePicker pageName={'dispatcheddepot'} {...props} />,
                    //     field: "transportPOScheduledDeliveryDate", type: 'date', render: rowData => <a target="_blank" href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID}>{(rowData.ScheduledFor) ? moment(rowData.ScheduledFor).format('MM/DD/YY') : ''}</a>
                    // },
                    // {
                    //     title: "Sch For",
                    //     defaultSort: cookiesi.getAll()['orderBy'] == 'transportPOScheduledDeliveryDate' ? cookiesi.getAll()['direction'] : false,
                    //     filterComponent: (props) => <CustomDatePicker pageName={'dispatcheddepot'} {...props} />,
                    //     field: "transportPOScheduledDeliveryDate", type: 'date', render: rowData => 
                    //     {
                    //         const [dateString, timeString] = rowData.ScheduledFor.split(" ");
                    //         const [YY, MM, DD] = dateString.split("-");
                    //        return <a target="_blank" href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID}>{(rowData.ScheduledFor) ? MM + '/' + DD + '/' + YY.slice(-2)  : ''}</a>
                    //     }
                        
                    // },

                    //    {
                    //     title: "Sch For",
                    //     defaultSort: cookiesi.getAll()['orderBy'] == 'transportPOScheduledDeliveryDate' ? cookiesi.getAll()['direction'] : false,
                    //     filterComponent: (props) => <CustomDatePicker pageName={'dispatcheddepot'} {...props} />,
                    //     field: "transportPOScheduledDeliveryDate", type: 'date', render: rowData => 
                    //     {
                    //         // const [dateString, timeString] = rowData.ScheduledFor.split(" ");
                    //         // const [YY, MM, DD] = dateString.split("-");
                            
                    //         // // Create a Date object with the stored date in the EST timezone
                    //         // const estDate = new Date(Date.UTC(YY, MM - 1, DD));
                            
                    //         // // Get the components of the date in the EST timezone
                    //         // const estYY = estDate.getUTCFullYear();
                    //         // const estMM = estDate.getUTCMonth() + 1;
                    //         // const estDD = estDate.getUTCDate();
                    //        return <a target="_blank" href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID}>{(rowData.ScheduledFor) ? `${estMM}/${estDD}/${estYY.toString().slice(-2)}`  : ''}</a>
                    //     }
                        
                    // },

                 
                    // {
                    //     title: "Miles", field: "newDistance",
                    //     defaultSort: cookiesi.getAll()['orderBy'] == 'newDistance' ? cookiesi.getAll()['direction'] : false,
                    //     filterComponent: ({ columnDef, onFilterChanged }) => (
                    //         <FilterComponentBox pageName={'dispatcheddepot'} onFilterChanged={onFilterChanged} columnDef={columnDef} columnName={columnDef.field} />
                    //     ),
                    //     render: rowData => <a target="_blank" href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID}>{ rowData.newDistance ? Math.round(rowData.newDistance) : ''}</a>
                    // },

                    {
                        title: "Miles", field: "transportPODistance",
                        defaultSort: cookiesi.getAll()['orderBy'] == 'transportPODistance' ? cookiesi.getAll()['direction'] : false,
                        filterComponent: ({ columnDef, onFilterChanged }) => (
                            <FilterComponentBox pageName={'dispatcheddepot'} onFilterChanged={onFilterChanged} columnDef={columnDef} columnName={columnDef.field} />
                        ),
                        render: rowData => <a className={rowData.sameTrailerMarker === 1 &&  rowData.invoiceIsExpedited === 'Yes' ? 'markerExp' : rowData.invoiceIsExpedited === 'Yes' ? 'expeditedClass' : rowData.sameTrailerMarker === 1 ? 'sameMarker' : ''} target="_blank" href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID}>{ rowData.transportPODistance ? Math.round(rowData.transportPODistance) : ''}</a>
                    },
                    {
                        title: "Del Status", field: "transportPODeliveryStatus",
                        defaultSort: cookiesi.getAll()['orderBy'] == 'transportPODeliveryStatus' ? cookiesi.getAll()['direction'] : false,
                        filterComponent: ({ columnDef, onFilterChanged }) => (
                            <FilterComponentBox pageName={'dispatcheddepot'} onFilterChanged={onFilterChanged} columnDef={columnDef} columnName={columnDef.field} />
                        ), render: rowData => <a className={rowData.sameTrailerMarker === 1 &&  rowData.invoiceIsExpedited === 'Yes' ? 'markerExp' : rowData.invoiceIsExpedited === 'Yes' ? 'expeditedClass' : rowData.sameTrailerMarker === 1 ? 'sameMarker' : ''} target="_blank" href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID}>{rowData.DeliveryStatus}</a>
                    },

                    {
                        title: "Prom Timeline", field: "invoicePromisedTimeline",
                        defaultSort: cookiesi.getAll()['orderBy'] == 'invoicePromisedTimeline' ? cookiesi.getAll()['direction'] : false,
                        filterComponent: ({ columnDef, onFilterChanged }) => (
                            <FilterComponentBox pageName={'dispatcheddepot'} onFilterChanged={onFilterChanged} columnDef={columnDef} columnName={columnDef.field} />
                        ),
                        render: rowData => <a className={rowData.sameTrailerMarker === 1 &&  rowData.invoiceIsExpedited === 'Yes' ? 'markerExp' : rowData.invoiceIsExpedited === 'Yes' ? 'expeditedClass' : rowData.sameTrailerMarker === 1 ? 'sameMarker' : ''} target="_blank" href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID}>{rowData.PromisedTimeline}</a>
                    },
                    {
                        title: "Prom Date",
                        defaultSort: cookiesi.getAll()['orderBy'] == 'invoicePromisedDate' ? cookiesi.getAll()['direction'] : false,
                        filterComponent: (props) => <CustomDatePicker pageName={'dispatcheddepot'} {...props} />,
                        field: "invoicePromisedDate", type: 'date', render: rowData => <a className={rowData.sameTrailerMarker === 1 &&  rowData.invoiceIsExpedited === 'Yes' ? 'markerExp' : rowData.invoiceIsExpedited === 'Yes' ? 'expeditedClass' : rowData.sameTrailerMarker === 1 ? 'sameMarker' : ''} target="_blank" href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID}>{(rowData.PromisedDate) ? moment(rowData.PromisedDate).format('MM/DD/YY') : ''}</a>
                    },
                    {
                        title: "Tier 1",
                        defaultSort: cookiesi.getAll()['orderBy'] == 'InvoiceLineHasTier1AddOn' ? cookiesi.getAll()['direction'] : false,
                        filterComponent: ({ columnDef, onFilterChanged }) => (
                            <FilterComponentBox pageName={'dispatcheddepot'} onFilterChanged={onFilterChanged} columnDef={columnDef} columnName={columnDef.field} />
                        ),
                        field: "InvoiceLineHasTier1AddOn", render: rowData => <a className={rowData.sameTrailerMarker === 1 &&  rowData.invoiceIsExpedited === 'Yes' ? 'markerExp' : rowData.invoiceIsExpedited === 'Yes' ? 'expeditedClass' : rowData.sameTrailerMarker === 1 ? 'sameMarker' : ''} target="_blank" href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID}>{rowData.InvoiceLineHasTier1AddOn}</a>
                    }
                ]}
                data={query =>
                    new Promise((resolve, reject) => {
                        const cookies = new Cookies();
                        cookies.remove('adminer_version');
                        if (query.filters.length > 0 || Object.keys(cookies.getAll()).length > 0) {
                            let url = API_BASE_URL + `/fetchOrderBasedOnColumn?`;
                            url += 'size=' + query.pageSize;
                            url += '&page=' + (query.page + 1);
                            url += '&orderNumber=' + 19;
                            if (query.orderBy) {
                                removeCookie("orderBy", { path: "/dispatcheddepot" });
                                removeCookie("direction", { path: "/dispatcheddepot" });
                                // setCookie("orderBy", query.orderBy.field, { path: '/dispatcheddepot' });
                                // setCookie("direction", query.orderDirection, { path: '/dispatcheddepot' });
                                // url += '&sortField=' + query.orderBy.field
                                // url += '&sortType=' + query.orderDirection
                                if (query.orderBy.field == 'invoiceRefNumber') {
                                    // url += '&sortField=invoiceRefNumber'
                                    setCookie("orderBy", "invoiceCustomerFullName", { path: '/dispatcheddepot' });
                                }
                                else {
                                    setCookie("orderBy", query.orderBy.field, { path: '/dispatcheddepot' });
                                    // url += '&sortField=' + query.orderBy.field

                                }
                                setCookie("direction", query.orderDirection, { path: '/dispatcheddepot' });
                            }
                            else {
                                url += '&sortField=invoiceTxnDate';
                                url += '&sortType=desc';
                                url += '&sortField=invoiceCustomerFullName';
                                url += '&sortType=asc';
                                url += '&sortField=resalePORecordID';
                                url += '&sortType=asc';
                            }

                            if (Object.keys(cookies.getAll()).length > 0) {

                                for (const key in cookies.cookies) {

                                    console.log(moment.isDate(cookies.cookies[key].replace('"', '').replace('"', '')), 'momentinfo', cookies.cookies[key], key);
                                    if(cookies.cookies[key] == 'Invalid date')
                                    {
                                        console.log('okkk')
                                         removeCookie(key, { path: "/dispatcheddepot" });
                                    }
                                    
                                    else
                                    {
                                        if (cookies.cookies[key] && moment.isDate(cookies.cookies[key])) {
                                        cookies.cookies[key] = moment(cookies.cookies[key]).format('YYYY-MM-DD')
                                    }

                                    if (cookies.cookies[key]) {
                                        if (key == 'invoiceRefNumber' && isNaN(cookies.cookies[key])) {
                                            url += '&columnName=' + 'invoiceCustomerFullName';
                                            if (cookies.cookies[key].toLowerCase() == 'yes') {
                                                url += '&search=' + 1;
                                            } else if (cookies.cookies[key].toLowerCase() == 'no') {
                                                url += '&search=' + 0;
                                            } else {
                                                url += '&search=' + encodeURIComponent(cookies.cookies[key]);
                                            }
                                        }
                                        else if (key == 'orderBy') {

                                            url += '&sortField=' + cookies.cookies[key]

                                            // url += '&sortType=' + cookies.cookies['direction']
                                        }
                                        else if (key == 'direction') {
                                            url += '&sortType=' + cookies.cookies[key]
                                        }
                                        else {
                                            url += '&columnName=' + key;
                                            if (cookies.cookies[key].toLowerCase() == 'yes') {
                                                url += '&search=' + 1;
                                            } else if (cookies.cookies[key].toLowerCase() == 'no') {
                                                url += '&search=' + 0;
                                            }

                                            else {
                                                url += '&search=' + encodeURIComponent(cookies.cookies[key]);
                                            }
                                        }
                                    } 
                                    }

                            
                                }
                            } else {
                                query.filters.forEach((item) => {
                                    if (item.value && moment.isDate(item.value)) {
                                        item.value = moment(item.value).format('YYYY-MM-DD')
                                    }

                                    if (item.column.field == 'invoiceRefNumber' && isNaN(parseInt(item.value))) {
                                        url += '&columnName=' + 'invoiceCustomerFullName';
                                        url += '&search=' + encodeURIComponent(item.value);
                                    } else {
                                        url += '&columnName=' + item.column.field;
                                        url += '&search=' + encodeURIComponent(item.value);
                                    }
                                });
                            }


                            axios.get(url, {
                                headers: {
                                    Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN_NAME),
                                },
                            }).then(result => {
                                resolve({
                                    data: result.data.rows.map((data) => ({
                                        orderStatusRecordID: data.recordID,
                                        RecordID: data.invoiceRecordID,
                                        // Miles: data.resalePODistance,
                                        newDistance: data.newDistance ? data.newDistance : null,
                                        transportPODistance: data.transportPODistance ? data.transportPODistance : null,
                                        Miles: (data.resalePOLineItemDistance && data.resalePOLineItemDistance != 0) ? data.resalePOLineItemDistance : data.distanceBetweenPickupAndDeliveryAddress,
                                        Customer: `${data.invoiceCustomerFullName.replace(',', ' ')}*${data.invoiceRefNumber}`,
                                        InvoiceRefNumber: data.invoiceRefNumber,
                                        PaidDate: (data.invoicePaidDate) ? data.invoicePaidDate?.split('T')[0] : '',
                                        Terms: data.invoiceTerms,
                                        Expected: (data.resalePOExpectedDate) ? data.resalePOExpectedDate?.split('T')[0] : '',
                                        TransportPO: (data.transportPORecordID) ? data.transportPORefNumer : data.invoiceRefNumber + 'T',
                                        transportPOVendor: data.transportPOVendor,
                                        TransportTotal: data.transportPOTotalPrice,
                                        ScheduledFor: (data.transportPOScheduledDeliveryDate) ? data.transportPOScheduledDeliveryDate?.split('T')[0] : '',
                                        PromisedDate: data.invoicePromisedDate,
                                        Product: data.invoiceLineItemItemNameWithoutParent,
                                        transportPORefNumer: (data.transportPORecordID) ? data.transportPORefNumer : data.invoiceRefNumber + 'T',
                                        AssignedTo: data.invoiceAssignedToName,
                                        SupplierLocation: data.invoiceSupplierLocation,
                                        ResalePO: data.resalePORefNumer,
                                        TransportPORecordID: data.transportPORecordID,
                                        InvoiceLineHasTier1AddOn: (data.InvoiceLineHasTier1AddOn) ? 'True' : 'False',
                                        transportPOLineItemContainerNumber: data.transportPOLineItemContainerNumber,
                                        pickupCity: data.invoiceLineSupplierLocation,
                                        planningId: (data.planning) ? data.planning.id : null,
                                        invoiceIsExpedited: data.invoiceIsExpedited
                                        ? "Yes"
                                        : "No",

                                        pickupLocation: (data.resalePOPickupCity && data.resalePOPickupState) ? data.resalePOPickupCity + ', ' + data.resalePOPickupState : '',

                                        shipCity: (data.invoiceShipCity) ? data.invoiceShipCity : '',
                                        shipState: (data.invoiceShipState) ? data.invoiceShipState : '',

                                        pickupCity: (data.resalePOPickupCity) ? data.resalePOPickupCity : '',
                                        pickupState: (data.resalePOPickupState) ? data.resalePOPickupState : '',

                                        invoiceShipAddress1: data.invoiceShipAddress1,
                                        invoiceShipAddress2: data.invoiceShipAddress2,
                                        invoiceShipAddress3: data.invoiceShipAddress3,
                                        invoiceShipCity: data.invoiceShipCity,
                                        invoiceShipZip: data.invoiceShipZip,
                                        resalePOPickupAddress1: data.resalePOPickupAddress1,
                                        resalePOPickupCity: data.resalePOPickupCity,
                                        resalePOPickupZipCode: data.resalePOPickupZipCode,
                                        // DriverName: (data.purchaseOrder) ? data.purchaseOrder.driverContactFullName : null,
                                        // vendorName: (data.purchaseOrder) ? data.purchaseOrder.vendor : null,
                                        invoiceLineItem_RecordID: data.invoiceLineItem_RecordID,
                                        solidSurface: (data.invoice?.solidSurface) ? data.invoice.solidSurface : 0,
                                        towTruck: (data.invoice?.towTruck) ? data.invoice.towTruck : 0,
                                        resalePOSupplierLocation: data.resalePOSupplierLocation,
                                        ResalePORecordID: data.resalePORecordID,
                                        Dropoff: (data.resalePOShipCity && data.resalePOShipState) ? data.resalePOShipCity + ' ' + data.resalePOShipState : '',
                                        distance: data.resalePODistance,
                                        entity: data.entity,
                                        // DriverName: data.driverName,
                                        DriverName: (data.purchaseOrder) ? data.purchaseOrder.driverContactFullName : null,
                                        // vendorName: (data.purchaseOrder) ? data.purchaseOrder.vendor : null,
                                       
                                        container: (data.resalePOLineItemContainerNumber) ? data.resalePOLineItemContainerNumber : '',
                                        ResaleVendor: data.resalePOVendor,
                                        Resale: data.resalePOReleaseNumber,
                                        ReleaseDate: (data.resalePOReleaseDate) ? data.resalePOReleaseDate?.split('T')[0] : '',
                                        ResaleTotal: data.resalePOTotalPrice,
                                        ExpectedDeliveryDate: (data.resalePOExpectedDate) ? data.resalePOExpectedDate?.split('T')[0] : '',
                                        LastDepotCall: (data.resalePOLastDepotCallDate) ? data.resalePOLastDepotCallDate?.split('T')[0] : '',
                                        sameTrailerMarker: data.sameTrailerMarker,
                                        DeliveryStatus: data.transportPODeliveryStatus,
                                        PromisedTimeline: (data.invoicePromisedTimeline) ? data.invoicePromisedTimeline : '',
                                        PromisedDate: (data.invoicePromisedDate) ? data.invoicePromisedDate?.split('T')[0] : '',
                                    })),
                                    page: result.data.currentPage - 1,
                                    totalCount: result.data.totalItems,
                                });
                                setPageRefresh(false);
                            });
                        } else {
                            let url = API_BASE_URL + `/fetchCompletedOrder?`;
                            url += 'size=' + query.pageSize;
                            url += '&page=' + (query.page + 1);
                            url += '&orderNumber=' + 19;
                            if (query.orderBy) {
                                setCookie("orderBy", query.orderBy.field, { path: '/dispatcheddepot' });
                                setCookie("direction", query.orderDirection, { path: '/dispatcheddepot' });
                                url += '&sortField=' + query.orderBy.field
                                url += '&sortType=' + query.orderDirection
                            } else {
                                switch (2) {
                                    case '2':
                                        url += '&sortField=invoicePaidDate';
                                        url += '&sortType=desc';
                                        url += '&sortField=invoiceCustomerFullName';
                                        url += '&sortType=asc';
                                        url += '&sortField=resalePORecordID';
                                        url += '&sortType=asc';
                                        break;
                                    case '7':
                                        url += '&sortField=invoicePaidDate';
                                        url += '&sortType=asc';
                                        url += '&sortField=invoiceCustomerFullName';
                                        url += '&sortType=asc';
                                        url += '&sortField=resalePORecordID';
                                        url += '&sortType=asc';
                                        break;
                                    case '15':
                                        url += '&sortField=invoicePaidDate';
                                        url += '&sortType=asc';
                                        url += '&sortField=invoiceCustomerFullName';
                                        url += '&sortType=asc';
                                        url += '&sortField=resalePORecordID';
                                        url += '&sortType=asc';
                                        break;
                                    case '10':
                                        url += '&sortField=transportPOScheduledDeliveryDate';
                                        url += '&sortType=asc';
                                        url += '&sortField=invoiceCustomerFullName';
                                        url += '&sortType=asc';
                                        url += '&sortField=resalePORecordID';
                                        url += '&sortType=asc';
                                        case '19':
                                        url += '&sortField=transportPOScheduledDeliveryDate';
                                        url += '&sortType=asc';
                                        url += '&sortField=invoiceCustomerFullName';
                                        url += '&sortType=asc';
                                        url += '&sortField=resalePORecordID';
                                        url += '&sortType=asc';
                                        break;
                                    default:
                                        url += '&sortField=invoiceTxnDate';
                                        url += '&sortType=desc';
                                        url += '&sortField=invoiceCustomerFullName';
                                        url += '&sortType=asc';
                                        url += '&sortField=resalePORecordID';
                                        url += '&sortType=asc';
                                }
                            }

                            if (query.search) {
                                url += '&search=' + encodeURIComponent(query.search);
                            }

                            if (query.filters && !query.search) {
                                query.filters.forEach((item) => {
                                    url += '&search=' + encodeURIComponent(item.value);
                                });
                            }

                            if (props.endDate && props.startDate) {
                                url += '&from=' + props.startDate;
                                url += '&to=' + props.endDate;
                            }

                            axios.get(url, {
                                headers: {
                                    Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN_NAME),
                                },
                            }).then(result => {
                                resolve({
                                    data: result.data.rows.map((data) => ({
                                        orderStatusRecordID: data.recordID,
                                        RecordID: data.invoiceRecordID,
                                        // Miles: data.resalePODistance,
                                        newDistance: data.newDistance ? data.newDistance : null,
                                        transportPODistance: data.transportPODistance ? data.transportPODistance : null,
                                        Miles: (data.resalePOLineItemDistance && data.resalePOLineItemDistance != 0) ? data.resalePOLineItemDistance : data.distanceBetweenPickupAndDeliveryAddress,
                                        Customer: `${data.invoiceCustomerFullName.replace(',', ' ')}*${data.invoiceRefNumber}`,
                                        InvoiceRefNumber: data.invoiceRefNumber,
                                        PaidDate: (data.invoicePaidDate) ? data.invoicePaidDate?.split('T')[0] : '',
                                        Terms: data.invoiceTerms,
                                        Expected: (data.resalePOExpectedDate) ? data.resalePOExpectedDate?.split('T')[0] : '',
                                        TransportPO: (data.transportPORecordID) ? data.transportPORefNumer : data.invoiceRefNumber + 'T',
                                        transportPOVendor: data.transportPOVendor,
                                        TransportTotal: data.transportPOTotalPrice,
                                        ScheduledFor: (data.transportPOScheduledDeliveryDate) ? data.transportPOScheduledDeliveryDate?.split('T')[0] : '',
                                        PromisedDate: data.invoicePromisedDate,
                                        Product: data.invoiceLineItemItemNameWithoutParent,
                                        transportPORefNumer: (data.transportPORecordID) ? data.transportPORefNumer : data.invoiceRefNumber + 'T',
                                        AssignedTo: data.invoiceAssignedToName,
                                        SupplierLocation: data.invoiceSupplierLocation,
                                        ResalePO: data.resalePORefNumer,
                                        TransportPORecordID: data.transportPORecordID,
                                        InvoiceLineHasTier1AddOn: (data.InvoiceLineHasTier1AddOn) ? 'True' : 'False',
                                        transportPOLineItemContainerNumber: data.transportPOLineItemContainerNumber,
                                        pickupCity: data.invoiceLineSupplierLocation,
                                        planningId: (data.planning) ? data.planning.id : null,
                                        invoiceIsExpedited: data.invoiceIsExpedited
                                        ? "Yes"
                                        : "No",
                                        sameTrailerMarker: data.sameTrailerMarker,
                                        pickupLocation: (data.resalePOPickupCity && data.resalePOPickupState) ? data.resalePOPickupCity + ', ' + data.resalePOPickupState : '',

                                        shipCity: (data.invoiceShipCity) ? data.invoiceShipCity : '',
                                        shipState: (data.invoiceShipState) ? data.invoiceShipState : '',

                                        pickupCity: (data.resalePOPickupCity) ? data.resalePOPickupCity : '',
                                        pickupState: (data.resalePOPickupState) ? data.resalePOPickupState : '',

                                        invoiceShipAddress1: data.invoiceShipAddress1,
                                        invoiceShipAddress2: data.invoiceShipAddress2,
                                        invoiceShipAddress3: data.invoiceShipAddress3,
                                        invoiceShipCity: data.invoiceShipCity,
                                        invoiceShipZip: data.invoiceShipZip,
                                        resalePOPickupAddress1: data.resalePOPickupAddress1,
                                        resalePOPickupCity: data.resalePOPickupCity,
                                        resalePOPickupZipCode: data.resalePOPickupZipCode,

                                        invoiceLineItem_RecordID: data.invoiceLineItem_RecordID,
                                        solidSurface: (data.invoice?.solidSurface) ? data.invoice.solidSurface : 0,
                                        towTruck: (data.invoice?.towTruck) ? data.invoice.towTruck : 0,
                                        resalePOSupplierLocation: data.resalePOSupplierLocation,
                                        ResalePORecordID: data.resalePORecordID,
                                        Dropoff: (data.resalePOShipCity && data.resalePOShipState) ? data.resalePOShipCity + ' ' + data.resalePOShipState : '',
                                        distance: data.resalePODistance,
                                        entity: data.entity,
                                        // DriverName: data.driverName,
                                        DriverName: (data.purchaseOrder) ? data.purchaseOrder.driverContactFullName : null,
                                        // vendorName: (data.purchaseOrder) ? data.purchaseOrder.vendor : null,
                                        container: (data.resalePOLineItemContainerNumber) ? data.resalePOLineItemContainerNumber : '',
                                        ResaleVendor: data.resalePOVendor,
                                        Resale: data.resalePOReleaseNumber,
                                        ReleaseDate: (data.resalePOReleaseDate) ? data.resalePOReleaseDate?.split('T')[0] : '',
                                        ResaleTotal: data.resalePOTotalPrice,
                                        ExpectedDeliveryDate: (data.resalePOExpectedDate) ? data.resalePOExpectedDate?.split('T')[0] : '',
                                        LastDepotCall: (data.resalePOLastDepotCallDate) ? data.resalePOLastDepotCallDate?.split('T')[0] : '',

                                        DeliveryStatus: data.transportPODeliveryStatus,
                                        PromisedTimeline: (data.invoicePromisedTimeline) ? data.invoicePromisedTimeline : '',
                                        PromisedDate: (data.invoicePromisedDate) ? data.invoicePromisedDate?.split('T')[0] : '',
                                    })),
                                    page: result.data.currentPage - 1,
                                    totalCount: result.data.totalItems,
                                });
                                setPageRefresh(false);
                            })
                        }
                    })
                }
                components={{
                    Toolbar: props => (
                        <ResetFillterButton elsement={props} resetFilter={resetFilter} />
                    )
                }}

                options={{
                    thirdSortClick: false,

                    // rowStyle: rowData => ({
                    //     backgroundColor: (rowData.Terms == "Rent To Own" && rowData.solidSurface == 1 ) ? '#90EE90' : (rowData.Terms == "Rent To Own") ? '#ffda73' : (rowData.solidSurface == 1) ? '#7bede9' : '#fff'
                    // }),
                    rowStyle: rowData => ({
                        backgroundColor: (rowData.Terms == "Rent To Own" && rowData.towTruck == 1 ) ? '#F7AE77' : (rowData.Terms == "Rent To Own" && rowData.solidSurface == 1 ) ? '#90EE90' : (rowData.towTruck == 1 && rowData.solidSurface == 1 ) ? '#F0BBF0' : (rowData.Terms == "Rent To Own") ? '#ffda73' : (rowData.solidSurface == 1) ? '#7bede9' : (rowData.towTruck == 1) ? 'pink' : '#fff'
                    }),
                    pageSize: 50,
                    debounceInterval: 1500,
                    pageSizeOptions: [50, 100, 200],
                    filtering: true
                }}
            />

            <style jsx="true">{`
            .MuiTableCell-root{
                padding: 2px !important;
                font-family: 'Source Sans Pro', sans-serif;
            }

            .btn-set{
                position: absolute;
                top: 18px;
                font-size: 17px;
                right: 255px;
                color: #000;
                padding: 4px 6px;
                border: 1px solid #79a3ff;
            }

            .input-wid-set {
                border: none;
                border-bottom: 1px solid #949494;
                position: relative;
                top: 3px;
                width: 100%;
            }

            .MuiTableCell-root a {
                font-size:12px !important;
                font-family: 'Source Sans Pro', sans-serif;
                overflow: visible;
                display: block;
                text-overflow: clip;
                word-break: break-word;
                white-space: break-spaces!important;            
            }

            .MuiTableHead-root th {
                font-size: 12px !important;
                font-weight: 600;
                background: #fbf5f5;
            }
            // .MuiTableHead-root th:nth-child(6) {
            //     width: 129px!important;
            // }
            .MuiTableCell-root {
                width: fit-content;
            }
            .MuiTableCell-root a {
                width: 45px;
            }
            tr td:first-child.MuiTableCell-root a, tr th:first-child span.MuiButtonBase-root.MuiTableSortLabel-root {
                width: 100%!important;
            }
        `}</style>
        </div>
    );
};

export default DispachedDepotBox;