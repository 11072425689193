import React from 'react';
import { useField, Form, FormikProps, Formik } from 'formik';
import { useState, useEffect, useRef } from "react";
import { useFormikContext } from 'formik';
import InvoiceServices from '../Invoice/Services/InvoiceServices';
import moment from 'moment-timezone';
import DatePicker from "react-datepicker";
import PurchaseFormServices from './Services/PurchaseFormServices'
import PurchaseOrderServices from './Services/PurchaseOrderServices'
import swal from "sweetalert";

const TextField = ({ ...props }) => {
    const [field, meta, helpers] = useField(props);
    return (<input {...field} {...props} />);
}

const TextArea = ({ ...props }) => {
    const [field, meta, helpers] = useField(props);
    return (<textarea {...field} {...props} ></textarea>);
}



const SelectionItem = ({ ...props }) => {
    const [field, meta, helpers] = useField(props);
    return (
        <select {...field} {...props} onChange={(e) => props.handleItem(e, props.option, props.index)}>
            <option value={null}></option>
            {
                props.option.map((item, index) =>
                    <>
                        <option value={item.recordID}>{item.fullName}</option>
                        <option disabled value={item.recordID}>{item.salesDesc}</option>
                        <option disabled value={item.recordID}>.............................................................................................................................</option>
                    </>
                )
            }
        </select>
    );
}


function PurchaseTable(props) {
    const [purchasedOrderItem, setPurchasedOrderItem] = useState([]);
    const [rowcount, setrowCount] = useState(0);
    const [indexStore, setIndexStore] = useState(null);
    const { setFieldValue } = useFormikContext();
    const [exhibit, setexhibit] = useState([]);
    const [exhibit2, setexhibit2] = useState([]);
    const [field, meta, helpers] = useField(props);
    // const inputRef = useRef(null);
    //const [lineItemDelete, setLineItemDelete] = useState([]);
    // const GFG_Fun = () => {
    //     const inputElements = document.querySelectorAll(".blockNumber");
    
    //     inputElements.forEach((input) => {
    //       input.addEventListener("mousewheel", function (event) {
    //         this.blur();
    //       });
    //     });
    
    //     const resultElements = document.querySelectorAll(".blockNumber");
    //     resultElements.forEach((result) => {
    //       result.innerHTML = "Mouse wheel disabled!";
    //     });
    //   };
    //   useEffect(() => {
    //     const handleMouseUp = (e) => {
    //       e.stopPropagation();
    //     };
    
    //     const inputElement = inputRef.current;
    
    //     if (inputElement) {
    //       inputElement.addEventListener('mouseup', handleMouseUp);
    
    //       return () => {
    //         inputElement.removeEventListener('mouseup', handleMouseUp);
    //       };
    //     }
    //   }, []);

   
    


    const handleItem = (e, option, index) => {
        const value = option.find((item) => item.recordID == e.target.value);

        setPurchasedOrderItem((old) =>
            old.map((x, indexItem) => {
                if (indexItem == index)
                    return {
                        ...x,
                        amount: '0.00',
                        containerNumber: "",
                        des: value.salesDesc,
                        item: value.fullName,
                        itemRecordID: value.recordID,
                        quantity: '1.00',
                        rate: '0.00'
                    };
                return x;
            }));

        setFieldValue('recordID' + index, null)
        setFieldValue('item' + index, e.target.value)
        setFieldValue('description' + index, value.salesDesc)
        setFieldValue('qty' + index, '1.00')
        setFieldValue('rate' + index, '0.00')
        setFieldValue('amount' + index, '0.00')
        setFieldValue('container' + index, '')
    }


    const incrementrow = () => {
        setrowCount((old) => old + 1);
        setFieldValue('length', rowcount + 1);
        setexhibit((item) => [...item, "none"]);
        setexhibit((item) => [...item, "none"]);
        setexhibit((item) => [...item, "none"]);
        setexhibit2((item) => [...item, "revert"]);
        setexhibit2((item) => [...item, "revert"]);
        setexhibit2((item) => [...item, "revert"]);

        setPurchasedOrderItem((x) => [...x, {
            "amount": null,
            "containerNumber": null,
            "des": null,
            "item": null,
            "itemRecordID": null,
            "quantity": null,
            "rate": null
        }]);
    }

    const handeleqty = (e, index) => {
        // if (e.target.value < 0) {
        //     return true;
        // }
        const userInput = e.target.value;
        // Regex to allow numbers with an optional decimal point
        const regex = /^\d*\.?\d*$/;
    
        if (!regex.test(userInput)) {
            e.preventDefault();
            return false;
            // console.log('gfgffff')
          // Your logic to handle quantity input
        }

        setFieldValue('qty' + index, e.target.value);
        var qty = e.target.value;
        setPurchasedOrderItem((old) =>
            old.map((x, inde) => {
                if (inde == index)
                    return {
                        ...x,
                        quantity: qty,
                        amount: qty * field.value['rate' + index.toString()]
                    };
                return x;
            }));
        if (field.value['rate' + index.toString()]) {
            const amount = e.target.value * field.value['rate' + index.toString()];
            setFieldValue('amount' + index, parseFloat(amount)?.toFixed(2));
        }

    }

    const handleContainer = (e, index) => {
        setFieldValue('container' + index, e.target.value);
        var container = e.target.value;
        setPurchasedOrderItem((old) =>
            old.map((x, inde) => {
                if (inde == index)
                    return {
                        ...x,
                        containerNumber: container,
                    };
                return x;
            }));
    }

    const handleDesc = (e, index) => {
        setFieldValue('description' + index, e.target.value);
        var description = e.target.value;
        setPurchasedOrderItem((old) =>
            old.map((x, inde) => {
                if (inde == index)
                    return {
                        ...x,
                        des: description,
                    };
                return x;
            }));
    }

    // const handleAmount = (e, index) => {
    //     const userInput = e.target.value;
    //     // Regex to allow numbers with an optional decimal point
    //     const regex = /^\d*\.?\d*$/;
    
    //     if (regex.test(userInput)) {
    //         e.preventDefault();
    //         return false;
    //       // Your logic to handle amount input (if needed)
    //       // For example, you can process or store the validated amount value here
    //     }
    //   };

    const handeleRate = (e, index) => {
        const userInput = e.target.value;
        // Regex to allow numbers with an optional decimal point
        const regex = /^\d*\.?\d*$/;
    
        if (!regex.test(userInput)) {
            e.preventDefault();
            return false;
            // console.log('gfgffff')
          // Your logic to handle quantity input
        }
        // if (e.target.value < 0) {
        //     return true;
        // }
        setFieldValue('rate' + index, e.target.value);
        var rate = e.target.value;
        setPurchasedOrderItem((old) =>
            old.map((x, inde) => {
                if (inde == index)
                    return {
                        ...x,
                        rate: rate,
                        amount: rate * field.value['qty' + index.toString()]
                    };
                return x;
            }));
        if (e.target.value && field.value['qty' + index.toString()]) {
            const amount = e.target.value * field.value['qty' + index.toString()];
            setFieldValue('amount' + index, parseFloat(amount)?.toFixed(2));
        }
    }

    const decrementrow = async (value, ii) => {
        if (value) {
            //lineItemDelete.push(value)
            //setLineItemDelete(prevArray => [...prevArray, value])
            props.deleteLineItemArray(value)
            //console.log('ddddd', props.stateinfo.transportPOrecordID)
           // console.log('tttttttt', props.stateinfo.data.transportPO)
            //await PurchaseFormServices.deletePurchasedOrderLine(value);

           // await PurchaseFormServices.updateBudget(props.stateinfo.transportPOrecordID || props.stateinfo.data.transportPO);
            //await fetchPruchasedOrderLine();
            //await swal("Please click on save button after delete line item");
            //await props.syncData();
            //window.location.reload();
            let data = [...purchasedOrderItem];
        
           let result = data.filter((item) => item.recordID != value)
            setPurchasedOrderItem(result)
            setrowCount(result.length);
            setFieldValue('length', result.length);
            result.length && result.forEach((element, index) => {
                setexhibit((item) => [...item, "none"]);
                setexhibit2((item) => [...item, "revert"]);
    
                setFieldValue('recordID' + index, element.recordID)
    
                setFieldValue('item' + index, element.itemRecordID)
                setFieldValue('description' + index, element.des)
                setFieldValue('qty' + index, (element.quantity) ? parseFloat(element.quantity)?.toFixed(2).toString() : '')
                setFieldValue('rate' + index, (element.rate) ? parseFloat(element.rate)?.toFixed(2).toString() : '')
                setFieldValue('amount' + index, (element.amount) ? parseFloat(element.amount)?.toFixed(2).toString() : '')
                setFieldValue('container' + index, element.containerNumber)
            });

        }
        // else {
        //     setrowCount((old) => old - 1);
        //     setFieldValue('length', rowcount - 1);

        //     setexhibit((item) => {
        //         var newArray = new Array();
        //         for (var i = 1; i < item.length; i++ ) {
        //             newArray.push(item[i]);
        //         }
        //         return newArray;
        //     });

        //     setexhibit2((item) => {
        //         var newArray = new Array();
        //         for (var i = 1; i < item.length; i++ ) {
        //             newArray.push(item[i]);
        //         }
        //         return newArray;
        //     });
        // }
        let data = [...purchasedOrderItem];
        let result = data.filter((d, i) => i != ii)
        // setPurchasedOrderItem((old) =>
        //     old.filter((x , inde) => {
        //     if (inde != index)
        //         return x;
        // }));
        setPurchasedOrderItem(result)
        setrowCount(result.length);
        setFieldValue('length', result.length);
        result.length && result.forEach((element, index) => {
            setexhibit((item) => [...item, "none"]);
            setexhibit2((item) => [...item, "revert"]);

            setFieldValue('recordID' + index, element.recordID)

            setFieldValue('item' + index, element.itemRecordID)
            setFieldValue('description' + index, element.des)
            setFieldValue('qty' + index, (element.quantity) ? parseFloat(element.quantity)?.toFixed(2).toString() : '')
            setFieldValue('rate' + index, (element.rate) ? parseFloat(element.rate)?.toFixed(2).toString() : '')
            setFieldValue('amount' + index, (element.amount) ? parseFloat(element.amount)?.toFixed(2).toString() : '')
            setFieldValue('container' + index, element.containerNumber)
        });

        //await props.syncData();
        //await props.fetchUpdateData();
        // setFieldValue('item' + index, '')

        // setFieldValue('recordID' + index, '')

        // setFieldValue('description' + index, '')
        // setFieldValue('qty' + index, '')
        // setFieldValue('rate' + index, '')
        // setFieldValue('amount' + index, '')
        // setFieldValue('container' + index, '')
    }


    const focusFunction = (index) => {
        console.log('rrrrrrrrrrr', index, indexStore, document.getElementsByName("item" + index)[0])
  
         if (typeof document.getElementsByName("item" + index)[0] == 'undefined' ||  indexStore == index || !document.getElementsByName("item" + index)[0].value) {
        } else {
            setIndexStore(index)
            document.getElementsByName("rate" + index)[0].focus();
        }
    }

    const handelexhibit = (index) => {
        setexhibit((old) => old.map((item, key) => (key == index) ? "revert" : "none"));
        setexhibit2((old) => old.map((item, key) => (key == index) ? "none" : "revert"));
    }

    const fetchPruchasedOrderLine = async () => {
        if (props.stateinfo.transportPOrecordID || props.stateinfo.data.transportPO) {
            try {
                props.updateloading(true);
                const result = await PurchaseFormServices.fetchPruchasedOrderLine(props.stateinfo.transportPOrecordID || props.stateinfo.data.transportPO);
                console.log('dddddddddddddddddd', result.length)
                if(result.length > 0)
                {
                    setPurchasedOrderItem(result);
                    setrowCount(result.length);
                    setFieldValue('length', result.length);
                    result.forEach((element, index) => {
                        setexhibit((item) => [...item, "none"]);
                        setexhibit2((item) => [...item, "revert"]);
    
                        setFieldValue('recordID' + index, element.recordID)
    
                        setFieldValue('item' + index, element.itemRecordID)
                        setFieldValue('description' + index, element.des)
                        setFieldValue('qty' + index, (element.quantity) ? parseFloat(element.quantity)?.toFixed(2).toString() : '')
                        setFieldValue('rate' + index, (element.rate) ? parseFloat(element.rate)?.toFixed(2).toString() : '')
                        setFieldValue('amount' + index, (element.amount) ? parseFloat(element.amount)?.toFixed(2).toString() : '')
                        setFieldValue('container' + index, element.containerNumber)
                    });
                }
                else
                {
                    const defaultObject = [
                        {
                            itemRecordID: 18,
                            item: 'Trucking Service:Delivery Expense',
                            des: props.stateinfo.data.resalePOLineItemDesc,
                            quantity: 1,
                            rate: null,
                            amount: null,
                            containerNumber: ''
                        },
                        {
                            itemRecordID: 114,
                            item: 'Trucking Service:Fuel Surcharge',
                            des: 'Fuel Surcharge',
                            quantity: 1,
                            rate: null,
                            amount: null,
                            containerNumber: 'Fuel'
                        }
                    ];
        
                    setPurchasedOrderItem(defaultObject);
                    setrowCount(defaultObject.length);
                    setFieldValue('length', defaultObject.length);
                    defaultObject.forEach((element, index) => {
                        setexhibit((item) => [...item, "none"]);
                        setexhibit2((item) => [...item, "revert"]);
                        setFieldValue('item' + index, element.itemRecordID)
                        setFieldValue('description' + index, element.des)
                        setFieldValue('qty' + index, (element.quantity) ? parseFloat(element.quantity)?.toFixed(2).toString() : '')
                        setFieldValue('rate' + index, (element.rate) ? parseFloat(element.rate)?.toFixed(2).toString() : '')
                        setFieldValue('amount' + index, (element.amount) ? parseFloat(element.amount)?.toFixed(2).toString() : '')
                        setFieldValue('container' + index, element.containerNumber)
                    });
                }
               

              await  updateTostatus(1, props.recordID);

                props.updateloading(false);
            } catch (error) {
                props.updateloading(false);
                console.log(error, 'error to fetchInvoiceItem');
            }
        } else {
            await  updateTostatus(1, props.recordID);
            const defaultObject = [
                {
                    itemRecordID: 18,
                    item: 'Trucking Service:Delivery Expense',
                    des: props.stateinfo.data.resalePOLineItemDesc,
                    quantity: 1,
                    rate: null,
                    amount: null,
                    containerNumber: ''
                },
                {
                    itemRecordID: 114,
                    item: 'Trucking Service:Fuel Surcharge',
                    des: 'Fuel Surcharge',
                    quantity: 1,
                    rate: null,
                    amount: null,
                    containerNumber: 'Fuel'
                }
            ];

            setPurchasedOrderItem(defaultObject);
            setrowCount(defaultObject.length);
            setFieldValue('length', defaultObject.length);
            defaultObject.forEach((element, index) => {
                setexhibit((item) => [...item, "none"]);
                setexhibit2((item) => [...item, "revert"]);
                setFieldValue('item' + index, element.itemRecordID)
                setFieldValue('description' + index, element.des)
                setFieldValue('qty' + index, (element.quantity) ? parseFloat(element.quantity)?.toFixed(2).toString() : '')
                setFieldValue('rate' + index, (element.rate) ? parseFloat(element.rate)?.toFixed(2).toString() : '')
                setFieldValue('amount' + index, (element.amount) ? parseFloat(element.amount)?.toFixed(2).toString() : '')
                setFieldValue('container' + index, element.containerNumber)
            });
        }
    }
    
    const updateTostatus = async (status, transportPOrecordID) => {
        console.log("asDASD",transportPOrecordID)
        await PurchaseOrderServices.updatePOstatus(status, transportPOrecordID);
    }

    useEffect(() => {
        console.log('hmmmmmmmmmmmmmm')
        fetchPruchasedOrderLine();
        // GFG_Fun();
     
    }, []);

    useEffect(() => {
       
        fetchPruchasedOrderLine();
       
    }, [props.stateinfo.data]);

    console.log(purchasedOrderItem, "purchasedOrderItem");

    return (
        <div className="row" onClick={props.fun}>
            <div className="col-sm-12">
                <div className="table-responsive custom-invoice">
                    <table id="special" className="table table-hover">
                        <thead>
                            <tr>
                                <td>Item</td>
                                <td>Description</td>
                                <td>Qty</td>
                                <td>Rate</td>
                                <td>Amount</td>
                                <td>Container </td>
                                <td></td>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                Array.from(Array(rowcount).keys()).map((item, index) => {

                                    console.log(!!purchasedOrderItem[3]?.item, 'inddd');
                                    return <>

                                        <tr key={purchasedOrderItem} id={'item' + index} style={{ display: exhibit[index] }} onClick={async () => {
                                            await handelexhibit(index);
                                            await focusFunction(index);
                                        }}>
                                            <td><SelectionItem handleItem={handleItem} option={props.items} index={index} name={'item' + index} type="text" className="form-control" /></td>
                                            <td><TextArea onChange={(e) => handleDesc(e, index)} name={'description' + index} className="form-control textarea" cols="50" style={{ minHeight: 50, height: "auto" }} /></td>

                                            <td><TextField   onChange={(e) => handeleqty(e, index)} name={'qty' + index} type="text" className="form-control blockNumber" /></td>
                                            <td><TextField   onChange={(e) => handeleRate(e, index)} name={'rate' + index} type="text" className="form-control blockNumber" /></td>

                                            <td><TextField  onKeyDown={ (evt) => evt.keyCode  === 40 || evt.keyCode  === 38 ? evt.preventDefault() : '' } name={'amount' + index} type="number" className="form-control blockNumber" disabled/></td>
                                            <td><TextField onChange={(e) => handleContainer(e, index)} name={'container' + index} className="form-control textarea" /></td>
                                            {
                                                (index != 0) ?
                                                    <td><i onClick={() => decrementrow(purchasedOrderItem[index]?.recordID, index)} className="fa fa-trash text-danger iconbaraction" data-toggle="tooltip" data-placement="top" title="Delete" aria-hidden="true"></i></td>
                                                    :
                                                    null

                                            }

                                        </tr>

                                        <tr className='custom_tr' style={{ display: exhibit2[index] }} onClick={async () => {
                                            await handelexhibit(index);
                                            await focusFunction(index);
                                        }}>
                                            <td><span>{(purchasedOrderItem[index]) ? purchasedOrderItem[index].item : null}</span></td>
                                            <td><span>{(purchasedOrderItem[index]) ? purchasedOrderItem[index].des : null}</span></td>
                                            <td><span>{(purchasedOrderItem[index] && purchasedOrderItem[index].quantity) ? parseFloat(purchasedOrderItem[index].quantity)?.toFixed(2) : null}</span></td>
                                            <td><span>{(purchasedOrderItem[index] && purchasedOrderItem[index].rate) ? parseFloat(purchasedOrderItem[index].rate)?.toFixed(2) : null}</span></td>
                                            <td><span>{(purchasedOrderItem[index] && purchasedOrderItem[index].amount) ? parseFloat(purchasedOrderItem[index].amount)?.toFixed(2) : null}</span></td>
                                            <td><span>{(purchasedOrderItem[index]) ? purchasedOrderItem[index].containerNumber : null}</span></td>
                                            {
                                                (index != 0) ?
                                                    <td><i style={{ cursor: 'pointer' }} onClick={() => decrementrow(purchasedOrderItem[index]?.recordID, index)} className="fa fa-trash text-danger iconbaraction" data-toggle="tooltip" data-placement="top" title="Delete" aria-hidden="true"></i></td>
                                                    :
                                                    null
                                            }
                                            <td></td>
                                        </tr>
                                    </>
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="col-sm-12">
                <button type="button" onClick={incrementrow} class="btn btn-primary">Add lines</button>
            </div>

            <style jsx="true">{`
                    .custom-invoice .table td {
                        border-top: 0px solid #dee2e6 !important;
                        width: 20%;
                        height: 100%;
                      }

                    .form-control {
                        padding: 0rem 0rem;}
                        .custom_tr td {
                        font-size: 0.8rem;
                        white-space: pre-wrap !important;
                        word-wrap: anywhere;
                    }

                    td select:nth-child(1) {
                        width: 80%;
                    }
                    
                    td span:nth-child(1) {
                        width: 100%;
                        word-break: break-word;
                    }
                    
                    .custom-invoice .table td {
                        border-top: 0px solid #dee2e6 !important;
                        width: 30%;
                        height: 100%;
                    }
                    td:nth-child(3) {
                        width: 8%!important;
                    }
                    td:nth-child(1) {
                        width: 20%!important;
                    }

                    td select:nth-child(1) {
                        width: 100%;
                    }
                    
                    td:nth-child(4) {
                        width: 11%!important;
                    }
                    td:nth-child(5) {
                        width: 8%!important;
                    }
                    
                    td:nth-child(6) {
                        width: 19%!important;
                    }
                    textarea.form-control {
                        resize: inherit;
                        height:auto;
                        max-height:700px!important;
                    }     

                `}</style>

        </div>
    );

}
export default PurchaseTable;