import React, { useState, useEffect } from "react";
import axios from "axios";
import { useHistory, useParams, Link } from "react-router-dom";
import { API_BASE_URL, ACCESS_TOKEN_NAME, ACCESS_TOKEN_NAME_USER } from "../../constants/apiConstants";
import {Multiselect}  from "multiselect-react-dropdown";
import { ToastContainer, toast } from 'react-toastify';
import InputMask from 'react-input-mask';
import logo from '../../images/logo.png';
import profilepic from '../../images/avatar-4.jpg';
import Navbar from "./../Layout/Navbar";
import Sidebar from "./../Layout/Sidebar";
import Footer from "./../Layout/Footer";
import { Input } from "@material-ui/core";
import Avatar from 'react-avatar';

const AddContact = (props) => {
  let history = useHistory();
  const params = useParams();
  const [display, setdisplay] = useState('none');
  const [state, setState] = useState({
    firstName : "",
    lastName : "",
    Phone : "",
    Email : "",
    ContactType: "",
    DriverPhone: "",
    DriverEmail: "",
    TruckType: "",
    TrailerType: "",
    TrailerSize : "",
    AssignedTerritory: [],
    entityRecordID:"",
    // supplierLocations: [],
    successMessage: null,
    errorMessage: {},
    errorMessages: {}
  });
//   const [location, setLocation] = useState();
  const [errorMessages, setErrorMessage] = useState();

  const [multiSelectOptions, setMultiSelectOptions] = useState([]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(name, value);
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    validate(name, value);
  };

  useEffect(() => {
      let {id} = params;
      console.log('params----------',`${id}`);
    async function fetchLocation() {
      const result = await axios.get(
          API_BASE_URL + `/findAllSupplierLocation`,
          {
              headers : {
                  Authorization : "Bearer " + localStorage.getItem(ACCESS_TOKEN_NAME),
              }
          },
      );
      const data = [];
      if(result.status === 200){
          result.data.forEach(element => {
            data.push({id: element.id, name: element.SupplierLocation});
            
          });
          console.log('not selected--',data);
          setMultiSelectOptions(data);
      }
    }   
    fetchLocation();

  }, []);

  const onSelect = (selectedList, selectedItem) => {
    console.log('selectedlist',selectedList);
    setState((prevState) => ({
      ...prevState,
      AssignedTerritory: {
        selectedList
      },
    }));
  }

  const onRemove = (selectedList, removedItem) => {
    console.log('deselected',selectedList);
    setState((prevState) => ({
      ...prevState,
      supplierLocations: {
        selectedList
      },
    }));
  }
  
  
  const validate = (key, value) => {
    setState((prevState) => ({
      ...prevState,
      errorMessage: {
        ...prevState.errorMessage,
        [key]: "",
      },
    }));
    switch (key) {
      case "email":
        let pattern = new RegExp(
            /^(("[\w-\s]+")|([+\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
        );
        if (!pattern.test(value)) {
          setState((prevState) => ({
            ...prevState,
            errorMessage: {
              ...prevState.errorMessage,
              Email: "Please Enter valid Email",
            },
          }));
        }
        break;

      default:
    }
  };
  const handleCancel = (e) => {
    history.push("/vendor");
  }

  const handleSubmitClick = async (e) => {

    e.preventDefault();

    if (!state.firstName.trim().length) {
      console.log('called name')
      setState((prevState) => ({
        ...prevState,
        errorMessage: {
          ...prevState.errorMessage,
          firstName: "Please Enter First Name",
        },
      }));
    }

    if (!state.lastName.trim().length) {
      setState((prevState) => ({
        ...prevState,
        errorMessage: {
          ...prevState.errorMessage,
          lastName: "Please Enter Last Name",
        },
      }));
    }

    if (!state.Phone.trim().length) {
      setState((prevState) => ({
        ...prevState,
        errorMessage: {
          ...prevState.errorMessage,
          Phone: "Please Enter Phone No",
        },
      }));
    }

    if (!state.ContactType.length) {
      setState((prevState) => ({
        ...prevState,
        errorMessage: {
          ...prevState.errorMessage,
          ContactType: "Please Select Contact Type",
        },
      }));
    }

    /*
    if (!state.DriverPhone.length) {
      setState((prevState) => ({
        ...prevState,
        errorMessage: {
          ...prevState.errorMessage,
          DriverPhone: "Please Enter Additional Phone",
        },
      }));
    }

    
    if (!state.DriverEmail.length) {
      setState((prevState) => ({
        ...prevState,
        errorMessage: {
          ...prevState.errorMessage,
          DriverEmail: "Please Enter Driver Email",
        },
      }));
    }
    if (!state.TruckType.length) {
      setState((prevState) => ({
        ...prevState,
        errorMessage: {
          ...prevState.errorMessage,
          TruckType: "Please Select Truck Type",
        },
      }));
    }
    if (!state.TrailerType.length) {
      setState((prevState) => ({
        ...prevState,
        errorMessage: {
          ...prevState.errorMessage,
          TrailerType: "Please Select Trailer Type",
        },
      }));
    }
    if (!state.TrailerSize.length) {
      setState((prevState) => ({
        ...prevState,
        errorMessage: {
          ...prevState.errorMessage,
          TrailerSize: "Please Select Trailer Size",
        },
      }));
    }

  

    if (state.AssignedTerritory.length == 0) {
      setState((prevState) => ({
        ...prevState,
        errorMessage: {
          ...prevState.errorMessage,
          AssignedTerritory: "Please Select Supplier Location",
        },
      }));
    }else{
      setState((prevState) => ({
        ...prevState,
        errorMessage: {
          ...prevState.errorMessage,
          AssignedTerritory: "",
        },
      }));
    }

    */

    validate("email", state.Email);

    if (
        state.firstName && 
        state.lastName && 
        state.Phone &&
        state.Email &&
        state.ContactType 

        /*
        state.DriverPhone &&
        state.DriverEmail &&
        state.TruckType &&
        state.TrailerType &&
        state.TrailerSize &&
        state.AssignedTerritory
        */
    ) {
      console.log('if condition');
      setState((prevState) => ({
        ...prevState,
        errorMessage: {},
      }));
      const { id } = params;
      const payload = {
        firstName: state.firstName.trim(),
        lastName: state.lastName.trim(),
        Phone: state.Phone.trim(),
        Email: state.Email.trim(),
        ContactType: state.ContactType,
        DriverPhone: state.DriverPhone.trim(),
        DriverEmail: state.DriverEmail.trim(),
        TruckType: state.TruckType,
        TrailerType: state.TrailerType,
        TrailerSize: state.TrailerSize,
        AssignedTerritory: state.AssignedTerritory,
        id:id
      };

      let j = 0

      

     await axios.post(API_BASE_URL + "/checkContact", payload)
      .then(function (response) {
        if(response.data.status == 404)
        {
          j = 1
          swal({
            text: "There is a duplicate driver within the carrier? Are you sure you want to add this driver?",
            icon: "warning",
            dangerMode: true,
            buttons: true,
        }).then(async (innsert) => {
          if(innsert) {
            const addCont =   await submitContactForm(payload)

        console.log('sssss', addCont)
        if(addCont)
        {
          setState((prevState) => ({
            ...prevState,
          }));

          //localStorage.setItem(ACCESS_TOKEN_NAME_USER, addCont);
          setState((prevState) => ({
            ...prevState,
            errorMessage: {},
          }));
          setTimeout(() => {
            toast.success('Contact Added Successfully', {
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true
            });
        });
        props.history.goBack()
         // props.history.push("/vendor");
        }
        else
        {
          setState((prevState) => ({
            ...prevState,
            errorMessage: "some error occurd",
          }));
        }

          }
       
         
    
        })
        }
      })

    
      if(j == 0)
      {
        const addCont =   await submitContactForm(payload)

        console.log('sssss', addCont)
        if(addCont)
        {
          setState((prevState) => ({
            ...prevState,
          }));

          //localStorage.setItem(ACCESS_TOKEN_NAME_USER, addCont);
          setState((prevState) => ({
            ...prevState,
            errorMessage: {},
          }));
          setTimeout(() => {
            toast.success('Contact Added Successfully', {
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true
            });
        });
          props.history.push("/vendor");
        }
        else
        {
          setState((prevState) => ({
            ...prevState,
            errorMessage: "some error occurd",
          }));
        }


      }


 
  
      return
      

    } else {
      setErrorMessage("Please Fill All The Fields");
    }
  };

  const submitContactForm = async (payload) => {
   
  
   const result =   await axios
        .post(API_BASE_URL + "/addContact", payload)
        .then(function (response) {
          
         console.log('responese', response)
    
           if (response.status === 200) {
            
        
            return true

           
          } else {
            return false
          }
        })
        .catch(function (error) {
          console.log(error);
          return false
        });

        return result
  }
  
  function handleLogout() {
    localStorage.removeItem(ACCESS_TOKEN_NAME);
    localStorage.removeItem('role');
    localStorage.removeItem('user');
    localStorage.removeItem(ACCESS_TOKEN_NAME_USER);
    props.history.push("/");
  }

  return (
    <>
    <div id="pcoded" className="pcoded">
        <div className="pcoded-overlay-box"></div>
        <div className="pcoded-container navbar-wrapper">

       
        <nav className="navbar header-navbar pcoded-header" style={{height:'55px', minHeight:'26px'}}> 
        <div className="navbar-wrapper">
            <div className="navbar-logo" style={{height: '52px'}}>
                <a style={{top:'7px'}} className="mobile-menu navabar_menu" id="mobile-collapse" href="#!">
                    <i class="ti-menu" style={{"color":" #000", "font-size" : "22px;"}}></i>
                </a>
                <a className="mobile-search morphsearch-search" href="#">
                    <i className="ti-search"></i>
                </a>
                <Link to="/dashboard">
                    <img style={{width:'30%'}} className="img-fluid logo-img" src={logo} alt="Theme-Logo" />
                </Link>
                <a className="mobile-options">
                    <i className="ti-more"></i>
                </a>
            </div>
            
            <div className="navbar-container container-fluid">
                <ul className="nav-right">
                    <li className="user-profile header-notification" style={{lineHeight: 3.5}}>
                        <a href="javascript:void(0)" onClick={()=>{
                              setdisplay((old)=>(old == 'none') ? 'block' : 'none')
                          }}>
                          <Avatar name={localStorage.getItem('user')} colors={['#ed3028', '#711999', '#383838']} size="40" round="20px" />
                        </a>
                        <ul className="show-notification profile-notification" style={{display:display}}>
                            <li>
                                <a href="#">
                                    <i className="ti-user"></i> Profile
                                </a>
                            </li>
                            <li>
                              <Link to="/resetpassword">
                                <i className="ti-layout-sidebar-left"></i> Change Password
                              </Link>
                            </li>
                            <li>
                                <a href="" onClick={() => handleLogout()}>
                                    <i className="ti-layout-sidebar-left"></i> Logout
                                </a>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
            <div className="pcoded-main-container">
                <div className="pcoded-wrapper">
                    
                    <Sidebar />

                    <div className="pcoded-content page_content">
                        <div className="pcoded-inner-content">
                            <div className="main-body">
                                <div className="page-wrapper">
                                    <div className="page-body">
                                        <div className="row">
                                            <div className="col-md-3">
                                                <h4 style={{ marginTop: "20px", fontSize: "large"}}>Add Contact</h4>
                                            </div>
                                            <div className="col-md-9">
                                                {/* <h4>Edit User</h4> */}
                                            </div>
                                        </div>
                                        <div className="col-md-12 tabs-ui p-0">
                                        <div className="tab-content" id="nav-tabContent">
                                            <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                                                <form>
                                                  {/* {errorMessages && (
                                                    <span className="red">{errorMessages}</span>
                                                  )} */}
                                                  <div className="row">
                                                      <div className="col-md-6">
                                                            <div className="form-group text-left">
                                                                <label>First Name</label>
                                                                <input
                                                                type="text"
                                                                name="firstName"
                                                                className="form-control"
                                                                id="firstName"
                                                                placeholder="First Name"
                                                                value={state.firstName}
                                                                onChange={handleChange}
                                                                />
                                                                {state.errorMessage.firstName && (
                                                                <span className="red">{state.errorMessage.firstName}</span>
                                                                )}
                                                            </div>
                                                        </div>

                                                        <div className="col-md-6">
                                                            <div className="form-group text-left">
                                                                <label>Last Name</label>
                                                                <input
                                                                type="text"
                                                                name="lastName"
                                                                className="form-control"
                                                                id="lastName"
                                                                placeholder="Last Name"
                                                                value={state.lastName}
                                                                onChange={handleChange}
                                                                />
                                                                {state.errorMessage.lastName && (
                                                                <span className="red">{state.errorMessage.lastName}</span>
                                                                )}
                                                            </div>
                                                        </div>

                                                        <div className="col-md-6">
                                                            <div className="form-group text-left">
                                                            <label>Email</label>
                                                                <input
                                                                type="email"
                                                                name="Email"
                                                                className="form-control"
                                                                id="email"
                                                                aria-describedby="emailHelp"
                                                                placeholder="Email"
                                                                value={state.Email}
                                                                onChange={handleChange}
                                                                />

                                                                {state.errorMessage.Email && (
                                                                <span className="red">{state.errorMessage.Email}</span>
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group text-left">
                                                            <label>Phone</label>
                                                                <InputMask
                                                                name="Phone"
                                                                className="form-control"
                                                                id="mobile"
                                                                maxLength="10"
                                                                placeholder="Phone Number"
                                                                value={state.Phone}
                                                
                                                                onChange={handleChange}
                                                                // minLength="10"
                                                                // maxLength="10"
                                                                />
                                                                {state.errorMessage.Phone && (
                                                                <span className="red">{state.errorMessage.Phone}</span>
                                                                )}
                                                            </div>
                                                        </div>

                                                        <div className="col-md-6">
                                                            <div className="form-group text-left">
                                                            <label>Contact Type</label>
                                                                <select
                                                                className="form-control"
                                                                name="ContactType"
                                                                value={state.ContactType}
                                                                onChange={handleChange}
                                                                >
                                                                <option value="">Select Contact Type</option>
                                                                <option value="Driver">Driver</option>
                                                                <option value="Other">Other</option>
                                                                </select>
                                                                {state.errorMessage.ContactType && (
                                                                <span className="red">{state.errorMessage.ContactType}</span>
                                                                )}
                                                            </div>
                                                        </div>

                                                        <div className="col-md-6">
                                                            <div className="form-group text-left">
                                                            <label>Additional Phone</label>
                                                                <InputMask
                                                                name="DriverPhone"
                                                                className="form-control"
                                                                id="mobile"
                                                                maxLength="10"
                                                                placeholder="Driver Phone"
                                                                value={state.DriverPhone}
                                                                onChange={handleChange}
                                                                // minLength="10"
                                                                // maxLength="10"
                                                                />
                                                                {state.errorMessage.DriverPhone && (
                                                                <span className="red">{state.errorMessage.DriverPhone}</span>
                                                                )}
                                                            </div>
                                                        </div>

                                                        <div className="col-md-6">
                                                            <div className="form-group text-left">
                                                            <label>Additional Email</label>
                                                                <input
                                                                type="email"
                                                                name="DriverEmail"
                                                                className="form-control"
                                                                id="email"
                                                                aria-describedby="emailHelp"
                                                                placeholder="Driver Email"
                                                                value={state.DriverEmail}
                                                                onChange={handleChange}
                                                                />
                                                                {state.errorMessage.DriverEmail && (
                                                                <span className="red">{state.errorMessage.DriverEmail}</span>
                                                                )}
                                                            </div>
                                                        </div>

                                                        <div className="col-md-6">
                                                            <div className="form-group text-left">
                                                            <label>Truck Type</label>
                                                                <select
                                                                className="form-control"
                                                                name="TruckType"
                                                                value={state.TruckType}
                                                                onChange={handleChange}
                                                                >
                                                                <option value="">Select Truck Type</option>
                                                                <option value="Pick Up Truck">Pick Up Truck</option>
                                                                <option value="Semi">Semi</option>
                                                                <option value="Tow Truck">Tow Truck</option>
                                                                </select>
                                                                {state.errorMessage.TruckType && (
                                                                <span className="red">{state.errorMessage.TruckType}</span>
                                                                )}
                                                            </div>
                                                        </div>

                                                        <div className="col-md-6">
                                                            <div className="form-group text-left">
                                                            <label>Trailer Type</label>
                                                                <select
                                                                className="form-control"
                                                                name="TrailerType"
                                                                value={state.TrailerType}
                                                                onChange={handleChange}
                                                                >
                                                                <option value="">Select Trailer Type</option>
                                                                <option value="Tilt Deck">Tilt Deck</option>
                                                                <option value="Tow Truck">Tow Truck</option>
                                                                <option value="Flatbed">Flatbed</option>
                                                                </select>
                                                                {state.errorMessage.TrailerType && (
                                                                <span className="red">{state.errorMessage.TrailerType}</span>
                                                                )}
                                                            </div>
                                                        </div>

                                                        <div className="col-md-6">
                                                            <div className="form-group text-left">
                                                            <label>Trailer Size</label>
                                                                <select
                                                                className="form-control"
                                                                name="TrailerSize"
                                                                value={state.TrailerSize}
                                                                onChange={handleChange}
                                                                >
                                                                <option value="">Select Trailer Size</option>
                                                                <option value="20 ft">20 ft</option>
                                                                <option value="22 ft">22 ft</option>
                                                                <option value="40 ft">40 ft</option>
                                                                </select>
                                                                {state.errorMessage.TrailerSize && (
                                                                <span className="red">{state.errorMessage.TrailerSize}</span>
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group text-left">
                                                            <label>Supplier Location</label>
                                                                <Multiselect
                                                                options={multiSelectOptions}
                                                                onSelect={onSelect}
                                                                onRemove={onRemove}
                                                                displayValue="name"
                                                                placeholder="Select Supplier"
                                                                />
                                                                 {state.errorMessage.AssignedTerritory && (
                                                                <span className="red">{state.errorMessage.AssignedTerritory}</span>
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className="col-md-12 cstm-add-btn">
                                                            <button
                                                                type="button"
                                                                className="btn btn-primary mr-2"
                                                                onClick={handleSubmitClick}
                                                            >
                                                                Add
                                                            </button>
                                                            <button
                                                                type="button"
                                                                className="btn btn-default"
                                                                onClick={handleCancel}
                                                            >
                                                                Cancel
                                                            </button>
                                                        </div>
                                                  </div>
                                                </form>
                                                <div
                                                  className="alert alert-success mt-2"
                                                  style={{ display: state.successMessage ? "block" : "none" }}
                                                  role="alert"
                                                >
                                                  {state.successMessage}
                                                </div>
                                           

                                            </div>
                                          <div className="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">2</div>
                                          <div className="tab-pane fade" id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab">3</div>
                                      </div>
                                            
                                        </div>

                                        <Footer />
                                        <ToastContainer />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> 
    </>
  );

};

export default AddContact;
