import React, { useState, useEffect } from "react";
import moment from "moment";
import axios from "axios";
import avoidNull from "../../utils/AvoidNull";
import avoidNull2 from "../../utils/AvoidNull2";
import removeAddress from "../../utils/RemoveAddress";
import MaterialTable from "material-table";
import CustomDatePicker from "../../utils/customDatePicker";
import FilterComponentBox from "../../utils/FilterComponentBox";
import ResetFillterButton from "../../utils/ResetFillterButton";
import { useCookies } from "react-cookie";
import Cookies from "universal-cookie";

import {
  ACCESS_TOKEN_NAME,
  ACCESS_TOKEN_NAME_USER,
  API_BASE_URL,
} from "../../constants/apiConstants";

function DataTable(props) {
  const [pageRefresh, setPageRefresh] = useState(false);
  const [cookies, setCookie, removeCookie] = useCookies(["needsdispatch"]);
  const cookiesi = new Cookies();

 

  const resetFilter = async () => {
 

    const test = document.querySelectorAll(".material-icons");
    console.log("asda",test);
    for(let tests of test)
    {
      if(tests.getAttribute("aria-label") == 'clear')
      {
        tests.click();
      }
    }
  //  let text = test.getAttribute("aria-label");

    const cookies = new Cookies();
    if (Object.keys(cookies.getAll()).length > 0) {
      setPageRefresh(true);
    }
    else {
      setPageRefresh(true);
  }
    await removeCookie("orderBy", { path: "/needsdispatch" });
    await removeCookie("direction", { path: "/needsdispatch" });
    await removeCookie("invoiceRefNumber", { path: "/needsdispatch" });
    await removeCookie("invoicePaidDate", { path: "/needsdispatch" });
    await removeCookie("invoiceTerms", { path: "/needsdispatch" });
    await removeCookie("invoiceLineItemItemNameWithoutParent", {
      path: "/needsdispatch",
    });
    await removeCookie("invoiceAssignedToName", { path: "/needsdispatch" });
    await removeCookie("resalePOPickupCity", { path: "/needsdispatch" });
    await removeCookie("resalePORefNumer", { path: "/needsdispatch" });
    await removeCookie("resalePOReleaseNumber", { path: "/needsdispatch" });
    await removeCookie("resalePOReleaseDate", { path: "/needsdispatch" });
    await removeCookie("resalePOTotalPrice", { path: "/needsdispatch" });
    await removeCookie("resalePOExpectedDate", { path: "/needsdispatch" });
    await removeCookie("resalePOLastDepotCallDate", { path: "/needsdispatch" });
    await removeCookie("newDistance", { path: "/needsdispatch" });
    await removeCookie("invoiceShipCity", { path: "/needsdispatch" });
    await removeCookie("transportPOLineItemContainerNumber", {
      path: "/needsdispatch",
    });
    await removeCookie("transportPORefNumer", { path: "/needsdispatch" });
    await removeCookie("transportPOVendor", { path: "/needsdispatch" });
    await removeCookie("transportPOTotalPrice", { path: "/needsdispatch" });
    await removeCookie("transportPOScheduledDeliveryDate", {
      path: "/needsdispatch",
    });
    await removeCookie("transportPODeliveryStatus", { path: "/needsdispatch" });
    await removeCookie("invoicePromisedTimeline", { path: "/needsdispatch" });
    await removeCookie("invoicePromisedDate", { path: "/needsdispatch" });
    await removeCookie("invoiceSupplierLocation", { path: "/needsdispatch" });
    await removeCookie("resalePOVendor", { path: "/needsdispatch" });
    await removeCookie("invoiceIsExpedited", { path: "/needsdispatch" });
  };

  useEffect(() => {
    console.log('hmmmmmmmmmmmmmm',props.refreshCount);
    setPageRefresh(true);
    
 
}, [props.refreshCount]);

  return (
    <div className="col-md-12">
      <MaterialTable
        title=""
        key={pageRefresh}
        columns={[
          { title: "RecordID", field: "RecordID", hidden: true },

          {
            title: "Customer",
            field: "invoiceRefNumber",
            defaultSort:
              cookiesi.getAll()["orderBy"] == "invoiceCustomerFullName"
                ? cookiesi.getAll()["direction"]
                : false,
            filterComponent: ({ columnDef, onFilterChanged }) => (
              <FilterComponentBox
                pageName={"needsdispatch"}
                onFilterChanged={onFilterChanged}
                columnDef={columnDef}
                columnName={columnDef.field}
              />
            ),
            render: (rowData) => (
              <a
              className={`breakwordwichpadding ${rowData.sameTrailerMarker === 1 &&  rowData.invoiceIsExpedited === 'Yes' ? 'markerExp' : rowData.invoiceIsExpedited === 'Yes' ? 'expeditedClass' : rowData.sameTrailerMarker === 1 ? 'sameMarker' : ''}`}
                target="_blank"
                href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID}
              >
                <strong className="title-strong">
                  {rowData.invoiceCustomerFullName?.split("*")[0]}
                </strong>
                <br />
                {rowData.invoiceCustomerFullName?.split("*")[1]}
              </a>
            ),
          },

          {
            title: "Paid Date",
            field: "invoicePaidDate",
            defaultSort:
              cookiesi.getAll()["orderBy"] == "invoicePaidDate"
                ? cookiesi.getAll()["direction"]
                : false,
            filterComponent: (props) => (
              <CustomDatePicker pageName={"needsdispatch"} {...props} />
            ),
            type: "date",
            render: (rowData) => (
              <a className={rowData.sameTrailerMarker === 1 &&  rowData.invoiceIsExpedited === 'Yes' ? 'markerExp' : rowData.invoiceIsExpedited === 'Yes' ? 'expeditedClass' : rowData.sameTrailerMarker === 1 ? 'sameMarker' : ''} target="_blank" href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID}>
                {rowData.invoicePaidDate
                  ? moment(rowData.invoicePaidDate).format("MM/DD/YY")
                  : ""}
              </a>
            ),
          },

          {
            title: "Terms",
            field: "invoiceTerms",
            defaultSort:
              cookiesi.getAll()["orderBy"] == "invoiceTerms"
                ? cookiesi.getAll()["direction"]
                : false,
            filterComponent: ({ columnDef, onFilterChanged }) => (
              <FilterComponentBox
                pageName={"needsdispatch"}
                onFilterChanged={onFilterChanged}
                columnDef={columnDef}
                columnName={columnDef.field}
              />
            ),
            render: (rowData) => (
              <a
              className={`breakwordwichpadding ${rowData.sameTrailerMarker === 1 &&  rowData.invoiceIsExpedited === 'Yes' ? 'markerExp' : rowData.invoiceIsExpedited === 'Yes' ? 'expeditedClass' : rowData.sameTrailerMarker === 1 ? 'sameMarker' : ''}`}
                target="_blank"
                href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID}
                style={{
                  color:
                    rowData.invoiceTerms == "Rent To Own" ? "Red" : "#303548",
                }}
              >
                {rowData.invoiceTerms}
              </a>
            ),
          },

          {
            title: "Product",
            field: "invoiceLineItemItemNameWithoutParent",
            defaultSort:
              cookiesi.getAll()["orderBy"] ==
                "invoiceLineItemItemNameWithoutParent"
                ? cookiesi.getAll()["direction"]
                : false,
            filterComponent: ({ columnDef, onFilterChanged }) => (
              <FilterComponentBox
                pageName={"needsdispatch"}
                onFilterChanged={onFilterChanged}
                columnDef={columnDef}
                columnName={columnDef.field}
              />
            ),
            render: (rowData) => (
              <a className={rowData.sameTrailerMarker === 1 &&  rowData.invoiceIsExpedited === 'Yes' ? 'markerExp' : rowData.invoiceIsExpedited === 'Yes' ? 'expeditedClass' : rowData.sameTrailerMarker === 1 ? 'sameMarker' : ''} target="_blank" href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID}>
                {rowData.invoiceLineItemItemNameWithoutParent}
              </a>
            ),
          },

          {
            title: "Assign To",
            field: "invoiceAssignedToName",
            defaultSort:
              cookiesi.getAll()["orderBy"] == "invoiceAssignedToName"
                ? cookiesi.getAll()["direction"]
                : false,
            filterComponent: ({ columnDef, onFilterChanged }) => (
              <FilterComponentBox
                pageName={"needsdispatch"}
                onFilterChanged={onFilterChanged}
                columnDef={columnDef}
                columnName={columnDef.field}
              />
            ),
            render: (rowData) => (
              <a
              className={`breakword ${rowData.sameTrailerMarker === 1 &&  rowData.invoiceIsExpedited === 'Yes' ? 'markerExp' : rowData.invoiceIsExpedited === 'Yes' ? 'expeditedClass' : rowData.sameTrailerMarker === 1 ? 'sameMarker' : ''}`}
                target="_blank"
                href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID}
              >
                {rowData.invoiceAssignedToName}
              </a>
            ),
          },

          {
            title: "Expedited",
            field: "invoiceIsExpedited",
            defaultSort:
              cookiesi.getAll()["orderBy"] == "invoiceIsExpedited"
                ? cookiesi.getAll()["direction"]
                : false,
            filterComponent: ({ columnDef, onFilterChanged }) => (
              <FilterComponentBox
                pageName={"needsdispatch"}
                onFilterChanged={onFilterChanged}
                columnDef={columnDef}
                columnName={columnDef.field}
              />
            ),
            render: (rowData) => (
              <a
              className={`breakword ${rowData.sameTrailerMarker === 1 &&  rowData.invoiceIsExpedited === 'Yes' ? 'markerExp' : rowData.invoiceIsExpedited === 'Yes' ? 'expeditedClass' : rowData.sameTrailerMarker === 1 ? 'sameMarker' : ''}`}
                target="_blank"
                href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID}
              >
                <center>{rowData.invoiceIsExpedited}</center>
              </a>
            ),
          },

          {
            title: "Container #",
            field: "transportPOLineItemContainerNumber",
            defaultSort:
              cookiesi.getAll()["orderBy"] ==
                "transportPOLineItemContainerNumber"
                ? cookiesi.getAll()["direction"]
                : false,
            filterComponent: ({ columnDef, onFilterChanged }) => (
              <FilterComponentBox
                pageName={"needsdispatch"}
                onFilterChanged={onFilterChanged}
                columnDef={columnDef}
                columnName={columnDef.field}
              />
            ),
            render: (rowData) => (
              <a className={rowData.sameTrailerMarker === 1 &&  rowData.invoiceIsExpedited === 'Yes' ? 'markerExp' : rowData.invoiceIsExpedited === 'Yes' ? 'expeditedClass' : rowData.sameTrailerMarker === 1 ? 'sameMarker' : ''} target="_blank" href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID}>
                {rowData.transportPOLineItemContainerNumber}
              </a>
            ),
          },

          {
            title: "Supp Loc",
            field: "invoiceSupplierLocation",
            defaultSort:
              cookiesi.getAll()["orderBy"] == "invoiceSupplierLocation"
                ? cookiesi.getAll()["direction"]
                : false,
            filterComponent: ({ columnDef, onFilterChanged }) => (
              <FilterComponentBox
                pageName={"needsdispatch"}
                onFilterChanged={onFilterChanged}
                columnDef={columnDef}
                columnName={columnDef.field}
              />
            ),
            render: (rowData) => (
              <a
              className={`breakword ${rowData.sameTrailerMarker === 1 &&  rowData.invoiceIsExpedited === 'Yes' ? 'markerExp' : rowData.invoiceIsExpedited === 'Yes' ? 'expeditedClass' : rowData.sameTrailerMarker === 1 ? 'sameMarker' : ''}`}
                target="_blank"
                href={
                  "https://www.google.com/maps/place/" +
                  rowData.invoiceSupplierLocation
                }
              >
                {rowData.invoiceSupplierLocation}
              </a>
            ),
          },

          {
            title: "Res PO#",
            field: "resalePORefNumer",
            defaultSort:
              cookiesi.getAll()["orderBy"] == "resalePORefNumer"
                ? cookiesi.getAll()["direction"]
                : false,
            filterComponent: ({ columnDef, onFilterChanged }) => (
              <FilterComponentBox
                pageName={"needsdispatch"}
                onFilterChanged={onFilterChanged}
                columnDef={columnDef}
                columnName={columnDef.field}
              />
            ),
            render: (rowData) => (
              <a
                className={rowData.sameTrailerMarker === 1 &&  rowData.invoiceIsExpedited === 'Yes' ? 'markerExp' : rowData.invoiceIsExpedited === 'Yes' ? 'expeditedClass' : rowData.sameTrailerMarker === 1 ? 'sameMarker' : ''}
                target="_blank"
                href={"/resalePurchaseOrder/" + rowData.ResalePORecordID + "/" + rowData.RecordID}
              >
                {rowData.resalePORefNumer}
              </a>
            ),
          },

          {
            title: "Res Ven",
            field: "resalePOVendor",
            defaultSort:
              cookiesi.getAll()["orderBy"] == "resalePOVendor"
                ? cookiesi.getAll()["direction"]
                : false,
            filterComponent: ({ columnDef, onFilterChanged }) => (
              <FilterComponentBox
                pageName={"needsdispatch"}
                onFilterChanged={onFilterChanged}
                columnDef={columnDef}
                columnName={columnDef.field}
              />
            ),
            render: (rowData) => (
              <a
                className={`breakword ${rowData.sameTrailerMarker === 1 &&  rowData.invoiceIsExpedited === 'Yes' ? 'markerExp' : rowData.invoiceIsExpedited === 'Yes' ? 'expeditedClass' : rowData.sameTrailerMarker === 1 ? 'sameMarker' : ''}`}
                target="_blank"
                href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID}
              >
                {rowData.resalePOVendor}
              </a>
            ),
          },

          {
            title: "Rele #",
            field: "resalePOReleaseNumber",
            defaultSort:
              cookiesi.getAll()["orderBy"] == "resalePOReleaseNumber"
                ? cookiesi.getAll()["direction"]
                : false,
            filterComponent: ({ columnDef, onFilterChanged }) => (
              <FilterComponentBox
                pageName={"needsdispatch"}
                onFilterChanged={onFilterChanged}
                columnDef={columnDef}
                columnName={columnDef.field}
              />
            ),
            render: (rowData) => (
              <a className={rowData.sameTrailerMarker === 1 &&  rowData.invoiceIsExpedited === 'Yes' ? 'markerExp' : rowData.invoiceIsExpedited === 'Yes' ? 'expeditedClass' : rowData.sameTrailerMarker === 1 ? 'sameMarker' : ''} target="_blank" href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID}>
                {rowData.resalePOReleaseNumber}
              </a>
            ),
          },

          {
            title: "Release Date",
            field: "resalePOReleaseDate",
            defaultSort:
              cookiesi.getAll()["orderBy"] == "resalePOReleaseDate"
                ? cookiesi.getAll()["direction"]
                : false,
            filterComponent: (props) => (
              <CustomDatePicker pageName={"needsdispatch"} {...props} />
            ),
            type: "date",
            render: (rowData) => (
              <a className={rowData.sameTrailerMarker === 1 &&  rowData.invoiceIsExpedited === 'Yes' ? 'markerExp' : rowData.invoiceIsExpedited === 'Yes' ? 'expeditedClass' : rowData.sameTrailerMarker === 1 ? 'sameMarker' : ''} target="_blank" href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID}>
                {rowData.resalePOReleaseDate
                  ? moment(rowData.resalePOReleaseDate).format("MM/DD/YY")
                  : ""}
              </a>
            ),
          },

          {
            title: "Res Total",
            field: "resalePOTotalPrice",
            defaultSort:
              cookiesi.getAll()["orderBy"] == "resalePOTotalPrice"
                ? cookiesi.getAll()["direction"]
                : false,
            filterComponent: ({ columnDef, onFilterChanged }) => (
              <FilterComponentBox
                pageName={"needsdispatch"}
                onFilterChanged={onFilterChanged}
                columnDef={columnDef}
                columnName={columnDef.field}
              />
            ),
            render: (rowData) => (
              <a className={rowData.sameTrailerMarker === 1 &&  rowData.invoiceIsExpedited === 'Yes' ? 'markerExp' : rowData.invoiceIsExpedited === 'Yes' ? 'expeditedClass' : rowData.sameTrailerMarker === 1 ? 'sameMarker' : ''} target="_blank" href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID}>
                {rowData.resalePOTotalPrice}
              </a>
            ),
          },

          {
            title: "Exp Date",
            field: "resalePOExpectedDate",
            defaultSort:
              cookiesi.getAll()["orderBy"] == "resalePOExpectedDate"
                ? cookiesi.getAll()["direction"]
                : false,
            filterComponent: (props) => (
              <CustomDatePicker pageName={"needsdispatch"} {...props} />
            ),
            type: "date",
            render: (rowData) => (
              <a className={rowData.sameTrailerMarker === 1 &&  rowData.invoiceIsExpedited === 'Yes' ? 'markerExp' : rowData.invoiceIsExpedited === 'Yes' ? 'expeditedClass' : rowData.sameTrailerMarker === 1 ? 'sameMarker' : ''} target="_blank" href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID}>
                {rowData.resalePOExpectedDate
                  ? moment(rowData.resalePOExpectedDate).format("MM/DD/YY")
                  : ""}
              </a>
            ),
          },

          {
            title: "Last Depot Call",
            field: "resalePOLastDepotCallDate",
            defaultSort:
              cookiesi.getAll()["orderBy"] == "resalePOLastDepotCallDate"
                ? cookiesi.getAll()["direction"]
                : false,
            filterComponent: (props) => (
              <CustomDatePicker pageName={"needsdispatch"} {...props} />
            ),
            type: "date",
            render: (rowData) => (
              <a className={rowData.sameTrailerMarker === 1 &&  rowData.invoiceIsExpedited === 'Yes' ? 'markerExp' : rowData.invoiceIsExpedited === 'Yes' ? 'expeditedClass' : rowData.sameTrailerMarker === 1 ? 'sameMarker' : ''} target="_blank" href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID}>
                {rowData.resalePOLastDepotCallDate
                  ? moment(rowData.resalePOLastDepotCallDate).format("MM/DD/YY")
                  : ""}
              </a>
            ),
          },

          {
            title: "Planned",
            field: "plannedStatus",
            defaultSort:
              cookiesi.getAll()["orderBy"] == "plannedStatus"
                ? cookiesi.getAll()["direction"]
                : false,
            filterComponent: ({ columnDef, onFilterChanged }) => (
              <FilterComponentBox
                pageName={"needsdispatch"}
                onFilterChanged={onFilterChanged}
                columnDef={columnDef}
                columnName={columnDef.field}
              />
            ),
            render: (rowData) => (
              <a className={rowData.sameTrailerMarker === 1 &&  rowData.invoiceIsExpedited === 'Yes' ? 'markerExp' : rowData.invoiceIsExpedited === 'Yes' ? 'expeditedClass' : rowData.sameTrailerMarker === 1 ? 'sameMarker' : ''} target="_blank" href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID}>
                <center>{rowData.plannedStatus}</center>
              </a>
            ),
          },

          {
            title: "Pickup Loc",
            field: "resalePOPickupCity",
            defaultSort:
              cookiesi.getAll()["orderBy"] == "resalePOPickupCity"
                ? cookiesi.getAll()["direction"]
                : false,
            filterComponent: ({ columnDef, onFilterChanged }) => (
              <FilterComponentBox
                pageName={"needsdispatch"}
                onFilterChanged={onFilterChanged}
                columnDef={columnDef}
                columnName={columnDef.field}
              />
            ),
            render: (rowData) => (
              <a
                className={`breakword ${rowData.sameTrailerMarker === 1 &&  rowData.invoiceIsExpedited === 'Yes' ? 'markerExp' : rowData.invoiceIsExpedited === 'Yes' ? 'expeditedClass' : rowData.sameTrailerMarker === 1 ? 'sameMarker' : ''}`}
                target="_blank"
                href={
                  "https://www.google.com/maps/place/" + rowData.pickupLocation
                }
              >
                {rowData.resalePOPickupCity}
                <br />
                {rowData.pickupState}
              </a>
            ),
          },

          {
            title: "Del City/State",
            field: "invoiceShipCity",
            defaultSort:
              cookiesi.getAll()["orderBy"] == "invoiceShipCity"
                ? cookiesi.getAll()["direction"]
                : false,
            filterComponent: ({ columnDef, onFilterChanged }) => (
              <FilterComponentBox
                pageName={"needsdispatch"}
                onFilterChanged={onFilterChanged}
                columnDef={columnDef}
                columnName={columnDef.field}
              />
            ),
            render: (rowData) => {
              return (
                <a className={`breakword ${rowData.sameTrailerMarker === 1 &&  rowData.invoiceIsExpedited === 'Yes' ? 'markerExp' : rowData.invoiceIsExpedited === 'Yes' ? 'expeditedClass' : rowData.sameTrailerMarker === 1 ? 'sameMarker' : ''}`} target="_blank" href={`https://www.google.com/maps/dir/?api=1&origin=${avoidNull(rowData.resalePOPickupAddress1)}${avoidNull(rowData.resalePOPickupCity)}${avoidNull2(rowData.resalePOPickupZipCode)}&destination=${removeAddress(rowData.invoiceShipAddress1,rowData.shipState)}${removeAddress(rowData.invoiceShipAddress2, rowData.shipState)}${removeAddress(rowData.invoiceShipAddress3, rowData.shipState)}${avoidNull(rowData.invoiceShipCity)}${avoidNull(rowData.shipState)}${avoidNull2(rowData.invoiceShipZip)}`}>{rowData.invoiceShipCity}<br />{rowData.shipState}</a>
              );
            },
          },

          {
            title: "Miles",
            field: "newDistance",
            defaultSort:
              cookiesi.getAll()["orderBy"] == "newDistance"
                ? cookiesi.getAll()["direction"]
                : false,
            filterComponent: ({ columnDef, onFilterChanged }) => (
              <FilterComponentBox
                pageName={"needsdispatch"}
                onFilterChanged={onFilterChanged}
                columnDef={columnDef}
                columnName={columnDef.field}
              />
            ),
            render: (rowData) => (
              <a className={rowData.sameTrailerMarker === 1 &&  rowData.invoiceIsExpedited === 'Yes' ? 'markerExp' : rowData.invoiceIsExpedited === 'Yes' ? 'expeditedClass' : rowData.sameTrailerMarker === 1 ? 'sameMarker' : ''} target="_blank" href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID}>
                {  rowData.newDistance ? Math.round(rowData.newDistance) : ''}
              </a>
            ),
          },

          {
            title: "Ship Method",
            field: "invoiceShipMethodMethodFieldName",
            defaultSort:
              cookiesi.getAll()["orderBy"] == "invoiceShipMethodMethodFieldName"
                ? cookiesi.getAll()["direction"]
                : false,
            filterComponent: ({ columnDef, onFilterChanged }) => (
              <FilterComponentBox
                pageName={"needsdispatch"}
                onFilterChanged={onFilterChanged}
                columnDef={columnDef}
                columnName={columnDef.field}
              />
            ),
            render: (rowData) => (
              <a
                target="_blank"
                className={`breakwordwichpadding ${rowData.sameTrailerMarker === 1 &&  rowData.invoiceIsExpedited === 'Yes' ? 'markerExp' : rowData.invoiceIsExpedited === 'Yes' ? 'expeditedClass' : rowData.sameTrailerMarker === 1 ? 'sameMarker' : ''}`}
                href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID}
              >
                {rowData.invoiceShipMethodMethodFieldName}
              </a>
            ),
          },

          {
            title: "Prom Timeline",
            field: "invoicePromisedTimeline",
            defaultSort:
              cookiesi.getAll()["orderBy"] == "invoicePromisedTimeline"
                ? cookiesi.getAll()["direction"]
                : false,
            filterComponent: ({ columnDef, onFilterChanged }) => (
              <FilterComponentBox
                pageName={"needsdispatch"}
                onFilterChanged={onFilterChanged}
                columnDef={columnDef}
                columnName={columnDef.field}
              />
            ),
            render: (rowData) => (
              <a className={rowData.sameTrailerMarker === 1 &&  rowData.invoiceIsExpedited === 'Yes' ? 'markerExp' : rowData.invoiceIsExpedited === 'Yes' ? 'expeditedClass' : rowData.sameTrailerMarker === 1 ? 'sameMarker' : ''} target="_blank" href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID}>
                {rowData.invoicePromisedTimeline}
              </a>
            ),
          },

          {
            title: "Prom Date",
            field: "invoicePromisedDate",
            defaultSort:
              cookiesi.getAll()["orderBy"] == "invoicePromisedDate"
                ? cookiesi.getAll()["direction"]
                : false,
            filterComponent: (props) => (
              <CustomDatePicker pageName={"needsdispatch"} {...props} />
            ),
            type: "date",
            render: (rowData) => (
              <a className={rowData.sameTrailerMarker === 1 &&  rowData.invoiceIsExpedited === 'Yes' ? 'markerExp' : rowData.invoiceIsExpedited === 'Yes' ? 'expeditedClass' : rowData.sameTrailerMarker === 1 ? 'sameMarker' : ''} target="_blank" href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID}>
                {rowData.invoicePromisedDate
                  ? moment(rowData.invoicePromisedDate).format("MM/DD/YY")
                  : ""}
              </a>
            ),
          },

          {
            title: "Inv Num",
            cellStyle: {
              display: "none",
            },

            headerStyle: {
              display: "none",
            },

            field: "invoiceRefNumber",
            render: (rowData) => (
              <a className={rowData.sameTrailerMarker === 1 &&  rowData.invoiceIsExpedited === 'Yes' ? 'markerExp' : rowData.invoiceIsExpedited === 'Yes' ? 'expeditedClass' : rowData.sameTrailerMarker === 1 ? 'sameMarker' : ''} href={"/invoice/" + rowData.RecordID + "/" + rowData.invoiceLineItem_RecordID}>
                {rowData.invoiceRefNumber}
              </a>
            ),
          },

          {
            render: (rowData) =>
              props.despatchLoading ? (
                <button disabled class="btn btn-primary custome-btn">
                  Dispatch
                </button>
              ) : (
                <button
                  onClick={() =>
                    props.doDispatch(
                      rowData.invoiceRefNumber,
                      rowData.Product,
                      rowData.orderStatusRecordID,
                      rowData.resalePOIsDepotCallCompleted
                    )
                  }
                  class="btn btn-primary custome-btn"
                >
                  Dispatch
                </button>
              ),
          },
        ]}
        data={(query) =>
          new Promise((resolve, reject) => {
            const cookies = new Cookies();
            cookies.remove("adminer_version");
            if (
              query.filters.length > 0 ||
              Object.keys(cookies.getAll()).length > 0
            ) {
              let url = API_BASE_URL + `/fetchOrderBasedOnColumn?`;
              url += "size=" + query.pageSize;
              url += "&page=" + (query.page + 1);
              url += '&orderNumber=' + props.filterData;
              if (query.orderBy) {
                removeCookie("orderBy", { path: "/needsdispatch" });
                removeCookie("direction", { path: "/needsdispatch" });
                if (query.orderBy.field == 'invoiceRefNumber') {
                  setCookie("orderBy", "invoiceCustomerFullName", { path: '/needsdispatch' });
                }
                else {
                  setCookie("orderBy", query.orderBy.field, { path: '/needsdispatch' });

                }
                setCookie("direction", query.orderDirection, { path: '/needsdispatch' });
              }
              else {
                url += '&sortField=invoicePromisedDate';
                url += '&sortType=asc';
                url += '&sortField=invoiceCustomerFullName';
                url += '&sortType=asc';
                url += '&sortField=resalePORecordID';
                url += '&sortType=asc';
              }

              if (Object.keys(cookies.getAll()).length > 0) {

                for (const key in cookies.cookies) {

                    console.log(moment.isDate(cookies.cookies[key].replace('"', '').replace('"', '')), 'momentinfo', cookies.cookies[key], key);
                    if(cookies.cookies[key] == 'Invalid date')
                    {
                        console.log('okkk')
                         removeCookie(key, { path: "/needsdispatch" });
                    }
                    
                    else
                    {
                        if (cookies.cookies[key] && moment.isDate(cookies.cookies[key])) {
                        cookies.cookies[key] = moment(cookies.cookies[key]).format('YYYY-MM-DD')
                    }

                    if (cookies.cookies[key]) {
                        if (key == 'invoiceRefNumber' && isNaN(cookies.cookies[key])) {
                            url += '&columnName=' + 'invoiceCustomerFullName';
                            if (cookies.cookies[key].toLowerCase() == 'yes') {
                                url += '&search=' + 1;
                            } else if (cookies.cookies[key].toLowerCase() == 'no') {
                                url += '&search=' + 0;
                            } else {
                                url += '&search=' + encodeURIComponent(cookies.cookies[key]);
                            }
                        }
                        else if (key == 'orderBy') {

                            url += '&sortField=' + cookies.cookies[key]

                            // url += '&sortType=' + cookies.cookies['direction']
                        }
                        else if (key == 'direction') {
                            url += '&sortType=' + cookies.cookies[key]
                        }
                        else {
                            url += '&columnName=' + key;
                            if (cookies.cookies[key].toLowerCase() == 'yes') {
                                url += '&search=' + 1;
                            } else if (cookies.cookies[key].toLowerCase() == 'no') {
                                url += '&search=' + 0;
                            }

                            else {
                                url += '&search=' + encodeURIComponent(cookies.cookies[key]);
                            }
                        }
                    } 
                    }

            
                }
            } else {
                query.filters.forEach((item) => {
                  if (item.value && moment.isDate(item.value)) {
                    item.value = moment(item.value).format("YYYY-MM-DD");
                  }

                  if (
                    item.column.field == "invoiceRefNumber" &&
                    isNaN(parseInt(item.value))
                  ) {
                    url += "&columnName=" + "invoiceCustomerFullName";
                    if (item.value.toLowerCase() == "yes") {
                      url += "&search=" + 1;
                    } else if (item.value.toLowerCase() == "no") {
                      url += "&search=" + 0;
                    } else {
                      url += "&search=" + encodeURIComponent(item.value);
                    }
                  } else {
                    url += "&columnName=" + item.column.field;
                    if (item.value.toLowerCase() == "yes") {
                      url += "&search=" + 1;
                    } else if (item.value.toLowerCase() == "no") {
                      url += "&search=" + 0;
                    } else {
                      url += "&search=" + encodeURIComponent(item.value);
                    }
                  }
                });
              }

              axios
                .get(url, {
                  headers: {
                    Authorization:
                      "Bearer " + localStorage.getItem(ACCESS_TOKEN_NAME),
                  },
                })
                .then((result) => {
                  resolve({
                    data: result.data.rows.map((data) => ({
                      RecordID: data.invoiceRecordID,
                      orderStatusRecordID: data.recordID,
                      invoiceCustomerFullName: `${data?.invoiceCustomerFullName?.replace(
                        ",",
                        " "
                      )}*${data?.invoiceRefNumber}`,
                      invoiceTerms: data?.invoiceTerms,
                      invoiceIsExpedited: data.invoiceIsExpedited
                        ? "Yes"
                        : "No",
                      transportPOLineItemContainerNumber:
                        data?.transportPOLineItemContainerNumber,
                      invoiceRefNumber: data?.invoiceRefNumber,
                      invoicePaidDate: data.invoicePaidDate
                        ? data.invoicePaidDate?.split("T")[0]
                        : "",
                      invoiceLineItemItemNameWithoutParent:
                        data?.invoiceLineItemItemNameWithoutParent,
                      invoiceAssignedToName: data?.invoiceAssignedToName,
                      invoiceSupplierLocation: data?.invoiceSupplierLocation,
                      resalePORefNumer: data?.resalePORefNumer,
                      resalePOVendor: data?.resalePOVendor,
                      solidSurface: (data.invoice?.solidSurface) ? data.invoice.solidSurface : 0,
                      towTruck: (data.invoice?.towTruck) ? data.invoice.towTruck : 0,
                      resalePOReleaseNumber: data?.resalePOReleaseNumber,
                      ResalePORecordID: data?.resalePORecordID,
                      resalePODistance: data?.resalePODistance,
                      resalePOIsDepotCallCompleted: data?.resalePOIsDepotCallCompleted,
                      // resalePOLineItemDistance:
                      //   data.resalePOLineItemDistance &&
                      //     data.resalePOLineItemDistance != 0
                      //     ? data.resalePOLineItemDistance
                      //     : data.purchaseOrder
                      //       ? data.purchaseOrder
                      //         .distanceBetweenPickupAndDeliveryAddress
                      //       : null,
                      newDistance: data.newDistance ? data.newDistance : null,
                      invoiceShipMethodMethodFieldName:
                        data?.invoiceShipMethodMethodFieldName,
                      invoiceShipAddress1: data?.invoiceShipAddress1,
                      invoiceShipAddress2: data?.invoiceShipAddress2,
                      invoiceShipAddress3: data?.invoiceShipAddress3,
                      invoiceShipCity: data?.invoiceShipCity,
                      invoiceShipZip: data?.invoiceShipZip,
                      resalePOPickupAddress1: data?.resalePOPickupAddress1,
                      resalePOPickupCity: data?.resalePOPickupCity,
                      resalePOPickupZipCode: data?.resalePOPickupZipCode,
                      pickupLocation:
                        data.resalePOPickupCity && data.resalePOPickupState
                          ? data.resalePOPickupCity +
                          ", " +
                          data.resalePOPickupState
                          : "",
                      resalePOReleaseDate: data.resalePOReleaseDate
                        ? data.resalePOReleaseDate?.split("T")[0]
                        : "",
                      resalePOTotalPrice: data?.resalePOTotalPrice,
                      plannedStatus:
                        data.planning && data.planning.plannedStatus == 1
                          ? "Yes"
                          : "No",
                      resalePOExpectedDate: data.resalePOExpectedDate
                        ? data.resalePOExpectedDate?.split("T")[0]
                        : "",
                      resalePOLastDepotCallDate: data.resalePOLastDepotCallDate
                        ? data.resalePOLastDepotCallDate?.split("T")[0]
                        : "",

                      invoiceShipCity: data.invoiceShipCity
                        ? data.invoiceShipCity
                        : "",
                      shipState: data.invoiceShipState
                        ? data.invoiceShipState
                        : "",

                      resalePOPickupCity: data.resalePOPickupCity
                        ? data.resalePOPickupCity
                        : "",
                      pickupState: data.resalePOPickupState
                        ? data.resalePOPickupState
                        : "",

                      invoiceLineItem_RecordID: data.invoiceLineItem_RecordID,

                      transportPODeliveryStatus:
                        data?.transportPODeliveryStatus,
                      invoicePromisedTimeline: data.invoicePromisedTimeline
                        ? data.invoicePromisedTimeline
                        : "",
                      invoicePromisedDate: data.invoicePromisedDate
                        ? data.invoicePromisedDate?.split("T")[0]
                        : "",
                        sameTrailerMarker: data.sameTrailerMarker,
                    })),
                    page: result.data.currentPage - 1,
                    totalCount: result.data.totalItems,
                    
                  });
                  setPageRefresh(false);
                });
            } else {
              let url = API_BASE_URL + `/fetchCompletedOrder?`;
              url += 'size=' + query.pageSize;
              url += '&page=' + (query.page + 1);
              url += '&orderNumber=' + props.filterData;
              if (query.orderBy) {
                setCookie("orderBy", query.orderBy.field, { path: '/needsdispatch' });
                setCookie("direction", query.orderDirection, { path: '/needsdispatch' });
                url += '&sortField=' + query.orderBy.field
                url += '&sortType=' + query.orderDirection
              } else {
                switch (props.filterData) {
                  case "2":
                    url += "&sortField=invoicePromisedDate";
                    url += "&sortType=desc";
                    url += "&sortField=invoiceCustomerFullName";
                    url += "&sortType=asc";
                    url += "&sortField=resalePORecordID";
                    url += "&sortType=asc";
                    break;
                  case "7":
                    url += "&sortField=invoicePromisedDate";
                    url += "&sortType=asc";
                    url += "&sortField=invoiceCustomerFullName";
                    url += "&sortType=asc";
                    url += "&sortField=resalePORecordID";
                    url += "&sortType=asc";
                    break;
                  case "15":
                    url += "&sortField=invoicePromisedDate";
                    url += "&sortType=asc";
                    url += "&sortField=invoiceCustomerFullName";
                    url += "&sortType=asc";
                    url += "&sortField=resalePORecordID";
                    url += "&sortType=asc";
                    break;
                  case "10":
                    url += "&sortField=transportPOScheduledDeliveryDate";
                    url += "&sortType=asc";
                    url += "&sortField=invoiceCustomerFullName";
                    url += "&sortType=asc";
                    url += "&sortField=resalePORecordID";
                    url += "&sortType=asc";
                    break;
                  default:
                    url += "&sortField=invoicePromisedDate";
                    url += "&sortType=asc";
                    url += "&sortField=invoiceCustomerFullName";
                    url += "&sortType=asc";
                    url += "&sortField=resalePORecordID";
                    url += "&sortType=asc";
                }
              }

              if (query.search) {
                url += "&search=" + encodeURIComponent(query.search);
              }

              if (props.endDate && props.startDate) {
                url += "&from=" + props.startDate;
                url += "&to=" + props.endDate;
              }
              if (query.filters && !query.search) {
                query.filters.forEach((item) => {
                  url += "&search=" + encodeURIComponent(item.value);
                });
              }

              axios
                .get(url, {
                  headers: {
                    Authorization:
                      "Bearer " + localStorage.getItem(ACCESS_TOKEN_NAME),
                  },
                })
                .then((result) => {
                  resolve({
                    data: result.data.rows.map((data) => ({
                      RecordID: data.invoiceRecordID,
                      orderStatusRecordID: data.recordID,
                      invoiceCustomerFullName: `${data?.invoiceCustomerFullName?.replace(
                        ",",
                        " "
                      )}*${data?.invoiceRefNumber}`,
                      resalePOIsDepotCallCompleted: data?.resalePOIsDepotCallCompleted,
                      invoiceTerms: data?.invoiceTerms,
                      invoiceIsExpedited: data.invoiceIsExpedited
                        ? "Yes"
                        : "No",
                      transportPOLineItemContainerNumber:
                        data?.transportPOLineItemContainerNumber,
                      invoiceRefNumber: data?.invoiceRefNumber,
                      invoicePaidDate: data.invoicePaidDate
                        ? data.invoicePaidDate?.split("T")[0]
                        : "",
                      invoiceLineItemItemNameWithoutParent:
                        data?.invoiceLineItemItemNameWithoutParent,
                      invoiceAssignedToName: data?.invoiceAssignedToName,
                      invoiceSupplierLocation: data?.invoiceSupplierLocation,
                      resalePORefNumer: data?.resalePORefNumer,
                      resalePOVendor: data?.resalePOVendor,
                      resalePOReleaseNumber: data?.resalePOReleaseNumber,
                      ResalePORecordID: data?.resalePORecordID,
                      resalePODistance: data?.resalePODistance,
                      solidSurface: (data.invoice?.solidSurface) ? data.invoice.solidSurface : 0,
                      towTruck: (data.invoice?.towTruck) ? data.invoice.towTruck : 0,
                      // resalePOLineItemDistance:
                      //   data.resalePOLineItemDistance &&
                      //     data.resalePOLineItemDistance != 0
                      //     ? data.resalePOLineItemDistance
                      //     : data.purchaseOrder
                      //       ? data.purchaseOrder
                      //         .distanceBetweenPickupAndDeliveryAddress
                      //       : null,
                      newDistance: data.newDistance ? data.newDistance : null,
                      invoiceShipMethodMethodFieldName:
                        data?.invoiceShipMethodMethodFieldName,
                      invoiceShipAddress1: data?.invoiceShipAddress1,
                      invoiceShipAddress2: data?.invoiceShipAddress2,
                      invoiceShipAddress3: data?.invoiceShipAddress3,
                      invoiceShipCity: data?.invoiceShipCity,
                      invoiceShipZip: data?.invoiceShipZip,
                      resalePOPickupAddress1: data?.resalePOPickupAddress1,
                      resalePOPickupCity: data?.resalePOPickupCity,
                      resalePOPickupZipCode: data?.resalePOPickupZipCode,
                      pickupLocation:
                        data.resalePOPickupCity && data.resalePOPickupState
                          ? data.resalePOPickupCity +
                          ", " +
                          data.resalePOPickupState
                          : "",
                      resalePOReleaseDate: data.resalePOReleaseDate
                        ? data.resalePOReleaseDate?.split("T")[0]
                        : "",
                      resalePOTotalPrice: data?.resalePOTotalPrice,
                      plannedStatus:
                        data.planning && data.planning.plannedStatus == 1
                          ? "Yes"
                          : "No",
                      resalePOExpectedDate: data.resalePOExpectedDate
                        ? data.resalePOExpectedDate?.split("T")[0]
                        : "",
                      resalePOLastDepotCallDate: data.resalePOLastDepotCallDate
                        ? data.resalePOLastDepotCallDate?.split("T")[0]
                        : "",

                      invoiceShipCity: data.invoiceShipCity
                        ? data.invoiceShipCity
                        : "",
                      shipState: data.invoiceShipState
                        ? data.invoiceShipState
                        : "",

                      resalePOPickupCity: data.resalePOPickupCity
                        ? data.resalePOPickupCity
                        : "",
                      pickupState: data.resalePOPickupState
                        ? data.resalePOPickupState
                        : "",

                      invoiceLineItem_RecordID: data.invoiceLineItem_RecordID,

                      transportPODeliveryStatus:
                        data?.transportPODeliveryStatus,
                      invoicePromisedTimeline: data.invoicePromisedTimeline
                        ? data.invoicePromisedTimeline
                        : "",
                      invoicePromisedDate: data.invoicePromisedDate
                        ? data.invoicePromisedDate?.split("T")[0]
                        : "",
                        sameTrailerMarker: data.sameTrailerMarker,
                    })),
                    page: result.data.currentPage - 1,
                    totalCount: result.data.totalItems,
                  });

                  setPageRefresh(false);
                });
            }
          })
        }
        components={{
          Toolbar: (props) => (
            <ResetFillterButton elsement={props} resetFilter={resetFilter} />
          ),
        }}


        options={{
          thirdSortClick: false,

          // rowStyle: (rowData) => ({

          //   backgroundColor: (rowData.invoiceTerms == "Rent To Own" && rowData.solidSurface == 1 ) ? '#90EE90' : (rowData.invoiceTerms == "Rent To Own") ? '#ffda73' : (rowData.solidSurface == 1) ? '#7bede9' : '#fff'
          // }),
          rowStyle: rowData => ({
            backgroundColor: (rowData.invoiceTerms == "Rent To Own" && rowData.towTruck == 1 ) ? '#F7AE77' : (rowData.invoiceTerms == "Rent To Own" && rowData.solidSurface == 1 ) ? '#90EE90' : (rowData.towTruck == 1 && rowData.solidSurface == 1 ) ? '#F0BBF0' : (rowData.invoiceTerms == "Rent To Own") ? '#ffda73' : (rowData.solidSurface == 1) ? '#7bede9' : (rowData.towTruck == 1) ? 'pink' : '#fff'
        }),

          debounceInterval: 1500,
          pageSize: 50,
          pageSizeOptions: [50, 100, 200],
          filtering: true,
        }}
      />
      <style jsx="true">{`

                .btn-set{
                    position: absolute;
                    top: 18px;
                    font-size: 17px;
                    right: 255px;
                    color: #000;
                    padding: 4px 6px;
                    border: 1px solid #79a3ff;
                }
                .input-wid-set {
                    border: none;
                    border-bottom: 1px solid #949494;
                    position: relative;
                    top: 3px;
                    width: 100%;
                }
            `}</style>
    </div>
  );
}

export default DataTable;
