import React from 'react';
import MaterialTable, { MTableToolbar,Chip  } from 'material-table';

function ResetFillterButton(props) {
    return (
        <div>
            <MTableToolbar {...props.elsement} />
            <div style={{padding: '0px 10px'}}>
                <a onClick={()=>props.resetFilter()} href="javascript:void(0)"><i className="fa fa-bars btn-set" aria-hidden="true"></i></a>
            </div>
        </div>
    );
}

export default ResetFillterButton;