import { API_BASE_URL , ACCESS_TOKEN_NAME } from '../../../constants/apiConstants';
import axios from 'axios'

export default class PurchaseFormServices {

    static async getPlanningDriverinfo(id) {
            const token = localStorage.getItem(ACCESS_TOKEN_NAME);
            const response = await axios.get(API_BASE_URL + `/getVendorDetails/`+id, {
                headers: {
                  Authorization: "Bearer " + token,
                },
            });
            return response.data;
    }

    static async fetchFuelSurcharge() {
        const token = localStorage.getItem(ACCESS_TOKEN_NAME);
        const response = await axios.get(API_BASE_URL + `/getFuelSurchargeRate`, {
          headers: {
            Authorization: "Bearer " + token,
          },
        });
        return response.data;
      }


    static async getVendor() {
            const token = localStorage.getItem(ACCESS_TOKEN_NAME);
            const response = await axios.get(API_BASE_URL + `/findAllVendor?isactive=`+1, {
                headers: {
                  Authorization: "Bearer " + token,
                },
            });
            return response.data;
    }


    static async getVendorById(id) {
            const token = localStorage.getItem(ACCESS_TOKEN_NAME);
            const response = await axios.get(API_BASE_URL + `/findSingleVendorData?id=`+id+`&status=`+[1,0], {
                headers: {
                  Authorization: "Bearer " + token,
                },
            });
            
            return response.data;
    }


    static async getData(id) {
            const token = localStorage.getItem(ACCESS_TOKEN_NAME);
            const response = await axios.get(API_BASE_URL + `/getPurchaseOrder?recordID=`+id, {
                headers: {
                  Authorization: "Bearer " + token,
                },
            });
            return response.data;
    }

    static async getPurchaseOrderItem(id) {
        const token = localStorage.getItem(ACCESS_TOKEN_NAME);
        const response = await axios.get(API_BASE_URL + `/getPurchaseOrderLine?recordID=`+id, {
            headers: {
              Authorization: "Bearer " + token,
            },
        });
        return response.data;
    }

    static async uploadAttachment(file) {
        var formData = new FormData(); 
        formData.append('file',file);
            const token = localStorage.getItem(ACCESS_TOKEN_NAME);
            const response = await axios.post(API_BASE_URL + `/attachment`, formData , {
                headers: {
                  Authorization: "Bearer " + token,
                },
            });
            return response.data;
    }

    static async getPurchaseOrderStatus(id) {
            const token = localStorage.getItem(ACCESS_TOKEN_NAME);
            const response = await axios.get(API_BASE_URL + `/checkOrderStatus?orderId=`+id, {
                headers: {
                  Authorization: "Bearer " + token,
                },
            });
            
            return response.data;
    }

    static async getPicklocationByName(pickupLocation , pickupAddress1) {
        const token = localStorage.getItem(ACCESS_TOKEN_NAME);
        const response = await axios.get(API_BASE_URL + `/getPicklocation?pickupLocationName=`+pickupLocation+`&pickupAddress1=`+pickupAddress1, {
            headers: {
                Authorization: "Bearer " + token,
            },
        });
        return response.data;
    }

    static async fetchAllDriverByVendor(id) {
        const token = localStorage.getItem(ACCESS_TOKEN_NAME);
        const response = await axios.get(API_BASE_URL + `/findSingleVendorData?id=`+id+`&status=`+[1,0], {
            headers: {
                Authorization: "Bearer " + token,
            },
        });
        return response.data;
    }

    static async upldatePlanningDriver(driverId , id){
        const token = localStorage.getItem(ACCESS_TOKEN_NAME);
        const response = await axios.put(API_BASE_URL + `/updatePlanningDriver?id=`+id, {driverId:driverId} , {
            headers: {
              Authorization: "Bearer " + token,
            },
        });
        return response.data;
    }
}


  