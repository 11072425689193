import React, { useState, useEffect } from 'react';
import "../../assets/css/demo.css";
import "../../assets/css/sidebar-accordion.css";
import "../../images/icon/themify-icons/themify-icons.css";
import "../../assets/css/style.css";
import "../../images/js/script.js"
import "../../images/js/pcoded.min.js"
import "../../images/js/demo-12.js"
import "../../images/icon/icofont/css/icofont.css";
import truck from '../../images/truck.png'
import file from '../../images/file.png'
import report from '../../images/report.png'
import driver from '../../images/driver.png'
import { ACCESS_TOKEN_NAME, ACCESS_TOKEN_NAME_USER } from "../../constants/apiConstants";
import { Link } from "react-router-dom";
import Navbar from "./../Layout/Navbar";
import Sidebar from "./../Layout/Sidebar";
import Header from "./../Layout/Header";
import Footer from "./../Layout/Footer";
import jstz from 'jstz';
import logo from '../../images/logo.png';
import profilepic from '../../images/avatar-4.jpg';
import $ from 'jquery';
import DashboardServices from './DashboardServices';
import Avatar from 'react-avatar';
import moment from 'moment-timezone';
import SyncComponent from '../Shared/SyncComponent';

function Dashboard(props) {

    const [companyCount, setCompanyCount] = useState({});

    const [count, setCount] = useState({});

    const [display, setdisplay] = useState('none');

    const [companyLoading, setCompanyLoading] = useState(false);

    const [loading, setloading] = useState(false);
    const [pageNameList, setPageNameList] = useState('Dashboard')
    const [currentTime, setCurrentTime] = useState(moment().tz(jstz.determine().name()).format('MM/DD/YYYY h:mm:ss a'));

  useEffect(() => {
    const intervalId = setInterval(() => {
      // Update the current time with the new time zone
      setCurrentTime(moment().tz(jstz.determine().name()).format('MM/DD/YYYY h:mm:ss a'));
    }, 1000);  // Update every second

    // Clear the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, []);  // Empty dependency array to run the effect only once on mount

    useEffect(() => {
        const role = localStorage.getItem('role');
        if (role == null) {
            redirectToLogin();
        } else {
            props.history.push('/dashboard');
        }
    }, []);

    const redirectToLogin = () => {
        props.history.push('/');
    }

    function handleLogout() {
        localStorage.removeItem(ACCESS_TOKEN_NAME);
        localStorage.removeItem('role');
        localStorage.removeItem('user');
        localStorage.removeItem(ACCESS_TOKEN_NAME_USER);
        props.history.push("/");
    }

    const fetchDashboardCompanyCount = async () => {
        try {
            const timezone = jstz.determine();
            setCompanyLoading(true);
            const result = await DashboardServices.fetchDashboardCompanyCount(moment().tz(timezone.name()).format('MM/DD/YYYY'));
            setCompanyCount(result);
            setCompanyLoading(false);
        } catch (error) {
            console.log(error, 'fetchDashboardCompanyCount');
        }
    }

    const fetchDashboardCount = async () => {
        try {
            const timezone = jstz.determine();
            setloading(true);
            const result = await DashboardServices.fetchDashboardCount(moment().tz(timezone.name()).format('MM/DD/YYYY'));
            setCount(result);
            setloading(false);
        } catch (error) {
            console.log(error, 'fetchDashboardCompanyCount');
        }
    }

    useEffect(() => {
        fetchDashboardCompanyCount();
        fetchDashboardCount();
    }, []);



    return (
        <div id="pcoded" className="pcoded">
            <div className="pcoded-overlay-box"></div>
            <div className="pcoded-container navbar-wrapper">
                <nav className="navbar header-navbar pcoded-header" style={{ height: '55px', minHeight: '26px' }}>
                    <div className="navbar-wrapper">
                        <div className="navbar-logo" style={{ height: '52px' }}>
                            <a style={{ top: '7px' }} className="mobile-menu navabar_menu" id="mobile-collapse" href="#!">
                                <i class="ti-menu" style={{ "color": " #000", "font-size": "22px;" }}></i>
                            </a>
                            <a className="mobile-search morphsearch-search" href="#">
                                <i className="ti-search"></i>
                            </a>
                            <a href="/dashboard">
                                <img style={{ width: '30%' }} className="img-fluid logo-img" src={logo} alt="Theme-Logo" />
                            </a>
                            <a className="mobile-options">
                                <i className="ti-more"></i>
                            </a>
                        </div>

                        <SyncComponent pageNameList={pageNameList} />
                    </div>
                </nav>
                <div className="pcoded-main-container">
                    <div className="pcoded-wrapper">
                        <Sidebar />
                        <div className="pcoded-content page_content">
                            <div className="pcoded-inner-content">
                                <div className="main-body">
                                    <div className="page-wrapper">
                                        <div className="page-body">
                                            {
                                                (companyLoading || loading) ?
                                                    <i class="fa fa-spinner fa-spin center-position" />
                                                    :
                                                    <>
                                                        <div class="row">
                                                            <div class="col-sm-12">
                                                                <h4 class="heading-work">My Workload</h4>
                                                            </div>
                                                            <div class="col-12 col-md-6 col-lg">
                                                                <div class="card comp-card">
                                                                    <div class="card-body">
                                                                        <a href="/orderstatus?filter=7">
                                                                            <div class="row align-items-center">
                                                                                <div class="col">
                                                                                    <i class="fa fa-truck service-icon"></i>
                                                                                </div>
                                                                                <div class="col-auto">

                                                                                    <h4 class="f-w-700 text-count">{count.needDispatched}</h4>
                                                                                    <p class="m-b-0">Need Dispatch</p>
                                                                                </div>
                                                                            </div>
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-12 col-md-6 col-lg">
                                                                <div class="card comp-card">
                                                                    <div class="card-body">
                                                                        <a href="/orderstatus?filter=10">
                                                                            <div class="row align-items-center">
                                                                                <div class="col">
                                                                                    <i class="fa fa-truck service-icon dispatch"></i>
                                                                                </div>
                                                                                <div class="col-auto">

                                                                                    <h4 class="f-w-700 text-count">{count.dispatchOrders}</h4>
                                                                                    <p class="m-b-0">Dispatched</p>
                                                                                </div>
                                                                            </div>
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-12 col-md-6 col-lg">
                                                                <div class="card comp-card">
                                                                    <div class="card-body">
                                                                        <a href="/orderstatus?filter=5">
                                                                        <div class="row align-items-center">
                                                                            <div class="col">
                                                                                <i class="fa fa-truck service-icon dispatch1"></i>
                                                                            </div>
                                                                            <div class="col-auto">

                                                                                <h4 class="f-w-700 text-count">{count.waitOnRelease}</h4>                                                                              <p class="m-b-0">Waiting on release</p>
                                                                            </div>

                                                                        </div>
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-12 col-md-6 col-lg">
                                                                <div class="card comp-card">
                                                                    <div class="card-body">
                                                                        <a href="/orderstatus?filter=4">
                                                                        <div class="row align-items-center">
                                                                            <div class="col">
                                                                                <i class="fa fa-shopping-cart service-icon dispatch2"></i>
                                                                            </div>
                                                                            <div class="col-auto">

                                                                                <h4 class="f-w-700 text-count">{count.needToBuy}</h4>
                                                                                <p class="m-b-0">Need to buy</p>
                                                                            </div>

                                                                        </div>
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-12 col-md-6 col-lg">
                                                                <div class="card comp-card">
                                                                    <div class="card-body">
                                                                    <a href="/orderstatus?filter=1">
                                                                        <div class="row align-items-center">
                                                                            <div class="col">
                                                                                <i class="fa fa-ban service-icon dispatch3"></i>
                                                                            </div>
                                                                            <div class="col-auto">

                                                                                <h4 class="f-w-700 text-count">{count.hasNotPaid}</h4>
                                                                                <p class="m-b-0">Has not paid</p>
                                                                            </div>

                                                                        </div>
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="row" style={{ "margin-top": "2%" }}>
                                                        <div class="col-12 col-md-6 col-lg">
                                                                <div class="card comp-card">
                                                                    <div class="card-body">
                                                                    <a href='/delivered?filter=1'>
                                                                            <div class="row align-items-center">
                                                                                <div class="col">
                                                                                    <i class="fa fa-truck service-icon"></i>
                                                                                </div>
                                                                                <div class="col-auto">

                                                                                    <h4 class="f-w-700 text-count">{count.deliveredToday}</h4>
                                                                                    <p class="m-b-0">Delivered Today</p>
                                                                                </div>
                                                                            </div>
                                                                      </a>
                                                                    </div>
                                                                </div>
                                                            </div>


                                                            <div class="col-12 col-md-6 col-lg">
                                                                <div class="card comp-card">
                                                                    <div class="card-body">
                                                                    <a href='/delivered?filter=2'>
                                                                        <div class="row align-items-center">
                                                                            <div class="col">
                                                                                <i class="fa fa-truck service-icon dispatch1"></i>
                                                                            </div>
                                                                            <div class="col-auto">

                                                                                <h4 class="f-w-700 text-count">{count.deliveredYesterday}</h4>
                                                                                <p class="m-b-0">Delivered yesterday</p>
                                                                            </div>

                                                                        </div>
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div class="col-12 col-md-6 col-lg">
                                                                <div class="card comp-card">
                                                                    <div class="card-body">
                                                                    <a href='/delivered?filter=3'>
                                                                        <div class="row align-items-center">
                                                                            <div class="col">
                                                                                <i class="fa fa-ban service-icon dispatch3"></i>
                                                                            </div>
                                                                            <div class="col-auto">

                                                                                <h4 class="f-w-700 text-count">{count.deliveredThisMonth}</h4>
                                                                                <p class="m-b-0">Delivered this month</p>
                                                                            </div>

                                                                        </div>
                                                                        </a>
                                                                
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div class="col-12 col-md-6 col-lg">
                                                                    <div class="card comp-card">
                                                                        <div class="card-body">
                                                                        <a href='/delivered?filter=4'>
                                                                            <div class="row align-items-center">
                                                                                <div class="col">
                                                                                    <i class="fa fa-ban service-icon dispatch3"></i>
                                                                                </div>
                                                                                <div class="col-auto">

                                                                                    <h4 class="f-w-700 text-count">{count.deliveredLastMonth}</h4>
                                                                                    <p class="m-b-0">Delivered last month</p>
                                                                                </div>

                                                                            </div>
                                                                        </a>
                                                                        </div>
                                                                    </div>
                                                            </div> 
                                                        </div>

                                                        <div class="row" style={{ "margin-top": "5%" }}>
                                                            <div class="col-sm-12">
                                                                <h4 class="heading-work">Company Total</h4>
                                                            </div>
                                                            <div class="col-12 col-md-6 col-lg">
                                                                <div class="card comp-card">
                                                                    <div class="card-body">
                                                                    <a href="/orderstatus?filter=7">
                                                                        <div class="row align-items-center">
                                                                            <div class="col">
                                                                                <i class="fa fa-truck service-icon"></i>
                                                                            </div>
                                                                            <div class="col-auto">

                                                                                <h4 class="f-w-700 text-count">{companyCount.needDispatched}</h4>
                                                                                <p class="m-b-0">Need Dispatch</p>
                                                                            </div>
                                                                        </div>
                                                                    </a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-12 col-md-6 col-lg">
                                                                <div class="card comp-card">
                                                                    <div class="card-body">
                                                                    <a href="/orderstatus?filter=10">
                                                                        <div class="row align-items-center">
                                                                            <div class="col">
                                                                                <i class="fa fa-truck service-icon dispatch"></i>
                                                                            </div>
                                                                            <div class="col-auto">

                                                                                <h4 class="f-w-700 text-count">{companyCount.dispatchOrders}</h4>
                                                                                <p class="m-b-0">Dispatched</p>
                                                                            </div>

                                                                        </div>
                                                                    </a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-12 col-md-6 col-lg">
                                                                <div class="card comp-card">
                                                                    <div class="card-body">
                                                                    <a href="/orderstatus?filter=5">
                                                                        <div class="row align-items-center">
                                                                            <div class="col">
                                                                                <i class="fa fa-truck service-icon dispatch1"></i>
                                                                            </div>
                                                                            <div class="col-auto">

                                                                                <h4 class="f-w-700 text-count">{companyCount.waitOnRelease}</h4>
                                                                               <p class="m-b-0">Waiting on release</p>
                                                                            </div>

                                                                        </div>
                                                                    </a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-12 col-md-6 col-lg">
                                                                <div class="card comp-card">
                                                                    <div class="card-body">
                                                                    <a href="/orderstatus?filter=4">
                                                                        <div class="row align-items-center">
                                                                            <div class="col">

                                                                                <i class="fa fa-shopping-cart service-icon dispatch2"></i>
                                                                            </div>
                                                                            <div class="col-auto">

                                                                                <h4 class="f-w-700 text-count">{companyCount.needToBuy}</h4>
                                                                                <p class="m-b-0">Need to buy</p>
                                                                            </div>

                                                                        </div>
                                                                    </a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-12 col-md-6 col-lg">
                                                                <div class="card comp-card">
                                                                    <div class="card-body">
                                                                    <a href="/orderstatus?filter=1">
                                                                        <div class="row align-items-center">
                                                                            <div class="col">
                                                                                <i class="fa fa-ban service-icon dispatch3"></i>
                                                                            </div>
                                                                            <div class="col-auto">

                                                                                <h4 class="f-w-700 text-count">{companyCount.hasNotPaid}</h4>
                                                                                <p class="m-b-0">Has not paid</p>
                                                                            </div>

                                                                        </div>
                                                                    </a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="row" style={{ "margin-top": "2%" }}>
                                                        <div class="col-12 col-md-6 col-lg">
                                                                <div class="card comp-card">
                                                                    <div class="card-body">
                                                                    <a href='/delivered?filter=1'>
                                                                            <div class="row align-items-center">
                                                                                <div class="col">
                                                                                    <i class="fa fa-truck service-icon"></i>
                                                                                </div>
                                                                                <div class="col-auto">

                                                                                    <h4 class="f-w-700 text-count">{companyCount.deliveredToday}</h4>
                                                                                    <p class="m-b-0">Delivered Today</p>
                                                                                </div>
                                                                            </div>
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>



                                                            <div class="col-12 col-md-6 col-lg">
                                                                <div class="card comp-card">
                                                                    <div class="card-body">
                                                                    <a href='/delivered?filter=2'>
                                                                        <div class="row align-items-center">
                                                                            <div class="col">
                                                                                <i class="fa fa-truck service-icon dispatch1"></i>
                                                                            </div>
                                                                            <div class="col-auto">

                                                                                <h4 class="f-w-700 text-count">{companyCount.deliveredYesterday}</h4>
                                                                                <p class="m-b-0">Delivered yesterday</p>
                                                                            </div>

                                                                        </div>
                                                           </a>
                                                                    </div>
                                                                </div>
                                                            </div>



                                                            <div class="col-12 col-md-6 col-lg">
                                                                <div class="card comp-card">
                                                                    <div class="card-body">
                                                                    <a href='/delivered?filter=3'>
                                                                        <div class="row align-items-center">
                                                                            <div class="col">
                                                                                <i class="fa fa-ban service-icon dispatch3"></i>
                                                                            </div>
                                                                            <div class="col-auto">

                                                                                <h4 class="f-w-700 text-count">{companyCount.deliveredThisMonth}</h4>
                                                                                <p class="m-b-0">Delivered this month</p>
                                                                            </div>

                                                                        </div>
                                                                 </a>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div class="col-12 col-md-6 col-lg">
                                                                <div class="card comp-card">
                                                                    <div class="card-body">
                                                                    <a href='/delivered?filter=4'>
                                                                        <div class="row align-items-center">
                                                                            <div class="col">
                                                                                <i class="fa fa-ban service-icon dispatch3"></i>
                                                                            </div>
                                                                            <div class="col-auto">

                                                                                <h4 class="f-w-700 text-count">{companyCount.deliveredLastMonth}</h4>
                                                                                <p class="m-b-0">Delivered last month</p>
                                                                            </div>

                                                                        </div>
                                                                    </a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            
                                                        </div>

                                                        <div class="row" style={{ "margin-top": "5%" }}>
                                                        
                                                        <div class="col-12 col-md-12" style={{"text-align":"center"}}>
                                                        
                                                                <h4 class="heading-work">Summary <span>({currentTime})</span></h4>
                                                                <ul>
                                                                <li className='menu-item'>Needs Dispatch: {companyCount.needDispatched}<Link to='/needsdispatch' className='cstmlink'><i className="fa fa-solid fa-link"></i></Link></li>  
                                                                    <li className='menu-item'>Dispatched: {companyCount.dispatchOrders}<Link to='/dispatched' className='cstmlink'><i className="fa fa-solid fa-link"></i></Link></li>
                                                                    <li className='menu-item'>Waiting on Release: {companyCount.waitOnRelease}<Link to='/WaitingOnRelease' className='cstmlink'><i className="fa fa-solid fa-link"></i></Link></li>
                                                                    <li className='menu-item'>Needs to Buy: {companyCount.needToBuy}<Link to='/orderstatus?filter=4' className='cstmlink'><i className="fa fa-solid fa-link"></i></Link></li>
                                                                    <li className='menu-item'>Has not Paid: {companyCount.hasNotPaid}<Link to='/orderstatus?filter=1' className='cstmlink'><i className="fa fa-solid fa-link"></i></Link></li>
                                                                    <li className='menu-item'>Delivered Today: {companyCount.deliveredToday}<a href='/delivered?filter=1' className='cstmlink'><i className="fa fa-solid fa-link"></i></a></li>
                                                                    <li className='menu-item'>Delivered Yesterday: {companyCount.deliveredYesterday}<a href='/delivered?filter=2' className='cstmlink'><i className="fa fa-solid fa-link"></i></a></li>
                                                                    <li className='menu-item'>Delivered This Month: {companyCount.deliveredThisMonth}<a href='/delivered?filter=3' className='cstmlink'><i className="fa fa-solid fa-link"></i></a></li>
                                                                    <li className='menu-item'>Delivered Last Month: {companyCount.deliveredLastMonth}<a href='/delivered?filter=4' className='cstmlink'><i className="fa fa-solid fa-link"></i></a></li>
                                                                </ul>
                                                                <br/>
                                                                <div className='row justify-content-center align-items-center color-choose'>
                                               <h5 className='cstmstates'><span>RTO</span><span className='statecolor rto'></span></h5>
                                               <h5 className='cstmstates'><span>Solid Surface</span><span className='statecolor solid_surface'></span></h5>      
                                               <h5 className='cstmstates'><span>RTO on Solid Surface</span><span className='statecolor rto_ss'></span></h5>  
                                               <h5 className='cstmstates'><span>Tow Truck</span><span className='statecolor solid_tow'></span></h5>      
                                               <h5 className='cstmstates'><span>RTO + Tow Truck</span><span className='statecolor rto-two'></span></h5>    
                                               <h5 className='cstmstates'><span>Solid Surface + Tow Truck</span><span className='statecolor solid-two'></span></h5>      
                                            </div>
                                                            </div>
                                                        </div>
                                                        

                                                        <style jsx="true">{`
                                                                .service-icon{
                                                                    display: flex;
                                                                    width: 2.2rem;
                                                                    height: 2.2rem;
                                                                    flex-shrink: 0;
                                                                    border-radius: 50%;
                                                                    align-items: center;
                                                                    font-size: 1.2rem;
                                                                    justify-content: center;
                                                                    background-color: #dc3545;
                                                                    color: #fff;
                                                                }
                                                                .service-icon.dispatch{
                                                                    background: #3a3a3c;
                                                                }
                                                                .service-icon.dispatch1{
                                                                    background: #35b653;
                                                                }
                                                                .service-icon.dispatch2{
                                                                    background: #7ecaf5;
                                                                }
                                                                .service-icon.dispatch3{
                                                                    background: #ef5f1c;
                                                                }
                                                                .text-count{
                                                                    color:#383838;
                                                                }
                                                                .card-body{
                                                                    padding: 1rem;
                                                                }
                                                                .col-auto{
                                                                    text-align: right;
                                                                }
                                                                .card {
                                                                    margin-bottom: 0px;

                                                                }
                                                                .service-sect-area{
                                                                    padding-top: 20px;
                                                                    margin-bottom: 20px;
                                                                }
                                                                .heading-work{
                                                                    font-size: 18px !important;
                                                                    font-family: 'Source Sans Pro', sans-serif;
                                                                }
                                                                .dash-section{
                                                                    font-weight:bold;
                                                                    font-size:20px;
                                                                }
                                                            `}</style>
                                                        <Footer />
                                                    </>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default Dashboard;