
import PropType from 'prop-types';
import React from 'react';

const CustomCallCheckboxInvoice = ({
  field, value , bindFunction, labelClassName , form: { touched, errors , values }, label, disabled, inputRef, ...props
}) => (
  <>
  {
    (values[field.name]) ?
      <input
          checked
          type="checkbox"
          id={field.name}
          ref={inputRef}
          {...field}
          {...props}
          onClick={bindFunction}
      /> 
    :
    <input
        type="checkbox"
        id={field.name}
        ref={inputRef}
        {...field}
        {...props}
        onClick={bindFunction}
    />
  }

  <label className={labelClassName} htmlFor={field.name}>&nbsp;&nbsp;{label}&nbsp;&nbsp;</label>
  {touched[field.name] && errors[field.name] ? (
    <small style={{ color: 'red' }}>{errors[field.name]}</small>
  ) : null}
</>
);

CustomCallCheckboxInvoice.defaultProps = {
  inputRef: undefined
};

CustomCallCheckboxInvoice.propTypes = {
  label: PropType.string.isRequired,
  field: PropType.object.isRequired,
  form: PropType.object.isRequired,
  inputRef: PropType.oneOfType([
    PropType.func,
    PropType.shape({ current: PropType.instanceOf(Element) })
  ])
};

export default CustomCallCheckboxInvoice;
