import React, { useState, useEffect } from "react";
import axios from "axios";
import { Route, Link } from "react-router-dom";
import Loader from "react-loader-spinner";
import { API_BASE_URL, ACCESS_TOKEN_NAME } from "../../constants/apiConstants";
import loaderimage from '../../images/load.gif';
import ReactPaginate from 'react-paginate';
import './order.css';
import Header from "../../components/Header/Header";

const Order = () => {
  const [orderData, setOrderData] = useState();
  const [filterData, setFilterData] = useState();
  const [loading, setLoading] = useState(true);
  const [offset, setOffset] = useState(0);
  const [perPage] = useState(10);
  const [pageCount, setPageCount] = useState(0)

  useEffect(() => {
    setLoading(true);
    fetchData();
    // filterStatus();
  }, [offset]);
    
  const fetchData = async () => {
    const token = localStorage.getItem(ACCESS_TOKEN_NAME);
    const response = await axios.get(API_BASE_URL + "/findAllOrder", {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    const res = response.data;
      const slice = response.data.slice(offset, offset + perPage)
      setOrderData(slice);
    // setOrderData(response.data) ;
    setPageCount(Math.ceil(res.length / perPage))
    if(response.data.length > 0){
      setLoading(false);
    }
    console.log('datasss',response.data);
  }

  const handlePageClick = (e) => {
      const selectedPage = e.selected;
      setOffset(selectedPage + 1)
  };
  // const filterStatus = async () => {
  //   const response = await axios.get(API_BASE_URL + "/findAllFilter");
  //   setFilterData(response.data);
  //   console.log('filter data ->>>>>>>.',response.data);
  // }
  
  const handleChange = (e) => {
    console.log("Selected!!", e.target.value);
    setLoading(true);
    const value = e.target.value;
    setFilterData(e.target.value);
    fetchSingleData(value);
  }

  const fetchSingleData = async (value) => {
    const statusResult = await axios.get(API_BASE_URL + `/findSingleOrder?status=${value}`);
    if(statusResult.data.length > 0){
      setLoading(false);
    }
    setOrderData(statusResult.data) ;
    console.log('selected datas-------',statusResult);
  }

  return (
    <>   
    <Header />
      <div style={{ width: "540px",  height: "10px", marginTop : "10px", marginBottom: "10px"}}>
        <select value={filterData} onChange={handleChange}>
        {/* {filterData && 
              filterData.map((data) => (
          <option value={data.id}>{data.filterName}</option>
        ))} */}

          <option value="1">Has Not Paid</option>
          <option value="2">Welcome Call Needed</option>
          <option value="3">Follow Up Call Needed</option>
          <option value="4">Needs To Buy</option>
          <option value="5">Waiting On Release</option>
          <option value="6">Inventory Not Paid</option>
          <option value="7">Needs Dispatch</option>
          <option value="8">Customer Pickup</option>
          <option value="9">Expedited Sales</option>
          <option value="10">Dispatched</option>
          <option value="11">Completed</option>
          <option value="12">Inventory</option>
          <option value="13">Cancelled</option>
          <option value="14">On Hold</option>
        </select>
      </div>
      <div className="container">
            <div>Orders</div>
            
        <div style={{marginTop: "10px"}}>
          <table className="table border shadow">
            <thead className="thead-dark">
              <tr>
                <th scope="col">RecordID</th>
                <th scope="col">InvoiceRefNumber</th>
                <th scope="col">InvoiceLineItemItemName</th>
                <th scope="col">InvoiceLineItemDesc</th>
                <th scope="col">InvoiceAssignedToName</th>
                <th scope="col">InvoiceCustomerFullName</th>
              </tr>
            </thead>
            <tbody>
            

              {/* {orderData &&
                orderData.map((data, index) => (
                  <tr key={data.id}>
                    <td>{data.RecordID}</td>
                    <td>{data.TenantID}</td>
                    <td>{data.InvoiceLineItemItemName}</td>
                    <td>{data.InvoiceLineItemDesc}</td>
                    <td>{data.InvoiceAssignedToName}</td>
                    <td>{data.InvoiceCustomerFullName}</td>
                  </tr>
                ))} */}

            </tbody>
          </table>
          
        </div>
        <div style={{display: loading ? "block" : "none"}}>
          <img src={loaderimage} />
        </div>
        <div style={{display: loading ? "none" : "block"}}>
          <table className="table table-hover">
            <tbody>
              {/* {orderData} */}
          {orderData &&
                orderData.map((data, index) => (
                  <tr key={data.id}>
                    <td style={{width:"100px"}}>{data.RecordID}</td>
                    <td style={{width:"200px"}}>{data.InvoiceRefNumber}</td>
                    <td style={{width:"300px"}}>{data.InvoiceLineItemItemName}</td>
                    <td style={{width:"220px"}}>{data.InvoiceLineItemDesc}</td>
                    <td style={{width:"240px"}}>{data.InvoiceAssignedToName}</td>
                    <td style={{width:"240px"}}>{data.InvoiceCustomerFullName}</td>
                  </tr>
                ))}
              </tbody>
          </table>
        </div>

        <ReactPaginate
                    previousLabel={"prev"}
                    nextLabel={"next"}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination"}
                    subContainerClassName={"pages pagination"}
                    activeClassName={"active"}/>

      </div>
    </>
  );
};
export default Order;

//
