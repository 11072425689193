import React, { Component } from 'react';
import { Field } from 'formik';
import  CustomCheckboxInvoice  from './CustomCheckboxInvoice';


class CheckboxInvoice extends Component {
    render() {
        return (
            <Field 
                placeholder={this.props.placeholder} 
                name={this.props.name}
                label={this.props.label}
                component={CustomCheckboxInvoice}
                disabled={this.props.disabled}
                value={this.props.value}
                labelClassName={this.props.labelClassName}
            />
        );
    }
}

export default CheckboxInvoice;